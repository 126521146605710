import { OverlayTrigger, Popover } from "react-bootstrap";
import React from "react";
import { HasErrorsModel } from "models/HasErrorsModel";


interface Params {
    item: HasErrorsModel;
    name: string;
    formattedValue?: string | JSX.Element;
}

const ErroneousValue = ({ item, name, formattedValue }: Params)=> {
    const key = name as keyof typeof item;

    if (item.errors && item.errors[name]){
        return <OverlayTrigger placement={ "left" } overlay={
            <Popover>
                <Popover.Body>
                    <div className="mb-1">{ item.errors[name] }</div>
                </Popover.Body>
            </Popover>
        } >
            <span className="text-danger">
                { item[key]
                    ? <span><b>{ item[key].toString() }</b></span>
                    : <span className="alert-danger wp-15 d-inline-block cursor-default">&nbsp;</span>
                }
            </span>
        </OverlayTrigger>;
    }
    else{
        return <span>{ formattedValue ? formattedValue : (item[key] !== undefined ? item[key].toString() : "N/A") }</span>;
    }
};

export  default ErroneousValue;

