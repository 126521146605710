import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Input from "components/form/Input";
import { modalStore } from "store/ModalStoreProvider";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";
import { useToastStore } from "store/ToastStoreProvider";
import PrincipalService from "services/admin/PrincipalService";
import { PrincipalModel } from "models/PrincipalModel";
import FormUtil from "utils/FormUtil";

interface Props {
    principal: PrincipalModel
    onSuccess: any;
}

export const showEditPrincipalDialog = (principal: PrincipalModel, onSuccess: any) => {
    modalStore.setBody(<EditPrincipalDialog onSuccess={ onSuccess } principal={ principal }/>);
    modalStore.setHeader("Edit Principal");
    modalStore.showModal();
};

const EditPrincipalDialog = ({ principal, onSuccess }: Props) => {

    const { addToast } = useToastStore();

    const {
        handleSubmit,
        control,
        formState: { errors, isDirty }
    } = useForm<PrincipalModel>({
        mode: "onChange",
        defaultValues: {
            first_name: principal.full_name.split(",")[1]?.trim(),
            last_name: principal.full_name.split(",")[0]?.trim(),
            zip_code: principal.zip_code,
            birthday: principal.birthday
        }
    });

    const onSubmit: SubmitHandler<PrincipalModel> = data => {
        data.id = principal.id;

        PrincipalService.update(data).then(() => {
            addToast({
                message:"Principal has been updated.",
                header: "Principal editing",
                variant: "success",
                timeoutMs: DEFAULT_TOASTER_TIMEOUT
            });
            onSuccess();
        }).catch(() => {
            addToast({
                message:"Something went wrong. Please try again",
                header: "Principal editing",
                variant: "danger",
                timeoutMs: DEFAULT_TOASTER_TIMEOUT
            });
        }).finally(() => modalStore.hideModal());
    };

    modalStore.setAdditionalButtons([
        <button key={ 0 } className="btn btn-primary" type="button"
            onClick={ handleSubmit(onSubmit) }
            disabled={ !isDirty }
        >Save</button>
    ]);

    return <div>
        <p className="mb-4">Edit principal&apos;s Name, Surname, year of birth and ZIP code</p>
        <div>
            <div className="row">
                <div className="col-12">
                    <Input
                        control={ control } errors={ errors } name="first_name" rules={ FormUtil.NAME_VALIDATIONS }
                        label="First name*" placeholder="Enter principal&apos;s first name" type="text"/>
                </div>
                <div className="col-12">
                    <Input
                        control={ control } errors={ errors } name="last_name" rules={ FormUtil.NAME_VALIDATIONS }
                        label="Last name*" placeholder="Enter principal&apos;s last name" type="text"/>
                </div>
                <div className="col-12">
                    <Input
                        control={ control } errors={ errors } rules={ FormUtil.YEAR_VALIDATIONS } name="birthday"
                        label="Year of birth*" placeholder="Enter principal&apos;s year of birth" type="number"/>
                </div>
                <div className="col-12">
                    <Input
                        control={ control } errors={ errors } name="zip_code" rules={ FormUtil.ZIP_COD_VALIDATIONS }
                        label="ZIP*" placeholder="Enter principal&apos;s ZIP code" type="text"/>
                </div>
            </div>
        </div>
    </div>;
};
