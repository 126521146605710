import React, { useState } from "react";
import Input from "components/form/Input";
import { ControllerInputProps } from "components/form/BaseInput";
import classNames from "classnames";

const PasswordFormInput = (props : Omit<ControllerInputProps, "type">) => {
    const [ visible, setVisible] = useState(false);
    return (
        <div className="password-with-icon">
            <Input { ...props } type={ visible ? "text" : "password" }/>
            <i className= { classNames("password-with-icon-i", visible ? "bi-eye-slash-fill" : "bi-eye-fill") }
                onClick={ () => setVisible(!visible) }
                role="presentation"
            />
        </div>
    );
};

export default PasswordFormInput;
