import React, { useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthStore } from "store/AuthStoreProvider";
import URLUtil from "utils/URLUtil";
import { PageInfo } from "models/PageInfo";
import Input from "components/form/Input";
import { SelectControlled } from "components/form/Select";
import LicenseSelectInput from "components/LicenseSelectInput";
import { observer } from "mobx-react";
import UserList from "pages/admin/components/UserList";

export type ManageUsersRequest = {
    name?: string,
    email?: string,
    status?: number,
    license_id?: number
} & PageInfo;

const STATUSES = [
    { value: 0, title: "All" },
    { value: 1, title: "Confirmed" },
    { value: 2, title: "Not confirmed" }
];

const ManageUsers = () => {
    const auth = useAuthStore();
    const navigate = useNavigate();
    const location = useLocation();
    const [urlData, setUrlData] = useState<ManageUsersRequest>({
        ...(URLUtil.readSearchParams() as ManageUsersRequest),
        ...(auth.permissionStore.isBusinessAdmin ? { license_id: auth.licenseId } : { }) //force BA to see user only from his license
    });

    useLayoutEffect(() => {
        URLUtil.updateUrlParams(location.pathname, urlData, navigate);//this component used in 2 locations
    }, [urlData]);

    return <div className="d-flex flex-column">
        <div className="mb-4 mb-md-5">
            <h1 className="text-primary mb-0">Manage Users</h1>
        </div>
        <SearchForm urlData={ urlData } setUrlData={ setUrlData } />
        <UserList urlData={ urlData } setUrlData={ setUrlData } />
    </div>;
};

export default observer(ManageUsers);

const SearchForm = ({ urlData, setUrlData }:{ urlData: ManageUsersRequest, setUrlData: (a:ManageUsersRequest)=>void }) =>{

    const auth = useAuthStore();
    const isBA = auth.permissionStore.isBusinessAdmin;

    const {
        handleSubmit,
        control,
        reset
    } = useForm<ManageUsersRequest>({
        mode: "onSubmit",
        defaultValues: {
            license_id: urlData.license_id,
            status: urlData.status || 0,
            name: urlData.name,
            email: urlData.email
        }
    });

    const resetForm = () => reset({ status: 0, name: "", email: "" });
    const onSubmit = (request: ManageUsersRequest) =>
        setUrlData({
            license_id: request.license_id,
            status: request.status,
            name: request.name?.trim(),
            email: request.email?.trim(),
            page: 1
        });

    return <form onSubmit={ handleSubmit(onSubmit) } >
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-md-6 col-xl-3">
                        <LicenseSelectInput control={ control } name="license_id" label="License ID or Serial Number"
                            isDisabled={ isBA }
                            placeholder="Enter license id or serial number"/>
                    </div>
                    <div className="col-12 col-md-6 col-xl-3">
                        <Input name="name" placeholder="Enter first and/or last name" label="Name" type="text"
                            control={ control }/>
                    </div>
                    <div className="col-12 col-md-6 col-xl-3">
                        <Input name="email" placeholder="Enter user email" label="Email" type="text" control={ control }/>
                    </div>
                    <div className="col-12 col-md-6 col-xl-3">
                        <SelectControlled name="status" options={ STATUSES } control={ control } label="Status"/>
                    </div>
                </div>
                <div className="row justify-content-center mt-2">
                    <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                        <button className="btn btn-outline-secondary w-100" type="button" onClick={ resetForm }>Clear
                            form
                        </button>
                    </div>
                    <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                        <button className="btn btn-primary w-100">Search</button>
                    </div>
                </div>
            </div>
        </div>
    </form>;
};
