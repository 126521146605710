import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

const WholesalerManageBrandsItemsBatchView = () => {
    return <div className="d-flex flex-column">
        <div className="mb-3">
            <h1 className="text-primary mb-4">Batch Submission - Liquor to Retailer Schedule Effective November 2022</h1>
            <p className="mb-0">Review results of the submission process. You can edit records later in an interactive mode.</p>
        </div>
        <div className="mb-4">
            <a className="btn btn-outline-primary me-3 mb-3" href="/">Read more about Batch Submission</a>
            <a className="btn btn-outline-primary mb-3" href="/">Price posting CSV Template</a>
        </div>
        <div className="steps-wr mb-4">
            <div className="steps-l row g-0">
                <div className="steps-li col-12 col-xl-3">
                    <div className="steps-li-in">
                        <strong>1</strong> Select CSV file to upload
                    </div>
                </div>
                <div className="steps-li col-12 col-xl-3">
                    <div className="steps-li-in">
                        <strong>2</strong> Select Discount codes CSV file
                    </div>
                </div>
                <div className="steps-li col-12 col-xl-3 active">
                    <div className="steps-li-in">
                        <strong>3</strong> Preview batch submission
                    </div>
                </div>
            </div>
        </div>
        <div className="card mt-4">
            <div className="card-body">
                <div className="mb-3">
                    <h4><strong>Step 3:</strong> Preview batch submission</h4>
                </div>
                <div className="">
                    <div className="nav nav-pills btn-group flex-column flex-lg-row d-lg-inline-flex mb-0" id="nav-tab" role="tablist" aria-orientation="vertical">
                        <button className="btn btn-outline-secondary min-wp-230 active" id="nav-1-tab" data-bs-toggle="tab" data-bs-target="#nav-1" type="button" role="tab" aria-controls="nav-1" aria-selected="true">Correct Records <span className="badge rounded-pill bg-success ms-1">2</span></button>
                        <button className="btn btn-outline-secondary min-wp-230" id="nav-2-tab" data-bs-toggle="tab" data-bs-target="#nav-2" type="button" role="tab" aria-controls="nav-2" aria-selected="false">Incorrect Items <span className="badge rounded-pill bg-danger ms-1">1</span></button>
                        <button className="btn btn-outline-secondary min-wp-230" id="nav-3-tab" data-bs-toggle="tab" data-bs-target="#nav-3" type="button" role="tab" aria-controls="nav-3" aria-selected="false">Incorrect Discounts <span className="badge rounded-pill bg-danger ms-1">26</span></button>
                    </div>
                </div>
            </div>
        </div>
        <div className="tab-content mt-4" id="nav-tabContent">
            <div className="tab-pane fade show active" id="nav-1" role="tabpanel" aria-labelledby="nav-1-tab">
                <div className="pt-3">
                    <h5 className="mb-3"><strong>Total records:</strong> 2</h5>
                </div>
                <div className="mb-4">
                    <div className="table-responsive">
                        <table className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>&nbsp;</th>
                                    <th>Type</th>
                                    <th>Brand name</th>
                                    <th>Product Name</th>
                                    <th>Product Number</th>
                                    <th>Size / Unit</th>
                                    <th>Price per bottle</th>
                                    <th>Price per case</th>
                                    <th>NYC price per bottle</th>
                                    <th>NYC price per case</th>
                                    <th>Approval or BLR #</th>
                                    <th>TTB / CoLA ID #</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <a className="table-collapse-a collapsed" data-bs-toggle="collapse" href="#collapseTr1" role="button" aria-expanded="false" aria-controls="collapseTr">
                                            <i className="bi-caret-right-fill"></i>
                                        </a>
                                    </td>
                                    <td>Neutral Spirits</td>
                                    <td>Regular</td>
                                    <td>Regular description</td>
                                    <td>ALBA</td>
                                    <td>750ML</td>
                                    <td>33.33</td>
                                    <td>192</td>
                                    <td>30.10</td>
                                    <td>180</td>
                                    <td>55333322</td>
                                    <td>12697555</td>
                                </tr>
                                <tr>
                                    <td colSpan={ 13 } className="p-0">
                                        <div className="collapse" id="collapseTr1">
                                            <div className="p-4">
                                                <div className="d-flex flex-column flex-sm-row align-items-start">
                                                    <div className="nav flex-column nav-pills btn-group-vertical min-wp-230" id="snav-tab" role="tablist" aria-orientation="vertical">
                                                        <button className="btn btn-outline-secondary active" id="snav-1-tab" data-bs-toggle="tab" data-bs-target="#snav-1" type="button" role="tab" aria-controls="snav-1" aria-selected="false">Item details</button>
                                                        <button className="btn btn-outline-secondary" id="snav-2-tab" data-bs-toggle="tab" data-bs-target="#snav-2" type="button" role="tab" aria-controls="snav-2" aria-selected="false">NYS supplier details</button>
                                                        <button className="btn btn-outline-secondary" id="snav-3-tab" data-bs-toggle="tab" data-bs-target="#snav-3" type="button" role="tab" aria-controls="snav-3" aria-selected="false">Discount details</button>
                                                        <button className="btn btn-outline-secondary" id="snav-7-tab" data-bs-toggle="tab" data-bs-target="#snav-7" type="button" role="tab" aria-controls="snav-7" aria-selected="false">Limited availability</button>
                                                        <button className="btn btn-outline-secondary" id="snav-8-tab" data-bs-toggle="tab" data-bs-target="#snav-8" type="button" role="tab" aria-controls="snav-8" aria-selected="true">Combo details</button>
                                                    </div>
                                                    <div className="tab-content flex-fill" id="snav-tabContent">
                                                        <div className="tab-pane fade show active" id="snav-1" role="tabpanel" aria-labelledby="snav-1-tab">
                                                            <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                                <h5 className="mb-3 fw-bold">Item details</h5>
                                                                <div className="row mt-4">
                                                                    <div className="col-12 col-lg-6 col-xl-4 mb-3">
                                                                        <p className="mb-2"><strong>Proof:</strong> 150.0</p>
                                                                        <p className="mb-2"><strong>Alcohol content:</strong> 7% to 14%</p>
                                                                        <p className="mb-2"><strong>Age of year:</strong> not applicable</p>
                                                                    </div>
                                                                    <div className="col-12 col-lg-6 col-xl-4 mb-lg-3">
                                                                        <p className="mb-2"><strong># of bottles per case / sleeve:</strong> 1/12</p>
                                                                        <p className="mb-2"><strong>Case only:</strong> N</p>
                                                                        <p className="mb-2"><strong>BT / SLV Split case charge:</strong> 1.00</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="snav-2" role="tabpanel" aria-labelledby="snav-2-tab">
                                                            <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                                <h5 className="mb-3 fw-bold">NYS supplier details</h5>
                                                                <p className="mb-2"><strong>NYS supplier #:</strong> 12388990</p>
                                                                <p className="mb-2"><strong>NYS supplier item #:</strong> 23188990</p>
                                                                <p className="mb-2"><strong>NYS label:</strong> N</p>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="snav-3" role="tabpanel" aria-labelledby="snav-3-tab">
                                                            <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                                <h5 className="mb-3 fw-bold">Discount details</h5>
                                                                <p className="mb-2"><strong>Discount code:</strong> ABC</p>
                                                                <p className="mb-2"><strong>Discount:</strong> $4.55 on 12 bottles</p>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="snav-7" role="tabpanel" aria-labelledby="snav-7-tab">
                                                            <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                                <h5 className="mb-3 fw-bold">Limited availability</h5>
                                                                <p className="mb-2"><strong>Allocation method:</strong> Unsold accounts</p>
                                                                <p className="mb-2"><strong>Allocation description:</strong> test description</p>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="snav-8" role="tabpanel" aria-labelledby="snav-8-tab">
                                                            <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                                <h5 className="mb-3 fw-bold">Combo details</h5>
                                                                <p className="mb-2">Consumer packaging</p>
                                                                <p className="mb-2">Supplier assem</p>
                                                                <div className="row mt-4">
                                                                    <div className="col-12 col-lg-6 col-xl-4 mb-3">
                                                                        <p className="mb-2"><strong>Item 1:</strong></p>
                                                                        <p className="mb-2"><strong>NYS Supplier Item:</strong> 1233455555</p>
                                                                        <p className="mb-2"><strong>Quantity:</strong> 12</p>
                                                                    </div>
                                                                    <div className="col-12 col-lg-6 col-xl-4 mb-lg-3">
                                                                        <p className="mb-2"><strong>Item 2:</strong></p>
                                                                        <p className="mb-2"><strong>NYS Supplier Item:</strong> 6123355855</p>
                                                                        <p className="mb-2"><strong>Quantity:</strong> 5</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <a className="table-collapse-a collapsed" data-bs-toggle="collapse" href="#collapseTr2" role="button" aria-expanded="false" aria-controls="collapseTr">
                                            <i className="bi-caret-right-fill"></i>
                                        </a>
                                    </td>
                                    <td>Whiskey</td>
                                    <td>Combo</td>
                                    <td>Regular description</td>
                                    <td>ALBA</td>
                                    <td>750ML</td>
                                    <td>33.33</td>
                                    <td>192</td>
                                    <td>30.10</td>
                                    <td>180</td>
                                    <td>55333322</td>
                                    <td>12697555</td>
                                </tr>
                                <tr>
                                    <td colSpan={ 13 } className="p-0">
                                        <div className="collapse" id="collapseTr2">
                                            <div className="p-4">
                                                <div className="d-flex flex-column flex-sm-row align-items-start">
                                                    <div className="nav flex-column nav-pills btn-group-vertical min-wp-230" id="nav--tab" role="tablist" aria-orientation="vertical">
                                                        <button className="btn btn-outline-secondary active" id="nav--1-tab" data-bs-toggle="tab" data-bs-target="#nav--1" type="button" role="tab" aria-controls="nav--1" aria-selected="false">Item details</button>
                                                        <button className="btn btn-outline-secondary" id="nav--2-tab" data-bs-toggle="tab" data-bs-target="#nav--2" type="button" role="tab" aria-controls="nav--2" aria-selected="false">NYS supplier details</button>
                                                        <button className="btn btn-outline-secondary" id="nav--3-tab" data-bs-toggle="tab" data-bs-target="#nav--3" type="button" role="tab" aria-controls="nav--3" aria-selected="false">Discount details</button>
                                                        <button className="btn btn-outline-secondary" id="nav--7-tab" data-bs-toggle="tab" data-bs-target="#nav--7" type="button" role="tab" aria-controls="nav--7" aria-selected="false">Limited availability</button>
                                                        <button className="btn btn-outline-secondary" id="nav--8-tab" data-bs-toggle="tab" data-bs-target="#nav--8" type="button" role="tab" aria-controls="nav--8" aria-selected="true">Combo details</button>
                                                    </div>

                                                    <div className="tab-content flex-fill" id="nav--tabContent">
                                                        <div className="tab-pane fade show active" id="nav--1" role="tabpanel" aria-labelledby="nav--1-tab">
                                                            <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                                <h5 className="mb-3 fw-bold">Item details</h5>
                                                                <div className="row mt-4">
                                                                    <div className="col-12 col-lg-6 col-xl-4 mb-3">
                                                                        <p className="mb-2"><strong>Proof:</strong> 136.0</p>
                                                                        <p className="mb-2"><strong>Alcohol content:</strong> 7% to 14%</p>
                                                                        <p className="mb-2"><strong>Age of year:</strong> not applicable</p>
                                                                    </div>
                                                                    <div className="col-12 col-lg-6 col-xl-4 mb-lg-3">
                                                                        <p className="mb-2"><strong># of bottles per case / sleeve:</strong> 1/12</p>
                                                                        <p className="mb-2"><strong>Case only:</strong> N</p>
                                                                        <p className="mb-2"><strong>BT / SLV Split case charge:</strong> 1.00</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="nav--2" role="tabpanel" aria-labelledby="nav--2-tab">
                                                            <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                                <h5 className="mb-3 fw-bold">NYS supplier details</h5>
                                                                <p className="mb-2"><strong>NYS supplier #:</strong> 12388990</p>
                                                                <p className="mb-2"><strong>NYS supplier item #:</strong> 23188990</p>
                                                                <p className="mb-2"><strong>NYS label:</strong> N</p>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="nav--3" role="tabpanel" aria-labelledby="nav--3-tab">
                                                            <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                                <h5 className="mb-3 fw-bold">Discount details</h5>
                                                                <p className="mb-2"><strong>Discount code:</strong> ABC</p>
                                                                <p className="mb-2"><strong>Discount:</strong> $4.55 on 12 bottles</p>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="nav--7" role="tabpanel" aria-labelledby="nav--7-tab">
                                                            <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                                <h5 className="mb-3 fw-bold">Limited availability</h5>
                                                                <p className="mb-2"><strong>Allocation method:</strong> Unsold accounts</p>
                                                                <p className="mb-2"><strong>Allocation description:</strong> test description</p>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="nav--8" role="tabpanel" aria-labelledby="nav--8-tab">
                                                            <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                                <h5 className="mb-3 fw-bold">Combo details</h5>
                                                                <p className="mb-2">Consumer packaging</p>
                                                                <p className="mb-2">Supplier assem</p>
                                                                <div className="row mt-4">
                                                                    <div className="col-12 col-lg-6 col-xl-4 mb-3">
                                                                        <p className="mb-2"><strong>Item 1:</strong></p>
                                                                        <p className="mb-2"><strong>NYS Supplier Item:</strong> 1233455555</p>
                                                                        <p className="mb-2"><strong>Quantity:</strong> 12</p>
                                                                    </div>
                                                                    <div className="col-12 col-lg-6 col-xl-4 mb-3">
                                                                        <p className="mb-2"><strong>Item 2:</strong></p>
                                                                        <p className="mb-2"><strong>NYS Supplier Item:</strong> 6123355855</p>
                                                                        <p className="mb-2"><strong>Quantity:</strong> 5</p>
                                                                    </div>
                                                                    <div className="col-12 col-lg-6 col-xl-4 mb-lg-3">
                                                                        <p className="mb-2"><strong>Item 3:</strong></p>
                                                                        <p className="mb-2"><strong>NYS Supplier Item:</strong> 6123355855</p>
                                                                        <p className="mb-2"><strong>Quantity:</strong> 5</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <ul className="justify-content-center pagination mb-5">
                    <li className="page-item disabled">
                        <span className="page-link">
                            <span className="bi bi-arrow-left me-2"></span>Prev
                        </span>
                    </li>
                    <li className="page-item active">
                        <span className="page-link">1</span>
                    </li>
                    <li className="page-item">
                        <a className="page-link" role="button" href="/">2</a>
                    </li>
                    <li className="page-item">
                        <a className="page-link" role="button" href="/">3</a>
                    </li>
                    <li className="page-item">
                        <a className="page-link" role="button" href="/">...</a>
                    </li>
                    <li className="page-item">
                        <a className="page-link" role="button" href="/">50</a>
                    </li>
                    <li className="page-item">
                        <a className="page-link" role="button" href="/">
                            Next<span className="bi bi-arrow-right ms-2"></span>
                        </a>
                    </li>
                </ul>
                <div className="row justify-content-center">
                    <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                        <a href="/wholesaler-static/manage-brands-items/batch2" className="btn btn-outline-secondary w-100">Start over</a>
                    </div>
                    <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                        <a href="/wholesaler-static/manage-brands-items/batch3" className="btn btn-primary w-100">Finish import</a>
                    </div>
                </div>
            </div>
            <div className="tab-pane fade" id="nav-2" role="tabpanel" aria-labelledby="nav-2-tab">
                <div className="pt-3 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                    <h5 className="mb-3"><strong>Total records:</strong> 1</h5>
                    <a className="mb-3" href="/">Download file with invalid records</a>
                </div>
                <div className="mb-4">
                    <div className="table-responsive">
                        <table className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>&nbsp;</th>
                                    <th>Type</th>
                                    <th>Brand name</th>
                                    <th>Product Name</th>
                                    <th>Product Number</th>
                                    <th>Size / Unit</th>
                                    <th>Price per bottle</th>
                                    <th>Price per case</th>
                                    <th>NYC price per bottle</th>
                                    <th>NYC price per case</th>
                                    <th>Approval or BLR #</th>
                                    <th>TTB / CoLA ID #</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <a className="table-collapse-a collapsed" data-bs-toggle="collapse" href="#collapseTr1" role="button" aria-expanded="false" aria-controls="collapseTr">
                                            <i className="bi-caret-right-fill"></i>
                                        </a>
                                    </td>
                                    <td>Neutral Spirits</td>
                                    <td>Regular</td>
                                    <td>Regular description</td>
                                    <td>ALBA</td>
                                    <td>750ML</td>
                                    <td>33.33</td>
                                    <td>192</td>
                                    <td>30.10</td>
                                    <td>180</td>
                                    <td>55333322</td>
                                    <td>12697555</td>
                                </tr>
                                <tr>
                                    <td colSpan={ 13 } className="p-0">
                                        <div className="collapse" id="collapseTr1">
                                            <div className="p-4">
                                                <div className="d-flex flex-column flex-sm-row align-items-start">
                                                    <div className="nav flex-column nav-pills btn-group-vertical min-wp-230" id="ssnav-tab" role="tablist" aria-orientation="vertical">
                                                        <button className="btn btn-outline-secondary active" id="ssnav-1-tab" data-bs-toggle="tab" data-bs-target="#ssnav-1" type="button" role="tab" aria-controls="ssnav-1" aria-selected="false">Item details</button>
                                                        <button className="btn btn-outline-secondary text-danger" id="ssnav-2-tab" data-bs-toggle="tab" data-bs-target="#ssnav-2" type="button" role="tab" aria-controls="ssnav-2" aria-selected="false"><i className="bi bi-exclamation-circle-fill me-2"></i>NYS supplier details</button>
                                                        <button className="btn btn-outline-secondary" id="ssnav-3-tab" data-bs-toggle="tab" data-bs-target="#ssnav-3" type="button" role="tab" aria-controls="ssnav-3" aria-selected="false">Discount details</button>
                                                        <button className="btn btn-outline-secondary" id="ssnav-7-tab" data-bs-toggle="tab" data-bs-target="#ssnav-7" type="button" role="tab" aria-controls="ssnav-7" aria-selected="false">Limited availability</button>
                                                        <button className="btn btn-outline-secondary" id="ssnav-8-tab" data-bs-toggle="tab" data-bs-target="#ssnav-8" type="button" role="tab" aria-controls="ssnav-8" aria-selected="false">Combo details</button>
                                                    </div>
                                                    <div className="tab-content flex-fill" id="ssnav-tabContent">
                                                        <div className="tab-pane fade show active" id="ssnav-1" role="tabpanel" aria-labelledby="ssnav-1-tab">
                                                            <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                                <h5 className="mb-3 fw-bold">Item details</h5>
                                                                <div className="row mt-4">
                                                                    <div className="col-12 col-lg-6 col-xl-4 mb-3">
                                                                        <p className="mb-2"><strong>Proof:</strong> 150.0</p>
                                                                        <p className="mb-2"><strong>Alcohol content:</strong> 7% to 14%</p>
                                                                        <p className="mb-2"><strong>Age of year:</strong> not applicable</p>
                                                                    </div>
                                                                    <div className="col-12 col-lg-6 col-xl-4 mb-lg-3">
                                                                        <p className="mb-2"><strong># of bottles per case / sleeve:</strong> 1/12</p>
                                                                        <p className="mb-2"><strong>Case only:</strong> N</p>
                                                                        <p className="mb-2"><strong>BT / SLV Split case charge:</strong> 1.00</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="ssnav-2" role="tabpanel" aria-labelledby="ssnav-2-tab">
                                                            <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                                <h5 className="mb-3 fw-bold">NYS supplier details</h5>
                                                                <p className="mb-2 text-danger"><strong>NYS supplier #:</strong> 12388990.jjj</p>
                                                                <p className="mb-2"><strong>NYS supplier item #:</strong> 23188990</p>
                                                                <p className="mb-2"><strong>NYS label:</strong> N</p>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="ssnav-3" role="tabpanel" aria-labelledby="ssnav-3-tab">
                                                            <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                                <h5 className="mb-3 fw-bold">Discount details</h5>
                                                                <p className="mb-2"><strong>Discount code:</strong> ABC</p>
                                                                <p className="mb-2"><strong>Discount:</strong> $4.55 on 12 bottles</p>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="ssnav-7" role="tabpanel" aria-labelledby="ssnav-7-tab">
                                                            <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                                <h5 className="mb-3 fw-bold">Limited availability</h5>
                                                                <p className="mb-2"><strong>Allocation method:</strong> Unsold accounts</p>
                                                                <p className="mb-2"><strong>Allocation description:</strong> test description</p>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="ssnav-8" role="tabpanel" aria-labelledby="ssnav-8-tab">
                                                            <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                                <h5 className="mb-3 fw-bold">Combo details</h5>
                                                                <p className="mb-2">Consumer packaging</p>
                                                                <p className="mb-2">Supplier assem</p>
                                                                <div className="row mt-4">
                                                                    <div className="col-12 col-lg-6 col-xl-4 mb-3">
                                                                        <p className="mb-2"><strong>Item 1:</strong></p>
                                                                        <p className="mb-2"><strong>NYS Supplier Item:</strong> 1233455555</p>
                                                                        <p className="mb-2"><strong>Quantity:</strong> 12</p>
                                                                    </div>
                                                                    <div className="col-12 col-lg-6 col-xl-4 mb-lg-3">
                                                                        <p className="mb-2"><strong>Item 2:</strong></p>
                                                                        <p className="mb-2"><strong>NYS Supplier Item:</strong> 6123355855</p>
                                                                        <p className="mb-2"><strong>Quantity:</strong> 5</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr id="error1">
                                    <td colSpan={ 12 } className="p-0">
                                        <div className="alert-danger p-2">
                                            <i className="bi-arrow-up me-2 fw-bold"></i>
                                            NYS supplier # is not valid: &apos;12388990.jjj&apos;
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p className="text-danger mb-4">Please correct the errors in CSV file and try uploading again</p>
                </div>
                <ul className="justify-content-center pagination mb-5">
                    <li className="page-item disabled">
                        <span className="page-link">
                            <span className="bi bi-arrow-left me-2"></span>Prev
                        </span>
                    </li>
                    <li className="page-item active">
                        <span className="page-link">1</span>
                    </li>
                    <li className="page-item">
                        <a className="page-link" role="button" href="/">2</a>
                    </li>
                    <li className="page-item">
                        <a className="page-link" role="button" href="/">3</a>
                    </li>
                    <li className="page-item">
                        <a className="page-link" role="button" href="/">...</a>
                    </li>
                    <li className="page-item">
                        <a className="page-link" role="button" href="/">50</a>
                    </li>
                    <li className="page-item">
                        <a className="page-link" role="button" href="/">
                            Next<span className="bi bi-arrow-right ms-2"></span>
                        </a>
                    </li>
                </ul>
                <div className="row justify-content-center">
                    <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                        <a href="/wholesaler-static/manage-brands-items/batch2" className="btn btn-outline-secondary w-100">Start over</a>
                    </div>
                    <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                        <a href="/wholesaler-static/manage-brands-items/batch3" className="btn btn-primary w-100">Finish import</a>
                    </div>
                </div>
            </div>
            <div className="tab-pane fade" id="nav-3" role="tabpanel" aria-labelledby="nav-3-tab">
                <div className="pt-3 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                    <h5 className="mb-3"><strong>Total discount codes:</strong> 4</h5>
                    <a className="mb-3" href="/">Download file with invalid records</a>
                </div>
                <div className="mb-4">
                    <div className="table-responsive">
                        <table className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>Discount code</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <OverlayTrigger placement={ "bottom" } overlay={
                                            <Popover>
                                                <Popover.Body>Empty discount code</Popover.Body>
                                            </Popover>
                                        } >
                                            <div className="alert-danger w-100 cursor-default">&nbsp;</div>
                                        </OverlayTrigger>
                                    </td>
                                    <td className="pb-1">
                                        <p className="mb-1"><strong className="text-primary">$2.40</strong> on 3 bottles</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>SALECASE</td>
                                    <td className="pb-1">
                                        <div className="content-styles">
                                            <ol className="ps-4 mb-0">
                                                <li className="mb-1 ps-1">
                                                    <OverlayTrigger placement={ "bottom" } overlay={
                                                        <Popover>
                                                            <Popover.Body>Wrong symbol. Can be % or $</Popover.Body>
                                                        </Popover>
                                                    } >
                                                        <strong className="text-danger cursor-default">^</strong>
                                                    </OverlayTrigger>
                                                    1.67 on 67
                                                    <OverlayTrigger placement={ "bottom" } overlay={
                                                        <Popover>
                                                            <Popover.Body>Wrong symbol. Can be B and bottles and С for cases</Popover.Body>
                                                        </Popover>
                                                    } >
                                                        <strong className="text-danger cursor-default">V</strong>
                                                    </OverlayTrigger>
                                                </li>
                                                <li className="mb-1 ps-1">
                                                    %5.00 on
                                                    <OverlayTrigger placement={ "bottom" } overlay={
                                                        <Popover>
                                                            <Popover.Body>Wrong value. Can be more than 0</Popover.Body>
                                                        </Popover>
                                                    } >
                                                        <strong className="text-danger cursor-default">0</strong>
                                                    </OverlayTrigger>
                                                    B
                                                </li>
                                                <li className="mb-1 ps-1">
                                                    <OverlayTrigger placement={ "bottom" } overlay={
                                                        <Popover>
                                                            <Popover.Body>Empty value. Can be % or $</Popover.Body>
                                                        </Popover>
                                                    } >
                                                        <div className="alert-danger wp-15 d-inline-block cursor-default">&nbsp;</div>
                                                    </OverlayTrigger>
                                                    5.00 on 35B
                                                </li>
                                            </ol>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>HELLO1</td>
                                    <td className="pb-1">
                                        <p className="mb-1"><strong className="text-primary">$2.40</strong> on 3 bottles</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <ul className="justify-content-center pagination mt-3">
                        <li className="page-item disabled">
                            <span className="page-link">
                                <span className="bi bi-arrow-left me-2"></span>Prev
                            </span>
                        </li>
                        <li className="page-item active">
                            <span className="page-link">1</span>
                        </li>
                        <li className="page-item">
                            <a className="page-link" role="button" href="/">2</a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" role="button" href="/">3</a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" role="button" href="/">...</a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" role="button" href="/">50</a>
                        </li>
                        <li className="page-item">
                            <a className="page-link" role="button" href="/">
                                Next<span className="bi bi-arrow-right ms-2"></span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>;
};

export default WholesalerManageBrandsItemsBatchView;

