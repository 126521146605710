import { action, makeObservable, observable } from "mobx";

export class ShowHideStore {

    @observable
    public isAllHidden = false;

    constructor() {
        makeObservable(this);
    }

    @action
    public hideAll() {
        this.isAllHidden = true;
    }

    @action
    public showAll() {
        this.isAllHidden = false;
    }
}
