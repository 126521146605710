import React from "react";
import { BaseSelect, SelectOption } from "components/form/Select";

 interface Props {
     value: number,
     onChange: (value: number) => void;

}

export const SelectLimit = ({ value, onChange } : Props) => {
    const LIMITS: SelectOption[] = [
        { value: 10, title: "10" },
        { value: 20, title: "20" },
        { value: 50, title: "50" },
        { value: 100, title: "100" }
    ];

    return <div className="d-flex flex-row align-items-center">
        <label htmlFor="limit" className="form-label mb-3 me-2">Results per page</label>
        <BaseSelect
            name="limit" options={ LIMITS } value={ value }
            onChange={ event => onChange(event.target.value) } />
    </div>;
};
