import httpClient from "api/HttpClient";
import BACKEND from "services/Constants";
import { ManualProduct } from "pages/wholesaler/priceposting/models/ManualProduct";
import { ProductModel } from "models/ProductModel";

export default {

    validate(bean: ManualProduct): Promise<Record<string, string>> {
        return httpClient.post(BACKEND + "/wholesaler/crud-product/validate", bean);
    },

    createOrUpdate(bean: ManualProduct): Promise<Counters> {
        return httpClient.post(BACKEND + "/wholesaler/crud-product/create", bean);
    },

    get(id: string, postType: string, year: string, month: string): Promise<ProductModel> {
        return httpClient.get(BACKEND + "/wholesaler/crud-product/get?id="+id+"&type="+postType+"&year="+year+"&month="+month);
    },

    getProduct(id: string): Promise<ProductModel> {
        return httpClient.get(BACKEND + "/wholesaler/crud-product/get-product/" + id);
    },

    delete: (id: number) => httpClient.post(BACKEND + "/wholesaler/crud-product/delete?id="+id),

    findCompetitorProduct(license: string, productNumber: string, postType: string, year: string, month: string): Promise<ProductModel> {
        return httpClient.get(BACKEND + "/wholesaler/crud-product/findCompetitorProduct?license="+license+"&productNumber="+productNumber+"&type="+postType+"&year="+year+"&month="+month);
    },

    applyCompetitorPrice(license: string, productNumber: string, id: string, postType: string, year: string, month: string): Promise<ProductModel> {
        return httpClient.post(BACKEND + "/wholesaler/crud-product/applyCompetitorPrice?license="+license+"&productNumber="+productNumber+"&id="+id+"&type="+postType+"&year="+year+"&month="+month);
    }

};

export interface Counters {
    successful: number,
    error: number;
}
