import React from "react";
import Input from "components/form/Input";

const WholesalerManageBrandsItems1View = () => {

    return <div className="d-flex flex-column">
        <div className="mb-4 mb-md-5">
            <h1 className="text-primary mb-0">Liquor to Wholesaler - Schedule for November 2022</h1>
        </div>
        <div>
            <div className="row mb-3 mb-md-4">
                <div className="col-12 col-lg-6">
                    <h5 className="mb-2 fw-bold">Manage Brands & Items</h5>
                    <p>On this page you can search and manage all your brands and items.</p>
                </div>
                <div className="col-12 col-lg-6 d-flex justify-content-lg-end align-items-lg-end">
                    <a className="btn btn-primary me-3 mb-3" href="/wholesaler-static/manage-brands-items/add-brand-or-item">Add Regular/VAP</a>
                    <a className="btn btn-primary me-3 mb-3" href="/wholesaler-static/manage-brands-items/add-brand-or-item2">Add Combo</a>
                    <a className="btn btn-secondary mb-3" href="/wholesaler-static/manage-brands-items/batch">Batch Submission</a>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <form action="">
                        <div className="row">
                            <div className="col-12 col-lg-6 col-xl-4">
                                <Input name="name" placeholder="Enter brand name" label="Brand name" type="text" />
                            </div>
                            <div className="col-12 col-lg-6 col-xl-4">
                                <Input name="Name" placeholder="Enter product name" label="Product Name" type="text" />
                            </div>
                            <div className="col-12 col-lg-6 col-xl-4">
                                <Input name="Number" placeholder="Enter product number" label="Product Number" type="text" />
                            </div>
                            <div className="col-12 col-lg-6 col-xl-3">
                                <Input name="approval" placeholder="Enter approval or BLR. #" label="Approval or BLR. #" type="text" />
                            </div>
                            <div className="col-12 col-lg-6 col-xl-3">
                                <Input name="ttb_id" placeholder="Enter TTB / CoLA ID number" label="TTB / CoLA ID number" type="text"/>
                            </div>
                            <div className="col-12 col-lg-6 col-xl-3">
                                <div className="form-group">
                                    <label htmlFor="BeverageType" className="form-label">Beverage Type</label>
                                    <select id="BeverageType" className="form-select" aria-label="Select">
                                        <option selected>All</option>
                                        <option value="1">Neutral Spirits</option>
                                        <option value="2">Whiskey</option>
                                        <option value="3">Gin</option>
                                        <option value="4">Brandy</option>
                                        <option value="5">Rum</option>
                                        <option value="6">Cordials & Liqueurs</option>
                                        <option value="7">Cocktails & Specialities</option>
                                        <option value="8">Imitations</option>
                                        <option value="9">Tequila</option>
                                        <option value="10">Other</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 col-xl-3">
                                <div className="form-group">
                                    <label htmlFor="PackageType" className="form-label">Package Type</label>
                                    <select id="PackageType" className="form-select" aria-label="Select">
                                        <option selected>All</option>
                                        <option value="1">Regular</option>
                                        <option value="2">Combo</option>
                                        <option value="3">VAP</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-2">
                            <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                                <div className="pe-md-1">
                                    <button className="btn btn-outline-secondary w-100">Clear Form</button>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                                <div className="ps-md-1">
                                    <button className="btn btn-primary w-100">Filter</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="mt-2">
                <div className="table-responsive mt-4">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>&nbsp;</th>
                                <th>Type</th>
                                <th>Brand name</th>
                                <th>Product Name</th>
                                <th>Product Number</th>
                                <th>Size / Unit</th>
                                <th>Price per bottle</th>
                                <th>Price per case</th>
                                <th>NYC price per bottle</th>
                                <th>NYC price per case</th>
                                <th>Approval or BLR #</th>
                                <th>TTB / CoLA ID #</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <a className="table-collapse-a collapsed" data-bs-toggle="collapse" href="#collapseTr1" role="button" aria-expanded="false" aria-controls="collapseTr">
                                        <i className="bi-caret-right-fill"></i>
                                    </a>
                                </td>
                                <td>Neutral Spirits</td>
                                <td>Regular</td>
                                <td>Regular description</td>
                                <td>ALBA</td>
                                <td>750ML</td>
                                <td>33.33</td>
                                <td>192</td>
                                <td>30.10</td>
                                <td>180</td>
                                <td>55333322</td>
                                <td>12697555</td>
                                <td className="text-nowrap">
                                    <button className="btn btn-link py-0 px-1 me-2" title="Edit user" data-bs-toggle="modal" data-bs-target="#exampleModalEdit">
                                        <i className="bi-pencil"></i>
                                    </button>
                                    <button className="btn btn-link py-0 px-1 me-2" title="Delete user" data-bs-toggle="modal" data-bs-target="#exampleModalRemove">
                                        <i className="bi-trash"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={ 13 } className="p-0">
                                    <div className="collapse" id="collapseTr1">
                                        <div className="p-4">
                                            <div className="d-flex flex-column flex-sm-row align-items-start">
                                                <div className="nav flex-column nav-pills btn-group-vertical min-wp-230" id="nav-tab" role="tablist" aria-orientation="vertical">
                                                    <button className="btn btn-outline-secondary active" id="nav-1-tab" data-bs-toggle="tab" data-bs-target="#nav-1" type="button" role="tab" aria-controls="nav-1" aria-selected="false">Item details</button>
                                                    <button className="btn btn-outline-secondary" id="nav-2-tab" data-bs-toggle="tab" data-bs-target="#nav-2" type="button" role="tab" aria-controls="nav-2" aria-selected="false">NYS supplier details</button>
                                                    <button className="btn btn-outline-secondary" id="nav-3-tab" data-bs-toggle="tab" data-bs-target="#nav-3" type="button" role="tab" aria-controls="nav-3" aria-selected="false">Discount details</button>
                                                    <button className="btn btn-outline-secondary" id="nav-4-tab" data-bs-toggle="tab" data-bs-target="#nav-4" type="button" role="tab" aria-controls="nav-4" aria-selected="false">FOB designation</button>
                                                    <button className="btn btn-outline-secondary" id="nav-7-tab" data-bs-toggle="tab" data-bs-target="#nav-7" type="button" role="tab" aria-controls="nav-7" aria-selected="false">Limited availability</button>
                                                    <button className="btn btn-outline-secondary" id="nav-8-tab" data-bs-toggle="tab" data-bs-target="#nav-8" type="button" role="tab" aria-controls="nav-8" aria-selected="true">Combo details</button>
                                                    <button className="btn btn-outline-secondary" id="nav-6-tab" data-bs-toggle="tab" data-bs-target="#nav-6" type="button" role="tab" aria-controls="nav-6" aria-selected="false">Brand / Owner agent</button>
                                                    <button className="btn btn-outline-secondary" id="nav-5-tab" data-bs-toggle="tab" data-bs-target="#nav-5" type="button" role="tab" aria-controls="nav-5" aria-selected="false">Authorized NYS distributors</button>
                                                </div>

                                                <div className="tab-content flex-fill" id="nav-tabContent">
                                                    <div className="tab-pane fade show active" id="nav-1" role="tabpanel" aria-labelledby="nav-1-tab">
                                                        <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                            <h5 className="mb-3 fw-bold">Item details</h5>
                                                            <div className="row mt-4">
                                                                <div className="col-12 col-lg-6 col-xl-4 mb-3">
                                                                    <p className="mb-2"><strong>Proof:</strong> 136.0</p>
                                                                    <p className="mb-2"><strong>Alcohol content:</strong> 7% to 14%</p>
                                                                    <p className="mb-2"><strong>Age of year:</strong> not applicable</p>
                                                                </div>
                                                                <div className="col-12 col-lg-6 col-xl-4 mb-lg-3">
                                                                    <p className="mb-2"><strong># of bottles per case / sleeve:</strong> 1/12</p>
                                                                    <p className="mb-2"><strong>Case only:</strong> N</p>
                                                                    <p className="mb-2"><strong>BT / SLV Split case charge:</strong> 1.00</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="nav-2" role="tabpanel" aria-labelledby="nav-2-tab">
                                                        <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                            <h5 className="mb-3 fw-bold">NYS supplier details</h5>
                                                            <p className="mb-2"><strong>NYS supplier #:</strong> 12388990</p>
                                                            <p className="mb-2"><strong>NYS supplier item #:</strong> 23188990</p>
                                                            <p className="mb-2"><strong>NYS label:</strong> N</p>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="nav-3" role="tabpanel" aria-labelledby="nav-3-tab">
                                                        <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                            <h5 className="mb-3 fw-bold">Discount details</h5>
                                                            <p className="mb-2"><strong>Discount code:</strong> ABC</p>
                                                            <p className="mb-2"><strong>Discount:</strong> $4.55 on 12 bottles</p>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="nav-4" role="tabpanel" aria-labelledby="nav-4-tab">
                                                        <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                            <h5 className="mb-3 fw-bold">FOB designation</h5>
                                                            <p className="mb-2">Some text with description</p>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="nav-5" role="tabpanel" aria-labelledby="nav-5-tab">
                                                        <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                            <h5 className="mb-3 fw-bold">Authorized NYS distributors</h5>
                                                            <p className="mb-2">Some text with description</p>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="nav-6" role="tabpanel" aria-labelledby="nav-6-tab">
                                                        <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                            <h5 className="mb-3 fw-bold">Brand / Owner agent</h5>
                                                            <p className="mb-2"><strong>Agent:</strong> ABC</p>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="nav-7" role="tabpanel" aria-labelledby="nav-7-tab">
                                                        <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                            <h5 className="mb-3 fw-bold">Limited availability</h5>
                                                            <p className="mb-2"><strong>Allocation method:</strong> Unsold accounts</p>
                                                            <p className="mb-2"><strong>Allocation description:</strong> test description</p>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="nav-8" role="tabpanel" aria-labelledby="nav-8-tab">
                                                        <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                            <h5 className="mb-3 fw-bold">Combo details</h5>
                                                            <p className="mb-2">Consumer packaging</p>
                                                            <p className="mb-2">Supplier assem</p>
                                                            <div className="row mt-4">
                                                                <div className="col-12 col-lg-6 col-xl-4 mb-3">
                                                                    <p className="mb-2"><strong>Item 1:</strong></p>
                                                                    <p className="mb-2"><strong>NYS Supplier Item:</strong> 1233455555</p>
                                                                    <p className="mb-2"><strong>Quantity:</strong> 12</p>
                                                                </div>
                                                                <div className="col-12 col-lg-6 col-xl-4 mb-lg-3">
                                                                    <p className="mb-2"><strong>Item 2:</strong></p>
                                                                    <p className="mb-2"><strong>NYS Supplier Item:</strong> 6123355855</p>
                                                                    <p className="mb-2"><strong>Quantity:</strong> 5</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a className="table-collapse-a collapsed" data-bs-toggle="collapse" href="#collapseTr2" role="button" aria-expanded="false" aria-controls="collapseTr">
                                        <i className="bi-caret-right-fill"></i>
                                    </a>
                                </td>
                                <td>Whiskey</td>
                                <td>Combo</td>
                                <td>Regular description</td>
                                <td>ALBA</td>
                                <td>750ML</td>
                                <td>33.33</td>
                                <td>192</td>
                                <td>30.10</td>
                                <td>180</td>
                                <td>55333322</td>
                                <td>12697555</td>
                                <td className="text-nowrap">
                                    <button className="btn btn-link py-0 px-1 me-2" title="Edit user" data-bs-toggle="modal" data-bs-target="#exampleModalEdit">
                                        <i className="bi-pencil"></i>
                                    </button>
                                    <button className="btn btn-link py-0 px-1 me-2" title="Delete user" data-bs-toggle="modal" data-bs-target="#exampleModalRemove">
                                        <i className="bi-trash"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={ 13 } className="p-0">
                                    <div className="collapse" id="collapseTr2">
                                        <div className="p-4">
                                            <div className="d-flex flex-column flex-sm-row align-items-start">
                                                <div className="nav flex-column nav-pills btn-group-vertical min-wp-230" id="nav--tab" role="tablist" aria-orientation="vertical">
                                                    <button className="btn btn-outline-secondary active" id="nav--1-tab" data-bs-toggle="tab" data-bs-target="#nav--1" type="button" role="tab" aria-controls="nav--1" aria-selected="false">Item details</button>
                                                    <button className="btn btn-outline-secondary" id="nav--2-tab" data-bs-toggle="tab" data-bs-target="#nav--2" type="button" role="tab" aria-controls="nav--2" aria-selected="false">NYS supplier details</button>
                                                    <button className="btn btn-outline-secondary" id="nav--3-tab" data-bs-toggle="tab" data-bs-target="#nav--3" type="button" role="tab" aria-controls="nav--3" aria-selected="false">Discount details</button>
                                                    <button className="btn btn-outline-secondary" id="nav--4-tab" data-bs-toggle="tab" data-bs-target="#nav--4" type="button" role="tab" aria-controls="nav--4" aria-selected="false">FOB designation</button>
                                                    <button className="btn btn-outline-secondary" id="nav--7-tab" data-bs-toggle="tab" data-bs-target="#nav--7" type="button" role="tab" aria-controls="nav--7" aria-selected="false">Limited availability</button>
                                                    <button className="btn btn-outline-secondary" id="nav--8-tab" data-bs-toggle="tab" data-bs-target="#nav--8" type="button" role="tab" aria-controls="nav--8" aria-selected="true">Combo details</button>
                                                    <button className="btn btn-outline-secondary" id="nav--6-tab" data-bs-toggle="tab" data-bs-target="#nav--6" type="button" role="tab" aria-controls="nav--6" aria-selected="false">Brand / Owner agent</button>
                                                    <button className="btn btn-outline-secondary" id="nav--5-tab" data-bs-toggle="tab" data-bs-target="#nav--5" type="button" role="tab" aria-controls="nav--5" aria-selected="false">Authorized NYS distributors</button>
                                                </div>

                                                <div className="tab-content flex-fill" id="nav--tabContent">
                                                    <div className="tab-pane fade show active" id="nav--1" role="tabpanel" aria-labelledby="nav--1-tab">
                                                        <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                            <h5 className="mb-3 fw-bold">Item details</h5>
                                                            <div className="row mt-4">
                                                                <div className="col-12 col-lg-6 col-xl-4 mb-3">
                                                                    <p className="mb-2"><strong>Proof:</strong> 136.0</p>
                                                                    <p className="mb-2"><strong>Alcohol content:</strong> 7% to 14%</p>
                                                                    <p className="mb-2"><strong>Age of year:</strong> not applicable</p>
                                                                </div>
                                                                <div className="col-12 col-lg-6 col-xl-4 mb-lg-3">
                                                                    <p className="mb-2"><strong># of bottles per case / sleeve:</strong> 1/12</p>
                                                                    <p className="mb-2"><strong>Case only:</strong> N</p>
                                                                    <p className="mb-2"><strong>BT / SLV Split case charge:</strong> 1.00</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="nav--2" role="tabpanel" aria-labelledby="nav--2-tab">
                                                        <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                            <h5 className="mb-3 fw-bold">NYS supplier details</h5>
                                                            <p className="mb-2"><strong>NYS supplier #:</strong> 12388990</p>
                                                            <p className="mb-2"><strong>NYS supplier item #:</strong> 23188990</p>
                                                            <p className="mb-2"><strong>NYS label:</strong> N</p>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="nav--3" role="tabpanel" aria-labelledby="nav--3-tab">
                                                        <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                            <h5 className="mb-3 fw-bold">Discount details</h5>
                                                            <p className="mb-2"><strong>Discount code:</strong> ABC</p>
                                                            <p className="mb-2"><strong>Discount:</strong> $4.55 on 12 bottles</p>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="nav--4" role="tabpanel" aria-labelledby="nav--4-tab">
                                                        <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                            <h5 className="mb-3 fw-bold">FOB designation</h5>
                                                            <p className="mb-2">Some text with description</p>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="nav--5" role="tabpanel" aria-labelledby="nav--5-tab">
                                                        <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                            <h5 className="mb-3 fw-bold">Authorized NYS distributors</h5>
                                                            <p className="mb-2">Some text with description</p>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="nav--6" role="tabpanel" aria-labelledby="nav--6-tab">
                                                        <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                            <h5 className="mb-3 fw-bold">Brand / Owner agent</h5>
                                                            <p className="mb-2"><strong>Agent:</strong> ABC</p>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="nav--7" role="tabpanel" aria-labelledby="nav--7-tab">
                                                        <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                            <h5 className="mb-3 fw-bold">Limited availability</h5>
                                                            <p className="mb-2"><strong>Allocation method:</strong> Unsold accounts</p>
                                                            <p className="mb-2"><strong>Allocation description:</strong> test description</p>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="nav--8" role="tabpanel" aria-labelledby="nav--8-tab">
                                                        <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                            <h5 className="mb-3 fw-bold">Combo details</h5>
                                                            <p className="mb-2">Consumer packaging</p>
                                                            <p className="mb-2">Supplier assem</p>
                                                            <div className="row mt-4">
                                                                <div className="col-12 col-lg-6 col-xl-4 mb-3">
                                                                    <p className="mb-2"><strong>Item 1:</strong></p>
                                                                    <p className="mb-2"><strong>NYS Supplier Item:</strong> 1233455555</p>
                                                                    <p className="mb-2"><strong>Quantity:</strong> 12</p>
                                                                </div>
                                                                <div className="col-12 col-lg-6 col-xl-4 mb-3">
                                                                    <p className="mb-2"><strong>Item 2:</strong></p>
                                                                    <p className="mb-2"><strong>NYS Supplier Item:</strong> 6123355855</p>
                                                                    <p className="mb-2"><strong>Quantity:</strong> 5</p>
                                                                </div>
                                                                <div className="col-12 col-lg-6 col-xl-4 mb-lg-3">
                                                                    <p className="mb-2"><strong>Item 3:</strong></p>
                                                                    <p className="mb-2"><strong>NYS Supplier Item:</strong> 6123355855</p>
                                                                    <p className="mb-2"><strong>Quantity:</strong> 5</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>;
};

export default WholesalerManageBrandsItems1View;

