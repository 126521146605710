import { SelectOption } from "components/form/Select";

export default class LicenseTypeConstants {

    static TYPES: SelectOption[] = [
        { value: "W", title: "Wholesaler" },
        { value: "R", title: "Retailer" }
    ];

    static getTitleByCode(code: string): string | undefined{
        return this.TYPES.find(c => c.value == code)?.title;
    }

    static isWholesaler(code: string | undefined): boolean {
        return code === "W";
    }
}
