import React, { useState } from "react";
import { FormCheck } from "react-bootstrap";
import { UseFormReturn } from "react-hook-form";
import Card from "react-bootstrap/Card";
import Spinner from "components/Spinner";
import { UserLicense, licenseClasses, licenseText } from "models/LoggedInUserInfo";
import GifTutorialImg from "assets/img/SwitchingBetweenLicenses.gif";

type LicenseSelectFormProps = {
    pending: boolean,
    onContinue: ()=>void,
    form: UseFormReturn<any>,
    list?: UserLicense[]
};

const LicenseSelectForm = ({ list, pending, form, onContinue }: LicenseSelectFormProps ) => {
    const [ selectedLicenseId, setSelectedLicenseId] = useState<number>();
    return <div>
        <div className="mb-4 mb-md-5 text-center">
            <h1 className="text-primary mb-4">Select License</h1>
            <p>You have multiple licenses associated with your account. Please select the license you wish to use for this session.</p>
            <ul className="list-unstyled license-select-txt mt-4">
                <li className="text-start mb-2"><h5 className="fw-bold">Switching Between Licenses</h5></li>
                <li className="text-start mb-2">Once you&apos;re logged into the system, effortlessly toggle between
                    your licenses. There&apos;s absolutely no need to log out and then log back in!
                </li>
                <li className="text-start mb-2 d-flex align-items-center pt-2">
                    <button className="license-select-link" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">Click on this text</button> to
                    expand for more information and a helpful GIF tutorial.
                </li>
                <li>
                    <div className="collapse" id="collapseExample">
                        <img className="d-block mx-auto img-fluid" src={ GifTutorialImg }
                            alt="Switching Between Licenses"/>
                    </div>
                </li>
            </ul>
        </div>
        <div className="row justify-content-center">
            <div className="col-12 col-lg-8 col-xl-7 col-xxl-5">
                <Card className={ "mx-auto" }>
                    <Card.Body>
                        <Card.Title className="fw-bold text-center mb-4">Choose Working License</Card.Title>

                        { pending ? <Spinner className="mb-3 text-center"/>
                            : <LicenseSelectControl setter={ setSelectedLicenseId } list={ list }/>
                        }
                    </Card.Body>
                </Card>
            </div>
        </div>
        { pending ||
            <div className="row justify-content-center mt-4 pt-3">
                <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                    <button className="btn btn-primary w-100" onClick={ ()=>{
                        form.setValue("license_id", selectedLicenseId);
                        onContinue();
                    } }>Continue</button>
                </div>
            </div>
        }
    </div>;
};
export default LicenseSelectForm;

export type LicenseSelectControlProps = {
    list?: UserLicense[],
    setter: (value: number)=>void
};

export const LicenseSelectControl = ({ list, setter: setSelectedLicenseId }: LicenseSelectControlProps)=> (
    <div>
        <ul className="list-unstyled license-select-l">
            {
                list?.map(l => (
                    <li key={ l.licenseId }>
                        <FormCheck name="license_id" type="radio" id={ `license-${l.licenseId}` }
                            label={ licenseText(l) } className={ licenseClasses(l) }
                            value={ l.licenseId }
                            onChange={ e => {
                                setSelectedLicenseId(parseInt(e.target.value));
                            } }/>
                    </li>
                ))
            }
        </ul>
    </div>
);

