import React from "react";
import { ControllerSelectProps, SelectControlled, SelectOption } from "components/form/Select";
import PackageTypeConstants from "services/PackageTypeConstants";

type SelectPackageTypeProps = Omit<ControllerSelectProps, "options"> & {
    filterValues?: string[],
    addAllOption?: boolean //true if unspecified
};

export const SelectPackageType = ({ filterValues, addAllOption, ...rest } : SelectPackageTypeProps) => {

    const options: SelectOption[] = PackageTypeConstants.filterByValue(filterValues);
    if (addAllOption === undefined || addAllOption) {
        options.unshift({ value: "", title: "All" });
    }

    return <SelectControlled options={ options } { ...rest } />;
};
