import React, { useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import LicenseInfo from "components/LicenseInfo";
import { showCreatePrincipalDialog } from "pages/admin/dialogs/CreatePrincipalDialog";
import Paginator from "components/Paginator";
import { PrincipalModel } from "models/PrincipalModel";
import URLUtil from "utils/URLUtil";
import { PageableRequest } from "models/requests/PageableRequest";
import { PageInfo } from "models/PageInfo";
import { ADMIN_PATHS } from "pages/admin/AdminRoutes";
import Spinner from "components/Spinner";
import PrincipalService from "services/admin/PrincipalService";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";
import { useToastStore } from "store/ToastStoreProvider";
import { showDeletePrincipalDialog } from "pages/admin/dialogs/DeletePrincipalDialog";
import ReturnToSearchLink from "pages/admin/components/ReturnToSearchLink";
import { showEditPrincipalDialog } from "pages/admin/dialogs/EditPrincipalDialog";
import { useAuthStore } from "store/AuthStoreProvider";

export const Principals = () => {
    const navigate = useNavigate();
    const { addToast } = useToastStore();
    const auth = useAuthStore();
    const currentLicenseIsActive = auth.currentLicense?.active;
    const { licenseId } = useParams();
    const licenseIdInt = parseInt(licenseId || "");
    const [principals, setPrincipals] = useState<PrincipalModel[]>([]);
    const [urlData, setUrlData] = useState<PageInfo>(URLUtil.readSearchParams() as PageInfo);
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(1);

    useEffect(() => {
        performQuery();
    }, []);

    useEffect(() => {
        URLUtil.updateUrlParams(generatePath(ADMIN_PATHS.LICENSES.PRINCIPALS, { licenseId: licenseIdInt }), urlData, navigate);
        performQuery();
    }, [urlData]);

    function errorMessage() {
        addToast({
            message: "Something went wrong. Please try again",
            header: "Manage Principals",
            variant: "danger",
            timeoutMs: DEFAULT_TOASTER_TIMEOUT
        });
    }

    function performQuery() {
        const request = {
            pageInfo: { page: urlData.page },
            filters: { license_id: licenseIdInt }
        } as PageableRequest;
        setIsDataLoading(true);
        PrincipalService.lookup(request).then(response => {
            setPrincipals(response.data);
            setPageNumber(response.pageInfo.page || 1);
            setPageCount(response.pageInfo.pagesCount || 1);
        }).catch(() => errorMessage()
        ).finally(() => setIsDataLoading(false));
    }

    function handlePageClick(pageNumber: number) {
        setPageNumber(pageNumber);
        setUrlData({ ...urlData, page: pageNumber });
    }

    return <div className="d-flex flex-column">
        <div className="mb-4 pb-md-3 d-flex flex-column flex-md-row align-items-center">
            <div className="order-0 order-md-1">
                <ReturnToSearchLink/>
            </div>
            <h1 className="text-primary mb-3 mb-md-0 mt-3 mt-md-0 me-md-auto order-1 order-md-0">Manage Principals</h1>
        </div>
        <LicenseInfo licenseId={ licenseIdInt } showDelinquentStatus={ true }/>
        <div className="mt-4">
            <button
                disabled={ !currentLicenseIsActive }
                className="btn btn-primary mb-4" title="Add new principal"
                onClick={ () => showCreatePrincipalDialog(licenseIdInt, performQuery) }>
                Add Principal
            </button>
            { isDataLoading && <Spinner className="mt-4 pt-2 text-center"/> }
            { !isDataLoading && principals.length == 0 &&
                <p>Principals not found.</p>
            }
            { !isDataLoading && principals.length != 0 &&
                <div className="table-responsive">
                    <table className="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th className="w-13">Name</th>
                                <th>ZIP</th>
                                <th>DOB</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            { principals.map(principal => {
                                return (
                                    <tr key={ principal.id }>
                                        <td>{ principal.full_name }</td>
                                        <td>{ principal.zip_code }</td>
                                        <td>{ principal.birthday }</td>
                                        <td className="text-nowrap">
                                            <button
                                                disabled={ !currentLicenseIsActive }
                                                className="btn btn-link py-0 px-1 me-2" title="Edit"
                                                onClick={ () => showEditPrincipalDialog(principal, performQuery) }>
                                                <i className="bi-gear"></i>
                                            </button>
                                            <button
                                                disabled={ !currentLicenseIsActive }
                                                className="btn btn-link py-0 px-1 me-2" title="Delete"
                                                onClick={ () => showDeletePrincipalDialog(principal, performQuery) }>
                                                <i className="bi-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                );
                            }) }
                        </tbody>
                    </table>
                    <Paginator totalPages={ pageCount } currentPage={ pageNumber } pageClicked={ handlePageClick } />
                </div>
            }
        </div>
    </div>;
};
