import { useParams } from "react-router-dom";
import { Page, RegComboType } from "pages/wholesaler/priceposting/pages/brands/components/Page";
import Spinner from "components/Spinner";

export const AddPage = ()=>{
    const { rcType, postType } = useParams<{
        rcType: RegComboType,
        postType: string
    }>();
    return rcType && postType
        ? <Page rcType={ rcType } postType={ postType } />
        : <Spinner/>;
};
