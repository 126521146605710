import React, { useEffect, useState } from "react";
import LicenseInfo from "components/LicenseInfo";
import { useParams } from "react-router-dom";
import SchedulesService from "services/admin/SchedulesService";
import Spinner from "components/Spinner";
import { CertifiedSchedule } from "pages/admin/components/CertifiedSchedule";
import ReturnToSearchLink from "pages/admin/components/ReturnToSearchLink";
import { useToastStore } from "store/ToastStoreProvider";
import { StringifiedDateTime } from "models/StringifiedDateTime";

export interface UpcomingScheduleModel {
    month: number,
    year: number,
    monthSchedules: MonthScheduleModel[]
}
interface MonthScheduleModel {
    postType:string,
    certifyCount: number,
    certifyDate?: StringifiedDateTime,
    confirmation: string
}

export const UpcomingSchedules = () => {
    const { licenseId = "" } = useParams();
    const licenseIdInt = parseInt(licenseId);
    const { addDefaultDangerToast } = useToastStore();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [upcomingSchedules, setUpcomingSchedules] = useState<UpcomingScheduleModel[]>([]);

    useEffect((()=> {
        setIsLoading(true);
        SchedulesService.getSchedules(licenseIdInt).then(response => {
            setUpcomingSchedules(response);
        }).catch(() => addDefaultDangerToast("Upcoming Schedules")
        ).finally(() => setIsLoading(false));
    }), []);

    return <div className="d-flex flex-column">
        <div className="mb-4 pb-md-3 d-flex flex-column flex-md-row align-items-center">
            <div className="order-0 order-md-1">
                <ReturnToSearchLink/>
            </div>
            <h1 className="text-primary mb-3 mb-md-0 mt-3 mt-md-0 me-md-auto order-1 order-md-0">
                Recent & Upcoming Schedules
            </h1>
        </div>
        <LicenseInfo licenseId={ licenseIdInt }/>
        <Spinner className="mt-4 pt-2 text-center" hidden={ !isLoading } />
        <div hidden={ isLoading }>
            { upcomingSchedules?.map((upcomingSchedule, index: number) => (
                <div className="mt-4" key={ index }>
                    <CertifiedSchedule upcomingSchedule={ upcomingSchedule } licenseId={ licenseIdInt }/>
                </div>
            )) }
        </div>
    </div>;
};
