import Input from "components/form/Input";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import URLUtil from "utils/URLUtil";
import { AvailabilityFormModel } from "pages/wholesaler/availability/models/AvailabilityFormModel";
import SelectPostingMonth from "pages/wholesaler/availability/components/SelectPostingMonth";
import { ControlledFormSwitch } from "components/form/ControlledFormSwitch";
import ProductConstants from "services/ProductConstants";
import { SelectControlled } from "components/form/Select";
import { useKeyDownEnterEvent } from "components/KeyDownHook";

interface Props {
    filters: AvailabilityFormModel;
    onSubmit: any;
}

export const AvailabilitySearchForm = ({ filters, onSubmit }: Props) => {

    const {
        handleSubmit,
        reset,
        control,
        setValue
    } = useForm<AvailabilityFormModel>({
        mode: "onSubmit",
        defaultValues: {
            post_type: "WR",
            post_date: 0,
            lim_avail: ProductConstants.enumLimAvail.LIMITED_AVAILABILITY
        }
    });
    useKeyDownEnterEvent(() => { handleSubmit(onSubmit)(); });

    useEffect(() => { URLUtil.setValuesFromUrl(filters, setValue); }, [filters]);

    return <form onSubmit={ handleSubmit(onSubmit) }>
        <div className="row">
            <div className="col-12 col-lg-6 col-xl-4">
                <div className="pe-lg-4">
                    <Input name="brand_name" control={ control } placeholder="Enter brand name" label="Brand name" type="text" />
                </div>
            </div>
            <div className="col-12 col-lg-6 col-xl-4">
                <div className="ps-lg-4 px-xl-2">
                    <Input name="prod_name" control={ control } placeholder="Enter item description" label="Item description" type="text" />
                </div>
            </div>
            <div className="col-12 col-lg-6 col-xl-4">
                <div className="pe-lg-4 ps-xl-4 pe-xl-0">
                    <Input name="prod_item" control={ control } placeholder="Enter item number" label="Item number" type="text" />
                </div>
            </div>
            <div className="col-12 col-lg-6 col-xl-4">
                <div className="ps-lg-4 ps-xl-0 pe-xl-4">
                    <Input name="brand_reg" control={ control } placeholder="Enter approval or BLR number" label="Approval or BLR number" type="text" />
                </div>
            </div>
            <div className="col-12 col-lg-6 col-xl-4">
                <div className="pe-lg-4 px-xl-2">
                    <Input name="ttb_id" control={ control } placeholder="Enter TTB / CoLA ID number" label="TTB / CoLA ID number" type="text"/>
                </div>
            </div>
            <div className="col-12 col-lg-6 col-xl-4">
                <div className="ps-lg-4 pt-2 mt-lg-4 mb-3">
                    <table>
                        <tr>
                            <td>All&nbsp;</td>
                            <td>
                                <ControlledFormSwitch
                                    control={ control } name="lim_avail" label="Limited Availability" id="custom-switch"
                                    options={ [ProductConstants.enumLimAvail.LIMITED_AVAILABILITY,ProductConstants.enumLimAvail.BLANK] } />
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div className="col-12 col-lg-6 col-xl-4">
                <div className="pe-lg-4">
                    <SelectControlled label="Posting type" control={ control } name="post_type"
                        options={ [ ProductConstants.POST_TYPES.WR.toSelectOption(), ProductConstants.POST_TYPES.LR.toSelectOption() ] } />
                </div>
            </div>
            <div className="col-12 col-lg-6 col-xl-4">
                <div className="ps-lg-4 px-xl-2">
                    <SelectPostingMonth control={ control } name="post_date" />
                </div>
            </div>
        </div>
        <div className="row justify-content-center mt-2">
            <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                <div className="pe-md-1">
                    <input type="button" className="btn btn-outline-secondary w-100" onClick={ () => reset() } value="Clear Form"/>
                </div>
            </div>
            <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                <div className="ps-md-1">
                    <input type="submit" className="btn btn-primary w-100" value="Filter"/>
                </div>
            </div>
        </div>
    </form>;
};
