import React, { useEffect, useState } from "react";
import { LicenseModel } from "models/license.model";
import { PageInfo } from "models/PageInfo";
import URLUtil from "utils/URLUtil";
import Paginator from "components/Paginator";
import Spinner from "components/Spinner";
import { PageableRequest } from "models/requests/PageableRequest";
import { LicenseGroupModel } from "pages/admin/models/LicenseGroupModel";
import { showEditLicenseGroupDialog } from "pages/admin/dialogs/EditLicenseGroupDialog";
import { showDeleteLicenseGroupDialog } from "pages/admin/dialogs/DeleteLicenseGroupDialog";
import { useToastStore } from "store/ToastStoreProvider";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";
import LicenseSearchForm, { LicenseParamModel } from "pages/admin/components/LicenseSearchForm";
import { LicenseGroupService } from "services/admin/LicenseGroupService";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { showLicenseDelinquentInfo } from "components/dialogs/LicenseDelinquentInfo";
import { useAuthStore } from "store/AuthStoreProvider";

type MasterFilerRequest = LicenseParamModel & PageInfo;

type LicenseGroupsPageProps ={
    service: LicenseGroupService,
    url: string,
    entityTitle: string,
}

export const LicenseGroupsPage = ({ service, url, entityTitle }:LicenseGroupsPageProps) => {
    const { addToast } = useToastStore();
    const navigate = useNavigate();
    const auth = useAuthStore();
    const currentLicenseIsActive = auth.currentLicense?.active;
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [urlData, setUrlData] = useState<MasterFilerRequest>(URLUtil.readSearchParams() as MasterFilerRequest);
    const [pageCount, setPageCount] = useState<number>(1);
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [models, setModels] = useState<LicenseGroupModel[]>([]);
    const [isPresent, setIsPresent] = useState<boolean>(false);

    useEffect(() => {
        service.isPresent().then(setIsPresent);

        performQuery();
    }, []);

    useEffect(() => {
        URLUtil.updateUrlParams(url, urlData, navigate);
        performQuery();
    }, [urlData]);

    const onSubmitForm = (data: LicenseParamModel) => {
        setUrlData({
            license: data.license,
            premise_or_trade_name: data.premise_or_trade_name?.trim(),
            page: 1
        });
    };

    const performQuery = () => {
        const request = {
            pageInfo: { page: urlData.page },
            filters:  URLUtil.pureUrlParameters(urlData)
        } as PageableRequest;

        setIsDataLoading(true);
        service.lookup(request).then(response => {
            setModels(response.data || []);
            setPageCount(response.pageInfo.pagesCount || 1);
            setTotalCount(response.pageInfo.totalItemsCount || 0);
        }).finally(() => setIsDataLoading(false));
    };

    function handlePageClick(pageNumber: number) {
        setPageNumber(pageNumber);
        setUrlData({ ...urlData, page: pageNumber });
    }

    function onDelete(isDeleted: boolean) {
        if (isDeleted) {
            addToast({
                header: entityTitle,
                message:`${entityTitle} was removed successfully`,
                variant: "success",
                timeoutMs: DEFAULT_TOASTER_TIMEOUT
            });
            performQuery();
        } else {
            addToast({
                header: entityTitle,
                message:`${entityTitle} was not removed. Something went wrong.`,
                variant: "danger",
                timeoutMs: DEFAULT_TOASTER_TIMEOUT
            });
        }
    }

    function openCreateDialog() {
        showEditLicenseGroupDialog({} as LicenseGroupModel, () => performQuery(), service, entityTitle);
    }

    function openEditDialog(model: LicenseGroupModel) {
        showEditLicenseGroupDialog({
            parentLicense: model.parentLicense,
            childLicenses: model.childLicenses.map( l => { return ({ ...l }); })
        }, () => performQuery(), service, entityTitle);
    }

    return  <div className="d-flex flex-column">
        <div className="mb-4 mb-md-5">
            <h1 className="text-primary mb-0">{ entityTitle }s</h1>
        </div>

        { isDataLoading && <Spinner className="mt-4 pt-2 text-center"/> }

        { !isDataLoading && !isPresent &&
            <div>
                <div className="card">
                    <div className="card-body pb-1">
                        <div className="text-center">
                            <p>Looks like you don&apos;t have any { entityTitle }s. You can create your first one by clicking button below:</p>
                            <div className="form-group mt-4">
                                <button
                                    disabled={ !currentLicenseIsActive }
                                    className="btn btn-primary" title={ `Create ${entityTitle}` }
                                    onClick={ () => openCreateDialog() } type="button">
                                    Create { entityTitle }</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        { !isDataLoading && isPresent &&
            <div>
                <LicenseSearchForm
                    license={ urlData.license }
                    premise_or_trade_name={ urlData.premise_or_trade_name }
                    onSubmitForm={ onSubmitForm }
                    isCentral={ true }
                />

                <div className="mt-3 mb-2 d-flex flex-column flex-lg-row align-items-end">
                    <div className="me-lg-auto pt-3">
                        <h5 className="mb-0">Found <strong>{ totalCount } { entityTitle }s</strong> matching search criteria</h5>
                    </div>
                    <div className="pt-3 ms-lg-3">
                        <button
                            disabled={ !currentLicenseIsActive }
                            className="btn btn-primary" title={ `Create ${entityTitle}` }
                            onClick={ () => openCreateDialog() } type="button"
                        >Create { entityTitle }</button>
                    </div>
                </div>
                <div className="table-responsive mt-4">
                    <table className="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th>
                                    <div className="text-nowrap d-flex flex-row">
                                        Master License
                                        <div className="tooltip-wr tooltip-wr-small ms-2">
                                            <span className="tooltip-icon bi-question-circle-fill"></span>
                                            <div className="tooltip-msg">
                                                <p>Current visible license format depends on the information we have on file.</p>
                                                <p>If a unique &lsquo;License ID&rsquo; has been provided for the license, you will see this displayed. In the absence of a License ID, we instead display the &lsquo;Serial Number&rsquo;.</p>
                                            </div>
                                        </div>
                                    </div>
                                </th>
                                <th>Premise Name</th>
                                <th>Address</th>
                                <th>
                                    <div className="text-nowrap d-flex flex-row">
                                        Secondary License(s)
                                        <div className="tooltip-wr tooltip-wr-small ms-2">
                                            <span className="tooltip-icon bi-question-circle-fill"></span>
                                            <div className="tooltip-msg">
                                                <p>Current visible license format depends on the information we have on file.</p>
                                                <p>If a unique &lsquo;License ID&rsquo; has been provided for the license, you will see this displayed. In the absence of a License ID, we instead display the &lsquo;Serial Number&rsquo;.</p>
                                            </div>
                                        </div>
                                    </div>
                                </th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                models.map((model, index) => {
                                    return (
                                        <tr key={ index }>
                                            <td>
                                                <button className="btn btn-link"
                                                    onClick={ () => showLicenseDelinquentInfo(model.parentLicense.id) }>
                                                    { model.parentLicense?.permit_id ? model.parentLicense?.permit_id : model.parentLicense?.serial_number }
                                                </button>
                                            </td>
                                            <td>{ model.parentLicense.premise_name }</td>
                                            <td>{ model.parentLicense.premise_address }</td>
                                            <td>
                                                <ul className="table-list">
                                                    {
                                                        model.childLicenses.map((slaveLicense: LicenseModel, index: number) => {
                                                            return (
                                                                <li className = { classNames(slaveLicense.status == "I"? "text-danger" : "" ) } key={ index }>
                                                                    <strong>{ slaveLicense.permit_id || slaveLicense.serial_number }:</strong> { slaveLicense.premise_name }, { slaveLicense.premise_address }
                                                                </li>
                                                            );
                                                        })
                                                    }
                                                </ul>
                                            </td>
                                            <td className="text-nowrap">
                                                <button type="button"
                                                    disabled={ !currentLicenseIsActive }
                                                    className="btn btn-link py-0 px-1 me-2" title="Edit"
                                                    onClick={ () => { openEditDialog(model); } }
                                                ><i className="bi-pencil"/></button>
                                                <button type="button"
                                                    disabled={ !currentLicenseIsActive }
                                                    className="btn btn-link py-0 px-1" title="Delete"
                                                    onClick={ () => showDeleteLicenseGroupDialog(model.parentLicense.id, model.parentLicense.permit_id, onDelete, service, entityTitle) }
                                                ><i className="bi-trash"/></button>
                                            </td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <Paginator totalPages={ pageCount } currentPage={ pageNumber } pageClicked={ handlePageClick }/>
            </div>
        }
    </div>;
};
