import { action, makeObservable, observable } from "mobx";
import { PageableRequest, pageableRequestToQueryString } from "models/requests/PageableRequest";

export class UtilStore {

    @observable
    public adminReturnParams = "";

    constructor() {
        makeObservable(this);
    }

    @action
    public setAdminReturnParams(request: PageableRequest) {
        this.adminReturnParams = "?" + pageableRequestToQueryString(request);
    }
}
