
export interface Role {
    name: string;
    category?: string;
    title?: string;
    description?: string;
}

export enum RolesEnum {

    SLA_ADMIN = "SLA_ADMIN",
    SLA_READ_ONLY = "SLA_READ_ONLY",
    SLA_COD = "SLA_COD",

    ADMIN = "ADMIN",
    BUSINESS_ADMIN = "BUSINESS_ADMIN",
    RETAILER = "RETAILER",
    WHOLESALER = "WHOLESALER",

    PRICES_READ = "PRICES_READ",
    PRICES_WRITE = "PRICES_WRITE",
    PRICES_CERTIFY = "PRICES_CERTIFY",

    COD_READ = "COD_READ",
    COD_WRITE = "COD_WRITE",
    COD_API = "COD_API",
    COD_BATCH_UPLOAD = "COD_BATCH_UPLOAD"
}
