import React, { useEffect, useState } from "react";
import SearchInput from "components/SearchInput";
import { WHOLESALER_PATHS } from "pages/wholesaler/WholesalerRoutes";
import { Link, generatePath, useNavigate } from "react-router-dom";
import { PageInfo } from "models/PageInfo";
import URLUtil from "utils/URLUtil";
import { LetterModel } from "models/LetterModel";
import { PageableRequest } from "models/requests/PageableRequest";
import LetterService from "services/wholesaler/LetterService";
import ProductConstants from "services/ProductConstants";
import DateUtil from "utils/DateUtil";
import Paginator from "components/Paginator";
import Spinner from "components/Spinner";
import Button from "react-bootstrap/Button";
import { useModalStore } from "store/ModalStoreProvider";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";
import { useToastStore } from "store/ToastStoreProvider";
import { useAuthStore } from "store/AuthStoreProvider";

type SearchParams = { query: string} & PageInfo;
const urlData = URLUtil.readSearchParams() as SearchParams;

const SearchForm = () => {
    const navigate = useNavigate();
    const auth = useAuthStore();
    const { permissionStore } = auth;
    const currentLicenseIsActive = auth.currentLicense?.active;
    const [letters, setLetters] = useState<LetterModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(0);
    const modalStore = useModalStore();
    const { addToast, addDefaultDangerToast } = useToastStore();

    useEffect(() => {
        performQuery(urlData);
    }, []);

    function handlePageClick(pageNumber: number) {
        urlData.page = pageNumber;
        URLUtil.updateUrlParams(WHOLESALER_PATHS.APPOINTMENT.LETTERS, urlData, navigate);
        performQuery(urlData);
    }

    function handleTableSearch(value: string) {
        urlData.query = value;
        urlData.page = 1;
        URLUtil.updateUrlParams(WHOLESALER_PATHS.APPOINTMENT.LETTERS, urlData, navigate);
        performQuery(urlData);
    }

    function performQuery(data: SearchParams) {
        const request = {
            pageInfo: { page: data.page },
            filters: data
        } as PageableRequest;

        setIsLoading(true);
        LetterService.search(request).then(response => {
            setLetters(response.data || []);
            setPageCount(response.pageInfo.pagesCount || 1);
            setPageNumber(response.pageInfo.page || 1);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    const link = <button
        onClick={ () => navigate(WHOLESALER_PATHS.APPOINTMENT.CREATE_LETTER) }
        className="btn btn-primary"
        title="Add New Appointment Letter"
        disabled={ !permissionStore.isWholesalerAndPricesCertifyOrWrite() }
    >Add New Appointment Letter</button>;

    function deleteLetter(id: number | undefined) {
        if (id == undefined) return;
        LetterService.delete(id).then(() => {
            performQuery(urlData);
            addToast({ message:"Letter deleted successfully.", header: "Delete letter", variant: "success", timeoutMs: DEFAULT_TOASTER_TIMEOUT });
        }).catch(()=>{
            addDefaultDangerToast("Delete letter");
        }).finally(()=>modalStore.hideModal());
    }

    function deleteLetterConfirmation(letter: LetterModel) {
        modalStore.setHeader("Remove Appointment Letter");
        modalStore.setBody(<p className="mb-0">
            You are going to delete <strong>{ letter.description }</strong> appointment letter. Are you sure?</p>);
        modalStore.setAdditionalButtons([
            <button key={ 0 } type="button" className="btn btn-primary" onClick={ ()=> deleteLetter(letter.id) }>Yes</button>
        ]);
        modalStore.showModal();
    }

    return <div className="d-flex flex-column">
        <div>
            <div className="card">
                <div className="card-body pb-1">
                    <div className="d-flex flex-column flex-xl-row justify-content-between">
                        <div className="form-group">
                            { link }
                        </div>
                        <div className="ms-md-auto mt-2 mt-lg-0 search-app-letter">
                            <SearchInput
                                value={ urlData.query ? urlData.query : "" }
                                placeholder="Search by Letter Description"
                                onSearch={ handleTableSearch }
                            />
                        </div>
                    </div>
                </div>
            </div>
            { letters.length !== 0 && !isLoading &&
                <div className="table-responsive mt-4">
                    <table className="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th>Schedule</th>
                                <th>Letter Description</th>
                                <th>File</th>
                                <th>Date Added</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            { letters.map((letter: LetterModel, index: number) => {
                                return (
                                    <tr key={ index }>
                                        <td>{ ProductConstants.WHOLESALER_POST_TYPES.find(t => t.code === letter.post_type)?.description }</td>
                                        <td>{ letter.description }</td>
                                        <td>
                                            <Link to={ LetterService.downloadFile(letter.storage_path, letter.file_name) } target="_blank" download>
                                                { letter.file_name }
                                            </Link>
                                        </td>
                                        <td>{ DateUtil.formatWithServerTZ(letter.created_at) }</td>
                                        <td>
                                            <button
                                                onClick={ () => navigate(generatePath(WHOLESALER_PATHS.APPOINTMENT.UPDATE_LETTER, { "id": letter.id })) }
                                                className="btn btn-link py-0 px-1"
                                                title="Edit letter"
                                                disabled={ !permissionStore.isWholesalerAndPricesCertifyOrWrite() || !currentLicenseIsActive }
                                            >
                                                <i className="bi-pencil"/>
                                            </button>
                                            <Button
                                                className="btn btn-link py-0 px-1 me-2"
                                                title="Delete letter"
                                                onClick={ () => deleteLetterConfirmation(letter) }
                                                disabled={ !permissionStore.isWholesalerAndPricesCertifyOrWrite() || !currentLicenseIsActive }
                                            >
                                                <i className="bi-trash"/>
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            }) }
                        </tbody>
                    </table>
                    <Paginator totalPages={ pageCount } currentPage={ pageNumber } pageClicked={ handlePageClick }/>
                </div>
            }
            <Spinner hidden={ !isLoading } className="mt-4 text-center" />
            { letters.length === 0 && !isLoading &&
                <div className="text-center">
                    <p>Results not found. Please check your searching criteria.</p>
                </div>
            }
        </div>
    </div>;
};

export default SearchForm;

