import Records from "pages/retailer/delinquent/Records";
import { MenuSettings, useLeftMenu } from "components/menu/Menu";
import { useAuthStore } from "store/AuthStoreProvider";
import { useTimeStore } from "store/TimeStoreProvider";
import { Route, Routes } from "react-router-dom";
import React from "react";
import AuthenticatedRoute from "components/AuthenticatedRoute";
import ProtectedRoute from "components/ProtectedRoute";
import NotFound from "pages/NotFound";
import LicenseUsers from "components/LicenseUsers";
import { PriceLookup } from "components/PriceLookup";
import { PriceLookupNext } from "components/PriceLookupNext";
import { PermissionStore } from "store/PermissionStore";
import { Dayjs } from "dayjs";

const RELATIVE_PATHS = {
    MAIN: "/retailer",
    PRICE_LOOKUP: "/prices/lookup",
    PRICE_LOOKUP_NEXT: "/prices/next-lookup",
    PRICE_LOOKUP_DISTRIBUTOR_INFO: "/prices/distributor/:licenseNumber",
    DELINQUENT_VIEW: "/delinquent/view",
    USER: {
        MANAGEMENT: "/user/management"
    }
};

export const RETAILER_PATHS = {
    MAIN: RELATIVE_PATHS.MAIN + "/*",
    PRICE_LOOKUP: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.PRICE_LOOKUP,
    PRICE_LOOKUP_NEXT: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.PRICE_LOOKUP_NEXT,
    PRICE_LOOKUP_DISTRIBUTOR_INFO: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.PRICE_LOOKUP_DISTRIBUTOR_INFO,
    DELINQUENT_VIEW: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.DELINQUENT_VIEW,
    USER: {
        MANAGEMENT: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.USER.MANAGEMENT
    }
};

export const LEFT_MENU = (permissionStore: PermissionStore, newYorkTime: Dayjs ): MenuSettings => ({
    caption: "Retailer Menu",
    items: [
        {
            title: "Pricing for " + newYorkTime.format("MMMM YYYY"),
            link: RETAILER_PATHS.PRICE_LOOKUP,
            predicate: () => permissionStore.isRetailerAndPriceRead()
        },
        {
            title: "Pricing for " + newYorkTime.add(1,"month").format("MMMM YYYY"),
            link: RETAILER_PATHS.PRICE_LOOKUP_NEXT,
            predicate: () => newYorkTime.date() >= 15 && permissionStore.isRetailerAndPriceRead()
        },
        {
            title: "Delinquent Data",
            link: RETAILER_PATHS.DELINQUENT_VIEW,
            predicate: () => permissionStore.isRetailerAndCodRead()
        },
        {
            title: "Settings",
            link: RETAILER_PATHS.USER.MANAGEMENT,
            predicate: () => permissionStore.isBusinessAdmin,
            submenu:[
                {
                    title: "User Management",
                    link: RETAILER_PATHS.USER.MANAGEMENT
                }
            ]
        }
    ]
});

const RetailerRoutes = () => {

    const { permissionStore } = useAuthStore();
    const { newYorkTime } = useTimeStore();

    useLeftMenu(LEFT_MENU(permissionStore, newYorkTime));
    return <Routes>
        <Route element={ <AuthenticatedRoute/> }>
            <Route element={ <ProtectedRoute isAllowed={ permissionStore.isRetailerAdmin() }/> }>
                <Route path={ RELATIVE_PATHS.USER.MANAGEMENT } element={ <LicenseUsers/> }/>
            </Route>
            <Route element={ <ProtectedRoute isAllowed={ permissionStore.isRetailerAndPriceRead() }/> }>
                <Route path={ RELATIVE_PATHS.PRICE_LOOKUP } element={ <PriceLookup/> }/>
            </Route>
            <Route element={ <ProtectedRoute isAllowed={ newYorkTime.date() >= 15 && permissionStore.isRetailerAndPriceRead() }/> }>
                <Route path={ RELATIVE_PATHS.PRICE_LOOKUP_NEXT } element={ <PriceLookupNext/> }/>
            </Route>
            <Route element={ <ProtectedRoute isAllowed={ permissionStore.isRetailerAndCodRead() }/> }>
                <Route path={ RELATIVE_PATHS.DELINQUENT_VIEW } element={ <Records/> }/>
            </Route>
        </Route>
        <Route path={ "*" } element={ <NotFound/> }/>
    </Routes>;
};

export default RetailerRoutes;
