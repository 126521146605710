import httpClient from "api/HttpClient";
import BACKEND from "services/Constants";
import { Dayjs } from "dayjs";
import { CountModel, Type } from "pages/admin/Reports";

const ROOT_PATH = `${BACKEND}/admin/report`;

export default {

    getCounts(date: Dayjs): Promise<CountModel> {
        return httpClient.get(`${ROOT_PATH}/get-counts`,
            { params: { month: date.month() + 1, year: date.year() } });
    },

    getDownloadUrl(type: Type, postType: string, date: Dayjs): string {
        return `${ROOT_PATH}/generate-report?type=${type}&postType=${postType}&month=${(date.month() + 1)}&year=${date.year()}`;
    }
};
