import { action, makeObservable, observable } from "mobx";
import { Variant } from "react-bootstrap/types";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";

export type ToastDescription = {
    message: string | JSX.Element,
    header: string,
    variant: Variant,
    timeoutMs?: number//if specified, auto-hide after specified timeout. Otherwise, toast can be closed only manually
}

export type ToastMap = Map<number,ToastDescription>;

export class ToastStore {

    @observable
    public toasts: ToastMap = observable.map();// new Map();

    constructor() {
        makeObservable(this);
    }

    @action.bound
    public addToast(description: ToastDescription) {
        const key = new Date().getTime();
        this.toasts.set(key, description);

        if (description.timeoutMs)
            setTimeout(this.deleteToast, description.timeoutMs, key);
    }

    @action.bound
    public addDefaultDangerToast(header: string) {
        this.addToast ({ message:"Something went wrong. Please try again", header: header, variant: "danger", timeoutMs: DEFAULT_TOASTER_TIMEOUT });
    }

    @action.bound
    public deleteToast(key: number){
        this.toasts.delete(key);
    }
}
