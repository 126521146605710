import { Route, Routes } from "react-router-dom";
import PricesLookupView, { PUBLIC_PRICES } from "pages/prices/PricesLookupView";
import RetailerRoutes, { RETAILER_PATHS } from "pages/retailer/RetailerRoutes";
import WholesalerRoutes, { WHOLESALER_PATHS } from "pages/wholesaler/WholesalerRoutes";
import AdminRoutes, { ADMIN_PATHS } from "pages/admin/AdminRoutes";
import StaticRoutes, { STATIC_PATHS } from "pages/statics/StaticRoutes";
import NotFound from "pages/NotFound";
import UserRoutes, { USER_PATHS } from "pages/user/UserRoutes";
import AuthRoutes, { AUTH_PATHS } from "pages/auth/AuthRoutes";
import { ROOT_PATHS } from "index";

const AppRoutes = () => {
    return <Routes>
        <Route path={ AUTH_PATHS.MAIN } element={ <AuthRoutes/> }/>
        <Route path={ WHOLESALER_PATHS.MAIN } element={ <WholesalerRoutes/> }/>
        <Route path={ PUBLIC_PRICES.LOOKUP + "/*" } element={ <PricesLookupView/> }/>
        <Route path={ RETAILER_PATHS.MAIN } element={ <RetailerRoutes/> }/>
        <Route path={ ADMIN_PATHS.MAIN } element={ <AdminRoutes/> }/>
        <Route path={ STATIC_PATHS.MAIN } element={ <StaticRoutes/> }/>
        <Route path={ ROOT_PATHS.NOT_FOUND } element={ <NotFound/> }/>
        <Route path={ USER_PATHS.MAIN } element={ <UserRoutes/> }/>
        <Route path={ "*" } element={ <NotFound/> }/>
    </Routes>;
};

export default AppRoutes;
