import { FC, PropsWithChildren, createContext, useContext } from "react";
import { AuthStore } from "store/AuthStore";
import { LoggedInUserInfo } from "models/LoggedInUserInfo";

type AuthStoreProviderProps = PropsWithChildren<any>  & { loggedInUserData?: LoggedInUserInfo }

const AuthStoreContext = createContext<null | AuthStore>(null);

const authStore = new AuthStore();

const AuthStoreProvider: FC<AuthStoreProviderProps> = ({ children, loggedInUserData }) => {
    authStore.setAuthorizedSilently(loggedInUserData);

    return(
        <AuthStoreContext.Provider value={ authStore }>
            { children }
        </AuthStoreContext.Provider>
    );
};

export function useAuthStore(): AuthStore {
    const store = useContext(AuthStoreContext);
    if (store === null) {
        throw new Error("Store cannot be null, please add a context provider");
    }
    return store;
}

export { AuthStoreProvider };
