import React, { useEffect, useState } from "react";
import { ControllerSelectProps, SelectControlled, SelectOption } from "components/form/Select";
import { useTimeStore } from "store/TimeStoreProvider";
import { DatePostCountModel } from "pages/admin/TransferScheduleData";
import DateUtil from "utils/DateUtil";

export type SelectProductTypeProps = Omit<ControllerSelectProps, "options"> & {
    counts?: DatePostCountModel[]
};

const SelectTransferMonth = (props : SelectProductTypeProps) => {
    const { newYorkTime } = useTimeStore();
    const [options, setOptions] = useState<SelectOption[]>([]);
    useEffect(() => {
        const opts: SelectOption[] = [];

        props.counts && props.counts.forEach(value => {
            const date = newYorkTime.add(value.month, "M");
            opts.push({ value: value.month, title: DateUtil.monthNumberToName(date.month()) + " " + date.year() });
        });
        if (!props.counts) {
            for (let i = 0; i <= 3; i++) {
                const date = newYorkTime.add(i, "M");
                opts.push({ value: i, title: DateUtil.monthNumberToName(date.month()) + " " + date.year() });
            }
        }
        opts.unshift({ value: "", title: "Select month" });
        setOptions(opts);
    }, [props.counts]);

    return <SelectControlled label="Period" options={ options } { ...props } />;
};

export default SelectTransferMonth;
