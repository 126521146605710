import { FC, PropsWithChildren, createContext, useContext } from "react";
import { ShowHideStore } from "store/ShowHideStore";

const StoreContext = createContext<null | ShowHideStore>(null);

const store = new ShowHideStore();

const ShowHideStoreProvider: FC<PropsWithChildren<any>> = ({ children }) => {
    return(
        <StoreContext.Provider value={ store }>
            { children }
        </StoreContext.Provider>
    );
};

export function useShowHideStore(): ShowHideStore {
    const store = useContext(StoreContext);
    if (store === null) {
        throw new Error("Store cannot be null, please add a context provider");
    }
    return store;
}

export { ShowHideStoreProvider };
