import Input from "components/form/Input";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import WholesalerLicenseSearchService from "services/wholesaler/WholesalerLicenseSearchService";
import { AddNewDelinquentModel } from "models/AddNewDelinquentModel";
import WholesalerDelinquentService from "services/wholesaler/WholesalerDelinquentService";
import CurrencyInput from "components/form/CurrencyInput";
import FormUtil from "utils/FormUtil";
import { AddNewDelinquentRequest } from "models/requests/AddNewDelinquentRequest";
import DatePickerInput from "components/form/DatePickerInput";
import { modalStore } from "store/ModalStoreProvider";
import TextArea from "components/form/TextArea";
import dayjs from "dayjs";
import { LICENSE_STATUSES } from "components/LicenseStatusCell";
import { LicenseModel } from "models/license.model";
import { useTimeStore } from "store/TimeStoreProvider";

interface Props {
    onSave: ()=>void,
    onError: ()=>void,
    disabled?: boolean
}

export const showNewDelinquencyDialog = (onSave: ()=>void, onError: ()=>void) => {
    modalStore.setSize("xl");
    modalStore.setBody(<NewDelinquencyDialog onSave={ onSave } onError={ onError } />);
    modalStore.setHeader("Add delinquency record");
    modalStore.showModal();
};

const NewDelinquencyDialog = ({ onSave, onError }: Props) => {
    const { newYorkTime } = useTimeStore();
    const [isUnknownError, setIsUnknownError] = useState<string | undefined>(undefined);
    const [licenseData, setLicenseData] = useState<LicenseModel>();
    const [addDisabled, setAddDisabled] = useState(false);

    useEffect(() => {
        reset();
        resetLicenseData();
        setValue("deliveryDate", new Date());
        setIsUnknownError(undefined);
    }, []);

    const {
        handleSubmit,
        control,
        getValues,
        setValue,
        reset,
        setError,
        clearErrors,
        formState: { errors }
    } = useForm<AddNewDelinquentModel>({
        mode: "onChange"
    });

    function resetLicenseData() {
        setLicenseData(undefined);
    }

    function searchLicense() {
        clearErrors("license");
        setIsUnknownError(undefined);
        resetLicenseData();
        setValue("license",getValues("license").trim());
        WholesalerLicenseSearchService.searchSingleLicense(getValues("license").trim())
            .then(setLicenseData)
            .catch(()=>{
                setError("license", {
                    type: "manual",
                    message: "Entered license number does not exist."
                });
            });
    }

    const onSubmit: SubmitHandler<AddNewDelinquentModel> = async data => {
        clearErrors();
        setIsUnknownError(undefined);
        setAddDisabled(true);

        const request = {
            license: data.license,
            deliveryDate: dayjs(data.deliveryDate).format("MM/DD/YYYY"),//TODO find the way to get raw string from Datepicker
            deliveryAmount: data.deliveryAmount,
            comment: data.comment
        } as AddNewDelinquentRequest;

        WholesalerDelinquentService.addDelinquent(request)
            .then (() => {
                modalStore.hideModal();
                onSave();
            })
            .catch(e => {
                if (e.status == 422){
                    FormUtil.handleValidationError(e.data,setError, setIsUnknownError);
                }
                else {
                    setIsUnknownError("Something went wrong. Please try again");
                    onError();
                }
            })
            .finally(()=>setAddDisabled(false));
    };

    modalStore.setAdditionalButtons([
        <button key={ 0 } type="button" className="btn btn-primary" onClick={ handleSubmit(onSubmit) } disabled={ addDisabled }>Add</button>
    ]);

    return <div>
        { isUnknownError &&
            <div className="invalid-message alert alert-sm alert-danger mb-4">
                <p className="mb-0">{ isUnknownError }</p>
            </div>
        }
        <div className="row">
            <div className="col-12 col-xl-6">
                <div className="pe-xl-4">
                    <div className="mb-4">
                        <h5 className="fw-bold mb-3">Premises</h5>
                        <hr/>
                    </div>
                    <div className="d-flex flex-row">
                        <div className="flex-fill">
                            <Input
                                name="license"
                                placeholder="Enter license id or serial number"
                                label="License ID or Serial number"
                                control={ control }
                                errors={ errors }
                                type="text"
                                required
                            />
                        </div>
                        <div className="mb-1">
                            <p className="form-label">&nbsp;</p>
                            <input
                                className="btn btn-outline-primary mb-3 ms-3"
                                type="button"
                                onClick={ searchLicense }
                                value="Search"
                            />
                        </div>
                    </div>
                    <div className="mb-2">
                        <p className="mb-0"><strong>Premise Name</strong></p>
                        <p className="mb-0">{ licenseData?.premise_name }</p>
                    </div>
                    <div className="mb-2">
                        <p className="mb-0"><strong>Trade Name</strong></p>
                        <p className="mb-0">{ licenseData?.dba }</p>
                    </div>
                    <div className="mb-2">
                        <p className="mb-0"><strong>License class</strong></p>
                        <p className="mb-0">{ licenseData?.type.class }</p>
                    </div>
                    <div className="mb-2">
                        <p className="mb-0"><strong>License type</strong></p>
                        <p className="mb-0">{  licenseData?.type.type }</p>
                    </div>
                    <div className="mb-2">
                        <p className="mb-0"><strong>Address</strong></p>
                        <p className="mb-0">{  licenseData?.premise_address } {  licenseData?.premise_address2 }</p>
                    </div>
                    <div className="mb-2">
                        <p className="mb-0"><strong>County</strong></p>
                        <p className="mb-0">{ licenseData?.county }</p>
                    </div>
                    <div className="mb-2">
                        <p className="mb-0"><strong>License status</strong></p>
                        <p className="mb-0">{ LICENSE_STATUSES.get(licenseData?.status || "")?.title }</p>
                    </div>
                </div>
            </div>
            <div className="col-12 col-xl-6 mt-4 mt-xl-0">
                <div className="ps-xl-4">
                    <div className="mb-4">
                        <h5 className="fw-bold mb-3">Delinquency Information</h5>
                        <hr/>
                    </div>
                    <CurrencyInput
                        name="deliveryAmount"
                        label="Delivery Amount *"
                        control={ control }
                        errors={ errors }
                        rules={ FormUtil.REQUIRED_FIELD }
                    />
                    <DatePickerInput
                        name="deliveryDate"
                        control={ control }
                        errors={ errors }
                        label="Delivery Date *"
                        maxDate={ newYorkTime }
                        rules={ FormUtil.REQUIRED_FIELD }
                    />
                    <TextArea
                        name="comment" control={ control } errors={ errors } label="Comment / Reference"
                        placeholder="Enter comment / reference" rows={ 5 } rules={ FormUtil.maxLengthRule(200) }/>
                </div>
            </div>
        </div>
    </div>;
};
