import React from "react";
import { MenuSettings, useLeftMenu } from "components/menu/Menu";
import WholesalerPriceLoginView from "pages/statics/wholesaler/WholesalerLoginView";
import WholesalerPricePostingView from "pages/statics/wholesaler/WholesalerPricePostingView";
import WholesalerCertifyView from "pages/statics/wholesaler/WholesalerCertifyView";
import WholesalerCertify2View from "pages/statics/wholesaler/WholesalerCertify2View";
import WholesalerCertify3View from "pages/statics/wholesaler/WholesalerCertify3View";
import WholesalerEditPostingProfileView from "pages/statics/wholesaler/WholesalerEditPostingProfileView";
import WholesalerManageScheduleView from "pages/statics/wholesaler/WholesalerManageScheduleView";
import WholesalerManageBrandsItemsBatchView from "pages/statics/wholesaler/WholesalerManageBrandsItemsBatchView";
import WholesalerManageBrandsItemsBatch2View from "pages/statics/wholesaler/WholesalerManageBrandsItemsBatch2View";
import WholesalerManageBrandsItemsBatch3View from "pages/statics/wholesaler/WholesalerManageBrandsItemsBatch3View";
import WholesalerManageBrandsItemsView from "pages/statics/wholesaler/WholesalerManageBrandsItemsView";
import WholesalerManageBrandsItems1View from "pages/statics/wholesaler/WholesalerManageBrandsItems1View";
import WholesalerWRAddBrandOrItemView from "pages/statics/wholesaler/WholesalerWRAddBrandOrItemView";
import WholesalerWRAddBrandOrItem1View from "pages/statics/wholesaler/WholesalerWRAddBrandOrItem1View";
import WholesalerWRAddBrandOrItem2View from "pages/statics/wholesaler/WholesalerWRAddBrandOrItem2View";
import WholesalerWRAddBrandOrItem21View from "pages/statics/wholesaler/WholesalerWRAddBrandOrItem21View";
import WholesalerWWAddBrandOrItemView from "pages/statics/wholesaler/WholesalerWWAddBrandOrItemView";
import WholesalerWWAddBrandOrItem1View from "pages/statics/wholesaler/WholesalerWWAddBrandOrItem1View";
import WholesalerWWAddBrandOrItem2View from "pages/statics/wholesaler/WholesalerWWAddBrandOrItem2View";
import WholesalerWWAddBrandOrItem21View from "pages/statics/wholesaler/WholesalerWWAddBrandOrItem21View";
import WholesalerWAddBrandOrItemView from "pages/statics/wholesaler/WholesalerWAddBrandOrItemView";
import WholesalerWAddBrandOrItem1View from "pages/statics/wholesaler/WholesalerWAddBrandOrItem1View";
import WholesalerWAddBrandOrItem2View from "pages/statics/wholesaler/WholesalerWAddBrandOrItem2View";
import WholesalerWAddBrandOrItem21View from "pages/statics/wholesaler/WholesalerWAddBrandOrItem21View";
import WholesalerAddBrandOrItemView from "pages/statics/wholesaler/WholesalerAddBrandOrItemView";
import WholesalerAddBrandOrItem1View from "pages/statics/wholesaler/WholesalerAddBrandOrItem1View";
import WholesalerAddBrandOrItem2View from "pages/statics/wholesaler/WholesalerAddBrandOrItem2View";
import WholesalerAddBrandOrItem21View from "pages/statics/wholesaler/WholesalerAddBrandOrItem21View";
import WholesalerManageDiscountsView from "pages/statics/wholesaler/WholesalerManageDiscountsView";
import WholesalerManageDiscounts1View from "pages/statics/wholesaler/WholesalerManageDiscounts1View";
import WholesalerScan from "pages/statics/wholesaler/WholesalerScan";
import WholesalerProductDetailsView from "pages/statics/wholesaler/WholesalerProductDetailsView";
import WholesalerProductDetailsView2 from "pages/statics/wholesaler/WholesalerProductDetailsView2";
import WholesalerMidMonthView from "pages/statics/wholesaler/WholesalerMidMonthView";
import { Route, Routes } from "react-router-dom";

export const WHOLESALER_STATIC_PATHS = {
    MAIN: "/wholesaler-static/*",
    LOGIN: "/login",
    PRICE_POSTING: "/price-posting",
    CREATE_APPOINTMENT_LETTERS: "/create-appointment-letter-1",
    CREATE_APPOINTMENT_LETTERS2: "/create-appointment-letter-2",
    CREATE_APPOINTMENT_LETTERS3: "/create-appointment-letter-3",
    CREATE_APPOINTMENT_LETTERS4: "/create-appointment-letter-4",
    APPOINTMENT_LETTERS: "/appointment-letters",
    APPOINTMENT_LETTERS_EMPTY: "/appointment-letters-empty",
    MANAGE_SCHEDULE: "/manage-schedule",
    EDIT_POSTING_PROFILE: "/edit-posting-profile",
    CERTIFY: "/certify",
    CERTIFY2: "/certify2",
    CERTIFY3: "/certify3",
    MANAGE_BRANDS_ITEMS_BATCH: "/manage-brands-items/batch",
    MANAGE_BRANDS_ITEMS_BATCH2: "/manage-brands-items/batch2",
    MANAGE_BRANDS_ITEMS_BATCH3: "/manage-brands-items/batch3",
    MANAGE_BRANDS_ITEMS: "/manage-brands-items",
    MANAGE_BRANDS_ITEMS1: "/manage-brands-items1",
    MANAGE_BRANDS_ITEMS2: "/manage-brands-items2",
    ADD_BRAND_OR_ITEM: "/manage-brands-items/add-brand-or-item",
    ADD_BRAND_OR_ITEM1: "/manage-brands-items/add-brand-or-item1",
    ADD_BRAND_OR_ITEM2: "/manage-brands-items/add-brand-or-item2",
    ADD_BRAND_OR_ITEM21: "/manage-brands-items/add-brand-or-item21",
    WR_ADD_BRAND_OR_ITEM: "/wine-to-retailer/add-brand-or-item",
    WR_ADD_BRAND_OR_ITEM1: "/wine-to-retailer/add-brand-or-item1",
    WR_ADD_BRAND_OR_ITEM2: "/wine-to-retailer/add-brand-or-item2",
    WR_ADD_BRAND_OR_ITEM21: "/wine-to-retailer/add-brand-or-item21",
    WW_ADD_BRAND_OR_ITEM: "/wine-to-wholesaler/add-brand-or-item",
    WW_ADD_BRAND_OR_ITEM1: "/wine-to-wholesaler/add-brand-or-item1",
    WW_ADD_BRAND_OR_ITEM2: "/wine-to-wholesaler/add-brand-or-item2",
    WW_ADD_BRAND_OR_ITEM21: "/wine-to-wholesaler/add-brand-or-item21",
    W_ADD_BRAND_OR_ITEM: "/add-brand-or-item",
    W_ADD_BRAND_OR_ITEM1: "/add-brand-or-item1",
    W_ADD_BRAND_OR_ITEM2: "/add-brand-or-item2",
    W_ADD_BRAND_OR_ITEM21: "/add-brand-or-item21",
    MANAGE_DISCOUNTS: "/manage-discounts",
    MANAGE_DISCOUNTS1: "/manage-discounts1",
    SCAN: "/delinquency-scan",
    PRODUCT_DETAILS: "/product-details",
    PRODUCT_DETAILS2: "/product-details2",
    MIDMONTH: "/mid-month-limited-availability"
};

const LEFT_MENU : MenuSettings = {
    caption: "Wholesaler menu",
    items: [
        {
            title: "Price Posting",
            link: "/wholesaler-static/price-posting"
        },
        {
            title: "User management",
            link:""
        },
        {
            title: "Delinquent Licensees Search",
            link: ""
        },
        {
            title: "Delinquent Listing Search",
            link: ""
        },
        {
            title: "Appointment ListLetter",
            link: ""
        }
    ]
};

const WholesalerStaticRoutes = () => {
    useLeftMenu(LEFT_MENU);
    return <Routes>
        <Route path={ WHOLESALER_STATIC_PATHS.LOGIN } element={ <WholesalerPriceLoginView/> }/>
        <Route path={ WHOLESALER_STATIC_PATHS.PRICE_POSTING } element={ <WholesalerPricePostingView/> }/>
        <Route path={ WHOLESALER_STATIC_PATHS.CERTIFY } element={ <WholesalerCertifyView/> }/>
        <Route path={ WHOLESALER_STATIC_PATHS.CERTIFY2 } element={ <WholesalerCertify2View/> }/>
        <Route path={ WHOLESALER_STATIC_PATHS.CERTIFY3 } element={ <WholesalerCertify3View/> }/>
        <Route path={ WHOLESALER_STATIC_PATHS.WR_ADD_BRAND_OR_ITEM } element={ <WholesalerWRAddBrandOrItemView/> }/>
        <Route path={ WHOLESALER_STATIC_PATHS.WR_ADD_BRAND_OR_ITEM1 } element={ <WholesalerWRAddBrandOrItem1View/> }/>
        <Route path={ WHOLESALER_STATIC_PATHS.WR_ADD_BRAND_OR_ITEM2 } element={ <WholesalerWRAddBrandOrItem2View/> }/>
        <Route path={ WHOLESALER_STATIC_PATHS.WR_ADD_BRAND_OR_ITEM21 } element={ <WholesalerWRAddBrandOrItem21View/> }/>
        <Route path={ WHOLESALER_STATIC_PATHS.WW_ADD_BRAND_OR_ITEM } element={ <WholesalerWWAddBrandOrItemView/> }/>
        <Route path={ WHOLESALER_STATIC_PATHS.WW_ADD_BRAND_OR_ITEM1 } element={ <WholesalerWWAddBrandOrItem1View/> }/>
        <Route path={ WHOLESALER_STATIC_PATHS.WW_ADD_BRAND_OR_ITEM2 } element={ <WholesalerWWAddBrandOrItem2View/> }/>
        <Route path={ WHOLESALER_STATIC_PATHS.WW_ADD_BRAND_OR_ITEM21 } element={ <WholesalerWWAddBrandOrItem21View/> }/>
        <Route path={ WHOLESALER_STATIC_PATHS.W_ADD_BRAND_OR_ITEM } element={ <WholesalerWAddBrandOrItemView/> }/>
        <Route path={ WHOLESALER_STATIC_PATHS.W_ADD_BRAND_OR_ITEM1 } element={ <WholesalerWAddBrandOrItem1View/> }/>
        <Route path={ WHOLESALER_STATIC_PATHS.W_ADD_BRAND_OR_ITEM2 } element={ <WholesalerWAddBrandOrItem2View/> }/>
        <Route path={ WHOLESALER_STATIC_PATHS.W_ADD_BRAND_OR_ITEM21 } element={ <WholesalerWAddBrandOrItem21View/> }/>
        <Route path={ WHOLESALER_STATIC_PATHS.ADD_BRAND_OR_ITEM } element={ <WholesalerAddBrandOrItemView/> }/>
        <Route path={ WHOLESALER_STATIC_PATHS.ADD_BRAND_OR_ITEM1 } element={ <WholesalerAddBrandOrItem1View/> }/>
        <Route path={ WHOLESALER_STATIC_PATHS.ADD_BRAND_OR_ITEM2 } element={ <WholesalerAddBrandOrItem2View/> }/>
        <Route path={ WHOLESALER_STATIC_PATHS.ADD_BRAND_OR_ITEM21 } element={ <WholesalerAddBrandOrItem21View/> }/>
        <Route path={ WHOLESALER_STATIC_PATHS.EDIT_POSTING_PROFILE } element={ <WholesalerEditPostingProfileView/> }/>
        <Route path={ WHOLESALER_STATIC_PATHS.MANAGE_SCHEDULE } element={ <WholesalerManageScheduleView/> }/>
        <Route path={ WHOLESALER_STATIC_PATHS.MANAGE_BRANDS_ITEMS_BATCH } element={ <WholesalerManageBrandsItemsBatchView/> }/>
        <Route path={ WHOLESALER_STATIC_PATHS.MANAGE_BRANDS_ITEMS_BATCH2 } element={ <WholesalerManageBrandsItemsBatch2View/> }/>
        <Route path={ WHOLESALER_STATIC_PATHS.MANAGE_BRANDS_ITEMS_BATCH3 } element={ <WholesalerManageBrandsItemsBatch3View/> }/>
        <Route path={ WHOLESALER_STATIC_PATHS.MANAGE_BRANDS_ITEMS } element={ <WholesalerManageBrandsItemsView/> }/>
        <Route path={ WHOLESALER_STATIC_PATHS.MANAGE_BRANDS_ITEMS1 } element={ <WholesalerManageBrandsItems1View/> }/>
        <Route path={ WHOLESALER_STATIC_PATHS.MANAGE_DISCOUNTS } element={ <WholesalerManageDiscountsView/> }/>
        <Route path={ WHOLESALER_STATIC_PATHS.MANAGE_DISCOUNTS1 } element={ <WholesalerManageDiscounts1View/> }/>
        <Route path={ WHOLESALER_STATIC_PATHS.SCAN } element={ <WholesalerScan/> }/>
        <Route path={ WHOLESALER_STATIC_PATHS.PRODUCT_DETAILS } element={ <WholesalerProductDetailsView/> }/>
        <Route path={ WHOLESALER_STATIC_PATHS.PRODUCT_DETAILS2 } element={ <WholesalerProductDetailsView2/> }/>
        <Route path={ WHOLESALER_STATIC_PATHS.MIDMONTH } element={ <WholesalerMidMonthView/> }/>
    </Routes>;
};

export default WholesalerStaticRoutes;
