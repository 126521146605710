import React, { InputHTMLAttributes, forwardRef } from "react";
import { Controller } from "react-hook-form";
import { UseControllerProps } from "react-hook-form/dist/types/controller";
import FormUtil from "utils/FormUtil";
import { extractPathFromObject, valueAsHandler } from "components/form/BaseInput";
import classNames from "classnames";

export interface SelectOption {
    value?: string | number;
    title?: string;
}

export interface SelectProps extends InputHTMLAttributes<HTMLSelectElement> { //TODO SelectHTMLAttributes should be here instead
    name: string;
    label?: string;
    errorMessage?: string;
    options: SelectOption[];
    value?: any,
    valueAsInteger?: boolean;
    onChange?: (...event: any[]) => void;
    addValueIfNotPresent?: boolean ; // should we add (non-empty) value to options if it is not there (default = false)
}

export interface ControllerSelectProps extends SelectProps {
    control: UseControllerProps<any,any>["control"];
    errors?: any;
    rules?: UseControllerProps<any,any>["rules"]
}

export const SelectControlled = (props : ControllerSelectProps) => {

    const { control, errors, ...selectProps } = props;

    const error = extractPathFromObject(errors, props.name);
    const errorMessage = error?.message;

    function getRules() {
        return props.rules ? props.rules : (props.required ? FormUtil.REQUIRED_FIELD : undefined);
    }

    // React form hook component
    return <Controller
        name={ props.name }
        control={ control }
        defaultValue=""
        rules={ getRules() }
        render={ ({ field }) => (
            <BaseSelect
                { ...Object.assign({},
                    field,
                    selectProps,
                    {
                        errorMessage: errorMessage,
                        ref: field.ref
                    })
                }
            />
        ) }
    />;

};

// eslint-disable-next-line react/display-name
export const BaseSelect = forwardRef((props: SelectProps, ref: any) => {

    const { options, label, errorMessage, valueAsInteger,
        onChange, value, addValueIfNotPresent, ...selectProps } = props;
    const name = selectProps.name;
    const displayedValue = value ? (valueAsInteger && !isNaN(value) ? parseInt(value) : value) : "";

    const innerOptions = Array.from(options);
    if (addValueIfNotPresent && value && !innerOptions.some(v=> v.value == value) ){
        innerOptions.unshift({ title: value, value: value });
    }

    return <div className="mb-3">
        { label &&
            <label htmlFor={ name } className="form-label"> { label } </label>
        }
        <select
            ref={ ref }
            id={ name }
            { ...selectProps }
            value={ displayedValue }
            onChange={ valueAsHandler(valueAsInteger, undefined, onChange) }
            className={ classNames("form-select" , { "is-invalid": errorMessage !== undefined }, selectProps.className) }
        >
            { innerOptions.map((op, idx) => (
                <option key={ idx } value={ op.value }>
                    { op.title ?? op.value }
                </option>
            )) }
        </select>
        { errorMessage &&
            <div className="invalid-feedback"> { errorMessage } </div>
        }
    </div>;
});
