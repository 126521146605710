import React from "react";
import FormUtil from "utils/FormUtil";
import BaseInput, { ControllerInputProps, defaultIfFalsy } from "components/form/BaseInput";
import { Controller } from "react-hook-form";


const Input = (props : ControllerInputProps) => {

    const { control, ...inputProps } = props;

    // React form hook component
    if (control) {
        const rules = getRules(props);
        return <Controller
            name={ props.name }
            control={ control }
            defaultValue={ defaultIfFalsy(props) }
            rules={ rules }
            render={ ({ field }) => (
                <BaseInput
                    { ...Object.assign({}, field, inputProps) }
                    ref= { field.ref }
                />
            ) }
        />;
    }
    
    // Simple control
    return <BaseInput { ...inputProps } />;
};

function getRules(props : ControllerInputProps) {

    if (props.rules) {
        return props.rules;
    }

    switch (props.type) {
    case "password": return FormUtil.PASSWORD_VALIDATIONS;
    case "email": return FormUtil.EMAIL_VALIDATIONS;
    }

    if (props.required) {
        return FormUtil.REQUIRED_FIELD;
    }
    return undefined;
}

export default Input;
