import httpClient from "api/HttpClient";
import dayjs, { Dayjs } from "dayjs";
import CacheStore from "store/CacheStore";
import BACKEND from "services/Constants";

const serverTimeCache = CacheStore.serverTimeCache;
const SERVER_TIME_KEY = CacheStore.SERVER_TIME_KEY;

export default {
    
    getServerTime(): Promise<Dayjs> {
        const serverDate: Promise<Dayjs> | undefined = serverTimeCache.get(SERVER_TIME_KEY);
        if (serverDate == undefined) {
            const promise = httpClient.get(BACKEND + "/properties/get-time").then(response => dayjs(response));
            serverTimeCache.set(SERVER_TIME_KEY, promise);
            return promise;

        } else {
            return serverDate;
        }
    }
};
