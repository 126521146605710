import React, { useEffect, useState } from "react";
import { ControllerSelectProps, SelectControlled, SelectOption } from "components/form/Select";
import ProductService from "services/ProductService";

export type SelectProductTypeProps = Omit<ControllerSelectProps, "options">

const SelectSchedule = (props : SelectProductTypeProps) => {

    function getOptions() {
        ProductService.getWholesalerPostTypes().then(data => {
            const types = data.map(t => {return { value: t.code, title: t.description };});
            types.unshift({ value: "", title: "Select schedule" });
            setOptions(types);
        });
    }

    useEffect(() => {
        getOptions();
    }, []);

    const [options, setOptions] = useState<SelectOption[]>([]);

    return <SelectControlled label="Select schedule" options={ options } { ...props } />;
};

export default SelectSchedule;
