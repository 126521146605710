import { modalStore } from "store/ModalStoreProvider";
import React, { useEffect, useState } from "react";
import SchedulesService from "services/admin/SchedulesService";
import { CertificationInfo } from "models/response/CertificationInfo";
import ProductConstants from "services/ProductConstants";
import Spinner from "components/Spinner";
import DateUtil from "utils/DateUtil";

interface Props {
    licenseId: number;
    month: number,
    year: number,
    postType: string;
}

export const showCertificationInfo = (licenseId: number, month: number, year: number, postType: string) => {
    modalStore.setSize("xl");
    modalStore.setHeader("Certification information");
    modalStore.setBody(<CertificationInfoDialog licenseId={ licenseId } month={ month } year={ year } postType={ postType }/>);
    modalStore.showModal();
};

const CertificationInfoDialog = (props: Props) => {
    const [certInfo, setCertInfo] = useState<CertificationInfo>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        SchedulesService.getCertificationInfo(props.licenseId, props.month, props.year, props.postType).then(info => {
            setCertInfo(info);
        }).finally(() => setIsLoading(false));
    }, []);



    return <div>
        { !isLoading && <div>
            <div className="">
                <div className="mb-4">
                    This is the confirmation of the new posting system.<br/>
                    The following price posting schedule has been submitted to the State Liquor Authority.
                    <hr/>
                </div>
                <div className="row">
                    <div className="col-12 col-md-5 col-xl-3">
                        <p>
                            <strong>Confirmation number:</strong>
                        </p>
                    </div>
                    <div className="col-12 col-md-7 col-xl-9">
                        <p>{ certInfo?.confirmation_number }</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-5 col-xl-3">
                        <p>
                            <strong>License ID:</strong>
                        </p>
                    </div>
                    <div className="col-12 col-md-7 col-xl-9">
                        <p>{ certInfo?.permit_id }</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-5 col-xl-3">
                        <p>
                            <strong>Posting type:</strong>
                        </p>
                    </div>
                    <div className="col-12 col-md-7 col-xl-9">
                        <p>{ ProductConstants.postTypeDescription( props.postType ) }</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-5 col-xl-3">
                        <p>
                            <strong>Total items:</strong>
                        </p>
                    </div>
                    <div className="col-12 col-md-7 col-xl-9">
                        <p>{ certInfo?.total_items }</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-5 col-xl-3">
                        <p>
                            <strong>Effective date:</strong>
                        </p>
                    </div>
                    <div className="col-12 col-md-7 col-xl-9">
                        <p>{ DateUtil.monthNumberToName(props.month-1) } { props.year }</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-5 col-xl-3">
                        <p>
                            <strong>Submitted By:</strong>
                        </p>
                    </div>
                    <div className="col-12 col-md-7 col-xl-9">
                        <p>{ certInfo?.submitted_by }</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-5 col-xl-3">
                        <p>
                            <strong>This certification applies to the following licenses:</strong>
                        </p>
                    </div>
                    <div className="col-12 col-md-7 col-xl-9">
                        <p>{ certInfo?.applied_licenses }</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-5 col-xl-3">
                        <p>
                            <strong>Confirmation email sent to:</strong>
                        </p>
                    </div>
                    <div className="col-12 col-md-7 col-xl-9">
                        <p>{ certInfo?.confirmation_email }</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-5 col-xl-3">
                        <p>
                            <strong>Certification date:</strong>
                        </p>
                    </div>
                    <div className="col-12 col-md-7 col-xl-9">
                        <p>{ certInfo?.created_at.substring(0, certInfo?.created_at.indexOf("T")) }</p>
                    </div>
                </div>
            </div>
        </div>
        }
        { isLoading && <Spinner className="mt-4 pt-2 text-center" /> }
    </div>;
};
