import { FC, PropsWithChildren, createContext, useContext } from "react";
import { MenuStore } from "store/MenuStore";

const StoreContext = createContext<null | MenuStore>(null);

const store = new MenuStore();

const MenuStoreProvider: FC<PropsWithChildren<any>> = ({ children }) => {
    return(
        <StoreContext.Provider value={ store }>
            { children }
        </StoreContext.Provider>
    );
};

export function useMenuStore(): MenuStore {
    const store = useContext(StoreContext);
    if (store === null) {
        throw new Error("Store cannot be null, please add a context provider");
    }
    return store;
}

export { MenuStoreProvider };
