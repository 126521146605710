import React, { forwardRef, useImperativeHandle, useState } from "react";
import TabContainer from "react-bootstrap/TabContainer";
import { Nav } from "react-bootstrap";
import TabContent from "react-bootstrap/TabContent";
import TabPane from "react-bootstrap/TabPane";
import { TabName } from "pages/wholesaler/priceposting/models/ManualProduct";

interface Props {
    activeTab: string;
    onSelectTab: (key:string|null) => void;
    itemTabContent: JSX.Element;
    discountsTabContent: JSX.Element;
}

export type ProductItemTabsHandler = {
    disableDiscountTab: () => void;
    enableDiscountTab: () => void;
}

const ProductItemTabs = forwardRef(({ activeTab, discountsTabContent, itemTabContent, onSelectTab }: Props, forwardedRef)=>{
    const [isDiscountTabDisabled, setIsDiscountTabDisabled] = useState<boolean>(false);
    useImperativeHandle(forwardedRef, () => ({
        disableDiscountTab() {
            setIsDiscountTabDisabled(true);
        },
        enableDiscountTab() {
            setIsDiscountTabDisabled(false);
        }
    }));

    return <TabContainer id="_ignored" activeKey={ activeTab } onSelect={ onSelectTab }>
        <Nav variant="pills" className="btn-group flex-column flex-lg-row d-lg-inline-flex mb-4" id="nav-tab"  role="tablist" >
            <Nav.Link eventKey={ TabName.ITEM_INFO } as="button" type="button" className="btn btn-outline-secondary min-wp-230" bsPrefix="_U">Item info</Nav.Link>
            <Nav.Link
                eventKey={ TabName.DISCOUNTS } as="button" type="button" className="btn btn-outline-secondary min-wp-230"
                bsPrefix="_U" disabled={ isDiscountTabDisabled }>Discounts</Nav.Link>
            { isDiscountTabDisabled && <div className="tooltip-wr tooltip-wr-small ms-2">
                <span className="tooltip-icon bi-info-circle-fill text-danger"></span>
                <div className="tooltip-msg text-center">
                    <p>Limited Availability items are not eligible for Discounts.</p>
                </div>
            </div> }
        </Nav>
        <TabContent id="nav-tabContent">
            <TabPane eventKey={ TabName.ITEM_INFO }>
                { itemTabContent }
            </TabPane>
            <TabPane eventKey={ TabName.DISCOUNTS }>
                { discountsTabContent }
            </TabPane>
        </TabContent>
    </TabContainer>;
});

ProductItemTabs.displayName = "ProductItemTabs";

export default ProductItemTabs;
