import { useEffect, useState } from "react";
import Input from "components/form/Input";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, generatePath, useNavigate, useParams } from "react-router-dom";
import { WHOLESALER_PATHS } from "pages/wholesaler/WholesalerRoutes";
import { Certify2Model } from "pages/wholesaler/priceposting/models/Certify2Model";
import UserProfileService from "services/user/UserProfileService";
import CertificationService from "services/wholesaler/priceposting/CertificationService";
import Spinner from "components/Spinner";
import ProductConstants from "services/ProductConstants";
import DateUtil from "utils/DateUtil";
import { useKeyDownEnterEvent } from "components/KeyDownHook";

const Certify2View = () => {
    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors }
    } = useForm<Certify2Model>({
        mode: "onChange"
    });
    useKeyDownEnterEvent(() => !isLoading && handleSubmit(onSubmit)());

    const navigate = useNavigate();
    const { type = "", year = "", month = "" } = useParams();
    const [name, setName] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [totalToBeCertified, setTotalToBeCertified] = useState<number>();
    const [masterFilerLicenseNumber, setMasterFilerLicenseNumber] = useState<number>();
    const [childLicenseNumbers, setChildLicenseNumbers] = useState<string>();

    useEffect(() => {
        UserProfileService.getProfile().then(user => {
            setValue("email", user.email);
            setName(user.first_name + " " + user.last_name);
        });

        CertificationService.countToByCertified(type, year, month).then(response => {
            setTotalToBeCertified(response);
        });

        CertificationService.fetchMasterFilerInfo().then(info => {
            if (info == null) return;

            setMasterFilerLicenseNumber(info[0]);
            setChildLicenseNumbers(info[1]);
        });
    },[]);

    function submitForm() {
        document.getElementById("emailForm")?.dispatchEvent(
            new Event("submit", { bubbles: true, cancelable: true })
        );
    }

    const onSubmit: SubmitHandler<Certify2Model> = async data => {
        setIsLoading(true);
        CertificationService.certify(type, year, month, data.email).then( r => {
            setIsLoading(false);

            navigate(generatePath(WHOLESALER_PATHS.CERTIFY.STEP3,{ "type":type, "year":year, "month": month }),
                { state: {
                    "totalToBeCertified": totalToBeCertified,
                    "email": data.email,
                    "licenses": childLicenseNumbers,
                    ...r
                } });
        });
    };

    return <div className="d-flex flex-column">
        <Spinner hidden={ !isLoading } className="text-center mt-2" />
        <div className="mb-4 mb-md-5">
            <h1 className="text-primary mb-0">Certify - { ProductConstants.postTypeDescription(type) } Schedule for { DateUtil.monthNumberToName(parseInt(month)-1).toString() + " " + year.toString() }</h1>
        </div>
        <div>
            <div className="steps-wr mb-4">
                <div className="steps-l row g-0">
                    <div className="steps-li col-12 col-xl-3">
                        <div className="steps-li-in">
                            <strong>1</strong> Edit Posting Profile
                        </div>
                    </div>
                    <div className="steps-li col-12 col-xl-3 active">
                        <div className="steps-li-in">
                            <strong>2</strong> Certify Schedule
                        </div>
                    </div>
                    <div className="steps-li col-12 col-xl-3">
                        <div className="steps-li-in">
                            <strong>3</strong> Confirmation
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-2 mb-3 mb-md-4">
                <h4><strong>Step 2:</strong> Certify Schedule</h4>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="mb-4">
                        <h4 className="mb-3">Certification</h4>
                    </div>
                    <div className="content-styles">
                        <p>
                            By pressing the &quot;Certify Price Posting&quot; button below, you agree to the following:
                        </p>
                        <ul>
                            <li>
                                I state, under penalty of perjury, that all statements herein are true to the best of my knowledge and belief,
                                and all prices posted herein reflect actual prices to be invoiced to licensees.
                                I understand that any price posting or statement made herein which is false may constitute perjury and may
                                subject the licensee to revocation, cancellation, or other penalty.
                            </li>
                            <li>
                                Prices may include a decimal point.
                                For numeric fields, use of dollar signs, commas or other non-numeric entry will cause batch price errors.
                                It is your responsibility to review for such errors before certifying.
                            </li>

                            { masterFilerLicenseNumber != null && <li>
                                By certifying on behalf of license number { masterFilerLicenseNumber } as a &quot;Master Filer&quot; on behalf of the following licenses { childLicenseNumbers },
                                I attest under penalty of perjury that all said licenses share common ownership and that I have the permission of the license principal(s) to file on behalf of all said licenses as &quot;Master Filer&quot;.
                            </li> }

                        </ul>
                    </div>
                    <form id="emailForm" onSubmit={ handleSubmit(onSubmit) } className="mt-4 pt-2">
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <p className="mb-1"><strong>Your name</strong></p>
                                <p>{ name }</p>
                            </div>
                            <div className="col-12 col-lg-6">
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col-12 col-lg-6 col-xl-4">
                                <Input name="email" control={ control } errors={ errors } placeholder="Email address" label="Email address" type="email" />
                            </div>
                        </div>
                    </form>
                    <div className="row justify-content-center mt-3">
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                            <Link
                                to={ generatePath(WHOLESALER_PATHS.CERTIFY.STEP1, { "type":type, "year":year, "month": month }) }
                                className="btn btn-outline-secondary w-100">
                                Back
                            </Link>
                        </div>
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                            <button
                                onClick={ submitForm }
                                className="btn btn-primary w-100">
                                Certify Schedule
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default Certify2View;
