import React from "react";
import Button from "react-bootstrap/Button";
import AuthService from "services/AuthService";
import Input from "components/form/Input";
import { SubmitHandler,  UseFormReturn } from "react-hook-form";
import FormUtil from "utils/FormUtil";
import { LoginModel } from "pages/auth/models/LoginModel";

export const NEW_EMAIL_IS_UNAVAILABLE = "A user with this email address already exists. Either log in with this email and the corresponding password, or use a different email address.";

const LegacyChangeEmailForm = ({ form, onSubmit, isPending }: {
    form: UseFormReturn<LoginModel>,
    onSubmit: SubmitHandler<LoginModel>,
    isPending: boolean
}) => {

    const {
        handleSubmit,
        formState: { errors },
        control
    } = form;

    const [ newEmail] = form.watch(["new_email"]);

    const checkNewEmail = async (v: string) =>
        v ? await AuthService.doesEmailAlreadyExist(v).then( exists => !exists, ()=> false) || NEW_EMAIL_IS_UNAVAILABLE
            : NEW_EMAIL_IS_UNAVAILABLE;

    const checkConfirmNewEmail = (confirmNewEmail: string) =>
        (newEmail === confirmNewEmail) || FormUtil.EMAIL_NOT_MATCH_MESSAGE;

    return (
        <div className="registration-block">
            <div className="mb-4 mb-md-5 text-center">
                <h1 className="text-primary mb-4">Email Confirmation Form</h1>
                <p>We’ve found your email below, but you have an opportunity to change it now.<br/>Keep in mind that duplicate emails on the same account are no longer permitted.</p>
            </div>
            <form onSubmit={ handleSubmit(onSubmit) } className="card login-block">
                <div className="card-body">

                    <Input
                        name="new_email" control={ control } errors={ errors } type="text"
                        label="Email*" placeholder="Enter email" maxLength={ 50 }
                        rules={ FormUtil.emailConfirmValidations(checkNewEmail) }
                    />

                    <Input
                        name="confirm_new_email" control={ control } errors={ errors } type="text"
                        label="Confirm Email*" placeholder="Confirm email" maxLength={ 50 }
                        rules={ FormUtil.emailConfirmValidations(checkConfirmNewEmail) }
                    />

                    <div className="row justify-content-center mt-4">
                        <div className="col-6 col-md-5 col-lg-5 mt-1">
                            <div className="pe-md-1">
                                <Button type="submit" className="btn btn-primary w-100" disabled={ isPending }>
                                    Confirm email
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default LegacyChangeEmailForm;
