import { useEffect, useState } from "react";
import { Link, generatePath, useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { WHOLESALER_PATHS } from "pages/wholesaler/WholesalerRoutes";
import TextArea from "components/form/TextArea";
import ProfilePostingService from "services/wholesaler/ProfilePostingService";
import { ProfileTermModel } from "models/ProfileTermModel";
import ProductConstants from "services/ProductConstants";
import DateUtil from "utils/DateUtil";
import { useTimeStore } from "store/TimeStoreProvider";
import { LicenseModel } from "models/license.model";
import CheckBox from "components/form/CheckBox";
import { useToastStore } from "store/ToastStoreProvider";
import { useKeyDownEnterEvent } from "components/KeyDownHook";

const Certify1View = () => {
    const navigate = useNavigate();
    const { newYorkTime } = useTimeStore();
    const { addDefaultDangerToast } = useToastStore();
    const { type = "", year = "", month = "" } = useParams();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const {
        control,
        handleSubmit,
        watch,
        reset
    } = useForm<LicenseModel>({
        mode: "onChange"
    });
    useKeyDownEnterEvent(() => !isLoading && handleSubmit(onSubmit)());


    useEffect(() => {
        setIsLoading(true);
        ProfilePostingService.getProfile(type, month, year)
            .then(response => reset(response))
            .finally(() => setIsLoading(false));
    },[]);

    const onSubmit: SubmitHandler<LicenseModel> = async data => {
        setIsLoading(true);
        const profileTerm = {
            terms_sale: data.profile_term.terms_sale,
            other_info: data.profile_term.other_info,
            pay_disc: data.profile_term.pay_disc? 1 : 0,
            post_type: type,
            post_month: month,
            post_year: year } as ProfileTermModel;
        ProfilePostingService.saveProfileTerm(profileTerm).then(() => {
            navigate(generatePath(WHOLESALER_PATHS.CERTIFY.STEP2, { "type":type, "year":year, "month": month }));
        }).catch(() => addDefaultDangerToast("Certify page")
        ).finally(() => setIsLoading(false));
    };

    return <div className="d-flex flex-column">
        <div className="mb-4 mb-md-5">
            <h1 className="text-primary mb-0">Certify - { ProductConstants.postTypeDescription(type) } Schedule for { DateUtil.monthNumberToName(parseInt(month)-1)+ " " + year }</h1>
        </div>
        <div>
            <div className="steps-wr mb-4">
                <div className="steps-l row g-0">
                    <div className="steps-li col-12 col-xl-3 active">
                        <div className="steps-li-in">
                            <strong>1</strong> Edit Posting Profile
                        </div>
                    </div>
                    <div className="steps-li col-12 col-xl-3">
                        <div className="steps-li-in">
                            <strong>2</strong> Certify Schedule
                        </div>
                    </div>
                    <div className="steps-li col-12 col-xl-3">
                        <div className="steps-li-in">
                            <strong>3</strong> Confirmation
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-2 mb-3 mb-md-4">
                <h4><strong>Step 1:</strong> Edit Posting Profile</h4>
            </div>
            <div className="card mb-4">
                <div className="card-body pb-2">
                    <div className="mb-4">
                        <h4 className="mb-3">License information for { DateUtil.getFormattedDate(newYorkTime) }</h4>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-5 col-lg-4 col-xl-2">
                            <p><strong>License ID:</strong></p>
                        </div>
                        <div className="col-12 col-md-7 col-lg-8 col-xl-10">
                            <p>{ watch("permit_id") }</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-5 col-lg-4 col-xl-2">
                            <p><strong>Premises name:</strong></p>
                        </div>
                        <div className="col-12 col-md-7 col-lg-8 col-xl-10">
                            <p>{ watch("premise_name") }</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-5 col-lg-4 col-xl-2">
                            <p><strong>Premises address:</strong></p>
                        </div>
                        <div className="col-12 col-md-7 col-lg-8 col-xl-10">
                            <p>{ watch("premise_address") + " " + watch("premise_address2") }</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <form className="card-body" onSubmit={ handleSubmit(onSubmit) }>
                    <div className="mb-4">
                        <h4 className="mb-3">Profile information</h4>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-4">
                            <p className="mb-1"><strong>Discount for time payment</strong></p>
                            <div className="switch-year">
                                <label className="form-check-label me-2" htmlFor="custom-switch">None</label>
                                <CheckBox control={ control } name="profile_term.pay_disc" label="1% for payment within 10 days of delivery"/>
                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="pe-xl-4">
                                <div className="form-group">
                                    <TextArea control={ control } name="profile_term.terms_sale" rows={ 5 } placeholder="Terms of sale" label="Terms of sale"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="ps-xl-4">
                                <div className="form-group">
                                    <TextArea control={ control } name="profile_term.other_info" rows={ 5 } placeholder="Other information" label="Other information"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-3">
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                            <Link
                                to={ generatePath(WHOLESALER_PATHS.PRICE_POSTING.VIEW, { "type":type, "year":year, "month": month }) }
                                className="btn btn-outline-secondary w-100">
                                Cancel
                            </Link>
                        </div>
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                            <input type="submit" className="btn btn-primary w-100" value="Continue"/>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>;
};

export default Certify1View;

