import { useEffect } from "react";
import { useMenuStore } from "store/MenuStoreProvider";

export interface MenuItem {
    title: string;
    link: string;
    predicate?: () => boolean;
    submenu?: MenuItem[];
    open?: boolean;
}

export interface MenuSettings {
    caption: string;
    items: MenuItem[];
}

export function useLeftMenu(menu: MenuSettings) {
    const menuStore = useMenuStore();
    useEffect(() => {
        menuStore.showLeftMenu(menu);
        return () => {
            menuStore.clearAndHideLeftMenu();
        };
    }, []);
}
