import { ControllerSelectProps, SelectControlled } from "components/form/Select";
import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";

type Props = Omit<ControllerSelectProps, "control" | "errors">;

/**
 * Adds default value to select options, if it is not already there.
 * This allows to display wrong values inside combos.
 * Works only inside FormProvider.
 */
export const SelectWithDefault = ({ options: initialOptions, ...rest } : Props) => {
    const { control, getValues, formState: { errors } } = useFormContext();
    const [ realOptions, setRealOptions ] = useState(initialOptions);

    useEffect(()=>{
        const value = getValues(rest.name);
        if (!realOptions.some(s=>s.value == value)){
            setRealOptions(old=>{
                old.unshift({ value: value || "", title: value || "" });
                return old;
            });
        }
    },[]);

    return <SelectControlled options={ realOptions } control={ control } errors={ errors } { ...rest } />;
};
