import { FC, PropsWithChildren, createContext, useContext } from "react";
import { UtilStore } from "store/UtilStore";

const StoreContext = createContext<null | UtilStore>(null);

const utilStore = new UtilStore();

const UtilStoreProvider: FC<PropsWithChildren<any>> = ({ children }) => {
    return(
        <StoreContext.Provider value={ utilStore }>
            { children }
        </StoreContext.Provider>
    );
};

export function useUtilStore(): UtilStore {
    const store = useContext(StoreContext);
    if (store === null) {
        throw new Error("Store cannot be null, please add a context provider");
    }
    return store;
}

export { UtilStoreProvider };
