import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { WHOLESALER_PATHS } from "pages/wholesaler/WholesalerRoutes";
import { showBatchDialogDialog } from "pages/wholesaler/delinquent/dialogs/BatchDialog";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";
import { useToastStore } from "store/ToastStoreProvider";
import { useAuthStore } from "store/AuthStoreProvider";
import { showNewDelinquencyDialog } from "pages/wholesaler/delinquent/dialogs/NewDelinquencyDialog";
import WholesalerDelinquentRecords, { Reloadable } from "components/WholesalerDelinquentRecords";

const ManageRecords = () => {

    const auth = useAuthStore();
    const { permissionStore } = auth;
    const currentLicenseIsActive = auth.currentLicense?.active;
    const navigate = useNavigate();
    const { addToast } = useToastStore();
    const recordsRef = useRef<Reloadable>(null);

    function successMessageAndReload (msg: string) {
        return () =>{
            addToast({ message:msg, header:"Manage Records",variant: "success", timeoutMs: DEFAULT_TOASTER_TIMEOUT });
            recordsRef.current?.reload();
        };
    }

    const errorMessage = ()=>{
        addToast({ message:"Something went wrong. Please try again", header:"Manage Records",variant: "danger", timeoutMs: DEFAULT_TOASTER_TIMEOUT });
    };

    function onBatchSubmissions() {
        permissionStore.isWholesalerAndBatchUpload()
            ? navigate(WHOLESALER_PATHS.DELINQUENT.BATCH)
            : showBatchDialogDialog();
    }

    return  (
        <div className="d-flex flex-column min-vh-100">
            <div className="mb-3 mb-md-4">
                <h1 className="text-primary mb-4">Manage Delinquent Records</h1>
                <div className="d-flex flex-column align-items-center">
                    <div className="mb-3 me-lg-3">
                        On this page, you can manage delinquent records that you have registered in the system. Utilize the search function to find specific records and view details such as the delivery date&nbsp;
                        and amount of the unpaid order for each licensee. By selecting a license id, you can access more information about the related business.
                    </div>
                    <div className="mb-3 me-lg-3">
                        The Actions column contains three buttons: edit, print, and delete. Use these buttons to modify the delivery date and amount, print the record, or remove a delinquent record as needed.&nbsp;
                        Additionally, you can add records either manually one at a time or in bulk by using the batch upload feature.
                    </div>
                    <div className="d-flex flex-row ms-lg-auto">
                        <button
                            disabled={ !permissionStore.isWholesalerAndBA_CW_CA_CB() || !currentLicenseIsActive }
                            onClick={ () => showNewDelinquencyDialog(successMessageAndReload("New record has been created."), errorMessage) }
                            className="btn btn-primary mt-3 me-3">
                            Add delinquency record
                        </button>
                        <button disabled={ !currentLicenseIsActive }
                            className="btn btn-outline-secondary mt-3" onClick={ () => onBatchSubmissions() }>
                            Batch Submissions
                        </button>
                    </div>
                </div>
            </div>
            <WholesalerDelinquentRecords readonly={ !currentLicenseIsActive } ref={ recordsRef } />
        </div>
    );
};

export default ManageRecords;



