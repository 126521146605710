import React from "react";
import { NumericFormat } from "react-number-format";

interface Props {
    value: number
}

export const currencyFormatProps = {
    thousandSeparator: true,
    fixedDecimalScale: true,
    prefix: "$",
    decimalScale:  2
};

export const currencyFormatWithoutPrefixProps = {
    thousandSeparator: true,
    fixedDecimalScale: true,
    prefix: "",
    decimalScale:  2
};

const CurrencyText = ({ value, ...props } : Props) => {
    return <NumericFormat
        value={ value }
        displayType={ "text" }
        { ...currencyFormatProps }
        { ...props }
    />;
};

export default CurrencyText;
