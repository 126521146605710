import React, { useContext } from "react";
import { AccordionContext, useAccordionButton } from "react-bootstrap";
import classNames from "classnames";

interface PropTypes {
    eventKey: string
}

export const CustomToggle = ({ eventKey }: PropTypes) => {
    const { activeEventKey } = useContext(AccordionContext);
    const onClickToggle = useAccordionButton(eventKey, (event) => {
        event.preventDefault();
    });

    return (
        <a
            className={ classNames("table-collapse-a", activeEventKey?.includes(eventKey) ? "" : "collapsed") }
            onClick={ onClickToggle } href="#/" role="button">
            <i className="bi-caret-right-fill"/>
        </a>
    );
};
