import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LicenseInfo from "components/LicenseInfo";
import Spinner from "components/Spinner";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";
import { useToastStore } from "store/ToastStoreProvider";
import { SubmitHandler, useForm } from "react-hook-form";
import LicenseService from "services/LicenseService";
import { LicenseSettings, SpecialAccess } from "models/LicenseSettings";
import CheckBox from "components/form/CheckBox";
import SelectAdminPostType from "pages/admin/components/SelectAdminPostType";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import { useTimeStore } from "store/TimeStoreProvider";
import ReturnToSearchLink from "pages/admin/components/ReturnToSearchLink";
import { LicenseInfoResponse } from "models/response/LicenseInfoResponse";
import { useAuthStore } from "store/AuthStoreProvider";

interface FormParams {
    auto_certify: boolean;
    post_type: string;
}

export const Settings = () => {
    const { addToast } = useToastStore();
    const navigate = useNavigate();
    const auth = useAuthStore();
    const currentLicenseIsActive = auth.currentLicense?.active;
    const { licenseId } = useParams();
    const licenseIdInt = parseInt(licenseId || "");
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [list, setList] = useState<SpecialAccess[]>([]);
    const [untilDate, setUntilDate] = useState<Date>(new Date());
    const { newYorkTime } = useTimeStore();
    const [licenseInfo, setLicenseInfo] = useState<LicenseInfoResponse>();

    const {
        handleSubmit,
        setValue,
        control,
        watch,
        formState: { isSubmitting }
    } = useForm<FormParams>({
        mode: "onSubmit",
        defaultValues: {
            auto_certify: undefined,
            post_type: "WR"
        }
    });

    useEffect(() => {
        setIsDataLoading(true);

        LicenseService.getLicenseInfoById(licenseIdInt).then(response => {
            setLicenseInfo(response);
        });

        LicenseService.getSettings(licenseIdInt).then(response => {
            setValue("auto_certify", response.auto_certify);
            setList(response.specialAccesses);
        }).catch(() => errorMessage()
        ).finally(() => setIsDataLoading(false));
    }, []);

    const onSubmit: SubmitHandler<FormParams> = data => {

        const request: LicenseSettings = {
            auto_certify: data.auto_certify,
            licenseId: licenseIdInt,
            specialAccesses: list
        };
        setIsDataLoading(true);
        LicenseService.saveSettings(request).then(() => {
            addToast({
                header: "Manage settings",
                message:"License settings were saved successfully",
                variant: "success",
                timeoutMs: DEFAULT_TOASTER_TIMEOUT
            });
        }).catch(() => errorMessage()
        ).finally(() => setIsDataLoading(false));
    };

    function errorMessage() {
        addToast({
            header: "Manage Settings",
            message: "Something went wrong. Please try again",
            variant: "danger",
            timeoutMs: DEFAULT_TOASTER_TIMEOUT
        });
    }

    const postType = watch("post_type");

    function addSpecialAccess() {
        const isNotExists: boolean = list.filter(specialAccess => specialAccess.post_type === postType).length === 0;

        if (isNotExists) {
            list.push({ post_type: postType, until_date: untilDate.toLocaleDateString("en-US") });
            setList([...list]);
            setError(false);
        }
        setError(!isNotExists);
    }

    function calculateMaxDate() {
        if (postType === "WR" || postType === "LR") {
            const d = dayjs();
            return new Date(d.year(), d.month(), 12);
        } else {
            const d = dayjs().add(1, "month");
            return new Date(d.year(), d.month(), 12);
        }
    }

    function buttonDisabled() {
        if (postType === "WR" || postType === "LR") {
            return untilDate.getDate() >= 13;
        } else {
            return untilDate.getDate() >= 13 && untilDate.getDate() <= 25;
        }
    }

    return <div className="d-flex flex-column">
        <div className="mb-4 pb-md-3 d-flex flex-column flex-md-row align-items-center">
            <div className="order-0 order-md-1">
                <ReturnToSearchLink/>
            </div>
            <h1 className="text-primary mb-3 mb-md-0 mt-3 mt-md-0 me-md-auto order-1 order-md-0">Manage Settings</h1>
        </div>
        <LicenseInfo licenseId={ licenseIdInt } showDelinquentStatus={ true }/>
        <form onSubmit={ handleSubmit(onSubmit) }>
            <div className="mt-4 pt-2">
                { isDataLoading && <Spinner className="mt-4 pt-2 text-center"/> }
                { !isDataLoading && licenseInfo?.type.org_type != "R" &&
                    <div>
                        <div className="mb-4">
                            <h5 className="fw-bold mb-3">License settings</h5>
                            <hr />
                        </div>
                        <div>
                            <div className="form-label-with-tooltip">
                                <CheckBox control={ control } name="auto_certify" label="Automation certification" type="checkbox"/>
                                <div className="tooltip-wr tooltip-wr-small ms-2">
                                    <span className="tooltip-icon bi-question-circle-fill"></span>
                                    <div className="tooltip-msg text-center">
                                        <p>Products in the price posting will be automatically checked and certified each month</p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4 pt-3">
                                <div className="mb-4">
                                    <h5 className="fw-bold mb-3">Special Access</h5>
                                    <hr />
                                </div>
                                <p>
                                    This section allows you to grant temporary access to users for making adjustments to price postings. By providing time-limited permissions,&nbsp;
                                    you can ensure secure and controlled data updates while maintaining flexibility.
                                </p>
                                <div className="d-flex flex-column flex-md-row align-items-md-end mt-4 mb-1">
                                    <div className="mb-3 mb-md-0 me-0 me-md-3">
                                        <label className="form-label">Add special access for</label>
                                        <div className="form-group-without-m">
                                            <SelectAdminPostType className="" name="post_type" control={ control }/>
                                        </div>
                                    </div>
                                    <div className="mb-4 mb-md-0 me-0 me-md-3">
                                        <div className="form-label-with-tooltip">
                                            <label htmlFor="data-picker" className="form-label">Price Edit Access Until (Inclusive)</label>
                                            <div className="tooltip-wr tooltip-wr-small ms-2">
                                                <span className="tooltip-icon bi-question-circle-fill"></span>
                                                <div className="tooltip-msg text-center">
                                                    <p>Input the deadline for the special access here.
                                                        Note that this date is inclusive - the action can still be performed <span className="text-nowrap">on this date</span>.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group-without-m">
                                            <DatePicker
                                                id="data-picker"
                                                selected={ untilDate }
                                                onChange={ (date:Date) => { setUntilDate(date); date; } }
                                                dateFormat="MM/dd/yyyy"
                                                maxDate={ calculateMaxDate() }
                                                minDate={ newYorkTime.toDate() }
                                            />
                                        </div>
                                    </div>
                                    <button disabled={ buttonDisabled() } type="button" className="btn btn-outline-primary mb-2 mb-md-0" onClick={ addSpecialAccess }>Select Posting Type</button>
                                </div>
                                { error && <div className="invalid-message">Special access already present with given type</div> }
                            </div>
                            { (list?.length > 0) &&
                                <div className="mt-4 row">
                                    <div className="col-12 col-lg-10 col-xl-6">
                                        <div className="table-responsive mb-0">
                                            <table className="table table-bordered table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>Price Posting Type</th>
                                                        <th>End date</th>
                                                        <th className="w-13">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { list.map((specialAccess, index) => {
                                                        return (<tr key={ index }>
                                                            <td>{ specialAccess.post_type }</td>
                                                            <td>{ specialAccess.until_date }</td>
                                                            <td className="text-nowrap">
                                                                <button type="button" className="btn btn-link py-0 px-1" title="Delete" onClick={ () => setList([...list.filter(s => s.post_type != specialAccess.post_type)]) }>
                                                                    <i className="bi-trash me-1"></i> Delete
                                                                </button>
                                                            </td>
                                                        </tr>);
                                                    }) }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
            { licenseInfo?.type.org_type != "R" &&
                <div className="row justify-content-center mt-4 pt-3">
                    <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                        <button
                            type="button" className="btn btn-outline-secondary w-100"
                            onClick={ () => navigate(-1) }>Cancel</button>
                    </div>
                    <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                        <button
                            type="submit" className="btn btn-primary w-100"
                            disabled={ isSubmitting || !currentLicenseIsActive }>Save</button>
                    </div>
                </div>
            }
        </form>
    </div>;
};
