import React from "react";

const AdminView = () => {

    return <div className="d-flex flex-column">
        <div className="mb-4 pb-md-3">
            <h1 className="text-primary mb-0">Licence Settings</h1>
        </div>

        <div>
            <div className="row">
                <div className="col-12 col-xl-6 mb-4 mb-xl-0">
                    <div className="pe-xl-4">
                        <div className="mb-4">
                            <h5 className="fw-bold mb-3">License information for 07/26/2022</h5>
                            <hr />
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-5 col-lg-4"><p><strong>Serial number:</strong></p></div>
                            <div className="col-12 col-md-7 col-lg-8"><p>8000001</p></div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-5 col-lg-4"><p><strong>Certificate number:</strong></p></div>
                            <div className="col-12 col-md-7 col-lg-8"><p>6788554</p></div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-5 col-lg-4"><p><strong>License type:</strong></p></div>
                            <div className="col-12 col-md-7 col-lg-8"><p>WW - Wholesale Wine</p></div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-5 col-lg-4"><p><strong>License status:</strong></p></div>
                            <div className="col-12 col-md-7 col-lg-8">
                                <span className="badge bg-success"><i className="bi-check-circle me-2"></i>Active</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-5 col-lg-4"><p><strong>Delinquent status:</strong></p></div>
                            <div className="col-12 col-md-7 col-lg-8"><p>N/A</p></div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-5 col-lg-4"><p><strong>Filing date:</strong></p></div>
                            <div className="col-12 col-md-7 col-lg-8"><p>05/07/2022</p></div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-5 col-lg-4"><p><strong>Effective date:</strong></p></div>
                            <div className="col-12 col-md-7 col-lg-8"><p>25/07/2022</p></div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-5 col-lg-4"><p><strong>Expiration date:</strong></p></div>
                            <div className="col-12 col-md-7 col-lg-8"><p>01/02/2030</p></div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-xl-6">
                    <div className="ps-xl-4">
                        <div className="mb-4">
                            <h5 className="fw-bold mb-3">Premises information</h5>
                            <hr/>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-5 col-lg-4">
                                <p><strong>Principal&apos;s name:</strong></p>
                            </div>
                            <div className="col-12 col-md-7 col-lg-8">
                                <p>Green, Adam</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-5 col-lg-4"><p><strong>Premises name:</strong></p></div>
                            <div className="col-12 col-md-7 col-lg-8"><p>Joffre Napa of South Weynon (Demo)</p></div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-5 col-lg-4"><p><strong>Trade name:</strong></p></div>
                            <div className="col-12 col-md-7 col-lg-8"><p>Joffre Winery</p></div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-5 col-lg-4">
                                <p><strong>Address:</strong></p>
                            </div>
                            <div className="col-12 col-md-7 col-lg-8">
                                <p>There is some very very long address,<br />Weynon, NY 124553</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-5 col-lg-4"><p><strong>County:</strong></p></div>
                            <div className="col-12 col-md-7 col-lg-8"><p>Albany</p></div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-xl-6 mt-4 pt-2">
                    <div className="pe-xl-4">
                        <div className="mb-4">
                            <h5 className="fw-bold mb-3">License settings</h5>
                            <hr />
                        </div>

                        <div className="form-label-with-tooltip">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="CheckChecked" checked />
                                <label className="form-check-label" htmlFor="CheckChecked">
                                    Automation certification
                                </label>
                            </div>
                            <div className="tooltip-wr tooltip-wr-small ms-2">
                                <span className="tooltip-icon bi-question-circle-fill"></span>
                                <div className="tooltip-msg text-center">
                                    <p>Products in the price posting will be automatically checked and certified each month</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mt-4 pt-2">
                <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                    <a className="btn btn-outline-secondary w-100" href="/">Cancel</a>
                </div>
                <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                    <a className="btn btn-primary w-100" href="/">Save</a>
                </div>
            </div>
        </div>
    </div>;
};

export default AdminView;
