import React, { Fragment, useEffect,  useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Badge, Nav, Tab }            from "react-bootstrap";
import BatchService                   from "services/wholesaler/BatchDelinquentService";
import Paginator                      from "components/Paginator";
import { PageableRequest }            from "models/requests/PageableRequest";
import { BatchDelinquentRecordsPreview } from "models/response/BatchDelinquentRecordsPreview";
import { PageInfo }                   from "models/PageInfo";
import { DEFAULT_PAGE_LIMIT }         from "api/HttpClient";
import URLUtil                        from "utils/URLUtil";
import { WHOLESALER_PATHS }           from "pages/wholesaler/WholesalerRoutes";
import { ROOT_PATHS } from "index";
import { useToastStore } from "store/ToastStoreProvider";
import Spinner from "components/Spinner";

const CORRECT_TAB_NAME = "correct";
const WRONG_TAB_NAME = "wrong";

const BatchPreview = () => {

    const navigate = useNavigate();
    const { addDefaultDangerToast } = useToastStore();
    const { jobId = "" } = useParams<{ jobId: string }>() ;

    if (!jobId)
        navigate(ROOT_PATHS.NOT_FOUND);

    type PageAndTab =  {page: number,  tab: string}
    type DRRequest = PageInfo & { tab: string } ;
    const urlParams = URLUtil.readSearchParams() as DRRequest;
    const pageLimit = urlParams?.limit || DEFAULT_PAGE_LIMIT;

    const [ activeTab, setActiveTab ] = useState<string>(urlParams?.tab || CORRECT_TAB_NAME);
    const [ criticalError, setCriticalError ] = useState<string>();
    const [ isLoading, setIsLoading ] = useState<boolean>(false);

    const [ correctPart, setCorrectPart ]  = useState<BatchDelinquentRecordsPreview>({
        pageInfo:{
            page: (urlParams?.tab === CORRECT_TAB_NAME && urlParams?.page) || 1 ,
            pagesCount: 0,
            totalItemsCount: 0
        },
        data:[]
    });

    const [ wrongPart, setWrongPart ]  = useState<BatchDelinquentRecordsPreview>({
        pageInfo:{
            page: (urlParams?.tab === WRONG_TAB_NAME && urlParams?.page) || 1 ,
            pagesCount: 0,
            totalItemsCount: 0
        },
        data:[]
    });

    useEffect(()=> {
        navigateTab({ page: correctPart.pageInfo.page, tab: CORRECT_TAB_NAME });
        navigateTab({ page: wrongPart.pageInfo.page, tab: WRONG_TAB_NAME });
    },[]);

    useEffect(()=>
        URLUtil.updateUrlParams(WHOLESALER_PATHS.DELINQUENT.BATCH_PREVIEW.replace(":jobId", jobId),
            { "page": activeTab === CORRECT_TAB_NAME ? correctPart.pageInfo.page : wrongPart.pageInfo.page,
                "limit": pageLimit,
                "tab": activeTab }, navigate)
    ,[correctPart.pageInfo.page, wrongPart.pageInfo.page, activeTab]);

    function navigateTab(pageAndTab: PageAndTab ){
        const request = {
            filters: { correct: pageAndTab.tab === CORRECT_TAB_NAME },
            pageInfo: { page: pageAndTab.page, limit: pageLimit }
        } as PageableRequest;

        BatchService.show(Number(jobId), request)
            .then(resp => {
                if (resp.criticalError){
                    setCriticalError(resp.criticalError);
                    return;
                }

                pageAndTab.tab === CORRECT_TAB_NAME
                    ? setCorrectPart(resp)
                    : setWrongPart(resp);
            });
    }

    function finishImport(){
        setIsLoading(true);
        BatchService.finish(Number(jobId))
            .catch(() => addDefaultDangerToast("Batch import."))
            .finally(() => {
                setIsLoading(false);
                navigate(WHOLESALER_PATHS.DELINQUENT.BATCH_HISTORY);
            });
    }

    function startOver(){
        setIsLoading(true);
        BatchService.cancel(Number(jobId))
            .catch(() => addDefaultDangerToast("Batch import."))
            .finally(()=> {
                setIsLoading(false);
                navigate(WHOLESALER_PATHS.DELINQUENT.BATCH);
            });
    }

    function getDownloadWrongCsvLink(){
        return BatchService.wrongCsvLink(parseInt(jobId));
    }

    return <div className="d-flex flex-column min-vh-100">
        <div className="mb-3">
            <h1 className="text-primary mb-4">Delinquent Records - Batch Submission</h1>
            <p className="mb-0">Below are the results of your batch submission. The list includes both correct and
                incorrect entries for your review.</p>
            <ul>
                <li><strong>Correct records</strong> pass validation and are ready for import.</li>
                <li><strong>Incorrect records</strong> do not pass our validation criteria and will be ignored.</li>
            </ul>
            <p className="mb-0">Please use the options below to manage your batch:</p>
            <ul>
                <li><strong>Start Over:</strong> Click this button if you wish to start fresh. This will not create
                    any new records, and will
                    take you back to the initial file selection step.
                </li>
                <li><strong>Finish Import:</strong> Once you&apos;re satisfied with your correct records, click this
                    button to finalize the
                    import. This will automatically create new records from the correct entries and dismiss any
                    incorrect
                    entries.
                </li>
            </ul>

            <p className="mb-0"> Remember, only the <strong>correct records</strong> are imported, ensuring the integrity of your
                data. Plan your batch submission accordingly.
            </p>
        </div>
        <div className="mb-4">
            <a
                className="btn btn-outline-primary me-3 mb-3" target="_blank" rel="noreferrer"
                href="https://support.nyslapricepostings.com/s/article/Credit-Reporting-Tutorial-for-Wholesalers">
                Read more about Batch Submission
            </a>
            <a className="btn btn-outline-primary mb-3" download href="/files/batch_template.csv">CSV Template</a>
        </div>
        <div className="steps-wr mb-4">
            <div className="steps-l row g-0">
                <div className="steps-li col-12 col-xl-3">
                    <div className="steps-li-in">
                        <strong>1</strong> Select CSV file to upload
                    </div>
                </div>
                <div className="steps-li col-12 col-xl-3 active">
                    <div className="steps-li-in">
                        <strong>2</strong> Preview batch submission
                    </div>
                </div>
            </div>
        </div>

        { criticalError &&
            <div className="alert alert-danger mb-4" role="alert">
                <i className="bi bi-exclamation-circle-fill me-2"/>
                <strong>Import error:</strong> Oops,your file was not successfully uploaded. Please make the corrections and
                try again.
                <div className="invalid-message alert alert-sm mt-3 mb-0 pb-0 ps-4">
                    <p className="text-danger"> { criticalError } </p>
                </div>
            </div>
        }

        <Tab.Container activeKey={ activeTab } onSelect={ (k) => setActiveTab(k || CORRECT_TAB_NAME ) }>
            <div className="card mt-3">
                <div className="card-body">
                    <div className="mb-3">
                        <h4><strong>Step 2:</strong> Preview batch submission</h4>
                    </div>
                    <Nav variant="pills" className={ "flex-column flex-lg-row d-lg-inline-flex mb-0" } fill >
                        <Nav.Item className={ "min-wp-230 " } role="button">
                            <Nav.Link eventKey={ CORRECT_TAB_NAME }>
                                Correct Records <Badge pill bg="success">{ correctPart.pageInfo.totalItemsCount }</Badge>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className={ "min-wp-230 " } role="button">
                            <Nav.Link eventKey={ WRONG_TAB_NAME }>
                                Incorrect Records <Badge pill bg="danger">{ wrongPart.pageInfo.totalItemsCount }</Badge>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
            </div>
            <Tab.Content className={ "mt-4" }>
                <Tab.Pane eventKey={ CORRECT_TAB_NAME } title="Correct Records" className="min-wp-230" role="tab" >
                    <div>
                        <div className="pt-3">
                            <h5 className="mb-3"><strong>Total records:</strong> { correctPart.pageInfo.totalItemsCount } </h5>
                        </div>
                        { correctPart.data && correctPart.data.length > 0 &&
                            <div>
                                <div className="table-responsive mb-4">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Intent</th>
                                                <th>Wholesaler_License</th>
                                                <th>Retailer_License</th>
                                                <th>Delivery_Amount</th>
                                                <th>Delivery_Date</th>
                                                <th>Comment</th>
                                                <th>Retailer_Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { correctPart.data.map((rec, idx) =>
                                                <tr key={ idx }>
                                                    <td>{ rec.record.intent }</td>
                                                    <td>{ rec.record.wholesalerLicense }</td>
                                                    <td>{ rec.record.retailerLicense }</td>
                                                    <td>{ rec.record.deliveryAmount }</td>
                                                    <td>{ rec.record.deliveryDate }</td>
                                                    <td>{ rec.record.comment }</td>
                                                    <td>{ rec.record.retailerName }</td>
                                                </tr>
                                            ) }
                                        </tbody>
                                    </table>
                                </div>
                                <div>
                                    <Paginator totalPages={ correctPart.pageInfo.pagesCount || 0 } currentPage={ correctPart.pageInfo.page }
                                        pageClicked={ (page:number)=>navigateTab({ page:page, tab: CORRECT_TAB_NAME }) }/>
                                </div>
                            </div>
                        }
                    </div>
                </Tab.Pane>
                <Tab.Pane eventKey={ WRONG_TAB_NAME } title="Incorrect Records" className="min-wp-230" role="tab" >
                    <div>
                        <div className="pt-3 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                            <h5 className="mb-3"><strong>Total records:</strong> { wrongPart.pageInfo.totalItemsCount } </h5>
                            { wrongPart.pageInfo.totalItemsCount !== undefined && wrongPart.pageInfo.totalItemsCount > 0 &&
                                <a download type="text/csv" className="mb-3" href={ getDownloadWrongCsvLink() }>
                                    Download file with invalid records
                                </a>
                            }
                        </div>

                        { wrongPart.data && wrongPart.data.length > 0 &&
                            <>
                                <div className="table-responsive mb-2">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Intent</th>
                                                <th>Wholesaler_License</th>
                                                <th>Retailer_License</th>
                                                <th>Delivery_Amount</th>
                                                <th>Delivery_Date</th>
                                                <th>Comment</th>
                                                <th>Retailer_Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                wrongPart.data.map((rec, idx) =>
                                                    <Fragment key={ idx }>
                                                        <tr>
                                                            <td>{ rec.record.intent }</td>
                                                            <td>{ rec.record.wholesalerLicense }</td>
                                                            <td>{ rec.record.retailerLicense }</td>
                                                            <td>{ rec.record.deliveryAmount }</td>
                                                            <td>{ rec.record.deliveryDate }</td>
                                                            <td>{ rec.record.comment }</td>
                                                            <td>{ rec.record.retailerName }</td>
                                                        </tr>
                                                        {
                                                            (rec.errors || []).map((error, index) =>
                                                                <tr key={ index }>
                                                                    <td colSpan={ 7 } className="p-0">
                                                                        <div className="alert-danger p-2">
                                                                            <i className="bi-arrow-up me-2 fw-bold"/>
                                                                            { error }
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </Fragment>
                                                ) }
                                        </tbody>
                                    </table>
                                </div>
                                <p className="text-danger mb-4">Please correct the errors in CSV file and try uploading again</p>

                                <div>
                                    <Paginator totalPages={ wrongPart.pageInfo.pagesCount || 0 } currentPage={ wrongPart.pageInfo.page }
                                        pageClicked={ (page:number)=>navigateTab({ page:page, tab: WRONG_TAB_NAME }) }/>
                                </div>
                            </>
                        }
                    </div>
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container>

        <div className="row justify-content-center">
            <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                <button onClick={ startOver } className="btn w-100 btn-outline-secondary">Start over</button>
            </div>
            { correctPart.pageInfo.totalItemsCount !== undefined && correctPart.pageInfo.totalItemsCount > 0 &&
                <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                    <button onClick={ finishImport } className="btn btn-primary w-100">Finish import</button>
                </div>
            }
            <Spinner className="mt-2 text-center" hidden={ !isLoading }/>
        </div>

    </div>;
};

export default BatchPreview;
