import { Controller } from "react-hook-form";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import React from "react";
import classNames from "classnames";

type Props = {
    name: string,
    control: any,
    label?: string,
    rules?: any
    errors?: any
} & Omit<ReactDatePickerProps<string,boolean>, "name" | "dateFormat" | "startDate" | "endDate" | "onChange" | "className" | "selectsRange">;

const DatePickerRangeInput = ({ control, name, label, rules, errors, ...props }: Props) => {

    const feedbackId = name + "Feedback";
    let errorMessage;
    if (errors && errors.hasOwnProperty(name)) {
        errorMessage = errors[name].message;
    }
    const isInvalid = errorMessage || false;
    return <div className="form-group form-group-datapicker">
        { label && <label htmlFor={ `dpicker-range-${name}` } className="form-label">{ label }</label> }
        <Controller
            control={ control }
            name={ name }
            rules={ rules }
            render={ ({ field: { onChange, value } }) => (
                <DatePicker
                    id={ `dpicker-range-${name}` }
                    className={  classNames( { "is-invalid": isInvalid }) }
                    onChange={ onChange }
                    startDate={ value ? value[0] : null }
                    endDate={ value ? value[1] : null }
                    dateFormat="MM/dd/yyyy"
                    selectsRange={ true }
                    { ...props }
                />
            ) }
        />
        <div id={ feedbackId } className="invalid-feedback">
            { errorMessage }
        </div>
    </div>;
};

export default DatePickerRangeInput;
