import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { LoggedInUserInfo, UserLicense } from "models/LoggedInUserInfo";
import { LoginModel } from "pages/auth/models/LoginModel";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";
import LoginForm from "pages/auth/components/LoginForm";
import { ResendConfirmation } from "pages/auth/components/ResendConfirmation";
import LicenseSelectForm from "pages/auth/components/LicenseSelectForm";
import AuthService from "services/AuthService";
import { redirectToHomePage } from "services/RedirectService";
import { useAuthStore } from "store/AuthStoreProvider";
import { useToastStore } from "store/ToastStoreProvider";
import LegacyChangeEmailForm, { NEW_EMAIL_IS_UNAVAILABLE } from "pages/auth/components/LegacyChangeEmailForm";
import { useAlertStore } from "store/AlertStoreProvider";

enum VISIBLE_FORM { LOGIN_FORM, RESEND_EMAIL_FORM, LICENSE_SELECT_FORM, LEGACY_CHANGE_EMAIL_FORM}

export type DisplayedError = {
    code?:number,
    message?:string
}

const Login = () => {

    const { addToast } = useToastStore();
    const authStore = useAuthStore();
    const navigate = useNavigate();
    const location = useLocation();
    const { updateAlerts } = useAlertStore();
    const query = new URLSearchParams(location.search);
    const [ visibleForm, setVisibleForm] = useState<VISIBLE_FORM>(VISIBLE_FORM.LOGIN_FORM);
    const [ licenseList, setLicenseList ] = useState<UserLicense[] | undefined>();
    const [ displayedError, setDisplayedError] = useState<DisplayedError>();
    const [ isPending, setIsPending] = useState<boolean>(false);

    const formReturn = useForm<LoginModel>({
        mode: "onChange",
        defaultValues: {}
    });
    const [licenseId, email] = formReturn.watch(["license_id","email"]);

    const attemptAuthenticate: SubmitHandler<LoginModel> = async data => {
        if (isPending){
            return;
        }
        setIsPending(true);
        setDisplayedError(undefined);
        setLicenseList(undefined);
        AuthService.authenticate(data)
            .then((resp: LoggedInUserInfo) => {
                authStore.setAuthorizedSilently(resp);
                updateAlerts();
                query.has("redirect") ? navigate(-1) : redirectToHomePage(authStore.permissionStore, navigate);
            })
            .catch((error) => {
                switch (error.status){
                case 403: setVisibleForm(VISIBLE_FORM.RESEND_EMAIL_FORM); break;
                case 409:
                    if (licenseId){
                        addToast({ header: "Login", message:"The license you specified is no longer active", variant: "warning", timeoutMs: DEFAULT_TOASTER_TIMEOUT });
                        formReturn.setValue("license_id", undefined);
                    }
                    setLicenseList(error.data);
                    setVisibleForm(VISIBLE_FORM.LICENSE_SELECT_FORM);
                    break;
                case 405:
                    formReturn.setValue("new_email",email);
                    setVisibleForm(VISIBLE_FORM.LEGACY_CHANGE_EMAIL_FORM);
                    break;
                case 432:
                    formReturn.setError("new_email", { type: "manual", message: NEW_EMAIL_IS_UNAVAILABLE });
                    setVisibleForm(VISIBLE_FORM.LEGACY_CHANGE_EMAIL_FORM);
                    break;
                case 433:
                    addToast({ header: "Login", message:"A confirmation email has been sent to your inbox. Please follow the instructions in that letter.", variant: "success" });
                    formReturn.reset();
                    setVisibleForm(VISIBLE_FORM.LOGIN_FORM);
                    break;
                case 406:
                case 400:
                default:
                    formReturn.setValue("license_id", undefined);
                    setVisibleForm(VISIBLE_FORM.LOGIN_FORM);
                    setDisplayedError({ "code": error.status, "message": error.data?.message });
                    break;
                }
            })
            .finally(() => setIsPending(false));
    };

    const onResendEmailComplete = ()=>{
        formReturn.reset();
        setVisibleForm(VISIBLE_FORM.LOGIN_FORM);
    };

    useEffect(() => {
        if (query.has("sessionExpired")) {
            addToast({ header: "Transfer delinquent data", message:"Your session has expired. Please login again", variant: "success", timeoutMs: DEFAULT_TOASTER_TIMEOUT });
        }
    }, []);

    switch (visibleForm) {
    case VISIBLE_FORM.LOGIN_FORM:
        return <div><LoginForm form={ formReturn } displayedError={ displayedError } onSubmit={ attemptAuthenticate } isPending={ isPending }/></div>;
    case VISIBLE_FORM.RESEND_EMAIL_FORM:
        return <div><ResendConfirmation email={ email } onComplete={ onResendEmailComplete }/></div>;
    case VISIBLE_FORM.LICENSE_SELECT_FORM:
        return <div>
            <LicenseSelectForm list={ licenseList } pending={ isPending } form={ formReturn } onContinue={ formReturn.handleSubmit(attemptAuthenticate) } />
        </div>;
    case VISIBLE_FORM.LEGACY_CHANGE_EMAIL_FORM:
        return <div><LegacyChangeEmailForm form={ formReturn } onSubmit={ attemptAuthenticate } isPending={ isPending }/></div>;
    }
};

export default Login;
