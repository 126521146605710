import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { APP_URL } from "config";
import { PageableRequest, pageableRequestToSearchParams } from "models/requests/PageableRequest";

export const DEFAULT_PAGE_LIMIT = 20;
const TIME_TO_LIFE_SESSION = 1800; // 30 mins in seconds
const AUTOMATED_REQUESTS: string[] = ["alerts/get-all"];

function validateStatusConfig(config?: object): object|undefined {
    return { ...config, validateStatus: function (status: number) {
        return (status >= 200 && status < 300 || status == 401);
    } };
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
export class HttpClient {

    private readonly axios: AxiosInstance;

    private _timeoutId: number | undefined;
    private _modalTimeoutId: number | undefined;
    private _timeoutFunction: TimerHandler | undefined;
    private _redirectToLogin: () => void;

    get axiosInstance() {
        return this.axios;
    }

    constructor() {
        this.axios = axios.create({
            baseURL: APP_URL,
            responseType: "json",
            headers: {
                "content-type": "application/json"
            },
            withCredentials: true
        });

        this.axios.interceptors.response.use(
            response => {
                this.check401(response);
                this.handleTTLHeader(response);

                return response;
            },
            error => {
                // Обработка Unauthorized error
                this.check401(error.response);

                return Promise.reject(error.response);
            }
        );

        this._redirectToLogin = () => {};
    }

    private handleTTLHeader(response: AxiosResponse){
        if (this._timeoutFunction === undefined){
            return;
        }

        // const ttl = response.headers["x-session-ttl"]; //back-end session
        if (AUTOMATED_REQUESTS.filter(value => response.config?.url?.includes(value)).length > 0){
            return;
        }

        if (window.location.pathname.includes("/auth/") || window.location.pathname === "/") {
            return;
        }

        this.clearTimeouts();
        this._timeoutId = window.setTimeout(this._timeoutFunction, (TIME_TO_LIFE_SESSION-60)*1000);//1 minute before session ends
    }

    public set timeoutFunction(tf: TimerHandler){
        this._timeoutFunction = tf;
    }

    public set modalTimeoutId(mt: number | undefined){
        this._modalTimeoutId = mt;
    }

    public clearModalTimeout(){
        if (this._modalTimeoutId != undefined) {
            window.clearTimeout(this._modalTimeoutId);
            this._modalTimeoutId = undefined;
        }
    }

    public clearTimeouts(){
        if (this._timeoutId != undefined) {
            window.clearTimeout(this._timeoutId);
            this._timeoutId = undefined;
        }

        this.clearModalTimeout();
    }

    public set redirectToLogin(f: () => void){
        this._redirectToLogin = f;
    }

    check401(response: AxiosResponse) {
        if (response && response.status === 401) {
            this._redirectToLogin();
        }
    }

    head<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return this.axios.head(url, validateStatusConfig(config));
    }

    options<T = any, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return this.axios.options(url, validateStatusConfig(config));
    }

    get = (url: string, config?: AxiosRequestConfig) => this.axios.get(url, validateStatusConfig(config)).then(r=>r.data);
    post = (url: string, data?: any, config?: AxiosRequestConfig) => this.axios.post(url, data, validateStatusConfig(config)).then(r=>r.data);
    put = (url: string, data?: any, config?: AxiosRequestConfig) => this.axios.put(url, data, validateStatusConfig(config)).then(r=>r.data);
    delete = (url: string, config?: AxiosRequestConfig) => this.axios.delete(url, validateStatusConfig(config)).then(r=>r.data);
    patch = (url: string, data?: any, config?: AxiosRequestConfig) => this.axios.patch(url, data, validateStatusConfig(config)).then((response: AxiosResponse) => response.data);

    search = (url: string, request: PageableRequest, config?: AxiosRequestConfig) => {
        const params = pageableRequestToSearchParams(request);
        if (config) {
            config.params = params;
        } else {
            config = { params: params };
        }
        return this.axios.get(url, validateStatusConfig(config)).then((response: AxiosResponse) => response.data);
    };
}

const httpClient = new HttpClient();
export default httpClient;
