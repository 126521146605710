import { action, computed, makeObservable, observable } from "mobx";
import AuthService from "services/AuthService";
import { PermissionStore } from "store/PermissionStore";
import { LoggedInUserInfo, UserLicense } from "models/LoggedInUserInfo";
import httpClient from "api/HttpClient";

export class AuthStore {

    @observable
    public permissionStore: PermissionStore = new PermissionStore();

    @observable
    public isAuthenticated = false;

    @observable
    public isAdminUnderUser = false;

    @observable
    public licenseId: number | undefined = undefined;

    @observable
    public licenses: UserLicense[] | undefined = undefined;

    @observable
    public hiddenAlerts: number[] = [];

    @observable
    public initialized = false;

    @observable
    public email: string | undefined = undefined;

    constructor() {
        makeObservable(this);
    }

    @computed
    get isNotAuthenticated() {
        return !this.isAuthenticated;
    }

    @action.bound
    public async logout() {
        this.reset();
        this.permissionStore.reset();

        AuthService.logout().then(() => httpClient.clearTimeouts());
    }

    @action
    public reset() {
        this.setIsAuthenticated(false);
        this.setIsAdminUnderUser(false);
        this.setLicenseId(undefined);
        this.setLicenses(undefined);
        this.setHiddenAlerts([]);
        this.setEmail(undefined);
    }

    @action
    public setLicenseId(licenseId: number | undefined) {
        this.licenseId = licenseId;
    }

    @action
    public setLicenses(licenses: UserLicense[] | undefined) {
        this.licenses = licenses;
    }

    @action
    public setHiddenAlerts(hiddenAlerts: number[]) {
        this.hiddenAlerts = hiddenAlerts || [];
    }

    @action
    public setIsAuthenticated(isAuthenticated: boolean) {
        this.isAuthenticated = isAuthenticated;
    }

    @action
    public setIsAdminUnderUser(isAdminUnderUser: boolean) {
        this.isAdminUnderUser = isAdminUnderUser;
    }

    @action
    public setInitialized(initialized: boolean) {
        this.initialized = initialized;
    }

    @action
    public setEmail(email: string | undefined) {
        this.email = email;
    }

    @action
    public setAuthorizedSilently(loggedInUserData: LoggedInUserInfo){
        this.setIsAuthenticated(loggedInUserData?.authenticated);
        this.setIsAdminUnderUser(loggedInUserData?.isAdminUnderUser);
        this.setEmail(loggedInUserData?.email);
        this.setLicenseId(loggedInUserData?.authorizedLicenseId);
        this.setLicenses(loggedInUserData?.licenses);
        this.setHiddenAlerts(loggedInUserData?.hiddenAlerts);
        this.permissionStore.setRoles(loggedInUserData?.roles || []);
        this.permissionStore.initialize();
        this.setInitialized(true);
    }

    @computed
    get isReady(): boolean {
        return this.initialized && this.permissionStore.isReady;
    }

    @computed
    get currentLicense(): UserLicense | undefined {
        return this.licenses?.find(l=>l.licenseId == this.licenseId);
    }
}
