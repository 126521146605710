import httpClient from "api/HttpClient";
import { PageableRequest, pageableRequestToQueryString } from "models/requests/PageableRequest";
import { PageableResponse } from "models/response/PageableResponse";
import { DelinquentSearchResponse } from "models/response/DelinquentSearchResponse";
import { ManageDelinquentResponse } from "models/response/ManageDelinquentResponse";
import BACKEND from "services/Constants";
import { AddNewDelinquentRequest } from "models/requests/AddNewDelinquentRequest";
import { RecordsResponse } from "components/WholesalerDelinquentRecords";
import { SearchDownloadDataModel } from "pages/wholesaler/delinquent/SearchDownload";
import { MessageModel } from "models/message.model";
import { TransferResult } from "models/response/TransferResult";

export default {

    searchDelinquent(request: PageableRequest): Promise<PageableResponse<DelinquentSearchResponse>> {
        return httpClient.search(BACKEND + "/wholesaler/delinquent/searchDelinquency", request);
    },

    searchDelinquentCsv(request: PageableRequest): string {
        return BACKEND + "/wholesaler/delinquent/searchDelinquency.csv?" + pageableRequestToQueryString(request);
    },

    searchDelinquentTxt(request: PageableRequest): string {
        return BACKEND + "/wholesaler/delinquent/searchDelinquency.txt?" + pageableRequestToQueryString(request);
    },

    searchDownload(request: PageableRequest): Promise<PageableResponse<SearchDownloadDataModel>> {
        return httpClient.post(BACKEND + "/wholesaler/delinquent/searchDownload", request);
    },

    searchDownloadCSV(request: PageableRequest): Promise<any> {
        return httpClient.post(BACKEND + "/wholesaler/delinquent/searchDownload.csv", request, { responseType:"blob" });
    },

    searchRecords(request: PageableRequest): Promise<PageableResponse<RecordsResponse>> {
        return httpClient.search(BACKEND + "/wholesaler/delinquent-records/delinquentRecords", request);
    },

    searchRecordsCsv(request: PageableRequest): string {
        return BACKEND + "/wholesaler/delinquent-records/delinquentRecords.csv?" + pageableRequestToQueryString(request);
    },

    searchRecordsTxt(request: PageableRequest): string {
        return BACKEND + "/wholesaler/delinquent-records/delinquentRecords.txt?" + pageableRequestToQueryString(request);
    },

    fetchCurrentWholesalerRecord(delinquentRecordId: number): Promise<ManageDelinquentResponse> {
        return httpClient.get(BACKEND + "/wholesaler/delinquent-records/delinquentSingleRecord?delinquentRecordId="+delinquentRecordId);
    },

    getLicenseUpdateDate(): Promise<number> {
        return httpClient.get(BACKEND + "/wholesaler/delinquent-records/info");
    },

    addDelinquent(data: AddNewDelinquentRequest): Promise<void> {
        return httpClient.post(BACKEND + "/wholesaler/delinquent-records/add", data);
    },

    updateDelinquent(data: any): Promise<void> {
        return httpClient.post(BACKEND + "/wholesaler/delinquent-records/update", data);
    },

    deleteDelinquent(delinquentRecordId: number): Promise<MessageModel> {
        return httpClient.post(BACKEND + "/wholesaler/delinquent-records/delete?delinquentRecordId="+delinquentRecordId);
    },

    deleteForLicense(data: { licenseId: number, dryRun: boolean }): Promise<TransferResult> {
        return httpClient.post(BACKEND + "/wholesaler/delinquent-records/deleteForLicense",data);
    },

    getAdminEmail(): Promise<string> {
        return httpClient.get(BACKEND + "/wholesaler/delinquent-records/admin-email");
    }
};
