import { Navigate, Outlet } from "react-router-dom";
import { useAuthStore } from "store/AuthStoreProvider";
import { AUTH_PATHS } from "pages/auth/AuthRoutes";

const AuthenticatedRoute = () => {
    const { isNotAuthenticated } = useAuthStore();

    if (isNotAuthenticated) {
        return <Navigate to={ AUTH_PATHS.LOGIN + "?redirect=true" }/>;
    }

    return <Outlet />;
};

export default AuthenticatedRoute;
