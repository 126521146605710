import React, { useEffect, useState } from "react";
import { ControllerSelectProps, SelectControlled, SelectOption } from "components/form/Select";
import ProductService from "services/ProductService";
import { PostCountModel } from "pages/admin/TransferScheduleData";

export type SelectProductTypeProps = Omit<ControllerSelectProps, "options"> & {
    post_counts?: PostCountModel[]
};

const SelectTransferMonth = (props : SelectProductTypeProps) => {
    const [options, setOptions] = useState<SelectOption[]>([]);

    useEffect(() => {
        if (props.post_counts) {
            const opts: SelectOption[] = ProductService.getPostTypesSync()
                .filter(({ code }) => props.post_counts?.find(value => value.code === code) != undefined)
                .map(m=> m.toSelectOption())
                .map(t => ({ ...t, title: t.title + " Price" }));
            opts.unshift({ value: "", title: "Select type" });
            setOptions(opts);
        }
    }, [props.post_counts]);

    return <SelectControlled label="Select type" options={ options } { ...props } />;
};

export default SelectTransferMonth;
