import React from "react";
import { MenuSettings, useLeftMenu } from "components/menu/Menu";
import DelinquentRetailerRecordsView from "pages/statics/retailer-delinquent/DelinquentRetailerRecordsView";
import dayjs from "dayjs";
import { Route, Routes } from "react-router-dom";

export const DELINQUENT_RETAILER_PATHS = {
    MAIN: "/delinquent-retailer",
    RECORDS: "/records"
};

const LEFT_MENU: MenuSettings = {
    caption: "Retailer Menu",
    items: [
        {
            title: "Pricing for " + dayjs().format("MMMM YYYY"),
            link: "PRICES_PATHS.LOOKUP"
        },
        {
            title: "Search Pricing ",
            link: "RETAILER_PRICES_PATHS.SEARCH"
        },
        {
            title: "Admin reports",
            link: "RETAILER_ADMIN_PATHS.REPORTS"
        },
        { title: "View Delinquent Data", link: "/change-link" }
    ]
};

const DelinquentRetailerStaticRoutes = () => {
    
    useLeftMenu(LEFT_MENU);

    return <Routes>
        <Route path={ DELINQUENT_RETAILER_PATHS.RECORDS } element={ <DelinquentRetailerRecordsView/> }/>
    </Routes>;
};

export default DelinquentRetailerStaticRoutes;
