import httpClient from "api/HttpClient";
import { PageableRequest }   from "models/requests/PageableRequest";
import { PageableResponse }  from "models/response/PageableResponse";
import { BatchOperationResult } from "models/BatchProductsOperationResult";
import { ProductModel } from "models/ProductModel";
import BACKEND from "services/Constants";
import { Discount } from "models/Discount";
import StoredFile from "pages/wholesaler/priceposting/models/StoredFile";
import { PostTypeModel } from "models/PostTypeModel";
import { Correctness } from "pages/wholesaler/priceposting/pages/batch/components/ProductList";

export type StoredFileType = "BATCH_PRICEPOSTING" | "BATCH_DISCOUNT";

export default {

    checkLock(type?: PostTypeModel): Promise<boolean> {
        return httpClient.get(`${BACKEND}/batch-price-posting/check-import-lock${type?("?type="+type.code):""}`);
    },

    productResults(request: PageableRequest): Promise<PageableResponse<ProductModel>> {
        return httpClient.search( `${BACKEND}/batch-price-posting/product-results`, request);
    },

    discountResults(request: PageableRequest): Promise<PageableResponse<Discount>> {
        return httpClient.search( `${BACKEND}/batch-price-posting/discount-results`, request);
    },

    discounts(request: FormData): Promise<BatchOperationResult> {
        return httpClient.post( `${BACKEND}/batch-price-posting/discounts`, request);
    },

    products(request: FormData): Promise<BatchOperationResult> {
        return httpClient.post(`${BACKEND}/batch-price-posting/products`, request);
    },

    async compress(file: File, encoding: CompressionFormat = "gzip") {
        try {
            return await new Response(file.stream().pipeThrough(new CompressionStream(encoding)), {}).blob();
        }
        catch (error) {
            return file;
        }
    },

    listFiles(request: PageableRequest): Promise<PageableResponse<StoredFile>> {
        return httpClient.search(`${BACKEND}/batch-price-posting/listFiles`, request);
    },

    downloadFileLink(id: number): string {
        return `${BACKEND}/batch-price-posting/download/${id}`;
    },

    downloadDiscountsResultsFileLink( startMillis: number, stopMillis: number, correct: Correctness): string {
        return `${BACKEND}/batch-price-posting/discount-results.csv?correct=${correct}&createdAtStart=${startMillis}&createdAtStop=${stopMillis}`;
    }
};
