import httpClient from "api/HttpClient";
import BACKEND from "services/Constants";
import { PageableResponse } from "models/response/PageableResponse";
import { PageableRequest } from "models/requests/PageableRequest";
import { AdminLicenseModel } from "pages/admin/Licenses";
import { ShortLicenseInfo } from "models/license.model";

export default {
    lookup(request: PageableRequest): Promise<PageableResponse<AdminLicenseModel>> {
        return httpClient.search(BACKEND + "/admin/licenses/lookup", request);
    },
    shortLicenseInfoBySerialOrPermit(pattern: string): Promise<ShortLicenseInfo[]> {
        return httpClient.get(BACKEND + "/admin/licenses/shortLicenseInfoBySerialOrPermit", { params: { pattern: pattern } });
    },

    shortLicenseInfoById(licenseId: number): Promise<ShortLicenseInfo> {
        return httpClient.get(BACKEND + "/admin/licenses/short-license-info-by-id", { params: { license_id: licenseId } });
    }
};
