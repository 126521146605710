import React, { forwardRef, useImperativeHandle } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { ProductItemForm } from "pages/wholesaler/priceposting/pages/brands/components/ProductItemForm";
import { CollapseModel } from "pages/wholesaler/priceposting/models/CollapseModel";

const RegularVapStep2 = ({ collapseModel }: { collapseModel: CollapseModel }, ref: any) => {

    useImperativeHandle(ref, () => ({
        append: append
    }));

    const formReturn = useFormContext();
    const { fields, append, remove } = useFieldArray({ control: formReturn.control, name: "items" });

    const removeProductItem = (index: number) => {
        if (fields.length>1) {
            remove(index);
        }
    };

    return <>{
        fields.map((field, index) => (
            <ProductItemForm
                key={ field.id } // important to include key with field's id
                index = { index }
                onRemove = { removeProductItem }
                collapseModel={ collapseModel }
            />
        ))
    }
    </>;
};

export default forwardRef(RegularVapStep2);
