import React, { useEffect } from "react";
import "styles/bootstrap.scss";
import "react-datepicker/dist/react-datepicker.css";
import Footer from "components/Footer";
import Header from "components/Header";
import LeftMenu from "components/menu/LeftMenu";
import Spinner from "components/Spinner";
import { useMenuStore } from "store/MenuStoreProvider";
import { useModalStore } from "store/ModalStoreProvider";
import classNames from "classnames";
import { observer } from "mobx-react";
import ModalWindow from "components/ModalWindow";
import Toaster from "components/Toaster";
import { useAuthStore } from "store/AuthStoreProvider";
import NotificationBar from "components/NotificationBar";
import httpClient from "api/HttpClient";
import AuthService from "services/AuthService";
import AppRoutes from "AppRoutes";
import { AUTH_PATHS } from "pages/auth/AuthRoutes";
import { useLocation, useNavigate } from "react-router-dom";
import { redirectToHomePage } from "services/RedirectService";

const App = () => {

    const authStore = useAuthStore();
    const menuStore = useMenuStore();
    const modalStore = useModalStore();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            navigate(location.hash.replace("#", "").replace("//", "/"));
        } else if (location.pathname === "/") {
            authStore.isAuthenticated
                ? redirectToHomePage(authStore.permissionStore, navigate)
                : navigate(AUTH_PATHS.LOGIN);
        } else if (location.pathname.includes("//")) {
            navigate(location.pathname.replace("//", "/"));
        }
    }, [navigate, location]);

    if (!authStore.isReady) {
        return <Spinner className="mt-4 pt-2 text-center" />;
    }

    const mainClasses = classNames({ "without-aside": menuStore.isLeftMenuHidden });

    function onClick() {
        const collapseButton = document.getElementById("collapse-main");
        if (collapseButton) {
            if (collapseButton.classList.contains("main-full")) {
                collapseButton.classList.remove("main-full");
            } else {
                collapseButton.classList.add("main-full");
            }
        }
    }

    httpClient.timeoutFunction = ()=>{
        httpClient.modalTimeoutId = window.setTimeout(unattendedLogout, 60*1000);//one minute left to navigating to login page

        modalStore.setHeader("Session");
        modalStore.setBody(<>Your session is about to expire,  keep the session?</>);
        modalStore.setAdditionalButtons([<button key={ 0 } type="button" className="btn btn-primary" onClick={ ()=>extendTimeout() }>Yes</button>]);
        modalStore.showModal();
    };

    httpClient.redirectToLogin = () => navigateToLogin;

    function unattendedLogout(){
        const isAdminUnderUser = authStore.isAdminUnderUser;
        authStore.logout().then(()=>{
            navigateToLogin(isAdminUnderUser);
            modalStore.hideModal();
        });
    }

    function navigateToLogin(isAdminUnderUser?: boolean) {
        if (isAdminUnderUser) {
            navigate(AUTH_PATHS.LOGIN);
        } else if (location.pathname != AUTH_PATHS.LOGIN) {
            navigate(AUTH_PATHS.LOGIN + "?redirect=true&sessionExpired=true");
        }
    }

    function extendTimeout(){
        modalStore.hideModal();
        AuthService.currentUser();//just to prolong server session and refresh http client timer
    }

    return (
        <div className="d-flex flex-column min-vh-100">
            <Header/>
            <main id="collapse-main" className={ mainClasses }>
                <div className="bg-aside">
                    <div className="bg-aside-collapse">
                        <button
                            onClick={ () => onClick() } className="d-none d-lg-block btn-aside btn-toggle"
                            data-bs-toggle="collapse"
                            data-bs-target="#aside-collapse"
                            aria-expanded="true" aria-controls="aside-collapse">
                            <i className="bi bi-chevron-left">&nbsp;</i>
                        </button>
                        <div className="collapse collapse-horizontal show" id="aside-collapse">
                            <LeftMenu/>
                        </div>
                    </div>
                </div>
                <div className="content-wrapper">
                    <div className="notifications-container">
                        <div className="content-notifications">
                            <NotificationBar/>
                        </div>
                    </div>
                    <div className="content">
                        <div className="container py-5">
                            <AppRoutes />
                        </div>
                    </div>
                </div>
            </main>
            <Footer/>
            <ModalWindow />
            <Toaster/>
        </div>
    );
};

export default observer(App);
