import React from "react";
import LicenseInfo from "components/LicenseInfo";
import RetailerDelinquentRecords from "components/RetailerDelinquentRecords";
import { useAuthStore } from "store/AuthStoreProvider";

const Records = () => {

    const authStore = useAuthStore();

    return <div className="d-flex flex-column min-vh-100">
        <div className="mb-4 mb-md-5">
            <h1 className="text-primary mb-4">Delinquent Data</h1>
        </div>
        <LicenseInfo licenseId={ authStore.licenseId || -1 } showDelinquentStatus={ true }/>
        <RetailerDelinquentRecords/>
    </div>;
};

export default Records;
