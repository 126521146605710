import React from "react";

const DelinquentRetailerRecordsView = () => {

    return <div className="d-flex flex-column min-vh-100">
        <div className="mb-4 mb-md-5">
            <h1 className="text-primary mb-4">Delinquent Data</h1>
            <p className="mb-0">Please select the delinquent list you would like to view.</p>
        </div>
        <div className="card">
            <div className="card-body pb-2">
                <form className="">
                    <div className="row">
                        <div className="col-12 col-md-6 col-xl-4">
                            <div className="form-group">
                                <label htmlFor="Select" className="form-label">Delinquent list</label>
                                <select id="Select" className="form-select" aria-label="Select">
                                    <option selected>Select</option>
                                    <option value="1">Current</option>
                                    <option value="2">Archived</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div className="mt-2">
            <div className="table-responsive mt-4">
                <table className="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Wholesale Serial #</th>
                            <th>Wholesaler Name</th>
                            <th>Amount</th>
                            <th>Delivery Date</th>
                            <th>Date Added</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <a href="/" data-bs-toggle="modal" data-bs-target="#exampleModal">87217875</a>
                            </td>
                            <td>
                                <a href="/" data-bs-toggle="modal" data-bs-target="#exampleModal">Wholesaler long Name</a>
                            </td>
                            <td>$ 2423.75</td>
                            <td>02/04/2022</td>
                            <td>02/14/2022</td>
                        </tr>
                        <tr>
                            <td>
                                <a href="/" data-bs-toggle="modal" data-bs-target="#exampleModal">16288775</a>
                            </td>
                            <td>
                                <a href="/" data-bs-toggle="modal" data-bs-target="#exampleModal">Wholesaler very very long Name</a>
                            </td>
                            <td>$ 423.82</td>
                            <td>08/24/2022</td>
                            <td>11/30/2022</td>
                        </tr>
                        <tr>
                            <td>
                                <a href="/" data-bs-toggle="modal" data-bs-target="#exampleModal">21788775</a>
                            </td>
                            <td>
                                <a href="/" data-bs-toggle="modal" data-bs-target="#exampleModal">Wholesaler long Name</a>
                            </td>
                            <td>$ 2423.75</td>
                            <td>02/04/2022</td>
                            <td>02/14/2022</td>
                        </tr>
                        <tr>
                            <td>
                                <a href="/" data-bs-toggle="modal" data-bs-target="#exampleModal">17884775</a>
                            </td>
                            <td>
                                <a href="/" data-bs-toggle="modal" data-bs-target="#exampleModal">Wholesaler very very long Name</a>
                            </td>
                            <td>$ 423.82</td>
                            <td>08/24/2022</td>
                            <td>11/30/2022</td>
                        </tr>
                        <tr>
                            <td>
                                <a href="/" data-bs-toggle="modal" data-bs-target="#exampleModal">31788775</a>
                            </td>
                            <td>
                                <a href="/" data-bs-toggle="modal" data-bs-target="#exampleModal">Wholesaler long Name</a>
                            </td>
                            <td>$ 2423.75</td>
                            <td>02/04/2022</td>
                            <td>02/14/2022</td>
                        </tr>
                        <tr>
                            <td>
                                <a href="/" data-bs-toggle="modal" data-bs-target="#exampleModal">88721775</a>
                            </td>
                            <td>
                                <a href="/" data-bs-toggle="modal" data-bs-target="#exampleModal">Wholesaler very very long Name</a>
                            </td>
                            <td>$ 423.82</td>
                            <td>08/24/2022</td>
                            <td>11/30/2022</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">1 Main lake (2171187)</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Cancel"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12 col-xl-6 mb-4 mb-xl-0">
                                <div className="pe-xl-4">
                                    <div className="mb-4">
                                        <h5 className="fw-bold mb-3">License information for 07/26/2022</h5>
                                        <hr/>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-5 col-lg-4">
                                            <p><strong>Serial number:</strong></p>
                                        </div>
                                        <div className="col-12 col-md-7 col-lg-8">
                                            <p>8000001</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-5 col-lg-4">
                                            <p><strong>License type:</strong></p>
                                        </div>
                                        <div className="col-12 col-md-7 col-lg-8">
                                            <p>WW - Wholesale Wine</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-5 col-lg-4">
                                            <p><strong>License status:</strong></p>
                                        </div>
                                        <div className="col-12 col-md-7 col-lg-8">
                                            <p>Operating under Sapa</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-5 col-lg-4">
                                            <p><strong>Delinquent status:</strong></p>
                                        </div>
                                        <div className="col-12 col-md-7 col-lg-8">
                                            <p>N/A</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-5 col-lg-4">
                                            <p><strong>Filing date:</strong></p>
                                        </div>
                                        <div className="col-12 col-md-7 col-lg-8">
                                            <p>05/07/2022</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-5 col-lg-4">
                                            <p><strong>Effective date:</strong></p>
                                        </div>
                                        <div className="col-12 col-md-7 col-lg-8">
                                            <p>25/07/2022</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-5 col-lg-4">
                                            <p><strong>Expiration date:</strong></p>
                                        </div>
                                        <div className="col-12 col-md-7 col-lg-8">
                                            <p>01/02/2030</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6">
                                <div className="ps-xl-4">
                                    <div className="mb-4">
                                        <h5 className="fw-bold mb-3">Premises information</h5>
                                        <hr/>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-5 col-lg-4">
                                            <p><strong>Principals:</strong></p>
                                        </div>
                                        <div className="col-12 col-md-7 col-lg-8">
                                            <p>Green, Adam, Arlotta, INC Drucker, Eric G, Bulldog Ventures,
                                                Steven G. Msggling, Antonio Jr., Green, Adam, Arlotta, INC Drucker, Eric G,
                                                Joseph John, Green, Adam, Arlotta, INC Drucker, Eric G</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-5 col-lg-4">
                                            <p><strong>Premises name:</strong></p>
                                        </div>
                                        <div className="col-12 col-md-7 col-lg-8">
                                            <p>Joffre Napa of South Weynon (Demo)</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-5 col-lg-4">
                                            <p><strong>Trade name:</strong></p>
                                        </div>
                                        <div className="col-12 col-md-7 col-lg-8">
                                            <p>Joffre Winery</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-5 col-lg-4">
                                            <p><strong>Address:</strong></p>
                                        </div>
                                        <div className="col-12 col-md-7 col-lg-8">
                                            <p>There is some very very long address,<br/>
                                                Weynon, NY 124553</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-5 col-lg-4">
                                            <p><strong>County:</strong></p>
                                        </div>
                                        <div className="col-12 col-md-7 col-lg-8">
                                            <p>Albany</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default DelinquentRetailerRecordsView;
