import httpClient from "api/HttpClient";
import BACKEND from "services/Constants";
import { DistributorModel } from "models/DistributorModel";
import { TransferResult } from "models/response/TransferResult";

const ROOT_PATH = `${BACKEND}/admin/transfer-cod`;

export default {
    perform(source: string, target: string, dryRun: boolean): Promise<TransferResult> {
        return httpClient.post(`${ROOT_PATH}/perform?source=${source}&target=${target}&dryRun=${dryRun}`);
    },

    getLicenseInfo(license: string): Promise<DistributorModel> {
        return httpClient.get(`${ROOT_PATH}/license-info/${license}`);
    }
};
