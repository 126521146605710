import { ProductModel } from "models/ProductModel";
import React, { Fragment, useEffect } from "react";
import * as _ from "lodash";
import classNames from "classnames";
import ErroneousValue from "components/ErroneousValue";
import Collapse from "react-bootstrap/Collapse";
import ProductConstants from "services/ProductConstants";
import { Row } from "react-bootstrap";
import { combo_assembly_description, combo_disassembly_description } from "services/ComboConstants";
import { Combo } from "models/Combo";
import Paginator from "components/Paginator";
import { currencyFormatProps } from "components/CurrencyText";
import { PageableRequest } from "models/requests/PageableRequest";
import BatchProductImportService from "services/BatchProductImportService";
import { useNumericFormat } from "react-number-format";
import { ProductToggledModel } from "models/ProductToggledModel";
import { DiscountValuesList } from "components/DiscountValuesList";
import { DiscountValues } from "models/DiscountValues";
import { arrayToString } from "pages/wholesaler/priceposting/pages/brands/components/DistribIdInput";
import { PostTypeModel } from "models/PostTypeModel";

export interface EntityWithPaging<T> {
    data: T[];
    pageNumber: number;
    pagesCount: number;
    totalCount: number;
    loading: boolean;
}

export const emptyEntityWithPaging = <T,>() => ({ data:[] as T[], pageNumber: 1, pagesCount: 1, totalCount: 0, loading: false } as EntityWithPaging<T>);

export enum Correctness {
    ONLY_INVALID = 0, ONLY_CORRECT
}

interface Params {
    products: EntityWithPaging<ProductToggledModel>,
    setProducts: React.Dispatch<React.SetStateAction<EntityWithPaging<ProductToggledModel>>>,
    correct: Correctness,
    type: PostTypeModel,
    createdAtStart: number,
    createdAtStop: number
}

const ProductList = ({ products, setProducts, correct, type, createdAtStart, createdAtStop }: Params) =>{

    const { format } = useNumericFormat(currencyFormatProps);

    useEffect(()=>productResultsQuery(1),[]);

    const currencyFormat = (v: number | undefined) =>
        v === undefined  ? "" : format ? format(v.toString()) : "";

    const productsPageClicked = (pageNumber: number)=>{
        setProducts( oldProducts =>({ ...oldProducts, pageNumber: pageNumber }));
        productResultsQuery(pageNumber);
    };

    const toggleOpen = (e: React.MouseEvent, index: number)=> {
        e.preventDefault();
        setProducts(oldProducts => {
            const data = oldProducts?.data.slice();//clone
            if (data) {
                data[index].open = !data[index].open;
                return { ...oldProducts,  data: data } as EntityWithPaging<ProductToggledModel>;
            }
            else {
                return oldProducts;
            }
        });
        return false;
    };

    function productResultsQuery(pageNumber: number) {
        setProducts(old =>({ ...old,loading :true }));

        const request = {
            pageInfo: { page: pageNumber, limit: 10 },
            filters:{
                post_type: type.code,
                correct: correct,
                createdAtStart: createdAtStart,
                createdAtStop: createdAtStop
            }
        } as PageableRequest;

        BatchProductImportService.productResults(request)
            .then(response => {
                setProducts(old =>({
                    ...old,
                    loading: false,
                    data: (response.data || []).map(i=>({ ...i, open: false } as ProductToggledModel)),
                    totalCount: response.pageInfo.totalItemsCount || 0,
                    pagesCount: response.pageInfo.pagesCount || 1
                }));
            });
    }

    const tabHasErrors = (i: ProductModel, tabNumber:number) => {
        switch (tabNumber) {
        case 1:
            return i.product_errors && (
                i.product_errors["proof"] ||
                    i.product_errors["alcohol"] ||
                    i.product_errors["vintage"] ||
                    i.product_errors["subpack"] ||
                    i.product_errors["fullcase"] ||
                    i.product_errors["split_charge"] ||
                    i.product_errors["distrib_id"]);
        case 2:
            return i.product_errors && (
                i.product_errors["nys_whole"] ||
                    i.product_errors["nys_item"] ||
                    i.product_errors["nys_prod"]);
        case 3:
            return i.product_errors && (
                i.product_errors["disc_code"] ||
                !_.isEmpty(i.discount_values_errors));
        case 4:
            return i.product_errors && (
                i.product_errors["alloc_met"] ||
                    i.product_errors["alloc_desc"]);
        case 5:
            return i.product_errors && (
                i.product_errors["combo_asse"] ||
                i.product_errors["combo_disa"] ||
                !_.isEmpty(i.combo_errors));
        }
        return false;
    };

    return <Fragment>
        <div className="mb-4">
            <div className="table-responsive">
                <table className="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th>Type</th>
                            <th>Brand name</th>
                            <th>Item description</th>
                            <th>Item Number</th>
                            <th>Size / Unit</th>
                            <th>Price per bottle</th>
                            <th>Price per case</th>
                            <th>NYC price per bottle</th>
                            <th>NYC price per case</th>
                            <th>Approval or BLR #</th>
                            <th>TTB / CoLA ID #</th>
                        </tr>
                    </thead>
                    <tbody>
                        { products.data && products.data.map((item, index) => {
                            const item1: ProductModel = { ...item, errors: item.product_errors };
                            const discountValues = item.discount_values?.reduce((acc: DiscountValues[], dv: DiscountValues) => {
                                if ( dv.id !== undefined){
                                    acc.push({ ...dv, errors: item.discount_values_errors && item.discount_values_errors[dv.id.toString()] });
                                }
                                return acc;
                            }, []);
                            return (
                                <Fragment key={ index }>
                                    <tr>
                                        <td>
                                            <a className={ classNames("table-collapse-a",item.open?"":"collapsed") } onClick={ (e) => toggleOpen(e,index) } href="#/" role="button">
                                                <i className="bi-caret-right-fill"/>
                                            </a>
                                        </td>
                                        <td><ErroneousValue item={ item1 } formattedValue={ item.beverage_type?.description } name={ "bev_type" } /></td>
                                        <td><ErroneousValue item={ item1 } name={ "brand_name" } /></td>
                                        <td><ErroneousValue item={ item1 } name={ "prod_name" } /></td>
                                        <td><ErroneousValue item={ item1 } name={ "prod_item" } /></td>
                                        <td>
                                            <ErroneousValue item={ item1 } name={ "item_size" } />
                                        &nbsp;
                                            <ErroneousValue item={ item1 } name={ "um" } />
                                        </td>
                                        <td><ErroneousValue item={ item1 } name={ "bot_price" } formattedValue={ currencyFormat(item1.bot_price) }/></td>
                                        <td><ErroneousValue item={ item1 } name={ "case_price" } formattedValue={ currencyFormat(item1.case_price) } /></td>
                                        <td><ErroneousValue item={ item1 } name={ "bot_nyc" } formattedValue={ currencyFormat(item1.bot_nyc) }/></td>
                                        <td><ErroneousValue item={ item1 } name={ "case_nyc" } formattedValue={ currencyFormat(item1.case_nyc) } /></td>
                                        <td><ErroneousValue item={ item1 } name={ "brand_reg" } /></td>
                                        <td><ErroneousValue item={ item1 } name={ "ttb_id" } /></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={ 13 } className="p-0">
                                            <Collapse in={ item.open }>
                                                <div className="p-4">
                                                    <div className="d-flex flex-column flex-sm-row align-items-start">
                                                        <div className="nav flex-column nav-pills btn-group-vertical min-wp-230"
                                                            id="snav-tab" role="tablist"
                                                            aria-orientation="vertical">
                                                            <button
                                                                className={ `btn btn-outline-secondary active ${tabHasErrors(item,1) ? "text-danger" : ""}` } id={ `xtab1-${index}-${correct}` }
                                                                data-bs-toggle="tab" data-bs-target={ `#x1-${index}-${correct}` } type="button" role="tab" aria-controls={ `x1-${index}-${correct}` } aria-selected="false">
                                                                { tabHasErrors(item,1) && <i className="bi bi-exclamation-circle-fill me-2"></i> }Item details
                                                            </button>
                                                            <button
                                                                className={ `btn btn-outline-secondary ${tabHasErrors(item,2) ? "text-danger" : ""}` } id={ `xtab2-${index}-${correct}` }
                                                                data-bs-toggle="tab" data-bs-target={ `#x2-${index}-${correct}` } type="button" role="tab" aria-controls={ `x2-${index}-${correct}` } aria-selected="false">
                                                                { tabHasErrors(item,2) && <i className="bi bi-exclamation-circle-fill me-2"></i> }NYS supplier details
                                                            </button>
                                                            <button
                                                                className={ `btn btn-outline-secondary ${tabHasErrors(item,3) ? "text-danger" : ""}` } id={ `xtab3-${index}-${correct}` }
                                                                data-bs-toggle="tab" data-bs-target={ `#x3-${index}-${correct}` } type="button" role="tab" aria-controls={ `x3-${index}-${correct}` } aria-selected="false">
                                                                { tabHasErrors(item,3) && <i className="bi bi-exclamation-circle-fill me-2"></i> }Discount details
                                                            </button>
                                                            <button
                                                                className={ `btn btn-outline-secondary ${tabHasErrors(item,4) ? "text-danger" : ""}` } id={ `xtab4-${index}-${correct}` } data-bs-toggle="tab"
                                                                data-bs-target={ `#x4-${index}-${correct}` } type="button" role="tab" aria-controls={ `x4-${index}-${correct}` } aria-selected="false">
                                                                { tabHasErrors(item,4) && <i className="bi bi-exclamation-circle-fill me-2"></i> }Limited availability
                                                            </button>
                                                            <button
                                                                className={ `btn btn-outline-secondary ${tabHasErrors(item,5) ? "text-danger" : ""}` } id={ `xtab5-${index}-${correct}` } data-bs-toggle="tab"
                                                                data-bs-target={ `#x5-${index}-${correct}` } type="button" role="tab" aria-controls={ `x5-${index}-${correct}` } aria-selected="false">
                                                                { tabHasErrors(item,5) && <i className="bi bi-exclamation-circle-fill me-2"></i> }Combo details
                                                            </button>
                                                        </div>
                                                        <div className="tab-content flex-fill" id={ "nav-tabContent" + index }>
                                                            <div className="tab-pane fade show active" id={ `x1-${index}-${correct}` } role="tabpanel" aria-labelledby={ `xtab1-${index}-${correct}` }>
                                                                <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                                    <h5 className="mb-3 fw-bold">Item details</h5>
                                                                    <div className="row mt-4">
                                                                        <div className="col-12 col-lg-6 col-xl-4 mb-3">
                                                                            { ProductConstants.isLiquor(item1) &&
                                                                                <p className="mb-2">
                                                                                    <strong>Proof:&nbsp;</strong><ErroneousValue item={ item1 } name={ "proof" } />
                                                                                </p>
                                                                            }
                                                                            { ProductConstants.isWine(item1) &&
                                                                                <p className="mb-2">
                                                                                    <strong>Alcohol content:&nbsp;</strong>
                                                                                    <ErroneousValue item={ item1 } formattedValue={ ProductConstants.alcoholContentString(item1.alcohol) } name={ "alcohol" } />
                                                                                </p>
                                                                            }
                                                                            <p className="mb-2"><strong>Age of year:&nbsp;</strong><ErroneousValue item={ item1 } name={ "vintage" } /></p>
                                                                            <p className="mb-2">
                                                                                <strong>Authorized NYS distributor license #&apos;s: </strong>
                                                                                <ErroneousValue item={ item1 } name={ "distrib_id" } formattedValue={ arrayToString(item1.distrib_id) }/>
                                                                            </p>
                                                                        </div>
                                                                        <div className="col-12 col-lg-6 col-xl-5 mb-lg-3">
                                                                            <p className="mb-2">
                                                                                <strong>
                                                                                    # of bottles per case / sleeve:&nbsp;
                                                                                </strong> <ErroneousValue item={ item1 } name={ "botpercase" } />/<ErroneousValue item={ item1 } name={ "subpack" } />
                                                                            </p>
                                                                            <p className="mb-2"><strong>Case only:&nbsp;</strong><ErroneousValue item={ item1 } name={ "fullcase" } /></p>
                                                                            <p className="mb-2">
                                                                                <strong>
                                                                                    BT / SLV Split case charge:&nbsp;
                                                                                </strong><ErroneousValue item={ item1 } name={ "split_charge" } formattedValue={ currencyFormat(item1.split_charge) } /></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="tab-pane fade" id={ `x2-${index}-${correct}` } role="tabpanel" aria-labelledby={ `xtab2-${index}-${correct}` }>
                                                                <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                                    <h5 className="mb-3 fw-bold">NYS supplier details</h5>
                                                                    <p className="mb-2"><strong>NYS supplier #:&nbsp;</strong><ErroneousValue item={ item1 } name={ "nys_whole" } /></p>
                                                                    <p className="mb-2">
                                                                        <strong>NYS supplier item #:&nbsp;</strong>
                                                                        <ErroneousValue item={ item1 } name={ (item1.post_type === "LR" || item1.post_type === "WR") ? "nys_item" : "prod_item" } />
                                                                    </p>
                                                                    <p className="mb-2"><strong>NYS label:&nbsp;</strong><ErroneousValue item={ item1 } name={ "nys_prod" } /></p>
                                                                </div>
                                                            </div>
                                                            <div className="tab-pane fade" id={ `x3-${index}-${correct}` } role="tabpanel" aria-labelledby={ `xtab3-${index}-${correct}` }>
                                                                <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                                    <h5 className="mb-3 fw-bold">Discount details</h5>
                                                                    {
                                                                        item.disc_code === undefined || item.disc_code.length == 0
                                                                            ? ( <DiscountValuesList values={ discountValues } /> )
                                                                            : <p className="mb-2"><strong>Discount code:&nbsp;</strong><ErroneousValue item={ item1 } name={ "disc_code" } /></p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="tab-pane fade" id={ `x4-${index}-${correct}` } role="tabpanel" aria-labelledby={ `xtab4-${index}-${correct}` }>
                                                                <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                                    <h5 className="mb-3 fw-bold">Limited availability</h5>
                                                                    <p className="mb-2"><strong>Allocation method:</strong><ErroneousValue item={ item1 } name={ "alloc_met" }/></p>
                                                                    <p className="mb-2"><strong>Allocation description:</strong><ErroneousValue item={ item1 } name={ "alloc_desc" }/></p>
                                                                </div>
                                                            </div>
                                                            <div className="tab-pane fade" id={ `x5-${index}-${correct}` } role="tabpanel" aria-labelledby={ `xtab5-${index}-${correct}` }>
                                                                <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                                    <h5 className="mb-3 fw-bold">Combo details</h5>
                                                                    <p className="mb-0">
                                                                        <strong><ErroneousValue item={ item1 } formattedValue={ combo_assembly_description(item.combo_asse) } name={ "combo_asse" }/></strong>
                                                                    &nbsp;/&nbsp;
                                                                        <strong><ErroneousValue item={ item1 } formattedValue={ combo_disassembly_description(item.combo_disa) } name={ "combo_disa" }/></strong>
                                                                    </p>
                                                                    <Row className="mt-4">
                                                                        { (item.combos || []).slice(0,5).map((c, i2)=> {
                                                                            const cItem : Combo = { ...c, errors: item.combo_errors && item.combo_errors[c.id.toString()] };
                                                                            return <div key={ i2 } className={ "col-12 col-lg-6 col-xl-4 mb-3" }>
                                                                                <p className="mb-2">
                                                                                    <strong>Item { i2 + 1 }</strong>
                                                                                </p>
                                                                                <p className="mb-2">
                                                                                    <strong>NYS Supplier Item:&nbsp;</strong>&nbsp;
                                                                                    <ErroneousValue item={ cItem } name={ "prod_item" } />
                                                                                </p>
                                                                                <p className="mb-2">
                                                                                    <strong>Quantity:&nbsp;</strong>
                                                                                    <ErroneousValue item={ cItem } name={ "quantity" } />
                                                                                </p>
                                                                            </div>;
                                                                        }
                                                                        ) }

                                                                        { (item.combos || []).slice(5).map((c, i1)=> {
                                                                            const cItem : Combo = { ...c, errors: item.combo_errors && item.combo_errors[c.id.toString()] };
                                                                            return <div key={ i1 } className={ "col-12 col-lg-6 col-xl-4 mb-3" }>
                                                                                <p className="mb-2">
                                                                                    <strong>Item { i1 + 6 }</strong>
                                                                                </p>
                                                                                <p className="mb-2">
                                                                                    <strong>NYS Supplier Item:&nbsp;</strong>
                                                                                    <ErroneousValue item={ cItem } name={ "prod_item" } />
                                                                                </p>
                                                                                <p className="mb-2">
                                                                                    <strong>Quantity:&nbsp;</strong>
                                                                                    <ErroneousValue item={ cItem } name={ "quantity" } />
                                                                                </p>
                                                                            </div>;
                                                                        }
                                                                        ) }
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </td>
                                    </tr>
                                </Fragment>
                            );
                        }) }
                    </tbody>
                </table>
            </div>
        </div>
        <Paginator totalPages={ products.pagesCount } currentPage={ products.pageNumber } pageClicked={ productsPageClicked }/>
    </Fragment>;
};

export default ProductList;
