import React, { useEffect, useState } from "react";
import { ControllerSelectProps, SelectControlled, SelectOption } from "components/form/Select";
import ProductService from "services/ProductService";

export type SelectProductTypeProps = Omit<ControllerSelectProps, "options">

const SelectAdminPostType = (props : SelectProductTypeProps) => {
    const [options, setOptions] = useState<SelectOption[]>([]);

    useEffect(() => {
        ProductService.getPostTypes().then(data => {
            setOptions(data.map(t => (
                { value: t.code, title: t.description + " Price" }
            )));
        });
    }, []);

    return <SelectControlled options={ options } { ...props } />;
};

export default SelectAdminPostType;
