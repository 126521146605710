import { useState } from "react";
import Spinner from "components/Spinner";
import { modalStore } from "store/ModalStoreProvider";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";
import { useToastStore } from "store/ToastStoreProvider";
import WholesalerPricePostingService from "services/wholesaler/WholesalerPricePostingService";

interface Props {
    postType: string;
    year: string;
    month: string;
    callback: () => any; 
}

export const showRevertScheduleDialog = (postType: string, year: string, month: string, callback: () => any ) => {
    modalStore.setBody(<RevertScheduleDialog postType={ postType } year={ year } month={ month } callback={ callback }/>);
    modalStore.setHeader("Revert Price Posting Data");
    modalStore.showModal();
};

const RevertScheduleDialog = (props: Props) => {
    const { addToast } = useToastStore();
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);

    function revertSchedule() {
        setIsDataLoading(true);

        WholesalerPricePostingService.revertPricesFromCertified(props.postType, props.year, props.month).then(() => {
            setIsDataLoading(false);
            addToast({
                message:"The schedule was successfully reverted.",
                header: "Revert Price Posting Data",
                variant: "success",
                timeoutMs: DEFAULT_TOASTER_TIMEOUT
            });
            props.callback();
        }).catch(() => addToast({
            message:"We couldn't find any previously certified posting data.",
            header:"Revert Price Posting Data",
            variant: "danger",
            timeoutMs: DEFAULT_TOASTER_TIMEOUT })
        ).finally(() => modalStore.hideModal());
    }

    modalStore.setAdditionalButtons([
        <button key={ 0 } type="button" className="btn btn-primary" onClick={ () => revertSchedule() }>Yes</button>
    ]);

    return <div>
        <p className="mb-0">
            Are you sure you want to revert to the most recent certified posting? This action will replace the current price posting data with the previously certified version.&nbsp;
            Please note that this cannot be undone. By clicking &apos;Yes&apos; you acknowledge that you understand the consequences of this action and agree to proceed with the revert.
        </p>
        <Spinner className="mt-4 pt-2 text-center" hidden={ !isDataLoading }/>
    </div>;
};
