import React from "react";
import { SortingOrder } from "models/Sorting";

interface Props {
    value?: SortingOrder;
    onSorting: (value?: SortingOrder) => void;
}

export const SortingButton = ({ value, onSorting }: Props) => {
    const asc  = "asc";
    const desc = "desc";

    function onClick(value?: SortingOrder) {
        onSorting(value);
    }

    return <>
        { !value && <button className="btn btn-link py-0 pe-0 ps-2 float-end" onClick={ () => onClick(asc) }><i className="bi-arrow-down-up"></i></button> }
        { value === asc && <button className="btn btn-link py-0 pe-0 ps-2 float-end" onClick={ () => onClick(desc) }><i className="bi-arrow-down"></i></button> }
        { value === desc && <button className="btn btn-link py-0 pe-0 ps-2 float-end" onClick={ () => onClick(undefined) }><i className="bi-arrow-up"></i></button> }
    </>;
};
