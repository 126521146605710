import httpClient from "api/HttpClient";
import { LicenseSearchResponse } from "models/response/LicenseSearchResponse";
import { PageableResponse } from "models/response/PageableResponse";
import { PageableRequest } from "models/requests/PageableRequest";
import BACKEND from "services/Constants";
import { LicenseModel } from "models/license.model";

export type SearchLicenseRequest = PageableRequest;

export default {

    getCounties(): Promise<string[]> {
        return httpClient.get(BACKEND + "/wholesaler/license-search/getCounties");
    },

    searchLicense(request: SearchLicenseRequest): Promise<PageableResponse<LicenseSearchResponse>> {
        return httpClient.search(BACKEND + "/wholesaler/license-search/searchLicense", request);
    },
    
    searchSingleLicense(license: string): Promise<LicenseModel> {
        return httpClient.get(BACKEND + "/wholesaler/license-search/searchSingleLicense/" + license);
    }
};
