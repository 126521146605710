import { BeverageType } from "models/BeverageType";

const bevTypes: BeverageType[] = [
    { type: "NS", description: "Neutral Spirits", super_type: "L" },
    { type: "WH", description: "Whiskey", super_type: "L" },
    { type: "G", description: "Gin", super_type: "L" },
    { type: "B", description: "Brandy", super_type: "L" },
    { type: "R", description: "Rum", super_type: "L" },
    { type: "CL", description: "Cordials & Liqueurs", super_type: "L" },
    { type: "CS", description: "Cocktails & Specialties", super_type: "L" },
    { type: "I", description: "Imitations", super_type: "L" },
    { type: "L", description: "Wine Low Alcohol", super_type: "W" },
    { type: "Q", description: "Tequila", super_type: "L" },
    { type: "TR", description: "Table Red and Rose Wine", super_type: "W" },
    { type: "O", description: "Other", super_type: "" },
    { type: "TW", description: "Table White Wine", super_type: "W" },
    { type: "TL", description: "Table Flavored Wine", super_type: "W" },
    { type: "TF", description: "Table Fruit Wine", super_type: "W" },
    { type: "S", description: "Sparkling Wine/Champagne", super_type: "W" },
    { type: "CW", description: "Carbonated Wine", super_type: "W" },
    { type: "V", description: "Vermouth", super_type: "W" },
    { type: "DL", description: "Dessert Flavored Wine", super_type: "W" },
    { type: "DP", description: "Dessert/Port/Sherry/(Cooking) Wine", super_type: "W" },
    { type: "DF", description: "Dessert Fruit Wine", super_type: "W" },
    { type: "UD", description: "Undefined - no match", super_type: "UD" }
];

export default {

    getTypesByPostType(postType: string): BeverageType[] {
        switch(postType) {
        case "LR":
        case "LW": return bevTypes.filter(b => b.super_type === "L" || b.type === "" || b.type === "O");

        case "WR":
        case "WW": return bevTypes.filter(b => b.super_type === "W" || b.type === "" || b.type === "O");
        }

        return [];
    }
};
