import React, { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { RegistrationRequestModel } from "models/requests/RegistrationRequestModel";
import registrationService from "services/RegistrationService";
import FormUtil from "utils/FormUtil";
import Input from "components/form/Input";
import PhoneNumberInput from "components/form/input/PhoneNumberInput";
import AuthService from "services/AuthService";
import { useToastStore } from "store/ToastStoreProvider";
import { Link, useNavigate } from "react-router-dom";
import { useKeyDownEnterEvent } from "components/KeyDownHook";
import { AUTH_PATHS } from "pages/auth/AuthRoutes";
import { LicenseAndPrincipalInput } from "components/LicenseAndPrincipalInput";

const EMAIL_IS_UNAVAILABLE = "A User with this email already exists";

const Registration = () => {
    const { addToast } = useToastStore();
    const navigate = useNavigate();
    const [formError, setFormError] = useState<string | undefined>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const formReturn = useForm<RegistrationRequestModel>({ mode: "onChange" });
    const {
        handleSubmit,
        reset,
        setError,
        formState: { errors, isValidating, isSubmitting },
        clearErrors,
        setFocus,
        control
    } = formReturn;

    useEffect(() => setFocus("permitIdOrSerialNumber"), []);
    useKeyDownEnterEvent(() => !isLoading && handleSubmit(onSubmit)());

    const onSubmit: SubmitHandler<RegistrationRequestModel> = async data => {
        setIsLoading(true);
        registrationService.registerUser(data)
            .then(() => {
                addToast({
                    header: "Registration",
                    message:"You are registered, please check your email!",
                    variant: "success"
                });
                navigate(-1);
            })
            .catch(response => {
                clearErrors();
                setFormError("");

                if (response.status == 422){
                    FormUtil.handleValidationError(response.data, setError, setFormError);
                }
                else {
                    setFormError("Something went wrong. Please try again");
                }
            }).finally(() => setIsLoading(false));
    };

    const checkEmail = async (v: string) =>
        v ? await AuthService.doesEmailAlreadyExist(v).then( exists => !exists, ()=> false) || EMAIL_IS_UNAVAILABLE
            : EMAIL_IS_UNAVAILABLE;

    return (
        <form onSubmit={ handleSubmit(onSubmit) }>
            <div className="mb-4">
                <p>In order to create a new account, you must complete the following form.</p>
                <p>The Principal <strong>first name</strong>, <strong>last name</strong>, <strong>year of
                    birth</strong> and <strong>zipcode</strong> must match ONE of the Principals listed on the license
                    registered with the state.</p>
                <p>Fields marked with a * are required.</p>
                <div className="d-flex flex-column flex-xl-row flex-wrap align-items-center">
                    <p className="mb-4 me-xl-3">Before proceeding with your registration, we recommend visiting our <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://support.nyslapricepostings.com/s/article/Authentication-process-for-Retailers">Support
                        Documentation</a> to understand better our registration process and the platform&apos;s
                        functionality.</p>
                    <Link className="btn btn-outline-secondary mb-4 ms-xl-auto" to={ AUTH_PATHS.LOGIN }>Return to Login</Link>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 col-md-6 mb-4 mb-md-0">
                            <FormProvider { ...formReturn }>
                                <LicenseAndPrincipalInput/>
                            </FormProvider>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="ps-md-4">
                                <h4 className="mb-3">Account Profile Info</h4>

                                <Input control={ control } errors={ errors } name="email"
                                    rules={ FormUtil.emailConfirmValidations(checkEmail) }
                                    label="Email *"
                                    placeholder="Enter email"
                                    type="email"
                                    required
                                />

                                <Input control={ control } errors={ errors } name="firstName"
                                    rules={ FormUtil.NAME_VALIDATIONS }
                                    label="First Name *"
                                    placeholder="Enter first name"
                                    type="text"
                                    required
                                />

                                <Input control={ control } errors={ errors } name="lastName"
                                    rules={ FormUtil.NAME_VALIDATIONS }
                                    label="Last Name *"
                                    placeholder="Enter last name"
                                    type="text"
                                    required
                                />

                                <PhoneNumberInput control={ control } errors={ errors } name="phone"
                                    label="Phone *"
                                    required
                                />

                                { formError &&
                                    <div className="invalid-message alert alert-sm alert-danger">
                                        <ul>
                                            <li>{ formError }</li>
                                        </ul>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center mt-4">
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                            <div className="pe-md-1">
                                <button className="btn btn-outline-secondary w-100" onClick={ () => reset() }>Clear Form</button>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                            <div className="ps-md-1">
                                <input
                                    className="btn btn-primary w-100"
                                    type="submit"
                                    value="Submit"
                                    disabled={ isSubmitting || isValidating }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default Registration;
