import { PageableRequest } from "models/requests/PageableRequest";
import { PageableResponse } from "models/response/PageableResponse";
import httpClient from "api/HttpClient";
import BACKEND from "services/Constants";
import { UpdateAvailabilityRequest } from "pages/wholesaler/availability/models/UpdateAvailabilityRequest";
import { ProductModel } from "models/ProductModel";

export default {
    search(request: PageableRequest): Promise<PageableResponse<ProductModel>> {
        return httpClient.search(BACKEND + "/wholesaler/availability/search", request);
    },

    updateProduct: (request: UpdateAvailabilityRequest, postType: string, year: number, month: number) =>
        httpClient.post(BACKEND + `/wholesaler/availability/updateProduct?type=${postType}&year=${year}&month=${month}`, request),

    clearLimAvail: (id: number, postType: string, year: number, month: number)  =>
        httpClient.post(BACKEND + `/wholesaler/availability/clearLimitedAvailability?type=${postType}&year=${year}&month=${month}`, { "id": id })
};
