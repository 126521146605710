import React, { Fragment, useEffect, useState } from "react";
import { Link, generatePath, useNavigate, useParams } from "react-router-dom";
import { WHOLESALER_PATHS } from "pages/wholesaler/WholesalerRoutes";
import { SubmitHandler } from "react-hook-form";
import URLUtil from "utils/URLUtil";
import { PageInfo } from "models/PageInfo";
import { BrandsSearchForm } from "components/BrandsSearchForm";
import { BrandsProductList } from "components/BrandsProductList";
import { BrandsFormModel } from "pages/wholesaler/priceposting/models/BrandsFormModel";
import Paginator from "components/Paginator";
import { PageableRequest } from "models/requests/PageableRequest";
import { ProductModel } from "models/ProductModel";
import Spinner from "components/Spinner";
import BrandService from "services/wholesaler/priceposting/BrandService";
import { useToastStore } from "store/ToastStoreProvider";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";
import { ToastDescription } from "store/ToastStore";
import { RegComboType } from "pages/wholesaler/priceposting/pages/brands/components/Page";
import { useAuthStore } from "store/AuthStoreProvider";
import WholesalerPricePostingService from "services/wholesaler/WholesalerPricePostingService";
import PricePostingTitle from "components/PricePostingTitle";
import { Sorting } from "models/Sorting";
import { PageableResponse } from "models/response/PageableResponse";
import { SelectLimit } from "pages/wholesaler/priceposting/pages/brands/components/SelectLimit";

const errorMsg: ToastDescription = {
    message:"Something went wrong. Please try again",
    header: "Get data",
    variant: "danger",
    timeoutMs: DEFAULT_TOASTER_TIMEOUT };

type SearchParams = BrandsFormModel & PageInfo & { sortings?: Sorting[] };

const ManageBrandsItems = () => {
    const navigate = useNavigate();
    const { permissionStore } = useAuthStore();
    const { addToast } = useToastStore();
    const { type = "", year = "", month = "", certifiedNeeded = "" } = useParams<{
        type: string;
        year: string;
        month: string;
        certifiedNeeded?: string;
    }>();
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ isRecordsPresent, setIsRecordsPresent ] = useState<boolean>(false);
    const [ urlData, setUrlData ] = useState<SearchParams>(URLUtil.readSearchParams() as SearchParams);
    const [ actions, setActions ] = useState<Array<string>>();
    const [ response, setResponse ] = useState<PageableResponse<ProductModel>>({} as PageableResponse<ProductModel>);
    const [ lastRequest, setLastRequest ] = useState<PageableRequest>({} as PageableRequest);

    const writePermitted = WholesalerPricePostingService.canDoAction(actions || [], "POST")
        && permissionStore.isWholesalerAndPricesCertifyOrWrite();

    useEffect(() => {
        WholesalerPricePostingService.fetchActions(type, year, month, certifiedNeeded==="true")
            .then(setActions);
        BrandService.isRecordsPresent(type, year, month, certifiedNeeded==="true")
            .then(setIsRecordsPresent)
            .catch(() => addToast(errorMsg));
    },[]);

    useEffect(() => {
        URLUtil.updateUrlParams(generatePath(WHOLESALER_PATHS.PRICE_POSTING.MANAGE_BRANDS_ITEMS,
            { "type":type, "year":year, "month": month, "certifiedNeeded": certifiedNeeded }), urlData, navigate);
        performQuery(urlData);
    },[urlData]);

    const onSubmit: SubmitHandler<BrandsFormModel> = async data => {
        const searchParams = data as SearchParams;
        searchParams.page = 1;
        searchParams.limit = urlData.limit;
        searchParams.sortings = urlData.sortings;
        setUrlData(searchParams);
    };

    function performQuery(params: SearchParams) {
        const request = {
            pageInfo: { page: params.page || 1, limit: params.limit || 20 },
            sortings: params.sortings,
            filters:{
                post_type: type,
                year: year,
                month: month,
                certifiedNeeded: certifiedNeeded==="true",
                brand_name: params.brand_name,
                prod_name: params.prod_name,
                prod_item: params.prod_item,
                brand_reg: params.brand_reg,
                ttb_id: params.ttb_id,
                beverage_type: params.beverage_type,
                reg_combo: params.reg_combo,
                show: params.show
            }
        } as PageableRequest;
        setLastRequest(request);

        setIsLoading(true);
        BrandService.search(request)
            .then(setResponse)
            .catch(() => addToast(errorMsg))
            .finally(() =>  setIsLoading(false));
    }

    function handlePageClick(pageNumber: number) {
        setUrlData({ ...urlData, page: pageNumber || 1 });
    }

    return <div className="d-flex flex-column">
        <div className="d-flex flex-column flex-xl-row flex-wrap align-items-start">
            <div className="order-1 order-xl-0 me-3">
                <PricePostingTitle type={ type } year={ year } month={ month } certifiedNeeded={ certifiedNeeded==="true" }  />
            </div>
            <Link
                to={ generatePath(WHOLESALER_PATHS.PRICE_POSTING.VIEW, { "type":type, "year":year, "month": month, "certifiedNeeded": certifiedNeeded }) }
                className="btn btn-outline-primary w-lg-100 ms-lg-auto mt-xl-1 order-0 order-xl-1 mb-5">
                <i className="bi-arrow-left-circle me-2"></i>
                Return to Posting Summary
            </Link>
        </div>
        <div>
            <div className="row mb-3 mb-md-4">
                <div className="col-12 col-lg-6">
                    <h5 className="mb-2 fw-bold">Manage Brands & Items</h5>
                    <p>On this page you can search and manage all your brands and items.</p>
                </div>
                <div className="col-12 col-lg-6 d-flex justify-content-lg-end align-items-lg-end">
                    <Link
                        to={ generatePath(WHOLESALER_PATHS.PRICE_POSTING.ADD_BRAND_ITEM,
                            { "rcType": RegComboType.REGULAR_VAP, "postType":type }) }
                        className={ "btn btn-primary me-3 mb-3 " + (writePermitted ? "" : "disabled") }
                        title="Add Product">
                        Add Regular/VAP
                    </Link>
                    <Link
                        to={ generatePath(WHOLESALER_PATHS.PRICE_POSTING.ADD_BRAND_ITEM,
                            { "rcType": RegComboType.COMBO, "postType":type }) }
                        className={ "btn btn-primary me-3 mb-3 " + (writePermitted ? "" : "disabled") }
                        title="Add Combo">
                        Add Combo
                    </Link>
                    <Link
                        to={ generatePath(WHOLESALER_PATHS.PRICE_POSTING.BATCH_IMPORT,{ "type":type, "year":year, "month": month }) }
                        className={ "btn btn-secondary mb-3 " + (writePermitted ? "" : "disabled") }
                        title="Add in batch">
                        Batch Submission
                    </Link>
                </div>
            </div>
            <div className="card mb-4">
                <div className="card-body">
                    <BrandsSearchForm postType={ type } filters={ urlData } onSubmit={ onSubmit } />
                </div>
            </div>
            { isRecordsPresent && !isLoading &&
                <Fragment>
                    <div className="mt-4 d-flex flex-column flex-xl-row align-items-center align-items-xl-end">
                        <h5 className="mb-3">Found <strong>{ response?.pageInfo?.totalItemsCount || 0 } records</strong> matching search criteria</h5>
                        <div className="ms-xl-auto d-flex flex-row align-items-center">
                            <SelectLimit
                                value={ urlData?.limit || 20 }
                                onChange={ (value: number) => setUrlData({ ...urlData, limit: value, page: 1 }) }/>
                            <div className="mb-3 mx-3">
                                <button type="button" className="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="bi-download me-2"/>Export
                                </button>
                                <ul className="dropdown-menu">
                                    <Link
                                        to={ BrandService.downloadCsv(lastRequest) }
                                        target="_blank"
                                        className="dropdown-item">
                                        Usual file
                                    </Link>
                                    <Link
                                        to={ BrandService.downloadCsv(lastRequest, true) }
                                        target="_blank"
                                        className="dropdown-item">
                                        For batch
                                    </Link>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <BrandsProductList
                        products={ response?.data }
                        writePermitted={ writePermitted }
                        reloadPage={ () => handlePageClick(response?.pageInfo?.page || 1) }
                        onSorting={ (sortings: Sorting[]) => setUrlData({ ...urlData, sortings: sortings }) }
                        sortings={ urlData.sortings }
                        postType={ type }
                        month={ month }
                        year={ year }
                        actions={ actions || [] }/>
                    <Paginator
                        totalPages={ response?.pageInfo?.pagesCount || 1 }
                        currentPage={ response?.pageInfo?.page || 1 }
                        pageClicked={ handlePageClick }/>
                </Fragment>
            }

            <Spinner hidden={ !isLoading } className="mt-4 text-center" />

            { !isRecordsPresent && !isLoading &&
                <div className="text-center">
                    <p>There are no records.</p>
                </div>
            }
        </div>
    </div>;
};

export default ManageBrandsItems;

