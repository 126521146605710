import React, { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Card, Row } from "react-bootstrap";
import { SelectControlled } from "components/form/Select";
import { InputGroup } from "components/form/InputGroup";
import { ControlledFormSwitch } from "components/form/ControlledFormSwitch";
import Input from "components/form/Input";
import TextArea from "components/form/TextArea";
import ProductConstants from "services/ProductConstants";
import DiscountTab, { DiscountTabHandler } from "pages/wholesaler/priceposting/pages/brands/components//DiscountTab";
import { LimitedAvailabilityInput } from "pages/wholesaler/priceposting/pages/brands/components/LimitedAvailabilityInput";
import { NumberOfBottlesControl } from "pages/wholesaler/priceposting/pages/brands/components/NumberOfBottlesControl";
import { TabName } from "pages/wholesaler/priceposting/models/ManualProduct";
import ProductItemTabs, { ProductItemTabsHandler } from "pages/wholesaler/priceposting/pages/brands/components/ProductItemTabs";
import Collapse from "react-bootstrap/Collapse";
import { CollapseModel } from "pages/wholesaler/priceposting/models/CollapseModel";

interface Props {
    index: number,
    onRemove?: (index:number)=>void,
    collapseModel: CollapseModel;
}

export const ProductItemForm = ({ index, onRemove, collapseModel }: Props)=> {

    const productItemTabsRef = useRef<ProductItemTabsHandler>(null);
    const discountTabRef = useRef<DiscountTabHandler>(null);
    const [toggle, setToggle] = useState(true);
    const { control, watch, setValue, formState:{ errors } } = useFormContext();
    const post_type = watch("post_type");

    const items = watch("items");
    const description = watch(`items[${index}].prod_name`);
    const activeTab = watch(`items[${index}].active_tab`);

    useEffect(()=>{
        setToggle(!collapseModel.all);
    },[collapseModel]);

    function collapse(e :any) {
        e.preventDefault();
        setToggle(!toggle);
    }

    function disableDiscounts() {
        productItemTabsRef.current?.disableDiscountTab();
        discountTabRef.current?.removeDiscounts();
    }

    function releaseDiscounts() {
        productItemTabsRef.current?.enableDiscountTab();
    }

    return <Card className="mt-4">
        <Card.Body className="">
            <a
                href="/"
                className="d-flex flex-row align-items-center product-item-head"
                onClick={ (e) => collapse(e) }>
                <h5 className="me-auto mb-0 text-black fw-bold">Item { index + 1 }{ description ? " - " + description : "" }</h5>
                <span className="bi bi-chevron-down"></span>
            </a>
            <hr/>
            <Collapse in={ toggle }>
                <div className="mt-3 position-relative">
                    { onRemove &&
                        <button
                            className="btn btn-primary mb-3 product-item-remove"
                            disabled={ items && items.length < 2 }
                            type="button" onClick={ () => onRemove(index) }>
                            Remove Item
                        </button>
                    }
                    <ProductItemTabs ref={ productItemTabsRef } activeTab={ activeTab }
                        onSelectTab={ k => setValue(`items[${index}].active_tab`, k as TabName || TabName.ITEM_INFO) }
                        itemTabContent={
                            <>
                                <div className="row">
                                    <div className="col-12 col-xl-6">
                                        <div className="pe-xl-4">
                                            <Input name={ `items[${index}].prod_name` } placeholder="Enter item description"
                                                maxLength={ 100 } label="Description *" type="text" control={ control } errors={ errors }/>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-6">
                                        <div className="ps-xl-4">
                                            <div className="row">
                                                <div className="col-6 col-md-6">
                                                    <Input name={ `items[${index}].prod_item` }
                                                        placeholder={ (post_type === "LR" || post_type === "WR") ? "Enter your item number" : "Enter NYS supplier item number" }
                                                        label={ (post_type === "LR" || post_type === "WR") ? "Your item number *" : "NYS supplier item number *" }
                                                        maxLength={ 15 } type="text" control={ control } errors={ errors }/>
                                                </div>
                                                <div className="col-3 col-md-6 d-flex flex-row">
                                                    <div className="flex-grow">
                                                        <Input name={ `items[${index}].item_size` } placeholder="Enter size/unit"
                                                            label="Size / Unit *" min={ 0 } step=".01" type="number" valueAsFloat={ true }
                                                            control={ control } errors={ errors }/>
                                                    </div>
                                                    <div className="wp-130 ms-3">
                                                        <SelectControlled name={ `items[${index}].um` as const } options={ ProductConstants.units } control={ control } errors={ errors }
                                                            label={ "\u00A0" } addValueIfNotPresent={ true }/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="pe-xl-4">
                                            <Row>
                                                <NumberOfBottlesControl rootName={ `items[${index}]` } />
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="ps-xl-4">
                                            <div className="mb-3">
                                                <p className="mb-2">
                                                    <strong>Case only</strong>
                                                </p>
                                                <div className="switch-year pt-1">
                                                    <label className="form-check-label me-2" htmlFor={ `fullcase${index}` }>No</label>
                                                    <ControlledFormSwitch control={ control } errors={ errors } id={ `fullcase${index}` } label={ "Yes" } name={ `items[${index}].fullcase` } options={ ["Y","N"] }/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                { (post_type === "LR" || post_type === "WR") &&
                                    <>
                                        <div className="row">
                                            <div className="col-12 col-xl-6">
                                                <div className="pe-xl-4">
                                                    <div className="row">
                                                        <div className="col-12 col-md-6">
                                                            <InputGroup name={ `items[${index}].bot_price` } control={ control }
                                                                errors={ errors }
                                                                label="Price per bottle *" prefix="$"
                                                                placeholder="Enter price per bottle"
                                                                type="number" step=".01" valueAsFloat={ true }/>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <InputGroup name={ `items[${index}].bot_nyc` } control={ control }
                                                                errors={ errors }
                                                                label="NYC Price per bottle" prefix="$"
                                                                placeholder="Enter NYC Price per bottle"
                                                                min={ 0 } type="number" step=".01" valueAsFloat={ true }/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-xl-6">
                                                <div className="ps-xl-4">
                                                    <div className="row">
                                                        <div className="col-12 col-md-6">
                                                            <InputGroup name={ `items[${index}].case_price` } control={ control }
                                                                errors={ errors }
                                                                label="Price per case *" prefix="$"
                                                                placeholder="Enter price per case"
                                                                step=".01" type="number" valueAsFloat={ true }/>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <InputGroup name={ `items[${index}].case_nyc` } control={ control }
                                                                errors={ errors }
                                                                label="NYC Price per case" prefix="$"
                                                                placeholder="Enter NYC Price per case"
                                                                min={ 0 } step=".01" type="number" valueAsFloat={ true }/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-xl-6">
                                                <div className="pe-xl-4">
                                                    <div className="row">
                                                        <div className="col-12 col-md-6">
                                                            <InputGroup name={ `items[${index}].split_charge` } control={ control } errors={ errors }
                                                                label="BT / SLV Split Case Chg" prefix="$" placeholder="BT / SLV Split Case Chg"
                                                                type="number" valueAsFloat={ true } step=".01"/>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className="mb-3">
                                                                <p className="mb-2">
                                                                    <strong>NYS label</strong>
                                                                </p>
                                                                <div className="switch-year pt-1">
                                                                    <label className="form-check-label me-2" htmlFor={ `nysprod${index}` }>No</label>
                                                                    <ControlledFormSwitch control={ control } errors={ errors } id={ `nysprod${index}` } label={ "Yes" } name={ `items[${index}].nys_prod` as const } options={ ["Y","N"] }/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-xl-6">
                                                <div className="ps-xl-4">
                                                    <div className="row">
                                                        <div className="col-12 col-md-6">
                                                            <Input name={ `items[${index}].nys_whole` } placeholder="Enter NYS supplier" control={ control }
                                                                label="NYS supplier *" type="text" errors={ errors } maxLength={ 16 } />
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <Input name={ `items[${index}].nys_item` } placeholder="Enter NYS supplier item number" control={ control }
                                                                label="NYS supplier item number *" type="text" errors={ errors }/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                { (post_type === "LW" || post_type === "WW") &&
                                    <div className="row">
                                        <div className="col-12 col-xl-6">
                                            <div className="pe-xl-4">
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <InputGroup name={ `items[${index}].bot_price` } control={ control }
                                                            errors={ errors }
                                                            label="Price (FOB) per bottle *" prefix="$"
                                                            placeholder="Enter price (FOB) per bottle"
                                                            type="number" step=".01" valueAsFloat={ true }/>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <InputGroup name={ `items[${index}].case_price` } control={ control }
                                                            errors={ errors }
                                                            label="Price (FOB) per case *" prefix="$"
                                                            placeholder="Enter price (FOB) per case"
                                                            step=".01" type="number" valueAsFloat={ true }/>
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <p className="mb-2">
                                                        <strong>NYS label</strong>
                                                    </p>
                                                    <div className="switch-year pt-1">
                                                        <label className="form-check-label me-2" htmlFor={ `nysprod${index}` }>No</label>
                                                        <ControlledFormSwitch control={ control } errors={ errors } id={ `nysprod${index}` } label={ "Yes" } name={ `items[${index}].nys_prod` as const } options={ ["Y","N"] }/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-6">
                                            <div className="ps-xl-4">
                                                <TextArea name={ `items[${index}].fob` } control={ control } errors={ errors }
                                                    label="FOB Designation" placeholder="Enter FOB designation"
                                                    rows={ 4 } maxLength={ 256 } />
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="row">
                                    <LimitedAvailabilityInput rootName={ `items[${index}]` } onCheck={ disableDiscounts } onUncheck={ releaseDiscounts }/>
                                </div>
                                { (post_type === "LW" || post_type === "WW") &&
                                    <div className="row">
                                        <div className="col-12">
                                            <TextArea name={ `items[${index}].prim_info` } control={ control } errors={ errors }
                                                label="Primary source (Brand owner) info" placeholder="Enter primary source (Brand owner) info"
                                                rows={ 2 } maxLength={ 256 } />
                                        </div>
                                    </div>
                                }
                            </>
                        }
                        discountsTabContent={
                            <DiscountTab ref={ discountTabRef } rootName={ `items[${index}]` }/>
                        } />
                </div>
            </Collapse>
        </Card.Body>
    </Card>;
};
