import React from "react";
import Input from "components/form/Input";

const AdminView = () => {

    return <div className="d-flex flex-column">
        <div className="mb-4 mb-md-5">
            <h1 className="text-primary mb-0">Master Filers</h1>
        </div>
        <div className="card">
            <div className="card-body">
                <form className="">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="pe-lg-5">
                                <Input name="name" placeholder="Enter premise name" label="Search by Premise Name" type="text"/>
                            </div>
                        </div>
                        <div className="input-or">or</div>
                        <div className="col-12 col-lg-6">
                            <div className="ps-lg-5 pt-4 pt-lg-0">
                                <Input name="serial_number" placeholder="Enter master or secondary serial number" label="Search by serial number" type="number"/>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-2">
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                            <button className="btn btn-outline-secondary w-100">Clear Form</button>
                        </div>
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                            <input className="btn btn-primary w-100" type="submit" value="Search" />
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div className="mt-3 mb-2 d-flex flex-column flex-lg-row align-items-end">
            <div className="me-lg-auto pt-3">
                <h5 className="mb-0">Found <strong>356 master filers</strong> matching search criteria</h5>
            </div>
            <div className="pt-3 ms-lg-3">
                <a className="btn btn-primary" href="/" title="Create master filter" data-bs-toggle="modal" data-bs-target="#exampleModalAdd">Create master filer</a>
            </div>
        </div>

        <div className="table-responsive mt-4">
            <table className="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th className="text-nowrap">Master Serial Number</th>
                        <th>Premise Name</th>
                        <th>Address</th>
                        <th>Secondary Serial Number(s)</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1333985</td>
                        <td>Empire mechanics LLC</td>
                        <td>139-37 JAMAICA AVE, QUEE</td>
                        <td>
                            <ul className="table-list">
                                <li>
                                    <strong>1477555:</strong> 056 DELI & GROCERY CORP, 2075 GRAND CONCOURSE, BRONX, 122
                                </li>
                            </ul>
                        </td>
                        <td className="text-nowrap">
                            <button data-bs-toggle="modal" data-bs-target="#exampleModalEdit" className="btn btn-link py-0 px-1 me-2" title="Edit"><i className="bi-pencil"></i></button>
                            <button className="btn btn-link py-0 px-1" title="Delete" data-bs-toggle="modal" data-bs-target="#exampleModalRemove">
                                <i className="bi-trash"></i>
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>1282282</td>
                        <td>003 VENUS TACOS LLC</td>
                        <td>705 9TH AVE, NEW</td>
                        <td>
                            <ul className="table-list">
                                <li>
                                    <strong>2226865:</strong> 0770 LOUNGE CORP, 139-37 JAMAICA AVE, QUEE, 252
                                </li>
                            </ul>
                        </td>
                        <td className="text-nowrap">
                            <button data-bs-toggle="modal" data-bs-target="#exampleModalEdit" className="btn btn-link py-0 px-1 me-2" title="Edit"><i className="bi-pencil"></i></button>
                            <button className="btn btn-link py-0 px-1" title="Delete" data-bs-toggle="modal" data-bs-target="#exampleModalRemove">
                                <i className="bi-trash"></i>
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>1158705</td>
                        <td>1000 MOTOR PKWY FOOD MART INC</td>
                        <td>1000 MOTOR PKWY, SUFFOLK, 122</td>
                        <td>
                            <ul className="table-list">
                                <li>
                                    <strong>1477555:</strong> 056 DELI & GROCERY CORP, 2075 GRAND CONCOURSE, BRONX, 122
                                </li>
                                <li>
                                    <strong>1268219:</strong> 1000 MOTOR PKWY, NEW YORK, 341
                                </li>
                                <li>
                                    <strong>2226865:</strong> 0770 LOUNGE CORP, 139-37 JAMAICA AVE, QUEE, 252
                                </li>
                            </ul>
                        </td>
                        <td className="text-nowrap">
                            <button data-bs-toggle="modal" data-bs-target="#exampleModalEdit" className="btn btn-link py-0 px-1 me-2" title="Edit"><i className="bi-pencil"></i></button>
                            <button className="btn btn-link py-0 px-1" title="Delete" data-bs-toggle="modal" data-bs-target="#exampleModalRemove">
                                <i className="bi-trash"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <ul className="justify-content-center pagination mt-3">
            <li className="page-item disabled">
                <span className="page-link">
                    <span className="bi bi-arrow-left me-2"></span>Prev
                </span>
            </li>
            <li className="page-item active">
                <span className="page-link">1</span>
            </li>
            <li className="page-item">
                <a className="page-link" role="button" href="/">2</a>
            </li>
            <li className="page-item">
                <a className="page-link" role="button" href="/">3</a>
            </li>
            <li className="page-item">
                <a className="page-link" role="button" href="/">...</a>
            </li>
            <li className="page-item">
                <a className="page-link" role="button" href="/">50</a>
            </li>
            <li className="page-item">
                <a className="page-link" role="button" href="/">
                    Next<span className="bi bi-arrow-right ms-2"></span>
                </a>
            </li>
        </ul>
        <div className="modal fade" id="exampleModalAdd" aria-labelledby="exampleModalAddLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalAddLabel">Add new master filer</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Cancel"></button>
                    </div>
                    <div className="modal-body">
                        <div>
                            <div className="d-flex flex-row align-items-end">
                                <div className="flex-fill">
                                    <Input name="Serial" placeholder="Enter master serial number" label="Master serial number" type="number"/>
                                </div>
                                <div className="mb-1">
                                    <a className="btn btn-outline-primary mb-3 ms-3" href="/">Search</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <div className="mb-2">
                                        <p className="mb-0"><strong>Premise Name:</strong></p>
                                        <p className="mb-0">Some name</p>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="mb-2">
                                        <p className="mb-0"><strong>Address:</strong></p>
                                        <p className="mb-0">123 Demo, ave demo, NY, 216444</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card mt-4">
                            <div className="card-body pb-3 pt-3">
                                <div className="styleguide-head">
                                    <div className="d-flex flex-row align-items-end">
                                        <h5 className="me-auto mb-0">Secondary Serial Number</h5>
                                        <a className="btn btn-outline-secondary" href="/">Remove</a>
                                    </div>
                                    <hr/>
                                </div>
                                <div className="">
                                    <div className="d-flex flex-row align-items-end">
                                        <div className="flex-fill error-validated">
                                            <Input name="Serial1" placeholder="Enter secondary serial number" label="Secondary Serial Number" type="number"/>
                                        </div>
                                        <div className="mb-1">
                                            <a className="btn btn-outline-primary mb-3 ms-3" href="/">Search</a>
                                        </div>
                                    </div>
                                    <div className="was-validated mb-2 mt--1">
                                        <div className="invalid-feedback d-block">
                                            Entered serial number does not exist in the system. Try again
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card mt-4">
                            <div className="card-body pb-3 pt-3">
                                <div className="styleguide-head">
                                    <div className="d-flex flex-row align-items-end">
                                        <h5 className="me-auto mb-0">Secondary Serial Number</h5>
                                        <a className="btn btn-outline-secondary" href="/">Remove</a>
                                    </div>
                                    <hr/>
                                </div>
                                <div className="">
                                    <div className="d-flex flex-row align-items-end">
                                        <div className="flex-fill">
                                            <Input name="Serial1" placeholder="Enter secondary serial number" label="Secondary Serial Number" type="number"/>
                                        </div>
                                        <div className="mb-1">
                                            <a className="btn btn-outline-primary mb-3 ms-3" href="/">Search</a>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <div className="mb-2">
                                                <p className="mb-0"><strong>Premise Name:</strong></p>
                                                <p className="mb-0">Some name</p>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <div className="mb-2">
                                                <p className="mb-0"><strong>Address:</strong></p>
                                                <p className="mb-0">123 Demo, ave demo, NY, 216444</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
                        <a href="/" className="btn btn-secondary">Add secondary serial number</a>
                        <button type="button" className="btn btn-primary">Save</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal fade" id="exampleModalEdit" aria-labelledby="exampleModalEditLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalAddLabel">Edit master filer</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Cancel"></button>
                    </div>
                    <div className="modal-body">
                        <div>
                            <div className="d-flex flex-row align-items-end">
                                <div className="flex-fill">
                                    <Input name="Serial" disabled placeholder="Enter master serial number" label="Master serial number" type="number"/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <div className="mb-2">
                                        <p className="mb-0"><strong>Premise Name:</strong></p>
                                        <p className="mb-0">Some name</p>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="mb-2">
                                        <p className="mb-0"><strong>Address:</strong></p>
                                        <p className="mb-0">123 Demo, ave demo, NY, 216444</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card mt-4">
                            <div className="card-body pb-3 pt-3">
                                <div className="styleguide-head">
                                    <div className="d-flex flex-row align-items-end">
                                        <h5 className="me-auto mb-0">Secondary Serial Number</h5>
                                        <a className="btn btn-outline-secondary" href="/">Remove</a>
                                    </div>
                                    <hr/>
                                </div>
                                <div className="">
                                    <div className="d-flex flex-row align-items-end">
                                        <div className="flex-fill">
                                            <Input name="Serial1" value="1667436" placeholder="Enter secondary serial number" label="Secondary Serial Number" type="number"/>
                                        </div>
                                        <div className="mb-1">
                                            <a className="btn btn-outline-primary mb-3 ms-3" href="/">Search</a>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <div className="mb-2">
                                                <p className="mb-0"><strong>Premise Name:</strong></p>
                                                <p className="mb-0">Some name</p>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <div className="mb-2">
                                                <p className="mb-0"><strong>Address:</strong></p>
                                                <p className="mb-0">123 Demo, ave demo, NY, 216444</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card mt-4">
                            <div className="card-body pb-3 pt-3">
                                <div className="styleguide-head">
                                    <div className="d-flex flex-row align-items-end">
                                        <h5 className="me-auto mb-0">Secondary Serial Number</h5>
                                        <a className="btn btn-outline-secondary" href="/">Remove</a>
                                    </div>
                                    <hr/>
                                </div>
                                <div className="">
                                    <div className="d-flex flex-row align-items-end">
                                        <div className="flex-fill">
                                            <Input name="Serial1" value="2667436" placeholder="Enter secondary serial number" label="Secondary Serial Number" type="number"/>
                                        </div>
                                        <div className="mb-1">
                                            <a className="btn btn-outline-primary mb-3 ms-3" href="/">Search</a>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <div className="mb-2">
                                                <p className="mb-0"><strong>Premise Name:</strong></p>
                                                <p className="mb-0">Some name</p>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <div className="mb-2">
                                                <p className="mb-0"><strong>Address:</strong></p>
                                                <p className="mb-0">123 Demo, ave demo, NY, 216444</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
                        <a href="/" className="btn btn-secondary">Add secondary serial number</a>
                        <button type="button" className="btn btn-primary">Save</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal fade" id="exampleModalRemove" aria-labelledby="exampleModalRemoveLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalRemoveLabel">Delete Master Filer</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Cancel"></button>
                    </div>
                    <div className="modal-body">
                        <p className="mb-0">You are going to delete Master Filer with Serial number <strong>1185777</strong>. Are you sure?</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" className="btn btn-primary">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default AdminView;
