import React, { ComponentProps, useLayoutEffect, useRef, useState } from "react";

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props extends ComponentProps<any> {
    value: string;
    placeholder?: string;
    onSearch: any;
}
let timerSearch: NodeJS.Timeout;
const SearchInput = ({ value, placeholder, onSearch }: Props) => {
    const [searchString, setSearchString] = useState(value);

    const firstUpdate = useRef(true);
    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        clearTimeout(timerSearch);
        timerSearch = setTimeout(() => {
            onSearch(searchString);
        }, 400);
    }, [searchString]);

    return (
        <div className="form-group form-group-search">
            <span className="bi bi-search"></span>
            <input
                type="text"
                className="form-control"
                placeholder={ placeholder ? placeholder : "Search" }
                value={ searchString }
                onChange={ e => setSearchString(e.target.value) }
            />
        </div>
    );
};

export default SearchInput;
