import React, { useEffect, useState } from "react";
import SelectSearch, { SelectSearchOption, SelectSearchProps } from "react-select-search";
import organizationService from "services/OrganizationService";
import Spinner from "components/Spinner";
import { DistributorModel } from "models/DistributorModel";
import { Controller } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";

export interface SelectDistributorProps extends Omit<SelectSearchProps, "options"> {
    label?: string;
    value?: string | undefined;
    postType: string;
    onChange?: any;
    empty?: boolean;
    month?: string;
    control?: Control<any>;
    name: string;
}

export const SelectDistributors = (props: SelectDistributorProps) => {

    const { label, postType, empty, month, onChange, value, control, name, ...selectSearchProps } = props;

    const [distributorPending, setDistributorPending] = useState<boolean>(false);
    const [distributorOptions, setDistributorOptions] = useState<SelectSearchOption[]>([]);

    useEffect(() => {
        if (postType) {
            setDistributorPending(true);
            organizationService.getPublishedDistributors(postType, month).then((distributors: DistributorModel[]) => {
                const options: SelectSearchOption[] = distributors.map((d: DistributorModel) => {
                    return {
                        name: d.premise_name?.trim() + " (" + d.county?.trim() + ") - " + d.permit_id,
                        value: d.id.toString()
                    };
                });
                setDistributorOptions(options);
            }).finally(() => {
                setDistributorPending(false);
            });
        }
    }, [postType]);

    const handleFilter = () => {
        return (query: string) => {
            if (query.length === 0) {
                return distributorOptions;
            }

            return distributorOptions.filter((item: SelectSearchOption) => {
                return item.name.toLowerCase().includes(query.toLowerCase());
            });
        };
    };

    return <div className="form-group">
        { label && <label className="form-label">{ label }</label> }
        { control && !distributorPending && (empty || distributorOptions.length !== 0) &&
            <Controller control={ control } name={ name }
                render={
                    ({ field:{ value, onChange, ref } })=> (
                        <SelectSearch
                            { ...selectSearchProps }
                            value={ value }
                            onChange={ onChange }
                            ref={ ref }
                            options={ distributorOptions }
                            filterOptions={ handleFilter }
                            placeholder="Select distributor"
                            search
                        /> )
                }
            />
        }
        { !control && !distributorPending && (empty || distributorOptions.length !== 0)&&
            <SelectSearch
                { ...selectSearchProps }
                value={ value }
                onChange={ onChange }
                options={ distributorOptions }
                filterOptions={ handleFilter }
                placeholder="Select distributor"
                search
            />
        }

        { !distributorPending && !empty && distributorOptions.length === 0 &&
            <div className="">
                <h5 className="mb-4">Results are not found</h5>
            </div>
        }
        { distributorPending &&
            <div className="" hidden={ !distributorPending }>
                <Spinner className="mb-3"/>
            </div>
        }
    </div>;
};
