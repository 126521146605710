import React, { PropsWithChildren, useEffect, useState } from "react";
import ProfileService from "services/user/UserProfileService";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import Input from "components/form/Input";
import FormUtil from "utils/FormUtil";
import { CurrentUserProfile } from "models/UserModel";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";
import { useToastStore } from "store/ToastStoreProvider";
import { useAuthStore } from "store/AuthStoreProvider";
import { useNavigate } from "react-router-dom";
import PhoneNumberInput from "components/form/input/PhoneNumberInput";
import { Control, UseFormGetValues } from "react-hook-form/dist/types/form";
import UserPublicService from "services/user/UserPublicService";

const Profile = () => {

    const { addToast, addDefaultDangerToast } = useToastStore();
    const authStore = useAuthStore();
    const navigate = useNavigate();

    const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
    const [anError, setAnError] = useState<boolean>(false);

    useEffect(() => {
        ProfileService.getProfile().then(response => {
            reset(response);
        }).catch(() => {
            setAnError(true);
        }).finally(() => {
            setIsDataLoading(false);
        });
    }, []);

    const {
        control,
        handleSubmit,
        reset,
        getValues,
        watch,
        setError,
        formState: { errors }
    } = useForm<CurrentUserProfile>({
        mode: "onChange"
    });

    const isProdApiAccess = watch("prod_api_access");

    const onSubmit: SubmitHandler<CurrentUserProfile> = data => {
        ProfileService.updateProfile(data).then(() => {
            addToast({ message:"Update was successful!", header:"Profile update",variant: "success", timeoutMs: DEFAULT_TOASTER_TIMEOUT });
        }).catch(() => addDefaultDangerToast("Profile update"));
    };

    function resetMyPassword() {
        const emailValue = getValues("email");
        if(!emailValue) {
            setError("email", { type: "manual", message: "The field is required" });
            return;
        }
        UserPublicService.resetPassword({ email: emailValue })
            .then(() => addToast({ message:"A password reset email has been sent to your inbox", header: "Reset password", variant: "success", timeoutMs: DEFAULT_TOASTER_TIMEOUT }))
            .catch(() => addDefaultDangerToast("Profile update"));
    }

    return (
        <div>
            <div className="mb-5">
                <h1 className="text-primary mb-0">Profile</h1>
            </div>

            <div hidden={ !isDataLoading } className="mt-4 pt-2 text-center">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
            <div hidden={ isDataLoading || !anError }>
                Something went wrong. Please try again later.
            </div>
            <div hidden={ isDataLoading || anError }>
                <form onSubmit={ handleSubmit(onSubmit) }>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <div className="pe-xl-4">
                                        <Input
                                            control={ control }
                                            errors={ errors }
                                            name="first_name"
                                            rules={ FormUtil.NAME_VALIDATIONS }
                                            label="First Name"
                                            placeholder="Enter first name"
                                            type="text"
                                        />
                                        <Input
                                            control={ control }
                                            errors={ errors }
                                            name="last_name"
                                            rules={ FormUtil.NAME_VALIDATIONS }
                                            label="Last Name"
                                            placeholder="Enter last name"
                                            type="text"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="ps-xl-4">
                                        <PhoneNumberInput
                                            control={ control }
                                            errors={ errors }
                                            name="phone"
                                            label="Phone"
                                        />
                                        <Input control={ control } errors={ errors } name="email" label="Email" type="email" required/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    { authStore.permissionStore.isWholesalerAdminOrCodApi() &&
                        <div className="row mb-4">
                            <TokenListControl control={ control } getValues={ getValues } arrayName="sandbox_api_tokens"
                                title="API (Sandbox)">
                                <p>This section provides API tokens for testing only, allowing verification of
                                    predefined responses from our system. Remember, these tokens are strictly for
                                    sandbox environments and shouldn’t be used in live applications.</p>
                            </TokenListControl>
                            { isProdApiAccess &&
                                <TokenListControl control={ control } getValues={ getValues } arrayName="api_tokens"
                                    title="API (Production)">
                                    <p>This section provides you with API tokens for real-world usage, enabling you to
                                        manage data across production environments. Please handle these tokens with care
                                        as they have direct impact on your live applications.</p>
                                </TokenListControl>
                            }
                        </div>
                    }
                    <div className="row justify-content-center mt-4">
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                            <div className="pe-md-1">
                                <input
                                    type="button" className="btn btn-secondary w-100"
                                    onClick={ () => navigate(-1) }
                                    value="Go back"/>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                            <div className="pe-md-1">
                                <button className="btn btn-secondary w-100" type="button"
                                    onClick={ () => resetMyPassword() }>Reset my password
                                </button>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                            <div className="ps-md-1">
                                <input
                                    className="btn btn-primary w-100"
                                    type="submit"
                                    value="Save changes"
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Profile;

const TokenListControl = ({ control, arrayName, title, children, getValues }: PropsWithChildren<{
    control: Control<any>,
    arrayName: string,
    title: string,
    getValues: UseFormGetValues<any>
}>) => {
    const { fields, append, remove } =
        useFieldArray({ name: arrayName, control });
    const { addToast } = useToastStore();

    const copyToClipboard = async (event: React.MouseEvent, name: string)=> {
        event.preventDefault();
        try {
            await navigator.clipboard.writeText(getValues(name) || "");
            addToast({ message:"Content copied to clipboard.", header: "Profile", variant: "primary", timeoutMs: DEFAULT_TOASTER_TIMEOUT });
        } catch (err) {
            addToast({ message:"Failed to copy.", header: "Profile", variant: "warning", timeoutMs: DEFAULT_TOASTER_TIMEOUT });
        }
    };

    return <div className="col-12 col-xl-6">
        <div className="card mt-4">
            <div className="card-body pb-3">
                <div className="d-flex flex-row align-items-center">
                    <div className="d-flex flex-row">
                        <h5 className="mb-0 fw-bold">{ title }</h5>
                        <div className="tooltip-wr tooltip-wr-small ms-2">
                            <span className="tooltip-icon bi-question-circle-fill"></span>
                            <div className="tooltip-msg text-center">
                                { children }
                            </div>
                        </div>
                    </div>
                    <button type="button" className="btn btn-outline-secondary ms-auto"
                        onClick={ async () => append({ value: await ProfileService.generateUUID() }) }>
                        Generate new token
                    </button>
                </div>
                <hr/>
                <div className="row gy-2">
                    {
                        fields.map((field, index) =>
                            <div key={ field.id } className="col-12 col-xl-10 col-xxl-9 col-xxxl-8">
                                <div className="d-flex flex-row align-items-center w-100">
                                    <div className="flex-fill w-100 me-1">
                                        <Input
                                            className="form-control input-with-copy"
                                            name={ `${arrayName}[${index}].value` }
                                            control={ control }
                                            type="text"
                                            disabled
                                        />
                                        <button className="btn btn-link link-copy p-0" title="Copy"
                                            onClick={ (event: React.MouseEvent) => copyToClipboard(event, `${arrayName}[${index}].value`) }>
                                            <i className="bi-copy"></i>
                                        </button>
                                    </div>
                                    <button className="btn btn-link py-0 px-1 mb-3 ms-auto" title="Delete"
                                        onClick={ () => remove(index) }>
                                        <i className="bi-trash"></i>
                                    </button>
                                </div>
                            </div>
                        ) }
                </div>
            </div>
        </div>
    </div>;
};
