import React, { useEffect, useState } from "react";
import { BaseSelect, ControllerSelectProps, SelectControlled, SelectOption } from "components/form/Select";
import ProductService from "services/ProductService";

export type SelectPostTypeProps = Omit<ControllerSelectProps, "options" | "control"> & {
    control?: any;
    isAll?: boolean
}

const SelectPostType = ({ isAll, control, ...props } : SelectPostTypeProps) => {

    useEffect(() => {
        const types = ProductService.getPostTypesSync()
            .filter(({ code }) => isAll || code === "WR" || code === "LR")
            .map(m=>m.toSelectOption())
            .map(t => ({ ...t, title: t.title + " Price" }));
        types.unshift({ value: "", title: "Select type" });
        setOptions(types);
    }, []);

    const [options, setOptions] = useState<SelectOption[]>([]);

    return control
        ? <SelectControlled label="Select type" options={ options } { ...props } control={ control } />
        : <BaseSelect label="Select type" options={ options } { ...props } />;
};

export default SelectPostType;
