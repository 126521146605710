import React, { Fragment, useEffect, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import URLUtil from "utils/URLUtil";
import dayjs from "dayjs";
import { useToastStore } from "store/ToastStoreProvider";
import Spinner from "components/Spinner";
import Paginator from "components/Paginator";
import { PageInfo } from "models/PageInfo";
import { ProductModel } from "models/ProductModel";
import { PageableRequest } from "models/requests/PageableRequest";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";
import { ToastDescription } from "store/ToastStore";
import AvailabilityService from "services/wholesaler/AvailabilityService";
import ProductConstants from "services/ProductConstants";
import { useTimeStore } from "store/TimeStoreProvider";
import { WHOLESALER_PATHS } from "pages/wholesaler/WholesalerRoutes";
import { AvailabilityProductList } from "pages/wholesaler/availability/components/AvailabilityProductList";
import { AvailabilitySearchForm } from "pages/wholesaler/availability/components/AvailabilitySearchForm";
import { AvailabilityFormModel } from "pages/wholesaler/availability/models/AvailabilityFormModel";
import { UpdateAvailabilityRequest } from "pages/wholesaler/availability/models/UpdateAvailabilityRequest";
import { useNavigate } from "react-router-dom";
import DateUtil from "utils/DateUtil";
import { modalStore } from "store/ModalStoreProvider";

type SearchParams = AvailabilityFormModel & PageInfo;

const MidMonthLimitedAvailability = () => {
    const navigate = useNavigate();
    const { addToast } = useToastStore();
    const { serverTime } = useTimeStore();
    const [products, setProducts] = useState<ProductModel[]>([]);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [urlData, setUrlData] = useState<SearchParams>({} as SearchParams);
    const [searchYearMonth, setSearchYearMonth] = useState(serverTime);

    useEffect(() => {
        const params = {
            post_type: "WR",
            post_date: 0,
            page: 1,
            lim_avail: ProductConstants.enumLimAvail.LIMITED_AVAILABILITY,
            ...URLUtil.readSearchParams()
        } as SearchParams;
        setUrlData(params);
        updateUrlParams(params);
        performQuery(params);
    },[]);

    const onSubmit: SubmitHandler<AvailabilityFormModel> = data => {
        const searchParams = data as SearchParams;
        searchParams.page = 1;
        setUrlData(searchParams);

        updateUrlParams(searchParams);
        performQuery(searchParams);
    };

    function handlePageClick(pageNumber: number) {
        const searchParams = urlData;
        setUrlData({ ...urlData, page: pageNumber });
        searchParams.page = pageNumber;
        updateUrlParams(searchParams);
        performQuery(searchParams);
    }

    function updateUrlParams(params: SearchParams) {
        URLUtil.updateUrlParams(WHOLESALER_PATHS.MID_MONTH, params, navigate);
    }

    function performQuery(filters: SearchParams) {
        const date: dayjs.Dayjs  = serverTime.add(filters.post_date, "M");
        setSearchYearMonth(date);
        const request = {
            pageInfo: { page: filters.page || 1 },
            filters:{
                ...filters,
                month: date.month() + 1, // dayjs: Months are zero indexed, so January is month 0.
                year: date.year()
            }
        } as PageableRequest;

        setIsLoading(true);
        AvailabilityService.search(request).then(response => {
            setProducts(response.data || []);
            setTotalPages(response.pageInfo.pagesCount || 0);
            setTotalRecords(response.pageInfo.totalItemsCount || 0);
        }).catch(() => addToast(errorMsg)
        ).finally(() =>  setIsLoading(false));
    }

    const errorMsg: ToastDescription = {
        message:"Something went wrong. Please try again",
        header: "Limited availability",
        variant: "danger",
        timeoutMs: DEFAULT_TOASTER_TIMEOUT };

    function onUpdateProduct(request: UpdateAvailabilityRequest,postType: string, year: number, month: number) {
        setIsLoading(true);
        AvailabilityService.updateProduct(request, postType,year,month)
            .then(() => {
                addToast({
                    message:"The product was updated successfully.",
                    header: "Limited availability",
                    variant: "success",
                    timeoutMs: DEFAULT_TOASTER_TIMEOUT
                });
                performQuery(urlData);
            })
            .catch(() => addToast(errorMsg))
            .finally(() =>  setIsLoading(false));
    }

    function onClearLimAvail(id: number, postType: string, year: number, month: number) {
        setIsLoading(true);
        AvailabilityService.clearLimAvail(id, postType,year,month)
            .then(() => {
                addToast({
                    message:"The product limited availability was cleared successfully.",
                    header: "Limited availability",
                    variant: "success",
                    timeoutMs: DEFAULT_TOASTER_TIMEOUT
                });
                performQuery(urlData);
            })
            .catch(() => addToast(errorMsg))
            .finally(() => {
                setIsLoading(false);
                modalStore.hideModal();
            });
    }

    return <div className="d-flex flex-column">
        <div className="mb-4 mb-md-5">
            <h1 className="text-primary mb-4">Mid-Month Limited Availability</h1>
            <p className="mb-0">
                On this page, you may manage Limited Availability for your products. You can select the
                schedule to which Limited Availability will apply, and you can choose which items to mark as limited.
                This will allow you to control the availability of your products and manage supply and demand more effectively. &nbsp;
                <a href="https://docs.google.com/document/d/e/2PACX-1vQMP-GwE2MvEEk8f7-yXZ7Ty-8WHAOm4XpRzskpgw_HQ9iFet51gXFQ4HCDlfp0Cotxl8UECLEw56J3/pub" target="_blank" rel="noreferrer">
                    Read More &hellip;
                </a>
            </p>
        </div>
        <div className="card">
            <div className="card-body">
                <AvailabilitySearchForm filters={ urlData } onSubmit={ onSubmit } />
            </div>
        </div>
        <div className="mt-4">
            <Spinner className="mt-4 pt-2 text-center" hidden={ !isLoading } />
            { !isLoading &&
                <Fragment>
                    <h5 className="pt-3">Found <strong>{ totalRecords } products</strong> for { DateUtil.monthNumberToName(searchYearMonth.month()) } { searchYearMonth.year() }</h5>
                    <AvailabilityProductList products={ products } onUpdateProduct={ onUpdateProduct } onClearLimAvail={ onClearLimAvail }/>
                    <Paginator totalPages={ totalPages } currentPage={ urlData.page || 1 } pageClicked={ handlePageClick } />
                </Fragment>
            }
        </div>
    </div>;
};

export default MidMonthLimitedAvailability;

