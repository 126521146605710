import React, { InputHTMLAttributes } from "react";
import { Controller } from "react-hook-form";
import Form from "react-bootstrap/Form";
import BaseInput, { extractPathFromObject } from "components/form/BaseInput";
import { FormCheckType } from "react-bootstrap/FormCheck";

export const VINTAGE_NA = "N/A";

type ExtendedAgeControlProps = InputHTMLAttributes<HTMLInputElement> & {
    control: any;
    errors: any;
    name: string;
};
export const ExtendedAgeControl = ({ control, errors, name }:ExtendedAgeControlProps)=> {

    const isAgePredefined = (value :string)=> ["N/A","NV","VV"].indexOf(value) != -1;
    const innerName = `${name}-group`;

    return <Controller name={ name } control={ control }
        render={ ({ field: { value, onChange, onBlur, ref } }) => {
            const propagate = (e:any) => onChange(e.target.value);

            const error = extractPathFromObject(errors,name);
            const isInvalid = error !== undefined;
            const errorMessage = error?.message;
            const common = {
                type:"radio" as FormCheckType,
                name: innerName,
                onChange: propagate,
                onBlur: onBlur,
                isInvalid: isInvalid,
                className: isInvalid ? "is-invalid" :""
            };

            return <div className="mb-3">
                <Form.Check inline id="ac-1" { ...common } checked={ value=="N/A" } label="Not applicable (N/A)" value="N/A" ref={ ref }/>
                <Form.Check inline id="ac-2" { ...common } checked={ value=="NV" }  label="Non vintage (NV)" value="NV"/>
                <Form.Check inline id="ac-3" { ...common } checked={ value=="VV" }  label="Various vintage (VV)" value="VV"/>
                <Form.Check inline { ...common }>
                    <Form.Check.Input type="radio" name="group1" className="form-check-input-with-year" id="ac-4" isInvalid={ isInvalid }
                        onChange={ () => onChange("") }
                        checked={ !isAgePredefined(value) }
                    />
                    <Form.Check.Label className="wp-80 input-year flex-lg-fill" htmlFor="ac-4">
                        <BaseInput name="ignored-name-1" type="number" placeholder="Year" divClassName="mb-0"
                            value={ isNaN(parseInt(value)) ? "" : value }
                            onChange={ propagate }
                            disabled={ isAgePredefined(value) }
                        />
                    </Form.Check.Label>
                </Form.Check>
                <div className="invalid-feedback mt-0"> { errorMessage } </div>
            </div>;
        } }
    />;
};
