import { useFormContext } from "react-hook-form";
import Input from "components/form/Input";
import React from "react";

interface Props1 {
    rootName: string,
    index: number,
    onRemove?: ()=>void
}

export const ComboItem = ({ rootName, index, onRemove }: Props1) =>{
    const { control,formState:{ errors } } = useFormContext();

    return <div className="mb-3">
        <div className="styleguide-head mb-4">
            <div className="d-flex flex-row align-items-end">
                <h5 className="me-auto mb-0">Combo item  { index + 1 }</h5>
                { onRemove &&
                    <button className="btn btn-outline-secondary" type="button" onClick={ onRemove }>Remove item</button>
                }
            </div>
            <hr/>
        </div>
        <div className="row">
            <div className="col-12 col-xl-6">
                <div className="pe-xl-4">
                    <Input name={ `${rootName}.combos[${index}].prod_item` } placeholder="Enter NYS supplier item"
                        label="NYS supplier item" type="text" control={ control } errors={ errors }/>
                </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3">
                <div className="ps-xl-4">
                    <Input name={ `${rootName}.combos[${index}].quantity` } placeholder="Enter quantity" label="Quantity"
                        type="number" control={ control } errors={ errors }/>
                </div>
            </div>
        </div>
    </div>;
};
