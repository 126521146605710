import { Location } from "history";
import { matchRoutes } from "react-router-dom";
import httpClient from "api/HttpClient";
import BACKEND from "services/Constants";
import { LoginModel } from "pages/auth/models/LoginModel";
import { LoggedInUserInfo } from "models/LoggedInUserInfo";
import { UserModel } from "models/UserModel";
import { WHOLESALER_PATHS } from "pages/wholesaler/WholesalerRoutes";
import { USER_PATHS } from "pages/user/UserRoutes";

export type TokenInfo = {
    missing : boolean,
    expired: boolean,
    used: boolean,
    email?: string,
    origin?: string
}

export default {

    authenticate(model: LoginModel): Promise<LoggedInUserInfo> {
        return httpClient.post(BACKEND + "/auth/authenticate", model);
    },

    reauthorize(newLicenseId: number): Promise<LoggedInUserInfo> {
        return httpClient.post(BACKEND + "/auth/reauthorize", null,{ params: { new_license_id : newLicenseId } });
    },

    doesEmailAlreadyExist(email: string): Promise<boolean> {
        return httpClient.get( `${BACKEND}/auth/does-email-already-exist`, { params: { email: email } });
    },

    logout(): Promise<void> {
        return httpClient.post(BACKEND + "/auth/logout");
    },

    checkToken(token: string): Promise<TokenInfo> {
        return httpClient.get(BACKEND + "/auth/check-token", { params: { token: token } });
    },

    impersonate(request: { userId: number, licenseId: number }): Promise<LoggedInUserInfo> {
        return httpClient.post(BACKEND + "/auth/impersonate", request);
    },

    exitImpersonate(): Promise<LoggedInUserInfo> {
        return httpClient.post(BACKEND + "/auth/exit-impersonate");
    },

    currentUser(): Promise<UserModel> {
        return httpClient.get(BACKEND + "/auth/current-user");
    },

    isDataModifyingPage(location: Location) {
        return matchRoutes([
            { path: WHOLESALER_PATHS.PRICE_POSTING.BATCH_IMPORT },
            { path: WHOLESALER_PATHS.PRICE_POSTING.BATCH_IMPORT_3 },
            { path: WHOLESALER_PATHS.PRICE_POSTING.EDIT_PROFILE },
            { path: WHOLESALER_PATHS.PRICE_POSTING.ADD_BRAND_ITEM },
            { path: WHOLESALER_PATHS.PRICE_POSTING.EDIT_BRAND_ITEM },
            { path: WHOLESALER_PATHS.APPOINTMENT.CREATE_LETTER },
            { path: WHOLESALER_PATHS.CERTIFY.STEP1 },
            { path: WHOLESALER_PATHS.CERTIFY.STEP2 },
            { path: WHOLESALER_PATHS.CERTIFY.STEP3 },
            { path: USER_PATHS.PROFILE }
        ], location) !== null;
    }
};
