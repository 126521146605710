import React, { useState } from "react";
import { useParams } from "react-router-dom";
import LicenseInfo from "components/LicenseInfo";
import ReturnToSearchLink from "pages/admin/components/ReturnToSearchLink";
import { useAuthStore } from "store/AuthStoreProvider";
import UserList from "pages/admin/components/UserList";
import URLUtil from "utils/URLUtil";
import { ManageUsersRequest } from "pages/admin/ManageUsers";

const LicenseUsers = ()=>{
    const auth = useAuthStore();
    const isBA = auth.permissionStore.isBusinessAdmin;

    const { licenseId } = useParams();
    const mur = URLUtil.readSearchParams() as Omit<ManageUsersRequest, "license_id">;
    const [urlData, setUrlData] = useState<ManageUsersRequest>(isBA ?
        {
            ...mur,
            license_id: auth.licenseId, //force BA to see user only from his license
            name: undefined,
            email: undefined,
            status: undefined
        }
        : {
            ...mur,
            license_id: parseInt(licenseId || "0")
        }
    );

    return <div className="d-flex flex-column">
        <div className="mb-4 pb-md-3 d-flex flex-column flex-md-row align-items-center">
            <div className="order-0 order-md-1" hidden={ isBA }>
                <ReturnToSearchLink/>
            </div>
            <h1 className="text-primary mb-3 mb-md-0 mt-3 mt-md-0 me-md-auto order-1 order-md-0">License Users</h1>
        </div>
        <LicenseInfo licenseId={ urlData.license_id || 0 } showDelinquentStatus={ true }/>
        <div className="mt-4">
            <UserList urlData={ urlData } setUrlData={ setUrlData } licenseIdForRolesMode={ urlData.license_id }/>
        </div>
    </div>;
};

export default LicenseUsers;
