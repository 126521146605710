import React from "react";
import Input from "components/form/Input";
import Form from "react-bootstrap/Form";

const WholesalerMidMonthView = () => {

    return <div className="d-flex flex-column">
        <div className="mb-4 mb-md-5">
            <h1 className="text-primary mb-4">Mid-Month Limited Availability</h1>
            <p className="mb-0">On this page, you may manage Limited Availability for your products. You can select the schedule to which Limited Availability will apply, and you can choose which items to mark as limited. This will allow you to control the availability of your products and manage supply and demand more effectively.</p>
        </div>
        <div className="card">
            <div className="card-body">
                <form>
                    <div className="row">
                        <div className="col-12 col-lg-6 col-xl-4">
                            <div className="pe-lg-4">
                                <Input name="name" placeholder="Enter brand name" label="Brand name" type="text"/>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-xl-4">
                            <div className="ps-lg-4 px-xl-2">
                                <Input name="name" placeholder="Enter item description" label="Item description" type="text"/>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-xl-4">
                            <div className="pe-lg-4 ps-xl-4">
                                <Input name="name" placeholder="Enter item number" label="Item number" type="number"/>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-xl-4">
                            <div className="ps-lg-4 ps-xl-0 pe-xl-4">
                                <Input name="approval" placeholder="Enter approval or BLR number" label="Approval or BLR number" type="text" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-xl-4">
                            <div className="pe-lg-4 px-xl-2">
                                <Input name="ttb_id" label="TTB / CoLA ID number" placeholder="Enter TTB / CoLA ID number" type="text"/>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-xl-4">
                            <div className="ps-lg-4 pt-2">
                                <div className="switch-year mb-3 mt-lg-4">
                                    <Form.Switch
                                        type="switch"
                                        id="custom-switch"
                                        label="Limited Availability"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-xl-4">
                            <div className="pe-lg-4">
                                <div className="form-group">
                                    <label htmlFor="Posting-type" className="form-label">Posting type</label>
                                    <select id="Posting-type" className="form-select" aria-label="Select">
                                        <option selected>Wine to retail</option>
                                        <option value="1">Liquor to retail</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-xl-4">
                            <div className="ps-lg-4 px-xl-2">
                                <div className="form-group">
                                    <label htmlFor="Posting-month" className="form-label">Posting month</label>
                                    <select id="Posting-month" className="form-select" aria-label="Select">
                                        <option selected>March</option>
                                        <option value="1">April</option>
                                        <option value="2">May</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-3">
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                            <a href="/" className="btn btn-outline-secondary w-100">Clear form</a>
                        </div>
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                            <a href="/" className="btn btn-primary w-100">Filter</a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div className="mt-4">
            <h5 className="pt-3">Found <strong>2 products</strong> for November 2022</h5>
            <div className="table-responsive accordion mt-4">
                <table className="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th>Brand name</th>
                            <th>Product Name</th>
                            <th>Product Number</th>
                            <th>Beverage Type</th>
                            <th>Approval or BLR #</th>
                            <th>TTB / CoLA #</th>
                            <th>Package Type</th>
                            <th>Size/Unit</th>
                            <th>Allocation Method</th>
                            <th>Allocation Description</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <a className="table-collapse-a collapsed" data-bs-toggle="collapse" href="#collapseTr1" role="button" aria-expanded="false" aria-controls="collapseTr">
                                    <i className="bi-caret-right-fill"></i>
                                </a>
                            </td>
                            <td>Neutral Spirits</td>
                            <td>Regular</td>
                            <td>Regular description</td>
                            <td>ALBA</td>
                            <td>55333322</td>
                            <td>12697555</td>
                            <td>Regular</td>
                            <td>6 ML</td>
                            <td>Maximum per account</td>
                            <td>70/30 - Off/On 1 case max per act</td>
                            <td className="text-nowrap">
                                <button className="btn btn-link py-0 px-1" title="Edit" data-bs-toggle="modal" data-bs-target="#exampleModalEdit">
                                    <i className="bi-pencil me-1"></i> Edit
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={ 13 } className="p-0">
                                <div className="collapse" id="collapseTr2">
                                    <div className="p-4">
                                        <div className="d-flex flex-column flex-sm-row align-items-start">
                                            <div className="nav flex-column nav-pills btn-group-vertical min-wp-230" id="nav-tab" role="tablist" aria-orientation="vertical">
                                                <button className="btn btn-outline-secondary active" id="nav-1-tab" data-bs-toggle="tab" data-bs-target="#nav-1" type="button" role="tab" aria-controls="nav-1" aria-selected="false">Item details</button>
                                                <button className="btn btn-outline-secondary" id="nav-2-tab" data-bs-toggle="tab" data-bs-target="#nav-2" type="button" role="tab" aria-controls="nav-2" aria-selected="false">NYS supplier details</button>
                                                <button className="btn btn-outline-secondary" id="nav-3-tab" data-bs-toggle="tab" data-bs-target="#nav-3" type="button" role="tab" aria-controls="nav-3" aria-selected="false">Discount details</button>
                                                <button className="btn btn-outline-secondary" id="nav-7-tab" data-bs-toggle="tab" data-bs-target="#nav-7" type="button" role="tab" aria-controls="nav-7" aria-selected="false">Limited availability</button>
                                                <button className="btn btn-outline-secondary" id="nav-8-tab" data-bs-toggle="tab" data-bs-target="#nav-8" type="button" role="tab" aria-controls="nav-8" aria-selected="true">Combo details</button>
                                            </div>
                                            <div className="tab-content flex-fill" id="nav-tabContent">
                                                <div className="tab-pane fade show active" id="nav-1" role="tabpanel" aria-labelledby="nav-1-tab">
                                                    <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                        <h5 className="mb-3 fw-bold">Item details</h5>
                                                        <div className="row mt-4">
                                                            <div className="col-12 col-lg-6 col-xl-4 mb-3">
                                                                <p className="mb-2"><strong>Proof:</strong> 150.0</p>
                                                                <p className="mb-2"><strong>Alcohol content:</strong> 7% to 14%</p>
                                                                <p className="mb-2"><strong>Age of year:</strong> not applicable</p>
                                                            </div>
                                                            <div className="col-12 col-lg-6 col-xl-4 mb-lg-3">
                                                                <p className="mb-2"><strong># of bottles per case / sleeve:</strong> 1/12</p>
                                                                <p className="mb-2"><strong>Case only:</strong> N</p>
                                                                <p className="mb-2"><strong>BT / SLV Split case charge:</strong> 1.00</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="nav-2" role="tabpanel" aria-labelledby="nav-2-tab">
                                                    <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                        <h5 className="mb-3 fw-bold">NYS supplier details</h5>
                                                        <p className="mb-2"><strong>NYS supplier #:</strong> 12388990</p>
                                                        <p className="mb-2"><strong>NYS supplier item #:</strong> 23188990</p>
                                                        <p className="mb-2"><strong>NYS label:</strong> N</p>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="nav-3" role="tabpanel" aria-labelledby="nav-3-tab">
                                                    <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                        <h5 className="mb-3 fw-bold">Discount details</h5>
                                                        <p className="mb-2"><strong>Discount code:</strong> ABC</p>
                                                        <p className="mb-2"><strong>Discount:</strong> $4.55 on 12 bottles</p>
                                                        <p className="mb-2"><strong>NYC price per bottle:</strong> 30.10</p>
                                                        <p className="mb-2"><strong>NYC price per case:</strong> 180</p>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="nav-7" role="tabpanel" aria-labelledby="nav-7-tab">
                                                    <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                        <h5 className="mb-3 fw-bold">Limited availability</h5>
                                                        <p className="mb-2"><strong>Allocation method:</strong> Unsold accounts</p>
                                                        <p className="mb-2"><strong>Allocation description:</strong> test description</p>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="nav-8" role="tabpanel" aria-labelledby="nav-8-tab">
                                                    <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                        <h5 className="mb-3 fw-bold">Combo details</h5>
                                                        <p className="mb-2">Consumer packaging</p>
                                                        <p className="mb-2">Supplier assem</p>
                                                        <div className="row mt-4">
                                                            <div className="col-12 col-lg-6 col-xl-4 mb-3">
                                                                <p className="mb-2"><strong>Item 1:</strong></p>
                                                                <p className="mb-2"><strong>NYS Supplier Item:</strong> 1233455555</p>
                                                                <p className="mb-2"><strong>Quantity:</strong> 12</p>
                                                            </div>
                                                            <div className="col-12 col-lg-6 col-xl-4 mb-lg-3">
                                                                <p className="mb-2"><strong>Item 2:</strong></p>
                                                                <p className="mb-2"><strong>NYS Supplier Item:</strong> 6123355855</p>
                                                                <p className="mb-2"><strong>Quantity:</strong> 5</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a className="table-collapse-a collapsed" data-bs-toggle="collapse" href="#collapseTr2" role="button" aria-expanded="false" aria-controls="collapseTr">
                                    <i className="bi-caret-right-fill"></i>
                                </a>
                            </td>
                            <td>Neutral Spirits</td>
                            <td>Regular</td>
                            <td>Regular description</td>
                            <td>ALBA</td>
                            <td>55333322</td>
                            <td>12697555</td>
                            <td>Regular</td>
                            <td>6 ML</td>
                            <td>
                                <label htmlFor="Posting-type" className="form-label d-none">Allocation Method</label>
                                <select id="Posting-type" className="form-select" aria-label="Select">
                                    <option selected value="1">Past sales history</option>
                                    <option value="2">Published 3rd part sources</option>
                                    <option value="3">Unsold accounts</option>
                                    <option value="4">First come First served</option>
                                    <option value="5">Advance interest</option>
                                    <option value="6">Closeout sales</option>
                                    <option value="7">Distributor assembled combo pack</option>
                                    <option value="8">Other with prior SLA approval</option>
                                </select>
                            </td>
                            <td>
                                <label htmlFor="Textarea" className="form-label d-none">Allocation Description</label>
                                <textarea id="Textarea" className="form-control" placeholder="Textarea" value="Some text"></textarea>
                            </td>
                            <td className="text-nowrap">
                                <button className="btn btn-link py-0 px-1" title="Save" data-bs-toggle="modal" data-bs-target="#exampleModalEdit">
                                    <i className="bi-save me-1"></i> Save
                                </button>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={ 13 } className="p-0">
                                <div className="collapse" id="collapseTr1">
                                    <div className="p-4">
                                        <div className="d-flex flex-column flex-sm-row align-items-start">
                                            <div className="nav flex-column nav-pills btn-group-vertical min-wp-230" id="nav-tab" role="tablist" aria-orientation="vertical">
                                                <button className="btn btn-outline-secondary active" id="nav-1-tab" data-bs-toggle="tab" data-bs-target="#nav-1" type="button" role="tab" aria-controls="nav-1" aria-selected="false">Item details</button>
                                                <button className="btn btn-outline-secondary" id="nav-2-tab" data-bs-toggle="tab" data-bs-target="#nav-2" type="button" role="tab" aria-controls="nav-2" aria-selected="false">NYS supplier details</button>
                                                <button className="btn btn-outline-secondary" id="nav-3-tab" data-bs-toggle="tab" data-bs-target="#nav-3" type="button" role="tab" aria-controls="nav-3" aria-selected="false">Discount details</button>
                                                <button className="btn btn-outline-secondary" id="nav-7-tab" data-bs-toggle="tab" data-bs-target="#nav-7" type="button" role="tab" aria-controls="nav-7" aria-selected="false">Limited availability</button>
                                                <button className="btn btn-outline-secondary" id="nav-8-tab" data-bs-toggle="tab" data-bs-target="#nav-8" type="button" role="tab" aria-controls="nav-8" aria-selected="true">Combo details</button>
                                            </div>
                                            <div className="tab-content flex-fill" id="nav-tabContent">
                                                <div className="tab-pane fade show active" id="nav-1" role="tabpanel" aria-labelledby="nav-1-tab">
                                                    <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                        <h5 className="mb-3 fw-bold">Item details</h5>
                                                        <div className="row mt-4">
                                                            <div className="col-12 col-lg-6 col-xl-4 mb-3">
                                                                <p className="mb-2"><strong>Proof:</strong> 150.0</p>
                                                                <p className="mb-2"><strong>Alcohol content:</strong> 7% to 14%</p>
                                                                <p className="mb-2"><strong>Age of year:</strong> not applicable</p>
                                                            </div>
                                                            <div className="col-12 col-lg-6 col-xl-4 mb-lg-3">
                                                                <p className="mb-2"><strong># of bottles per case / sleeve:</strong> 1/12</p>
                                                                <p className="mb-2"><strong>Case only:</strong> N</p>
                                                                <p className="mb-2"><strong>BT / SLV Split case charge:</strong> 1.00</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="nav-2" role="tabpanel" aria-labelledby="nav-2-tab">
                                                    <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                        <h5 className="mb-3 fw-bold">NYS supplier details</h5>
                                                        <p className="mb-2"><strong>NYS supplier #:</strong> 12388990</p>
                                                        <p className="mb-2"><strong>NYS supplier item #:</strong> 23188990</p>
                                                        <p className="mb-2"><strong>NYS label:</strong> N</p>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="nav-3" role="tabpanel" aria-labelledby="nav-3-tab">
                                                    <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                        <h5 className="mb-3 fw-bold">Discount details</h5>
                                                        <p className="mb-2"><strong>Discount code:</strong> ABC</p>
                                                        <p className="mb-2"><strong>Discount:</strong> $4.55 on 12 bottles</p>
                                                        <p className="mb-2"><strong>NYC price per bottle:</strong> 30.10</p>
                                                        <p className="mb-2"><strong>NYC price per case:</strong> 180</p>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="nav-7" role="tabpanel" aria-labelledby="nav-7-tab">
                                                    <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                        <h5 className="mb-3 fw-bold">Limited availability</h5>
                                                        <p className="mb-2"><strong>Allocation method:</strong> Unsold accounts</p>
                                                        <p className="mb-2"><strong>Allocation description:</strong> test description</p>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="nav-8" role="tabpanel" aria-labelledby="nav-8-tab">
                                                    <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
                                                        <h5 className="mb-3 fw-bold">Combo details</h5>
                                                        <p className="mb-2">Consumer packaging</p>
                                                        <p className="mb-2">Supplier assem</p>
                                                        <div className="row mt-4">
                                                            <div className="col-12 col-lg-6 col-xl-4 mb-3">
                                                                <p className="mb-2"><strong>Item 1:</strong></p>
                                                                <p className="mb-2"><strong>NYS Supplier Item:</strong> 1233455555</p>
                                                                <p className="mb-2"><strong>Quantity:</strong> 12</p>
                                                            </div>
                                                            <div className="col-12 col-lg-6 col-xl-4 mb-lg-3">
                                                                <p className="mb-2"><strong>Item 2:</strong></p>
                                                                <p className="mb-2"><strong>NYS Supplier Item:</strong> 6123355855</p>
                                                                <p className="mb-2"><strong>Quantity:</strong> 5</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <ul className="justify-content-center pagination mt-3 mb-5">
                <li className="page-item disabled">
                    <span className="page-link">
                        <span className="bi bi-arrow-left me-2"></span>Prev
                    </span>
                </li>
                <li className="page-item active">
                    <span className="page-link">1</span>
                </li>
                <li className="page-item">
                    <a className="page-link" role="button" href="/">2</a>
                </li>
                <li className="page-item">
                    <a className="page-link" role="button" href="/">3</a>
                </li>
                <li className="page-item">
                    <a className="page-link" role="button" href="/">...</a>
                </li>
                <li className="page-item">
                    <a className="page-link" role="button" href="/">50</a>
                </li>
                <li className="page-item">
                    <a className="page-link" role="button" href="/">
                        Next<span className="bi bi-arrow-right ms-2"></span>
                    </a>
                </li>
            </ul>
        </div>
    </div>;
};

export default WholesalerMidMonthView;

