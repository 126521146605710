import React, { useEffect, useState } from "react";
import JobService from "services/admin/JobService";
import { JobInfo } from "models/JobInfo";
import Spinner from "components/Spinner";
import { JobComponent } from "pages/admin/components/JobComponent";
import { FtpJobComponent } from "pages/admin/components/FtpJobComponent";

export const JobsDashboard = () => {
    const [jobStatus, setJobStatus] = useState<JobInfo[]>([]);
    const [ isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => loadData() , []);

    function loadData() {
        JobService.getJobStatus().then((response) => {
            setJobStatus(response);
        });
    }

    return <div className="d-flex flex-column">
        <div className="mb-4 pb-md-3">
            <h1 className="text-primary mb-4">Jobs Dashboard</h1>
            <p className="mb-0">Automatically running jobs are managed here.</p>
        </div>

        { isLoading && <Spinner className="mt-4 pt-2 text-center" /> }

        <div className="row">
            { jobStatus?.map((job, i) =>
            { if (job.jobName ==="ftp_reports") {
                return <FtpJobComponent key={ i } job={ job } loadData={ loadData } setIsLoading={ setIsLoading }/>;
            } else {
                return <JobComponent key={ i } job={ job } loadData={ loadData } setIsLoading={ setIsLoading } />;
            }
            } ) }
        </div>
    </div>;
};
