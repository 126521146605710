import React from "react";
import Input from "components/form/Input";

const AdminView = () => {

    return <div className="d-flex flex-column">
        <div className="mb-4 mb-md-5">
            <h1 className="text-primary mb-0">Master Filers</h1>
        </div>
        <div className="card">
            <div className="card-body pb-1">
                <div className="text-center">
                    <p>Looks like you don&apos;t have any master filers. You can create your first one by clicking button below:</p>
                    <div className="form-group mt-4">
                        <a href="/" className="btn btn-primary" title="Create master filter" data-bs-toggle="modal" data-bs-target="#exampleModalAdd">Create master filer</a>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal fade" id="exampleModalAdd" aria-labelledby="exampleModalAddLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalAddLabel">Add new master filer</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Cancel"></button>
                    </div>
                    <div className="modal-body">
                        <div>
                            <div className="d-flex flex-row align-items-end">
                                <div className="flex-fill">
                                    <Input name="Serial" placeholder="Enter Master Serial number" label="Master Serial number" type="number"/>
                                </div>
                                <div className="mb-1">
                                    <a className="btn btn-outline-primary mb-3 ms-3" href="/">Search</a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-lg-6">
                                    <div className="mb-2">
                                        <p className="mb-0"><strong>Name:</strong></p>
                                        <p className="mb-0">Some name</p>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="mb-2">
                                        <p className="mb-0"><strong>Address:</strong></p>
                                        <p className="mb-0">123 Demo, ave demo, NY, 216444</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card mt-4">
                            <div className="card-body pb-3 pt-3">
                                <div className="styleguide-head">
                                    <div className="d-flex flex-row align-items-end">
                                        <h5 className="me-auto mb-0">Secondary Serial number</h5>
                                        <a className="btn btn-outline-secondary" href="/">Remove</a>
                                    </div>
                                    <hr/>
                                </div>
                                <div className="">
                                    <div className="d-flex flex-row align-items-end">
                                        <div className="flex-fill">
                                            <Input name="Serial1" placeholder="Enter Secondary Serial number" label="Secondary Serial number" type="number"/>
                                        </div>
                                        <div className="mb-1">
                                            <a className="btn btn-outline-primary mb-3 ms-3" href="/">Search</a>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <div className="mb-2">
                                                <p className="mb-0"><strong>Name:</strong></p>
                                                <p className="mb-0">Some name</p>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <div className="mb-2">
                                                <p className="mb-0"><strong>Address:</strong></p>
                                                <p className="mb-0">123 Demo, ave demo, NY, 216444</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
                        <a href="/" className="btn btn-secondary">Add secondary serial number</a>
                        <button type="button" className="btn btn-primary">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default AdminView;
