import { modalStore } from "store/ModalStoreProvider";
import React from "react";

export const showClearLimitAvailDialog = (onClear: () => void) => {
    modalStore.setHeader("Clear Limited Availability");
    modalStore.setBody(<ClearLimAvailDialog onClear={ onClear } />);
    modalStore.setAdditionalButtons([
        <button key={ 0 } type="button" className="btn btn-primary" onClick={ () => onClear() }>Yes</button>
    ]);
    modalStore.showModal();
};

const ClearLimAvailDialog = ({ onClear }: { onClear: () => void }) => {

    return <div>You are going to clear the limited availability. Are you sure?</div>;
};
