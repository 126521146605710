import FormUtil from "utils/FormUtil";

interface Props {
    status: string;
    certificationDate?: string;
}

const PriceStatus = (props: Props) => {
    function statusToStyle(status: string) {
        switch(status) {
        case "NOT_CERTIFIED": return "badge bg-blue";
        case "CERTIFIED": return "badge bg-green";
        case "PUBLISHED": return "badge bg-info";
        case "EQUALIZABLE": return "badge bg-info";
        case "EFFECTIVE": return "badge bg-blue";
        default: return "badge bg-grey";
        }
    }

    function statusIconToStyle(status: string) {
        switch(status) {
        case "NOT_CERTIFIED": return "bi-pencil";
        case "CERTIFIED": return "bi-award";
        case "PUBLISHED": return "bi-check-circle";
        case "EQUALIZABLE": return "bi-check-circle";
        case "EFFECTIVE": return "bi-exclamation-circle";
        default: return "bi-exclamation-circle";
        }
    }

    function certifiedOn(status: string, certificationDate?: string) {
        return status === "CERTIFIED"
            ? " on " + certificationDate
            : "";
    }

    return <span className={ statusToStyle(props.status) }>
        <i className={ statusIconToStyle(props.status) + " me-2" }></i>{ FormUtil.statusToName(props.status) + certifiedOn(props.status, props.certificationDate) }
    </span>;
};

export default PriceStatus;
