import httpClient from "api/HttpClient";
import { PageableRequest } from "models/requests/PageableRequest";
import { PageableResponse } from "models/response/PageableResponse";
import { LetterModel } from "models/LetterModel";
import { SaveLetterRequest } from "pages/wholesaler/appointment/models/SaveLetterRequest";
import BACKEND from "services/Constants";

export default {

    search(request: PageableRequest): Promise<PageableResponse<LetterModel>> {
        return httpClient.search(BACKEND + "/wholesaler/letter/search", request);
    },

    isLettersPresent(): Promise<boolean> {
        return httpClient.get(BACKEND + "/wholesaler/letter/is-letters-present");
    },

    save(request: SaveLetterRequest): Promise<boolean> {
        return httpClient.post(BACKEND + "/wholesaler/letter/save", request);
    },

    update(request: SaveLetterRequest): Promise<boolean> {
        return httpClient.post(BACKEND + "/wholesaler/letter/update", request);
    },

    getLetter(letter_id: string): Promise<LetterModel> {
        return httpClient.get(BACKEND + "/wholesaler/letter/find-by-id?id=" + letter_id);
    },

    uploadFile(file: File): Promise<string> {
        const formData = new FormData();
        formData.append("file", file, file.name);
        return httpClient.post(BACKEND + "/wholesaler/letter/upload-file", formData);
    },

    downloadFile(storagePath: string, fileName: string): string {
        const path = encodeURIComponent(storagePath);
        const name = encodeURIComponent(fileName);
        return `${BACKEND}/wholesaler/letter/download-file?path=${path}&name=${name}`;
    },
    delete(id: number) {
        return httpClient.post(BACKEND+"/wholesaler/letter/delete/"+id);
    }
};
