import { ProductModel } from "models/ProductModel";
import React, { Fragment } from "react";
import ErroneousValue from "components/ErroneousValue";
import { TabModel } from "components/product-tab-widget/ProductTabContainer";
import { combo_assembly_description, combo_disassembly_description } from "services/ComboConstants";
import { Row } from "react-bootstrap";
import { Combo } from "models/Combo";
import classNames from "classnames";
import * as _ from "lodash";
import StringUtils from "utils/StringUtils";

const DISPLAYED_PROPERTIES = ["combo_asse","combo_disa"];
const ComboTabHeader = (product: ProductModel) => {
    const hasErrors = (p: ProductModel) =>
        StringUtils.hasAnyProperty(p.product_errors, DISPLAYED_PROPERTIES) ||
        !_.isEmpty(p.combo_errors);

    return <span className = { classNames(hasErrors(product) ? "text-danger" : "" ) }>
        { hasErrors(product) && <i className="bi bi-exclamation-circle-fill me-2"></i> }
        Combo
    </span>;
};

const ComboTabBody = (product: ProductModel) => {
    return <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
        <h5 className="mb-3 fw-bold">Combo details</h5>
        <p className="mb-3">
            { product.combo_asse && product.combo_disa
                ? <Fragment>
                    <ErroneousValue item={ product } formattedValue={ combo_assembly_description(product.combo_asse) } name={ "combo_asse" }/>
                    &nbsp;/&nbsp;
                    <ErroneousValue item={ product } formattedValue={ combo_disassembly_description(product.combo_disa) } name={ "combo_disa" }/>
                </Fragment>
                : "N/A"
            }
        </p>
        <Row>
            { (product.combos || []).slice(0,5).map((c, i2)=> {
                const cItem : Combo = { ...c, errors: product.combo_errors && product.combo_errors[c.id.toString()] };
                return <div key={ i2 } className={ "col-12 col-lg-6 col-xl-4 mb-3" }>
                    <p className="mb-2">
                        <strong>Item { i2 + 1 }</strong>
                    </p>
                    <p className="mb-2">
                        <strong>NYS Supplier Item:&nbsp;</strong>&nbsp;
                        <ErroneousValue item={ cItem } name={ "prod_item" } />
                    </p>
                    <p className="mb-2">
                        <strong>NYS Supplier Item Name:&nbsp;</strong>&nbsp;
                        <ErroneousValue item={ cItem } name={ "prod_name" } />
                    </p>
                    <p className="mb-2">
                        <strong>Quantity:&nbsp;</strong>
                        <ErroneousValue item={ cItem } name={ "quantity" } />
                    </p>
                </div>;
            }) }

            { (product.combos || []).slice(5).map((c, i1)=> {
                const cItem : Combo = { ...c, errors: product.combo_errors && product.combo_errors[c.id.toString()] };
                return <div key={ i1 } className={ "col-12 col-lg-6 col-xl-4 mb-3" }>
                    <p className="mb-2">
                        <strong>Item { i1 + 6 }</strong>
                    </p>
                    <p className="mb-2">
                        <strong>NYS Supplier Item:&nbsp;</strong>
                        <ErroneousValue item={ cItem } name={ "prod_item" } />
                    </p>
                    <p className="mb-2">
                        <strong>NYS Supplier Item Name:&nbsp;</strong>&nbsp;
                        <ErroneousValue item={ cItem } name={ "prod_name" } />
                    </p>
                    <p className="mb-2">
                        <strong>Quantity:&nbsp;</strong>
                        <ErroneousValue item={ cItem } name={ "quantity" } />
                    </p>
                </div>;
            }) }
        </Row>
    </div>;
};

export const ComboTab: TabModel = { header: ComboTabHeader, body: ComboTabBody };
