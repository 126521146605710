import httpClient from "api/HttpClient";
import BACKEND from "services/Constants";
import { PageableResponse } from "models/response/PageableResponse";
import { PageableRequest } from "models/requests/PageableRequest";
import { ProductModel } from "models/ProductModel";
import { CountItem } from "models/response/CountItem";
import { CertificationInfo } from "models/response/CertificationInfo";
import { UpcomingScheduleModel } from "pages/admin/UpcomingSchedules";

const root = `${BACKEND}/admin/upcoming-schedules`;

export default {
    getSchedules(licenseId: number): Promise<UpcomingScheduleModel[]> {
        return httpClient.get(`${root}/get-schedules/${licenseId}`);
    },

    search(request: PageableRequest, licenseId: string): Promise<PageableResponse<ProductModel>> {
        return httpClient.search(`${root}/search-brands/${licenseId}`, request);
    },

    isRecordsPresent(licenseId: string, type: string, year: string, month: string): Promise<boolean> {
        return httpClient.get(`${root}/is-brands-presents?postType=${type}&month=${month}&year=${year}&id=${licenseId}`);
    },

    countProductsByBeverageTypes(type: string, year: string, month: string, licenseId: string): Promise<Array<CountItem>> {
        return httpClient.get(`${root}/countProductsByBeverageTypes?type=${type}&month=${month}&year=${year}&id=${licenseId}`);
    },

    countProductsByPackageTypes(type: string, year: string, month: string, licenseId: string): Promise<Array<CountItem>> {
        return httpClient.get(`${root}/countProductsByPackageTypes?type=${type}&month=${month}&year=${year}&id=${licenseId}`);
    },

    getCertificationInfo(licenseId: number, month: number, year: number, postType: string): Promise<CertificationInfo> {
        return httpClient.get(`${root}/get-certification-info?type=${postType}&month=${month}&year=${year}&id=${licenseId}`);
    }
};
