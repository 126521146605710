import { ProductModel } from "models/ProductModel";
import * as _ from "lodash";

export enum DISCOUNT_SOURCES {
    CREATE = "CREATE",
    SELECT = "SELECT"
}

export enum TabName {
    ITEM_INFO ="ITEM_INFO",
    DISCOUNTS ="DISCOUNTS"
}

export type ProductModelForForm = ProductModel & {
    discount_source: string,
    active_tab: TabName
}

const step1Fields = ["brand_name", "brand_reg", "vintage", "reg_combo", "ttb_id", "bev_type", "proof", "alcohol", "label_type",
    "distrib_id", "post_month", "post_type", "post_year", "combo_disa", "combo_asse"] as const;

export const isStep1Field = (field: string)=> (step1Fields as unknown as string[]).includes(field);

export const ITEMS_FIELD = "items";
const ITEM_INDEX_RE = ITEMS_FIELD+"\\[(\\d+)\\]";

export const getItemIndexFromField = (field: string)=>{
    const m = RegExp(ITEM_INDEX_RE,"g").exec(field);
    return m === null ? null : m[1];
};

//https://www.typescriptlang.org/docs/handbook/2/indexed-access-types.html
//https://stackoverflow.com/a/45257357/2056178
export type ManualProduct = Pick<ProductModel, typeof step1Fields[number] > & {
    items: ProductModelForForm[]
}

export const createManualProductFromMap = (map: any)=>{
    const s1 = _.pick(map, step1Fields);
    const s2 = _.omit(map, step1Fields);//TODO `omit` is much slower than `pick`. Think about replacing it with `pick`
    return { ...s1, items:[
        { ...s2,
            active_tab: TabName.ITEM_INFO,
            discount_source: map.discount_id ? DISCOUNT_SOURCES.SELECT: DISCOUNT_SOURCES.CREATE
        }
    ] } as ManualProduct;
};
