import React from "react";

interface FormLabelWithTooltipParams {
    label: string,
    tooltip: string,
    htmlFor: string
}

export const FormLabelWithTooltip = ({ label, tooltip, htmlFor }:FormLabelWithTooltipParams)=>{
    return <div className="form-label-with-tooltip">
        <label className="form-label" htmlFor={ htmlFor }>{ label }</label>
        <div className="tooltip-wr tooltip-wr-small ms-2">
            <span className="tooltip-icon bi-question-circle-fill"/>
            <div className="tooltip-msg text-center">
                <p>{ tooltip }</p>
            </div>
        </div>
    </div>;
};
