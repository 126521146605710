import httpClient from "api/HttpClient";
import BACKEND from "services/Constants";
import { JobInfo } from "models/JobInfo";

export default {
    getJobStatus(): Promise<JobInfo[]> {
        return httpClient.get(BACKEND + "/admin/jobs/get-status");
    },

    runJob(jobName: string): Promise<any> {
        return httpClient.post(BACKEND + "/admin/jobs/run-job?jobName="+jobName);
    },

    runFtpReportsJob(year: number, month: number): Promise<any> {
        return httpClient.post(BACKEND + "/admin/jobs/run-ftp-job?year="+year+"&month="+month);
    }
};
