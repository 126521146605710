import { LicenseModel } from "models/license.model";
import httpClient from "api/HttpClient";
import BACKEND from "services/Constants";
import { ProfileTermModel } from "models/ProfileTermModel";

export default {

    getProfile(postType: string, month: string, year: string): Promise<LicenseModel> {
        return httpClient.get(BACKEND + "/wholesaler/profile-posting/get-profile"
            + "?month=" + month + "&post_type=" + postType + "&year=" + year);
    },

    saveProfileTerm(profileTerm: ProfileTermModel): Promise<boolean> {
        return httpClient.post(BACKEND + "/wholesaler/profile-posting/save-profile-term/", profileTerm);
    }
};
