import React, { Fragment, useEffect, useState } from "react";
import Paginator from "components/Paginator";
import { Link, generatePath, useNavigate } from "react-router-dom";
import { ADMIN_PATHS } from "pages/admin/AdminRoutes";
import LicenseStatusCell from "components/LicenseStatusCell";
import LicenseSearchForm, { LicenseParamModel } from "pages/admin/components/LicenseSearchForm";
import URLUtil from "utils/URLUtil";
import { PageableRequest } from "models/requests/PageableRequest";
import { PageInfo } from "models/PageInfo";
import { LicenseModel } from "models/license.model";
import ManageLicensesService from "services/admin/ManageLicensesService";
import Spinner from "components/Spinner";
import LicenseTypeConstants from "services/LicenseTypeConstants";
import { useAuthStore } from "store/AuthStoreProvider";
import { useUtilStore } from "store/UtilStoreProvider";

type ManageLicensesRequest = LicenseParamModel & PageInfo;
export type AdminLicenseModel = LicenseModel &
    {
        org_type: string,
        has_certified_product: boolean
    };

export const Licenses = () => {
    const navigate = useNavigate();
    const [urlData, setUrlData] = useState<ManageLicensesRequest>(URLUtil.readSearchParams() as ManageLicensesRequest);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(1);
    const [totalItemsCount, setTotalItemsCount] = useState<number>(1);
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [licenses, setLicenses] = useState<AdminLicenseModel[]>([]);

    const utilStore = useUtilStore();
    const { permissionStore } = useAuthStore();

    useEffect(() => {
        URLUtil.updateUrlParams(ADMIN_PATHS.LICENSES.SEARCH, urlData, navigate);
        performQuery();
    }, [urlData]);

    const onSubmitForm = (data: LicenseParamModel) => {
        setUrlData({
            license: data.license,
            premise_or_trade_name: data.premise_or_trade_name?.trim(),
            special: data.special,
            products: data.products,
            page: 1
        });
    };

    const performQuery = () => {
        const request = {
            pageInfo: { page: urlData.page },
            filters:  URLUtil.pureUrlParameters(urlData)
        } as PageableRequest;

        utilStore.setAdminReturnParams(request);
        setIsDataLoading(true);
        ManageLicensesService.lookup(request).then(response => {
            setLicenses( response.data || []);
            setPageCount(response.pageInfo.pagesCount || 1);
            setTotalItemsCount(response.pageInfo.totalItemsCount || 0);
        }).finally(() => setIsDataLoading(false));
    };

    function handlePageClick(pageNumber: number) {
        setPageNumber(pageNumber);
        setUrlData({ ...urlData, page: pageNumber });
    }

    return <div className="d-flex flex-column">
        <div className="mb-4 pb-md-3">
            <h1 className="text-primary mb-4">Manage Licenses</h1>
            <p>
                This page provides comprehensive management capabilities for your licenses, users, and schedules.&nbsp;
                View past schedules, set and manage current ones, and easily manage users all in one place.
            </p>
        </div>
        <div>
            <LicenseSearchForm
                license={ urlData.license }
                premise_or_trade_name={ urlData.premise_or_trade_name }
                special={ urlData.special }
                products={ urlData.products }
                onSubmitForm={ onSubmitForm }
            />
            { isDataLoading && <Spinner className="mt-4 pt-2 text-center"/> }
            { !isDataLoading &&
                <div>
                    <div className="me-lg-auto pt-4 mt-2 mb-lg-0">
                        <h5 className="mb-0">Found <strong>{ totalItemsCount }</strong> licenses</h5>
                    </div>
                    <Fragment>
                        <div className="table-responsive mt-4">
                            <table className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>License ID</th>
                                        <th>Serial Number</th>
                                        <th>Premise Name</th>
                                        <th>Trade Name</th>
                                        <th>Type</th>
                                        <th>Status</th>
                                        <th>Certified Postings</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        licenses.map((model, index) => {
                                            const isWholesalerLicense = LicenseTypeConstants.isWholesaler(model.org_type);
                                            return (
                                                <tr key={ index }>
                                                    <td>{ model.permit_id || "N/A" }</td>
                                                    <td>{ model.serial_number }</td>
                                                    <td>{ model.premise_name }</td>
                                                    <td>{ model.dba }</td>
                                                    <td>{ model.org_type && LicenseTypeConstants.getTitleByCode(model.org_type) }</td>
                                                    <td>
                                                        <LicenseStatusCell statusCode={ model.status }/>
                                                    </td>
                                                    <td>
                                                        { model.has_certified_product ? "Yes" : "No" }
                                                    </td>
                                                    <td className="text-nowrap">
                                                        { !permissionStore.isSlaCod() &&
                                                            <Link
                                                                to={ generatePath(ADMIN_PATHS.LICENSES.USERS, { "licenseId": model.id }) }
                                                                className="btn btn-link py-0 px-1 me-2" title="Users"><i
                                                                    className="bi-person-fill"></i> Users
                                                            </Link>
                                                        }
                                                        { !permissionStore.isSlaCod() &&
                                                            <Link
                                                                to={ generatePath(ADMIN_PATHS.LICENSES.PRINCIPALS, { "licenseId": model.id }) }
                                                                className="btn btn-link py-0 px-1 me-2" title="Principals"><i
                                                                    className="bi-file-earmark-ppt-fill"></i> Principals
                                                            </Link>
                                                        }
                                                        { (isWholesalerLicense && !permissionStore.isSlaCod()) &&
                                                            <Link
                                                                to={ generatePath(ADMIN_PATHS.LICENSES.UPCOMING_SCHEDULES.VIEW, { "licenseId": model.id }) }
                                                                className="btn btn-link py-0 px-1 me-2"
                                                                title="Recent & Upcoming Schedules">
                                                                <i className="bi-folder-fill"></i> Recent & Upcoming Schedules
                                                            </Link>
                                                        }
                                                        { !permissionStore.isSlaCod() &&
                                                            <Link
                                                                to={ generatePath(ADMIN_PATHS.LICENSES.SETTINGS, { "licenseId": model.id }) }
                                                                className="btn btn-link py-0 px-1 me-2" title="Settings">
                                                                <i className="bi-gear-fill"></i> Settings
                                                            </Link>
                                                        }
                                                        { (permissionStore.isSlaCod() || permissionStore.isSlaAdminOrAdmin()) &&
                                                            <Link
                                                                to={ generatePath(
                                                                    isWholesalerLicense
                                                                        ? ADMIN_PATHS.LICENSES.COD_DATA_WHOLESALER
                                                                        : ADMIN_PATHS.LICENSES.COD_DATA_RETAILER, { "licenseId": model.id.toString() }) }
                                                                className="btn btn-link py-0 px-1 me-2" title="COD Data">
                                                                <i className="bi-eye-fill"></i> COD Data
                                                            </Link>
                                                        }
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <Paginator totalPages={ pageCount } currentPage={ pageNumber } pageClicked={ handlePageClick }/>
                    </Fragment>
                </div>
            }
        </div>
    </div>;
};
