import { ControlledFormSwitch } from "components/form/ControlledFormSwitch";
import { SelectControlled } from "components/form/Select";
import ProductConstants from "services/ProductConstants";
import TextArea from "components/form/TextArea";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";

interface Props {
    rootName: string,
    onCheck: () => void;
    onUncheck: () => void;
}
export const LimitedAvailabilityInput = ({ rootName, onCheck, onUncheck }:Props)=>{
    const { control, watch, setValue,  formState: { errors } } = useFormContext();
    const lim_avail = watch(`${rootName}.lim_avail`);
    const alloc_met = watch(`${rootName}.alloc_met`);

    useEffect(()=> {
        lim_avail === ProductConstants.enumLimAvail.LIMITED_AVAILABILITY && onCheck();
        lim_avail === ProductConstants.enumLimAvail.BLANK && onUncheck();
        if (lim_avail === ProductConstants.enumLimAvail.LIMITED_AVAILABILITY && alloc_met === ""){
            setValue(`${rootName}.alloc_met`, "01");
        }
        else if (lim_avail === ProductConstants.enumLimAvail.BLANK){
            setValue(`${rootName}.alloc_met`,  "");
            setValue(`${rootName}.alloc_desc`,  "");
        }
    }, [lim_avail]);

    return <>
        <div className="col-12 col-xl-6">
            <div className="pe-xl-4">
                <ControlledFormSwitch control={ control } label={ "Limited Availability" } type={ "checkbox" }
                    className={ "mb-3 mt-3" } name={ `${rootName}.lim_avail` } errors={ errors }
                    options={ [ProductConstants.enumLimAvail.LIMITED_AVAILABILITY, ProductConstants.enumLimAvail.BLANK] }/>
                <SelectControlled name={ `${rootName}.alloc_met` } options={ ProductConstants.ALLOCATION_METHODS } control={ control }
                    disabled={ lim_avail !== ProductConstants.enumLimAvail.LIMITED_AVAILABILITY } errors={ errors } valueAsInteger={ true }/>
            </div>
        </div>
        <div className="col-12 col-xl-6">
            <div className="ps-xl-4">
                <div className="form-group">
                    <TextArea name={ `${rootName}.alloc_desc` } control={ control } errors={ errors }
                        rows={ 4 } maxLength={ 128 }
                        placeholder="Enter allocation description" label="Allocation Description"
                        disabled={ lim_avail !== ProductConstants.enumLimAvail.LIMITED_AVAILABILITY } />
                </div>
            </div>
        </div>
    </>;
};
