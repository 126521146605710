import { modalStore } from "store/ModalStoreProvider";
import React, { useEffect, useState } from "react";
import { useToastStore } from "store/ToastStoreProvider";
import WholesalerDelinquentService from "services/wholesaler/WholesalerDelinquentService";
import { ShortLicenseInfo } from "models/license.model";

export const showDeleteCODConfirmation = (lic: ShortLicenseInfo, onSuccess?: ()=>void ) => {
    modalStore.setBody(<DeleteCODDialog license={ lic } onSuccess={ onSuccess }/>);
    modalStore.setHeader("Delete COD Data");
    modalStore.showModal();
};

const DeleteCODDialog = ({ license, onSuccess }: { license: ShortLicenseInfo, onSuccess?: ()=>void }) => {

    const { addToast, addDefaultDangerToast } = useToastStore();
    const [count, setCount] = useState<number | undefined>(); // amount of records to be deleted

    useEffect(()=>{
        WholesalerDelinquentService.deleteForLicense({ licenseId: license.id, dryRun: true })
            .then((resp) => setCount(resp.count));
    },[]);

    const deleteCODOfLicense = ()=>{
        WholesalerDelinquentService.deleteForLicense({ licenseId: license.id, dryRun: false })
            .then((resp) => {
                addToast({
                    header: "Delete COD Data",
                    message: <>
                        Delete COD from license <strong>{ license.permit_id ?? license.serial_number }</strong> complete
                        at: <strong>{ resp.time }</strong><br/>
                        Records deleted: <strong>{ resp.count }</strong>
                    </>,
                    variant: "success"
                });
                onSuccess && onSuccess();
            })
            .catch(() => addDefaultDangerToast("Delete COD Data"))
            .finally(()=>modalStore.hideModal());
    };

    modalStore.setAdditionalButtons([
        <button key={ 0 } className="btn btn-primary" type="button" onClick={ deleteCODOfLicense }>Yes</button>
    ]);

    return <>
        You are going to delete <span className="text-danger fw-bold">{ count }</span> records from the &nbsp;
        <strong>{ license?.permit_id ?? license?.serial_number }, { license?.premise_name }</strong>.<br/>
        This can not be undone. Are you sure?
    </>;
};
