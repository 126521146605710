import { Modal } from "react-bootstrap";
import Spinner from "components/Spinner";
import React from "react";

interface ProgressDialogProps { show: boolean }

export const ProgressDialog = ({ show }: ProgressDialogProps)=>{
    return <Modal show={ show } backdrop="static" centered={ true } keyboard={ false }>
        <Modal.Header>
            <Modal.Title>Operation in progress...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Spinner className="text-center" />
        </Modal.Body>
    </Modal>;
};
