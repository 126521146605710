import { CreatableEditableUser, UserModel } from "models/UserModel";
import httpClient from "api/HttpClient";
import BACKEND from "services/Constants";
import { PageableRequest, pageableRequestToSearchParams } from "models/requests/PageableRequest";
import { PageableResponse } from "models/response/PageableResponse";

export default {

    lookup(request: PageableRequest): Promise<PageableResponse<UserModel>> {
        return httpClient.get(`${BACKEND}/users/lookup`,{ params: pageableRequestToSearchParams(request) });
    },

    searchByExactEmail(email: string): Promise<UserModel> {
        return httpClient.get(`${BACKEND}/users/searchByExactEmail`,{ params: { email : email } });
    },

    createOrUpdateUser(request: CreatableEditableUser): Promise<void> {
        return httpClient.post(BACKEND + "/users/create-or-update", request);
    },

    deleteUser(userId: number): Promise<void> {
        return httpClient.post( `${BACKEND}/users/delete/${userId}`);
    },

    removeFromLicense(userId: number, licenseId: number): Promise<void> {
        return httpClient.post( `${BACKEND}/users/removeFromLicense/${userId}`, null, { params:{ license_id: licenseId } });
    },

    resendInvitation(userId: number): Promise<void> {
        return httpClient.post(BACKEND + "/users/resendInvitation?id=" + userId);
    }

};
