import { PageInfo } from "models/PageInfo";
import { Sorting } from "models/Sorting";
import URLUtil from "utils/URLUtil";

export interface Filer {
    name: string;
    value: string | number | boolean;
}

export interface PageableRequest {
    pageInfo: PageInfo;
    filters?: any;
    sortings?: Sorting[];
}

export function buildOrderByUrlParams(sortings?: Sorting[]) {
    if (!sortings || sortings.length === 0) {
        return undefined;
    }
    
    const order = sortings.map(sort =>
        sort.field + (sort.order?.toLowerCase() === "desc" ? " desc" : "")
    );
    
    return { order: order.join(",") };
}

export function pageableRequestToSearchParams(request: PageableRequest) {
    return new URLSearchParams({
        ...request.pageInfo,
        ...URLUtil.removeEmptyValues(request.filters),
        ...buildOrderByUrlParams(request.sortings)
    });
}

export function pageableRequestToQueryString(request: PageableRequest): string {
    return pageableRequestToSearchParams(request).toString();
}
