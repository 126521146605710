import React from "react";

export interface Props {
    step: number;
}

const StepsWidget = (props: Props) => {

    const { step } = props;

    return <div className="steps-wr mb-4">
        <div className="steps-l row g-0">
            <div className={ "steps-li col-12 col-xl-3 " + (step === 1 ? "active" : "") }>
                <div className="steps-li-in">
                    <strong>1</strong> Select schedule and enter the letter name
                </div>
            </div>
            <div className={ "steps-li col-12 col-xl-3 " + (step === 2 ? "active" : "") }>
                <div className="steps-li-in">
                    <strong>2</strong> Link at least one item to the letter
                </div>
            </div>
            <div className={ "steps-li col-12 col-xl-3 " + (step === 3 ? "active" : "") }>
                <div className="steps-li-in">
                    <strong>3</strong> Select PDF-file to upload
                </div>
            </div>
            <div className={ "steps-li col-12 col-xl-3 " + (step === 4 ? "active" : "") }>
                <div className="steps-li-in">
                    <strong>4</strong> Post the letter with linked items to NYSLA
                </div>
            </div>
        </div>
    </div>;
};

export default StepsWidget;

