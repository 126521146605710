import { PostTypeModel } from "models/PostTypeModel";
import { ProductSizeModel } from "models/ProductSizeModel";
import httpClient from "api/HttpClient";
import { PageableResponse } from "models/response/PageableResponse";
import { PageableRequest, pageableRequestToSearchParams } from "models/requests/PageableRequest";
import { ProductModel } from "models/ProductModel";
import ProductConstants from "services/ProductConstants";
import { ProductSearchParamsModel } from "models/ProductSearchParamsModel";
import BACKEND from "services/Constants";
import RecaptchaService from "services/RecaptchaService";

export interface ProductSearchRequest extends PageableRequest {
    filters: ProductSearchParamsModel;
}

export default {

    getPostTypesSync(): PostTypeModel[]  {
        return Object.values(ProductConstants.POST_TYPES);
    },

    getPostTypes(): Promise<PostTypeModel[]> {
        return Promise.resolve( this.getPostTypesSync());
    },

    getWholesalerPostTypes(): Promise<PostTypeModel[]> {
        return Promise.resolve(ProductConstants.WHOLESALER_POST_TYPES);
    },

    getProductSizes(): Promise<ProductSizeModel[]> {
        return Promise.resolve(ProductConstants.POPULAR_PRODUCT_SIZES);
    },

    getScheduleTypeDescription(postType: string): string | undefined {
        return ProductConstants.WHOLESALER_POST_TYPES.find(t => t.code === postType)?.description;
    },

    searchByRetailer(request: ProductSearchRequest, getCaptchaValue: ()=>Promise<string> ): Promise<PageableResponse<ProductModel>> {
        return RecaptchaService.withRecaptcha({
            method: "GET",
            url: BACKEND + "/product/retailer-lookup",
            params: pageableRequestToSearchParams(request)
        }, getCaptchaValue);
    },

    searchByPublicUser(request: PageableRequest, getCaptchaValue: ()=>Promise<string> ): Promise<PageableResponse<ProductModel>> {
        return RecaptchaService.withRecaptcha({
            method: "GET",
            url: BACKEND + "/product/lookup",
            params: pageableRequestToSearchParams(request)
        }, getCaptchaValue);
    },

    searchByLetters(request: ProductSearchRequest): Promise<PageableResponse<ProductModel>> {
        return httpClient.search(BACKEND + "/product/create-letter-lookup", request);
    }
};
