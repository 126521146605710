import { ProductModel } from "models/ProductModel";
import React from "react";
import ErroneousValue from "components/ErroneousValue";
import classNames from "classnames";
import { TabModel } from "components/product-tab-widget/ProductTabContainer";
import StringUtils from "utils/StringUtils";
import ProductConstants from "services/ProductConstants";

const DISPLAYED_PROPERTIES = ["proof", "alcohol", "vintage"];
const BrandsTabHeader = (product: ProductModel) => {
    const hasErrors = (p: ProductModel) =>
        StringUtils.hasAnyProperty(p.product_errors, DISPLAYED_PROPERTIES);

    return <span className = { classNames(hasErrors(product) ? "text-danger" : "" ) }>
        { hasErrors(product) && <i className="bi bi-exclamation-circle-fill me-2"></i> }
        Brand Details
    </span>;
};

const BrandsTabBody = (product: ProductModel) => {
    return <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
        <h5 className="mb-3 fw-bold">Brand details</h5>
        <div className="">
            { ProductConstants.isLiquor(product) &&
                <p className="mb-2">
                    <strong>Proof:&nbsp;</strong><ErroneousValue item={ product } name={ "proof" } />
                </p>
            }
            { ProductConstants.isWine(product) &&
                <p className="mb-2">
                    <strong>Alcohol content:&nbsp;</strong><ErroneousValue item={ product } formattedValue={ ProductConstants.alcoholContentString(product.alcohol) } name={ "alcohol" } />
                </p>
            }
            <p className="mb-2">
                <strong>Age of year:&nbsp;</strong><ErroneousValue item={ product } name={ "vintage" } />
            </p>
        </div>
    </div>;
};

export const BrandTab: TabModel = { header: BrandsTabHeader, body: BrandsTabBody };
