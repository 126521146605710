import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import organizationService from "services/OrganizationService";
import Input from "components/form/Input";
import FormUtil from "utils/FormUtil";

export const LicenseAndPrincipalInput = ()=>{
    const [organizationName, setOrganizationName] = useState<string | undefined>("");
    const [organizationType, setOrganizationType] = useState<string | undefined>("");
    const { control, setValue, formState:{ errors } } = useFormContext();

    const validatePermitIdOrSerialNumber = async (value: string)=>{
        if (!value){
            return false;
        }
        setOrganizationName("");
        setOrganizationType("");
        return await organizationService.findByPermitIdOrSerialNumber(value)
            .then(dm=>{
                setValue("licenseId", dm.id);
                setOrganizationName(dm.premise_name);
                setOrganizationType(`${dm.type?.type} - ${dm.type?.category_description}`);
                return true;
            })
            .catch(()=>"License not found.");
    };

    return <div className="pe-md-4">
        <h4 className="mb-3">License Info</h4>

        <Input control={ control } errors={ errors } name="permitIdOrSerialNumber"
            rules={ {
                ...FormUtil.PERMIT_ID_OR_SERIAL_NUMBER,
                validate: validatePermitIdOrSerialNumber } }
            label="License ID *"
            placeholder="Enter license id or serial number"
            type="text"
        />

        <div className="form-group">
            <label className="form-label">Company / Organization Name</label>
            <input disabled className="form-control" value={ organizationName }/>
        </div>

        <div className="form-group">
            <label className="form-label">Type</label>
            <input disabled className="form-control" value={ organizationType }/>
        </div>

        <Input control={ control } errors={ errors } name="principalFirstName"
            rules={ FormUtil.NAME_VALIDATIONS }
            label="Principal First Name and Middle Initial *"
            placeholder="Enter principal first name"
            type="text"
            required
        />

        <Input control={ control } errors={ errors } name="principalLastName"
            rules={ FormUtil.NAME_VALIDATIONS }
            label="Principal Last Name *"
            placeholder="Enter principal last name"
            type="text"
            required
        />

        <Input control={ control } errors={ errors } name="principalBirth"
            rules={ FormUtil.YEAR_VALIDATIONS }
            label="Principal Year of Birth (YYYY) *"
            placeholder="Enter principal year of birth"
            type="number"
            required
        />

        <Input control={ control } errors={ errors } name="principalZipcode"
            rules={ FormUtil.ZIP_COD_VALIDATIONS }
            label="Principal Home Zipcode *"
            placeholder="Enter principal zipcode"
            type="text"
            required
        />
    </div>;

};
