import Input from "components/form/Input";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import CheckBox from "components/form/CheckBox";
import { useKeyDownEnterEvent } from "components/KeyDownHook";

export interface LicenseParamModel {
    license?: string; // serial number or license ID
    premise_or_trade_name?: string;
    special?: boolean;
    products?: boolean;
}

type Props = LicenseParamModel & {
    onSubmitForm: (data: LicenseParamModel) => void,
    isCentral?: boolean
};

const LicenseSearchForm = (props: Props) => {
    const {
        handleSubmit,
        setValue,
        control
    } = useForm<LicenseParamModel>({
        mode: "onSubmit",
        defaultValues: {
            license: props.license,
            premise_or_trade_name: props.premise_or_trade_name,
            special: props.special,
            products: props.products
        }
    });
    useKeyDownEnterEvent(() => { handleSubmit(onSubmit)(); });

    const resetForm = ()=>{
        setValue("premise_or_trade_name", "");
        setValue("license", "");
        setValue("special", false);
        setValue("products", false);
    };

    const onSubmit: SubmitHandler<LicenseParamModel> = data => {
        props.onSubmitForm(data);
    };

    return <div className="card">
        <div className="card-body">
            <form onSubmit={ handleSubmit(onSubmit) }>
                <div className="row">
                    <div className="col-12 col-xl-4">
                        <div className="">
                            <Input
                                name="license" control={ control }
                                placeholder="Enter license id or serial number"
                                label="License ID or Serial number" type="text"/>
                        </div>
                    </div>
                    <div className="col-12 col-xl-4">
                        <div className="pt-lg-0">
                            <Input
                                control={ control }
                                name="premise_or_trade_name" placeholder="Enter premise or trade name"
                                label="Premise or Trade Name" type="text"/>
                        </div>
                    </div>
                    { !props.isCentral &&
                        <div className="col-12 col-xl-4 d-flex flex-column align-items-center justify-content-center">
                            <div className="switch-year mt-3 m-xl-0 min-wp-225">
                                <label className="form-check-label me-2" htmlFor="custom-switch">All</label>
                                <CheckBox control={ control } name="special" label="With Special Access"/>
                            </div>
                            <div className="switch-year mt-3 m-xl-2 mb-4 mb-xl-0 min-wp-225">
                                <label className="form-check-label me-2" htmlFor="custom-switch">All</label>
                                <CheckBox control={ control } name="products" label="Has Certified Postings"/>
                            </div>
                        </div>
                    }
                </div>
                <div className="row justify-content-center mt-2">
                    <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                        <button
                            type="button"
                            className="btn btn-outline-secondary w-100"
                            onClick={ () => resetForm() }
                        >Clear Form
                        </button>
                    </div>
                    <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                        <input className="btn btn-primary w-100" type="submit" value="Search" />
                    </div>
                </div>
            </form>
        </div>
    </div>;
};

export default LicenseSearchForm;
