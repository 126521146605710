import React from "react";
import classNames from "classnames";

const WizardStepsBar = ({ steps, activity }:{ steps: string[], activity: boolean[] }) =>
    <div className="steps-wr mb-4">
        <div className="steps-l row g-0">
            { steps.map((step,idx)=>
                <div className={ classNames("steps-li","col-12","col-xl-3", { "active":activity[idx] }) } key={ idx }>
                    <div className="steps-li-in">
                        <strong>{ idx+1 }</strong> { step }
                    </div>
                </div>
            ) }
        </div>
    </div>;

export default WizardStepsBar;
