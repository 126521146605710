import React from "react";
import Input from "components/form/Input";
import Form from "react-bootstrap/Form";

const WholesalerProductDetailsView2 = () => {

    return <div className="d-flex flex-column">
        <div className="mb-4 mb-md-5">
            <h1 className="text-primary mb-0">Liquor to Retailer - Schedule for November 2022</h1>
        </div>
        <div>
            <div className="mb-3 mb-md-4 d-flex flex-row flex-wrap align-items-center">
                <h4 className="mb-2 me-auto">Amend prices for brand_name / product_name</h4>
                <a className="btn btn-outline-primary mb-2" href="#CompetitorsItemInfo">Competitor&apos;s item information</a>
            </div>
            <div className="card mt-3">
                <div className="card-body pb-2">
                    <h4 className="mb-4">Brand information</h4>
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <div className="pe-xl-4">
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="brand_name"> Brand name * </label>
                                    <input placeholder="Enter brand name" type="text" className="form-control" disabled aria-describedby="brand_nameFeedback" id="brand_name" value="Regular " />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="ps-xl-4">
                                <div className="row">
                                    <div className="col-12 col-lg-6 col-xl-5">
                                        <div className="mb-3">
                                            <label htmlFor="reg_combo" className="form-label"> Package type </label>
                                            <select id="reg_combo" name="reg_combo" disabled className="form-select">
                                                <option value="R">Regular</option>
                                                <option value="V">VAP</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 col-xl-7">
                                        <div className="mb-3">
                                            <label htmlFor="bev_type" className="form-label"> Type of beverage * </label>
                                            <select id="bev_type" name="bev_type" disabled className="form-select">
                                                <option value="B">Brandy</option>
                                                <option value="CS">Cocktails &amp; Specialties</option>
                                                <option value="CL">Cordials &amp; Liqueurs</option>
                                                <option value="G">Gin</option>
                                                <option value="I">Imitations</option>
                                                <option value="NS">Neutral Spirits</option>
                                                <option value="R">Rum</option>
                                                <option value="Q">Tequila</option>
                                                <option value="WH">Whiskey</option>
                                                <option value="O">Other</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <div className="pe-xl-4">
                                <div className="row">
                                    <div className="col-12 col-lg-6 col-xl-6">
                                        <div className="form-label-with-tooltip">
                                            <label className="form-label" htmlFor="brand_reg">Approval/BLR number*</label>
                                            <div className="tooltip-wr tooltip-wr-small ms-2">
                                                <span className="tooltip-icon bi-question-circle-fill"></span>
                                                <div className="tooltip-msg text-center">
                                                    <p>Enter either the approval # in this format: NY-XXXXXXXX-XXXX, or the regular 7-digit NYS BLR (Brand Label Registration) Number, or P (for Pending)</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <input id="brand_reg" placeholder="Enter approval / BLR number" disabled type="text" className="form-control" aria-describedby="brand_regFeedback" value="P" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 col-xl-6">
                                        <div className="form-label-with-tooltip">
                                            <label className="form-label" htmlFor="ttb_id">TTB / CoLA ID number</label>
                                            <div className="tooltip-wr tooltip-wr-small ms-2">
                                                <span className="tooltip-icon bi-question-circle-fill"></span>
                                                <div className="tooltip-msg text-center">
                                                    <p>P for Pending may only be used for the first two posting periods while the TTB or BLR number is pending approval</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <input id="ttb_id" placeholder="Enter TTB / CoLA ID number" disabled type="text" className="form-control" aria-describedby="ttb_idFeedback" value="P" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="ps-xl-4">
                                <div className="row">
                                    <div className="col-12 col-lg-6 col-xl-5">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="proof"> Proof * </label>
                                            <input id="proof" placeholder="Enter proof" type="number" disabled className="form-control" aria-describedby="proofFeedback" value="66" />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 col-xl-7">
                                        <div className="form-label-with-tooltip">
                                            <label className="form-label" htmlFor="custom-switch">Age *</label>
                                            <div className="tooltip-wr tooltip-wr-small ms-2">
                                                <span className="tooltip-icon bi-question-circle-fill"></span>
                                                <div className="tooltip-msg text-center">
                                                    <p>Some text here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="switch-year">
                                            <label className="form-check-label disabled me-2" htmlFor="vintage-ac-id">Not applicable</label>
                                            <div className="form-check form-switch">
                                                <input type="checkbox" id="vintage-ac-id" disabled className="form-check-input" />
                                                <label title="" htmlFor="vintage-ac-id" className="form-check-label">Year</label>
                                            </div>
                                            <div className="wp-80 input-year flex-lg-fill ms-2">
                                                <input className="form-control " type="number" disabled value="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mt-4">
                <div className="card-body pb-2">
                    <h4 className="mb-4">Item(s) and discounts information</h4>
                    <div className="">
                        <div className="nav nav-pills btn-group flex-column flex-lg-row d-lg-inline-flex mb-4" id="nav-tab" role="tablist" aria-orientation="vertical">
                            <button className="btn btn-outline-secondary min-wp-230 active" id="nav-1-tab" data-bs-toggle="tab" data-bs-target="#nav-1" type="button" role="tab" aria-controls="nav-1" aria-selected="false">Item info</button>
                            <button className="btn btn-outline-secondary min-wp-230" id="nav-2-tab" data-bs-toggle="tab" data-bs-target="#nav-2" type="button" role="tab" aria-controls="nav-2" aria-selected="false">Discounts</button>
                        </div>

                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-1" role="tabpanel" aria-labelledby="nav-1-tab">
                                <div className="pb-3 mx--row">
                                    <div className="row">
                                        <div className="col-12 col-xl-6">
                                            <div className="pe-xl-4">
                                                <Input name="description" placeholder="Description" label="Description *" type="text" disabled />
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-6">
                                            <div className="ps-xl-4">
                                                <div className="row">
                                                    <div className="col-6 col-md-6">
                                                        <div className="mb-3">
                                                            <label className="form-label">Your item number *</label>
                                                            <input placeholder="Enter your item number" disabled type="text" className="form-control"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-3 col-md-6 d-flex flex-row">
                                                        <div className="flex-grow">
                                                            <div className="mb-3">
                                                                <label className="form-label">Size / Unit *</label>
                                                                <input placeholder="Enter size/unit" disabled type="number" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="wp-130 ms-3">
                                                            <div className="mb-3">
                                                                <label htmlFor="items[0].um" className="form-label"> &nbsp; </label>
                                                                <select className="form-select" disabled>
                                                                    <option value="ML">ML</option>
                                                                    <option value="L">L</option>
                                                                    <option value="EA">EA</option>
                                                                    <option value="KEG">KEG</option>
                                                                    <option value="OZ">OZ</option>
                                                                    <option value="G">G</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="pe-xl-4">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <label htmlFor="number" className="form-label">Number of bottles per *</label>
                                                    </div>
                                                    <div className="col-12 col-lg-6 form-group-without-label">
                                                        <Input name="number" placeholder="Case *" label="" required type="text" disabled/>
                                                    </div>
                                                    <div className="col-12 col-lg-6 form-group-without-label">
                                                        <Input name="number" placeholder="SubPack / Sleeve *" label="" required type="text" disabled/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="ps-xl-4">
                                                <div className="mb-3">
                                                    <p className="mb-2">
                                                        <strong>Case only</strong>
                                                    </p>
                                                    <div className="switch-year pt-1">
                                                        <label className="form-check-label disabled me-2" htmlFor="custom-switch">No</label>
                                                        <Form.Switch
                                                            type="switch"
                                                            id="custom-switch"
                                                            label="Yes"
                                                            checked
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-xl-6">
                                            <div className="pe-xl-4">
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <label className="form-label" htmlFor="Price3">Price per bottle *</label>
                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text" id="Price3">$</span>
                                                            <input type="number" className="form-control" placeholder="Enter price per bottle" disabled/>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label className="form-label" htmlFor="Price4">NYC Price per bottle</label>
                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text" id="Price4">$</span>
                                                            <input type="number" className="form-control" placeholder="Enter NYC Price per bottle" disabled/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-6">
                                            <div className="ps-xl-4">
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <label className="form-label" htmlFor="Price3">Price per case *</label>
                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text" id="Price3">$</span>
                                                            <input type="number" className="form-control" placeholder="Price per case" disabled/>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label className="form-label" htmlFor="Price4">NYC Price per case</label>
                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text" id="Price4">$</span>
                                                            <input type="number" className="form-control" placeholder="NYC Price per case" disabled/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-6">
                                            <div className="pe-xl-4">
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <div className="mb-3">
                                                            <label className="form-label" htmlFor="items[0].split_charge"> BT / SLV Split Case Chg </label>
                                                            <div className="input-group has-validation">
                                                                <span className="input-group-text">$</span>
                                                                <input className="form-control" disabled placeholder="BT / SLV Split Case Chg" type="number" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="mb-3">
                                                            <p className="mb-2">
                                                                <strong>NYS label</strong>
                                                            </p>
                                                            <div className="switch-year pt-1">
                                                                <label className="form-check-label disabled me-2" htmlFor="nysprod0">No</label>
                                                                <div className="form-check form-switch">
                                                                    <input type="checkbox" id="nysprod0" disabled className="form-check-input" />
                                                                    <label htmlFor="nysprod0" className="form-check-label">Yes</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-6">
                                            <div className="ps-xl-4">
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <Input name="serial-number" placeholder="Enter NYS supplier serial number" label="NYS supplier serial number*" required type="number" disabled/>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <Input name="item-number" placeholder="Enter NYS supplier item number" label="NYS supplier item number*" required type="number" disabled/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-xl-6">
                                            <div className="pe-xl-4">
                                                <div className="form-check mb-3 mt-3">
                                                    <input id="CheckDefault" type="checkbox" className="form-check-input" value="" checked disabled />
                                                    <label htmlFor="CheckDefault" className="form-check-label">Limited Availability</label>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="Method" className="form-label">Allocation Method</label>
                                                    <select id="Method" className="form-select" aria-label="Select" disabled>
                                                        <option selected>N/A</option>
                                                        <option value="1">Method 1</option>
                                                        <option value="2">Method 2</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-6">
                                            <div className="ps-xl-4">
                                                <div className="form-group">
                                                    <label htmlFor="Textarea" className="form-label">Allocation Description</label>
                                                    <textarea rows={ 4 } id="Textarea" className="form-control" placeholder="Enter allocation Description" disabled></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-2" role="tabpanel" aria-labelledby="nav-2-tab">
                                <div className="switch-header mb-4 fw-bold">
                                    <label className="form-check-label h5" htmlFor="custom-switch">Select discount</label>
                                    <Form.Switch type="switch" id="custom-switch1" label="Create new discount" />
                                </div>
                                <div className="mb-4 pb-2">
                                    <div className="d-block">
                                        <form action="">
                                            <div className="row">
                                                <div className="col-12 col-xl-6">
                                                    <div className="pe-xl-4">
                                                        <div className="form-group mb-3 mb-xl-0">
                                                            <label htmlFor="code" className="form-label">Discount code</label>
                                                            <select id="code" className="form-select" aria-label="Select" disabled>
                                                                <option selected>Discount code</option>
                                                                <option value="1">Discount code 1</option>
                                                                <option value="2">Discount code 2</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-xl-6">
                                                    <div className="ps-xl-4">
                                                        <div className="mb-3 mb-xl-0">
                                                            <p className="mb-2">
                                                                <strong>Discounts by</strong>
                                                            </p>
                                                            <div className="switch-year pt-1">
                                                                <label className="form-check-label disabled me-2" htmlFor="custom-switch">Case</label>
                                                                <Form.Switch
                                                                    type="switch"
                                                                    id="custom-switch"
                                                                    label="Bottle"
                                                                    checked
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <div className="row">
                                            <div className="col-12 col-lg-4 mt-4">
                                                <div className="card bg-white">
                                                    <div className="card-body pb-2 pt-3">
                                                        <div className="styleguide-head mb-4">
                                                            <div className="d-flex flex-row align-items-center mb-2">
                                                                <h5 className="me-auto mb-0">Discount 1</h5>
                                                            </div>
                                                            <hr className="mt-1" />
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="Type" className="form-label">Type</label>
                                                                    <select id="Type" className="form-select" aria-label="Select" disabled>
                                                                        <option selected>$</option>
                                                                        <option value="1">$</option>
                                                                        <option value="2">Euro</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <Input name="amount" placeholder="00.00" label="Amount" type="number" disabled/>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <Input name="on" placeholder="0" label="On"  type="number" disabled/>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="Units" className="form-label">Units</label>
                                                                    <select id="Units" className="form-select" aria-label="Select" disabled>
                                                                        <option selected>Case</option>
                                                                        <option value="1">Case 1</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-4 mt-4">
                                                <div className="card bg-white">
                                                    <div className="card-body pb-2 pt-3">
                                                        <div className="styleguide-head mb-4">
                                                            <div className="d-flex flex-row align-items-center mb-2">
                                                                <h5 className="me-auto mb-0">Discount 2</h5>
                                                            </div>
                                                            <hr className="mt-1" />
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="Type" className="form-label">Type</label>
                                                                    <select id="Type" className="form-select" aria-label="Select" disabled>
                                                                        <option selected>$</option>
                                                                        <option value="1">$</option>
                                                                        <option value="2">Euro</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <Input name="amount" placeholder="00.00" label="Amount" type="number" disabled/>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <Input name="on" placeholder="0" label="On"  type="number" disabled/>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="Units" className="form-label">Units</label>
                                                                    <select id="Units" className="form-select" aria-label="Select" disabled>
                                                                        <option selected>Bottle</option>
                                                                        <option value="1">Case</option>
                                                                        <option value="1">Bottle</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-4 mt-4">
                                                <div className="card bg-white">
                                                    <div className="card-body pb-2 pt-3">
                                                        <div className="styleguide-head mb-4">
                                                            <div className="d-flex flex-row align-items-center mb-2">
                                                                <h5 className="me-auto mb-0">Discount 3</h5>
                                                            </div>
                                                            <hr className="mt-1" />
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="Type" className="form-label">Type</label>
                                                                    <select id="Type" className="form-select" aria-label="Select" disabled>
                                                                        <option selected>$</option>
                                                                        <option value="1">$</option>
                                                                        <option value="2">Euro</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <Input name="amount" placeholder="00.00" label="Amount" type="number" disabled/>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <Input name="on" placeholder="0" label="On"  type="number" disabled/>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="Units" className="form-label">Units</label>
                                                                    <select id="Units" className="form-select" aria-label="Select" disabled>
                                                                        <option selected>Case</option>
                                                                        <option value="1">Case 1</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-4 mt-4">
                                                <div className="card bg-white">
                                                    <div className="card-body pb-2 pt-3">
                                                        <div className="styleguide-head mb-4">
                                                            <div className="d-flex flex-row align-items-center mb-2">
                                                                <h5 className="me-auto mb-0">Discount 4</h5>
                                                            </div>
                                                            <hr className="mt-1" />
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="Type" className="form-label">Type</label>
                                                                    <select id="Type" className="form-select" aria-label="Select" disabled>
                                                                        <option selected>$</option>
                                                                        <option value="1">$</option>
                                                                        <option value="2">Euro</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <Input name="amount" placeholder="00.00" label="Amount" type="number" disabled/>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <Input name="on" placeholder="0" label="On"  type="number" disabled/>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="Units" className="form-label">Units</label>
                                                                    <select id="Units" className="form-select" aria-label="Select" disabled>
                                                                        <option selected>Case</option>
                                                                        <option value="1">Case 1</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-none">
                                        <button className="btn btn-primary">Add new discount</button>
                                        <div className="row">
                                            <div className="col-12 col-lg-4 mt-4">
                                                <div className="card bg-white">
                                                    <div className="card-body pb-2 pt-3">
                                                        <div className="styleguide-head mb-4">
                                                            <div className="d-flex flex-row align-items-center mb-2">
                                                                <h5 className="me-auto mb-0">Discount 1</h5>
                                                            </div>
                                                            <hr className="mt-1" />
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="Type" className="form-label">Type</label>
                                                                    <select id="Type" className="form-select" aria-label="Select" disabled>
                                                                        <option selected>$</option>
                                                                        <option value="1">$</option>
                                                                        <option value="2">Euro</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <Input name="amount" placeholder="00.00" label="Amount" type="number" disabled/>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <Input name="on" placeholder="0" label="On"  type="number" disabled/>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="Units" className="form-label">Units</label>
                                                                    <select id="Units" className="form-select" aria-label="Select" disabled>
                                                                        <option selected>Case</option>
                                                                        <option value="1">Case 1</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-4 mt-4">
                                                <div className="card bg-white">
                                                    <div className="card-body pb-2 pt-3">
                                                        <div className="styleguide-head mb-4">
                                                            <div className="d-flex flex-row align-items-center mb-2">
                                                                <h5 className="me-auto mb-0">Discount 2</h5>
                                                            </div>
                                                            <hr className="mt-1" />
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="Type" className="form-label">Type</label>
                                                                    <select id="Type" className="form-select" aria-label="Select" disabled>
                                                                        <option selected>$</option>
                                                                        <option value="1">$</option>
                                                                        <option value="2">Euro</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <Input name="amount" placeholder="00.00" label="Amount" type="number" disabled/>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <Input name="on" placeholder="0" label="On"  type="number" disabled/>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="Units" className="form-label">Units</label>
                                                                    <select id="Units" className="form-select" aria-label="Select" disabled>
                                                                        <option selected>Bottle</option>
                                                                        <option value="1">Case</option>
                                                                        <option value="1">Bottle</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-4 mt-4">
                                                <div className="card bg-white">
                                                    <div className="card-body pb-2 pt-3">
                                                        <div className="styleguide-head mb-4">
                                                            <div className="d-flex flex-row align-items-center mb-2">
                                                                <h5 className="me-auto mb-0">Discount 3</h5>
                                                            </div>
                                                            <hr className="mt-1" />
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="Type" className="form-label">Type</label>
                                                                    <select id="Type" className="form-select" aria-label="Select" disabled>
                                                                        <option selected>$</option>
                                                                        <option value="1">$</option>
                                                                        <option value="2">Euro</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <Input name="amount" placeholder="00.00" label="Amount" type="number" disabled/>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <Input name="on" placeholder="0" label="On"  type="number" disabled/>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="Units" className="form-label">Units</label>
                                                                    <select id="Units" className="form-select" aria-label="Select" disabled>
                                                                        <option selected>Case</option>
                                                                        <option value="1">Case 1</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-4 mt-4">
                                                <div className="card bg-white">
                                                    <div className="card-body pb-2 pt-3">
                                                        <div className="styleguide-head mb-4">
                                                            <div className="d-flex flex-row align-items-center mb-2">
                                                                <h5 className="me-auto mb-0">Discount 4</h5>
                                                            </div>
                                                            <hr className="mt-1" />
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="Type" className="form-label">Type</label>
                                                                    <select id="Type" className="form-select" aria-label="Select" disabled>
                                                                        <option selected>$</option>
                                                                        <option value="1">$</option>
                                                                        <option value="2">Euro</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <Input name="amount" placeholder="00.00" label="Amount" type="number" disabled/>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <Input name="on" placeholder="0" label="On"  type="number" disabled/>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="Units" className="form-label">Units</label>
                                                                    <select id="Units" className="form-select" aria-label="Select" disabled>
                                                                        <option selected>Case</option>
                                                                        <option value="1">Case 1</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mt-4">
                <div className="card-body pb-2">
                    <div className="mb-4">
                        <h4>Enter competitor&apos;s item information</h4>
                        <p className="mb-0">If you wish to amend your prices to match this competitor, then click &quot;Apply&quot;</p>
                    </div>
                    <div className="row align-items-end">
                        <div className="col-12 col-xl-6">
                            <div className="pe-xl-4">
                                <div className="row">
                                    <div className="col-12 col-xl-6">
                                        <Input name="name" placeholder="Enter serial number" label="Serial number" type="number"/>
                                    </div>
                                    <div className="col-12 col-xl-6">
                                        <Input name="name" placeholder="Enter item number" label="Item number" type="number"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="ps-xl-4">
                                <button className="btn btn-primary mb-3">Search</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row justify-content-center mt-2" id="CompetitorsItemInfo">
            <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-4">
                <a href="/" className="btn btn-outline-secondary w-100">Cancel</a>
            </div>
            <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-4">
                <button className="btn btn-primary w-100" disabled>Apply</button>
            </div>
        </div>
    </div>;
};

export default WholesalerProductDetailsView2;

