import React from "react";
import Input from "components/form/Input";

const WholesalerManageBrandsItemsBatchView = () => {

    return <div className="d-flex flex-column">
        <div className="mb-3">
            <h1 className="text-primary mb-4">Batch Submission - Liquor to Retailer Schedule Effective November 2022</h1>
            <p className="mb-0">Select CSV file with product price posting.</p>
        </div>
        <div className="mb-4">
            <a className="btn btn-outline-primary me-3 mb-3" href="/">Read more about Batch Submission</a>
            <a className="btn btn-outline-primary mb-3" href="/">Price posting CSV Template</a>
        </div>
        <div className="steps-wr mb-4">
            <div className="steps-l row g-0">
                <div className="steps-li col-12 col-xl-3 active">
                    <div className="steps-li-in">
                        <strong>1</strong> Select CSV file to upload
                    </div>
                </div>
                <div className="steps-li col-12 col-xl-3">
                    <div className="steps-li-in">
                        <strong>2</strong> Select Discount codes CSV file
                    </div>
                </div>
                <div className="steps-li col-12 col-xl-3">
                    <div className="steps-li-in">
                        <strong>3</strong> Preview batch submission
                    </div>
                </div>
            </div>
        </div>
        <div className="content-styles">
            <p>
                Select the price posting file on your local machine to upload to the Liquor Authority for processing. You are sending the price posting data and the keys for discount codes
                contained in these files to the Liquor Authority for processing. All price posting information that is currently on file for this posting period will be deleted and replaced by the data
                in these batch files.
            </p>
            <ul>
                <li>Prices may include a decimal point.</li>
                <li>For numeric fields, use of dollar signs, commas or other non-numeric entry will cause batch price errors.</li>
                <li>It is your responsibility to review for such errors before uploading.</li>
            </ul>
        </div>
        <div className="card mt-4">
            <div className="card-body">
                <div className="mb-3 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                    <h4><strong>Step 1:</strong> Select Price Posting CSV file</h4>
                    <a href="/">Check the status & history of previous imports</a>
                </div>
                <div className="form-group">
                    <label htmlFor="formFile" className="form-label">Choose file</label>
                    <input className="form-control" type="file" id="formFile" />
                </div>
                <div className="row justify-content-center mt-4">
                    <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                        <a href="/wholesaler-static/manage-brands-items/batch2" className="btn btn-primary w-100">Continue</a>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default WholesalerManageBrandsItemsBatchView;

