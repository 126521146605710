import  { extractPathFromObject } from "components/form/BaseInput";
import React from "react";
import { Controller } from "react-hook-form";
import classNames from "classnames";
import DateUtil from "utils/DateUtil";
import DeadlinesModel from "pages/admin/models/DeadlinesModel";

interface Props {
    name: string,
    deadline: DeadlinesModel,
    control: any,
    errors: any,
    isWholesaler: boolean
}

export const DeadlineInput = ({ name, deadline, control, errors, isWholesaler } : Props) => {

    const error = extractPathFromObject(errors,name);
    const month = deadline.month;

    const startLabel = isWholesaler
        ? "Wholesale for " + DateUtil.monthNumberToName(month + 1)
        : "Retail for " + DateUtil.monthNumberToName(month);

    const daysInMonth = DateUtil.daysInMonth(month, deadline.year || 1);

    const rules = isWholesaler
        ? {
            "required": "This field is required",
            "min": { "value": 16, "message": "Date can not be earlier than the 16th" },
            "max": { "value": daysInMonth,
                "message": `Date can not be later than the ${DateUtil.dayOfMonthOrdinal(daysInMonth)}` }
        }
        : {
            "required": "This field is required",
            "min": { "value": 1, "message": "Date can not be earlier than the 1st" },
            "max": { "value": 12, "message": "Date can not be later than the 12th" }
        };
    return <div className="body-block">
        <h5>{ startLabel }</h5>
        <div className="d-flex flex-row align-items-center">
            <label htmlFor={ name }>until</label>
            <div className="form-group-without-m form-group-sm wp-50 me-2">
                <Controller
                    name={ name }
                    control={ control }
                    rules={ rules }
                    render={ ({ field: { onChange, value, onBlur, ref } }) => (
                        <input
                            value={ value }
                            onChange={ onChange }
                            onBlur={ onBlur }
                            className={ classNames("form-control", { "is-invalid": error !== undefined }) }
                            id={ name }
                            ref={ ref }
                            type="number"
                        />
                    ) }
                />
            </div>
            <label htmlFor={ name }>of { DateUtil.monthNumberToName(month-1) }</label>
        </div>
        { error && <div className="invalid-message">{ error?.message }</div> }

    </div>;
};
