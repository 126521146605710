import Input from "components/form/Input";
import React from "react";
import { useFormContext } from "react-hook-form";

interface Props {
    rootName: string
}
export const NumberOfBottlesControl = ({ rootName }:Props)=>{
    const { control, formState: { errors } } = useFormContext();

    return <>
        <div className="col-12">
            <label htmlFor="number" className="form-label">Number of bottles per *</label>
        </div>
        <div className="col-12 col-lg-6 form-group-without-label">
            <Input name={ `${rootName}.botpercase` } placeholder="per case" label=""
                valueAsInteger={ true } min={ 1 } type="number" control={ control } errors={ errors }/>
        </div>
        <div className="col-12 col-lg-6 form-group-without-label">
            <Input name={ `${rootName}.subpack` } placeholder="per subpack/sleeve"
                valueAsInteger={ true } label="" type="number" control={ control } errors={ errors }/>
        </div>
    </>;
};
