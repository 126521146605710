import React, { useEffect, useState } from "react";
import Spinner from "components/Spinner";
import { WHOLESALER_PATHS } from "pages/wholesaler/WholesalerRoutes";
import { useNavigate } from "react-router-dom";
import LetterService from "services/wholesaler/LetterService";
import SearchForm from "pages/wholesaler/appointment/components/SearchForm";
import { useAuthStore } from "store/AuthStoreProvider";

const ListLetter = () => {

    const auth = useAuthStore();
    const currentLicenseIsActive = auth.currentLicense?.active;
    const navigate = useNavigate();
    const [isLettersPresent, setIsLettersPresent] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        LetterService.isLettersPresent().then(response => {
            setIsLettersPresent(response);
        }).finally(() => {
            setIsLoading(false);
        });
    }, []);

    return <div className="d-flex flex-column">
        <div className="mb-4 mb-md-5">
            <h1 className="text-primary mb-4">Appointment Letters</h1>
            <p className="mb-0">Manage appointment letters</p>
        </div>
        <div>
            { isLettersPresent && !isLoading &&
                <SearchForm />
            }
            <Spinner hidden={ !isLoading } className="mt-4 text-center" />

            { !isLettersPresent && !isLoading &&
                <div className="card">
                    <div className="card-body pb-1">
                        <div className="text-center">
                            <p>Looks like you don&apos;t have any appointment letters. You can create your first letter by clicking button below:</p>
                            <div className="form-group mt-4">
                                <button
                                    onClick={ () => navigate(WHOLESALER_PATHS.APPOINTMENT.CREATE_LETTER) }
                                    className="btn btn-primary"
                                    title="Add New Appointment Letter"
                                    disabled={ !auth.permissionStore.isWholesalerAndPricesCertifyOrWrite() || !currentLicenseIsActive }
                                >Add New Appointment Letter</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    </div>;
};

export default ListLetter;

