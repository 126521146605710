import { Outlet } from "react-router-dom";
import AccessDenied from "pages/AccessDenied";
import React from "react";

interface Props {
    isAllowed: boolean,
}
const ProtectedRoute = ({ isAllowed }: Props) => {
    if (!isAllowed) {
        return <AccessDenied />;
    }

    return <Outlet />;
};

export default ProtectedRoute;
