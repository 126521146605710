import { Outlet } from "react-router-dom";
import React from "react";

interface Props {
    actions?: Array<() => void>;
}
const RouteWithActions = ({ actions }: Props) => {
    if (actions) {
        actions.forEach(action => action());
    }

    return <Outlet />;
};

export default RouteWithActions;
