import React from "react";
import { ADMIN_PATHS } from "pages/admin/AdminRoutes";
import { Link } from "react-router-dom";
import { useUtilStore } from "store/UtilStoreProvider";

const ReturnToSearchLink = () => {
    const { adminReturnParams } = useUtilStore();

    return <Link
        to={ ADMIN_PATHS.LICENSES.SEARCH + adminReturnParams }
        className="btn btn-outline-primary">
        <i className="bi-arrow-left-circle me-2"></i>
        Return to search
    </Link>;
};

export default ReturnToSearchLink;
