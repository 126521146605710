import { ShortLicenseInfo, licenseTitle } from "models/license.model";
import React from "react";

const LicenseBar = ({ license, isMulti }:{ license: ShortLicenseInfo; isMulti?: boolean }) => {
    const text = licenseTitle(license);
    return <span className={ "badge bg-grey d-block text-truncate " + (isMulti ? "max-w-150" : "max-wper-90") } title={ text }>
        { text }
    </span>;
};

export default LicenseBar;
