import { useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { LicenseModel } from "models/license.model";
import Spinner from "components/Spinner";
import { useForm } from "react-hook-form";
import TextArea from "components/form/TextArea";
import FormUtil from "utils/FormUtil";
import ProfilePostingService from "services/wholesaler/ProfilePostingService";
import { WHOLESALER_PATHS } from "pages/wholesaler/WholesalerRoutes";
import CheckBox from "components/form/CheckBox";
import PricePostingTitle from "components/PricePostingTitle";

interface ProfileTermFormModel {
    terms_sale: string;
    other_info: string;
    pay_disc: boolean;
}

const ViewPostingProfile = () => {
    const navigate = useNavigate();
    const { type = "", month = "", year = ""  } = useParams<{
        type: string,
        year: string,
        month: string
    }>();
    const [license, setLicense] = useState<LicenseModel>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const postingDate = new Date().toLocaleDateString("en-US");

    const {
        setValue,
        control,
        formState: { errors }
    } = useForm<ProfileTermFormModel>({
        mode: "onSubmit",
        defaultValues: {}
    });

    useEffect(() => {
        setIsLoading(true);
        ProfilePostingService.getProfile(type, month, year).then(license => {
            setLicense(license);
            setValue("pay_disc", license.profile_term?.pay_disc == 1);
            setValue("terms_sale", license.profile_term?.terms_sale);
            setValue("other_info", license.profile_term?.other_info);
        }).finally(() => setIsLoading(false));
    }, []);

    function onReturn() {
        navigate(generatePath(WHOLESALER_PATHS.PRICE_POSTING.VIEW,
            { "type":type, "year":year, "month": month }));
    }

    return <div className="d-flex flex-column">
        <PricePostingTitle type={ type } year={ year } month={ month } />
        { isLoading && <Spinner className="mt-4 pt-2 text-center" /> }
        { !isLoading &&
            <div>
                <div className="mb-3 mb-md-4">
                    <h4 className="mb-2">View Posting Profile</h4>
                </div>
                <div className="card mb-4">
                    <div className="card-body pb-2">
                        <div className="mb-4">
                            <h4 className="mb-3">License information for { postingDate }</h4>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-5 col-lg-4 col-xl-2">
                                <p><strong>License ID:</strong></p>
                            </div>
                            <div className="col-12 col-md-7 col-lg-8 col-xl-10">
                                <p>{ license?.permit_id }</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-5 col-lg-4 col-xl-2">
                                <p><strong>Premises name:</strong></p>
                            </div>
                            <div className="col-12 col-md-7 col-lg-8 col-xl-10">
                                <p>{ license?.premise_name }</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-5 col-lg-4 col-xl-2">
                                <p><strong>Premises address:</strong></p>
                            </div>
                            <div className="col-12 col-md-7 col-lg-8 col-xl-10">
                                <p>{ license?.premise_address }
                                    { license?.premise_address2 ? ", " + license?.premise_address2 : "" }</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="mb-4">
                        <h4 className="mb-3">Profile information</h4>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-4">
                            <p className="mb-1"><strong>Discount for time payment</strong></p>
                            <div className="switch-year">
                                <label className="form-check-label me-2" htmlFor="custom-switch">None</label>
                                <CheckBox control={ control } name="pay_disc" label="1% for payment within 10 days of delivery" disabled />
                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="pe-xl-4">
                                <TextArea
                                    disabled
                                    control={ control } name="terms_sale" rows={ 5 }
                                    errors={ errors } rules={ FormUtil.maxLengthRule(5000) }
                                    placeholder="Terms of sale" label="Terms of sale" />
                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="ps-xl-4">
                                <TextArea
                                    disabled
                                    control={ control }  name="other_info" rows={ 5 }
                                    errors={ errors } rules={ FormUtil.maxLengthRule(5000) }
                                    placeholder="Other information" label="Other information" />
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-3">
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                            <button type="button" onClick={ () => onReturn() } className="btn btn-outline-secondary w-100">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>;
};

export default ViewPostingProfile;

