import { ProfileTermModel } from "models/ProfileTermModel";
import { PrincipalModel } from "models/PrincipalModel";
import { LicenseTypeModel } from "models/LicenseTypeModel";

export interface LicenseModel {
    id: number;
    certificate_number: number;
    county: string;
    dba: string;
    effective_date: string;
    expiration_date: string;
    georeference: string;
    issued_date: string;
    original_date: string;
    premise_address: string;
    premise_address2: string;
    premise_city: string;
    zip: string;
    premise_name: string;
    premise_state: string;
    serial_number: number;
    status: string;
    zone: number;
    delinquent_status: string;
    profile_term: ProfileTermModel;
    principals: PrincipalModel[];
    type: LicenseTypeModel;
    related_permit_ids: string[];
    permit_id: string;
    available_roles?: string[]
    licenseSelector: string; // This is needed to search by license ID or serial number
}

export type ShortLicenseInfo = Pick<LicenseModel, "id" | "serial_number" | "permit_id" | "premise_name" | "dba" | "status" | "available_roles" > ;

export const licenseTitle = (license: ShortLicenseInfo ) =>
    `${license.premise_name ? license.premise_name.trim() : ""}`+
    `${license.permit_id ? ("/"+ license.permit_id) : "" }`+
    `${license.serial_number ? ("/"+ license.serial_number) : "" }`;

export const isActiveLicense = ( sli: ShortLicenseInfo ) => sli.status == "A" || sli.status == "T";
