

export const debounce = (fn: any, delay: number) => {
    let timer: NodeJS.Timeout;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => fn(...args), delay);
    };
};
