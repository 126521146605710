import { SelectOption } from "components/form/Select";

export class PostTypeModel {
    code: string;
    description: string;

    constructor(code: string, description: string) {
        this.code = code;
        this.description = description;
    }

    toSelectOption(){
        return { value: this.code, title: this.description } as SelectOption;
    }
}
