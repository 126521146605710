import httpClient from "api/HttpClient";
import BACKEND from "services/Constants";
import { HistoricData } from "models/HistoricData";

const root = `${BACKEND}/admin/historic-data-reports`;

export default {
    list(): Promise<HistoricData[]> {
        return httpClient.get(`${root}/list`);
    }
};
