import React, { useEffect, useState } from "react";
import { useAuthStore } from "store/AuthStoreProvider";
import ProductConstants from "services/ProductConstants";
import WholesalerPricePostingService from "services/wholesaler/WholesalerPricePostingService";
import PriceStatus from "pages/wholesaler/priceposting/components/PriceStatus";
import { UserLicense } from "models/LoggedInUserInfo";
import { PricesStatusAndDate } from "models/response/PricesStatusAndDate";
import DateUtil from "utils/DateUtil";

interface Props {
    type: string,
    year?: string,
    month?: string,
    certifiedNeeded?: boolean
}

const PricePostingTitle = ({ type, month, year, certifiedNeeded }: Props) => {
    const { licenseId, licenses } = useAuthStore();
    const [status, setStatus] = useState<string>();
    const [certificationDate, setCertificationDate] = useState<string>();

    useEffect(() => {
        if(year == undefined || month == undefined) {
            setStatus("NOT_CERTIFIED");
        } else {
            WholesalerPricePostingService.getStatus(type, year, month, certifiedNeeded).then((r: PricesStatusAndDate) => {
                setStatus(r.status);
                setCertificationDate(r.certificationDate);
            });
        }
    },[]);

    function getStatus() {
        return status
            ? <PriceStatus status={ status } certificationDate={ certificationDate } />
            : <span>-</span>;
    }

    function license(): UserLicense | undefined   {
        if (licenseId == undefined || licenses == undefined || licenses.length == 0) {
            return undefined;
        }

        return licenses.filter(l => l.licenseId == licenseId)[0];
    }

    return <div className="mb-4 mb-md-5">
        <h1 className="text-primary mb-2">
            { type && ProductConstants.postTypeDescription(type) } - { month && DateUtil.monthNumberToName(parseInt(month)-1) } { year && year }
        </h1>
        <div className="h5 d-flex flew-row flex-wrap align-items-center">
            <span className="me-2">License: <b>{ license()?.permitId }</b></span>
            <span className="me-2">-</span>
            <span className="d-flex flew-row">
                <span className="me-2">Status:</span> { getStatus() }
            </span>
        </div>
    </div>;
};

export default PricePostingTitle;
