import  { extractPathFromObject } from "components/form/BaseInput";
import React from "react";
import { Controller } from "react-hook-form";
import classNames from "classnames";
import DeadlinesModel from "pages/admin/models/DeadlinesModel";
import DateUtil from "utils/DateUtil";

interface Props {
    deadline: DeadlinesModel,
    control: any,
    errors: any,
    index: number
}

export const DeadlineAmendmentInputs = ({ deadline, control, errors, index } : Props) => {

    const { month } = deadline;
    const daysInMonth =  DateUtil.daysInMonth(month, deadline.year || 1);
    const dayOfMonthOrdinal =  DateUtil.dayOfMonthOrdinal(daysInMonth);

    const name1 =  `schedules[${index}].amendment_day1`;
    const name2 =  `schedules[${index}].amendment_day2`;
    const name3 =  `schedules[${index}].amendment_day3`;
    const error1 = extractPathFromObject(errors, name1);
    const error2 = extractPathFromObject(errors, name2);
    const error3 = extractPathFromObject(errors, name3);

    const rules = {
        "required": "This field is required",
        "min": { "value": 16, "message": "Date can not be earlier than the 16th" },
        "max": {
            "value": daysInMonth,
            "message": `Date can not be later than the ${dayOfMonthOrdinal}`
        }
    };

    function getController(name: string, error: any) {
        return <Controller
            name={ name }
            control={ control }
            rules={ rules }
            render={ ({ field: { onChange, value, onBlur, ref } }) => (
                <input
                    value={ value }
                    onChange={ onChange }
                    onBlur={ onBlur }
                    className={ classNames("form-control", { "is-invalid": error !== undefined }) }
                    id={ name }
                    ref={ ref }
                    type="number"
                />
            ) }
        />;
    }

    return <div className="body-block">
        <h5>Amendments for { DateUtil.monthNumberToName(month) }</h5>
        <div className="d-flex flex-row align-items-center">
            <label htmlFor={ name1 }>on</label>
            <div className="form-group-without-m form-group-sm wp-50">
                { getController(name1, error1) }
            </div>
            <div className="form-group-without-m form-group-sm wp-50">
                { getController(name2, error2) }
            </div>
            <div className="form-group-without-m form-group-sm wp-50 me-2">
                { getController(name3, error3) }
            </div>
            <label htmlFor={ name3 }>of { DateUtil.monthNumberToName(month - 1) }</label>
        </div>
        { error1 && <div className="invalid-message">{ error1?.message }</div> }
        { error2 && <div className="invalid-message">{ error2?.message }</div> }
        { error3 && <div className="invalid-message">{ error3?.message }</div> }
    </div>;
};
