import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import WholesalerDelinquentService from "services/wholesaler/WholesalerDelinquentService";
import { ManageDelinquentResponse } from "models/response/ManageDelinquentResponse";
import StringUtils from "utils/StringUtils";
import CurrencyInput from "components/form/CurrencyInput";
import FormUtil from "utils/FormUtil";
import { modalStore } from "store/ModalStoreProvider";
import TextArea from "components/form/TextArea";
import DatePickerInput from "components/form/DatePickerInput";
import dayjs from "dayjs";
import { MessageModel } from "models/message.model";
import { useTimeStore } from "store/TimeStoreProvider";
import { useKeyDownEnterEvent } from "components/KeyDownHook";


interface Props {
    id: number,
    onSave: any,
    onError: (data: any)=>void
}

interface EditDelinquentModel {
    id: string;
    deliveryAmount: number | undefined;
    deliveryDate: Date | null;
    comment: string;
}

export const showEditDelinquencyDialog = (id: number, onSave: any, onError: any) => {
    modalStore.setSize("xl");
    modalStore.setBody(<EditDelinquencyDialog id={ id } onSave={ onSave } onError={ onError } />);
    modalStore.setHeader("Edit Delinquent Record");
    modalStore.showModal();
};

const EditDelinquencyDialog = ({ id, onSave, onError }: Props) => {

    const { newYorkTime } = useTimeStore();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    useEffect(() => {
        loadData(id);
    },[]);

    const {
        handleSubmit,
        control,
        setValue,
        setError,
        formState: { errors }
    } = useForm<EditDelinquentModel>({
        mode: "onChange"
    });
    useKeyDownEnterEvent(() => !isLoading && handleSubmit(onSubmit)());

    const [delinquency, setDelinquency] = useState<ManageDelinquentResponse>();
    const [isUnknownError, setIsUnknownError] = useState<string | undefined>(undefined);

    const handleValidationError = (message: MessageModel) =>{
        if (message.errors){
            message.errors.forEach(error=>{
                if (error.field && error.field.length > 0){
                    setError(error.field as keyof EditDelinquentModel, { message: error.message });
                }
                else {
                    setIsUnknownError(error.message);
                }
            });
        }
    };

    const onSubmit: SubmitHandler<EditDelinquentModel> = async data => {
        setIsUnknownError(undefined);
        setIsLoading(true);
        const request = {
            deliveryAmount: data.deliveryAmount,
            deliveryDate: dayjs(data.deliveryDate).format("MM/DD/YYYY"),
            comment: data.comment,
            id: id
        };

        WholesalerDelinquentService.updateDelinquent(request)
            .then ( () => {
                modalStore.hideModal();
                onSave();
            })
            .catch(e => {
                if (e.status == 422){
                    handleValidationError(e.data as MessageModel);
                }
                else {
                    setIsUnknownError("Something went wrong. Please try again");
                    onError(e.data);
                }
            }).finally(() => setIsLoading(false));
    };
    modalStore.setAdditionalButtons([
        <button key={ 0 } type="button" className="btn btn-primary" onClick={ handleSubmit(onSubmit) }>Save</button>
    ]);

    function loadData(delinquentRecordId: number) {
        setIsLoading(true);
        WholesalerDelinquentService.fetchCurrentWholesalerRecord(delinquentRecordId)
            .then( (response: ManageDelinquentResponse) => {
                setValue("deliveryDate", new Date(response.delivery_date));
                setValue("deliveryAmount", response.amount );
                setValue("comment", response.comment);
                setDelinquency(response);
            }).finally(() => setIsLoading(false));
    }


    return <div>
        { isUnknownError &&
            <div className="invalid-message alert alert-sm alert-danger mb-4">
                <p className="mb-0">{ isUnknownError }</p>
            </div>
        }
        <div className="row">
            <div className="col-12 col-xl-6">
                <div className="pe-xl-4">
                    <div className="mb-4">
                        <h5 className="fw-bold mb-3">Premises</h5>
                        <hr/>
                    </div>
                    <div className="mb-2 pb-1">
                        <p className="mb-0"><strong>License ID or Serial number</strong></p>
                        <p className="mb-0">{ delinquency?.license_permit_id }</p>
                    </div>
                    <div className="mb-2 pb-1">
                        <p className="mb-0"><strong>Name</strong></p>
                        <p className="mb-0">{ delinquency?.premise_name }</p>
                    </div>
                    <div className="mb-2 pb-1">
                        <p className="mb-0"><strong>License class</strong></p>
                        <p className="mb-0">{ delinquency?.license_class }</p>
                    </div>
                    <div className="mb-2 pb-1">
                        <p className="mb-0"><strong>License type</strong></p>
                        <p className="mb-0">{ delinquency?.license_type }</p>
                    </div>
                    <div className="mb-2 pb-1">
                        <p className="mb-0"><strong>Address</strong></p>
                        <p className="mb-0">{ delinquency && (delinquency.premise_address + " " + delinquency.premise_address2) }</p>
                    </div>
                    <div className="mb-2 pb-1">
                        <p className="mb-0"><strong>County</strong></p>
                        <p className="mb-0">{ delinquency?.county }</p>
                    </div>
                    <div className="mb-2 pb-1">
                        <p className="mb-0"><strong>License status</strong></p>
                        <p className="mb-0">{ delinquency && StringUtils.capitalize(delinquency.license_status) }</p>
                    </div>
                </div>
            </div>
            <div className="col-12 col-xl-6 mt-4 mt-xl-0">
                <div className="ps-xl-4">
                    <div className="mb-4">
                        <h5 className="fw-bold mb-3">Delinquency Information</h5>
                        <hr/>
                    </div>

                    <CurrencyInput
                        name="deliveryAmount"
                        label="Delivery Amount *"
                        control={ control }
                        errors={ errors }
                        rules={ FormUtil.REQUIRED_FIELD }
                    />

                    <DatePickerInput
                        name="deliveryDate" control={ control } errors={ errors } label="Delivery Date *"
                        maxDate={ newYorkTime } rules={ FormUtil.REQUIRED_FIELD }/>
                    <TextArea
                        name="comment" control={ control } errors={ errors } label="Comment / Reference"
                        placeholder="Enter comment / reference" rows={ 5 } rules={ FormUtil.maxLengthRule(200) }/>
                </div>
            </div>
        </div>
    </div>;
};
