import { Link, generatePath, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Spinner from "components/Spinner";
import { CountItem } from "models/response/CountItem";
import { ADMIN_PATHS } from "pages/admin/AdminRoutes";
import SchedulesService from "services/admin/SchedulesService";
import ProductConstants from "services/ProductConstants";
import DateUtil from "utils/DateUtil";

const MonthSchedule = () => {

    const { type = "", year = "", month  = "", licenseId = "" } = useParams();
    const [beverageTypes, setBeverageTypes] = useState<Array<CountItem>>();
    const [packageTypes, setPackageTypes] = useState<Array<CountItem>>();
    const [isLoading, setIsLoading] = useState<boolean>();

    useEffect(() => {
        setIsLoading(true);

        SchedulesService
            .countProductsByBeverageTypes(type, year, month, licenseId).then((response: Array<CountItem>) => {
                setBeverageTypes(response);
                setIsLoading(false);
            });

        SchedulesService
            .countProductsByPackageTypes(type, year, month, licenseId).then((response: Array<CountItem>) => {
                setPackageTypes(response);
            });
    },[]);

    return <div className="d-flex flex-column">
        <div className="mb-4 mb-md-5">
            <h1 className="text-primary mb-0">{ ProductConstants.postTypeDescription(type) } - Schedule for { DateUtil.monthNumberToName(parseInt(month)-1) } { year }</h1>
        </div>
        <Spinner hidden={ !isLoading } className="text-center mt-2" />
        <div>
            <h5 className="mb-3 fw-bold">Price Posting Summary</h5>
            <p>The current price posting schedule has been pre-filled with the brand information contained in the last price schedule you submitted online.</p>
            <p>The table below contains a summary of the current price schedule. As you make changes, this summary information is updated.</p>
            <div className="row">
                <div className="col-12 col-lg-6">
                    <div className="pe-xl-4">
                        <div className="table-responsive mt-3">
                            <table className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>Beverage type</th>
                                        <th>Number of items</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { beverageTypes?.map( (item: CountItem) => {
                                        /* eslint-disable indent */
                                        return <tr key={ item.title }>
                                            <td>
                                                <Link to={ generatePath(ADMIN_PATHS.LICENSES.UPCOMING_SCHEDULES.BRANDS_ITEMS,
                                                    { "licenseId": licenseId, "type": type, "year": year, "month": month } ) + "?beverage_type=" + item.code }>
                                                    { item.title }
                                                </Link>
                                            </td>
                                            <td>
                                                { item.count }
                                            </td>
                                        </tr>;
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="ps-xl-4">
                        <div className="table-responsive mt-3">
                            <table className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>Package type</th>
                                        <th>Number of items</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    { packageTypes?.map( (item: CountItem) => {
                                        /* eslint-disable indent */
                                        return <tr key={ item.title }>
                                            <td>
                                                <Link to={ generatePath(ADMIN_PATHS.LICENSES.UPCOMING_SCHEDULES.BRANDS_ITEMS,
                                                    { "licenseId": licenseId, "type": type, "year": year, "month": month }) + "?reg_combo=" + item.code }>
                                                    { item.title }
                                                </Link>
                                            </td>
                                            <td>
                                                { item.count }
                                            </td>
                                        </tr>;
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="mt-4">
            <h5 className="mb-3 fw-bold">Price Posting Actions</h5>
            <div className="content-styles">
                <ul>
                    <li>
                        <Link
                            to={ generatePath(ADMIN_PATHS.LICENSES.UPCOMING_SCHEDULES.BRANDS_ITEMS,
                                { "licenseId": licenseId, "type": type, "year": year, "month": month }) }
                            className="mb-0">
                            View Brands & Items
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    </div>;
};

export default MonthSchedule;
