export const combo_disa_values = [
    { "value": "RD", "title":"Retail Disassembly (RD)" },
    { "value": "CP", "title":"Consumer Packaging (CP)" }
];

export const combo_asse_values = [
    { "value": "", "title":"" },
    { "value": "SA", "title":"Supplier Assembly (SA)" },
    { "value": "DA", "title":"Distributor Assembly (DA)" }
];

export const combo_assembly_description = (value:string | undefined) =>
    value && combo_asse_values.find(s=>s.value==value)?.title || "N/A";


export const combo_disassembly_description = (value:string | undefined) =>
    value && combo_disa_values.find(s=>s.value==value)?.title || "N/A";
