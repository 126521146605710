import React from "react";
import { MenuSettings, useLeftMenu } from "components/menu/Menu";
import DelinquentSearchView from "pages/statics/delinquent/DelinquentSearchView";
import DelinquentRecordsView from "pages/statics/delinquent/DelinquentRecordsView";
import DelinquentBatchView from "pages/statics/delinquent/DelinquentBatchView";
import DelinquentBatch1View from "pages/statics/delinquent/DelinquentBatch1View";
import DelinquentBatch2View from "pages/statics/delinquent/DelinquentBatch2View";
import DelinquentBatch3View from "pages/statics/delinquent/DelinquentBatch3View";
import DelinquentBatch4View from "pages/statics/delinquent/DelinquentBatch4View";
import { Route, Routes } from "react-router-dom";

export const DELINQUENT_PATHS = {
    MAIN: "/delinquent",
    SEARCH: "/search",
    RECORDS: "/records",
    BATCH: "/batch",
    BATCH1: "/batch1",
    BATCH2: "/batch2",
    BATCH3: "/batch3",
    BATCH4: "/batch4"
};

const LEFT_MENU: MenuSettings = {
    caption: "Search Delinquent",
    items: [
        { title: "Price Posting", link: "/link" },
        { title: "Users", link: "/link2" },
        { title: "My Delinquent Records", link: "/link2" },
        { title: "Licensees Search", link: "/link2" },
        { title: "Delinquent Licensees Search", link: "/link2" }
    ]
};

const DelinquentStaticRoutes = () => {

    useLeftMenu(LEFT_MENU);

    return <Routes>
        <Route path={ DELINQUENT_PATHS.SEARCH } element={ <DelinquentSearchView/> }/>
        <Route path={ DELINQUENT_PATHS.RECORDS } element={ <DelinquentRecordsView/> }/>
        <Route path={ DELINQUENT_PATHS.BATCH } element={ <DelinquentBatchView/> }/>
        <Route path={ DELINQUENT_PATHS.BATCH1 } element={ <DelinquentBatch1View/> }/>
        <Route path={ DELINQUENT_PATHS.BATCH2 } element={ <DelinquentBatch2View/> }/>
        <Route path={ DELINQUENT_PATHS.BATCH3 } element={ <DelinquentBatch3View/> }/>
        <Route path={ DELINQUENT_PATHS.BATCH4 } element={ <DelinquentBatch4View/> }/>
    </Routes>;
};

export default DelinquentStaticRoutes;
