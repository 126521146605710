import { MenuSettings, useLeftMenu } from "components/menu/Menu";
import React from "react";
import AdminView from "pages/statics/admin/AdminView";
import AdminViewEmpty from "pages/statics/admin/AdminEmpty";
import AdminScheduleDeadlines from "pages/statics/admin/AdminScheduleDeadlines";
import AdminLicenceSettings from "pages/statics/admin/AdminLicenceSettings";
import { Route, Routes } from "react-router-dom";

export const ADMIN_STATIC_PATHS = {
    MAIN: "/admin/*",
    VIEW: "/view",
    EMPTY: "/view-empty",
    SCHEDULE: "/schedule-deadlines",
    SETTINGS: "/licence-settings"
};

const LEFT_MENU: MenuSettings = {
    caption: "Admin menu",
    items: [
        { title: "Master Filers", link: "/link" },
        { title: "Manage Schedule Deadlines", link: "/link" }
    ]
};

const AdminStaticRoutes = () => {

    useLeftMenu(LEFT_MENU);

    return <Routes>
        <Route path={ ADMIN_STATIC_PATHS.VIEW } element={ <AdminView/> }/>
        <Route path={ ADMIN_STATIC_PATHS.EMPTY } element={ <AdminViewEmpty/> }/>
        <Route path={ ADMIN_STATIC_PATHS.SCHEDULE } element={ <AdminScheduleDeadlines/> }/>
        <Route path={ ADMIN_STATIC_PATHS.SETTINGS } element={ <AdminLicenceSettings/> }/>
    </Routes>;
};

export default AdminStaticRoutes;
