import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import LicenseInfo from "components/LicenseInfo";
import WholesalerDelinquentRecords, { Reloadable } from "components/WholesalerDelinquentRecords";
import ReturnToSearchLink from "pages/admin/components/ReturnToSearchLink";
import { showDeleteCODConfirmation } from "components/dialogs/DeleteCodDataDialog";
import { useAuthStore } from "store/AuthStoreProvider";
import { LicenseModel, isActiveLicense } from "models/license.model";

export const CodDataWholesaler = () => {

    const { permissionStore } = useAuthStore();
    const { licenseId = "" } = useParams();
    const licenseIdInt = parseInt(licenseId);
    const [license, setLicense] = useState<LicenseModel>();
    const recordsRef = useRef<Reloadable>(null);

    const reloadRecords = ()=>{
        recordsRef.current?.reload();
    };

    return <div className="d-flex flex-column">
        <div className="mb-4 pb-md-3 d-flex flex-column flex-md-row align-items-center">
            <div className="order-0 order-md-1">
                <ReturnToSearchLink/>
            </div>
            <h1 className="text-primary mb-3 mb-md-0 mt-3 mt-md-0 me-md-auto order-1 order-md-0">Delinquent Data</h1>
        </div>
        <LicenseInfo licenseId={ licenseIdInt } showDelinquentStatus={ true } onLoadLicense={ setLicense }/>
        <div className="mt-5">
            <WholesalerDelinquentRecords wholesalerLicenseId={ licenseIdInt } readonly={ true } ref={ recordsRef }>
                { (permissionStore.isSlaAdminOrAdmin()) &&
                    <div className="ms-3 mb-2">
                        <button type="button" className="btn btn-danger"
                            disabled={ license ? isActiveLicense(license) : true }
                            onClick={ () => showDeleteCODConfirmation(license ?? { } as LicenseModel, reloadRecords ) }>
                            Delete COD Data
                        </button>
                    </div>
                }
            </WholesalerDelinquentRecords>
        </div>
    </div>;

};
