import { PriceScheduleData } from "models/PriceScheduleData";
import { PriceScheduleByDate } from "models/response/PriceScheduleByDate";
import { Link, generatePath, useNavigate } from "react-router-dom";
import ProductConstants from "services/ProductConstants";
import React, { Fragment } from "react";
import { WHOLESALER_PATHS } from "pages/wholesaler/WholesalerRoutes";
import service from "services/wholesaler/WholesalerPricePostingService";
import Spinner from "components/Spinner";
import { showRevertScheduleDialog } from "components/dialogs/RevertScheduleDialog";
import { useAuthStore } from "store/AuthStoreProvider";
import DateUtil from "utils/DateUtil";

interface Props {
    priceSchedule: PriceScheduleByDate | undefined;
    isLoading: boolean;
    loadData: () => void;
}

const PriceSchedules = ({ priceSchedule, isLoading, loadData }: Props) => {
    const { permissionStore } = useAuthStore();

    return <Fragment>
        <Spinner hidden={ !isLoading } className="text-center mb-3" />
        { priceSchedule && priceSchedule.schedules && priceSchedule?.schedules.length != 0 &&
            <Fragment>
                <h5>Schedules Effective { DateUtil.monthNumberToName(priceSchedule.month-1) } { priceSchedule.year }</h5>
                <div className="table-responsive mt-3 mb-3">
                    <table className="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th className="w-30">Price Schedule</th>
                                <th>Total</th>
                                <th>Valid</th>
                                <th>Errors</th>
                                <th>Certified</th>
                                <th className="w-19">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            { priceSchedule.schedules.map((schedule: PriceScheduleData) => {
                                const canChange = service.canDoAction(schedule.actions || [], "POST")
                                    && permissionStore.isWholesalerAndPricesCertifyOrWrite();
                                const canCertify = service.canDoAction(schedule.actions || [], "CERTIFY")
                                    && permissionStore.canCertify();
                                const canRevert = service.canDoAction(schedule.actions || [], "REVERT_RECENT")
                                    && permissionStore.isWholesalerAndPricesCertifyOrWrite();
                                const canAmendPrices = service.canDoAction(schedule.actions || [], "EQUALIZE")
                                    && permissionStore.isWholesalerAndPricesCertifyOrWrite();

                                return <tr key={ schedule.postType } className={ schedule.certified && schedule.certified > 0 ? "" : "" }>
                                    <td>
                                        <Link
                                            className="" title="Manage Schedule"
                                            to={ generatePath(WHOLESALER_PATHS.PRICE_POSTING.VIEW, {
                                                "type": schedule.postType,
                                                "year": priceSchedule.year,
                                                "month": priceSchedule.month
                                            }) }>
                                            { ProductConstants.postTypeDescription(schedule.postType) }
                                        </Link>
                                        { schedule.draftChangedAfterCertification ?
                                            <div className="tooltip-wr tooltip-wr-certify d-inline ms-2">
                                                <span className="bi-exclamation-circle-fill text-danger"></span>
                                                <div className="tooltip-msg text-center">
                                                    <p>Changes detected in your working set! To include these in the certified schedule, you have to re-certify</p>
                                                </div>
                                            </div>
                                            : "" }
                                    </td>
                                    <td>{ schedule.total }</td>
                                    <td>{ schedule.valid }</td>
                                    <td className="text-danger">{ schedule.errors }</td>
                                    <td>
                                        { schedule.certified == 0 ? <>{ schedule.certified }</>
                                            : <div>
                                                { schedule.certified && schedule.certified > 0 &&
                                                    <div className="tooltip-wr tooltip-wr-table me-2">
                                                        <span className="bi-check-circle-fill text-success"></span>
                                                        <div className="tooltip-msg text-center">
                                                            <p>Prices Certified</p>
                                                        </div>
                                                    </div>
                                                }
                                                <Link to={ generatePath(WHOLESALER_PATHS.PRICE_POSTING.VIEW, { "type": schedule.postType, "year": priceSchedule.year, "month": priceSchedule.month, "certifiedNeeded": "true" }) }>
                                                    { schedule.certified }
                                                </Link>
                                            </div>
                                        }
                                    </td>
                                    <td>
                                        <Link
                                            className="btn btn-link py-0 px-1 me-2" title={ canAmendPrices ? "Manage Schedule or Amend Prices" : "Manage Schedule" }
                                            to={ generatePath(WHOLESALER_PATHS.PRICE_POSTING.VIEW, { "type": schedule.postType, "year": priceSchedule.year, "month": priceSchedule.month }) }>
                                            {
                                                schedule.status == "PUBLISHED" || schedule.status == "EQUALIZABLE" || !permissionStore.isWholesalerAndPricesCertifyOrWrite()
                                                    ? <i className="bi-eye"></i>
                                                    : <i className="bi-gear"></i>
                                            }
                                        </Link>

                                        { canCertify && schedule.errors === 0 &&
                                            <Link
                                                className="btn btn-link py-0 px-1 me-2" title="Certify schedule"
                                                to={ generatePath(WHOLESALER_PATHS.CERTIFY.STEP1, { "type": schedule.postType, "year": priceSchedule?.year, "month": priceSchedule?.month }) }>
                                                <i className="bi-check-circle"></i>
                                            </Link>
                                        }

                                        { canCertify && schedule.errors != 0 &&
                                            <span className="disabled py-0 px-1 me-2" title="The schedule can not be certified due to errors. You can correct them in interactive mode or re-upload the corrected information.">
                                                <i className="bi-check-circle"></i>
                                            </span>
                                        }

                                        { canChange &&
                                            <Link
                                                className="btn btn-link py-0 px-1 me-2" title="Batch Upload"
                                                to={ generatePath(WHOLESALER_PATHS.PRICE_POSTING.BATCH_IMPORT, { "type": schedule.postType, "year": priceSchedule?.year, "month": priceSchedule?.month }) }>
                                                <i className="bi-upload"></i>
                                            </Link>
                                        }

                                        { canRevert &&
                                            <button
                                                className="btn btn-link py-0 px-1 me-2" title="Revert to the most recent certified schedule"
                                                onClick={ () => { showRevertScheduleDialog(schedule.postType, priceSchedule.year.toString(), priceSchedule.month.toString(), loadData); } }>
                                                <i className="bi-bootstrap-reboot"></i>
                                            </button>
                                        }

                                    </td>
                                </tr>;
                            }) }
                        </tbody>
                    </table>
                </div>
            </Fragment>
        }
    </Fragment>;
};

export default PriceSchedules;
