import httpClient from "api/HttpClient";
import { RegistrationRequestModel } from "models/requests/RegistrationRequestModel";
import { ConfirmRegistrationModel } from "models/requests/ConfirmRegistrationModel";
import BACKEND from "services/Constants";

export default {

    registerUser(data: RegistrationRequestModel): Promise<void> {
        return httpClient.post(BACKEND + "/registration/registerUser", data);
    },

    confirmRegistration(data: ConfirmRegistrationModel): Promise<void> {
        return httpClient.post(BACKEND + "/registration/confirmRegistration", data);
    }
};
