import React from "react";
import Profile from "pages/user/Profile";
import { MyLicenses } from "pages/user/MyLicenses";
import { Route, Routes } from "react-router-dom";
import AuthenticatedRoute from "components/AuthenticatedRoute";
import { useAuthStore } from "store/AuthStoreProvider";
import { useTimeStore } from "store/TimeStoreProvider";
import { MenuSettings, useLeftMenu } from "components/menu/Menu";
import { roleDependentMenu } from "services/RedirectService";

const RELATIVE_PATHS = {
    MAIN: "/user",
    PROFILE: "/profile",
    MY_LICENSES: "/my-licenses"
};

export const USER_PATHS = {
    MAIN: RELATIVE_PATHS.MAIN + "/*",
    PROFILE: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.PROFILE,
    MY_LICENSES: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.MY_LICENSES
};

export const LEFT_MENU = (): MenuSettings => ({
    caption: "Profile Menu",
    items: [
        {
            title: "Profile",
            link: USER_PATHS.PROFILE
        },
        {
            title: "My Licenses",
            link: USER_PATHS.MY_LICENSES
        }
    ]
});

const UserRoutes = () => {
    const { permissionStore } = useAuthStore();
    const { newYorkTime } = useTimeStore();

    useLeftMenu(roleDependentMenu(permissionStore, newYorkTime));

    return <Routes>
        <Route element={ <AuthenticatedRoute/> }>
            <Route path={ RELATIVE_PATHS.PROFILE } element={ <Profile/> }/>
            <Route path={ RELATIVE_PATHS.MY_LICENSES } element={ <MyLicenses/> }/>
        </Route>
    </Routes>;
};

export default UserRoutes;

