import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Input from "components/form/Input";
import { useNavigate, useParams } from "react-router-dom";
import { ConfirmWholesalerInvitationRequest } from "models/requests/ConfirmWholesalerInvitationRequest";
import FormUtil from "utils/FormUtil";
import { TokenInfo } from "services/AuthService";
import { AUTH_PATHS } from "pages/auth/AuthRoutes";
import PhoneNumberInput from "components/form/input/PhoneNumberInput";
import { useToastStore } from "store/ToastStoreProvider";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";
import { MessageModel } from "models/message.model";
import CheckTokenForm from "pages/auth/components/CheckTokenForm";
import PasswordFormInput from "components/form/input/PasswordFormInput";
import userPublicService from "services/user/UserPublicService";
import { useKeyDownEnterEvent } from "components/KeyDownHook";

const ConfirmInvitation = () => {
    const { token = "" } = useParams<{
        token: string;
    }>();
    const [ tokenInfo, setTokenInfo] = useState<Required<TokenInfo>|undefined>();

    return <CheckTokenForm tokenInfo={ tokenInfo } setTokenInfo={ setTokenInfo }
        token={ token } innerForm={ <ConfirmInvitationForm token={ token }/> }
        messageHeader={ "Confirm invitation form" }/>;
};
export default ConfirmInvitation;

const ConfirmInvitationForm = ({ token }: { token: string }) => {
    const { addToast } = useToastStore();
    const navigate = useNavigate();
    const [formError, setFormError] = useState<string | undefined>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const {
        handleSubmit,
        reset,
        formState: { errors, isDirty },
        control,
        setError
    } = useForm<ConfirmWholesalerInvitationRequest>({
        mode: "onSubmit",
        defaultValues: {
            token: token
        }
    });
    useKeyDownEnterEvent(() =>  !isLoading && handleSubmit(onSubmit)());

    const onSubmit: SubmitHandler<ConfirmWholesalerInvitationRequest> = async data => {

        if (data.password !== data.confirmPassword) {
            setFormError("Password and confirm password do not match");
            return;
        }

        setIsLoading(true);
        userPublicService.confirmInvitation(data)
            .then(() => {
                addToast({
                    message: "You successfully completed your registration",
                    header: "Invitation",
                    variant: "success",
                    timeoutMs: DEFAULT_TOASTER_TIMEOUT
                });
                navigate(AUTH_PATHS.LOGIN);
            })
            .catch(resp => {
                if (resp.status == 422) {
                    FormUtil.handleValidationError(resp.data as MessageModel, setError, setFormError);
                } else {
                    setFormError("Something went wrong. Please try again");
                }
            }).finally(() => setIsLoading(false));
    };

    return (
        <div className="registration-block">
            <div className="mb-4 mb-md-5 text-center">
                <h1 className="text-primary mb-4">Registration Form</h1>
                <p className="mb-0">In order to create a new account, you must complete the following form.</p>
            </div>
            <div className="login-block">
                <form onSubmit={ handleSubmit(onSubmit) } className="card">
                    <div className="card-body">

                        <Input control={ control } errors={ errors } name="firstName"
                            label="First Name *"
                            placeholder="First Name"
                            type="text"
                            rules={ FormUtil.NAME_VALIDATIONS }
                            required
                        />

                        <Input control={ control } errors={ errors } name="lastName"
                            label="Last Name *"
                            placeholder="Last Name"
                            type="text"
                            rules={ FormUtil.NAME_VALIDATIONS }
                            required
                        />

                        <PhoneNumberInput control={ control } errors={ errors } name="phone"
                            label="Phone *"
                            required
                        />

                        <PasswordFormInput control={ control } errors={ errors } name="password"
                            label="Password *"
                            placeholder="Enter password"
                            autoComplete="new-password"
                        />

                        <PasswordFormInput control={ control } errors={ errors } name="confirmPassword"
                            label="Confirm Password *"
                            placeholder="Confirm password"
                            autoComplete="new-password"
                        />

                        { formError &&
                            <div className="invalid-message alert alert-sm alert-danger">
                                <ul>
                                    <li>{ formError }</li>
                                </ul>
                            </div>
                        }

                        <div className="row justify-content-center mt-4">
                            <div className="col-6 col-md-5 col-lg-5 mt-1">
                                <div className="pe-md-1">
                                    <button className="btn btn-outline-secondary w-100" onClick={ () => reset() }>
                                        Clear Form
                                    </button>
                                </div>
                            </div>
                            <div className="col-6 col-md-5 col-lg-5 mt-1">
                                <div className="ps-md-1">
                                    <input
                                        className="btn btn-primary w-100"
                                        type="submit"
                                        value="Submit"
                                        disabled={ !isDirty }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

