import React, { ClipboardEvent, KeyboardEvent } from "react";
import { Controller, useFormContext } from "react-hook-form";
import BaseInput from "components/form/BaseInput";

const restrictionPattern = /^[\dA-Za-z-, ]+$/;
const PERMIT_ID_PATTERN  = /^[\dA-Za-z-]+$/;
const SERIAL_NUMBER_PATTERN = /^\d{7}$/;
const CONTAINS_TWO_DASHES_PATTERN = /^.*-.*-.*$/;

export const arrayToString = (v: string[] | null | undefined) => v === null || v === undefined ? "N" : v.toString();
const stringToArray = (s: string ): string[] => {
    s = s.trim();
    if (s === "" || s === "N" || s === "n"){
        return ["N"];
    }
    return s.split(",").map(j=>j.trim()).filter(isValidSerialNumberOrPermitId);
};

const isValidSerialNumberOrPermitId = (s: string)=>{
    if (s === null || s.length < 7 ){
        return false;
    }
    else {
        return s.length == 7
            ? s.match(SERIAL_NUMBER_PATTERN)!=null //serial number
            : (s.match(PERMIT_ID_PATTERN)!=null) && (s.match(CONTAINS_TWO_DASHES_PATTERN)!=null) ; //license id
    }
};

export const DistribIdInput = () =>{
    const { control, setValue, formState:{ errors } } = useFormContext();

    const restrictKeypress = (e:KeyboardEvent)=>{
        if (!restrictionPattern.test(e.key)) {
            e.preventDefault();
        }
    };

    const restrictPaste = (e:ClipboardEvent)=>{
        const paste = e.clipboardData.getData("Text");
        if (!restrictionPattern.test(paste)) {
            e.preventDefault();
        }
    };

    return <Controller
        name="distrib_id"
        control={ control }
        render={ ({ field: { onChange, value, ref, name } }) => (
            <BaseInput
                type="text" label="Authorized NYS distributor license #'s *" placeholder="Enter authorized NYS distributor license #'s"
                errors={ errors }
                onKeyDown={ restrictKeypress }
                onPaste={ restrictPaste }
                onBeforeBlur={ e => setValue(name, stringToArray(e.target.value)) }
                onChange={ onChange }
                value= { arrayToString(value) }
                name={ name }
                ref= { ref }
            />
        ) }
    />;

};
