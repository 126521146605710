import React from "react";
import { modalStore } from "store/ModalStoreProvider";
import { LicenseGroupService } from "services/admin/LicenseGroupService";

export const showDeleteLicenseGroupDialog = (id: number, permit_id:string, onDelete: (isDeleted: boolean)=>void,
    service: LicenseGroupService,entityTitle: string) => {
    modalStore.setHeader("Delete Master Filer");
    modalStore.setBody(<DeleteLicenseGroupDialog id={ id } onDelete={ onDelete } service={ service } entityTitle={ entityTitle } permit_id={ permit_id }/>);
    modalStore.showModal();
};

interface Props {
    id: number; // license id
    onDelete: (isDeleted: boolean)=>void,
    service: LicenseGroupService,
    entityTitle: string,
    permit_id: string
}

const DeleteLicenseGroupDialog = ({ id, onDelete, service, entityTitle, permit_id }: Props) => {

    const deleteMasterFiler = (id: number, onDelete: any) =>
        service.delete(id)
            .then((response) => {
                onDelete(response);
            })
            .finally(() => modalStore.hideModal());

    modalStore.setAdditionalButtons([
        <button key={ 0 } type="button" className="btn btn-primary" onClick={ ()=> deleteMasterFiler(id, onDelete) }>Yes</button>
    ]);

    return <div>
        <p className="mb-0">
            You are going to delete { entityTitle } <strong>{ permit_id }</strong>. Are you sure?
        </p>
    </div>;
};
