import React, { useState } from "react";
import Spinner from "components/Spinner";
import { modalStore } from "store/ModalStoreProvider";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";
import { useToastStore } from "store/ToastStoreProvider";
import { PrincipalModel } from "models/PrincipalModel";
import PrincipalService from "services/admin/PrincipalService";

interface Props {
    principal: PrincipalModel;
    onSuccessDelete: any;
}

export const showDeletePrincipalDialog = (principal: PrincipalModel, onPrincipalDelete: any) => {
    modalStore.setBody(<DeletePrincipalDialog principal={ principal } onSuccessDelete={ onPrincipalDelete } />);
    modalStore.setHeader("Delete Principal");
    modalStore.showModal();
};

const DeletePrincipalDialog = ({ principal, onSuccessDelete }: Props) => {
    const { addToast, addDefaultDangerToast } = useToastStore();
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);

    function deletePrincipal() {
        setIsDataLoading(true);
        PrincipalService.delete(principal.id).then(() => {
            addToast({
                message:"Selected principal has been removed from the system.",
                header: "Delete Principal",
                variant: "success",
                timeoutMs: DEFAULT_TOASTER_TIMEOUT
            });
            onSuccessDelete();
        }).catch(() => addDefaultDangerToast("Delete Principal")
        ).finally(() => {
            setIsDataLoading(false);
            modalStore.hideModal();
        });
    }
    modalStore.setAdditionalButtons([
        <button key={ 0 } type="button" className="btn btn-primary" onClick={ () => deletePrincipal() }>Yes</button>
    ]);

    return <div>
        <p className="mb-0">You are going to delete principal { principal.full_name }. Are you sure?</p>
        <Spinner className="mt-4 pt-2 text-center" hidden={ !isDataLoading }/>
    </div>;
};
