import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { UserLicenseDTO, UserModel } from "models/UserModel";
import { ShortLicenseInfo, licenseTitle } from "models/license.model";
import AddLicenseWithRoles from "pages/admin/components/AddLicenseWithRoles";
import EditLicensesTable from "pages/admin/components/EditLicensesTable";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useToastStore } from "store/ToastStoreProvider";

/*
    Can operate in 2 modes:
    - single license mode - (when "licenseIdForSingleMode" is set)
        -- BA use only this mode
        -- Add license control is hidden
    - unrestricted mode
        -- intended for SLA_ADMIN / ADMIN
*/

export type EditUserLicensesDialogProps = {
    licenseIdForSingleMode?: number
};

const EditUserLicensesDialog = ({ licenseIdForSingleMode }: EditUserLicensesDialogProps) => {

    const { control, getValues, formState: { errors } } = useFormContext<UserModel>();

    const {
        append,
        remove,
        fields } = useFieldArray({ name: "licenses", control: control, keyName: "_id" });

    const [ showDeleteModal, setShowDeleteModal] = useState(false);
    const [ licenseToDelete, setLicenseToDelete] = useState<ShortLicenseInfo>();
    const { addToast } = useToastStore();

    const tryAddLicense = (lic:ShortLicenseInfo, roles: string[]) => {
        const licenseIds = getValues("licenses")?.map(ul=>ul.id) || [];
        if ( !licenseIds.some(id=> id == lic.id)){
            append({ ...lic, roles: roles });
            return true;
        } else {
            addToast({ header: "Warning"
                , message: "This user already has this license. Feel free to update it or assign a different one."
                , variant: "warning"
            });
        }
        return false;
    };

    const showDeleteConfirmationDialog = (ul : UserLicenseDTO) => {
        setLicenseToDelete(ul);
        setShowDeleteModal(true);
    };

    const deleteLicense = () => {
        if (licenseToDelete){
            const idx = getValues("licenses")?.findIndex(ul => ul.id == licenseToDelete.id);
            if (idx !== undefined && idx > -1){
                remove(idx);
            }
        }
        setShowDeleteModal(false);
    };

    return <>
        <div className="edit-user-license pb-4">
            { licenseIdForSingleMode
                ? <EditLicensesTable control={ control } errors={ errors } fields={ fields as unknown as UserLicenseDTO[] } licenseIdForSingleMode={ licenseIdForSingleMode } />
                : <EditLicensesTable control={ control } errors={ errors } fields={ fields as unknown as UserLicenseDTO[] } deleteLicense={ showDeleteConfirmationDialog } />
            }
            { !licenseIdForSingleMode &&
                <AddLicenseWithRoles tryAddLicense={ tryAddLicense } />
            }

            <Modal show={ showDeleteModal } onHide={ ()=> setShowDeleteModal(false) }>
                <Modal.Header closeButton>
                    <Modal.Title>Delete License</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="mb-0">Are you sure you want to delete the <b>{ licenseToDelete ? licenseTitle(licenseToDelete) : "" }</b> license?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-outline-secondary" onClick={ () => setShowDeleteModal(false) }>Cancel</button>
                    <button type="button" className="btn btn-primary" onClick={ ()=>deleteLicense() }>Yes, delete</button>
                </Modal.Footer>
            </Modal>
        </div>
    </>;
};

export default EditUserLicensesDialog;


