import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Input from "components/form/Input";
import Form from "react-bootstrap/Form";
import CrudProductService from "services/wholesaler/priceposting/CrudProductService";
import { ProductModel } from "models/ProductModel";
import PackageTypeConstants from "services/PackageTypeConstants";
import FormUtil from "utils/FormUtil";
import { useToastStore } from "store/ToastStoreProvider";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";
import { WHOLESALER_PATHS } from "pages/wholesaler/WholesalerRoutes";
import PricePostingTitle from "components/PricePostingTitle";
import { useKeyDownEnterEvent } from "components/KeyDownHook";

interface AmendFormModel {
    license: string;
    itemNumber: string;
}

export const AmendPage = () => {
    const { addToast } = useToastStore();
    const navigate = useNavigate();
    const { id = "-1", postType= "", year = "", month = "" } = useParams<{
        id: string;
        postType: string;
        year: string;
        month: string;
    }>();
    const [product, setProduct] = useState<ProductModel>();
    const [showCompetitor, setShowCompetitor] = useState<boolean>();
    const [notFoundCompetitor, setNotFoundCompetitor] = useState<boolean>();

    const [foundName, setFoundName] = useState<string>();
    const [foundItem, setFoundItem] = useState<string>();
    const [foundPrice, setFoundPrice] = useState<number>();
    const [foundPriceNyc, setFoundPriceNyc] = useState<number>();
    const [foundPriceBottle, setFoundPriceBottle] = useState<number>();
    const [foundPriceNycBottle, setFoundPriceNycBottle] = useState<number>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const {
        handleSubmit,
        watch,
        control
    } = useForm<AmendFormModel>({
        mode: "onSubmit",
        defaultValues: {}
    });
    useKeyDownEnterEvent(() => !isLoading && handleSubmit(onSubmit)());

    const license = watch("license");
    const itemNumber = watch("itemNumber");

    useEffect(()=>{
        setIsLoading(true);
        CrudProductService.get(id, postType, year, month).then( setProduct ).finally(() => setIsLoading(false));
    },[]);

    const onSubmit: SubmitHandler<AmendFormModel> = async data => {
        setIsLoading(true);
        CrudProductService.findCompetitorProduct(data.license, data.itemNumber, postType, year, month).then(response => {
            if (Object.keys(response).length == 0) {
                setShowCompetitor(false);
                setNotFoundCompetitor(true);
            } else {
                setShowCompetitor(true);
                setNotFoundCompetitor(false);
            }

            setFoundName(response.prod_name);
            setFoundItem(response.prod_item);
            setFoundPrice(response.case_price);
            setFoundPriceNyc(response.case_nyc);
            setFoundPriceBottle(response.bot_price);
            setFoundPriceNycBottle(response.bot_nyc);

        }).catch(() => {
            setShowCompetitor(false);
            setNotFoundCompetitor(true);
        }).finally(() => setIsLoading(false));
    };

    function applyPrice() {
        CrudProductService.applyCompetitorPrice(license, itemNumber || "", id, postType, year, month)
            .then(() => CrudProductService.get(id, postType, year, month))
            .then( setProduct )
            .then(() => addToast({
                header: "Amend product price",
                message:"The product price was amended successfully",
                variant: "success",
                timeoutMs: DEFAULT_TOASTER_TIMEOUT
            }))
            .catch(() => addToast({
                header: "Amend product price",
                message:"Prices and Discounts were not applied because the competing prices are higher or the same as yours.",
                variant: "danger"
            }));
    }

    const returnToManageBrandsAndItems = ()=>
        navigate(generatePath(WHOLESALER_PATHS.PRICE_POSTING.MANAGE_BRANDS_ITEMS, { "type":postType, "year":year, "month": month }));


    return <div className="d-flex flex-column">
        <PricePostingTitle type={ postType } year={  year } month={ month } />
        <div>
            <div className="mb-3 mb-md-4 d-flex flex-row flex-wrap align-items-center">
                <h4 className="mb-2 me-auto">Amend prices for { product?.brand_name } / { product?.prod_name }</h4>
            </div>
            <div className="card mt-4">
                <div className="card-body pb-2">
                    <div className="mb-4">
                        <h4>Competitor&apos;s item information</h4>
                        <p className="mb-0">Enter competitor&apos;s license id, item number and
                            click &quot;Search&quot;. If you wish to amend your prices to match this competitor, then
                            click &quot;Apply&quot;.</p>
                    </div>
                    <form onSubmit={ handleSubmit(onSubmit) } className="row align-items-end">
                        <div className="col-12 col-xl-6">
                            <div className="pe-xl-4">
                                <div className="row">
                                    <div className="col-12 col-xl-6">
                                        <Input name="license" control={ control }
                                            placeholder="Enter license id or serial number" label="License ID"
                                            type="text"/>
                                    </div>
                                    <div className="col-12 col-xl-6">
                                        <Input name="itemNumber" control={ control } placeholder="Enter item number"
                                            label="Item number" type="text"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="ps-xl-4">
                                <input type="submit" className="btn btn-primary mb-3" value={ "Search" }/>
                            </div>
                        </div>
                        { notFoundCompetitor && <div className="col-12 col-xl-6">
                            <p>We&apos;re sorry, but we couldn&apos;t find any results that match your search. Please
                                try again with different search terms, or contact our support team for further
                                assistance.</p>
                        </div>
                        }
                    </form>

                    { showCompetitor && <div className="ps-xl-4">
                        <div className="row">
                            <div className="col-6 col-md-3">
                                <label className="form-label" htmlFor="Price33">Name</label>
                                <div className="input-group mb-3">
                                    <input id="Price33" type="text" className="form-control" disabled
                                        defaultValue={ foundName }/>
                                </div>
                            </div>
                            <div className="col-6 col-md-3">
                                <label className="form-label" htmlFor="Price44">Item</label>
                                <div className="input-group mb-3">
                                    <input id="Price44" type="text" className="form-control" disabled
                                        defaultValue={ foundItem }/>
                                </div>
                            </div>
                            <div className="col-6 col-md-3">
                                <label className="form-label" htmlFor="Price55">Price per case *</label>
                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="Price55">$</span>
                                    <input id="Price55" type="number" className="form-control" disabled
                                        defaultValue={ foundPrice }/>
                                </div>
                            </div>
                            <div className="col-6 col-md-3">
                                <label className="form-label" htmlFor="Price66">NYC Price per case</label>
                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="Price66">$</span>
                                    <input type="number" className="form-control" disabled
                                        defaultValue={ foundPriceNyc }/>
                                </div>
                            </div>

                            <div className="col-12 col-md-6">
                            </div>

                            <div className="col-6 col-md-3">
                                <label className="form-label" htmlFor="Price555">Price per bottle *</label>
                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="Price555">$</span>
                                    <input type="number" className="form-control" disabled
                                        defaultValue={ foundPriceBottle }/>
                                </div>
                            </div>
                            <div className="col-6 col-md-3">
                                <label className="form-label" htmlFor="Price666">NYC Price per bottle</label>
                                <div className="input-group mb-3">
                                    <span className="input-group-text" id="Price666">$</span>
                                    <input type="number" className="form-control" disabled
                                        defaultValue={ foundPriceNycBottle }/>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>

            <div className="row justify-content-center mt-2" id="CompetitorsItemInfo">
                <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-4">
                    <button className="btn btn-outline-secondary w-100" onClick={ returnToManageBrandsAndItems }>Cancel
                    </button>
                </div>
                <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-4">
                    <button className="btn btn-primary w-100" disabled={ !(license && itemNumber) }
                        onClick={ applyPrice }>Apply
                    </button>
                </div>
            </div>

            <div className="card mt-3">
                <div className="card-body pb-2">
                    <h4 className="mb-4">Brand information</h4>
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <div className="pe-xl-4">
                                <div className="mb-3">
                                    <label className="form-label" htmlFor="brand_name"> Brand name * </label>
                                    <input type="text" className="form-control" disabled
                                        aria-describedby="brand_nameFeedback" id="brand_name"
                                        defaultValue={ product?.brand_name }/>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="ps-xl-4">
                                <div className="row">
                                    <div className="col-12 col-lg-6 col-xl-5">
                                        <div className="mb-3">
                                            <label htmlFor="reg_combo" className="form-label"> Package type </label>
                                            <input type="text" className="form-control" disabled id="reg_combo"
                                                defaultValue={ PackageTypeConstants.getTitleByCode(product?.reg_combo.trim() || "") }/>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 col-xl-7">
                                        <div className="mb-3">
                                            <label htmlFor="bev_type" className="form-label"> Type of beverage
                                                * </label>
                                            <input type="text" className="form-control" disabled id="bev_type"
                                                defaultValue={ FormUtil.bevTypeToName(product?.bev_type.trim() || "") }/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <div className="pe-xl-4">
                                <div className="row">
                                    <div className="col-12 col-lg-6 col-xl-6">
                                        <div className="form-label-with-tooltip">
                                            <label className="form-label" htmlFor="brand_reg">Approval/BLR
                                                number*</label>
                                            <div className="tooltip-wr tooltip-wr-small ms-2">
                                                <span className="tooltip-icon bi-question-circle-fill"></span>
                                                <div className="tooltip-msg text-center">
                                                    <p>Enter either the approval # in this format: NY-XXXXXXXX-XXXX, or
                                                        the regular 7-digit NYS BLR (Brand Label Registration) Number,
                                                        or P (for Pending)</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <input id="brand_reg" disabled type="text" className="form-control"
                                                aria-describedby="brand_regFeedback"
                                                defaultValue={ product?.brand_reg }/>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 col-xl-6">
                                        <div className="form-label-with-tooltip">
                                            <label className="form-label" htmlFor="ttb_id">TTB / CoLA ID number</label>
                                            <div className="tooltip-wr tooltip-wr-small ms-2">
                                                <span className="tooltip-icon bi-question-circle-fill"></span>
                                                <div className="tooltip-msg text-center">
                                                    <p>P for Pending may only be used for the first two posting periods
                                                        while the TTB or BLR number is pending approval</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <input id="ttb_id" disabled type="text" className="form-control"
                                                aria-describedby="ttb_idFeedback" defaultValue={ product?.ttb_id }/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="ps-xl-4">
                                <div className="row">
                                    <div className="col-12 col-lg-6 col-xl-5">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="proof"> Proof * </label>
                                            <input id="proof" type="number" disabled className="form-control"
                                                aria-describedby="proofFeedback" defaultValue={ product?.proof }/>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 col-xl-7">
                                        <div className="form-label-with-tooltip">
                                            <label className="form-label" htmlFor="custom-switch">Age *</label>
                                            <div className="tooltip-wr tooltip-wr-small ms-2">
                                                <span className="tooltip-icon bi-question-circle-fill"></span>
                                                <div className="tooltip-msg text-center">
                                                    <p>Some text here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="switch-year">
                                            <label className="form-check-label disabled me-2" htmlFor="vintage-ac-id">Not
                                                applicable</label>
                                            <div className="form-check form-switch">
                                                <input type="checkbox" id="vintage-ac-id" disabled
                                                    className="form-check-input"
                                                    checked={ product?.vintage.trim() !== "VINTAGE_NA" }/>
                                                <label title="" htmlFor="vintage-ac-id"
                                                    className="form-check-label">Year</label>
                                            </div>
                                            <div className="wp-80 input-year flex-lg-fill ms-2">
                                                <input className="form-control " type="text" disabled
                                                    defaultValue={ product?.vintage.trim() }/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mt-4">
                <div className="card-body pb-2">
                    <h4 className="mb-4">Item(s) and discounts information</h4>
                    <div className="">
                        <div className="nav nav-pills btn-group flex-column flex-lg-row d-lg-inline-flex mb-4"
                            id="nav-tab" role="tablist" aria-orientation="vertical">
                            <button className="btn btn-outline-secondary min-wp-230 active" id="nav-1-tab"
                                data-bs-toggle="tab" data-bs-target="#nav-1" type="button" role="tab"
                                aria-controls="nav-1" aria-selected="false">Item info
                            </button>
                            <button className="btn btn-outline-secondary min-wp-230" id="nav-2-tab" data-bs-toggle="tab"
                                data-bs-target="#nav-2" type="button" role="tab" aria-controls="nav-2"
                                aria-selected="false">Discounts
                            </button>
                        </div>

                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-1" role="tabpanel"
                                aria-labelledby="nav-1-tab">
                                <div className="pb-3 mx--row">
                                    <div className="row">
                                        <div className="col-12 col-xl-6">
                                            <div className="pe-xl-4">
                                                <Input name="description" label="Description *" type="text" disabled
                                                    value={ product?.prod_name }/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-6">
                                            <div className="ps-xl-4">
                                                <div className="row">
                                                    <div className="col-6 col-md-6">
                                                        <div className="mb-3">
                                                            <label className="form-label">Your item number *</label>
                                                            <input disabled type="text" className="form-control"
                                                                defaultValue={ product?.prod_item }/>
                                                        </div>
                                                    </div>
                                                    <div className="col-3 col-md-6 d-flex flex-row">
                                                        <div className="flex-grow">
                                                            <div className="mb-3">
                                                                <label className="form-label">Size / Unit *</label>
                                                                <input disabled type="number" className="form-control"
                                                                    defaultValue={ product?.item_size }/>
                                                            </div>
                                                        </div>
                                                        <div className="wp-130 ms-3">
                                                            <div className="mb-3">
                                                                <label htmlFor="items[0].um"
                                                                    className="form-label"> &nbsp; </label>
                                                                <input disabled type="text" className="form-control"
                                                                    defaultValue={ product?.um.trim() }/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="pe-xl-4">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <label htmlFor="number" className="form-label">Number of bottles
                                                            per *</label>
                                                    </div>
                                                    <div className="col-12 col-lg-6 form-group-without-label">
                                                        <Input name="number" label="" required type="text" disabled
                                                            value={ product?.botpercase }/>
                                                    </div>
                                                    <div className="col-12 col-lg-6 form-group-without-label">
                                                        <Input name="number" label="" required type="number" disabled
                                                            value={ product?.subpack }/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="ps-xl-4">
                                                <div className="mb-3">
                                                    <p className="mb-2">
                                                        <strong>Case only</strong>
                                                    </p>
                                                    <div className="switch-year pt-1">
                                                        <label className="form-check-label disabled me-2"
                                                            htmlFor="custom-switch">No</label>
                                                        <Form.Switch
                                                            type="switch"
                                                            id="custom-switch"
                                                            label="Yes"
                                                            checked={ product?.fullcase.trim() === "Y" }
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-xl-6">
                                            <div className="pe-xl-4">
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <label className="form-label" htmlFor="Price3">Price per bottle
                                                            *</label>
                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text" id="Price3">$</span>
                                                            <input type="number" className="form-control" disabled
                                                                defaultValue={ product?.bot_price }/>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label className="form-label" htmlFor="Price4">NYC Price per
                                                            bottle</label>
                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text" id="Price4">$</span>
                                                            <input type="number" className="form-control" disabled
                                                                defaultValue={ product?.bot_nyc }/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-6">
                                            <div className="ps-xl-4">
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <label className="form-label" htmlFor="Price3">Price per case
                                                            *</label>
                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text" id="Price3">$</span>
                                                            <input type="number" className="form-control" disabled
                                                                defaultValue={ product?.case_price }/>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label className="form-label" htmlFor="Price4">NYC Price per
                                                            case</label>
                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text" id="Price4">$</span>
                                                            <input type="number" className="form-control" disabled
                                                                defaultValue={ product?.case_nyc }/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-6">
                                            <div className="pe-xl-4">
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <div className="mb-3">
                                                            <label className="form-label"
                                                                htmlFor="items[0].split_charge"> BT / SLV Split Case
                                                                Chg </label>
                                                            <div className="input-group has-validation">
                                                                <span className="input-group-text">$</span>
                                                                <input className="form-control" disabled type="number"
                                                                    defaultValue={ product?.split_charge }/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="mb-3">
                                                            <p className="mb-2">
                                                                <strong>NYS label</strong>
                                                            </p>
                                                            <div className="switch-year pt-1">
                                                                <label className="form-check-label disabled me-2"
                                                                    htmlFor="nysprod0">No</label>
                                                                <div className="form-check form-switch">
                                                                    <input type="checkbox" id="nysprod0" disabled
                                                                        className="form-check-input"
                                                                        checked={ product?.nys_prod.trim() === "Y" }/>
                                                                    <label htmlFor="nysprod0"
                                                                        className="form-check-label">Yes</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-6">
                                            <div className="ps-xl-4">
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <Input name="serial-number" label="NYS supplier*" type="text"
                                                            disabled value={ product?.nys_whole }/>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <Input name="item-number" label="NYS supplier item number*"
                                                            type="number" disabled value={ product?.nys_item }/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-xl-6">
                                            <div className="pe-xl-4">
                                                <div className="form-check mb-3 mt-3">
                                                    <input id="CheckDefault" type="checkbox"
                                                        className="form-check-input"
                                                        checked={ product?.lim_avail.trim() === "L" } disabled/>
                                                    <label htmlFor="CheckDefault" className="form-check-label">Limited
                                                        Availability</label>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="Method" className="form-label">Allocation
                                                        Method</label>
                                                    <input disabled type="text" className="form-control"
                                                        defaultValue={ product?.alloc_met.trim() ? "Method " + product?.alloc_met.trim() : "N/A" }/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-6">
                                            <div className="ps-xl-4">
                                                <div className="form-group">
                                                    <label htmlFor="Textarea" className="form-label">Allocation
                                                        Description</label>
                                                    <textarea rows={ 4 } id="Textarea" className="form-control" disabled>
                                                        { product?.alloc_met }
                                                    </textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-2" role="tabpanel" aria-labelledby="nav-2-tab">
                                <div className="mb-4 pb-2">
                                    <div className="d-block">
                                        <form action="">
                                            <div className="row">
                                                <div className="col-12 col-xl-6">
                                                    <div className="ps-xl-4">
                                                        <div className="mb-3 mb-xl-0">
                                                            <p className="mb-2">
                                                                <strong>Discounts by</strong>
                                                            </p>
                                                            <div className="switch-year pt-1">
                                                                <label className="form-check-label disabled me-2"
                                                                    htmlFor="custom-switch">Case</label>
                                                                <Form.Switch
                                                                    type="switch"
                                                                    id="custom-switch"
                                                                    label="Bottle"
                                                                    checked={ product?.fullcase === "Y" }
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        { product?.discounts_json && JSON.parse(product?.discounts_json?.value).map((d: Map<string, string | number>, index: number) => {
                                            const disc = new Map(Object.entries(d));
                                            return <div className="row" key={ "disc-" + (index + 1) }>
                                                <div className="col-12 col-lg-4 mt-4">
                                                    <div className="card bg-white">
                                                        <div className="card-body pb-2 pt-3">
                                                            <div className="styleguide-head mb-4">
                                                                <div
                                                                    className="d-flex flex-row align-items-center mb-2">
                                                                    <h5 className="me-auto mb-0">Discount { index + 1 }</h5>
                                                                </div>
                                                                <hr className="mt-1"/>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor="Type"
                                                                            className="form-label">Type</label>
                                                                        <input id="Type" className="form-select"
                                                                            disabled
                                                                            defaultValue={ disc.get("type") || "" }/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                    <Input name="amount" label="Amount" type="number"
                                                                        disabled value={ disc.get("amount") || "" }/>
                                                                </div>
                                                                <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                    <Input name="on" label="On" type="number" disabled
                                                                        value={ disc.get("quantity") }/>
                                                                </div>
                                                                <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                    <div className="form-group">
                                                                        <Input name="Units" label="Units" type="text"
                                                                            disabled
                                                                            value={ disc.get("unit") === "C" ? "Case" : "Bottle" }/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>;
                                        })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

