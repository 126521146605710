import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useTimeStore } from "store/TimeStoreProvider";
import dayjs, { Dayjs } from "dayjs";
import ReportsService from "services/admin/ReportService";
import { useToastStore } from "store/ToastStoreProvider";
import Spinner from "components/Spinner";
import ProductConstants, { PostTypeCode } from "services/ProductConstants";
import { Link } from "react-router-dom";

export interface CountModel {
    totals: CountPostModel[],
    pending: CountPostModel[],
    missing: number
}

export interface CountPostModel {
    code: PostTypeCode,
    count: number
}

export enum Type {
    TOTAL,
    PENDING,
    MISSING
}

const Reports = () => {

    const { serverTime } = useTimeStore();
    const { addDefaultDangerToast } = useToastStore();

    const [selectedDate, setSelectedDate] = useState<Dayjs | undefined>();
    const [fourMonthBefore, setFourMonthBefore] = useState<Dayjs | undefined>();
    const [counts, setCounts] = useState<CountModel>();
    const [isLoaded, setIsLoaded] = useState<boolean>(true);

    useEffect(() => {
        selectedDate && setFourMonthBefore(dayjs(selectedDate).add(-3,"month"));
        selectedDate && getReportCounts();
    }, [selectedDate]);

    function getReportCounts() {
        setIsLoaded(false);
        selectedDate && ReportsService.getCounts(selectedDate).then((response: CountModel) => {
            setCounts(response);
        }).catch(() => addDefaultDangerToast("Admin reports")
        ).finally(() => setIsLoaded(true));
    }

    function getDownloadUrl(type: Type, postType: string): string {
        return ReportsService.getDownloadUrl(type, postType, selectedDate ?? dayjs());
    }

    return <>
        <div className="mb-4 mb-md-5">
            <h1 className="text-primary mb-4">Admin Reports</h1>
            <p>Please choose the date for your report and select report that you want to download</p>
        </div>
        <div className="card mb-4">
            <div className="card-body">
                <div className="row">
                    <div className="col-12 col-md-6 col-xl-5 col-xxl-3">
                        <div className="d-flex flex-column input-calendar">
                            <label className="form-label" htmlFor="data-picker">Select data</label>
                            <DatePicker
                                id="data-picker"
                                selected={ selectedDate?.toDate() }
                                onChange={ (date:Date) => setSelectedDate(dayjs(date)) }
                                dateFormat="MM/yyyy"
                                placeholderText="Month / Year"
                                showMonthYearPicker
                                autoComplete="off"
                                minDate={ serverTime.add(-3, "year").toDate() }
                                maxDate={ serverTime.date() > 15 ? serverTime.add(1, "month").toDate() : serverTime.toDate() }
                            />
                            <i className="bi-calendar-week text-primary"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Spinner hidden={ isLoaded }/>
        { isLoaded && selectedDate && fourMonthBefore &&
            <div>
                <div className="mt-5">
                    <h4 className="mb-3">Schedule totals by license</h4>
                    <div className="content-styles">
                        <ul>
                            <li>
                                <Link to={ getDownloadUrl(Type.TOTAL, ProductConstants.POST_TYPES.WR.code) } target="_blank" download>
                                    Wine to Retail ({ counts?.totals.find(c => c.code === ProductConstants.POST_TYPES.WR.code)?.count })
                                </Link>
                            </li>
                            <li>
                                <Link to={ getDownloadUrl(Type.TOTAL, ProductConstants.POST_TYPES.WW.code) } target="_blank" download>
                                    Wine to Wholesaler ({ counts?.totals.find(c => c.code === ProductConstants.POST_TYPES.WW.code)?.count })
                                </Link>
                            </li>
                            <li>
                                <Link to={ getDownloadUrl(Type.TOTAL, ProductConstants.POST_TYPES.LR.code) } target="_blank" download>
                                    Liquer to Retail ({ counts?.totals.find(c => c.code === ProductConstants.POST_TYPES.LR.code)?.count })
                                </Link>
                            </li>
                            <li>
                                <Link to={ getDownloadUrl(Type.TOTAL, ProductConstants.POST_TYPES.LW.code) } target="_blank" download>
                                    Liquer to Wholesaler ({ counts?.totals.find(c => c.code === ProductConstants.POST_TYPES.LW.code)?.count })
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="mt-5">
                    <h4 className="mb-3"> Pending brand registration -&nbsp;
                        { fourMonthBefore.format("MMM") + ". " + selectedDate.year() } /
                        { selectedDate.format("MMM") + ". " + selectedDate.year() }</h4>
                    <div className="content-styles">
                        <ul>
                            <li>
                                <Link to={ getDownloadUrl(Type.PENDING, ProductConstants.POST_TYPES.WR.code) } target="_blank" download>
                                    Wine to Retail ({ counts?.pending.find(c => c.code === ProductConstants.POST_TYPES.WR.code)?.count })
                                </Link>
                            </li>
                            <li>
                                <Link to={ getDownloadUrl(Type.PENDING, ProductConstants.POST_TYPES.WW.code) } target="_blank" download>
                                    Wine to Wholesaler ({ counts?.pending.find(c => c.code === ProductConstants.POST_TYPES.WW.code)?.count })
                                </Link>
                            </li>
                            <li>
                                <Link to={ getDownloadUrl(Type.PENDING, ProductConstants.POST_TYPES.LR.code) } target="_blank" download>
                                    Liquer to Retail ({ counts?.pending.find(c => c.code === ProductConstants.POST_TYPES.LR.code)?.count })
                                </Link>
                            </li>
                            <li>
                                <Link to={ getDownloadUrl(Type.PENDING, ProductConstants.POST_TYPES.LW.code) } target="_blank" download>
                                    Liquer to Wholesaler ({ counts?.pending.find(c => c.code === ProductConstants.POST_TYPES.LW.code)?.count })
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="mt-5">
                    <h4 className="mb-3">Licensee Missing in Current Month Schedule</h4>
                    <div className="content-styles">
                        <ul>
                            <li>
                                <Link to={ getDownloadUrl(Type.MISSING, "") } target="_blank" download>
                                    Licensee Missing in Index of Wholesaler and/or Distributors ({ counts?.missing })
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        }
    </>;
};

export default Reports;
