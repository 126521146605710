import classNames from "classnames";

export interface LoggedInUserInfo {
    authenticated: boolean;
    roles: string[];
    isAdminUnderUser: boolean;
    email: string;
    authorizedLicenseId: number;
    licenses: UserLicense[],
    hiddenAlerts: number[];
}

export type UserLicense = {
    licenseId: number;
    premiseName: string;
    tradeName?: string;
    serialNumber: number;
    permitId?: string;
    active: boolean;
}

export const licenseText = (lic?: UserLicense)=> {
    let r = `${lic?.premiseName} (${lic?.serialNumber}`;
    if (lic?.permitId){
        r += `/ ${lic?.permitId}`;
    }
    r += ")";
    if (!lic?.active){
        r += " - Inactive";
    }
    return r;
};

export const licenseClasses = (lic?: UserLicense ) => classNames({ "fw-bold": !lic?.active, "text-danger": !lic?.active });
