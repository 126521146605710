import React, { KeyboardEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import URLUtil from "utils/URLUtil";
import { DEFAULT_PAGE_LIMIT } from "api/HttpClient";
import { PageInfo } from "models/PageInfo";
import { Discount } from "models/Discount";
import Spinner from "components/Spinner";
import Paginator from "components/Paginator";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";
import { useToastStore } from "store/ToastStoreProvider";
import DiscountService from "services/wholesaler/DiscountService";
import { useModalStore } from "store/ModalStoreProvider";
import { showEditDiscountDialog } from "pages/wholesaler/priceposting/dialogs/EditDiscountDialog";
import { DiscountValuesList } from "components/DiscountValuesList";
import { useAuthStore } from "store/AuthStoreProvider";
import ErroneousValue from "components/ErroneousValue";
import Input from "components/form/Input";
import { SubmitHandler, useForm } from "react-hook-form";
import { PageableRequest } from "models/requests/PageableRequest";
import { SelectValid } from "components/form/select/SelectValid";
import { useKeyDownEnterEvent } from "components/KeyDownHook";
import { WHOLESALER_PATHS } from "pages/wholesaler/WholesalerRoutes";
import classNames from "classnames";

const dialogTitle = "Manage discounts";

type SearchParams = { code: string; status: string; } & PageInfo;

type StatesOfPage = "CHECKING_IF_USER_HAS_CODES" | "USER_HAS_NO_CODES" | "LOADING_DATA" | "DATA_DISPLAYED" | "DATA_IS_EMPTY";
type ElementsOfPage = "BUTTON_BAR"| "NO_CODES_BAR"| "SEARCH_FORM"| "LOADING_SPINNER"| "COUNT_BAR"| "TABLE";
const ELEMENTS_VISIBILITY: Record<StatesOfPage, ElementsOfPage[]> = {
    CHECKING_IF_USER_HAS_CODES: ["LOADING_SPINNER"],
    USER_HAS_NO_CODES: ["NO_CODES_BAR"],
    LOADING_DATA: ["BUTTON_BAR","SEARCH_FORM","LOADING_SPINNER"],
    DATA_DISPLAYED: ["BUTTON_BAR","SEARCH_FORM","COUNT_BAR","TABLE"],
    DATA_IS_EMPTY: ["BUTTON_BAR","SEARCH_FORM","COUNT_BAR"]
};

const ManageDiscounts = () => {

    const auth = useAuthStore();
    const { permissionStore } = auth;
    const currentLicenseIsActive = auth.currentLicense?.active;
    const { addToast, addDefaultDangerToast } = useToastStore();
    const modalStore = useModalStore();
    const location = useLocation();
    const navigate = useNavigate();
    const [ urlData, setUrlData ] = useState<SearchParams>(getUrlData);

    const [ pagesCount, setPagesCount ] = useState<number>(0);
    const [ totalCount, setTotalCount ] = useState<number>(0);
    const [ pageLimit ] = useState<number>(urlData?.limit ?? DEFAULT_PAGE_LIMIT);
    const [ pageState, setPageState ] = useState<StatesOfPage>("CHECKING_IF_USER_HAS_CODES");
    const [ data, setData ] = useState<Discount[] | undefined>([]);

    const {
        handleSubmit,
        setValue,
        control
    } = useForm<SearchParams>({
        mode: "onSubmit",
        defaultValues: {
            code: urlData.code || "",
            status: urlData.status || "All"
        }
    });
    useKeyDownEnterEvent(() => pageState != "LOADING_DATA" && handleSubmit(onSubmit)());

    useEffect(checkIfUserHasCodes,[]);

    useEffect(()=> {
        if (pageState !== "CHECKING_IF_USER_HAS_CODES") {
            performQuery(urlData);
        }
    },[urlData]);

    function checkIfUserHasCodes(){
        setPageState("CHECKING_IF_USER_HAS_CODES");
        DiscountService.list({ pageInfo: { page: 1, limit: 1 } })
            .then(response=>{
                const total = response.pageInfo.totalItemsCount ?? 0;
                if (total > 0){
                    performQuery(urlData);
                }
                else {
                    setPageState("USER_HAS_NO_CODES");
                }
            })
            .catch(()=>addDefaultDangerToast(dialogTitle));
    }

    const performQuery = (data: SearchParams) => {
        setPageState("LOADING_DATA");

        const request = {
            pageInfo: { page: urlData.page, limit: pageLimit },
            filters: URLUtil.pureUrlParameters(data)
        } as PageableRequest;

        DiscountService.list(request)
            .then(response=>{
                const total = response.pageInfo.totalItemsCount ?? 0;
                setData(response.data);
                setPagesCount(response.pageInfo.pagesCount ?? 1);
                setTotalCount(total);
                setPageState(total > 0 ? "DATA_DISPLAYED" : "DATA_IS_EMPTY");
            })
            .catch(()=>addDefaultDangerToast(dialogTitle));
    };

    function getUrlData(): SearchParams {
        const params = URLUtil.readSearchParams() as SearchParams;
        params.page = params.page ?? 1;
        params.limit = params.limit ?? DEFAULT_PAGE_LIMIT;
        updateUrlParams(params);
        return params;
    }

    function updateUrlParams(params: SearchParams) {
        URLUtil.updateUrlParams(location.pathname, params, navigate);
    }

    function openCreateDialog(){
        showEditDiscountDialog((success)=>{
            if (success){
                addToast({ header: dialogTitle,  message:"Discount was saved successfully", variant: "success", timeoutMs: DEFAULT_TOASTER_TIMEOUT });
                if (pageState == "USER_HAS_NO_CODES"){
                    checkIfUserHasCodes();
                }
                else {
                    performQuery(urlData || {} as SearchParams);
                }
            } else {
                addDefaultDangerToast(dialogTitle);
            }
        });
    }

    function openEditDialog(discount: Discount){
        showEditDiscountDialog((success) => {
            if (success){
                performQuery(urlData || {} as SearchParams);
                addToast({ header: dialogTitle,  message:"Discount was saved successfully", variant: "success", timeoutMs: DEFAULT_TOASTER_TIMEOUT });
            } else {
                addDefaultDangerToast(dialogTitle);
            }
        }, discount);
    }

    const openConfirmDeleteDialog = (discount: Discount) => {
        if (!discount.id)
            return;

        const discountId: number = discount.id;
        DiscountService.hasBoundProducts(discountId)
            .then(hasProducts=>{

                modalStore.setSize("lg");
                modalStore.setHeader("Delete discount code");
                modalStore.setBody(hasProducts
                    ? <p className="mb-0"><strong>Warning!</strong> This discount code is in use for one or more products. Deleting it will remove it from all associated products in your current working area. Are you sure you want to continue?</p>
                    : <p className="mb-0">You are going to delete discount code <strong>&quot;{ discount.discount_code }&quot;</strong>. Are you sure?</p>);
                modalStore.setAdditionalButtons([
                    <button key={ 0 } type="button" className="btn btn-primary" onClick={ ()=> deleteDiscount(discountId) }>Yes</button>
                ]);
                modalStore.showModal();
            })
            .catch(()=>addDefaultDangerToast(dialogTitle));
    };

    const deleteDiscount = (discountId: number) =>
        DiscountService.delete(discountId)
            .then(()=>{
                addToast({ header: dialogTitle,  message:"Discount was deleted successfully", variant: "success", timeoutMs: DEFAULT_TOASTER_TIMEOUT });
                checkIfUserHasCodes();
            })
            .catch(()=>addDefaultDangerToast(dialogTitle))
            .finally(()=>modalStore.hideModal());

    function handleSearch(event: KeyboardEvent) {
        if (event.key === "Enter") {
            event.preventDefault();
            document.getElementById("searchForm")?.dispatchEvent(
                new Event("submit", { bubbles: true, cancelable: true })
            );
        }
    }

    const onSubmit: SubmitHandler<SearchParams> = async data => {
        const searchParams = data as SearchParams;
        searchParams.page = 1;
        setUrlData(searchParams);
        updateUrlParams(searchParams);
    };

    function handlePageClick(pageNumber: number) {
        const searchParams = urlData;
        setUrlData({ ...urlData, page: pageNumber });
        searchParams.page = pageNumber;
        updateUrlParams(searchParams);
    }
    const visible = (el:ElementsOfPage) => ELEMENTS_VISIBILITY[pageState].includes(el);

    const resetForm = ()=>{
        setValue("code","");
        setValue("status","All");
        return false;
    };

    return <div className="d-flex flex-column">
        <div className="mb-3 mb-md-3">
            <h1 className="text-primary mb-4">Discount Codes</h1>
            <div className="d-flex flex-column align-items-center">
                <div className="mb-3 me-lg-3">
                    On this page, you can manage the Discount Codes that you have added in the system. Utilize the search function to locate specific codes and view details such as the validity period and the discount amount for each code. By selecting a Discount Code, you can access additional information about the particular discount.
                </div>
                <div className="mb-3 me-lg-3">
                    The Actions column contains two buttons: edit and delete. Use these buttons to modify the discount amount or validity period, or remove a Discount Code as required. Additionally, you can add Discount Codes either individually or in bulk using the batch uploading feature.
                </div>
                { visible("BUTTON_BAR") &&
                    <div className="d-flex flex-row ms-lg-auto" hidden={ !permissionStore.isWholesalerAndPricesCertifyOrWrite() }>
                        <button className="btn btn-primary mt-3 me-3" title="Create discount code" onClick={ openCreateDialog } disabled={ !currentLicenseIsActive }>
                            Create discount code
                        </button>
                        <a className={ classNames("btn","btn-outline-secondary","mt-3", { disabled: !currentLicenseIsActive }) } href={ WHOLESALER_PATHS.DISCOUNTS.BATCH_IMPORT } >
                            Batch Submission
                        </a>
                    </div>
                }
            </div>
        </div>

        {  visible("NO_CODES_BAR") &&
            <div className="card">
                <div className="card-body pb-1">
                    <div className="text-center">
                        <p>Looks like you don&apos;t have any discount codes. You can create your first one by clicking button below:</p>
                        <div className="form-group mt-4" hidden={ !permissionStore.isWholesalerAndPricesCertifyOrWrite() }>
                            <button className="btn btn-primary" title="Create discount code" onClick={ openCreateDialog }  disabled={ !currentLicenseIsActive }>
                                Create discount code
                            </button>
                            <span className="mx-3">or</span>
                            <a className={ classNames("btn","btn-outline-secondary", { disabled: !currentLicenseIsActive }) } href={ WHOLESALER_PATHS.DISCOUNTS.BATCH_IMPORT }>
                                Batch Submission
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        }

        { visible("SEARCH_FORM") &&
            <div className="card">
                <div className="card-body">
                    <form id="searchForm" className="" onSubmit={ handleSubmit(onSubmit) }>
                        <div className="row justify-content-center mt-2">
                            <div className="col-12 col-lg-6">
                                <Input
                                    control={ control }
                                    name="code"
                                    placeholder="Enter discount code"
                                    label="Discount code"
                                    type="text"
                                    onKeyDown={ handleSearch }
                                />
                            </div>
                            <div className="col-12 col-lg-6">
                                <SelectValid name="status" control={ control } label="Validity Status"/>
                            </div>
                        </div>

                        <div className="row justify-content-center mt-2">
                            <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                                <div className="pe-md-1">
                                    <button className="btn btn-outline-secondary w-100" type="button" onClick={ resetForm }>
                                        Clear Form
                                    </button>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                                <div className="ps-md-1">
                                    <input className="btn btn-primary w-100" type="submit" value="Search"/>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        }

        { visible("LOADING_SPINNER") && <Spinner className="mt-4 pt-2 text-center"/> }

        { visible("COUNT_BAR") &&
            <div className="d-flex flex-column flex-sm-row align-items-end justify-content-between mt-3">
                <h5 className="mb-sm-0">Found <strong>{ totalCount } discount codes</strong></h5>
            </div>
        }

        { visible("TABLE") &&
            <>
                <div className="table-responsive mt-3">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Discount Code</th>
                                <th>Description</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            { data?.map(discount=>
                                <tr key={ discount.id }>
                                    <td><ErroneousValue item={ discount } name="discount_code" /></td>
                                    <td className="pb-1">
                                        <div className="content-styles">
                                            <DiscountValuesList values={ discount.values } showTitle={ false } highlightAmount={ true } />
                                        </div>
                                    </td>
                                    <td className="text-nowrap">
                                        <button
                                            className="btn btn-link py-0 px-1 me-2" title="Edit" onClick={ () => openEditDialog(discount) }
                                            disabled={ !permissionStore.isWholesalerAndPricesCertifyOrWrite() || !currentLicenseIsActive }>
                                            <i className="bi-pencil"></i>
                                        </button>
                                        <button
                                            className="btn btn-link py-0 px-1 me-2" title="Delete" onClick={ ()=>openConfirmDeleteDialog(discount) }
                                            disabled={ !permissionStore.isWholesalerAndPricesCertifyOrWrite() || !currentLicenseIsActive }>
                                            <i className="bi-trash"></i>
                                        </button>
                                    </td>
                                </tr>
                            ) }
                        </tbody>
                    </table>
                </div>
                <Paginator totalPages={ pagesCount } currentPage={ urlData.page ?? 1 } pageClicked={ handlePageClick }/>
            </>
        }

    </div>;
};

export default ManageDiscounts;

