import httpClient from "api/HttpClient";
import BACKEND from "services/Constants";
import { SaveMasterFilerModel } from "pages/admin/models/SaveMasterFilerModel";
import { PageableResponse } from "models/response/PageableResponse";
import { LicenseModel } from "models/license.model";
import { PageableRequest } from "models/requests/PageableRequest";
import { LicenseGroupModel } from "pages/admin/models/LicenseGroupModel";
import { LicenseGroupService } from "services/admin/LicenseGroupService";

class MasterFilerService implements LicenseGroupService {

    checkLicense(license: string): Promise<LicenseModel> {
        return httpClient.get(BACKEND + "/admin/master-filer/check-license/" + license);
    }

    isPresent(): Promise<boolean> {
        return httpClient.get(BACKEND + "/admin/master-filer/check-is-present");
    }

    save(request: SaveMasterFilerModel): Promise<boolean> {
        return httpClient.post(BACKEND + "/admin/master-filer/save", request);
    }

    delete(licenseId: number): Promise<boolean> {
        return httpClient.delete(BACKEND + "/admin/master-filer/delete/" + licenseId);
    }

    lookup(request: PageableRequest): Promise<PageableResponse<LicenseGroupModel>> {
        return httpClient.search(BACKEND + "/admin/master-filer/lookup", request);
    }
}

const instance = new MasterFilerService();
export default instance;
