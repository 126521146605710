import React, { ChangeEvent, useState } from "react";
import { WHOLESALER_PATHS } from "pages/wholesaler/WholesalerRoutes";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import BatchProductImportService from "services/BatchProductImportService";
import ProductConstants, { PostTypeCode } from "services/ProductConstants";
import { useToastStore } from "store/ToastStoreProvider";
import { ProgressDialog } from "components/dialogs/ProgressDialog";
import PricePostingTitle from "components/PricePostingTitle";
import { SampleFormatsButton } from "components/SampleFormatsButton";
import { SampleXlsButton } from "components/SampleXlsButton";
import { SampleTxtButton } from "components/SampleTxtButton";
import { ImportSubject } from "models/ImportDiscriminator";
import WizardStepsBar from "components/WizardStepsBar";

const BatchProducts = () => {
    const navigate = useNavigate();
    const { addToast } = useToastStore();
    const [ selectedFile, setSelectedFile ] = useState<File>();
    const [ showProgress, setShowProgress ] = useState<boolean>(false);

    const { type = "", year= "", month= "" } = useParams();

    function handleChange(event: ChangeEvent) {
        const target: HTMLInputElement = event.target as HTMLInputElement;
        if (target !== null && target.files !== null)
            setSelectedFile(target.files[0]);
    }

    function uploadFile(){
        if (!selectedFile)
            return;

        setShowProgress(true);
        BatchProductImportService.checkLock(ProductConstants.postTypeByString(type as PostTypeCode))
            .then(result =>
                result
                    ? prepareFormData(selectedFile, type as PostTypeCode)
                    : Promise.reject("Cannot proceed, somebody is working with the license right now. Wait for some time and try again")
            )
            .then(fdata => BatchProductImportService.products(fdata))
            .then(result => {
                navigate(generatePath(WHOLESALER_PATHS.PRICE_POSTING.BATCH_IMPORT_3, {
                    "type": type,
                    "year": year,
                    "month": month
                }), { state: result });
            })
            .catch(msg => {
                setShowProgress(false);
                addToast({
                    header: "Price Posting",
                    message: (typeof msg === "string") ? msg : "Something went wrong. Wait for some time and try again",
                    variant: "danger"
                });
            });
    }

    async function prepareFormData (file: File, postType: PostTypeCode) {
        const compressed = await BatchProductImportService.compress(file, "gzip");

        const data = new FormData();
        data.append("file", compressed, file.name);
        data.append("clientDate", new Date().getTime().toString());
        data.append("type", postType);
        return data;
    }

    return <div className="d-flex flex-column">
        <PricePostingTitle type={ type } year={ year } month={ month } />
        <div className="mb-3">
            <p className="mb-0">Select CSV file with product price posting.</p>
        </div>
        <div className="mb-4">
            <SampleFormatsButton subject={ ImportSubject.PRODUCTS }/>
            <SampleXlsButton subject={ ImportSubject.PRODUCTS }/>
            <SampleTxtButton subject={ ImportSubject.PRODUCTS }/>
        </div>
        <WizardStepsBar steps={ ["Upload Price Posting file","Preview Batch Submission"] } activity={ [true,false] }/>
        <div className="content-styles">
            <p>Follow this 2-step wizard to upload your price posting file to the Liquor Authority:</p>
            <ol>
                <li><strong>Select Products CSV file:</strong> Choose the &apos;Products CSV file&apos; from your local
                    machine.
                </li>
                <li><strong>Preview Batch Submission:</strong> Review the prepared information for import.
                    Click &apos;Back to File selection&apos; to return to the first step of the wizard.
                    Click &apos;Complete Submission&apos; to complete the process and go to the posting summary page.
                </li>
            </ol>
            <p className="pt-1 mt-4 mb-2">
                <b>Please note:</b> You are in the process of submitting price posting data.
            </p>
            <p className="fw-bold">
                ⚠️ Important: The existing price posting information for this period will be overridden after this import action. This cannot be undone.
            </p>
        </div>
        <div className="card mt-4">
            <div className="card-body">
                <div className="mb-3 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                    <h4><strong>Step 1:</strong> Select Price Posting CSV file</h4>
                </div>
                <div className="form-group">
                    <label htmlFor="formFile" className="form-label">Choose file</label>
                    <input className="form-control" type="file" id="formFile" onChange={ handleChange }/>
                </div>
                <div className="row justify-content-center mt-4">
                    <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                        <button onClick={ () => navigate(-1) } className="btn btn-outline-secondary w-100" type="button">Back</button>
                    </div>
                    <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                        <button onClick={ uploadFile } className="btn btn-primary w-100" type="button">Continue</button>
                    </div>
                </div>
            </div>
        </div>
        <ProgressDialog show={ showProgress } />
    </div>;
};

export default BatchProducts;

