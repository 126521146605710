import React from "react";
import Input from "components/form/Input";
import DatePicker from "react-datepicker";

const DelinquentSearchView = () => {

    return <div className="d-flex flex-column min-vh-100">
        <div className="mb-4 mb-md-5">
            <h1 className="text-primary mb-4">Delinquent Records Search</h1>
            <div className="d-flex flex-row align-content-center">
                <p className="mb-0">Using form below you can search for Delinquent Records registered in the system</p>
                <div className="tooltip-wr ms-2">
                    <span className="tooltip-icon bi-question-circle-fill"></span>
                    <div className="tooltip-msg">
                        <p><strong>Wholesale Delinquent Listing Overview</strong></p>
                        <small>
                            <p>Pursuant to Sections 101-A, 101-AA, and/or 101-AAA of the A.B.C. Law, notice is hereby given that the following retail licensees have been reported for non-compliance with the requirements of the said sections.</p>
                            <p>All retail licensees whose names appear on this list are required to pay cash for all purchases of alcoholic beverages until their names have been removed from a subsequent list or they have received a release in writing from the State Liquor Authority.</p>
                            <p>&quot;Cash&ldquo; means and includes currency and coin of the United States of America, certified check, money order, bank officer&apos;s check or draft, or a check drawn on the account of the retail licensee payable to the manufacturer or retailer and dated no later than the date of delivery of the alcoholic beverages and which is honored by the bank upon presentment for payment.</p>
                            <p>A retail license is one who sells to a consumer or to any person other than for resale. Accordingly, &quot;Retail Licensee&ldquo; includes a vendor, railroad car, steamship, fishing vessel, ball park, drug store, summer licensee or other person or premises licensed to sell liquor, wine or beer at retail.</p>
                        </small>
                    </div>
                </div>
            </div>
        </div>

        <div className="card">
            <div className="card-body">
                <form className="">
                    <div className="row">
                        <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group">
                                <label htmlFor="Select" className="form-label">Select License Type</label>
                                <select id="Select" className="form-select" aria-label="Select">
                                    <option selected>All</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group">
                                <label htmlFor="Select2" className="form-label">Premises</label>
                                <select id="Select2" className="form-select" aria-label="Select">
                                    <option selected>All</option>
                                    <option value="1">Added</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group">
                                <label htmlFor="Select3" className="form-label">From</label>
                                <DatePicker
                                    selected={ new Date() }
                                    onChange={ (date:Date) => { date; } }
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                            <div className="form-group">
                                <label htmlFor="Select4" className="form-label">To</label>
                                <DatePicker
                                    selected={ new Date() }
                                    onChange={ (date:Date) => { date; } }
                                    dateFormat="dd/MM/yyyy"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-2">
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                            <button className="btn btn-outline-secondary w-100">Clear Form</button>
                        </div>
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                            <input className="btn btn-primary w-100" type="submit" value="Search" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div className="mt-4 mb-2 d-flex flex-column flex-lg-row align-items-center">
            <div className="me-lg-auto pt-3">
                <h5>Found <strong>150 records</strong> matching search criteria</h5>
                <p className="mb-0">3475 licenses were added from the delinquent list from <b>&quot;Start date&ldquo;</b> to <b>&quot;End date&ldquo;</b></p>
            </div>
            <div className="pt-3 ms-lg-3">
                <a className="btn btn-secondary" href="/">
                    <i className="bi-download me-2"></i>Download CSV
                </a>
            </div>
        </div>
        <div className="table-responsive mt-4">
            <table className="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th>Serial #</th>
                        <th>License Class</th>
                        <th>License Type</th>
                        <th>Premises Name</th>
                        <th>Principal Name</th>
                        <th>Address</th>
                        <th>County</th>
                        <th>City, State, Zip</th>
                        <th>License Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <a href="/" data-bs-toggle="modal" data-bs-target="#exampleModal">21788775</a>
                        </td>
                        <td>252</td>
                        <td>OP</td>
                        <td>
                            <a href="/" data-bs-toggle="modal" data-bs-target="#exampleModal">Some long premises name</a>
                        </td>
                        <td>Discount liquors</td>
                        <td>Some long address somewhere</td>
                        <td>Essex</td>
                        <td>12</td>
                        <td>
                            <span className="badge bg-success">
                                <i className="bi-check-circle"></i>Active
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <a href="/" data-bs-toggle="modal" data-bs-target="#exampleModal">17878775</a>
                        </td>
                        <td>003</td>
                        <td>ST</td>
                        <td>
                            <a href="/" data-bs-toggle="modal" data-bs-target="#exampleModal">Some very long premises name</a>
                        </td>
                        <td>Discount liquors</td>
                        <td>Some so long address somewhere</td>
                        <td>New York</td>
                        <td>12</td>
                        <td>
                            <span className="badge bg-secondary">
                                <i className="bi-pause-circle"></i>Inactive
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <a href="/" data-bs-toggle="modal" data-bs-target="#exampleModal">17878775</a>
                        </td>
                        <td>003</td>
                        <td>ST</td>
                        <td>
                            <a href="/" data-bs-toggle="modal" data-bs-target="#exampleModal">Some very long premises</a>
                        </td>
                        <td>Discount liquors</td>
                        <td>Some long address somewhere</td>
                        <td>New York</td>
                        <td>12</td>
                        <td>
                            <span className="badge bg-success">
                                <i className="bi-check-circle"></i>Active
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <a href="/" data-bs-toggle="modal" data-bs-target="#exampleModal">17878775</a>
                        </td>
                        <td>003</td>
                        <td>ST</td>
                        <td>
                            <a href="/" data-bs-toggle="modal" data-bs-target="#exampleModal">Some very long premises name</a>
                        </td>
                        <td>Discount liquors</td>
                        <td>Some so long address somewhere</td>
                        <td>Orange</td>
                        <td>12</td>
                        <td>
                            <span className="badge bg-success">
                                <i className="bi-check-circle"></i>Active
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <a href="/" data-bs-toggle="modal" data-bs-target="#exampleModal">17878775</a>
                        </td>
                        <td>003</td>
                        <td>ST</td>
                        <td>
                            <a href="/" data-bs-toggle="modal" data-bs-target="#exampleModal">Some very long premises name</a>
                        </td>
                        <td>Discount liquors</td>
                        <td>Some so long address somewhere</td>
                        <td>New York</td>
                        <td>12</td>
                        <td>
                            <span className="badge bg-secondary">
                                <i className="bi-pause-circle"></i>Inactive
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ul className="justify-content-center pagination mt-3">
            <li className="page-item disabled">
                <span className="page-link">
                    <span className="bi bi-arrow-left me-2"></span>Prev
                </span>
            </li>
            <li className="page-item active">
                <span className="page-link">1</span>
            </li>
            <li className="page-item">
                <a className="page-link" role="button" href="/">2</a>
            </li>
            <li className="page-item">
                <a className="page-link" role="button" href="/">3</a>
            </li>
            <li className="page-item">
                <a className="page-link" role="button" href="/">...</a>
            </li>
            <li className="page-item">
                <a className="page-link" role="button" href="/">50</a>
            </li>
            <li className="page-item">
                <a className="page-link" role="button" href="/">
                    Next<span className="bi bi-arrow-right ms-2"></span>
                </a>
            </li>
        </ul>
        <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">1 Main lake (2171187)</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Cancel"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12 col-xl-6 mb-4 mb-xl-0">
                                <div className="pe-xl-4">
                                    <div className="mb-4">
                                        <h5 className="fw-bold mb-3">License information for 07/26/2022</h5>
                                        <hr/>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-5 col-lg-4">
                                            <p><strong>Serial number:</strong></p>
                                        </div>
                                        <div className="col-12 col-md-7 col-lg-8">
                                            <p>8000001</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-5 col-lg-4">
                                            <p><strong>License type:</strong></p>
                                        </div>
                                        <div className="col-12 col-md-7 col-lg-8">
                                            <p>WW - Wholesale Wine</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-5 col-lg-4">
                                            <p><strong>License status:</strong></p>
                                        </div>
                                        <div className="col-12 col-md-7 col-lg-8">
                                            <p>Operating under Sapa</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-5 col-lg-4">
                                            <p><strong>Delinquent status:</strong></p>
                                        </div>
                                        <div className="col-12 col-md-7 col-lg-8">
                                            <p>N/A</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-5 col-lg-4">
                                            <p><strong>Filing date:</strong></p>
                                        </div>
                                        <div className="col-12 col-md-7 col-lg-8">
                                            <p>05/07/2022</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-5 col-lg-4">
                                            <p><strong>Effective date:</strong></p>
                                        </div>
                                        <div className="col-12 col-md-7 col-lg-8">
                                            <p>25/07/2022</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-5 col-lg-4">
                                            <p><strong>Expiration date:</strong></p>
                                        </div>
                                        <div className="col-12 col-md-7 col-lg-8">
                                            <p>01/02/2030</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6">
                                <div className="ps-xl-4">
                                    <div className="mb-4">
                                        <h5 className="fw-bold mb-3">Premises information</h5>
                                        <hr/>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-5 col-lg-4">
                                            <p><strong>Principal&apos;s name:</strong></p>
                                        </div>
                                        <div className="col-12 col-md-7 col-lg-8">
                                            <p>Green, Adam</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-5 col-lg-4">
                                            <p><strong>Premises name:</strong></p>
                                        </div>
                                        <div className="col-12 col-md-7 col-lg-8">
                                            <p>Joffre Napa of South Weynon (Demo)</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-5 col-lg-4">
                                            <p><strong>Trade name:</strong></p>
                                        </div>
                                        <div className="col-12 col-md-7 col-lg-8">
                                            <p>Joffre Winery</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-5 col-lg-4">
                                            <p><strong>Address:</strong></p>
                                        </div>
                                        <div className="col-12 col-md-7 col-lg-8">
                                            <p>There is some very very long address,<br/>
                                                Weynon, NY 124553</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-5 col-lg-4">
                                            <p><strong>County:</strong></p>
                                        </div>
                                        <div className="col-12 col-md-7 col-lg-8">
                                            <p>Albany</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default DelinquentSearchView;
