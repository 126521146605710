import httpClient from "api/HttpClient";
import { PageableRequest }               from "models/requests/PageableRequest";
import { PageableResponse }              from "models/response/PageableResponse";
import { BatchDelinquentRecordsPreview } from "models/response/BatchDelinquentRecordsPreview";
import { BatchDelinquentRecordsJob }       from "models/response/BatchDelinquentRecordsJob";
import BACKEND from "services/Constants";

export default {

    index(request: PageableRequest): Promise<PageableResponse<BatchDelinquentRecordsJob>> {
        return httpClient.search(BACKEND + "/wholesaler/batch_delinquent_records_job", request);
    },

    finish(jobId: number): Promise<void> {
        return httpClient.put(`${BACKEND}/wholesaler/batch_delinquent_records_job/update/${jobId}`);
    },

    cancel(jobId: number): Promise<void> {
        return httpClient.delete(`${BACKEND}/wholesaler/batch_delinquent_records_job/destroy/${jobId}`);
    },

    show(jobId: number, request: PageableRequest): Promise<BatchDelinquentRecordsPreview> {
        return httpClient.search(`${BACKEND}/wholesaler/batch_delinquent_records_job/show/${jobId}`, request);
    },

    wrongCsvLink(jobId: number): string {
        return `${BACKEND}/wholesaler/batch_delinquent_records_job/wrong.csv?id=${jobId}`;
    },

    create(): Promise<number> {
        return httpClient.post(BACKEND + "/wholesaler/batch_delinquent_records_job/create");
    },

    upload(jobId: number, request: FormData): Promise<number> {
        return httpClient.post(`${BACKEND}/wholesaler/batch_delinquent_records_job/upload/${jobId}`, request);
    },

    download(jobId: number): string {
        return `${BACKEND}/wholesaler/batch_delinquent_records_job/download/${jobId}`;
    }
};
