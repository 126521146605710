import React, { Fragment } from "react";
import { Link, generatePath } from "react-router-dom";
import ProductService from "services/ProductService";
import { ADMIN_PATHS } from "pages/admin/AdminRoutes";
import { showDistributorInfo } from "components/dialogs/DistributorInfoDialog";
import { showCertificationInfo } from "components/dialogs/CertificationInfoDialog";
import { UpcomingScheduleModel } from "pages/admin/UpcomingSchedules";
import DateUtil from "utils/DateUtil";

interface Props {
    upcomingSchedule: UpcomingScheduleModel,
    licenseId: number
}

export const CertifiedSchedule = ({ upcomingSchedule, licenseId }: Props) => {

    return <Fragment>
        <h5>Schedules { DateUtil.monthNumberToName(upcomingSchedule.month -1) } { upcomingSchedule.year }</h5>
        <div className="table-responsive mt-4">
            <table className="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th>Certified Schedule</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="pb-0 no-icons">
                            { upcomingSchedule.monthSchedules.map((model, index) => {
                                return <div key={ index }>
                                    <div className="d-flex flex-row align-items-center flex-wrap">
                                        <p className="mb-0 me-3"><strong>{ ProductService.getPostTypesSync().find(value => value.code === model.postType)?.description }</strong></p>
                                        { model.certifyDate &&
                                            <span className="badge d-inline green-outline my-1">
                                                Certified on { model.certifyDate.date }&nbsp;{ model.certifyDate.time }
                                            </span>
                                        }
                                    </div>
                                    <p>
                                        { model.certifyDate &&
                                            <Fragment>
                                                <Link to={ generatePath(ADMIN_PATHS.LICENSES.UPCOMING_SCHEDULES.MONTH_SCHEDULE,
                                                    { "licenseId": licenseId, "type": model.postType, "year": upcomingSchedule.year, "month": upcomingSchedule.month }) }>
                                                    View schedule (items { model.certifyCount })
                                                </Link>
                                                &nbsp;|&nbsp;
                                                <button className="btn btn-link" onClick={ () => showDistributorInfo(licenseId, upcomingSchedule.month, upcomingSchedule.year, model.postType) }>
                                                    View Profile Terms
                                                </button>
                                                &nbsp;|&nbsp;
                                                <button className="btn btn-link" onClick={ () => showCertificationInfo(licenseId, upcomingSchedule.month, upcomingSchedule.year, model.postType) }>
                                                    { model.confirmation }
                                                </button>
                                            </Fragment>
                                        }
                                        { typeof(model.certifyDate) === "undefined" && "No data" }
                                    </p>
                                </div>;
                            }) }
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </Fragment>;
};
