import React, { useEffect } from "react";
import Input from "components/form/Input";
import { StepProps } from "pages/wholesaler/appointment/models/StepProps";
import SelectSchedule from "pages/wholesaler/appointment/components/SelectSchedule";
import { SubmitHandler, useForm } from "react-hook-form";
import { LetterModel } from "models/LetterModel";
import { useKeyDownEnterEvent } from "components/KeyDownHook";

const Step1 = (props: StepProps) => {

    const { letter, onSuccess } = props;

    const {
        control,
        handleSubmit,
        setValue
    } = useForm<LetterModel>({
        defaultValues: letter,
        mode: "onSubmit"
    });
    useKeyDownEnterEvent(() => { handleSubmit(onSubmit)(); });

    useEffect(() => {
        letter?.post_type && setValue("post_type", letter.post_type);
        letter?.description && setValue("description", letter.description);
    }, [letter]);

    const onSubmit: SubmitHandler<LetterModel> = async data => {
        onSuccess(data);
    };

    return <div className="card mt-3">
        <div className="card-body">
            <div className="mb-4">
                <h5><strong>Step 1:</strong> Select schedule and enter the letter name</h5>
            </div>
            <form onSubmit={ handleSubmit(onSubmit) }>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="pe-xl-4">
                            <SelectSchedule
                                name="post_type"
                                control={ control }
                                required
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="ps-xl-4">
                            <Input
                                name="description"
                                control={ control }
                                placeholder="Letter name"
                                label="Enter letter name"
                                type="text"
                                required
                            />
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mt-3">
                    <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                        <input type="submit" className="btn btn-primary w-100" value="Continue" />
                    </div>
                </div>
            </form>
        </div>
    </div>;
};

export default Step1;

