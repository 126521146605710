import httpClient from "api/HttpClient";
import BACKEND from "services/Constants";
import { TransferResult } from "models/response/TransferResult";
import { DatePostCountModel } from "pages/admin/TransferScheduleData";
import { TransferDataParamModel } from "pages/admin/models/TransferDataParamModel";

const ROOT_PATH = `${BACKEND}/admin/transfer-products`;

export default {

    getCounts(license: string, status: string): Promise<DatePostCountModel[]> {
        return httpClient.get(`${ROOT_PATH}/counts`, { params: { license: license, status: status } });
    },
    
    transferProducts(request: TransferDataParamModel): Promise<TransferResult> {
        return httpClient.post(`${ROOT_PATH}/copy-products`, request);
    }
};
