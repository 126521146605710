import React from "react";
import Input from "components/form/Input";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const WholesalerScan = () => {

    return <div className="d-flex flex-column">
        <div className="mb-2">
            <h1 className="text-primary mb-4">Delinquent Status Search</h1>
            <p className="mb-0">
                Enter each license number on a separate line in the input field. Then, click the &apos;Submit&apos; button to check for any delinquency records. The results will show you any existing records for the licenses you entered.
            </p>
        </div>
        <div>
            <div className="mb-2 d-flex">
                <div className="ms-auto">
                    <a target="_blank" className="btn btn-secondary" href="/">
                        <i className="bi-download me-2"></i>Download CSV
                    </a>
                </div>
            </div>
            <div className="form-group">
                <div className="form-label-with-tooltip">
                    <label className="form-label" htmlFor="numbers">License numbers</label>
                    <div className="tooltip-wr tooltip-wr-small ms-2">
                        <span className="tooltip-icon bi-question-circle-fill"></span>
                        <div className="tooltip-msg text-center">
                            <p>
                                1234567<br/>
                                5674892<br/>
                                1234678<br/>
                                ….
                            </p>
                        </div>
                    </div>
                </div>
                <textarea rows={ 5 } id="numbers" className="form-control" placeholder="License numbers"></textarea>
            </div>
            <div className="table-responsive mt-4">
                <table className="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Serial number</th>
                            <th>Type</th>
                            <th>Class</th>
                            <th>Expire date</th>
                            <th>Description</th>
                            <th className="w-13">License status</th>
                            <th className="w-13">Delinquent status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>6777777</td>
                            <td>A</td>
                            <td>122</td>
                            <td>2023/12/02</td>
                            <td>Grocery store beer</td>
                            <td>
                                <span className="badge bg-success">
                                    <i className="bi-check-circle"></i>Active
                                </span>
                            </td>
                            <td>
                                <span className="badge bg-orange">
                                    <i className="bi-exclamation-circle"/>Delinquent
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>6777777</td>
                            <td>A</td>
                            <td>122</td>
                            <td>2023/12/02</td>
                            <td>Grocery store beer</td>
                            <td>
                                <span className="badge bg-secondary">
                                    <i className="bi-pause-circle"></i>Inactive
                                </span>
                            </td>
                            <td>
                                <span className="badge bg-info">
                                    <i className="bi-check-circle"></i>Not delinquent
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div className="row justify-content-center mt-3">
            <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                <div className="pe-md-1">
                    <a className="btn btn-outline-secondary w-100" href="/">
                        Back
                    </a>
                </div>
            </div>
            <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                <div className="ps-md-1">
                    <Button className="btn-primary btn w-100">
                        Submit
                    </Button>
                </div>
            </div>
        </div>
    </div>;
};

export default WholesalerScan;
