import React from "react";
import { ControllerSelectProps, SelectControlled, SelectOption } from "components/form/Select";
import { useTimeStore } from "store/TimeStoreProvider";
import { observer } from "mobx-react";
import DateUtil from "utils/DateUtil";

export type SelectProductTypeProps = Omit<ControllerSelectProps, "options">

const SelectPostingMonth = (props : SelectProductTypeProps) => {

    const { newYorkTime } = useTimeStore();

    const options: SelectOption[] = [{ value: 0, title: DateUtil.monthNumberToName(newYorkTime.month()) }];
    if (newYorkTime.date() > 15) {
        options.push({ value: 1, title: DateUtil.monthNumberToName(newYorkTime.add(1, "M").month()) });
    }

    return <SelectControlled label="Posting month" options={ options } { ...props } />;
};

export default observer(SelectPostingMonth);
