import httpClient from "api/HttpClient";
import { ErrorModel } from "models/error.model";
import { AxiosRequestConfig } from "axios";

export const RECAPTCHA_VALUE_HEADER = "recaptcha-value";
export const RECAPTCHA_REQUIRED_RESPONSE = 499;// Token Required
export const RECAPTCHA_INVALID_RESPONSE = 498; // Invalid Token

export enum LoadingState { LOADING, DATA_DISPLAYED, DATA_LOADING_ERROR, CAPTCHA_DISPLAYED}

export type PromiseExecutorArgsType = {
    resolve: (value: string) => void,
    reject: (reason?: any) => void
};

export default {

    withRecaptcha<T = any>(config: AxiosRequestConfig<T>, getCaptchaValue: ()=>Promise<string>): Promise<T>{

        return httpClient.axiosInstance.request(config)
            .then(
                response => response.data,
                (error:ErrorModel)=>
                    (error.status !== RECAPTCHA_REQUIRED_RESPONSE)
                        ? Promise.reject(error)
                        : getCaptchaValue()
                            .then(captcha => {
                                const headers: any = {};
                                headers[RECAPTCHA_VALUE_HEADER] = captcha;
                                return httpClient.axiosInstance.request({ ...config, headers: headers })
                                    .then(response => response.data, error => Promise.reject(error));
                            })
            );
    }
};
