import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import registrationService from "services/RegistrationService";
import Input from "components/form/Input";
import { ConfirmRegistrationModel } from "models/requests/ConfirmRegistrationModel";
import { useNavigate, useParams } from "react-router-dom";
import { useToastStore } from "store/ToastStoreProvider";
import { AUTH_PATHS } from "pages/auth/AuthRoutes";
import { TokenInfo } from "services/AuthService";
import CheckTokenForm from "pages/auth/components/CheckTokenForm";
import PasswordFormInput from "components/form/input/PasswordFormInput";
import FormUtil from "utils/FormUtil";
import { useKeyDownEnterEvent } from "components/KeyDownHook";

const ConfirmRegistration = () => {
    const { token = "" } = useParams<{ token: string; }>();
    const [ tokenInfo, setTokenInfo] = useState<Required<TokenInfo>|undefined>();

    return <CheckTokenForm tokenInfo={ tokenInfo } setTokenInfo={ setTokenInfo }
        token={ token } innerForm={ <ConfirmRegistrationForm token={ token } tokenInfo={ tokenInfo }/> }
        messageHeader={ "Confirm registration form" }/>;
};

export default ConfirmRegistration;

const ConfirmRegistrationForm = ({ token, tokenInfo }: { token: string, tokenInfo: TokenInfo | undefined}) => {
    const { addToast } = useToastStore();
    const navigate = useNavigate();
    const [ formError, setFormError] = useState<string | undefined>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const {
        handleSubmit,
        reset,
        formState: { errors, isDirty },
        clearErrors,
        setError,
        control
    } = useForm<ConfirmRegistrationModel>({
        mode: "onSubmit",
        defaultValues: {
            token: token
        }
    });

    useKeyDownEnterEvent(() => { !isLoading && handleSubmit(onSubmit)(); });

    const onSubmit: SubmitHandler<ConfirmRegistrationModel> = async data => {

        if (data.password !== data.confirmPassword) {
            setFormError("Password and confirm password do not match");
            return;
        }

        clearErrors();
        setFormError(undefined);
        setIsLoading(true);

        registrationService.confirmRegistration(data)
            .then(() => {
                addToast({
                    message: "Your account has been created successfully. Please continue to explore the website",
                    header: "Registration",
                    variant: "success"
                });
                navigate(AUTH_PATHS.LOGIN);
            })
            .catch(error => {
                if (error.status == 422){
                    FormUtil.handleValidationError(error.data, setError, setFormError);
                }
                else {
                    setFormError(error.data.message || "Something went wrong. Please try again");
                }
            }).finally(() => setIsLoading(false));
    };

    return (
        <div className="registration-block">
            <div className="mb-4 mb-md-5 text-center">
                <h1 className="text-primary mb-4">Confirm your registration</h1>
            </div>
            <div className="card login-block">
                <form onSubmit={ handleSubmit(onSubmit) } className="card-body">

                    <Input name="email" value={ tokenInfo?.email } disabled={ true } label="Email" type="email" />

                    <PasswordFormInput control={ control } errors={ errors } name="password"
                        label="Password *"
                        placeholder="Enter password"
                        autoComplete="new-password"
                    />

                    <PasswordFormInput control={ control } errors={ errors } name="confirmPassword"
                        label="Confirm Password *"
                        placeholder="Confirm password"
                        autoComplete="new-password"
                    />

                    { formError &&
                        <div className="invalid-message alert alert-sm alert-danger">
                            <ul>
                                <li>{ formError }</li>
                            </ul>
                        </div>
                    }

                    <div className="d-flex flex-column flex-sm-row mt-3">
                        <button className="col btn-outline-secondary btn me-sm-2 mt-3" onClick={ () => reset() }>
                            Clear Form
                        </button>
                        <input
                            className="col btn btn-primary ms-sm-2 mt-3"
                            type="submit"
                            value="Submit"
                            disabled={ !isDirty }
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};
