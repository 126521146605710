import App from "App";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import RootStoresWrapper from "store/RootStoresWrapper";
import StyleGuideMainPage from "pages/StyleGuide/StyleGuideMainPage";
import { LoggedInUserInfo } from "models/LoggedInUserInfo";
import { createRoot } from "react-dom/client";

declare global {
    interface Window { loggedInUser: LoggedInUserInfo | undefined; }
}

export const ROOT_PATHS = {
    HOME: "/",
    NOT_FOUND: "/404",
    STYLE_GUIDE: "/style-guide"
};

createRoot(document.getElementById("root") as HTMLElement).render(
    <BrowserRouter>
        <RootStoresWrapper loggedInUserData={ window.loggedInUser }>
            <Routes>
                <Route path={ ROOT_PATHS.STYLE_GUIDE } element={ <StyleGuideMainPage/> } />
                <Route path="/*" element={ <App/> }/>
            </Routes>
        </RootStoresWrapper>
    </BrowserRouter>
);

