import { useEffect, useState } from "react";
import { Link, generatePath, useLocation, useParams } from "react-router-dom";
import { WHOLESALER_PATHS } from "pages/wholesaler/WholesalerRoutes";
import UserProfileService from "services/user/UserProfileService";
import { CurrentUserProfile } from "models/UserModel";
import ProductConstants from "services/ProductConstants";
import { useAuthStore } from "store/AuthStoreProvider";
import LicenseService from "services/LicenseService";
import DateUtil from "utils/DateUtil";

const Certify3View = () => {
    const { licenseId } = useAuthStore();
    const { type = "", year = "", month = "" } = useParams();
    const [permitId, setPermitId] = useState<string>();
    const [totalItems, setTotalItems] = useState<number>();
    const [effectiveDate, setEffectiveDate] = useState<string>();
    const [userName, setUserName] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [licenses, setLicenses] = useState<string>();
    const location = useLocation();

    useEffect(() => {
        setEffectiveDate(DateUtil.monthNumberToName(parseInt(month)-1).toString() + " " + year.toString());
        setEmail(location.state.email);
        setTotalItems(location.state.totalToBeCertified);
        setLicenses(location.state.licenses);

        LicenseService.getLicenseInfoById(licenseId || -1).then(license => setPermitId(license?.permit_id));

        UserProfileService.getProfile().then(response => {
            const userModel = response as CurrentUserProfile;
            setUserName(userModel.first_name + " " + userModel.last_name);
        });
    });

    return <div className="d-flex flex-column">
        <div className="mb-4 mb-md-5">
            <h1 className="text-primary mb-0">Certify - { ProductConstants.postTypeDescription(type) } Schedule for { effectiveDate }</h1>
        </div>
        <div>
            <div className="steps-wr mb-4">
                <div className="steps-l row g-0">
                    <div className="steps-li col-12 col-xl-3">
                        <div className="steps-li-in">
                            <strong>1</strong> Edit Posting Profile
                        </div>
                    </div>
                    <div className="steps-li col-12 col-xl-3">
                        <div className="steps-li-in">
                            <strong>2</strong> Certify Schedule
                        </div>
                    </div>
                    <div className="steps-li col-12 col-xl-3 active">
                        <div className="steps-li-in">
                            <strong>3</strong> Confirmation
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-2 mb-3 mb-md-4">
                <h4><strong>Step 3:</strong> Confirmation</h4>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="mb-4">
                        <h4 className="mb-3 d-flex flex-row align-items-center">
                            <strong className="me-4">Date:</strong>
                            <span className="bi-calendar text-primary fs-5 me-1">&nbsp;</span>
                            <span className="me-4">{ location.state.dateTime.date }</span>
                            <span className="bi-clock text-primary fs-5 me-1">&nbsp;</span>
                            <span>{ location.state.dateTime.time }</span>
                        </h4>
                    </div>
                    <div className="content-styles">
                        <p>
                            This is the confirmation of the new posting system.
                        </p>
                        <p>
                            The following price posting schedule has been submitted to the State Liquor Authority.
                        </p>
                        <div className="mb-4 mt-4 pt-2">
                            <div className="row mb-2 mb-lg-0">
                                <div className="col-12 col-lg-4 col-xl-4 col-xxxl-3">
                                    <p className="mb-2"><strong>Confirmation Number:</strong></p>
                                </div>
                                <div className="col-12 col-lg-8 col-xl-8 col-xxxl-9">
                                    <p className="mb-2">{ location.state.confirmationNumber }</p>
                                </div>
                            </div>
                            <div className="row mb-2 mb-lg-0">
                                <div className="col-12 col-lg-4 col-xl-4 col-xxxl-3">
                                    <p className="mb-2"><strong>License ID:</strong></p>
                                </div>
                                <div className="col-12 col-lg-8 col-xl-8 col-xxxl-9">
                                    <p className="mb-2">{ permitId }</p>
                                </div>
                            </div>
                            <div className="row mb-2 mb-lg-0">
                                <div className="col-12 col-lg-4 col-xl-4 col-xxxl-3">
                                    <p className="mb-2"><strong>Posting Type:</strong></p>
                                </div>
                                <div className="col-12 col-lg-8 col-xl-8 col-xxxl-9">
                                    <p className="mb-2">{ ProductConstants.postTypeDescription(type) }</p>
                                </div>
                            </div>
                            <div className="row mb-2 mb-lg-0">
                                <div className="col-12 col-lg-4 col-xl-4 col-xxxl-3">
                                    <p className="mb-2"><strong>Total Items:</strong></p>
                                </div>
                                <div className="col-12 col-lg-8 col-xl-8 col-xxxl-9">
                                    <p className="mb-2">{ totalItems }</p>
                                </div>
                            </div>
                            <div className="row mb-2 mb-lg-0">
                                <div className="col-12 col-lg-4 col-xl-4 col-xxxl-3">
                                    <p className="mb-2"><strong>Effective Date:</strong></p>
                                </div>
                                <div className="col-12 col-lg-8 col-xl-8 col-xxxl-9">
                                    <p className="mb-2">{ effectiveDate }</p>
                                </div>
                            </div>
                            <div className="row mb-2 mb-lg-0">
                                <div className="col-12 col-lg-4 col-xl-4 col-xxxl-3">
                                    <p className="mb-2"><strong>Submitted By:</strong></p>
                                </div>
                                <div className="col-12 col-lg-8 col-xl-8 col-xxxl-9">
                                    <p className="mb-2">{ userName }</p>
                                </div>
                            </div>
                            <div className="row mb-2 mb-lg-0">
                                <div className="col-12 col-lg-4 col-xl-4 col-xxxl-3">
                                    <p className="mb-2"><strong>This certification applies to the following licenses:</strong></p>
                                </div>
                                <div className="col-12 col-lg-8 col-xl-8 col-xxxl-9 d-flex align-items-center">
                                    <p className="mb-2">{ licenses == "" ? permitId : licenses }</p>
                                </div>
                            </div>
                            <div className="row mb-2 mb-lg-0">
                                <div className="col-12 col-lg-4 col-xl-4 col-xxxl-3">
                                    <p className="mb-2"><strong>A confirmation email will be sent to:</strong></p>
                                </div>
                                <div className="col-12 col-lg-8 col-xl-8 col-xxxl-9 d-flex align-items-center">
                                    <p className="mb-2">{ email }</p>
                                </div>
                            </div>
                        </div>
                        <p>
                            You can use the online applications to edit the information contained in this posting schedule. Please review the Filing Period Schedules and Deadlines for more details.
                        </p>
                    </div>
                    <div className="row justify-content-center mt-3">
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                            <Link
                                to={ generatePath(WHOLESALER_PATHS.PRICE_POSTING.VIEW, { "type":type, "year":year, "month": month }) }
                                className="btn btn-primary w-100">
                                Return to Posting Summary
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default Certify3View;

