import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Input from "components/form/Input";
import PhoneNumberInput from "components/form/input/PhoneNumberInput";
import CheckBox from "components/form/CheckBox";
import EditUserLicensesDialog from "pages/admin/dialogs/EditUserLicenses";
import { CreatableEditableUser, UserModel } from "models/UserModel";
import { useToastStore } from "store/ToastStoreProvider";
import { useAuthStore } from "store/AuthStoreProvider";
import UserService from "services/UserService";
import ManageLicensesService from "services/admin/ManageLicensesService";
import FormUtil from "utils/FormUtil";
import { ShortLicenseInfo } from "models/license.model";
import { useModalStore } from "store/ModalStoreProvider";

/*
    The dialog runs in 2 modes:
    - create mode
        -- activated when user is null
        -- allows creating new and editing existing users (but does not allow to modify email)
        -- when email field is modified, page will :
        --- 1. search for user with specified email,
        --- 2. (if found) fills all fields with values of the found user
        --- 3. (if not found) resets licenses and user id

    - edit mode
        -- activated when user is not null
        -- allows to edit user (possibly modifying user email) after pressing save
        -- when email field is modified, page will :
        --- 1. search if there's any user with specified email,
        --- 2. (if yes) display validation error
 */
type Props = {
    user?: UserModel,
    licenseIdForSingleMode?: number,
    userUpdated: ()=>void
};

const CreateEditUser = ({ user, licenseIdForSingleMode, userUpdated }:Props) => {

    const modal = useModalStore();
    const { addDefaultDangerToast } = useToastStore();
    const auth = useAuthStore();
    const isCreate = user === undefined;
    const isBA = !auth.permissionStore.isSlaAdminOrAdmin();

    const formDefaults = () : Promise<CreatableEditableUser> => ( isCreate
        ? ( (isBA || licenseIdForSingleMode)           // add new
            ? ManageLicensesService.shortLicenseInfoById((isBA ? auth.licenseId : licenseIdForSingleMode) || 0)
                .then(lm=> {
                    currentLicenseSLI.current = lm;
                    return { email: "",last_name: "",first_name: "",phone: "",licenses: [{ ...lm, roles: [] }] } ;
                })
            : Promise.resolve ({ email:"", last_name:"", first_name:"", phone:"", licenses: [] } )
        )
        : Promise.resolve(user) // edit existing
    );

    const currentLicenseSLI = useRef<ShortLicenseInfo>();
    const [formError, setFormError] = useState<string | undefined>("");
    const formReturn= useForm<CreatableEditableUser>({ defaultValues: formDefaults });
    const {
        control,
        formState: { errors, dirtyFields },
        handleSubmit,
        setError,
        getValues,
        reset,
        clearErrors
    } = formReturn;

    const saveUser =(d: CreatableEditableUser ) => {
        if (!modal.isLoading) {
            modal.setIsLoading(true);
            UserService.createOrUpdateUser(d)
                .then(() => {
                    modal.hideModal();
                    userUpdated();
                })
                .catch(response => {
                    if (response.status == 422) {
                        FormUtil.handleValidationError(response.data, setError, setFormError);
                    } else {
                        addDefaultDangerToast("User operation");
                    }
                    modal.setIsLoading(false)
                });
        }
    }

    const setHeaderAndButton = ( createOrEdit: boolean ) =>
        modal.setHeaderAndButtons(createOrEdit ? "Create User" : "Edit User",[
            <button key={ 1 } type="button" className="btn btn-primary" onClick={ handleSubmit(saveUser) } disabled={ modal.isLoading }>
                { createOrEdit ? "Create" : "Update" }
            </button>
        ]);

    const emailOnBlurInCreateMode = ()=>{
        const email = getValues("email");
        if (email) {
            UserService.searchByExactEmail(email)
                .then(um=> {
                    reset(prepareModelInCreateMode(um));
                    setHeaderAndButton(false);
                })
                .catch(() => setHeaderAndButton(true ));
        }
        else {
            setHeaderAndButton(true);
        }
    };

    const emailOnBlurInEditMode = ()=>{

        if (!("email" in dirtyFields)){
            clearErrors("email");
            return;
        }
        const email = getValues("email");

        if (email) {
            UserService.searchByExactEmail(email)
                .then(()=> setError("email", { message:"User with this email already exists", type:"manual" }));
        }
    };

    const prepareModelInCreateMode = (um: Partial<UserModel>) =>{
        const licenses = um.licenses ?? [];
        //if user license list contains no current license
        if ( currentLicenseSLI.current !== undefined &&  licenses.find(l=>l.id===currentLicenseSLI.current?.id) === undefined ){
            licenses.push({ ... currentLicenseSLI.current, roles:[] });
            um.licenses = licenses;
        }
        return um;
    };

    useEffect(()=> setHeaderAndButton(isCreate),[]);

    return <FormProvider { ...formReturn } >
        { formError &&
            <div className="invalid-message alert alert-sm alert-danger">
                <ul>
                    <li>{ formError }</li>
                </ul>
            </div>
        }
        <div>
            <p>Enter user email and give him permissions. User will get an email with link that can be used for registration. Without completing registration process, user won&apos;t be able to login</p>
            <div className="row mb-4">
                <div className="col-12 col-md-6 col-lg-6">
                    <Input name="email" placeholder="Enter address" label="Email address *" type="email"
                        control={ control } errors={ errors } onBlur={ isCreate ? emailOnBlurInCreateMode : emailOnBlurInEditMode }/>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                    <PhoneNumberInput control={ control } errors={ errors } name="phone" label="Phone" />
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                    <Input name="first_name" placeholder="Enter first name" label="First Name" type="text" control={ control } errors={ errors } />
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                    <Input name="last_name" placeholder="Enter last name" label="Last Name" type="text" control={ control } errors={ errors } />
                </div>
                { !isBA &&
                    <div className="col-12 col-md-6 col-lg-6">
                        <CheckBox control={ control } name="prod_api_access" label="Access Production API" type="checkbox"/>
                    </div>
                }
            </div>
        </div>
        <EditUserLicensesDialog licenseIdForSingleMode={ isBA && licenseIdForSingleMode ? licenseIdForSingleMode : undefined } />
    </FormProvider>;
};

export default CreateEditUser;
