import { Controller } from "react-hook-form";
import { FormCheck } from "react-bootstrap";
import { FormCheckProps } from "react-bootstrap/FormCheck";

type Props = {
    name: string,
    control: any
} & FormCheckProps;

const CheckBox = ({ name, control, type="switch", ...props }: Props) => {
    return <Controller
        name={ name }
        control={ control }
        render={ ({ field: { ... fieldProps } }) => {
            if (type !== "radio"){
                props.checked = fieldProps.value;
            }
            return <FormCheck
                id={ `cfc-${name}-${props.value}` }
                type={ type }
                { ...fieldProps }
                { ...props }
            />;
        } }
    />;
};

export default CheckBox;
