import { LEFT_MENU as ADMIN_LEFT_MENU, ADMIN_PATHS } from "pages/admin/AdminRoutes";
import { LEFT_MENU as RETAILER_LEFT_MENU, RETAILER_PATHS } from "pages/retailer/RetailerRoutes";
import { LEFT_MENU as WHOLESALER_LEFT_MENU, WHOLESALER_PATHS } from "pages/wholesaler/WholesalerRoutes";
import { NavigateFunction } from "react-router/dist/lib/hooks";
import { PermissionStore } from "store/PermissionStore";
import { Dayjs } from "dayjs";
import { MenuSettings } from "components/menu/Menu";
import { LEFT_MENU as USER_LEFT_MENU, USER_PATHS } from "pages/user/UserRoutes";

export function redirectToHomePage(ps: PermissionStore, navigate: NavigateFunction) {
    if (ps.isSlaAdminOrAdminOrSlaCod()) {
        navigate(ADMIN_PATHS.LICENSES.SEARCH);
    }
    else if (ps.isRetailer) {
        ps.isRetailerAndPriceRead()
            ? navigate(RETAILER_PATHS.PRICE_LOOKUP)
            : navigate(RETAILER_PATHS.DELINQUENT_VIEW);
    }
    else if (ps.isWholesaler) {
        ps.isWholesalerAndPricePosting()
            ? navigate(WHOLESALER_PATHS.PRICE_POSTING.MAIN)
            : navigate(WHOLESALER_PATHS.LICENSE.SEARCH);
    } else {
        navigate(USER_PATHS.MY_LICENSES);
    }
}

export function roleDependentMenu(ps: PermissionStore, newYorkTime: Dayjs): MenuSettings {
    if (ps.isSlaAdminOrAdminOrSlaCod()) {
        return ADMIN_LEFT_MENU(ps,newYorkTime);
    }
    else if (ps.isRetailer) {
        return RETAILER_LEFT_MENU(ps,newYorkTime);
    }
    else if (ps.isWholesaler) {
        return WHOLESALER_LEFT_MENU(ps,newYorkTime);
    }
    else {
        return USER_LEFT_MENU();
    }
}
