import React, { useEffect, useState } from "react";
import { LicenseModel } from "models/license.model";
import DialogSearchForm from "pages/admin/components/DialogSearchForm";
import { SaveMasterFilerModel } from "pages/admin/models/SaveMasterFilerModel";
import { useToastStore } from "store/ToastStoreProvider";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";
import { LicenseGroupModel } from "pages/admin/models/LicenseGroupModel";
import { modalStore } from "store/ModalStoreProvider";
import { LicenseGroupService } from "services/admin/LicenseGroupService";

interface Props {
    initModel: LicenseGroupModel,
    onSaveMasterFiler: ()=>void,
    isEdit: boolean,
    service: LicenseGroupService,
    entityTitle: string
}

export const showEditLicenseGroupDialog = (initModel: LicenseGroupModel, onSaveMasterFiler: ()=>void,
    service: LicenseGroupService, entityTitle: string) => {
    modalStore.setSize("lg");
    const isEdit: boolean = initModel.parentLicense?.id != undefined;
    modalStore.setHeader(isEdit ? `Edit ${entityTitle}` : `Create ${entityTitle}`);
    modalStore.setBody(<EditLicenseGroupDialog initModel={ initModel } onSaveMasterFiler={ onSaveMasterFiler }
        isEdit={ isEdit } service={ service } entityTitle={ entityTitle }/>);
    modalStore.showModal();
};

const EditLicenseGroupDialog = ({ initModel, onSaveMasterFiler, isEdit, service, entityTitle }: Props) => {

    const { addToast } = useToastStore();
    const [model, setModel] = useState<LicenseGroupModel>({} as LicenseGroupModel);

    useEffect(() => {
        setModel({
            parentLicense: initModel?.parentLicense,
            childLicenses: initModel?.childLicenses?.map( l => {
                return ({
                    premise_name: l.premise_name,
                    premise_address: l.premise_address,
                    permit_id: l.permit_id,
                    serial_number: l.serial_number,
                    id: l.id
                } as LicenseModel);
            })
        });
    }, [initModel]);

    function onSave() {
        const request = {
            parentLicenseId: model.parentLicense.id,
            childLicenseIds: model.childLicenses.filter(l => l.id != undefined).map(l => l.id)
        } as SaveMasterFilerModel;

        service.save(request).then((response) => {
            if (response){
                onSaveMasterFiler();
                addToast({ header: entityTitle,  message:`${entityTitle} saved successfully`, variant: "success", timeoutMs: DEFAULT_TOASTER_TIMEOUT });
            }
        }).catch((error) => {
            addToast({ header: entityTitle,  message: error, variant: "danger", timeoutMs: DEFAULT_TOASTER_TIMEOUT });
        }).finally(() => modalStore.hideModal());
    }

    function onSuccess(license: LicenseModel, isParent: boolean, index: number) {
        if (isParent) {
            setModel({
                parentLicense: license,
                childLicenses: [{} as LicenseModel]
            });
        } else {
            model.childLicenses[index] = license;
            setModel(prevState => {
                return { ...prevState, childLicenses: prevState.childLicenses };
            });
        }
    }

    function onRemove(index: number) {
        if (model.childLicenses.length > 1) {
            model.childLicenses.splice(index, 1);
            setModel(prevState => {
                return { ...prevState, childLicenses: prevState.childLicenses };
            });
        }
    }

    function addNewChildLicense() {
        if (model.childLicenses.filter(l => l.id == undefined).length == 0) {
            setModel(prevState => {
                return { ...prevState, childLicenses: [ ...prevState.childLicenses, {} as LicenseModel] };
            });
        }
    }

    function checkOnDuplicate(selector: string): boolean {
        return compareLicenses(model.parentLicense, selector)
            || model.childLicenses.filter(l => compareLicenses(l, selector)).length != 0;
    }

    function compareLicenses(l: LicenseModel, selector: string) {
        return l.permit_id === selector || l.serial_number === parseInt(selector);
    }

    function checkOnSame(selector: string, index: number): boolean {
        const present: boolean = initModel?.childLicenses
            ?.filter(l => compareLicenses(l, selector)).length > 0;
        if(present) {
            model.childLicenses[index] = initModel.childLicenses.filter(l => compareLicenses(l, selector))[0];
            setModel({ ...model, childLicenses: model.childLicenses });
        }
        return present;
    }

    function onChange(isParent: boolean, index: number) {
        if (isParent) {
            setModel({
                parentLicense: {} as LicenseModel,
                childLicenses: []
            });
        } else {
            model.childLicenses[index] = {} as LicenseModel;
            setModel(prevState => {
                return { ...prevState, childLicenses: prevState.childLicenses };
            });
        }
    }

    modalStore.setAdditionalButtons([
        <button
            key={ 0 } type="button" className="btn btn-secondary"
            onClick={ addNewChildLicense }
            disabled={ model?.parentLicense?.id === undefined
                || model?.childLicenses.filter(l => l.id == undefined).length != 0 }
        >Add secondary license</button>,
        <button
            key={ 1 } type="button" className="btn btn-primary" onClick={ onSave }
            disabled={ (model?.parentLicense?.id == undefined
                || model?.childLicenses?.filter(l => l.id != undefined).length == 0) }
        >Save</button>
    ]);
    return <div>
        <DialogSearchForm
            license={ model?.parentLicense }
            isParent={ true }
            isEdit={ isEdit }
            onSuccess={ onSuccess }
            onChange={ onChange }
            service={ service } entityTitle={ entityTitle }
        />

        { model?.childLicenses?.map((license: LicenseModel, index: number) => {
            return (
                <div  key={ index } className="card mt-4">
                    <div className="card-body pt-3">
                        <div className="styleguide-head mb-4">
                            <div className="d-flex flex-row align-items-end">
                                <h5 className="me-auto mb-0">Secondary License</h5>
                                { (model.childLicenses.length > 1) &&
                                    <button
                                        className="btn btn-outline-secondary"
                                        onClick={ () => onRemove(index) }>Remove</button>
                                }
                            </div>
                            <hr className="mb-0" />
                        </div>
                        <DialogSearchForm
                            index={ index }
                            license={ license }
                            isParent={ false }
                            onSuccess={ onSuccess }
                            checkOnDuplicate={ checkOnDuplicate }
                            onChange={ onChange }
                            isEdit={ isEdit && license.id != undefined }
                            checkOnSame={ checkOnSame }
                            service={ service } entityTitle={ entityTitle }
                        />
                    </div>
                </div>
            );
        }) }
    </div>;
};
