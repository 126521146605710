import React, { useEffect, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useToastStore } from "store/ToastStoreProvider";
import TransferCODService from "services/admin/TransferCODService";
import { useModalStore } from "store/ModalStoreProvider";
import Input from "components/form/Input";
import { DistributorModel } from "models/DistributorModel";
import { ProgressDialog } from "components/dialogs/ProgressDialog";
import { useAuthStore } from "store/AuthStoreProvider";
import { SelectControlled, SelectOption } from "components/form/Select";
import { UserLicense } from "models/LoggedInUserInfo";

/**
 * Component can work in 2 modes:
 * 1. Admin mode
 *      - any license allowed to be in source or target
 *      - therefore, text inputs are used to select licenses
 * 2. BA mode
 *      - only bound licenses allowed to be in source or target
 *      - therefore, dropdowns are used to select licenses
 *      - uses different title and text
 */

type TransferRequest = {
    source?: string,
    target?: string
}

const SOURCE = true;
const TARGET = false;

const TransferCOD = ({ mode }:{ mode: "admin"|"ba" }) => {

    const modalStore = useModalStore();
    const { addToast, addDefaultDangerToast } = useToastStore();
    const auth = useAuthStore();
    const [ sourceLicenseInfo, setSourceLicenseInfo ] = useState<DistributorModel>();
    const [ targetLicenseInfo, setTargetLicenseInfo ] = useState<DistributorModel>();
    const formReturn = useForm<TransferRequest>({ mode: "onBlur" });
    const { trigger, getValues, reset, formState:{ isDirty } } = formReturn;
    const [ showProgress, setShowProgress ] = useState<boolean>(false);

    const resetLicenseInfo = (sourceOrTarget: boolean)=>
        (sourceOrTarget === SOURCE ? setSourceLicenseInfo : setTargetLicenseInfo)(undefined);

    const validateLicense = (sourceOrTarget: boolean, value:string) => {
        const [srcSerial,trgSerial] = getValues(["source","target"]);
        if (srcSerial && trgSerial && srcSerial === trgSerial){
            return "Source and target license numbers should not be the same";
        }

        return TransferCODService.getLicenseInfo(value)
            .then(
                lic => {
                    (sourceOrTarget === SOURCE ? setSourceLicenseInfo : setTargetLicenseInfo)(lic);
                    return true;
                },
                ()=> false
            );
    };

    const openConfirmDialog = () => {
        trigger();
        if (!sourceLicenseInfo || !targetLicenseInfo){
            return;
        }

        modalStore.setHeader("Move Delinquent Data");
        modalStore.setBody(<DialogContent source={ sourceLicenseInfo } target={ targetLicenseInfo } />);
        modalStore.setAdditionalButtons([
            <button key={ 0 } type="button" className="btn btn-primary" onClick={ ()=> doTransfer() }>Move</button>
        ]);
        modalStore.showModal();
    };

    const doTransfer = ()=> {
        modalStore.hideModal();
        setShowProgress(true);

        const source = getValues("source") || "";
        const target = getValues("target") || "";
        TransferCODService.perform(source, target, false)
            .then((resp) => {
                addToast({
                    header: "Data Migration Detail",
                    message: <>
                        Transfer from <strong>{ source }</strong> to <strong>{ target }</strong> complete at: <strong>{ resp.time }</strong><br/>
                        Records transferred: <strong>{ resp.count }</strong>
                    </>,
                    variant: "success"
                });
            })
            .catch(() => addDefaultDangerToast("Move Delinquent Data"))
            .finally(() => {
                setShowProgress(false);
                reset();
            });
    };

    return <div className="d-flex flex-column">
        <div className="mb-4 pb-md-3">
            <h1 className="text-primary mb-4">{ mode == "admin" ? "Move Delinquent Data" : "Data Migrator" }</h1>
            <p>
                { mode == "admin" ?
                    "You can move Delinquent data from one license to another by entering the source license number and the \
                    target license number, and then clicking the &apos;Move&apos; button."
                    :
                    "You can move Delinquent Data from one license to another by selecting the source license number and the \
                    target license number, and then clicking the 'Move' button"
                }
            </p>
        </div>
        <div className="card">
            <div className="card-body">
                <FormProvider  { ...formReturn }>
                    <form action="" className="row">
                        <div className="col-12 col-lg-6 col-xl-6">
                            <div className="pe-lg-5">
                                <LicenseBlock sourceOrTarget={ SOURCE } licenseInfo={ sourceLicenseInfo }
                                    resetLicenseInfo={ resetLicenseInfo } validate={ validateLicense }
                                    allowedLicenses={ mode == "ba" ? auth.licenses : undefined }
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 col-xl-6">
                            <div className="ps-lg-5 position-relative">
                                <div className="arrow-right-big bi-arrow-right-circle d-none d-lg-block">&nbsp;</div>
                                <LicenseBlock sourceOrTarget={ TARGET } licenseInfo={ targetLicenseInfo }
                                    resetLicenseInfo={ resetLicenseInfo } validate={ validateLicense }
                                    allowedLicenses={ mode == "ba" ? auth.licenses : undefined }
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row justify-content-center">
                                <div className="col-6 col-md-4 col-lg-4 col-xl-3">
                                    <button type="button" disabled={ !isDirty } className="btn btn-primary w-100"
                                        onClick={ openConfirmDialog }>Move</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
        <ProgressDialog show={ showProgress } />
    </div>;
};

export default TransferCOD;

type LicenseBlockParams = {
    sourceOrTarget: boolean,
    licenseInfo?: DistributorModel,
    resetLicenseInfo: (sourceOrTarget: boolean)=>void,
    validate: (sourceOrTarget: boolean, value: string) => (string | Promise<boolean>),
    allowedLicenses?: UserLicense[]
}
const LicenseBlock = ({ sourceOrTarget, licenseInfo, resetLicenseInfo, validate, allowedLicenses }:LicenseBlockParams)=>{

    const text =  sourceOrTarget === SOURCE ? "Source": "Target" ;
    const name =  sourceOrTarget === SOURCE ? "source": "target" ;
    const { control, watch, formState: { errors } } = useFormContext();

    useEffect(() => resetLicenseInfo(sourceOrTarget),[ watch(name) ]);

    const options = (allowedLicenses ?? []).map(l=>({
        title: `${l.permitId ?? l.serialNumber} - ${l.premiseName}${l.active?"":" - Inactive"}`,
        value: l.permitId ?? l.serialNumber
    } as SelectOption) );
    options.unshift({ title: "", value: "" });

    return <div className="mb-3">
        <h4 className="mb-3">{ text } License</h4>
        <div className="row pt-1">
            <div className="col-12">
                {
                    allowedLicenses == undefined ?
                        <Input name={ name } control={ control } errors={ errors }
                            type="text" placeholder={ "Enter license id or serial number" } label={ `${text} License` }
                            rules={ {
                                required: "Enter valid license id or serial number",
                                validate: async (value:string) => await validate(sourceOrTarget,value) || "Entered license number does not exist."
                            } }
                        />
                        :
                        <SelectControlled name={ name } control={ control } errors={ errors }
                            options={ options } label={ `${text} License` }
                            rules={ {
                                required: "Please select license",
                                validate: async (value:string) => await validate(sourceOrTarget,value) || "Entered license number does not exist."
                            } }
                        />
                }
            </div>
            <div className="col-12 col-lg-6 col-xxl-7">
                <Input name={ `${name}_name` } label="Company Name" type="text"
                    disabled value={ licenseInfo?.premise_name } />
            </div>
            <div className="col-12 col-xxl-5">
                <Input name={ `${name}_address` } label="Company Address" type="text"
                    disabled value={ licenseInfo?.premise_address } />
            </div>
        </div>
    </div>;
};

type DialogContentProps = {
    source: DistributorModel,
    target: DistributorModel
}
const DialogContent = ({ source, target  }:DialogContentProps) => {
    const [ countBeforeOperation, setCountBeforeOperation ] = useState<number|undefined>();

    useEffect(()=>{
        TransferCODService.perform(source?.permit_id ?? source?.serial_number , target?.permit_id ?? target?.serial_number, true)
            .then((resp) => setCountBeforeOperation(resp.count));
    },[]);

    return <>
        <div className="row">
            <div className="col-12 col-lg-6">
                <p className="mb-1">From:</p>
                <p className="mb-0"><strong>{ source?.permit_id }</strong>,</p>
                <p className="mb-0">{ source?.premise_name },</p>
                <p>{ source?.premise_address }</p>
            </div>
            <div className="col-12 col-lg-6 mt-2 mt-lg-0">
                <p className="mb-1">To:</p>
                <p className="mb-0"><strong>{ target?.permit_id }</strong>,</p>
                <p className="mb-0">{ target?.premise_name },</p>
                <p>{ target?.premise_address }</p>
            </div>
        </div>
        <p className="mb-3"><strong>COD Records</strong>: { countBeforeOperation ?? "loading" }</p>
        <p className="mb-0">Initiating this transfer will move all COD data from the source license to the target license. Please be aware that this process will permanently remove the records from the source license and any existing records in the target license will be overwritten. Once moved, the data will only exist in the target license. Are you sure you want to proceed?</p>
    </>;
};
