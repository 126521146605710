import React, { Fragment } from "react";
import { ProductModel } from "models/ProductModel";
import classNames from "classnames";
import ErroneousValue from "components/ErroneousValue";
import { Accordion } from "react-bootstrap";
import CurrencyText from "components/CurrencyText";
import PackageTypeConstants from "services/PackageTypeConstants";
import { ProductTabContainer, TabModel } from "components/product-tab-widget/ProductTabContainer";
import { BrandTab } from "components/product-tab-widget/components/BrandsTab";
import { BrandOwnerTab } from "components/product-tab-widget/components/BrandOwnerTab";
import { ProductTab } from "components/product-tab-widget/components/ProductTab";
import { NYSSupplierTab } from "components/product-tab-widget/components/NYSSupplierTab";
import {
    LimitedAvailabilityTab
} from "components/product-tab-widget/components/LimitedAvailabilityTab";
import { ComboTab } from "components/product-tab-widget/components/ComboTab";
import { DiscountTab } from "components/product-tab-widget/components/DiscountTab";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";
import { Link, generatePath } from "react-router-dom";
import { WHOLESALER_PATHS } from "pages/wholesaler/WholesalerRoutes";
import { useModalStore } from "store/ModalStoreProvider";
import CrudProductService from "services/wholesaler/priceposting/CrudProductService";
import { useToastStore } from "store/ToastStoreProvider";
import WholesalerPricePostingService from "services/wholesaler/WholesalerPricePostingService";
import * as _ from "lodash";
import { CustomToggle } from "components/product-tab-widget/CustomToggle";
import { Sorting, SortingOrder } from "models/Sorting";
import { SortingButton } from "components/SortingButton";

const DIALOG_HEADER = "Delete Product";

interface Props {
    postType: string;
    year: string;
    month: string;
    products: ProductModel[];
    writePermitted: boolean;
    reloadPage: ()=>void;
    onSorting: (value: Sorting[]) => void;
    sortings?: Sorting[];
    actions: Array<string>;
}

export const BrandsProductList = ({ products, writePermitted, reloadPage, postType, year, month, onSorting, sortings, actions }: Props) => {
    const { addToast } = useToastStore();
    const modalStore = useModalStore();

    function canPostPrices(): boolean {
        return WholesalerPricePostingService.canDoAction(actions || [], "POST");
    }

    function canAmendPrices(): boolean {
        return WholesalerPricePostingService.canDoAction(actions || [], "EQUALIZE");
    }

    const currencyFormat = (value: number | undefined) => value === undefined  ? "N/A" : <CurrencyText value={ value } />;

    const tabs: TabModel[] = [
        BrandTab, ProductTab, NYSSupplierTab, LimitedAvailabilityTab, ComboTab, DiscountTab, BrandOwnerTab
    ];

    const deleteProductConfirmation = (item: ProductModel)=>{
        modalStore.setHeader(DIALOG_HEADER);
        modalStore.setBody(<p className="mb-0">
            You are going to delete product&nbsp;
            <strong>{ item.prod_name }{ item.prod_item && <span>, { item.prod_item }</span> }</strong>.
            Are you sure?</p>);
        modalStore.setAdditionalButtons([
            <button key={ 0 } type="button" className="btn btn-primary" onClick={ ()=> deleteProduct(item.id) }>Yes</button>
        ]);
        modalStore.showModal();
    };

    const deleteProduct = (productId: number) =>
        CrudProductService.delete(productId)
            .then(() => {
                reloadPage();
                addToast({ message:"Product deleted successfully.", header: DIALOG_HEADER, variant: "success", timeoutMs: DEFAULT_TOASTER_TIMEOUT });
            })
            .catch(()=>{
                addToast({ message:"Something went wrong. Please try again", header: DIALOG_HEADER, variant: "danger", timeoutMs: DEFAULT_TOASTER_TIMEOUT });
            })
            .finally(()=>modalStore.hideModal());

    const showAmendPage = (id: number) => {
        modalStore.setSize("xl");
        modalStore.setBody(<AgreeAndContinueDialog />);
        modalStore.setAdditionalButtons([
            <Link onClick={ () => modalStore.hideModal() } key={ id } to={ generatePath(WHOLESALER_PATHS.PRICE_POSTING.AMEND_BRAND_ITEM,
                { "id": id, "postType": postType, "month": month, "year": year }) } className={ "btn btn-primary" } title="Agree & Continue">
                Agree & Continue
            </Link>
        ]);
        modalStore.setHeader("Please read law(s) and agree in order to continue");
        modalStore.showModal();
    };

    function handleSorting(field: string, order?: SortingOrder) {
        const newSorting = { field: field, order: order };
        const updatedSortings = sortings || [];
        const found = sortings?.find((element) => element.field === newSorting.field);

        if (found) {
            const index = updatedSortings.indexOf(found);
            if (!newSorting.order) {
                updatedSortings.splice(index, 1);
            } else {
                updatedSortings[index] = newSorting;
            }
        } else {
            updatedSortings.push(newSorting);
        }

        updatedSortings.sort((a, b) => a.field.localeCompare(b.field));
        onSorting(updatedSortings);
    }

    return <div className="mb-4">
        <Accordion className="table-responsive" alwaysOpen>
            <table className="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th>Actions</th>
                        <th>
                            <div className="d-flex flex-row">
                                Brand name
                                <SortingButton
                                    value={ sortings?.find((s) => s.field === "brand_name")?.order }
                                    onSorting={ (order) => handleSorting("brand_name", order) }/>
                            </div>
                        </th>
                        <th>
                            <div className="d-flex flex-row">
                                Item description
                                <SortingButton
                                    value={ sortings?.find((s) => s.field === "prod_name")?.order }
                                    onSorting={ (order) => handleSorting("prod_name", order) }/>
                            </div>
                        </th>
                        <th>{ (postType === "LR" ||  postType === "WR") ? "Item number" : "NYS supplier item number" }
                        </th>
                        <th>Beverage Type</th>
                        <th>Approval or BLR #</th>
                        <th>TTB / CoLA #</th>
                        <th>Package Type</th>
                        <th>Size/Unit</th>
                        <th>Price per bottle</th>
                        <th>Price per case</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    { products && products.map((item, index) => {
                        const productWithErrors: ProductModel = { ...item, errors: item.product_errors };
                        const valid = _.isEmpty(item.product_errors) && _.isEmpty(item.discount_values_errors) && _.isEmpty(item.combo_errors);
                        return (
                            <Fragment key={ index }>
                                <tr>
                                    <td>
                                        <CustomToggle eventKey={ index.toString() } />
                                    </td>
                                    <td className="text-nowrap">
                                        { canAmendPrices() && <button className="btn btn-link py-0 px-1 me-2" onClick={ e=> {e.preventDefault(); showAmendPage(item.id);} }>
                                            <i className="bi-alarm"></i>
                                        </button>
                                        }
                                        { canPostPrices() && <Link
                                            to={ generatePath(WHOLESALER_PATHS.PRICE_POSTING.EDIT_BRAND_ITEM, { "id": item.id }) }
                                            className={ "btn btn-link py-0 px-1 me-2 " + (writePermitted ? "" : "disabled") } title="Edit Product">
                                            <i className="bi-pencil"/>
                                        </Link>
                                        }
                                        { canPostPrices() && <button className="btn btn-link py-0 px-1 me-2" title="Delete Product" type="button"
                                            disabled={ !writePermitted } onClick={ ()=>deleteProductConfirmation(item) }>
                                            <i className="bi-trash"/>
                                        </button>
                                        }
                                    </td>
                                    <td><ErroneousValue item={ productWithErrors } name={ "brand_name" } /></td>
                                    <td><ErroneousValue item={ productWithErrors } name={ "prod_name" } /></td>
                                    <td><ErroneousValue item={ productWithErrors } name={ "prod_item" } /></td>
                                    <td><ErroneousValue item={ productWithErrors } formattedValue={ item.beverage_type?.description } name={ "bev_type" } /></td>
                                    <td><ErroneousValue item={ productWithErrors } name={ "brand_reg" } /></td>
                                    <td><ErroneousValue item={ productWithErrors } name={ "ttb_id" } /></td>
                                    <td><ErroneousValue item={ productWithErrors } name={ "reg_combo" } formattedValue={ PackageTypeConstants.getTitleByCode(item.reg_combo) } /></td>
                                    <td>
                                        <ErroneousValue item={ productWithErrors } name={ "item_size" } />
                                        &nbsp;
                                        <ErroneousValue item={ productWithErrors } name={ "um" } />
                                    </td>
                                    <td><ErroneousValue item={ productWithErrors } name={ "bot_price" } formattedValue={ currencyFormat(item.bot_price) }/></td>
                                    <td><ErroneousValue item={ productWithErrors } name={ "case_price" } formattedValue={ currencyFormat(item.case_price) }/></td>
                                    <td>
                                        <span className={ classNames("badge", valid ? "bg-success" : "bg-danger") }>
                                            <i className={  classNames(valid ? "bi-check-circle": "bi-x-circle") }/>
                                            { valid ? "Valid" : "Invalid" }
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={ 13 } className="p-0">
                                        <Accordion.Collapse eventKey={ index.toString() }>
                                            <div className="p-4">
                                                <ProductTabContainer product={ productWithErrors } tabs={ tabs }/>
                                            </div>
                                        </Accordion.Collapse>
                                    </td>
                                </tr>
                            </Fragment>
                        );
                    }) }
                </tbody>
            </table>
        </Accordion>
        { products.length === 0 &&
            <div className="text-center">
                <p>Records not found.</p>
            </div>
        }
    </div>;
};

const AgreeAndContinueDialog = () => {
    return <div>
        <p>Per ABC law 101-b(4):</p>
        <p>… the authority shall make (filed price schedules) or a composite thereof available for inspection by licensees. Within three business days after such inspection is provided for,&nbsp;
            a wholesaler may amend his filed schedule for sales to retailers in order to meet lower competing prices and discounts for liquor or wine of the same brand or trade name,&nbsp;
            and of like age and quality, filed pursuant to this section by any licensee selling such brand, provided such amended prices are not lower and discounts are not greater than&nbsp;
            those to be met. Any amended schedule so filed shall become effective on the first day of the calendar month following the filing thereof and shall be in effect for such calendar month.
        </p>
        <p className="mb-0">No other online changes are allowed on this schedule during the amendment period.</p>
    </div>;
};

