import { FC, PropsWithChildren, createContext, useContext } from "react";
import { ToastStore } from "store/ToastStore";

const StoreContext = createContext<null | ToastStore>(null);

const toastStore = new ToastStore();

const ToastStoreProvider: FC<PropsWithChildren<any>> = ({ children }) => {
    return(
        <StoreContext.Provider value={ toastStore }>
            { children }
        </StoreContext.Provider>
    );
};

export function useToastStore(): ToastStore {
    const store = useContext(StoreContext);
    if (store === null) {
        throw new Error("Store cannot be null, please add a context provider");
    }
    return store;
}

export { ToastStoreProvider };
