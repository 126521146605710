import Input from "components/form/Input";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { LicenseModel } from "models/license.model";
import FormUtil from "utils/FormUtil";
import { LicenseGroupService } from "services/admin/LicenseGroupService";

export interface Props {
    license: LicenseModel,
    isParent: boolean,
    onSuccess: (license: LicenseModel, isParent: boolean, index: number) => void,
    checkOnDuplicate?: (selector: string) => boolean,
    checkOnSame?: (selector: string, index: number) => boolean,
    onChange: (isParent: boolean, index: number) => void,
    index?: number,
    isEdit?: boolean,
    service: LicenseGroupService,
    entityTitle: string
}

const DialogSearchForm = (props: Props) => {

    const { license, isParent, onSuccess, checkOnDuplicate, onChange, index = -1, isEdit, checkOnSame, service, entityTitle } = props;
    const [change, setChange] = useState<boolean>(isEdit || false);

    const {
        handleSubmit,
        control,
        getValues,
        setValue,
        setError,
        clearErrors,
        reset,
        formState: { errors }
    } = useForm<LicenseModel>({
        mode: "onChange",
        defaultValues: {} as LicenseModel
    });

    useEffect(() => {
        reset(license);
        setValue("licenseSelector", license?.permit_id ? license.permit_id : license?.serial_number?.toString());
        setValue("premise_name", license?.premise_name);
        setValue("premise_address", license?.premise_address);
        setChange(license?.id != undefined);
    }, [license]);

    const onSubmit: SubmitHandler<LicenseModel> = async data => {
        if(checkOnDuplicate && checkOnDuplicate(data.licenseSelector)) {
            setError("licenseSelector", {
                type: "manual", message: "You have already added the same license"
            });
            return;
        }

        if(checkOnSame && checkOnSame(data.licenseSelector, index)) {
            setChange(true);
            return;
        }

        service.checkLicense(data.licenseSelector || "").then(license => {
            clearErrors("licenseSelector");
            setValue("premise_name", license.premise_name);
            setValue("premise_address", license.premise_address);
            setChange(true);
            onSuccess(license, isParent, index);
        }).catch((error) => {
            if (error.status === 404) {
                setError("licenseSelector", {
                    type: "manual", message: "Entered license number does not exist."
                });
            }

            if (error.status === 405) {
                setError("licenseSelector", {
                    type: "manual", message: `The entered license number already exists in the ${entityTitle}s.`
                });
            }
        });
    };

    function onLicenseChange() {
        setChange(false);
        onChange(isParent, index);
    }

    return <form onSubmit={ handleSubmit(onSubmit) }>
        <div className="d-flex flex-row">
            <div className="flex-fill form-group-without-m">
                <Input
                    control={ control } errors={ errors } name="licenseSelector"
                    placeholder="Enter license id or serial number"
                    label={ isParent ? "Master Identifier" : "Secondary Identifier" }
                    type="text"
                    rules={ FormUtil.SERIAL_NUMBER_OR_PERMITID_VALIDATIONS }
                    disabled={ change || (isEdit && isParent) }
                />
            </div>
            { !(isEdit && isParent) &&
                <div className="pt-1">
                    { !change && <input type="submit" className="btn btn-outline-primary mt-4 ms-3" value="Search" /> }
                    { change &&
                        <button
                            type="button" className="btn btn-outline-primary mt-4 ms-3"
                            onClick={ onLicenseChange }
                        >Change</button>
                    }
                </div>
            }
        </div>
        { change &&
            <div className="row mt-2">
                <div className="col-12 col-lg-6">
                    <div className="mt-2">
                        <p className="mb-0"><strong>Premise Name:</strong></p>
                        <p className="mb-0">{ getValues("premise_name") }</p>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="mt-2">
                        <p className="mb-0"><strong>Address:</strong></p>
                        <p className="mb-0">{ getValues("premise_address") }</p>
                    </div>
                </div>
            </div>
        }
    </form>;
};

export default DialogSearchForm;
