import { Page, RegComboType } from "pages/wholesaler/priceposting/pages/brands/components/Page";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import Spinner from "components/Spinner";
import { ManualProduct, createManualProductFromMap } from "pages/wholesaler/priceposting/models/ManualProduct";
import CrudProductService from "services/wholesaler/priceposting/CrudProductService";

export const EditPage = () => {
    const { id = "" } = useParams<{ id: string }>();
    const [product, setProduct] = useState<ManualProduct>();

    useEffect(() => {
        CrudProductService.getProduct(id).then(r =>
            setProduct(createManualProductFromMap(r))
        );
    }, []);

    if (product) {
        return <Page rcType={ product.reg_combo === "C" ? RegComboType.COMBO : RegComboType.REGULAR_VAP }
            postType={ product.post_type }
            editedProduct={ product }
        />;
    } else {
        return <Spinner/>;
    }
};
