import React from "react";

const WholesalerManageScheduleView = () => {

    return <div className="d-flex flex-column">
        <div className="mb-4 mb-md-5">
            <h1 className="text-primary mb-0">Liquor to Retailer - Schedule for November 2022</h1>
        </div>
        <div>
            <h5 className="mb-3 fw-bold">Price Posting Summary</h5>
            <p>The current price posting schedule has been pre-filled with the brand information contained in the last price schedule you submitted online.</p>
            <p>The table below contains a summary of the current price schedule. As you make changes, this summary information is updated.</p>
            <div className="row">
                <div className="col-12 col-lg-6">
                    <div className="pe-xl-4">
                        <div className="table-responsive mt-3">
                            <table className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>Beverage type</th>
                                        <th>Number of items</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <a href="/">Neutral Spirits</a>
                                        </td>
                                        <td>0</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="/">Whiskey</a>
                                        </td>
                                        <td>1</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="/" className="text-danger">Gin</a>
                                        </td>
                                        <td>0</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="/">Brandy</a>
                                        </td>
                                        <td>0</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="/">Rum</a>
                                        </td>
                                        <td>0</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="/">Cordials & Liqueurs</a>
                                        </td>
                                        <td>0</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="/">Cocktails & Specialities</a>
                                        </td>
                                        <td>0</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="/">Imitations</a>
                                        </td>
                                        <td>0</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="/">Tequila</a>
                                        </td>
                                        <td>0</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="/">Other</a>
                                        </td>
                                        <td>0</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="ps-xl-4">
                        <div className="table-responsive mt-3">
                            <table className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>Package type</th>
                                        <th>Number of items</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <a href="/">Regular</a>
                                        </td>
                                        <td>0</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="/">Combo</a>
                                        </td>
                                        <td>1</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="/">VAP</a>
                                        </td>
                                        <td>0</td>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td>1</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="mt-4">
            <h5 className="mb-3 fw-bold">Price Posting Actions</h5>
            <div className="content-styles">
                <ul>
                    <li><a href="/wholesaler-static/manage-brands-items" className="mb-0">Manage Brands & Items</a></li>
                    <li><a href="/wholesaler-static/manage-discounts" className="mb-0">Manage Discounts</a></li>
                    <li><a href="/wholesaler-static/edit-posting-profile" className="mb-0">Edit posting Profile</a></li>
                    <li><a href="/wholesaler-static/certify" className="mb-0">Certify Schedule</a></li>
                    <li className="form-label-with-tooltip">
                        <span className="mb-0">Certify Schedule</span>
                        <div className="tooltip-wr tooltip-wr-small ms-2">
                            <span className="tooltip-icon bi-info-circle-fill text-danger"></span>
                            <div className="tooltip-msg text-center">
                                <p>The schedule can not be certified due to errors. You can correct them in interactive mode or re-upload the corrected information.</p>
                            </div>
                        </div>
                    </li>
                    <li><a href="/" className="mb-0">Revert Schedule to Most Recent Certified Schedule</a></li>
                </ul>
            </div>
        </div>
    </div>;
};

export default WholesalerManageScheduleView;

