import React, { useEffect, useState } from "react";
import { SelectControlled, SelectOption } from "components/form/Select";
import { SubmitHandler, useForm } from "react-hook-form";
import { PageInfo } from "models/PageInfo";
import URLUtil from "utils/URLUtil";
import { PageableRequest } from "models/requests/PageableRequest";
import { DEFAULT_PAGE_LIMIT } from "api/HttpClient";
import { WHOLESALER_PATHS } from "pages/wholesaler/WholesalerRoutes";
import WholesalerDelinquentService from "services/wholesaler/WholesalerDelinquentService";
import { SearchDelinquentWholesalerRequest } from "models/requests/SearchDelinquentWholesalerRequest";
import Spinner from "components/Spinner";
import { Link, generatePath, useNavigate } from "react-router-dom";
import { showLicenseDelinquentInfo } from "components/dialogs/LicenseDelinquentInfo";
import { DelinquentSearchResponse } from "models/response/DelinquentSearchResponse";
import LicenseStatusCell from "components/LicenseStatusCell";
import DateUtil from "utils/DateUtil";
import Paginator from "components/Paginator";
import DatePickerRangeInput from "components/form/DatePickerRangeInput";
import { useAuthStore } from "store/AuthStoreProvider";
import { useToastStore } from "store/ToastStoreProvider";
import { useKeyDownEnterEvent } from "components/KeyDownHook";

type SearchParams = SearchDelinquentWholesalerRequest & PageInfo;

const premiseTypes: SelectOption[] = [
    { value: "1", title: "New added" },
    { value: "2", title: "New removed" }
];

const licenseTypes: SelectOption[] = [
    {   value: "0", title: "ALL" },
    {   value: "1", title: "Off Premises Beer" },
    {   value: "2", title: "Off Premises Wine / Liquor" },
    {   value: "3", title: "On Premises" }
];

const historyRangesOptions: SelectOption[] = [
    { value: 3, title: "Complete Listing" },
    { value: 0, title: "Date Range - Last 7 days" },
    { value: 1, title: "Date Range - Last 14 days" },
    { value: 2, title: "Date Range - Last 30 days" },
    { value: 4, title: "Custom Date Range" }
];

const urlParams = URLUtil.readSearchParams() as SearchParams;

const ListingSearch = () => {
    const navigate = useNavigate();
    const { addDefaultDangerToast } = useToastStore();
    const { permissionStore } = useAuthStore();
    const [ delinquentRecords, setDelinquentRecords ] = useState<DelinquentSearchResponse[]>([]);
    const [ isDataLoading, setIsDataLoading ] = useState<boolean>(false);
    const [ pageNumber, setPageNumber ] = useState<number>(urlParams?.page || 1);
    const [ pagesCount, setPagesCount ] = useState<number>(0);
    const [ totalItemsCount, setTotalItemsCount ] = useState<number>(0);
    const [ pageLimit ] = useState<number>(urlParams?.limit || DEFAULT_PAGE_LIMIT);
    const [ lastRequest, setLastRequest] = useState<PageableRequest>();
    const [ historyRanges, setHistoryRanges] = useState<SelectOption[]>(historyRangesOptions);

    useEffect(() => {
        performQuery(urlParams);
    }, []);

    const {
        handleSubmit,
        getValues,
        control,
        reset,
        watch
    } = useForm<SearchParams>({
        mode: "onSubmit",
        defaultValues: formDefaultsFromURLParams()
    });
    useKeyDownEnterEvent(() => !isDataLoading && handleSubmit(onSubmit)());
    const historyRangeWatch = watch("history_range");
    const premisesTypeWatch = watch("premises_type");

    const onSubmit: SubmitHandler<SearchParams> = async data => {
        setPageNumber(1);
        data.page = 1;
        data.limit = pageLimit;
        updateUrlParams(data);
        performQuery(data);
    };

    function updateUrlParams(data: SearchParams) {
        URLUtil.updateUrlParams(WHOLESALER_PATHS.DELINQUENT.SEARCH, {
            ...data,
            dates: (data.history_range == 4 && data.dates && data.dates[0] !== null && data.dates[1] !== null)
                ? DateUtil.formatDate(data.dates[0]) + "," + DateUtil.formatDate(data.dates[1])
                : undefined
        }, navigate);
    }

    function formDefaultsFromURLParams(): SearchParams{
        let definedDates = undefined;
        if (urlParams.dates){
            const d = urlParams.dates.toString().split(",");
            definedDates = [ DateUtil.parseString(d[0]), DateUtil.parseString(d[1]) ];
        }

        return {
            license_type: urlParams.license_type || 0,
            premises_type: urlParams.premises_type || "1",
            history_range: urlParams.history_range || 3,
            dates: definedDates
        } as SearchParams;
    }

    function performQuery(data: SearchParams) {
        const hr = getValues("history_range");
        const request = {
            pageInfo: { page: data.page, limit: data.limit },
            filters: {
                license_type: getValues("license_type"),
                premises_type: getValues("premises_type"),
                history_range: hr
            }
        } as PageableRequest;

        if (hr == 4){
            const dates = getValues("dates");
            if (typeof(dates) === "undefined" || dates === null || dates[0] === null || dates[1] === null){
                return;
            }
            request.filters.date_from = (dates[0] as Date).getTime();
            request.filters.date_to = (dates[1] as Date).getTime();
        }

        setIsDataLoading(true);
        setLastRequest(request);

        WholesalerDelinquentService.searchDelinquent(request).then(response => {
            setTotalItemsCount(response.pageInfo.totalItemsCount || 0);
            setPagesCount(response.pageInfo.pagesCount || 0);
            setPageNumber(response.pageInfo.page || 1);
            setDelinquentRecords(response.data);
        }).catch(() => addDefaultDangerToast("Delinquent Search")
        ).finally(() => setIsDataLoading(false));
    }

    function handlePageClick(pageNumber: number) {
        setPageNumber(pageNumber);
        const searchParams = getValues() as SearchParams;
        searchParams.page = pageNumber;
        searchParams.limit = pageLimit;

        updateUrlParams(searchParams);
        performQuery(searchParams);
    }

    return <div className="d-flex flex-column min-vh-100">
        <div className="mb-4 mb-md-5">
            <h1 className="text-primary mb-4">Delinquent Licenses</h1>
            <div className="d-flex flex-column flex-xl-row align-items-center">
                <div className="d-flex flex-row flex-grow-1 align-items-center me-xl-4 mb-3 mb-xl-0 listing-search-head">
                    <p className="mb-0">
                        Use the form below to search for delinquent licensees registered in the system.&nbsp;
                        Also, you can search for delinquencies, those added within a specific date range, or those removed within a specific date range.&nbsp;
                        The results will show relevant license information. Click a license ID for full details.
                    </p>
                    <div className="tooltip-wr ms-2">
                        <span className="tooltip-icon bi-question-circle-fill"/>
                        <div className="tooltip-msg">
                            <p><strong>Delinquent Licenses Overview</strong></p>
                            <small>
                                <p>
                                    Pursuant to Sections 101-A, 101-AA, and/or 101-AAA of the A.B.C. Law, notice is hereby given that the following retail licensees have been&nbsp;
                                    reported for non-compliance with the requirements of the said sections.
                                </p>
                                <p>
                                    All retail licensees whose names appear on this list are required to pay cash for all purchases of alcoholic beverages until their names have been removed&nbsp;
                                    from a subsequent list or they have received a release in writing from the State Liquor Authority.
                                </p>
                                <p>
                                    &quot;Cash&ldquo; means and includes currency and coin of the United States of America, certified check, money order, bank officer&apos;s check or draft, or a check drawn on the account of the&nbsp;
                                    retail licensee payable to the manufacturer or retailer and dated no later than the date of delivery of the alcoholic beverages and which is honored by the bank upon presentment for payment.
                                </p>
                                <p>
                                    A retail license is one who sells to a consumer or to any person other than for resale. Accordingly, &quot;Retail Licensee&ldquo; includes a vendor, railroad car, steamship, fishing vessel,&nbsp;
                                    ball park, drug store, summer licensee or other person or premises licensed to sell liquor, wine or beer at retail.
                                </p>
                            </small>
                        </div>
                    </div>
                </div>
                { permissionStore.isWholesalerAndBA_CR_CW_CA_CB() &&
                    <Link to={ generatePath(WHOLESALER_PATHS.DELINQUENT.SEARCH_DOWNLOAD) } className="btn btn-outline-primary text-nowrap">Batch Search</Link>
                }
            </div>
        </div>

        <div className="card">
            <div className="card-body">
                <form onSubmit={ handleSubmit(onSubmit) }>
                    <div className="row">
                        <div className="col-12 col-md-6 col-xl-3">
                            <SelectControlled name="history_range" control={ control } options={ historyRanges }
                                label="Select Report Type"
                            />
                        </div>
                        <div className="col-12 col-md-6 col-xl-3">
                            <SelectControlled name="license_type" control={ control } options={ licenseTypes }
                                label="Select License Type"
                            />
                        </div>
                        { !(historyRangeWatch == 3) &&
                            <div className="col-12 col-md-6 col-xl-3">
                                <SelectControlled name="premises_type" control={ control } options={ premiseTypes }
                                    label="Delinquency Type"
                                />
                            </div>
                        }
                        { historyRangeWatch == 4 &&
                            <div className="col-12 col-md-6 col-xl-3">
                                <DatePickerRangeInput
                                    name="dates"
                                    control={ control }
                                    label="Date Range"
                                    maxDate={ new Date() }
                                    required={ true }
                                />
                            </div>
                        }
                    </div>
                    <div className="row justify-content-center mt-2">
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                            <button className="btn btn-outline-secondary w-100" onClick={ () => reset() }>
                                Clear Form
                            </button>
                        </div>
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                            <input className="btn btn-primary w-100" type="submit" value="Search" />
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <Spinner className="mt-4 pt-2 text-center" hidden={ !isDataLoading } />

        { !isDataLoading &&
            <div className="mt-4 mb-2 d-flex flex-column flex-lg-row align-items-center">
                { (historyRangeWatch == 3) &&
                    <div className="me-lg-auto pt-3 mb-3 mb-lg-0">
                        <h5 className="mb-0"><strong>{ totalItemsCount }</strong> delinquent licenses found matching the search criteria</h5>
                    </div>
                }
                { !(historyRangeWatch == 3) &&
                    <div className="me-lg-auto pt-3 mb-3 mb-lg-0">
                        <h5 className="mb-0"><strong>{ totalItemsCount }</strong> licenses { premisesTypeWatch == "1" ? "added" : "removed" } to the delinquent list for the selected period</h5>
                    </div>
                }
                { (!isDataLoading && delinquentRecords.length > 0 && lastRequest !== undefined) &&
                    <div className="pt-3 ms-lg-3 mt-2">
                        <button type="button" className="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi-download me-2"/>Export
                        </button>
                        <ul className="dropdown-menu">
                            <Link
                                to={ WholesalerDelinquentService.searchDelinquentCsv(lastRequest) }
                                target="_blank"
                                className="dropdown-item">
                                CSV
                            </Link>
                            <Link
                                to={ WholesalerDelinquentService.searchDelinquentTxt(lastRequest) }
                                target="_blank"
                                className="dropdown-item">
                                TXT
                            </Link>
                        </ul>
                    </div>
                }
            </div>
        }

        { !isDataLoading && delinquentRecords.length > 0 &&

            <div>
                <div className="wrapper1">
                    <div className="div1">
                    </div>
                </div>
                <div className="wrapper2">
                    <div className="div2">
                        <div className="table-responsive mt-2">
                            <table className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>License ID</th>
                                        <th>Serial Number</th>
                                        <th>Premises Name</th>
                                        <th>Type</th>
                                        <th>Class</th>
                                        <th>Expire date</th>
                                        <th>License Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { delinquentRecords.map(record => {
                                        return <tr key={ record.id }>
                                            <td>
                                                <button className="btn btn-link" onClick={ () => showLicenseDelinquentInfo(record.id) }>
                                                    <div>
                                                        { record.permit_id }
                                                    </div>
                                                </button>
                                            </td>
                                            <td>
                                                <button className="btn btn-link" onClick={ () => showLicenseDelinquentInfo(record.id) }>
                                                    <div>
                                                        { record.serial_number }
                                                    </div>
                                                </button>
                                            </td>
                                            <td> { record.premise_name } </td>
                                            <td> { record.type } </td>
                                            <td> { record.class } </td>
                                            <td> { record.expiration_date } </td>
                                            <td>
                                                <LicenseStatusCell statusCode={ record.license_status } />
                                            </td>
                                        </tr>;
                                    }) }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        }
        <div>
            <Paginator totalPages={ pagesCount } currentPage={ pageNumber } pageClicked={ handlePageClick }/>
        </div>
    </div>;

};

export default ListingSearch;
