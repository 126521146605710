import { SelectOption } from "components/form/Select";

export const TYPE_OPTIONS = [
    { value: "$" },
    { value: "%" }
] as SelectOption[];

export const UNIT_OPTIONS = [
    { value: "C", title:"Case" },
    { value: "B", title:"Bottle" }
] as SelectOption[];

export const unitString = (unit: string) =>
    UNIT_OPTIONS.find(c=>unit && c.value == unit.toUpperCase())?.title ;
