import React from "react";
import Button from "react-bootstrap/Button";

const ChooseLicense = () => {

    return <div className="registration-block">
        <div className="mb-4 mb-md-5 text-center">
            <h1 className="text-primary mb-4">Wholesaler Login</h1>
            <h5 className="fw-bold">Welcome back, John!</h5>
            <p className="mb-0">Select an account you want to login into.</p>
            <p className="mb-0"><strong>Note:</strong> you can select different account after being logged in.</p>
        </div>
        <form className="card login-block">
            <div className="card-body">
                <div className="form-group">
                    <label htmlFor="Select" className="form-label">Select an account</label>
                    <select id="Select" className="form-select" aria-label="Select">
                        <option selected>Select account</option>
                        <option value="1">4200 GENESEE ST 2D09 - 3164970</option>
                        <option value="2">1008 WESTCHESTER AVE FOX ST & SIMPSON ST - 1195594</option>
                        <option value="3">10014 QUEENS BLVD - 1268219</option>
                    </select>
                </div>
                <div className="row justify-content-center mt-4">
                    <div className="col-6 col-md-5 col-lg-5 mt-1">
                        <div className="pe-md-1">
                            <a className="btn btn-outline-secondary w-100" href="/">
                                Cancel
                            </a>
                        </div>
                    </div>
                    <div className="col-6 col-md-5 col-lg-5 mt-1">
                        <div className="ps-md-1">
                            <Button className="btn-primary btn w-100" disabled>
                                Proceed
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>;
};

export default ChooseLicense;

