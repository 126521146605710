import {
    PageableRequest,
    pageableRequestToQueryString
} from "models/requests/PageableRequest";
import { PageableResponse } from "models/response/PageableResponse";
import { ProductModel } from "models/ProductModel";
import httpClient from "api/HttpClient";
import BACKEND from "services/Constants";

const root = `${BACKEND}/wholesaler/brand`;
export default {
    search(request: PageableRequest, isDownloadCSV?: boolean): Promise<PageableResponse<ProductModel>> {
        const format: string = isDownloadCSV ? ".csv" : "";
        return httpClient.search( `${root}/search${format}`, request);
    },

    isRecordsPresent(type: string, year: string, month: string, certifiedNeeded = false): Promise<boolean> {
        return httpClient.get( `${root}/is-records-presents`,
            { params: { type: type, year: year, month: month, certifiedNeeded: certifiedNeeded } });
    },

    downloadCsv(request: PageableRequest, forBatch?: boolean) {
        const pageable = pageableRequestToQueryString(request);
        return `${root}/search.csv?${pageable}${forBatch?"&forBatch":""}` ;
    }
};
