import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha-enterprise";
import { ProductModel } from "models/ProductModel";
import Paginator from "components/Paginator";
import DateUtil from "utils/DateUtil";
import { PageInfo } from "models/PageInfo";
import ProductService from "services/ProductService";
import URLUtil from "utils/URLUtil";
import SelectPostType from "components/form/select/SelectPostType";
import ProductConstants, { PostTypeCode } from "services/ProductConstants";
import Spinner from "components/Spinner";
import { SelectDistributors } from "components/form/select/SelectDistributors";
import OrganizationService from "services/OrganizationService";
import { DistributorModel } from "models/DistributorModel";
import CurrencyText from "components/CurrencyText";
import { GRECAPTCHA_SITEKEY } from "config";
import { useTimeStore } from "store/TimeStoreProvider";
import { PriceMonthConstants } from "services/PriceMonthConstants";
import { showDistributorInfo } from "components/dialogs/DistributorInfoDialog";
import { Dayjs } from "dayjs";
import { FillingPeriodInformation } from "components/FillingPeriodInformation";
import { DiscountValuesList } from "components/DiscountValuesList";
import { useNavigate } from "react-router-dom";
import { PageableRequest } from "models/requests/PageableRequest";
import { LoadingState, PromiseExecutorArgsType } from "services/RecaptchaService";

export const PUBLIC_PRICES = {
    LOOKUP: "/public/price-lookup"
};

type SearchParams = {
    post_type: string,
    license_id: number,
    query: string
} & PageInfo;

const PricesLookupView = () => {
    const { newYorkTime } = useTimeStore();
    const navigate = useNavigate();
    const [urlParams, setUrlParams] = useState<SearchParams>(URLUtil.readSearchParams() as SearchParams);
    const [products, setProducts] = useState<ProductModel[]>([]);
    const [currentDistributor, setCurrentDistributor] = useState<DistributorModel>();
    const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.LOADING);
    const [postingDate, setPostingDate] = useState<Dayjs>();
    const [distributorData, setDistributorData] = useState<DistributorModel[]>([]);

    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const [captchaPromise, setCaptchaPromise] = useState<PromiseExecutorArgsType>();

    useEffect(() => {
        urlParams.license_id && getCurrentDistributor(urlParams.license_id);
        urlParams.license_id && getDistributorData(urlParams.license_id);
        DateUtil.getCurrentPricingDate().then(date => { setPostingDate(date); });
    }, []);

    useEffect(() => {
        URLUtil.updateUrlParams(PUBLIC_PRICES.LOOKUP, urlParams, navigate, ["pagesCount"]);
        urlParams.post_type && urlParams.license_id && performQuery();
    }, [urlParams.post_type, urlParams.license_id, urlParams.query, urlParams.page]);

    /* eslint-disable @typescript-eslint/no-explicit-any */
    function handleSelectedType(event: any) {
        setUrlParams({ post_type: event.target.value } as SearchParams);
        setCurrentDistributor(undefined);
        setProducts([]);
        setLoadingState(LoadingState.LOADING);
    }

    function performQuery() {
        const request = {
            pageInfo: { page: urlParams.page },
            filters: {
                post_type: urlParams.post_type,
                license_id: urlParams.license_id,
                query: urlParams.query
            }
        } as PageableRequest;

        setLoadingState(LoadingState.LOADING);
        ProductService.searchByPublicUser(request, showCaptcha )
            .then(response => {
                setProducts(response.data || []);
                setUrlParams(prevState => ({
                    ...prevState,
                    pagesCount: response.pageInfo.pagesCount || 1
                }));
                setLoadingState(LoadingState.DATA_DISPLAYED);
            })
            .catch(() => setLoadingState(LoadingState.DATA_LOADING_ERROR));
    }

    function getCurrentDistributor(licenseId: number) {
        OrganizationService.getDistributor(licenseId).then(distributor => {
            setCurrentDistributor(distributor);
        });
    }

    function handleDistributorSelection(licenseId: number) {
        if (urlParams.license_id === licenseId) return;
        getCurrentDistributor(licenseId);
        setUrlParams(prevState => ({
            ...prevState,
            license_id: licenseId,
            query: "",
            page: 1
        }));

        getDistributorData(licenseId);
    }

    function getDistributorData(licenseId: number) {
        OrganizationService.getDistributorsByLicenseAndPostType(licenseId, urlParams.post_type, month)
            .then(setDistributorData)
            .catch(() => setDistributorData([]));
    }

    function getAgentOwnerString(label_type: string): string {
        if (label_type === "A") {
            return "Agent";
        } else {
            if (label_type === "O") {
                return "Owner";
            } else {
                return "";
            }
        }
    }

    function showCaptcha(): Promise<string>{
        if (recaptchaRef.current){
            recaptchaRef.current.reset();
            setLoadingState(LoadingState.CAPTCHA_DISPLAYED);

            return new Promise((resolve,reject)=>
                setCaptchaPromise({ resolve: resolve, reject: reject })
            );
        }
        return Promise.reject();
    }

    const month = newYorkTime.date() >= 15 ? PriceMonthConstants.NEXT_MONTH : PriceMonthConstants.CURRENT_MONTH;

    return (
        <div className="d-flex flex-column">
            <div className="mb-4 mb-md-5">
                <h1 className="text-primary mb-4">Price Lookup</h1>
                <p>View the currently published New York State wholesaler price postings.</p>
                <FillingPeriodInformation/>
            </div>
            <div className="card">
                <div className="card-body pb-0">
                    <div className="row selects align-items-end mb-3">
                        <div className="col-12 col-md-6 col-lg-4 col-xl-3 type">
                            <div className="form-group">
                                <SelectPostType
                                    name="priceType"
                                    value={ urlParams.post_type }
                                    onChange={ handleSelectedType }
                                    isAll={ true }
                                />
                            </div>
                        </div>
                        { urlParams.post_type &&
                            <>
                                <div className="col-12 col-md-6 col-lg-4 col-xl-5 distributors">
                                    <SelectDistributors
                                        name="license"
                                        label="Choose distributor"
                                        value={ urlParams.license_id?.toString() }
                                        postType={ urlParams.post_type }
                                        onChange={ handleDistributorSelection }
                                        month={ month }
                                    />
                                </div>
                            </>
                        }
                    </div>
                </div>
            </div>

            { urlParams.post_type && urlParams.license_id &&
                <div>
                    { loadingState === LoadingState.LOADING &&
                        <Spinner className="mt-4 pt-2 text-center"/>
                    }

                    { loadingState === LoadingState.DATA_DISPLAYED &&
                        <>
                            { products.length === 0
                                ? <h5 className="mt-4 pt-2 text-center">Results are not found</h5>
                                : <div className="mt-2">
                                    { distributorData && distributorData.length != 0 &&
                                        <div className="border">
                                            <h4 className="p-lg-2 form-label">Index of Wholesalers and/or Distributors</h4>
                                            <div className="ml-1 mb-0-5">
                                                { distributorData.map((item, index) =>
                                                    <p key={ index }>{ item.premise_name?.trim() + " (" + item.county?.trim() + ") - " + (item.permit_id ? item.permit_id : item.serial_number) }</p>
                                                ) }
                                            </div>
                                        </div>
                                    }
                                    <div className="table-responsive mt-4">
                                        <table className="table table-bordered table-hover non-selectable" >
                                            <thead>
                                                <tr>
                                                    <th colSpan={ 5 }>Distributor: <span
                                                        className="text-uppercase">{ currentDistributor?.premise_name }</span>
                                                    </th>
                                                    <th colSpan={ 8 }>Posting type: <span
                                                        className="text-uppercase">{ ProductConstants.postTypeByString(urlParams.post_type as PostTypeCode).description } Price</span>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th colSpan={ 5 }>Posting Month: <span
                                                        className="text-uppercase">{ postingDate?.format("MMMM, YYYY") }</span></th>
                                                    <th colSpan={ 2 }>Prices, $ </th>
                                                    <th colSpan={ 6 }>
                                                        License # <span className="text-uppercase">{ currentDistributor?.permit_id }</span>
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th>TTB#<br/>BrandReg#</th>
                                                    <th>Brand name</th>
                                                    <th>Type</th>
                                                    <th>Item</th>
                                                    <th>Size</th>
                                                    <th>Per bottle</th>
                                                    <th>Per case</th>
                                                    <th>Bot / Case</th>
                                                    <th>Alc.Cont</th>
                                                    <th>Discounts</th>
                                                    <th>Combo / Ltd.Avail</th>
                                                    <th>Age</th>
                                                    <th>Owner / Agent</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { products.map((item, index) => {
                                                    return (
                                                        <tr key={ index }>
                                                            <td>{ item.ttb_id }<br/>{ item.brand_reg }</td>
                                                            <td>
                                                                { item.brand_name &&
                                                                    <button className="btn btn-link" onClick={ () => {
                                                                        showDistributorInfo(currentDistributor?.id || -1, DateUtil.getMonth(postingDate), postingDate?.year() || 0, urlParams.post_type);
                                                                    } }>
                                                                        { item.brand_name }
                                                                    </button>
                                                                }
                                                            </td>
                                                            <td>{ item.beverage_type?.description }</td>
                                                            <td>{ item.prod_name }</td>
                                                            <td>{ item.item_size }{ item.um }</td>
                                                            <td><CurrencyText value={ item.bot_price } /></td>
                                                            <td><CurrencyText value={ item.case_price } /></td>
                                                            <td>{ item.botpercase }</td>
                                                            <td>{ ProductConstants.alcoholContentString(item.alcohol) }</td>
                                                            <td>
                                                                <DiscountValuesList values={ item.discount_values } showTitle={ false } highlightAmount={ true }/>
                                                            </td>
                                                            <td>{ item.availability }</td>
                                                            <td>{ item.vintage }</td>
                                                            <td>{ getAgentOwnerString(item.label_type) }</td>
                                                        </tr>
                                                    );
                                                }) }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                            <Paginator
                                totalPages={ urlParams.pagesCount || 1 } currentPage={ urlParams.page }
                                pageClicked={ (page: number) => setUrlParams(prevState => ({ ...prevState, page: page })) }/>
                        </>
                    }

                    { loadingState === LoadingState.DATA_LOADING_ERROR &&
                        <h5 className="mt-4 pt-2 text-center">Data loading error</h5>
                    }

                    <div className={ "mt-4 " + (loadingState === LoadingState.CAPTCHA_DISPLAYED ? "d-inline-block" : "d-none") }>
                        <ReCAPTCHA ref={ recaptchaRef } sitekey={ GRECAPTCHA_SITEKEY }
                            onChange={ (token: string | null) => token && captchaPromise?.resolve(token) }
                            onErrored={ ()=>captchaPromise?.reject() }/>
                    </div>
                </div>
            }

            <div className="mt-5">
                <h5>Disclaimer:</h5>
                <p>A license status check provides information taken from the State Liquor Authority license
                    data base. Before relying on this information, you should be aware that due to workload and administrative issues there may be relevant information that has hot yet been
                    entered into the Authority&apos;s data base. Please contact the nearest zone office for verification of critical data.</p>
            </div>
        </div>
    );
};

export default PricesLookupView;
