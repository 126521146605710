import React, { useEffect } from "react";
import { useToastStore } from "store/ToastStoreProvider";
import { redirectToHomePage } from "services/RedirectService";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "store/AuthStoreProvider";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";

const AccessDenied = () => {
    const { addToast } = useToastStore();
    const navigate = useNavigate();
    const { permissionStore } = useAuthStore();
    useEffect(() => {
        addToast({ message:"You will be redirected to the home page.", header: "Access Denied", variant: "warning", timeoutMs: DEFAULT_TOASTER_TIMEOUT });
        redirectToHomePage(permissionStore, navigate);
    }, []);

    return <>
        <div className="mb-4 mb-md-5 text-center">
            <h1 className="text-primary mb-4">Access denied</h1>
        </div>
    </>;
};

export default AccessDenied;
