import React, { useEffect, useState } from "react";
import BatchService                   from "services/wholesaler/BatchDelinquentService";
import Paginator                      from "components/Paginator";
import { PageableRequest }            from "models/requests/PageableRequest";
import { BatchDelinquentRecordsJob }  from "models/response/BatchDelinquentRecordsJob";
import { PageInfo }                   from "models/PageInfo";
import URLUtil from "utils/URLUtil";
import { DEFAULT_PAGE_LIMIT } from "api/HttpClient";
import { WHOLESALER_PATHS } from "pages/wholesaler/WholesalerRoutes";
import DateUtil from "utils/DateUtil";
import { Link, useNavigate } from "react-router-dom";


const BatchHistory = () => {

    const urlParams = URLUtil.readSearchParams() as PageInfo;
    const navigate = useNavigate();
    const [ pageNumber, setPageNumber ] = useState<number>(urlParams?.page || 1);
    const [ pagesCount, setPagesCount ] = useState<number>(0);
    const [ pageLimit ] = useState<number>(urlParams?.limit || DEFAULT_PAGE_LIMIT);
    const [ data, setData ] = useState<BatchDelinquentRecordsJob[] | undefined>([]);

    useEffect(()=>{
        updateUrlParams();
        performQuery();
    },[pageNumber]);

    function updateUrlParams() {
        URLUtil.updateUrlParams(WHOLESALER_PATHS.DELINQUENT.BATCH_HISTORY,
            { "page": pageNumber,"limit": pageLimit }, navigate);
    }

    function performQuery() {
        const request = { pageInfo: { page: pageNumber, limit: pageLimit } } as PageableRequest;

        BatchService.index(request)
            .then(response => {
                setPagesCount(response.pageInfo.pagesCount || 1);
                setData(response.data);
            });
    }

    return <div className="d-flex flex-column min-vh-100">
        <div className="d-flex flex-column flex-xl-row flex-wrap align-items-center mb-4">
            <h1 className="text-primary mb-4 me-xl-3">Delinquent Records - Batch Submission</h1>
            <Link className="btn btn-outline-secondary mb-4 ms-xl-auto" to={ WHOLESALER_PATHS.DELINQUENT.MANAGE }>Return to delinquent records</Link>
        </div>

        <h4 className="mb-3">Import History</h4>

        { data && data.length > 0 &&
            <>
                <div className="table-responsive mb-4">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Importer</th>
                                <th>Uploaded</th>
                                <th>Filename</th>
                                <th>Correct rows</th>
                                <th>Incorrect rows</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            { data.map(job=>
                                <tr key={ job.id }>
                                    <td>{ job.userFirstName }&nbsp;{ job.userLastName }</td>
                                    <td>{ DateUtil.formatWithServerTZ(job.updated_at) }</td>
                                    <td>
                                        {  job.s3_key
                                            ? <Link to={ BatchService.download(job.id) } target="_blank" download>
                                                { job.filename }
                                            </Link>
                                            : job.filename
                                        }
                                    </td>
                                    <td>{ job.total_correct_line_count }</td>
                                    <td>{ job.total_wrong_line_count }</td>
                                    <td>
                                        { job.status === "IN_PROGRESS" &&
                                            <span className="badge bg-info">
                                                <i className="bi-clock-history"/>
                                                In Progress
                                            </span>
                                        }
                                        { job.status === "ERROR" &&
                                            <span className="badge bg-danger">
                                                <i className="bi-exclamation-triangle"/>
                                                Error
                                            </span>
                                        }
                                        { job.status === "CANCELLED" &&
                                            <span className="badge bg-secondary">
                                                <i className="bi-x-circle"/>
                                                Cancelled
                                            </span>
                                        }
                                        { job.status === "COMPLETED" &&
                                            <span className="badge bg-success">
                                                <i className="bi-check-circle"/>
                                                Completed
                                            </span>
                                        }
                                    </td>
                                </tr>
                            ) }
                        </tbody>
                    </table>
                </div>
                <Paginator totalPages={ pagesCount } currentPage={ pageNumber } pageClicked={ setPageNumber }/>
            </>
        }
    </div>;
};

export default BatchHistory;
