import React, { ChangeEvent, useState } from "react";
import { Link, generatePath, useNavigate } from "react-router-dom";
import BatchService                   from "services/wholesaler/BatchDelinquentService";
import { WHOLESALER_PATHS }           from "pages/wholesaler/WholesalerRoutes";
import { useToastStore } from "store/ToastStoreProvider";
import Spinner from "components/Spinner";

const Batch = () => {

    const navigate = useNavigate();
    const { addDefaultDangerToast } = useToastStore();
    const [ selectedFile, setSelectedFile ] = useState<File>();
    const [ isLoading, setIsLoading ] = useState<boolean>(false);

    function handleChange(event: ChangeEvent) {
        const target: HTMLInputElement = event.target as HTMLInputElement;
        if (target !== null && target.files !== null)
            setSelectedFile(target.files[0]);
    }

    function uploadCsv(){
        if (selectedFile===undefined)
            return;

        setIsLoading(true);
        const data = new FormData();
        data.append("file", selectedFile as Blob, selectedFile?.name);
        BatchService.create()
            .then(jobId=> BatchService.upload(jobId, data))
            .then(jobId=> navigate(generatePath(WHOLESALER_PATHS.DELINQUENT.BATCH_PREVIEW, { "jobId": jobId })))
            .catch(() => addDefaultDangerToast("Batch delinquent upload"))
            .finally(() => setIsLoading(false));
    }

    return <div className="d-flex flex-column min-vh-100">
        <div className="mb-3">
            <h1 className="text-primary mb-4">Delinquent Records - Batch Submission</h1>
            <p className="mb-0">Select CSV file with delinquency record and click &ldquo;Upload&rdquo; button.</p>
        </div>
        <div className="mb-4">
            <a
                className="btn btn-outline-primary me-3 mb-3" target="_blank" rel="noreferrer"
                href="https://support.nyslapricepostings.com/s/article/Credit-Reporting-Tutorial-for-Wholesalers">
                Read more about Batch Submission
            </a>
            <a className="btn btn-outline-primary mb-3" download href="/files/batch_template.csv">CSV Template</a>
        </div>
        <div className="steps-wr mb-4">
            <div className="steps-l row g-0">
                <div className="steps-li col-12 col-xl-3 active">
                    <div className="steps-li-in">
                        <strong>1</strong> Select CSV file to upload
                    </div>
                </div>
                <div className="steps-li col-12 col-xl-3">
                    <div className="steps-li-in">
                        <strong>2</strong> Preview batch submission
                    </div>
                </div>
            </div>
        </div>

        <div className="text-start mb-3">
            <button className="batch-link collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseExample"
                aria-expanded="false" aria-controls="collapseExample">
                View Example
                <i className="bi bi-chevron-down ms-2"></i>
            </button>
        </div>
        <div className="collapse" id="collapseExample">
            <div className="content-styles">
                <p><strong>Use the example below as a guide while following a few simple rules:</strong></p>
                <ul>
                    <li>Only the fields in the example are permitted</li>
                    <li><strong>&ldquo;Intent&rdquo;</strong> can be either &ldquo;A&rdquo; for Add
                        or &ldquo;R&rdquo; for
                        Remove. Required.
                    </li>
                    <li>
                        <strong>&ldquo;Wholesaler_License&rdquo;</strong> and <strong>&ldquo;Retailer_License&rdquo;</strong>.
                        Required.
                    </li>
                    <li><strong>&ldquo;Delivery_Amount&rdquo;</strong> must be valid number with maximum possible value
                        9999999.99. Required.
                    </li>
                    <li><strong>&ldquo;Delivery_Date&rdquo;</strong> must be in the &apos;mm/dd/yyyy&apos; format.
                        Required.
                    </li>
                    <li><strong>&ldquo;Comment&rdquo;</strong> can not be longer 200 characters. Optional.</li>
                    <li><strong>&ldquo;Retailer_Name&rdquo;</strong> can not be longer 300 characters. Optional.</li>
                </ul>
                <br/>
                <h4 className="mb-3">Example</h4>
                <div className="table-responsive mb-4">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Column name</th>
                                <th>Example value</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Intent</td>
                                <td>A</td>
                                <td>&ldquo;Intent&rdquo; with either be &ldquo;A&rdquo; for Add or &ldquo;R&rdquo; for
                                    Remove
                                </td>
                            </tr>
                            <tr>
                                <td>Wholesaler_License</td>
                                <td>9999-99-999999 or 9999999</td>
                                <td>Wholesaler license id or serial number</td>
                            </tr>
                            <tr>
                                <td>Retailer_License</td>
                                <td>8000-00-111111</td>
                                <td>Retailer license id or serial number</td>
                            </tr>
                            <tr>
                                <td>Delivery_Amount</td>
                                <td>11.11</td>
                                <td>Delinquency record amount value</td>
                            </tr>
                            <tr>
                                <td>Delivery_Date</td>
                                <td>02/11/2016</td>
                                <td>Date of delivery</td>
                            </tr>
                            <tr>
                                <td>Comment</td>
                                <td>TEST 1</td>
                                <td>Plain text for comment</td>
                            </tr>
                            <tr>
                                <td>Retailer_Name</td>
                                <td>My Store</td>
                                <td>Name of the retailer</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h4 className="mb-3">Which would look like this</h4>
                <div className="rounded-3 border px-3 py-2 bg-light opacity-75 mb-4 text-break">
                    <p className="mb-0">Intent,Wholesaler_License,Retailer_License,Delivery_Amount,Delivery_Date,Comment,Retailer_Name<br/>
                        A,9999-99-999999,8000-00-111111,11.11,02/11/2016,TEST 1,My Store</p>
                </div>
            </div>
        </div>

        <div className="card mt-3">
            <div className="card-body">
                <div className="mb-3 d-flex flex-column flex-lg-row align-itms-center justify-content-between">
                    <h4><strong>Step 1:</strong> Select CSV file</h4>
                    <Link to={ WHOLESALER_PATHS.DELINQUENT.BATCH_HISTORY }>
                        Check the status & history of previous imports
                    </Link>
                </div>
                <div className="form-group">
                    <label htmlFor="formFile" className="form-label">Choose file</label>
                    <input className="form-control" type="file" id="formFile" onChange={ handleChange }/>
                </div>
                <div className="row justify-content-center mt-4">
                    <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                        <button className="btn btn-primary w-100" disabled={ selectedFile === undefined || isLoading }
                            onClick={ uploadCsv }>Continue
                        </button>
                        <Spinner className="mt-2 text-center" hidden={ !isLoading }/>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default Batch;
