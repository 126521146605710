import React, { Fragment, useEffect, useState } from "react";
import LicenseStatusCell from "components/LicenseStatusCell";
import { useTimeStore } from "store/TimeStoreProvider";
import { LicenseModel } from "models/license.model";
import Spinner from "components/Spinner";
import LicenseService from "services/LicenseService";
import { useToastStore } from "store/ToastStoreProvider";
import DateUtil from "utils/DateUtil";

interface Props {
    licenseId: number;
    showDelinquentStatus?: boolean;
    onLoadLicense?: any;
}

const LicenseInfo= ({ licenseId, onLoadLicense, showDelinquentStatus }: Props) => {
    const { newYorkTime } = useTimeStore();
    const { addDefaultDangerToast } = useToastStore();
    const [license, setLicense] = useState<LicenseModel>();
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsDataLoading(true);
        LicenseService.getLicenseInfoById(licenseId).then(license => {
            setLicense(license);
            onLoadLicense && onLoadLicense(license);
        }).catch(() => addDefaultDangerToast("Licence Info")
        ).finally(() => setIsDataLoading(false));
    }, []);

    return <Fragment>
        { isDataLoading && <Spinner className="mt-4 pt-2 text-center"/> }
        { !isDataLoading && <div className="row">
            <div className="col-12 col-xl-6 mb-4 mb-xl-0">
                <div className="pe-xl-4">
                    <div className="mb-4">
                        <h5 className="fw-bold mb-3">License information for { DateUtil.getFormattedDate(newYorkTime) }</h5>
                        <hr />
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-5 col-lg-4">
                            <p className="mb-1 mb-md-3"><strong>License ID:</strong></p>
                        </div>
                        <div className="col-12 col-md-7 col-lg-8">
                            <p className="mb-3 license-permit-id">{ license?.permit_id }</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-5 col-lg-4">
                            <p className="mb-1 mb-md-3"><strong>Serial Number:</strong></p>
                        </div>
                        <div className="col-12 col-md-7 col-lg-8">
                            <p className="mb-3 license-serial-number">{ license?.serial_number }</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-5 col-lg-4">
                            <p className="mb-1 mb-md-3"><strong>Linked Licenses:</strong></p>
                        </div>
                        <div className="col-12 col-md-7 col-lg-8">
                            <p className="mb-3">
                                { (license?.related_permit_ids && license?.related_permit_ids.length > 0)
                                    ? license?.related_permit_ids.join(", ")
                                    : "N/A"
                                }
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-5 col-lg-4">
                            <p className="mb-1 mb-md-3"><strong>License type:</strong></p>
                        </div>
                        <div className="col-12 col-md-7 col-lg-8">
                            <p className="mb-3">{ license?.type.type } - { license?.type?.category_description }</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-5 col-lg-4">
                            <p className="mb-1 mb-md-3"><strong>License class:</strong></p>
                        </div>
                        <div className="col-12 col-md-7 col-lg-8">
                            <p className="mb-3">{ license?.type.class }{ license?.type.description ? " - " + license?.type.description : "" }</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-5 col-lg-4">
                            <p className="mb-1 mb-md-3"><strong>License status:</strong></p>
                        </div>
                        <div className="col-12 col-md-7 col-lg-8">
                            <div className="mb-3">
                                <LicenseStatusCell statusCode={ license?.status || "" } />
                            </div>
                        </div>
                    </div>
                    { showDelinquentStatus &&
                        <div className="row">
                            <div className="col-12 col-md-5 col-lg-4">
                                <p className="mb-1 mb-md-3"><strong>Delinquent status:</strong></p>
                            </div>
                            <div className="col-12 col-md-7 col-lg-8">
                                <p className="mb-3">
                                    { license?.delinquent_status ? "Delinquent" : "Not Delinquent" }
                                </p>
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-12 col-md-5 col-lg-4">
                            <p className="mb-1 mb-md-3"><strong>Filing date:</strong></p>
                        </div>
                        <div className="col-12 col-md-7 col-lg-8">
                            <p className="mb-3">{ license?.issued_date }</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-5 col-lg-4">
                            <p className="mb-1 mb-md-3"><strong>Effective date:</strong></p>
                        </div>
                        <div className="col-12 col-md-7 col-lg-8">
                            <p className="mb-3">{ license?.effective_date }</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-5 col-lg-4">
                            <p className="mb-1 mb-md-3"><strong>Expiration date:</strong></p>
                        </div>
                        <div className="col-12 col-md-7 col-lg-8">
                            <p className="mb-3">{ license?.expiration_date }</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-xl-6">
                <div className="ps-xl-4">
                    <div className="mb-4">
                        <h5 className="fw-bold mb-3">Premises information</h5>
                        <hr/>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-5 col-lg-4">
                            <p className="mb-1 mb-md-3"><strong>Principal&apos;s names:</strong></p>
                        </div>
                        <div className="col-12 col-md-7 col-lg-8">
                            <div className="mb-3 principals-names">
                                { license?.principals && license.principals?.map(principal => (
                                    <Fragment key={ principal.id }>{ principal.full_name?.trim() }<br/></Fragment>
                                ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-5 col-lg-4">
                            <p className="mb-1 mb-md-3"><strong>Premises name:</strong></p>
                        </div>
                        <div className="col-12 col-md-7 col-lg-8">
                            <p className="mb-3 license-premise-name">{ license?.premise_name?.trim() }</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-5 col-lg-4">
                            <p className="mb-1 mb-md-3"><strong>Trade name:</strong></p>
                        </div>
                        <div className="col-12 col-md-7 col-lg-8">
                            <p className="mb-3 license-trade-name">{ license?.dba?.trim() }</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-5 col-lg-4">
                            <p className="mb-1 mb-md-3"><strong>Address:</strong></p>
                        </div>
                        <div className="col-12 col-md-7 col-lg-8">
                            <p className="mb-3 license-address">
                                { license?.premise_address?.trim() }
                                { license?.premise_address2 ? ", " + license?.premise_address2?.trim() : "" }
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-5 col-lg-4">
                            <p className="mb-1 mb-md-3"><strong>County:</strong></p>
                        </div>
                        <div className="col-12 col-md-7 col-lg-8">
                            <p className="mb-3 license-county">{ license?.county?.trim() }</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-5 col-lg-4">
                            <p className="mb-1 mb-md-3"><strong>City:</strong></p>
                        </div>
                        <div className="col-12 col-md-7 col-lg-8">
                            <p className="mb-3 license-city">{ license?.premise_city?.trim() }</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-5 col-lg-4">
                            <p className="mb-1 mb-md-3"><strong>Zip code:</strong></p>
                        </div>
                        <div className="col-12 col-md-7 col-lg-8">
                            <p className="mb-3 license-zip-code">{ license?.zip?.trim() }</p>
                        </div>
                    </div>
                </div>
            </div>
        </div> }
    </Fragment>;
};

export default LicenseInfo;
