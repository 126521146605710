import React, { useState } from "react";
import { StepProps } from "pages/wholesaler/appointment/models/StepProps";
import { ProductModel } from "models/ProductModel";
import ProductSearchForm from "pages/wholesaler/appointment/components/ProductSearchForm";

type Step2Props = { initialProducts: ProductModel[] } & StepProps;

const Step2 = (props: Step2Props) => {

    const { initialProducts, letter, onSuccess, onBack } = props;

    const [selectedProducts, setSelectedProducts] = useState<ProductModel[]>([...initialProducts]);

    function onLinkProduct(product: ProductModel) {
        selectedProducts.push(product);
        setSelectedProducts([...selectedProducts]);
    }

    function onUnlinkProduct(product: ProductModel) {
        selectedProducts.splice(selectedProducts.indexOf(product), 1);
        setSelectedProducts([...selectedProducts]);
    }

    function onLinkSelectedItems() {
        onSuccess(selectedProducts);
    }

    return <div className="d-flex flex-column">
        <div className="mt-3">
            <ProductSearchForm
                letter={ letter }
                initProducts={ selectedProducts }
                onSelect={ onLinkProduct }
                onUnselect={ onUnlinkProduct }
                isDialog={ false }
            />
        </div>
        <div className="mt-sm-5 px-md-4 mx-md-1">
            <div className="row justify-content-center">
                <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mt-4 mt-sm-1 order-1 order-sm-0">
                    <input className="btn btn-outline-secondary w-100" type="button" onClick={ onBack } value="Back"/>
                </div>
                <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mt-4 mt-sm-1 order-0 order-sm-1">
                    <input
                        className="btn btn-primary w-100"
                        type="button"
                        onClick={ onLinkSelectedItems }
                        value="Link selected items"
                        disabled={ selectedProducts.length < 1 }
                    />
                </div>
            </div>
        </div>
    </div>;
};

export default Step2;

