import React from "react";

const WholesalerCertifyView = () => {

    return <div className="d-flex flex-column">
        <div className="mb-4 mb-md-5">
            <h1 className="text-primary mb-0">Certify - Liquor to Retailer Schedule for November 2022</h1>
        </div>
        <div>
            <div className="steps-wr mb-4">
                <div className="steps-l row g-0">
                    <div className="steps-li col-12 col-xl-3 active">
                        <div className="steps-li-in">
                            <strong>1</strong> Edit Posting Profile
                        </div>
                    </div>
                    <div className="steps-li col-12 col-xl-3">
                        <div className="steps-li-in">
                            <strong>2</strong> Certify Schedule
                        </div>
                    </div>
                    <div className="steps-li col-12 col-xl-3">
                        <div className="steps-li-in">
                            <strong>2</strong> Confirmation
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-2 mb-3 mb-md-4">
                <h4><strong>Step 1:</strong> Edit Posting Profile</h4>
            </div>
            <div className="card mb-4">
                <div className="card-body pb-2">
                    <div className="mb-4">
                        <h4 className="mb-3">License information for 07/26/2022</h4>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-5 col-lg-4 col-xl-2">
                            <p><strong>Serial number:</strong></p>
                        </div>
                        <div className="col-12 col-md-7 col-lg-8 col-xl-10">
                            <p>8000001</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-5 col-lg-4 col-xl-2">
                            <p><strong>Premises name:</strong></p>
                        </div>
                        <div className="col-12 col-md-7 col-lg-8 col-xl-10">
                            <p>Joffre Napa of South Weynon (Demo)</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-5 col-lg-4 col-xl-2">
                            <p><strong>Premises address:</strong></p>
                        </div>
                        <div className="col-12 col-md-7 col-lg-8 col-xl-10">
                            <p>There is some very very long address, Weynon, NY 124553</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="mb-4">
                        <h4 className="mb-3">Profile information</h4>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-4">
                            <p className="mb-1"><strong>Discount for time payment</strong></p>
                            <div className="switch-year">
                                <label className="form-check-label me-2" htmlFor="custom-switch">None</label>
                                <div className="form-check form-switch">
                                    <input type="checkbox" id="custom-switch" className="form-check-input" />
                                    <label title="" htmlFor="custom-switch" className="form-check-label">1% for payment within 10 days of delivery</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="pe-xl-4">
                                <div className="form-group">
                                    <label htmlFor="Textarea" className="form-label">Terms of sale</label>
                                    <textarea rows={ 5 } id="Textarea" className="form-control" placeholder="Terms of sale"></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="ps-xl-4">
                                <div className="form-group">
                                    <label htmlFor="Textarea" className="form-label">Other information</label>
                                    <textarea rows={ 5 } id="Textarea" className="form-control" placeholder="Other information"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-3">
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                            <a href="/wholesaler-static/manage-schedule" className="btn btn-outline-secondary w-100">Cancel</a>
                        </div>
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                            <a href="/wholesaler-static/certify2" className="btn btn-primary w-100">Continue</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default WholesalerCertifyView;

