import React, { useState } from "react";
import { ShortLicenseInfo } from "models/license.model";
import { OptionModel } from "models/OptionModel";
import { LicenseOptionModel, UncontrolledLicenseSelectInput } from "components/LicenseSelectInput";
import { UncontrolledMultiSelectRoles } from "components/form/select/MultiSelectRoles";

const AddLicenseWithRoles = ({ tryAddLicense }:{ tryAddLicense: (license:ShortLicenseInfo, roles: string[])=>boolean }) => {
    type InnerState = {
        addLicenseModel: LicenseOptionModel | null,
        addRoles: OptionModel[] ,
        availableRoles: string[]
    };
    const defaultState = { addLicenseModel: null, addRoles: [], availableRoles: [] };
    const [ state, setState] = useState<InnerState>(defaultState);

    const addLicense = () => {
        if ( state.addLicenseModel && state.addRoles.length > 0 ){
            if (tryAddLicense (state.addLicenseModel.info, state.addRoles.map(m=>m.value) )){
                setState(defaultState);
            }
        }
    };

    const changeSelectedLicense = (li: LicenseOptionModel | null) =>
        setState({
            addLicenseModel: li,
            addRoles: [],
            availableRoles: li?.info.available_roles || []
        });

    return <div className="card mt-3 add-license">
        <div className="card-body pb-3">
            <h5 className="mb-3">Add License</h5>
            <div className="d-flex flex-column flex-lg-row align-items-end">
                <div className="wp-250 me-lg-3">
                    <UncontrolledLicenseSelectInput label="License" name="rc2"
                        value={ state.addLicenseModel }
                        onChange={ changeSelectedLicense }
                    />
                </div>
                <div className="flex-fill">
                    <div className="mb-3">
                        <UncontrolledMultiSelectRoles label="Role" name="rc1" menuPlacement="bottom"
                            onChange={ mv=> setState(os=>({ ...os, addRoles: mv } as InnerState)) }
                            value={ state.addRoles }
                            roles={ state.availableRoles }/>
                    </div>
                </div>
                <button className="btn btn-primary ms-lg-3 mt-2 mt-lg-0 mb-3" onClick={ addLicense }>Add License</button>
            </div>
        </div>
    </div>;
};

export default AddLicenseWithRoles;
