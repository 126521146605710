import dayjs, { Dayjs } from "dayjs";
import AppPropertiesService from "services/AppPropertiesService";
import advancedFormat from "dayjs/plugin/advancedFormat";

const STANDARD_DATE_FORMAT = "MM/DD/YYYY";
const DATE_TIME_FORMAT = "MM/DD/YYYY HH:mm:ss";


export default {

    getServerDate(): Promise<Dayjs> {
        return AppPropertiesService.getServerTime();
    },

    getFormattedDate(date: Dayjs): string {
        return date.format(STANDARD_DATE_FORMAT);
    },

    async getCurrentPricingDate(): Promise<Dayjs> {
        const date = await this.getServerDate();
        return this.getPricingDate(date);
    },

    getPricingDate(date: Dayjs): Dayjs {
        if (date.date() >= 15) {
            return date.add(1,"month").startOf("month");
        } else {
            return date.startOf("month");
        }
    },

    // Dayjs start month from 0, but LocalDate start from 1
    getMonth(date: Dayjs | undefined): number {
        return date ? date.month() + 1 : 0;
    },

    getNextMonth(): Promise<Dayjs> {
        return this.getServerDate().then( date => { return date.add(1,"month"); } );
    },

    formatWithServerTZ(date?: number | string): string {
        return date ? dayjs(date).utc().format(STANDARD_DATE_FORMAT) : "";
    },

    formatDateTimeToLocalTimezone(timestamp?: number): string {
        return timestamp ? dayjs(timestamp).tz().format(DATE_TIME_FORMAT) : "";
    },

    formatDateToLocalTimezone(timestamp?: number | string): string {
        return timestamp ? dayjs(timestamp).tz().format(STANDARD_DATE_FORMAT) : "";
    },

    formatDate(date?: Date): string {
        return date ? dayjs(date).format(STANDARD_DATE_FORMAT) : "";
    },

    startOfCurrentMonth(): Date {
        return dayjs().date(1).toDate();
    },

    parseString(text: string): Date {
        return dayjs(text, STANDARD_DATE_FORMAT).toDate();
    },

    formatUsaTime(date: Date) {
        return date.toLocaleTimeString("en-US",{ hour: "2-digit", minute: "2-digit" });
    },

    daysInMonth(month: number, year: number) {
        // return dayjs().year(year || dayjs().year()).month(month).daysInMonth();
        return new Date(year, month, 0).getDate();
    },

    dayOfMonthOrdinal(day: number) {
        require("dayjs/plugin/advancedFormat");
        dayjs.extend(advancedFormat);
        return dayjs().date(day).format("Do");
    },

    // month starts from 0
    monthNumberToName(number: number): string {
        return dayjs().month(number).format("MMMM");
    }
};
