import React, { FC, PropsWithChildren } from "react";
import { MenuStoreProvider } from "store/MenuStoreProvider";
import { AuthStoreProvider } from "store/AuthStoreProvider";
import { ModalStoreProvider } from "store/ModalStoreProvider";
import { LoggedInUserInfo } from "models/LoggedInUserInfo";
import { TimeStoreProvider } from "store/TimeStoreProvider";
import { ShowHideStoreProvider } from "store/ShowHideStoreProvider";
import { UtilStoreProvider } from "store/UtilStoreProvider";
import { ToastStoreProvider } from "store/ToastStoreProvider";
import { AlertStoreProvider } from "store/AlertStoreProvider";

type RootStoresWrapperProps = PropsWithChildren<any>  & { loggedInUserData?: LoggedInUserInfo }

const RootStoresWrapper: FC<RootStoresWrapperProps> = ({ children, loggedInUserData }) => {
    return(
        <TimeStoreProvider>
            <MenuStoreProvider>
                <ShowHideStoreProvider>
                    <ModalStoreProvider>
                        <AuthStoreProvider loggedInUserData={ loggedInUserData } >
                            <UtilStoreProvider>
                                <ToastStoreProvider>
                                    <AlertStoreProvider>
                                        { children }
                                    </AlertStoreProvider>
                                </ToastStoreProvider>
                            </UtilStoreProvider>
                        </AuthStoreProvider>
                    </ModalStoreProvider>
                </ShowHideStoreProvider>
            </MenuStoreProvider>
        </TimeStoreProvider>
    );
};

export default RootStoresWrapper;
