import React from "react";

const DelinquentBatch3View = () => {

    return <div className="d-flex flex-column min-vh-100">
        <div className="mb-3">
            <h1 className="text-primary mb-4">Delinquent Records - Batch Submission</h1>
            <p className="mb-0">Select CSV file with delinquency record and click &ldquo;Upload&rdquo; button.</p>
        </div>
        <div className="mb-4">
            <a className="btn btn-outline-primary me-3 mb-3" href="/">Read more about Batch Submission</a>
            <a className="btn btn-outline-primary mb-3" href="/">CSV Template</a>
        </div>
        <div className="steps-wr mb-4">
            <div className="steps-l row g-0">
                <div className="steps-li col-12 col-xl-3">
                    <div className="steps-li-in">
                        <strong>1</strong> Select CSV file to upload
                    </div>
                </div>
                <div className="steps-li col-12 col-xl-3 active">
                    <div className="steps-li-in">
                        <strong>2</strong> Preview batch submission
                    </div>
                </div>
            </div>
        </div>
        <div className="pt-3">
            <h4 className="mb-3">Preview Batch Submission</h4>
        </div>

        <div className="table-responsive mb-4">
            <table className="table">
                <thead>
                    <tr>
                        <th>Intent</th>
                        <th>Wholesaler_Serial_Number</th>
                        <th>Retailer_Serial_Number</th>
                        <th>Delivery_Amount</th>
                        <th>Delivery_Date</th>
                        <th>Comment</th>
                        <th>Retailer_Name</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>gsome-email@gmail.com</td>
                        <td>74775555</td>
                        <td>22/12/2022</td>
                        <td>Friday</td>
                        <td></td>
                        <td></td>
                        <td>America, Los Angeles</td>
                    </tr>
                    <tr>
                        <td>some-email@gmail.com</td>
                        <td>67775555</td>
                        <td>22/12/2022</td>
                        <td>Friday</td>
                        <td></td>
                        <td></td>
                        <td>America, Los Angeles</td>
                    </tr>
                    <tr>
                        <td>gsome-email@gmail.com</td>
                        <td>74775555</td>
                        <td>22/12/2022</td>
                        <td>Friday</td>
                        <td></td>
                        <td></td>
                        <td>America, Los Angeles</td>
                    </tr>
                    <tr>
                        <td>some-email@gmail.com</td>
                        <td>67775555</td>
                        <td>22/12/2022</td>
                        <td>Friday</td>
                        <td></td>
                        <td></td>
                        <td>America, Los Angeles</td>
                    </tr>
                    <tr>
                        <td>gsome-email@gmail.com</td>
                        <td>74775555</td>
                        <td>22/12/2022</td>
                        <td>Friday</td>
                        <td></td>
                        <td></td>
                        <td>America, Los Angeles</td>
                    </tr>
                    <tr>
                        <td>some-email@gmail.com</td>
                        <td>67775555</td>
                        <td>22/12/2022</td>
                        <td>Friday</td>
                        <td></td>
                        <td></td>
                        <td>America, Los Angeles</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ul className="justify-content-center pagination mb-5">
            <li className="page-item disabled">
                <span className="page-link">
                    <span className="bi bi-arrow-left me-2"></span>Prev
                </span>
            </li>
            <li className="page-item active">
                <span className="page-link">1</span>
            </li>
            <li className="page-item">
                <a className="page-link" role="button" href="/">2</a>
            </li>
            <li className="page-item">
                <a className="page-link" role="button" href="/">3</a>
            </li>
            <li className="page-item">
                <a className="page-link" role="button" href="/">...</a>
            </li>
            <li className="page-item">
                <a className="page-link" role="button" href="/">50</a>
            </li>
            <li className="page-item">
                <a className="page-link" role="button" href="/">
                    Next<span className="bi bi-arrow-right ms-2"></span>
                </a>
            </li>
        </ul>
        <div className="row justify-content-center">
            <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                <a href="/delinquent/batch2" className="btn btn-outline-secondary w-100">Start over</a>
            </div>
            <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                <a href="/delinquent/batch4" className="btn btn-primary w-100">Finish import</a>
            </div>
        </div>
    </div>;
};

export default DelinquentBatch3View;
