import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import WholesalerDelinquentService from "services/wholesaler/WholesalerDelinquentService";
import DateUtil from "utils/DateUtil";
import { ManageDelinquentResponse } from "models/response/ManageDelinquentResponse";
import CurrencyText from "components/CurrencyText";
import { useTimeStore } from "store/TimeStoreProvider";

const PrintRecord = () => {
    const [ record, setRecord ] = useState<ManageDelinquentResponse>();
    const { id = "" } = useParams<{ id: string }>() ;
    const { serverTime } = useTimeStore();

    useEffect(()=>{
        WholesalerDelinquentService
            .fetchCurrentWholesalerRecord(parseInt(id))
            .then(setRecord);
    },[]);

    useEffect(() => {
        if (record != null) {
            window.print();
        }
    }, [record]);

    return <>
        <div className="fade modal-backdrop show">&nbsp;</div>
        <div className="fade modal show d-block">
            <div className="modal-dialog modal-fullscreen modal-dialog-centered">
                <div className="modal-content overflow-auto">
                    <div className="modal-header flex-column">
                        <h1>Notice of default</h1>
                        <h4>Date: { DateUtil.getFormattedDate(serverTime) }</h4>
                    </div>
                    <div className="modal-body overflow-visible">
                        <div className="mb-4 pb-1">
                            <h4 className="mb-4">From retail license certificate:</h4>
                            <div className="row mb-2">
                                <div className="col-3 col-md-2">
                                    <p className="mb-0"><strong>License ID:</strong></p>
                                </div>
                                <div className="col-9 col-md-10">
                                    <p className="mb-0">{ record?.license_permit_id }</p>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-3 col-md-2">
                                    <p className="mb-0"><strong>Serial number:</strong></p>
                                </div>
                                <div className="col-9 col-md-10">
                                    <p className="mb-0">{ record?.license_serial_number }</p>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-3 col-md-2">
                                    <p className="mb-0"><strong>Name of Retail Licensee:</strong></p>
                                </div>
                                <div className="col-9 col-md-10">
                                    <p className="mb-0">{ record?.premise_name }</p>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-3 col-md-2">
                                    <p className="mb-0"><strong>DBA Name:</strong></p>
                                </div>
                                <div className="col-9 col-md-10">
                                    <p className="mb-0">{ record?.dba }</p>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-3 col-md-2">
                                    <p className="mb-0"><strong>License type:</strong></p>
                                </div>
                                <div className="col-9 col-md-10">
                                    <p className="mb-0">{ record?.license_type }</p>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-3 col-md-2">
                                    <p className="mb-0"><strong>Premise Address:</strong></p>
                                </div>
                                <div className="col-9 col-md-10">
                                    <p className="mb-0">{ record?.premise_address }, { record?.premise_address2 }</p>
                                </div>
                            </div>

                            <div className="row mb-2">
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-6 col-md-4">
                                            <p className="mb-0"><strong>City:</strong></p>
                                        </div>
                                        <div className="col-6 col-md-8">
                                            <p className="mb-0">{ record?.license_premise_city }</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row">
                                        <div className="row mb-2">
                                            <div className="col-6 col-md-4">
                                                <p className="mb-0"><strong>State:</strong></p>
                                            </div>
                                            <div className="col-6 col-md-8">
                                                <p className="mb-0">{ record?.license_premise_state }</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-2">
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-6 col-md-4">
                                            <p className="mb-0"><strong>Zip:</strong></p>
                                        </div>
                                        <div className="col-6 col-md-8">
                                            <p className="mb-0">{ record?.license_premise_zip }</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row">
                                        <div className="row mb-2">
                                            <div className="col-6 col-md-4">
                                                <p className="mb-0"><strong>County:</strong></p>
                                            </div>
                                            <div className="col-6 col-md-8">
                                                <p className="mb-0">{ record?.county }</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-4 pb-1">
                            <h4 className="mb-4">Delivery Dates:</h4>
                            <div className="row mb-2">
                                <div className="col-3 col-md-2">
                                    <strong>Last Delivery Dates</strong>
                                </div>
                                <div className="col-3 col-md-3">
                                    <strong>Unpaid Amount</strong>
                                </div>
                                <div className="col-6 col-md-7">
                                    <strong>Comment</strong>
                                </div>
                            </div>
                            <hr className="mt-2 mb-0"/>
                            <div className="row mb-2 pt-2">
                                <div className="col-3 col-md-2">
                                    { DateUtil.formatWithServerTZ(record?.delivery_date) }
                                </div>
                                <div className="col-3 col-md-3">
                                    { record && <CurrencyText value={ record.amount } /> }
                                </div>
                                <div className="col-6 col-md-7">
                                    { record?.comment }
                                </div>
                            </div>
                            <div className="border-bottom"/>
                        </div>

                        <div className="pb-2">
                            <div className="card mb-4">
                                <div className="card-body p-3">
                                    <p className="mb-0">
                                        <small>
                                            <strong>NOTE:</strong> In accordance with the provisions of Section 101-a/aa
                                            of the Alcoholic Beverage Control Law, notice is hereby given that you are
                                            in default for payment for purchase of alcoholic beverages. You may NOT
                                            purchase alcoholic beverages from any manufacturer or wholesaler except for
                                            cash, which means U.S. currency, money order, certified check, bank
                                            officer&apos;s check, or draft or check drawn on the account of the retail
                                            licensee. The manufacturer or wholesaler may require that a check drawn on
                                            the account of the retail licensee be certified. The Notice of Default has
                                            been provided to the New York State Liquor Authority electronically.
                                        </small>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <h4 className="mb-4">Manufacturer or Wholesaler information:</h4>
                        <div className="row mb-2">
                            <div className="col-3 col-md-2">
                                <p className="mb-0"><strong>Name:</strong></p>
                            </div>
                            <div className="col-9 col-md-10">
                                <p className="mb-0">{ record?.wholesaler_premise_name }</p>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-3 col-md-2">
                                <p className="mb-0"><strong>Address:</strong></p>
                            </div>
                            <div className="col-9 col-md-10">
                                <p className="mb-0">{ record?.wholesaler_premise_address } { record?.wholesaler_premise_city } { record?.wholesaler_premise_state } { record?.wholesaler_premise_zip }</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
};

export default PrintRecord;
