import { MenuSettings, useLeftMenu } from "components/menu/Menu";
import LicenseSearch from "pages/wholesaler/LicenseSearch";
import ListingSearch from "pages/wholesaler/delinquent/ListingSearch";
import SearchDownload from "pages/wholesaler/delinquent/SearchDownload";
import ManageRecords from "pages/wholesaler/delinquent/ManageRecords";
import Batch from "pages/wholesaler/delinquent/Batch";
import BatchPreview from "pages/wholesaler/delinquent/BatchPreview";
import BatchHistory from "pages/wholesaler/delinquent/BatchHistory";
import ListLetter from "pages/wholesaler/appointment/ListLetter";
import CreateLetter from "pages/wholesaler/appointment/CreateLetter";
import UpdateLetter from "pages/wholesaler/appointment/UpdateLetter";
import PricePosting from "pages/wholesaler/priceposting/PricePosting";
import PricePostingView from "pages/wholesaler/priceposting/pages/PricePostingView";
import { useAuthStore } from "store/AuthStoreProvider";
import PrintRecord from "pages/wholesaler/delinquent/PrintRecord";
import ManageDiscounts from "pages/wholesaler/priceposting/pages/ManageDiscounts";
import Certify1View from "pages/wholesaler/priceposting/pages/certify/Certify1View";
import Certify2View from "pages/wholesaler/priceposting/pages/certify/Certify2View";
import Certify3View from "pages/wholesaler/priceposting/pages/certify/Certify3View";
import ManageBrandsItems from "pages/wholesaler/priceposting/pages/brands/ManageBrandsItems";
import BatchProducts from "pages/wholesaler/priceposting/pages/batch/BatchProducts";
import BatchProductsResults from "pages/wholesaler/priceposting/pages/batch/BatchProductsResults";
import BatchDiscounts from "pages/wholesaler/priceposting/pages/batch/BatchDiscounts";
import BatchDiscountResults from "pages/wholesaler/priceposting/pages/batch/BatchDiscountResults";
import UploadedFiles from "pages/wholesaler/priceposting/pages/batch/UploadedFiles";
import EditPostingProfile from "pages/wholesaler/priceposting/pages/EditPostingProfile";
import ViewPostingProfile from "pages/wholesaler/priceposting/pages/ViewPostingProfile";
import { AddPage } from "pages/wholesaler/priceposting/pages/brands/AddPage";
import { EditPage } from "pages/wholesaler/priceposting/pages/brands/EditPage";
import { AmendPage } from "pages/wholesaler/priceposting/pages/brands/AmendPage";
import { useHideAll } from "services/ShowHideServise";
import MidMonthLimitedAvailability from "pages/wholesaler/availability/MidMonthLimitedAvailability";
import { useTimeStore } from "store/TimeStoreProvider";
import { PriceLookup } from "components/PriceLookup";
import { PriceLookupNext } from "components/PriceLookupNext";
import Amendments from "pages/wholesaler/Amendments";
import { TransferDelinquentData } from "pages/wholesaler/delinquent/TransferDelinquentData";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "components/ProtectedRoute";
import AuthenticatedRoute from "components/AuthenticatedRoute";
import NotFound from "pages/NotFound";
import React from "react";
import RouteWithActions from "components/RouteWithActions";
import LicenseUsers from "components/LicenseUsers";
import { PermissionStore } from "store/PermissionStore";
import { Dayjs } from "dayjs";

const RELATIVE_PATHS = {
    MAIN: "/wholesaler",
    MID_MONTH: "/mid-month-limited-availability",
    DISCOUNTS: {
        MAIN: "/discounts",
        BATCH_IMPORT: "/discounts/batch-import/step1",
        BATCH_IMPORT_RESULTS: "/discounts/batch-import/results"
    },
    UPLOADED_FILES: "/uploaded-filez", /* 'files' is in exclusions list */
    AMENDMENTS: "/amendments",

    PRICE_POSTING: {
        MAIN: "/price-posting",
        VIEW: "/price-posting/view/:type/:year/:month/:certifiedNeeded?",
        EDIT_PROFILE: "/price-posting/edit-profile/:type/:year/:month",
        VIEW_PROFILE: "/price-posting/view-profile/:type/:year/:month",
        MANAGE_BRANDS_ITEMS: "/price-posting/manage-brands-items/:type/:year/:month/:certifiedNeeded?",
        BATCH_IMPORT: "/price-posting/batch-import/step1/:type/:year/:month",
        BATCH_IMPORT_3: "/price-posting/batch-import/results/:type/:year/:month",
        ADD_BRAND_ITEM: "/price-posting/add/:rcType/:postType",
        EDIT_BRAND_ITEM: "/price-posting/edit/:id",
        AMEND_BRAND_ITEM: "/price-posting/amend/:id/:postType/:year/:month"
    },

    USER: {
        MANAGEMENT: "/user/management"
    },
    LICENSE: {
        SEARCH: "/license/search"
    },
    DELINQUENT: {
        SEARCH: "/delinquent/search",
        SEARCH_DOWNLOAD: "/delinquent/search_download",
        MANAGE: "/delinquent/manage",
        BATCH: "/delinquent/manage/batch",
        BATCH_PREVIEW: "/delinquent/manage/batch_preview/:jobId",
        BATCH_HISTORY: "/delinquent/manage/batch_history",
        TRANSFER: "/delinquent/transfer"
    },
    APPOINTMENT: {
        LETTERS: "/appointment/letters",
        CREATE_LETTER: "/appointment/letters/create",
        UPDATE_LETTER: "/appointment/letters/update/:id"
    },
    PRINT_DELINQUENT_RECORD: "/print/delinquent/:id",
    CERTIFY: {
        STEP1: "/price-posting/certify/step1/:type/:year/:month",
        STEP2: "/price-posting/certify/step2/:type/:year/:month",
        STEP3: "/price-posting/certify/step3/:type/:year/:month"
    },
    PRICE_LOOKUP: {
        CURRENT: "/price-lookup/current",
        NEXT: "/price-lookup/next"
    }
};

export const WHOLESALER_PATHS = {
    MAIN: RELATIVE_PATHS.MAIN + "/*",
    MID_MONTH: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.MID_MONTH,
    DISCOUNTS: {
        MAIN: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.DISCOUNTS.MAIN,
        BATCH_IMPORT: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.DISCOUNTS.BATCH_IMPORT,
        BATCH_IMPORT_RESULTS: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.DISCOUNTS.BATCH_IMPORT_RESULTS
    },
    UPLOADED_FILES: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.UPLOADED_FILES,
    AMENDMENTS: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.AMENDMENTS,

    PRICE_POSTING: {
        MAIN: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.PRICE_POSTING.MAIN,
        VIEW: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.PRICE_POSTING.VIEW,
        EDIT_PROFILE: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.PRICE_POSTING.EDIT_PROFILE,
        VIEW_PROFILE: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.PRICE_POSTING.VIEW_PROFILE,
        MANAGE_BRANDS_ITEMS: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.PRICE_POSTING.MANAGE_BRANDS_ITEMS,
        BATCH_IMPORT: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.PRICE_POSTING.BATCH_IMPORT,
        BATCH_IMPORT_3: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.PRICE_POSTING.BATCH_IMPORT_3,
        ADD_BRAND_ITEM: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.PRICE_POSTING.ADD_BRAND_ITEM,
        EDIT_BRAND_ITEM: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.PRICE_POSTING.EDIT_BRAND_ITEM,
        AMEND_BRAND_ITEM: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.PRICE_POSTING.AMEND_BRAND_ITEM
    },

    USER: {
        MANAGEMENT: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.USER.MANAGEMENT
    },
    LICENSE: {
        SEARCH: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.LICENSE.SEARCH
    },
    DELINQUENT: {
        SEARCH: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.DELINQUENT.SEARCH,
        SEARCH_DOWNLOAD: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.DELINQUENT.SEARCH_DOWNLOAD,
        MANAGE: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.DELINQUENT.MANAGE,
        BATCH: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.DELINQUENT.BATCH,
        BATCH_PREVIEW: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.DELINQUENT.BATCH_PREVIEW,
        BATCH_HISTORY:RELATIVE_PATHS.MAIN + RELATIVE_PATHS.DELINQUENT.BATCH_HISTORY,
        TRANSFER:RELATIVE_PATHS.MAIN + RELATIVE_PATHS.DELINQUENT.TRANSFER
    },
    APPOINTMENT: {
        LETTERS: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.APPOINTMENT.LETTERS,
        CREATE_LETTER: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.APPOINTMENT.CREATE_LETTER,
        UPDATE_LETTER: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.APPOINTMENT.UPDATE_LETTER
    },
    PRINT_DELINQUENT_RECORD: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.PRINT_DELINQUENT_RECORD,
    CERTIFY: {
        STEP1: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.CERTIFY.STEP1,
        STEP2: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.CERTIFY.STEP2,
        STEP3: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.CERTIFY.STEP3
    },
    PRICE_LOOKUP: {
        CURRENT: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.PRICE_LOOKUP.CURRENT,
        NEXT: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.PRICE_LOOKUP.NEXT
    }
};

export const LEFT_MENU = (permissionStore: PermissionStore, newYorkTime: Dayjs ): MenuSettings => ({
    caption: "Wholesaler menu",
    items: [
        {
            title: "Pricing Management",
            link: WHOLESALER_PATHS.PRICE_POSTING.MAIN,
            predicate: () => permissionStore.isWholesalerAndPricePosting(),
            open: true,
            submenu:[
                {
                    title: "Price Posting",
                    link: WHOLESALER_PATHS.PRICE_POSTING.MAIN
                },
                {
                    title: "Amendments",
                    link: WHOLESALER_PATHS.AMENDMENTS
                },
                {
                    title: "Mid-Month Limited Availability",
                    link: WHOLESALER_PATHS.MID_MONTH
                },
                {
                    title: "Discount Codes",
                    link: WHOLESALER_PATHS.DISCOUNTS.MAIN
                },
                {
                    title: "Uploaded Files",
                    link: WHOLESALER_PATHS.UPLOADED_FILES
                },
                {
                    title: "Appointment Letters",
                    link: WHOLESALER_PATHS.APPOINTMENT.LETTERS,
                    predicate: () => permissionStore.isWholesaler
                }
            ]
        },
        {
            title: "Delinquency Control",
            link: WHOLESALER_PATHS.DELINQUENT.MANAGE,
            predicate: () => permissionStore.isWholesalerAndBA_CR_CW_CA_CB(),
            submenu:[
                {
                    title: "Manage Delinquency Records",
                    link: WHOLESALER_PATHS.DELINQUENT.MANAGE,
                    predicate: () => permissionStore.isWholesalerAndBA_CR_CW_CA_CB()
                },
                {
                    title: "Delinquent Licenses Overview",
                    link: WHOLESALER_PATHS.DELINQUENT.SEARCH,
                    predicate: () => permissionStore.isWholesalerAndBA_CR_CW_CA_CB()
                },
                {
                    title: "Licensees Search",
                    link: WHOLESALER_PATHS.LICENSE.SEARCH,
                    predicate: () => permissionStore.isWholesaler
                }
            ]
        },
        {
            title: "Pricing Lookup",
            link: WHOLESALER_PATHS.PRICE_LOOKUP.CURRENT,
            predicate: () => permissionStore.isWholesalerAndPricePosting(),
            submenu:[
                {
                    title: newYorkTime.format("MMMM YYYY"),
                    link: WHOLESALER_PATHS.PRICE_LOOKUP.CURRENT
                },
                {
                    title: newYorkTime.add(1,"month").format("MMMM YYYY"),
                    link: WHOLESALER_PATHS.PRICE_LOOKUP.NEXT,
                    predicate: () => newYorkTime.date() >= 15
                }
            ]
        },
        {
            title: "Settings",
            link: WHOLESALER_PATHS.USER.MANAGEMENT,
            predicate: () => permissionStore.isBusinessAdmin,
            submenu:[
                {
                    title: "User Management",
                    link: WHOLESALER_PATHS.USER.MANAGEMENT
                }
            ]
        }
    ]
});

const WholesalerRoutes = () => {

    const { permissionStore } = useAuthStore();
    const { newYorkTime } = useTimeStore();

    useLeftMenu(LEFT_MENU(permissionStore, newYorkTime));
    return <Routes>
        <Route element={ <AuthenticatedRoute/> }>
            <Route element={ <ProtectedRoute isAllowed={ permissionStore.isWholesalerAdmin() }/> }>
                <Route path={ RELATIVE_PATHS.USER.MANAGEMENT } element={ <LicenseUsers/> }/>
            </Route>
            <Route element={ <ProtectedRoute isAllowed={ permissionStore.isWholesalerAndPricesCertifyOrWrite() }/> }>
                <Route path={ RELATIVE_PATHS.AMENDMENTS } element={ <Amendments/> }/>
                <Route path={ RELATIVE_PATHS.PRICE_POSTING.BATCH_IMPORT } element={ <BatchProducts/> }/>
                <Route path={ RELATIVE_PATHS.PRICE_POSTING.BATCH_IMPORT_3 } element={ <BatchProductsResults/> }/>
                <Route path={ RELATIVE_PATHS.PRICE_POSTING.ADD_BRAND_ITEM } element={ <AddPage/> }/>
                <Route path={ RELATIVE_PATHS.PRICE_POSTING.EDIT_BRAND_ITEM } element={ <EditPage/> }/>
                <Route path={ RELATIVE_PATHS.PRICE_POSTING.AMEND_BRAND_ITEM } element={ <AmendPage/> }/>
                <Route path={ RELATIVE_PATHS.APPOINTMENT.CREATE_LETTER } element={ <CreateLetter/> }/>
                <Route path={ RELATIVE_PATHS.APPOINTMENT.UPDATE_LETTER } element={ <UpdateLetter/> }/>
            </Route>
            <Route element={ <ProtectedRoute isAllowed={ permissionStore.isWholesaler }/> }>
                <Route path={ RELATIVE_PATHS.LICENSE.SEARCH } element={ <LicenseSearch/> }/>
            </Route>
            <Route element={ <ProtectedRoute isAllowed={ permissionStore.isWholesalerAndBA_CR_CW_CA_CB() }/> }>
                <Route path={ RELATIVE_PATHS.DELINQUENT.SEARCH } element={ <ListingSearch/> }/>
                <Route path={ RELATIVE_PATHS.DELINQUENT.SEARCH_DOWNLOAD } element={ <SearchDownload/> }/>
                <Route path={ RELATIVE_PATHS.DELINQUENT.MANAGE } element={ <ManageRecords/> }/>
            </Route>
            <Route element={ <ProtectedRoute isAllowed={ permissionStore.isWholesalerAndBatchUpload() }/> }>
                <Route path={ RELATIVE_PATHS.DELINQUENT.BATCH } element={ <Batch/> }/>
                <Route path={ RELATIVE_PATHS.DELINQUENT.BATCH_PREVIEW } element={ <BatchPreview/> }/>
                <Route path={ RELATIVE_PATHS.DELINQUENT.BATCH_HISTORY } element={ <BatchHistory/> }/>
            </Route>
            <Route element={ <ProtectedRoute isAllowed={ permissionStore.isWholesalerAndBA_CW() }/> }>
                <Route path={ RELATIVE_PATHS.DELINQUENT.TRANSFER } element={ <TransferDelinquentData/> }/>
            </Route>
            <Route element={ <ProtectedRoute isAllowed={ permissionStore.isSlaAdminOrAdminOrSlaCod() || permissionStore.isWholesalerAndBA_CR_CW_CA_CB() }/> }>
                <Route element={ <RouteWithActions actions={ [useHideAll] } /> }>
                    <Route path={ RELATIVE_PATHS.PRINT_DELINQUENT_RECORD } element={ <PrintRecord/> }/>
                </Route>
            </Route>
            <Route element={ <ProtectedRoute isAllowed={ permissionStore.isWholesalerAndPricePosting() }/> }>
                <Route path={ RELATIVE_PATHS.CERTIFY.STEP1 } element={ <Certify1View/> }/>
                <Route path={ RELATIVE_PATHS.CERTIFY.STEP2 } element={ <Certify2View/> }/>
                <Route path={ RELATIVE_PATHS.CERTIFY.STEP3 } element={ <Certify3View/> }/>
                <Route path={ RELATIVE_PATHS.PRICE_POSTING.EDIT_PROFILE } element={ <EditPostingProfile/> }/>
                <Route path={ RELATIVE_PATHS.PRICE_POSTING.VIEW_PROFILE } element={ <ViewPostingProfile/> }/>
                <Route path={ RELATIVE_PATHS.PRICE_POSTING.VIEW } element={ <PricePostingView/> }/>
                <Route path={ RELATIVE_PATHS.PRICE_POSTING.MANAGE_BRANDS_ITEMS } element={ <ManageBrandsItems/> }/>
                <Route path={ RELATIVE_PATHS.PRICE_POSTING.BATCH_IMPORT } element={ <BatchProducts/> }/>
                <Route path={ RELATIVE_PATHS.PRICE_POSTING.MAIN } element={ <PricePosting/> }/>
                <Route path={ RELATIVE_PATHS.DISCOUNTS.MAIN } element={ <ManageDiscounts/> }/>
                <Route path={ RELATIVE_PATHS.DISCOUNTS.BATCH_IMPORT } element={ <BatchDiscounts/> }/>
                <Route path={ RELATIVE_PATHS.DISCOUNTS.BATCH_IMPORT_RESULTS } element={ <BatchDiscountResults/> }/>
                <Route path={ RELATIVE_PATHS.UPLOADED_FILES } element={ <UploadedFiles/> }/>
                <Route path={ RELATIVE_PATHS.PRICE_LOOKUP.CURRENT } element={ <PriceLookup/> }/>
                <Route path={ RELATIVE_PATHS.MID_MONTH } element={ <MidMonthLimitedAvailability/> }/>
                <Route path={ RELATIVE_PATHS.APPOINTMENT.LETTERS } element={ <ListLetter/> }/>
            </Route>
            <Route element={ <ProtectedRoute isAllowed={ newYorkTime.date() >= 15 && permissionStore.isWholesalerAndPricePosting() }/> }>
                <Route path={ RELATIVE_PATHS.PRICE_LOOKUP.NEXT } element={ <PriceLookupNext/> }/>
            </Route>
            <Route path={ "*" } element={ <NotFound/> }/>
        </Route>
    </Routes>;
};

export default WholesalerRoutes;
