import httpClient from "api/HttpClient";
import { LicenseInfoResponse } from "models/response/LicenseInfoResponse";
import BACKEND from "services/Constants";
import { LicenseSettings } from "models/LicenseSettings";

export default {

    getLicenseInfoById(licenseId: number): Promise<LicenseInfoResponse> {
        return httpClient.get(BACKEND + "/license/getLicenseInfoById/" + licenseId);
    },

    getSettings(licenseId: number): Promise<LicenseSettings>  {
        return httpClient.get(BACKEND + "/license/getSettings/" + licenseId);
    },

    saveSettings(request: LicenseSettings) {
        return httpClient.post(BACKEND + "/license/saveSettings", request);
    }
};
