import React, { useEffect } from "react";
import { Row } from "react-bootstrap";
import Input from "components/form/Input";
import { FormLabelWithTooltip } from "components/form/FormLabelWithTooltipParams";
import { SelectPackageType } from "components/form/select/SelectPackageType";
import { DistribIdInput } from "pages/wholesaler/priceposting/pages/brands/components/DistribIdInput";
import { ControlledFormSwitch } from "components/form/ControlledFormSwitch";
import { ExtendedAgeControl } from "pages/wholesaler/priceposting/pages/brands/components/ExtendedAgeControl";
import { useFormContext } from "react-hook-form";
import { SelectBeverageType } from "components/form/select/SelectBeverageType";
import { AlcoholOtherControl } from "pages/wholesaler/priceposting/pages/brands/components/AlcoholOtherControl";

interface Props {
    type: string
}

export const RegularVapStep1 = ({ type }:Props)=>{
    const { control, watch, setValue, formState: { errors } } = useFormContext();
    const proofWatch = watch("proof");

    useEffect(()=>{
        if (type === "LR" || type === "LW") {
            const a = parseFloat(proofWatch) / 2;
            setValue("alcohol", isNaN(a) ? "0.01" : a.toString());
        }
    },[proofWatch]);

    return <>
        <div className="row">
            <div className="col-12 col-xl-6">
                <div className="pe-xl-4">
                    <Input name="brand_name" placeholder="Enter brand name" label="Brand name *" required type="text" control={ control } errors={ errors }/>
                </div>
            </div>
            <div className="col-12 col-xl-6">
                <div className="ps-xl-4">
                    <div className="row">
                        <div className="col-12 col-lg-6 col-xl-5">
                            <SelectPackageType name="reg_combo" id="reg_combo" control={ control } errors={ errors } addAllOption={ false } addValueIfNotPresent={ true } filterValues={ ["R","V"] } label="Package type"/>
                        </div>
                        <div className="col-12 col-lg-6 col-xl-7">
                            <SelectBeverageType name="bev_type" id="bev_type" control={ control } errors={ errors } required
                                type={ type } addAllOption={ false } addValueIfNotPresent={ true } label="Type of beverage *"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-12 col-xl-6">
                <div className="pe-xl-4">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <FormLabelWithTooltip label="Approval/BLR number*"
                                tooltip={ (type === "LW" || type === "LR")
                                    ? "Enter either the approval # in this format: NY-XXXXXXXX-XXXX, or the regular 7-digit NYS BLR (Brand Label Registration) Number, or P (for Pending)"
                                    : "Required for low-alcohol wine (beverage type \"L\"), not available for wines with 7 - 24% alcohol"
                                } htmlFor="brand_reg" />
                            <Input name="brand_reg" id="brand_reg" placeholder="Enter approval / BLR number" type="text" control={ control }  errors={ errors } />
                        </div>
                        <div className="col-12 col-lg-6">
                            <FormLabelWithTooltip label="TTB / CoLA ID number"
                                tooltip={ (type === "LW" || type === "LR")
                                    ? "P for Pending may only be used for the first two posting periods while the TTB or BLR number is pending approval"
                                    : "Enter the 14-digit CoLA number. Use 'P' for pending. Required for wines with 7-24% alcohol. Not required for low-alcohol wine."
                                } htmlFor="ttb_id" />
                            <Input name="ttb_id" id="ttb_id" placeholder="Enter TTB / CoLA ID number" type="text" control={ control }  errors={ errors }/>
                        </div>
                    </div>
                </div>
            </div>

            { (type === "WR" || type === "WW") &&
                <div className="col-12 col-xl-6">
                    <div className="ps-xl-4">
                        <AlcoholOtherControl control={ control } errors={ errors }/>
                    </div>
                </div>
            }
            { (type == "LR" || type === "LW") &&
                <div className="col-12 col-xl-6">
                    <div className="ps-xl-4">
                        <div className="row">
                            <div className="col-12 col-lg-6 col-xl-5">
                                <Input name="proof" id="proof" required placeholder="Enter proof" type="number"
                                    control={ control } errors={ errors } label="Proof *"/>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
        { (type === "LW" || type === "WW") &&
            <Row>
                <div className="col-12 col-md-6">
                    <div className="pe-xl-4">
                        <DistribIdInput/>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="ps-xl-4">
                        <div className="mb-3">
                            <p className="mb-2">
                                <strong>Label *</strong>
                            </p>
                            <div className="switch-year pt-1">
                                <label className="form-check-label me-2" htmlFor="custom-switch3">Brand agent</label>
                                <ControlledFormSwitch control={ control } errors={ errors } name="label_type" label="Brand owner" options={ ["O","A"] } id="custom-switch3"/>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>
        }

        <div className="row">
            <div className="col-12">
                <FormLabelWithTooltip label="Age *" tooltip="Some text here" htmlFor="age_id" />
                <ExtendedAgeControl name="vintage" control={ control } errors={ errors }/>
            </div>
        </div>
    </>;
};
