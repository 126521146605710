import { ProductModel } from "models/ProductModel";
import StringUtils from "utils/StringUtils";
import classNames from "classnames";
import React from "react";
import { TabModel } from "components/product-tab-widget/ProductTabContainer";

const DISPLAYED_PROPERTIES:string[] = ["prim_info"];
const BrandOwnerTabHeader = (product: ProductModel) => {
    const hasErrors = (p: ProductModel) =>
        StringUtils.hasAnyProperty(p.product_errors, DISPLAYED_PROPERTIES);

    return <span className = { classNames(hasErrors(product) ? "text-danger" : "" ) }>
        { hasErrors(product) && <i className="bi bi-exclamation-circle-fill me-2"></i> }
        Brand Owner Details
    </span>;
};

const BrandOwnerTabBody = (product: ProductModel) => {
    return <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
        <h5 className="mb-3 fw-bold">Brand owner details</h5>
        <div className="">
            { product.prim_info
                ? product.prim_info
                : "N/A"
            }
        </div>
    </div>;
};

export const BrandOwnerTab: TabModel = { header: BrandOwnerTabHeader, body: BrandOwnerTabBody };
