import React, { useEffect } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import authService, { TokenInfo } from "services/AuthService";
import Spinner from "components/Spinner";
import { useToastStore } from "store/ToastStoreProvider";
import { AUTH_PATHS } from "pages/auth/AuthRoutes";
import { useNavigate } from "react-router-dom";
import UserPublicService from "services/user/UserPublicService";

interface Params {
    token: string;
    innerForm: React.JSX.Element,
    messageHeader: string,
    tokenInfo: Required<TokenInfo>|undefined,
    setTokenInfo:  (ti: Required<TokenInfo>|undefined) => void
}

const linkTitleFromOrigin = (origin: string)=>{
    switch (origin) {
    case "MIGRATION": return "password reset";
    case "REGISTRATION": return "confirm registration";
    case "INVITATION": return "confirm invitation";
    default: return "";
    }
};

const CheckTokenForm = ({ token, innerForm, messageHeader, tokenInfo, setTokenInfo }:Params) => {

    const navigate = useNavigate();
    const { addToast } = useToastStore();

    useEffect(()=>{
        authService.checkToken(token)
            .then(info=>{
                if (info.missing || !info.email || !info.origin){
                    addToast({
                        header: messageHeader,
                        message:"Link is broken, please login again.",
                        variant: "warning"
                    });
                    navigate(AUTH_PATHS.LOGIN);
                }
                else {
                    setTokenInfo(info as Required<TokenInfo>);
                }
            })
            .catch(()=>
                addToast({
                    header: messageHeader,
                    message:"Something went wrong. Please try again",
                    variant: "danger"
                })
            );
    },[]);

    if (tokenInfo === undefined){
        return <Spinner className="mt-4 pt-2 text-center"/>;
    }
    if (tokenInfo.expired){
        return <ResendExpiredToken messageHeader={ messageHeader } tokenInfo={ tokenInfo }/>;
    }
    else if (tokenInfo.used){
        return <TokenAlreadyUsed tokenInfo={ tokenInfo }/>;
    }
    else {
        return innerForm;
    }
};
export default CheckTokenForm;

const ResendExpiredToken = ({ messageHeader, tokenInfo }: { messageHeader: string, tokenInfo: Required<TokenInfo> } ) => {
    const { addToast } = useToastStore();
    const navigate = useNavigate();
    const linkTitle = linkTitleFromOrigin (tokenInfo.origin);

    const resend = ()=>{
        UserPublicService.resendConfirmationEmail(tokenInfo.email)
            .then(()=>{
                addToast({
                    header: messageHeader,
                    message:`A ${ linkTitle } email has been sent to your inbox`,
                    variant: "success"
                });
                navigate(AUTH_PATHS.LOGIN);
            })
            .catch(()=>{
                addToast({
                    header: messageHeader,
                    message:"Something went wrong. Please try again",
                    variant: "danger"
                });
            });
    };

    return (
        <div className="registration-block">
            <div className="text-center login-block">
                <h1 className="text-primary mb-4">Oops!</h1>
                <Card className={ "mx-auto" } >
                    <Card.Body>
                        <Card.Text>
                            Your { linkTitle } link has expired for security reasons.<br/>
                            Don’t worry, you can request a new one.
                        </Card.Text>
                        <Button variant="primary" onClick={ resend }>Resend Email</Button>
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
};

const TokenAlreadyUsed = ({ tokenInfo }: { tokenInfo: Required<TokenInfo> }) => {
    return (
        <div className="registration-block">
            <div className="text-center login-block">
                <h1 className="text-primary mb-4">Oops!</h1>
                <Card className={ "mx-auto" } >
                    <Card.Body>
                        <Card.Text>
                            This { linkTitleFromOrigin (tokenInfo.origin) } link has already been used.<br/>
                            If you need further assistance, please contact the system administrator.
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
};
