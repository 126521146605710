import httpClient from "api/HttpClient";
import BACKEND from "services/Constants";
import { StringifiedDateTime } from "models/StringifiedDateTime";

class CertificationService {

    public static certify(type: string, year:string, month: string, email: string): Promise<CertificationResult> {
        return httpClient.post(BACKEND + "/wholesaler/price-posting/certifyProductsPrices?type="+type+"&year="+year+"&month="+month+"&email="+email);
    }

    public static countToByCertified(type: string, year:string, month: string): Promise<number> {
        return httpClient.get(BACKEND + "/wholesaler/price-posting/countToBeCertified?type="+type+"&year="+year+"&month="+month);
    }

    public static fetchMasterFilerInfo(): Promise<[number, string] | null> {
        return httpClient.get(BACKEND + "/wholesaler/price-posting/masterFilerInfo");
    }
}

export default CertificationService;

export type CertificationResult = {
    confirmationNumber: string,
    dateTime: StringifiedDateTime
};

