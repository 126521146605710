import React, { useEffect, useState } from "react";
import { StepProps } from "pages/wholesaler/appointment/models/StepProps";
import LetterService from "services/wholesaler/LetterService";
import Spinner from "components/Spinner";
import { useToastStore } from "store/ToastStoreProvider";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";

type Step3Props = { file?: SavedFile } & StepProps;
export type SavedFile = { storagePath: string, file: File } ;

const Step3 = (props: Step3Props) => {

    const { onSuccess, onBack, file } = props;

    const { addToast } = useToastStore();
    const [savedFile, setSavedFile] = useState<SavedFile>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    useEffect(() => {
        setSavedFile(file);
    }, [file]);

    function onContinue() {
        if (savedFile) {
            onSuccess(savedFile);
        }
    }

    function onFileUpdate(event: any) {
        const file: File = event.target.files[0];
        if (file === undefined) return;
        setIsLoading(true);
        LetterService.uploadFile(file).then(response => {
            if (response) {
                setSavedFile({ storagePath: response, file: file });
            }
        }).catch(() => {
            setIsError(true);
            addToast({ message:"Something went wrong. Please try again", header: "Load file", variant: "danger", timeoutMs: DEFAULT_TOASTER_TIMEOUT });
        }).finally(() => setIsLoading(false));
    }

    return <div className="card mt-3">
        <div className="card-body">
            <div className="mb-4">
                <h5><strong>Step 3:</strong> Select PDF-file</h5>
            </div>
            <div className="form-group">
                <label htmlFor="formFile" className="form-label">Choose file</label>
                <input
                    className="form-control"
                    type="file"
                    id="formFile"
                    accept="application/pdf"
                    onChange={ onFileUpdate }
                />
            </div>
            <Spinner hidden={ !isLoading } className="mt-4 text-center" />
            <div className="" hidden={ !savedFile }>
                <p><strong>Selected file:</strong> { savedFile?.file?.name }</p>
            </div>
            <div className="row justify-content-center mt-4">
                <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                    <input type="button" className="btn btn-outline-secondary w-100" value="Back" onClick={ onBack } />
                </div>
                <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                    <input
                        className="btn btn-primary w-100"
                        type="button"
                        onClick={ onContinue }
                        value="Continue"
                        disabled={ isError || isLoading || !savedFile }
                    />
                </div>
            </div>
        </div>
    </div>;
};

export default Step3;

