import React  from "react";
import { Controller } from "react-hook-form";
import {
    ControllerInputProps,
    defaultIfFalsy,
    extractPathFromObject,
    nonDisplayable,
    valueAsHandler
} from "components/form/BaseInput";

type InputGroupProps = Omit<ControllerInputProps, "inputRef" | "value" | "divClassName" > & {
    prefix?: string,
    postfix?: string,
    control: any;
};

export const InputGroup = (props: InputGroupProps)=>{

    const { label, name, prefix, postfix, control, rules, errors, valueAsInteger, valueAsFloat, ...rest } = props;

    return <Controller name={ name } control={ control } defaultValue={ defaultIfFalsy(props) } rules={ rules }
        render={ ({ field:{ value, onChange,  ...restField } }) => {
            const error = extractPathFromObject(errors,name);
            return <div className="mb-3">
                { label &&
                    <label className="form-label" htmlFor={ name }> { label } </label>
                }
                <div className="input-group has-validation">
                    { prefix && <span className="input-group-text">{ prefix }</span> }
                    <input className={ `form-control ${error ? "is-invalid" : ""}` } id={ name } { ...rest } { ...restField }
                        value={ nonDisplayable(value) ? defaultIfFalsy(props) : value }
                        onChange={ valueAsHandler(valueAsInteger, valueAsFloat, onChange) }
                    />
                    { postfix && <span className="input-group-text">{ postfix }</span> }
                    <div className="invalid-feedback">{ error?.message }</div>
                </div>
            </div>;
        } }
    />;
};
