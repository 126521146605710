import httpClient from "api/HttpClient";
import BACKEND from "services/Constants";
import { AlertModel } from "models/AlertModel";

const root = `${BACKEND}/admin/alerts`;
export default {
    getAll(): Promise<AlertModel[]> {
        return httpClient.get(`${root}/get-all`);
    },

    getAllForAdmin(): Promise<AlertModel[]> {
        return httpClient.get(`${root}/get-all-for-admin`);
    },

    get(id: string): Promise<AlertModel> {
        return httpClient.get(`${root}/get/${id}`);
    },

    hideAlert(id: number): Promise<void> {
        return httpClient.get(`${root}/hide-alert/${id}`);
    },

    update(model: AlertModel): Promise<void> {
        return httpClient.post(`${root}/update`, model);
    },

    delete(id: number): Promise<void> {
        return httpClient.delete(`${root}/delete/${id}`);
    }
};
