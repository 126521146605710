import ProductConstants from "services/ProductConstants";
import { BaseSelect } from "components/form/Select";
import BaseInput, { extractPathFromObject } from "components/form/BaseInput";
import { Controller } from "react-hook-form";

const alcohols = ProductConstants.alcoholOptions(["E"]);
alcohols.push({ value:"", title: "Other" });

export const AlcoholOtherControl = ({ control, errors }: { control: any; errors: any })=>{
    const isNumber = (value :string)=> !isNaN(parseFloat(value));
    const error = extractPathFromObject(errors, "alcohol");
    const errorMessage = error?.message;

    return <Controller name="alcohol" control={ control }
        render={ ({ field: { value, onChange, ref } }) => (
            <div className="row">
                <div className="col-12 col-lg-6 col-xl-5">
                    <BaseSelect name="alcoholSelect" options={ alcohols } label="Alcohol content *"
                        onChange={ e => onChange(e.target.value) } ref={ ref }
                        value={ isNumber(value) ? "" : value } addValueIfNotPresent={ true }
                        errorMessage={ errorMessage }
                    />
                </div>
                <div className="col-12 col-lg-6 col-xl-7">
                    <BaseInput name="alcoholOther" label="Other" type="number" step=".1"
                        onChange={ e => onChange(e.target.value) }
                        value={ isNumber(value) ? value : "" }
                        disabled={ !isNumber(value) && value !== "" }
                    />
                </div>
            </div>
        ) }
    />;
};
