import React from "react";
import notFound from "assets/img/404map.jpg";

const NotFound = () => {
    return <>
        <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 col-xl-6">
                <img className="d-block mx-auto mb-4 mt-3" src={ notFound } alt="Not found"/>
                <div className="mb-4 mb-md-5">
                    <h1 className="text-primary mb-4">We&apos;re sorry, the page that you are looking for is not available.</h1>
                    <div className="content-styles">
                        <p>This may be because:</p>
                        <ul>
                            <li><p className="mb-0">There is an error in the URL you have entered. Please double check that the URL is spelled correctly.</p></li>
                            <li><p className="mb-0">The page you are looking for may have been moved or deleted.</p></li>
                        </ul>

                        <p> <strong>If you are looking for a login page, please proceed to the <a href="/auth/login">login form </a> </strong></p>

                        <p>For help finding what you are looking for, search <a href="http://search.its.ny.gov/search/search.html">here</a>.</p>
                    </div>
                </div>
            </div>
        </div>
    </>;
};

export default NotFound;
