import React from "react";
import { Controller } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";
import { TransferDataParamModel } from "pages/admin/models/TransferDataParamModel";
import ProductConstants from "services/ProductConstants";
import FormUtil from "utils/FormUtil";
import { extractPathFromObject } from "components/form/BaseInput";
import { RadioStateButton } from "pages/admin/components/RadioStateButton";

interface Props {
    name:  keyof TransferDataParamModel,
    control: Control<TransferDataParamModel>,
    errors: any,
    disabled?: boolean,
    required?: boolean
}

export const RadioProductStateButtons = ({ name, control, disabled, errors, ...props }: Props) => {
    const error = extractPathFromObject(errors, name);
    return <div className="d-flex flex-column flex-md-row flex-lg-column flex-xxxl-row align-items-md-center align-items-lg-start align-items-xxxl-center my-4 pt-1">
        <label className="form-label mb-md-0 mb-lg-2 mb-lg-0 me-md-3 me-lg-0 me-xxxl-3">Choose schedule status</label>
        <Controller
            control={ control }
            name={ name }
            rules={ FormUtil.REQUIRED_FIELD }
            render={ ({ field: { onChange, value } }) => (
                <div className={ "radio-btns d-flex flex-row align-items-sm-center flex-wrap" + (error ? " is-invalid" : "") }>
                    <RadioStateButton
                        onChange={ () => onChange(ProductConstants.STATUS.WORKING) } { ...props }
                        checked={ value === ProductConstants.STATUS.WORKING } name={ name }
                        statusType={ ProductConstants.STATUS.WORKING } disabled={ disabled } />
                    <RadioStateButton
                        onChange={ () => onChange(ProductConstants.STATUS.CERTIFIED) } { ...props }
                        checked={ value === ProductConstants.STATUS.CERTIFIED } name={ name }
                        statusType={ ProductConstants.STATUS.CERTIFIED } disabled={ disabled } />
                    <RadioStateButton
                        onChange={ () => onChange(ProductConstants.STATUS.PUBLISHED) } { ...props }
                        checked={ value === ProductConstants.STATUS.PUBLISHED } name={ name }
                        statusType={ ProductConstants.STATUS.PUBLISHED } disabled={ disabled } />
                </div>
            ) }
        />
    </div>;
};
