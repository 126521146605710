import { action, makeObservable, observable } from "mobx";
import { MenuSettings } from "components/menu/Menu";

export class MenuStore {

    @observable
    public isLeftMenuHidden = true;

    @observable
    public leftMenuSettings?: MenuSettings;

    constructor() {
        makeObservable(this);
    }

    @action
    public showLeftMenu(menu: MenuSettings) {
        this.leftMenuSettings = menu;
        this.isLeftMenuHidden = false;
    }

    @action
    public clearAndHideLeftMenu() {
        this.leftMenuSettings = undefined;
        this.isLeftMenuHidden = true;
    }
}
