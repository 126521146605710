import { Nav, Tab } from "react-bootstrap";
import { ProductModel } from "models/ProductModel";
import React from "react";

interface Props {
    product: ProductModel;
    tabs: TabModel[];
}

export interface TabModel {
    header: (product: ProductModel) => JSX.Element;
    body: (product: ProductModel) => JSX.Element;
}

export const ProductTabContainer = ({ product, tabs }: Props) => {

    return <Tab.Container defaultActiveKey="0">
        <div className="d-flex flex-column flex-sm-row align-items-start">
            <Nav variant="pills" className="flex-column min-wp-230">
                {
                    tabs.map((value, index) => {
                        return <Nav.Item key={ index }>
                            <Nav.Link className="btn btn-outline-secondary" eventKey={ index.toString() }>
                                { value?.header(product) }
                            </Nav.Link>
                        </Nav.Item>;
                    })
                }
            </Nav>
            <Tab.Content className="flex-fill">
                {
                    tabs.map((value, index) => {
                        return <Tab.Pane key={ index } eventKey={ index.toString() }>
                            { value.body(product) }
                        </Tab.Pane>;
                    })
                }
            </Tab.Content>
        </div>
    </Tab.Container>;
};
