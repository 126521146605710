import React from "react";
import ConfirmRegistration from "pages/auth/ConfirmRegistration";
import ResetPassword from "pages/auth/ResetPassword";
import SetNewPassword from "pages/auth/SetNewPassword";
import ConfirmInvitation from "pages/auth/ConfirmInvitation";
import Login from "pages/auth/Login";
import Registration from "pages/auth/Registration";
import { Route, Routes } from "react-router-dom";
import NotFound from "pages/NotFound";

const RELATIVE_PATHS = {
    MAIN: "/auth",
    CONFIRM_REGISTRATION: "/confirm-registration/:token",
    CONFIRM_INVITATION: "/confirm-wholesaler-invitation/:token",
    RESET_PASSWORD: "/reset-password",
    LINK_RESET_PASSWORD: "/reset-password",
    SET_NEW_PASSWORD: "/set-new-password/:token",
    LOGIN: "/login",
    REGISTRATION: "/registration"
};

export const AUTH_PATHS = {
    MAIN: RELATIVE_PATHS.MAIN + "/*",
    CONFIRM_REGISTRATION: RELATIVE_PATHS.MAIN  + RELATIVE_PATHS.CONFIRM_REGISTRATION,
    CONFIRM_INVITATION: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.CONFIRM_INVITATION,
    RESET_PASSWORD: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.RESET_PASSWORD,
    SET_NEW_PASSWORD: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.SET_NEW_PASSWORD,
    LOGIN: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.LOGIN,
    REGISTRATION: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.REGISTRATION
};

const AuthRoutes = () => {
    return <Routes>
        <Route path={ RELATIVE_PATHS.RESET_PASSWORD } element={ <ResetPassword/> }/>
        <Route path={ RELATIVE_PATHS.CONFIRM_REGISTRATION } element={ <ConfirmRegistration/> }/>
        <Route path={ RELATIVE_PATHS.CONFIRM_INVITATION } element={ <ConfirmInvitation/> }/>
        <Route path={ RELATIVE_PATHS.SET_NEW_PASSWORD } element={ <SetNewPassword/> }/>
        <Route path={ RELATIVE_PATHS.LOGIN } element={ <Login/> }/>
        <Route path={ RELATIVE_PATHS.REGISTRATION } element={ <Registration/> }/>
        <Route path={ "*" } element={ <NotFound/> }/>
    </Routes>;
};

export default AuthRoutes;

