import { FieldErrors, useFieldArray } from "react-hook-form";
import { Control } from "react-hook-form/dist/types/form";
import { Card, Row } from "react-bootstrap";
import { SelectWithDefault } from "components/form/select/SelectWithDefault";
import { TYPE_OPTIONS, UNIT_OPTIONS } from "services/DiscountConstants";
import Input from "components/form/Input";
import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { DiscountValues } from "models/DiscountValues";
import { createDefaultDiscountValue } from "pages/wholesaler/priceposting/dialogs/EditDiscountDialog";
import classNames from "classnames";

//TODO move somewhere up
export interface Appendable {
    append: (i:any)=>void,
    addDefault: ()=>void
}

interface EditDiscountValuesProps {
    valuesName: string,
    control:  Control<any>,
    errors: FieldErrors,
    readonly?: boolean,
    isTabDiscounts?: boolean
}

const EditDiscountValues = ({ valuesName, control, errors, readonly, isTabDiscounts }:EditDiscountValuesProps, ref: any) =>{

    const { fields, append, remove } = useFieldArray({ control, name: valuesName });

    useEffect(() => {
        if (isTabDiscounts && !readonly && fields.length === 0) {
            append(createDefaultDiscountValue());
        }
    }, [isTabDiscounts]);

    useImperativeHandle(ref, () => ({
        append: (i:DiscountValues)=> {
            if (!readonly) {
                append(i);
            }
        },
        addDefault: ()=>{
            if (!readonly) {
                append(createDefaultDiscountValue());
            }
        }
    }));

    readonly = readonly || false;
    const deleteValue = (index: number, e: any) => {
        e.preventDefault();
        if (fields.length>1 && !readonly) {
            remove(index);
        }
    };

    return <div className="row">
        {
            fields?.map((value, index) =>
                <div className="col-12 col-lg-4 mb-4" key={ value.id }>
                    <Card>
                        <div className="card-body pb-2 pt-3">
                            <div className="styleguide-head mb-4">
                                <div className="d-flex flex-row align-items-center mb-2">
                                    <h5 className="me-auto mb-0">Discount { index+1 }</h5>
                                    <a
                                        className={ classNames("fs-4", readonly || fields.length < 2 ? "disabled" : "") }
                                        href="#/" onClick={ (e)=> deleteValue(index, e) }>
                                        <i className="bi-x-circle-fill"/>
                                    </a>
                                </div>
                                <hr className="mt-1"/>
                            </div>
                            <Row>
                                <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                    <SelectWithDefault name={ `${valuesName}[${index}].type` }
                                        options={ TYPE_OPTIONS } label="Type" disabled={ readonly } />
                                </div>
                                <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                    <Input name={ `${valuesName}[${index}].amount` } placeholder="00.00" label="Amount" type="number"
                                        control={ control } errors={ errors }  disabled={ readonly }
                                        rules={ {
                                            "required": "Please add amount",
                                            "min": { "value": 1,      "message": "Can be between 1 and 100000" },
                                            "max": { "value": 100000, "message": "Can be between 1 and 100000" }
                                        } }
                                    />
                                </div>
                                <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                    <Input name={ `${valuesName}[${index}].quantity` } placeholder="0" label="On" type="number"
                                        control={ control } errors={ errors }  disabled={ readonly }
                                        rules={ {
                                            "required": "Please add count",
                                            "min": { "value": 1,    "message": "Can be between 1 and 3000" },
                                            "max": { "value": 3000, "message": "Can be between 1 and 3000" }
                                        } }
                                    />
                                </div>
                                <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                    <SelectWithDefault name={ `${valuesName}[${index}].unit` }
                                        options={ UNIT_OPTIONS } label="Units" disabled={ readonly }/>
                                </div>
                            </Row>
                        </div>
                    </Card>
                </div>
            )
        }
    </div>;
};

export default forwardRef(EditDiscountValues);
