import React from "react";
import { useModalStore } from "store/ModalStoreProvider";
import { observer } from "mobx-react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useKeyDownEvent } from "components/KeyDownHook";

const ModalWindow = () => {

    const modalStore = useModalStore();
    const { body, header, show, additionalButtons, size, fullscreen } = modalStore;

    useKeyDownEvent(closeWindow, ["Escape"]);

    function closeWindow() {
        modalStore.hideModal();
    }

    return <Modal
        show={ show } onHide={ closeWindow } centered={ true } size={ size }
        scrollable={ true } animation={ true } fullscreen={ fullscreen }>
        <Modal.Header closeButton>
            <h5 className="modal-title">{ header }</h5>
        </Modal.Header>
        <Modal.Body>
            { body }
        </Modal.Body>
        <Modal.Footer>
            <Button variant="outline-secondary" onClick={ closeWindow }>
                Cancel
            </Button>
            { additionalButtons?.map(e => { return e; }) }
        </Modal.Footer>
    </Modal>;
};

export default observer(ModalWindow);
