import React, { useEffect } from "react";
import { JobInfo } from "models/JobInfo";
import { useToastStore } from "store/ToastStoreProvider";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";
import { useTimeStore } from "store/TimeStoreProvider";
import { classByJobStatus, statusName } from "utils/JobsDashboard";
import { useForm } from "react-hook-form";
import Input from "components/form/Input";
import { SelectControlled } from "components/form/Select";
import JobService from "services/admin/JobService";
import FormUtil from "utils/FormUtil";

export interface FtpJobComponentProps {
    job: JobInfo;
    loadData: () => void;
    setIsLoading: (value:boolean) => void;
}

interface FtpJobData {
    month: number;
    year: number;
}

export const FtpJobComponent = ({ job, loadData, setIsLoading }:FtpJobComponentProps) => {
    const { serverTime } = useTimeStore();
    const { addToast } = useToastStore();

    const MONTHS = [
        { value: 1, title: "January" },
        { value: 2, title: "February" },
        { value: 3, title: "March" },
        { value: 4, title: "April" },
        { value: 5, title: "May" },
        { value: 6, title: "June" },
        { value: 7, title: "July" },
        { value: 8, title: "August" },
        { value: 9, title: "September" },
        { value: 10, title: "October" },
        { value: 11, title: "November" },
        { value: 12, title: "December" },
    ];

    const {
        handleSubmit,
        control,
        getValues,
        setValue,
        setError,
        clearErrors,
        reset,
        formState: { errors }
    } = useForm<FtpJobData>({ defaultValues: { } as FtpJobData });

    useEffect(() => prepareData() , []);

    function prepareData() {
        setValue("year", serverTime.year());
        setValue("month", serverTime.month()+1);
    }

    function jobStartedMessage() {
        addToast({ header: "Job status", timeoutMs: DEFAULT_TOASTER_TIMEOUT, message:"FTP Reports Job has been started. You will get an email when it ends.", variant: "success" });
    }

    function onSubmit(data: FtpJobData) {
        setIsLoading(true);
        JobService.runFtpReportsJob(data.year, data.month).then((_) => {
            jobStartedMessage();
            loadData();

        }).catch(() => {
            addToast({ header: "Job status", timeoutMs: DEFAULT_TOASTER_TIMEOUT, message:"Job FTP Reports failed", variant: "danger" });
        }).finally(() =>  setIsLoading(false));
    }

    return <div className="col-12 col-xl-4 mb-4">
        <div className={ classByJobStatus(job.status) }>
            <div className="card">
                <div className="card-body">
                    <form onSubmit={ handleSubmit(onSubmit) }>
                        <h5 className="fw-bold mb-4">FTP Reports</h5>
                        <div className="">
                            <span className="badge">
                                <i className="bi-check-circle me-1"></i> { statusName(job.status) }
                            </span>
                            <p className="mt-3 mb-0"><strong>Scheduled
                                on:</strong> { serverTime?.month() ? serverTime?.month() + 1 : 1 }/{ job.scheduledDay }/{ serverTime?.year() }
                            </p>
                            { job.dateCompleted && <p className="mt-2 mb-0"><strong>Date
                                Completed:</strong> { new Date(job.dateCompleted).toLocaleDateString("en-US") }</p> }
                        </div>

                        <Input
                            control={ control } errors={ errors } name="year"
                            placeholder="Year"
                            label={ "Year" }
                            type="number"
                            rules={ FormUtil.YEAR_VALIDATIONS }
                        />

                        <SelectControlled name="month" options={ MONTHS } control={ control } label="Month"/>

                        <input type="submit" className="btn btn-primary w-100 mt-4" value="Run job" disabled={ job.status === "STARTED"} />
                    </form>
                </div>
            </div>
        </div>
    </div>;
};
