import React from "react";
import Input from "components/form/Input";
import DatePicker from "react-datepicker";

const DelinquentRecordsView = () => {

    return <div className="d-flex flex-column min-vh-100">
        <div className="mb-5">
            <h1 className="text-primary mb-0">Manage Delinquent Records</h1>
        </div>
        <div className="d-flex flex-row flex-wrap align-items-end justify-content-between">
            <div className="form-group me-lg-3 mb-3">
                <label htmlFor="Status" className="form-label">Status</label>
                <select id="Status" className="form-select min-wp-180" aria-label="Select">
                    <option selected>Active</option>
                    <option value="1">Inactive</option>
                </select>
            </div>
            <div>
                <button className="btn btn-primary mb-3 me-3" data-bs-toggle="modal" data-bs-target="#exampleModalAdd">Add new Delinquency</button>
                <button className="btn btn-outline-secondary mb-3" data-bs-toggle="modal" data-bs-target="#exampleModalBatch">Batch Submissions</button>
            </div>
        </div>
        <div className="my-2 d-flex flex-column flex-xl-row align-items-center">
            <div className="me-lg-auto pt-3 mb-3 mb-lg-0">
                <h5>Found <strong>3 records</strong> matching criteria</h5>
                <p>Status of licenses updated on <strong>07/22/2022 06:00 PM</strong>.
                    Status of delinquencies is current.</p>
            </div>
            <div className="pt-3 d-flex flex-row">
                <div className="ms-xl-3 mb-2">
                    <button
                        data-bs-toggle="modal" data-bs-target="#exampleModalFull"
                        className="btn btn-outline-secondary">
                        <i className="bi-arrows-fullscreen me-2" />
                        Table full screen view
                    </button>
                </div>
            </div>
        </div>

        <div className="table-responsive">
            <table className="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th>Serial #</th>
                        <th>Premises Name</th>
                        <th>Address</th>
                        <th>County</th>
                        <th>Date On</th>
                        <th>Delivery Amount</th>
                        <th>Delivery Date</th>
                        <th>User ID On</th>
                        <th>License Class</th>
                        <th>License Type</th>
                        <th>Comment/ Reference</th>
                        <th>License Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <a href="/">9878817</a>
                        </td>
                        <td>
                            <a href="/">Some long premises name</a>
                        </td>
                        <td>Some long address somewhere</td>
                        <td>Essex</td>
                        <td>02/29/2022</td>
                        <td>41.25</td>
                        <td>04/30/2022</td>
                        <td>NYSLA</td>
                        <td>303</td>
                        <td>WW</td>
                        <td>Some Comment</td>
                        <td>
                            <span className="badge bg-success"><i className="bi-check-circle"></i>Active</span>
                        </td>
                        <td className="text-nowrap">
                            <button data-bs-toggle="modal" data-bs-target="#exampleModalEdit" className="btn btn-link py-0 px-1 me-2" title="Edit"><i className="bi-pencil"></i></button>
                            <button data-bs-toggle="modal" data-bs-target="#exampleModalPrint" className="btn btn-link py-0 px-1 me-2" title="Print"><i className="bi-printer"></i></button>
                            <button className="btn btn-link py-0 px-1" title="Delete user" data-bs-toggle="modal" data-bs-target="#exampleModalRemove">
                                <i className="bi-trash"></i>
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <a href="/">2178877</a>
                        </td>
                        <td>
                            <a href="/">Some long premises name</a>
                        </td>
                        <td>Some long address somewhere</td>
                        <td>Essex</td>
                        <td>02/29/2022</td>
                        <td>33.33</td>
                        <td>04/30/2022</td>
                        <td>NYSLA</td>
                        <td>303</td>
                        <td>WW</td>
                        <td>Some Comment</td>
                        <td>
                            <span className="badge bg-secondary"><i className="bi-pause-circle"></i>Inactive</span>
                        </td>
                        <td className="text-nowrap">
                            <button data-bs-toggle="modal" data-bs-target="#exampleModalEdit" className="btn btn-link py-0 px-1 me-2" title="Edit"><i className="bi-pencil"></i></button>
                            <button data-bs-toggle="modal" data-bs-target="#exampleModalPrint" className="btn btn-link py-0 px-1 me-2" title="Print"><i className="bi-printer"></i></button>
                            <button className="btn btn-link py-0 px-1" title="Delete user" data-bs-toggle="modal" data-bs-target="#exampleModalRemove">
                                <i className="bi-trash"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ul className="justify-content-center pagination mt-3">
            <li className="page-item disabled">
                <span className="page-link">
                    <span className="bi bi-arrow-left me-2"></span>Prev
                </span>
            </li>
            <li className="page-item active">
                <span className="page-link">1</span>
            </li>
            <li className="page-item">
                <a className="page-link" role="button" href="/">2</a>
            </li>
            <li className="page-item">
                <a className="page-link" role="button" href="/">3</a>
            </li>
            <li className="page-item">
                <a className="page-link" role="button" href="/">...</a>
            </li>
            <li className="page-item">
                <a className="page-link" role="button" href="/">50</a>
            </li>
            <li className="page-item">
                <a className="page-link" role="button" href="/">
                    Next<span className="bi bi-arrow-right ms-2"></span>
                </a>
            </li>
        </ul>
        <div className="modal fade" id="exampleModalAdd" aria-labelledby="exampleModalAddLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalAddLabel">Add New Delinquency</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Cancel"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12 col-xl-6 mt-2">
                                <div className="pe-xl-4">
                                    <div className="mb-4">
                                        <h5 className="fw-bold mb-3">Premises</h5>
                                        <hr/>
                                    </div>
                                    <div className="d-flex flex-row align-items-end">
                                        <div className="flex-fill">
                                            <Input name="Serial" placeholder="Enter serial number" label="Serial number" type="number"/>
                                        </div>
                                        <div className="mb-1">
                                            <a className="btn btn-outline-primary mb-3 ms-3" href="/">Search</a>
                                        </div>
                                    </div>
                                    <div className="mb-2">
                                        <label htmlFor="Name" className="form-label">Name</label>
                                        <p className="mb-0">Some name</p>
                                    </div>
                                    <div className="mb-2">
                                        <p className="mb-0"><strong>License class:</strong></p>
                                        <p className="mb-0">252</p>
                                    </div>
                                    <div className="mb-2">
                                        <p className="mb-0"><strong>License type:</strong></p>
                                        <p className="mb-0">OP</p>
                                    </div>
                                    <div className="mb-2">
                                        <p className="mb-0"><strong>Address:</strong></p>
                                        <p className="mb-0">123 Demo, ave demo, NY 216444</p>
                                    </div>
                                    <div className="mb-2">
                                        <p className="mb-0"><strong>County:</strong></p>
                                        <p className="mb-0">Alba</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6 mt-4 mt-xl-2">
                                <div className="ps-xl-4">
                                    <div className="mb-4">
                                        <h5 className="fw-bold mb-3">Delinquency Information</h5>
                                        <hr/>
                                    </div>
                                    <Input name="Delivery_Amount" placeholder="$ 00.00" label="Delivery Amount *" type="text"/>
                                    <div className="form-group">
                                        <label htmlFor="Select3" className="form-label">Delivery Date *</label>
                                        <DatePicker
                                            selected={ new Date() }
                                            onChange={ (date:Date) => { date; } }
                                            dateFormat="dd/MM/yyyy"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="Textarea" className="form-label">Comment / Reference</label>
                                        <textarea rows={ 5 } id="Textarea" className="form-control" placeholder="Enter comment / reference"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" className="btn btn-primary">Add New Delinquency</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal fade" id="exampleModalEdit" aria-labelledby="exampleModalEditLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalEditLabel">Edit Delinquent Record</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Cancel"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12 col-xl-6 mt-2">
                                <div className="pe-xl-4">
                                    <div className="mb-4">
                                        <h5 className="fw-bold mb-3">Premises</h5>
                                        <hr/>
                                    </div>
                                    <div className="mb-2">
                                        <p className="mb-0"><strong>Serial number:</strong></p>
                                        <p className="mb-0">99998888</p>
                                    </div>
                                    <div className="mb-2">
                                        <p className="mb-0"><strong>Name:</strong></p>
                                        <p className="mb-0">Demo Company with long name</p>
                                    </div>
                                    <div className="mb-2">
                                        <p className="mb-0"><strong>License class:</strong></p>
                                        <p className="mb-0">252</p>
                                    </div>
                                    <div className="mb-2">
                                        <p className="mb-0"><strong>License type:</strong></p>
                                        <p className="mb-0">OP</p>
                                    </div>
                                    <div className="mb-2">
                                        <p className="mb-0"><strong>Address:</strong></p>
                                        <p className="mb-0">123 Demo, ave demo, NY 216444</p>
                                    </div>
                                    <div className="mb-2">
                                        <p className="mb-0"><strong>County:</strong></p>
                                        <p className="mb-0">Alba</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-xl-6 mt-4 mt-xl-2">
                                <div className="ps-xl-4">
                                    <div className="mb-4">
                                        <h5 className="fw-bold mb-3">Delinquency Information</h5>
                                        <hr/>
                                    </div>
                                    <Input name="Delivery_Amount" placeholder="$ 00.00" label="Delivery Amount *" value="$ 33.56" type="text"/>
                                    <div className="form-group">
                                        <label htmlFor="Select3" className="form-label">Delivery Date *</label>
                                        <DatePicker
                                            selected={ new Date() }
                                            onChange={ (date:Date) => { date; } }
                                            dateFormat="dd/MM/yyyy"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="Textarea" className="form-label">Comment / Reference</label>
                                        <textarea rows={ 5 } id="Textarea" className="form-control" placeholder="Enter comment / reference"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" className="btn btn-primary">Edit</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal fade" id="exampleModalRemove" aria-labelledby="exampleModalRemoveLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalRemoveLabel">Remove Delinquent Records</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Cancel"></button>
                    </div>
                    <div className="modal-body">
                        <p className="mb-0">You are going to delete 1 delinquent records. Are you sure?</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" className="btn btn-primary">Yes</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal fade" id="exampleModalBatch" aria-labelledby="exampleModalBatchLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalBatchLabel">Batch Submissions</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Cancel"></button>
                    </div>
                    <div className="modal-body">
                        <p>The Batch and API WebService Submissions are available for special users that are pre-certified and approved by SLA.</p>
                        <p className="mb-0">If you want to use this, please contact the Wholesale Bureau at <a href="mailto: wholesale.bureau@sla.ny.gov">wholesale.bureau@sla.ny.gov</a> for assistance.</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal fade" id="exampleModalFull" aria-labelledby="exampleModalFullLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-fullscreen">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalBatchLabel">Found 3 records matching criteria</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Cancel"></button>
                    </div>
                    <div className="modal-body">
                        <div className="table-responsive">
                            <table className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>Serial #</th>
                                        <th>Premises Name</th>
                                        <th>Address</th>
                                        <th>County</th>
                                        <th>Date On</th>
                                        <th>Delivery Amount</th>
                                        <th>Delivery Date</th>
                                        <th>User ID On</th>
                                        <th>License Class</th>
                                        <th>License Type</th>
                                        <th>Comment/ Reference</th>
                                        <th>License Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <a href="/">9878817</a>
                                        </td>
                                        <td>
                                            <a href="/">Some long premises name</a>
                                        </td>
                                        <td>Some long address somewhere</td>
                                        <td>Essex</td>
                                        <td>02/29/2022</td>
                                        <td>41.25</td>
                                        <td>04/30/2022</td>
                                        <td>NYSLA</td>
                                        <td>303</td>
                                        <td>WW</td>
                                        <td>Some Comment</td>
                                        <td>
                                            <span className="badge bg-success"><i className="bi-check-circle"></i>Active</span>
                                        </td>
                                        <td className="text-nowrap">
                                            <button data-bs-toggle="modal" data-bs-target="#exampleModalEdit" className="btn btn-link py-0 px-1 me-2" title="Edit"><i className="bi-pencil"></i></button>
                                            <button data-bs-toggle="modal" data-bs-target="#exampleModalPrint" className="btn btn-link py-0 px-1 me-2" title="Print"><i className="bi-printer"></i></button>
                                            <button className="btn btn-link py-0 px-1" title="Delete user" data-bs-toggle="modal" data-bs-target="#exampleModalRemove">
                                                <i className="bi-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <a href="/">2178877</a>
                                        </td>
                                        <td>
                                            <a href="/">Some long premises name</a>
                                        </td>
                                        <td>Some long address somewhere</td>
                                        <td>Essex</td>
                                        <td>02/29/2022</td>
                                        <td>33.33</td>
                                        <td>04/30/2022</td>
                                        <td>NYSLA</td>
                                        <td>303</td>
                                        <td>WW</td>
                                        <td>Some Comment</td>
                                        <td>
                                            <span className="badge bg-secondary"><i className="bi-pause-circle"></i>Inactive</span>
                                        </td>
                                        <td className="text-nowrap">
                                            <button data-bs-toggle="modal" data-bs-target="#exampleModalEdit" className="btn btn-link py-0 px-1 me-2" title="Edit"><i className="bi-pencil"></i></button>
                                            <button data-bs-toggle="modal" data-bs-target="#exampleModalPrint" className="btn btn-link py-0 px-1 me-2" title="Print"><i className="bi-printer"></i></button>
                                            <button className="btn btn-link py-0 px-1" title="Delete user" data-bs-toggle="modal" data-bs-target="#exampleModalRemove">
                                                <i className="bi-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <ul className="justify-content-center pagination mt-3">
                            <li className="page-item disabled">
                                <span className="page-link">
                                    <span className="bi bi-arrow-left me-2"></span>Prev
                                </span>
                            </li>
                            <li className="page-item active">
                                <span className="page-link">1</span>
                            </li>
                            <li className="page-item">
                                <a className="page-link" role="button" href="/">2</a>
                            </li>
                            <li className="page-item">
                                <a className="page-link" role="button" href="/">3</a>
                            </li>
                            <li className="page-item">
                                <a className="page-link" role="button" href="/">...</a>
                            </li>
                            <li className="page-item">
                                <a className="page-link" role="button" href="/">50</a>
                            </li>
                            <li className="page-item">
                                <a className="page-link" role="button" href="/">
                                    Next<span className="bi bi-arrow-right ms-2"></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal fade print" id="exampleModalPrint" aria-labelledby="exampleModalPrintLabel" aria-hidden="true">
            <div className="modal-dialog modal-fullscreen modal-dialog-centered">
                <div className="modal-content overflow-auto">
                    <div className="modal-header flex-column">
                        <h1>Notice of default</h1>
                        <h4>Date: 07/26/2022</h4>
                    </div>
                    <div className="modal-body overflow-visible">
                        <div className="mb-4 pb-1">
                            <h4 className="mb-4">From retail license certificate:</h4>
                            <div className="row mb-2">
                                <div className="col-3 col-md-2">
                                    <p className="mb-0"><strong>Serial number:</strong></p>
                                </div>
                                <div className="col-9 col-md-10">
                                    <p className="mb-0">9999888</p>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-3 col-md-2">
                                    <p className="mb-0"><strong>Name of Retail Licensee:</strong></p>
                                </div>
                                <div className="col-9 col-md-10">
                                    <p className="mb-0">Demo Company with long name</p>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-3 col-md-2">
                                    <p className="mb-0"><strong>DBA Name:</strong></p>
                                </div>
                                <div className="col-9 col-md-10">
                                    <p className="mb-0">Company with long name</p>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-3 col-md-2">
                                    <p className="mb-0"><strong>License type:</strong></p>
                                </div>
                                <div className="col-9 col-md-10">
                                    <p className="mb-0">OP</p>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-3 col-md-2">
                                    <p className="mb-0"><strong>Premise Address:</strong></p>
                                </div>
                                <div className="col-9 col-md-10">
                                    <p className="mb-0">Some very long Premise Address</p>
                                </div>
                            </div>

                            <div className="row mb-2">
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-6 col-md-4">
                                            <p className="mb-0"><strong>City:</strong></p>
                                        </div>
                                        <div className="col-6 col-md-8">
                                            <p className="mb-0">Weynon</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row">
                                        <div className="row mb-2">
                                            <div className="col-6 col-md-4">
                                                <p className="mb-0"><strong>State:</strong></p>
                                            </div>
                                            <div className="col-6 col-md-8">
                                                <p className="mb-0">NY</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-2">
                                <div className="col-6">
                                    <div className="row">
                                        <div className="col-6 col-md-4">
                                            <p className="mb-0"><strong>Zip:</strong></p>
                                        </div>
                                        <div className="col-6 col-md-8">
                                            <p className="mb-0">65333</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="row">
                                        <div className="row mb-2">
                                            <div className="col-6 col-md-4">
                                                <p className="mb-0"><strong>County:</strong></p>
                                            </div>
                                            <div className="col-6 col-md-8">
                                                <p className="mb-0">ALBA</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-4 pb-1">
                            <h4 className="mb-4">Delivery Dates:</h4>
                            <div className="row mb-2">
                                <div className="col-3 col-md-2">
                                    <strong>Last Delivery Dates</strong>
                                </div>
                                <div className="col-9 col-md-10">
                                    <strong>Unpaid Amount</strong>
                                </div>
                            </div>
                            <hr className="mt-2 mb-0" />
                            <div className="row mb-2 pt-2">
                                <div className="col-3 col-md-2">
                                    02/24/2022
                                </div>
                                <div className="col-9 col-md-10">
                                    $ 10.10
                                </div>
                            </div>
                            <div className="border-bottom"></div>
                            <div className="row mb-2 pt-2">
                                <div className="col-3 col-md-2">
                                    03/05/2022
                                </div>
                                <div className="col-9 col-md-10">
                                    $ 25.30
                                </div>
                            </div>
                            <div className="border-bottom"></div>
                            <div className="mt-3">
                                <h6 className="fw-bold">
                                    <span className="">Total:</span> $ 35.40
                                </h6>
                            </div>
                        </div>

                        <div className="pb-2">
                            <div className="card mb-4">
                                <div className="card-body p-3">
                                    <p className="mb-0">
                                        <small>
                                            <strong>NOTE:</strong> In accordance with the provisions of Section 101-a/aa of the Alcoholic Beverage Control Law, notice is hereby given that you are in default for payment for purchase of alcoholic beverages. You may NOT purchase alcoholic beverages from any manufacturer or wholesaler except for cash, which means U.S. currency, money order, certified check, bank officer&apos;s check, or draft or check drawn on the account of the retail licensee. The manufacturer or wholesaler may require that a check drawn on the account of the retail licensee be certified. The Notice of Default has been provided to the New York State Liquor Authority electronically.
                                        </small>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <h4 className="mb-4">Manufacturer or Wholesaler information:</h4>
                        <div className="row mb-2">
                            <div className="col-3 col-md-2">
                                <p className="mb-0"><strong>Name:</strong></p>
                            </div>
                            <div className="col-9 col-md-10">
                                <p className="mb-0">Demo Company with long name</p>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-3 col-md-2">
                                <p className="mb-0"><strong>Address:</strong></p>
                            </div>
                            <div className="col-9 col-md-10">
                                <p className="mb-0">Demo Company with address, NY, 356677</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default DelinquentRecordsView;
