import React from "react";
import { modalStore } from "store/ModalStoreProvider";

export const showViewingDialog = () => {
    modalStore.setSize("lg");
    modalStore.setBody(<ViewingDialog />);
    modalStore.setHeader("Deadlines for Viewing Previously Filed Price Postings");
    modalStore.showModal();
};

export const ViewingDialog = () => {
    return <div>
        <p className="mb-4">The online applications allow users to view the price schedule information previously filed with the Liquor Authority according to the following dates:</p>
        <h5>Wholesaler Schedules</h5>
        <div className="table-responsive mb-3">
            <table className="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th className="w-30">Date</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1st thru 14th</td>
                        <td>View the schedules filed for the current month.</td>
                    </tr>
                    <tr>
                        <td>15th thru End of Month</td>
                        <td>View the schedules filed for the next month.</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <h5>Retail Schedules</h5>
        <div className="table-responsive">
            <table className="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th className="w-30">Date</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1st thru 14th</td>
                        <td>View the schedules filed for the current month.</td>
                    </tr>
                    <tr>
                        <td>15th thru 3 Business Days after the 15th</td>
                        <td>View the schedules filed for the next month.</td>
                    </tr>
                    <tr>
                        <td>3 Business Days after the 15th thru End of Month</td>
                        <td>View the schedules filed for the next month, including any amendments made by the wholesaler.</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>;
};
