import React from "react";
import { Col, Row } from "react-bootstrap";
import { DiscountValues } from "models/DiscountValues";
import ErroneousValue from "components/ErroneousValue";
import { unitString } from "services/DiscountConstants";
import { useNumericFormat } from "react-number-format";
import { currencyFormatWithoutPrefixProps } from "components/CurrencyText";

interface Props {
    showTitle?: boolean;
    highlightAmount?: boolean;
    values?: DiscountValues[];
}

export const DiscountValuesList = ( props: Props)=>{
    const { values, ...rest } = { showTitle: true, highlightAmount: false, ...props }; //apply defaults
    return <Row>
        <Col>
            { (values || []).slice(0,5).map((dvItem, idx)=> (
                <SingleDiscount key={ idx } idx={ idx } dvItem={ dvItem } numerationShift={ 1 } { ...rest } />
            )
            ) }
        </Col>
        <Col>
            { (values || []).slice(5).map((dvItem, idx)=> (
                <SingleDiscount key={ idx } idx={ idx } dvItem={ dvItem } numerationShift={ 6 } { ...rest } />
            )
            ) }
        </Col>
    </Row>;
};

interface SingleDiscountProps {
    idx: number,
    showTitle: boolean,
    highlightAmount: boolean,
    dvItem: DiscountValues,
    numerationShift: number
}

const SingleDiscount = ({ idx, showTitle, highlightAmount, dvItem, numerationShift }:SingleDiscountProps) => {
    const { format } = useNumericFormat(currencyFormatWithoutPrefixProps);
    const currencyFormat = (v: number | undefined) =>
        v === undefined  ? "" : format ? format (v.toString()) : "";

    const AmountAndType = ()=> dvItem.type && dvItem.type === "%"
        ? <><ErroneousValue item={ dvItem } name="amount"/><ErroneousValue item={ dvItem } name="type"/></>
        : <><ErroneousValue item={ dvItem } name="type"/><ErroneousValue item={ dvItem } name="amount" formattedValue={ currencyFormat(dvItem.amount) }/></>;

    return <p className={ "mb-0" }>
        { idx + numerationShift }.&nbsp;
        { showTitle && <strong>Discount:&nbsp;</strong> }
        { highlightAmount
            ? <strong className="text-primary"><AmountAndType/></strong>
            : <AmountAndType/> }
        &nbsp;on&nbsp;
        <ErroneousValue item={ dvItem } name="quantity" />&nbsp;
        <ErroneousValue item={ dvItem } formattedValue={ unitString(dvItem.unit) } name="unit" />&nbsp;
    </p>;
};
