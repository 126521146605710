import React, { useState } from "react";
import { StepProps } from "pages/wholesaler/appointment/models/StepProps";
import { ProductModel } from "models/ProductModel";
import LetterService from "services/wholesaler/LetterService";
import { Link } from "react-router-dom";

type Step4Props = { initProducts: ProductModel[] } & StepProps;

const Step4 = (props: Step4Props) => {

    const { initProducts, letter, onSuccess, onBack } = props;

    const [products, setProducts] = useState<ProductModel[]>(initProducts);
    const [isError, setIsError] = useState<boolean>(false);

    function unlinkItem(id: number) {
        if (products.length != 1) {
            products.splice(products.map(product => product.id).indexOf(id), 1);
            setProducts([...products]);
        } else {
            setIsError(true);
        }
    }

    return <div className="d-flex flex-column">
        <div className="card mt-3">
            <div className="card-body">
                <div className="mb-4">
                    <h5><strong>Step 4:</strong> Review and post the letter</h5>
                </div>
                <div className="row mb-2">
                    <div className="col-12 col-md-4 col-lg-2">
                        <p className="mb-0"><strong>File:</strong></p>
                    </div>
                    <div className="col-12 col-md-8 col-lg-10">
                        <p className="mb-0">
                            <Link to={ LetterService.downloadFile(letter.storage_path, letter.file_name) } target="_blank" download>
                                { letter.file_name }
                            </Link>
                        </p>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-12 col-md-4 col-lg-2">
                        <p className="mb-0"><strong>Description:</strong></p>
                    </div>
                    <div className="col-12 col-md-8 col-lg-10">
                        <p className="mb-0">{ letter?.description }</p>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-12 col-md-4 col-lg-2">
                        <p className="mb-0"><strong>Schedule:</strong></p>
                    </div>
                    <div className="col-12 col-md-8 col-lg-10">
                        <p className="mb-0">{ letter?.post_type }</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-4 col-lg-2">
                        <p className="mb-0"><strong>Linked items:</strong></p>
                    </div>
                    <div className="col-12 col-md-8 col-lg-10">
                        <p className="mb-0">See in the table</p>
                    </div>
                </div>
            </div>
        </div>

        <div className="mt-2">
            <div className="table-responsive mt-4">
                <table className="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Link</th>
                            <th>Wholesaler item ID</th>
                            <th>TTB / CoLA ID #</th>
                            <th>Approval / BLR #</th>
                            <th>Brand name</th>
                            <th>Description</th>
                            <th>Size/ Unit</th>
                            <th>Wholesaler / NYS Supplier ID</th>
                        </tr>
                    </thead>
                    <tbody>
                        { products.map((item: ProductModel, index: number) => {
                            return (
                                <tr key={ index }>
                                    <td>
                                        { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions*/ }
                                        <div className="btn btn-sm btn-outline-primary d-flex" onClick={ () => unlinkItem(item.id) } >
                                            <i className="bi-link-45deg me-1"></i>Unlink
                                        </div>
                                    </td>
                                    <td>{ item.prod_item }</td>
                                    <td>{ item.ttb_id }</td>
                                    <td>{ item.brand_reg }</td>
                                    <td>{ item.brand_name }</td>
                                    <td>{ item.prod_name }</td>
                                    <td>{ item.item_size } { item.um }</td>
                                    <td>{ item.distrib_id }</td>
                                </tr>
                            );
                        }) }
                    </tbody>
                </table>
            </div>
        </div>

        <div className="mt-3">
            { isError &&
                <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                    Should be specified at least one product!
                </div>
            }
            <div className="row justify-content-center">
                { onBack &&
                    <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                        <input className="btn btn-outline-secondary w-100" type="button" onClick={ onBack } value="Back"/>
                    </div>
                }
                <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                    <input className="btn btn-primary w-100" type="button" onClick={ () => onSuccess(products) } value="Post letter to NYSLA"/>
                </div>
            </div>
        </div>
    </div>;
};

export default Step4;

