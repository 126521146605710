import React from "react";
import StringUtils from "utils/StringUtils";

interface Props {
    onChange: any,
    checked: boolean,
    statusType: string,
    disabled?: boolean,
    name: string
}

export const RadioStateButton = ({ onChange, statusType, checked, disabled, name, ...props }: Props) => {
    return  <div className="radio-btn">
        <input
            type="radio" className="btn-check" name={ name }
            id={ "option-" + statusType + name } autoComplete="off"
            onChange={ () => onChange(statusType) }
            checked={ checked }
            disabled={ disabled }
            { ...props }
        />
        <label className="btn btn-outline-primary" htmlFor={ "option-" + statusType + name }>
            { statusType ? StringUtils.capitalize(statusType) : "" }
        </label>
        <i className="bi bi-check"></i>
    </div>;
};
