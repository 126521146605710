import React, { PropsWithChildren, Ref, forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Link, generatePath, useLocation, useNavigate } from "react-router-dom";
import Paginator                   from "components/Paginator";
import { showLicenseDelinquentInfo } from "components/dialogs/LicenseDelinquentInfo";
import URLUtil                     from "utils/URLUtil";
import DateUtil                    from "utils/DateUtil";
import { PageInfo }                  from "models/PageInfo";
import { PageableRequest }           from "models/requests/PageableRequest";
import { MessageModel } from "models/message.model";
import { DEFAULT_PAGE_LIMIT }        from "api/HttpClient";
import WholesalerDelinquentService from "services/wholesaler/WholesalerDelinquentService";
import { WHOLESALER_PATHS }          from "pages/wholesaler/WholesalerRoutes";
import { showEditDelinquencyDialog } from "pages/wholesaler/delinquent/dialogs/EditDelinquencyDialog";
import CurrencyText from "components/CurrencyText";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";
import { useToastStore } from "store/ToastStoreProvider";
import { useAuthStore } from "store/AuthStoreProvider";
import { showRemoveDelinquencyDialog } from "pages/wholesaler/delinquent/dialogs/RemoveDelinquencyDialog";
import { RecordsFormModel, RecordsSearchForm } from "components/RecordsSearchForm";
import { SubmitHandler } from "react-hook-form";


export interface Reloadable {
    reload: ()=>void
}

export interface RecordsResponse {
    class_code: string;
    comment: string;
    county: string;
    date_on: string;
    delivery_amount: number;
    delivery_date: string;
    id: number;
    premise_address: string;
    premise_address2: string;
    premise_name: string;
    serial_number: number;
    permit_id: string;
    type_code: string;
    legacy_on_user: string;
    legacy_off_user: string;
    on_user: string;
    off_user: string;
    license_id: number;
}

type ManageRecordsRequest = RecordsFormModel & PageInfo;

type Props = {
    readonly: boolean,
    wholesalerLicenseId?: number // empty if wholesaler is current logged-in user
}

const WholesalerDelinquentRecords = ({ readonly, wholesalerLicenseId, children }: PropsWithChildren<Props>, ref: Ref<Reloadable>) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { permissionStore } = useAuthStore();
    const { addToast, addDefaultDangerToast } = useToastStore();

    const [ urlData, setUrlData ] = useState<ManageRecordsRequest>(getReadSearchParams());
    const [ pagesCount, setPagesCount ] = useState<number>(0);
    const [ totalItemsCount, setTotalItemsCount ] = useState<number>(0);
    const [ pageLimit ] = useState<number>(urlData?.limit || DEFAULT_PAGE_LIMIT);
    const [ isDataLoading, setIsDataLoading ] = useState<boolean>(false);
    const [ records, setRecords ] = useState<RecordsResponse[]>([]);

    const [ wUpdateStatusDate, setWUpdateStatusDate ] = useState<string>();
    const [ lastRequest, setLastRequest] = useState<PageableRequest>({} as PageableRequest);

    useImperativeHandle(ref, () => ({
        reload: () => reload()
    }));

    useEffect(() => {
        WholesalerDelinquentService.getLicenseUpdateDate()
            .then(resp => { setWUpdateStatusDate(DateUtil.formatWithServerTZ(resp)); });
    }, []);

    useEffect(() => {
        updateUrlParams();
        performQuery();
    }, [urlData]);

    const updateUrlParams = () => URLUtil.updateUrlParams(location.pathname, urlData, navigate);

    function getReadSearchParams() {
        const params = URLUtil.readSearchParams(["from", "to"]) as ManageRecordsRequest;
        params.page = params.page || 1;
        params.status = params.status || "0";
        return params;
    }

    const reload = () => setUrlData({ ...urlData });

    function performQuery() {
        setIsDataLoading(true);
        const request = {
            pageInfo: { page: urlData.page || 1, limit: pageLimit },
            filters: {
                wholesaler_license_id: wholesalerLicenseId || "",
                license: urlData.license || "",
                premise_name: urlData.premise_name || "",
                address: urlData.address || "",
                county: urlData.county || "",
                from: urlData.from?.getTime() || "",
                to: urlData.to?.getTime() || "",
                status: urlData.status || "0"
            }
        } as PageableRequest;
        setLastRequest(request);

        WholesalerDelinquentService.searchRecords(request)
            .then(response => {
                setTotalItemsCount(response.pageInfo.totalItemsCount || 0);
                setPagesCount(response.pageInfo.pagesCount || 1);
                setRecords(response.data);
                setIsDataLoading(false);
            });
    }

    const onSubmit: SubmitHandler<RecordsFormModel> = async data => {
        const searchParams = data as ManageRecordsRequest;
        searchParams.page = 1;
        setUrlData(searchParams);
    };

    function successMessageAndReload (msg: string) {
        return () =>{
            addToast({ message:msg, header:"Manage Records",variant: "success", timeoutMs: DEFAULT_TOASTER_TIMEOUT });
            reload();
        };
    }

    const errorMessage = () => addDefaultDangerToast("Manage Records");

    function isNotActive(): boolean {
        return urlData.status != "0";
    }

    return  (
        <div className="d-flex flex-column min-vh-100">
            <RecordsSearchForm onSubmit={ onSubmit } filters={ urlData }/>
            { !isDataLoading &&
                <div className="my-2 d-flex flex-column flex-xl-row align-items-center">
                    <div className="me-lg-auto pt-3 mb-3 mb-lg-0">
                        <h5 className="mb-0">Found <strong>{ totalItemsCount } records</strong> matching criteria</h5>
                        <p className="mb-0">Status of licenses updated on <strong>{ wUpdateStatusDate }</strong>. Status of delinquencies is current.</p>
                    </div>
                    <div className="pt-3 d-flex flex-row">
                        { !isDataLoading && records.length > 0 &&
                            <>
                                <div className="ms-3 mb-2">
                                    <button type="button" className="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="bi-download me-2"/>Export
                                    </button>
                                    <ul className="dropdown-menu">
                                        <Link to={ WholesalerDelinquentService.searchRecordsCsv(lastRequest) } target="_blank" className="dropdown-item">
                                            CSV
                                        </Link>
                                        <Link to={ WholesalerDelinquentService.searchRecordsTxt(lastRequest) } target="_blank" className="dropdown-item">
                                            TXT
                                        </Link>
                                    </ul>
                                </div>
                                { children }
                            </>
                        }
                    </div>
                </div>
            }

            { !isDataLoading && records.length > 0 &&
                <div className="table-fix-height">
                    <div className="table-responsive">
                        <table className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>License ID</th>
                                    <th>Serial Number</th>
                                    <th>Premises Name</th>
                                    <th className="d-none">Address</th>
                                    <th className="d-none">County</th>
                                    <th>Date On</th>
                                    <th>Delivery Amount</th>
                                    <th>Delivery Date</th>
                                    <th>User ID On</th>
                                    <th>User ID Off</th>
                                    <th>Comment/ Reference</th>
                                    <th>COD Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    records.map(r =>
                                        <tr key={ r.id }>
                                            <td>
                                                <button className="btn btn-link" onClick={ () => showLicenseDelinquentInfo(r.license_id) }>
                                                    { r.permit_id }
                                                </button>
                                            </td>
                                            <td>{ r.serial_number }</td>
                                            <td>
                                                <button className="btn btn-link" onClick={ () => showLicenseDelinquentInfo(r.license_id) }>
                                                    { r.premise_name }
                                                </button>
                                            </td>
                                            <td className="d-none">{ r.premise_address }{ r.premise_address2 ? ", " + r.premise_address2 : "" }</td>
                                            <td className="d-none">{ r.county }</td>
                                            <td>{ r.date_on }</td>
                                            <td><CurrencyText value={ r.delivery_amount } /></td>
                                            <td>{ r.delivery_date }</td>
                                            <td>{ r.on_user || r.legacy_on_user }</td>
                                            <td>{ r.off_user || r.legacy_off_user }</td>
                                            <td>{ r.comment }</td>
                                            <td>
                                                <span className={ isNotActive() ? "badge bg-grey" : "badge bg-danger" }>
                                                    <i className={ isNotActive() ? "bi-check-circle": "bi-exclamation-circle" }></i>
                                                    { isNotActive() ? "Cleared" : "Delinquent" }
                                                </span>
                                            </td>
                                            <td className="text-nowrap">
                                                { !readonly &&
                                                    <button
                                                        className="btn btn-link py-0 px-1 me-2"
                                                        title="Edit"
                                                        disabled={ isNotActive() || !permissionStore.isWholesalerAndBA_CW_CA_CB() }
                                                        onClick={ () => showEditDelinquencyDialog(r.id, successMessageAndReload("The record has been updated."), errorMessage) }
                                                    ><i className="bi-pencil"></i>
                                                    </button>
                                                }
                                                <Link
                                                    to={ generatePath(WHOLESALER_PATHS.PRINT_DELINQUENT_RECORD, { "id": r.id }) }
                                                    rel="noopener noreferrer"
                                                    target={ "_blank" }
                                                    className={ "btn btn-link py-0 px-1 me-2 " }
                                                    title="Print">
                                                    <i className="bi-printer"/>
                                                </Link>
                                                { !readonly &&
                                                    <button
                                                        className="btn btn-link py-0 px-1"
                                                        title="Delete"
                                                        disabled={ isNotActive() || !permissionStore.isWholesalerAndBA_CW_CA_CB() }
                                                        onClick={ () => showRemoveDelinquencyDialog(r.id,
                                                            successMessageAndReload("The record has been deleted."),
                                                            (err:MessageModel)=>addToast({ header: "Manage Records", variant: "warning", message:err.message })
                                                        ) }
                                                    >
                                                        <i className="bi-trash"></i>
                                                    </button>
                                                }
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
            <div>
                <Paginator totalPages={ pagesCount } currentPage={ urlData.page || 1 } pageClicked={ (pageNumber: number) => setUrlData({ ...urlData, page: pageNumber }) }/>
            </div>
        </div>
    );
};

export default forwardRef(WholesalerDelinquentRecords);
