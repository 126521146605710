import React from "react";
import { Row } from "react-bootstrap";
import { FormLabelWithTooltip } from "components/form/FormLabelWithTooltipParams";
import { SelectPackageType } from "components/form/select/SelectPackageType";
import { DistribIdInput } from "pages/wholesaler/priceposting/pages/brands/components/DistribIdInput";
import { ControlledFormSwitch } from "components/form/ControlledFormSwitch";
import { SelectControlled } from "components/form/Select";
import { ExtendedAgeControl } from "pages/wholesaler/priceposting/pages/brands/components/ExtendedAgeControl";
import { useFormContext } from "react-hook-form";
import { combo_asse_values, combo_disa_values } from "services/ComboConstants";

interface Props {
    type: string
}

export const ComboStep1 = ({ type }:Props)=>{
    const { control, formState: { errors } } = useFormContext();
    return <>
        { /*first row*/ }
        <Row>
            <AssemblyDisassembly/>
            <div className="col-12 col-xl-6">
                <div className="ps-xl-4">
                    <Row>
                        <div className="col-12 col-lg-6">
                            <FormLabelWithTooltip label="Package type" tooltip="Some text here" htmlFor="reg_combo"/>
                            <SelectPackageType name="reg_combo" id="reg_combo" control={ control } errors={ errors } addAllOption={ false } addValueIfNotPresent={ true } filterValues={ ["C"] } disabled/>
                        </div>
                    </Row>
                </div>
            </div>
        </Row>

        { /*middle row*/ }
        { (type === "LW" || type === "WW") &&
            <Row>
                <div className="col-12 col-md-6">
                    <div className="pe-xl-4">
                        <DistribIdInput/>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="ps-xl-4">
                        <div className="mb-3">
                            <p className="mb-2">
                                <strong>Label *</strong>
                            </p>
                            <div className="switch-year pt-1">
                                <label className="form-check-label me-2" htmlFor="custom-switch3">Brand agent</label>
                                <ControlledFormSwitch control={ control } errors={ errors } name="label_type" label="Brand owner" options={ ["O","A"] } id="custom-switch3"/>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>
        }

        { /*last row*/ }
        <Row>
            <div className="col-12">
                <FormLabelWithTooltip label="Age *" tooltip="Some text here" htmlFor="age_id" />
                <ExtendedAgeControl name="vintage" control={ control } errors={ errors }/>
            </div>
        </Row>
    </>;
};

const AssemblyDisassembly = ()=>{
    const { control, formState: { errors } } = useFormContext();

    return <div className="col-12 col-xl-6">
        <div className="pe-xl-4">
            <Row>
                <div className="col-12 col-lg-6">
                    <SelectControlled name="combo_disa" control={ control } options={ combo_disa_values }
                        label="Combo type 1" addValueIfNotPresent={ true } errors={ errors }/>
                </div>
                <div className="col-12 col-lg-6">
                    <SelectControlled name="combo_asse" control={ control } options={ combo_asse_values }
                        label="Combo type 2" addValueIfNotPresent={ true } errors={ errors }/>
                </div>
            </Row>
        </div>
    </div>;
};
