import { useForm } from "react-hook-form";
import React, { useEffect } from "react";
import URLUtil from "utils/URLUtil";
import Input from "components/form/Input";
import { SelectActive } from "components/form/select/SelectActive";
import DatePickerInput from "components/form/DatePickerInput";
import SelectCounty from "components/form/select/SelectCounty";
import { useKeyDownEnterEvent } from "components/KeyDownHook";

export interface RecordsFormModel {
    license: string;
    premise_name: string;
    address: string;
    county: string;
    from: Date;
    to: Date;
    status: string;
}

interface Props {
    filters: RecordsFormModel;
    onSubmit: (data: RecordsFormModel) => void;
}

export const RecordsSearchForm = ({ filters, onSubmit }: Props) => {

    const {
        handleSubmit,
        reset,
        control,
        setValue
    } = useForm<RecordsFormModel>({
        mode: "onSubmit",
        defaultValues: {
            status: "0"
        }
    });
    useKeyDownEnterEvent(() => { handleSubmit(onSubmit)(); });

    useEffect(() => { URLUtil.setValuesFromUrl(filters, setValue); }, [filters]);

    return <form onSubmit={ handleSubmit(onSubmit) } className="card">
        <div className="card-body">
            <div className="row">
                <div className="col-12 col-lg-6 col-xl-4">
                    <div className="pe-lg-4">
                        <Input name="license" control={ control } placeholder="Enter license id or serial number" label="License ID" type="text" />
                    </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-4">
                    <div className="ps-lg-4 px-xl-2">
                        <Input name="premise_name" control={ control } placeholder="Enter premise name" label="Premise Name" type="text" />
                    </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-4">
                    <div className="pe-lg-4 ps-xl-4 pe-xl-0">
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <DatePickerInput name="from" control={ control } label="From" />
                            </div>
                            <div className="col-12 col-sm-6">
                                <DatePickerInput name="to" control={ control }  label="To" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-4">
                    <div className="ps-lg-4 ps-xl-0 pe-xl-4">
                        <Input name="address" control={ control } placeholder="Enter address" label="Address" type="text" />
                    </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-4">
                    <div className="pe-lg-4 pe-xl-0 px-xl-2">
                        <SelectCounty name="county" control={ control } label="County" />
                    </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-4">
                    <div className="ps-lg-4 ps-xl-4">
                        <SelectActive name="status" control={ control } label="COD Status" />
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mt-2">
                <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                    <div className="pe-md-1">
                        <input type="button" className="btn btn-outline-secondary w-100" onClick={ () => reset() } value="Clear Form"/>
                    </div>
                </div>
                <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                    <div className="ps-md-1">
                        <input type="submit" className="btn btn-primary w-100" value="Filter"/>
                    </div>
                </div>
            </div>
        </div>
    </form>;
};
