import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import { AUTH_PATHS } from "pages/auth/AuthRoutes";
import Input from "components/form/Input";
import { SubmitHandler, UseFormReturn } from "react-hook-form";
import FormUtil from "utils/FormUtil";
import { SUPPORT_EMAIL } from "config";
import { DisplayedError } from "pages/auth/Login";
import { LoginModel } from "pages/auth/models/LoginModel";
import { useNavigate } from "react-router-dom";
import { useKeyDownEnterEvent } from "components/KeyDownHook";


const LoginForm = ({ form, displayedError, onSubmit, isPending }: {
    form: UseFormReturn<LoginModel>,
    displayedError : DisplayedError | undefined,
    onSubmit: SubmitHandler<LoginModel>,
    isPending: boolean
}) => {
    const navigate = useNavigate();

    const {
        handleSubmit,
        formState: { errors },
        setFocus,
        control
    } = form;

    useEffect(() => {
        setFocus("email");
    }, []);

    useKeyDownEnterEvent(() => !isPending && handleSubmit(onSubmit)());

    return (
        <div className="registration-block">
            <div className="mb-4 mb-md-5 text-center">
                <h1 className="text-primary mb-4">Login</h1>
                <div className="mb-4 mb-md-5 text-center">
                    <p className="pb-1">We have upgraded the Price Posting and Delinquency Management system.</p>
                    <p className="mb-1">Check out this document for more information:</p>
                    <p className="pb-1"><a rel="noreferrer" target="_blank" href="https://docs.google.com/document/d/e/2PACX-1vRc2rpIegYRe8_QDOux48pTFiYZDtRKVVzXleh6cfBhhNwe-kcpnJxZupF0ou3iXynabyt4LLeZk9ZJ/pub">Migration to the new Price Posting Management System</a></p>
                    <p>Please submit any questions to <a href={ `mailto:${SUPPORT_EMAIL}` }>{ SUPPORT_EMAIL }</a></p>
                </div>
            </div>
            <div className="login-block">
                <form onSubmit={ handleSubmit(onSubmit) } className="card">
                    <div className="card-body">
                        <Input
                            name="email" control={ control } errors={ errors } type="email"
                            label="Email" placeholder="Enter your email"
                            rules={ FormUtil.EMAIL_VALIDATIONS }
                            autoComplete="username" id="username"
                        />
                        <Input
                            name="password" control={ control } errors={ errors } type="password"
                            label="Password" placeholder="Enter password" maxLength={ 56 }
                            rules={ FormUtil.REQUIRED_FIELD }
                            autoComplete="current-password" id="current-password"
                        />

                        <ErrorBar error={ displayedError } />

                        <Button className="btn btn-link" onClick={ () => navigate(AUTH_PATHS.RESET_PASSWORD) } disabled={ isPending }>
                            Forgot password?
                        </Button>

                        <div className="row justify-content-center mt-4">
                            <div className="col-6 col-md-5 col-lg-5 mt-1">
                                <div className="pe-md-1">
                                    <Button type="submit" className="btn btn-primary w-100" disabled={ isPending }>
                                        Login
                                    </Button>
                                </div>
                            </div>
                            <div className="col-6 col-md-5 col-lg-5 mt-1">
                                <div className="ps-md-1">
                                    <Button className="btn-secondary btn w-100" onClick={ () => navigate(AUTH_PATHS.REGISTRATION) } disabled={ isPending }>
                                        Register user
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="pt-5 text-center">
                    <div className="mt-4">
                        <a className="btn btn-outline-primary mb-2" rel="noreferrer" href="https://support.nyslapricepostings.com/s/article/Information-Page-about-the-Migration" target="_blank">Visit the Support Hub</a>
                        <p className="pt-1">Access all support documents, tutorials, and help resources in one place.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginForm;

const ErrorBar = ({ error }: { error?: DisplayedError }) =>
    (<>
        { error &&
            <div className="invalid-message alert alert-sm alert-danger">
                { (() => {
                    switch (error.code) {
                    case 406:
                        return <p className="mb-0">Your credentials do not correspond to any active licenses.</p>;
                    case 400:
                        return <p className="mb-0">Authentication failed. Please check your credentials.</p>;
                    default:
                        return <p className="mb-0">{ error.message || "Unknown error" }</p>;
                    }
                })() }
            </div>
        }
    </>);
