import React, { ComponentProps } from "react";
import { Pagination } from "react-bootstrap";
import { debounce } from "utils/debounce";

/* eslint-disable @typescript-eslint/no-explicit-any */
interface PaginationProps extends ComponentProps<any>{
    totalPages: number;
    currentPage: number;
    pageClicked: any;
}

const Paginator = (props : PaginationProps) => {
    const { pageClicked } = props;
    let { totalPages, currentPage } = props;

    if (typeof currentPage === "string") {
        currentPage = parseInt(currentPage);
    }

    if (typeof totalPages === "string") {
        totalPages = parseInt(totalPages);
    }

    const onPageClick = debounce(pageClicked, 250);
    const items = [];
    
    for (let number = 1; number <= totalPages; number++) {
        if (number === 2 && totalPages > 10 && currentPage - 4 > 1) {
            number = currentPage - 2;
            const avr: number = Math.floor(currentPage / 2);
            items.push(
                <Pagination.Ellipsis
                    key={ avr }
                    onClick={ () => { onPageClick(avr); } }
                />
            );
        }

        if (number > (currentPage + 2) && totalPages > 10 && (currentPage + 4) < totalPages) {
            number = totalPages;
            const avr: number = totalPages - Math.floor((totalPages - currentPage) / 2);
            items.push(
                <Pagination.Ellipsis
                    key={ avr }
                    onClick={ () => { onPageClick(avr); } }
                />
            );
        }

        items.push(
            <Pagination.Item
                key={ number }
                active={ number === currentPage }
                onClick={ props.currentPage === number ? undefined : () => { onPageClick(number); } }
            >
                { number }
            </Pagination.Item>
        );
    }

    return (
        <div className="mt-4" hidden={ totalPages === 1 }>
            <Pagination className="justify-content-center">
                <Pagination.Prev
                    key={ "prevpage" }
                    disabled={ currentPage === 1 }
                    onClick={
                        currentPage === 1 ? undefined : () => { onPageClick(currentPage - 1); }
                    }
                ><span className="bi bi-arrow-left me-2"></span>Prev</Pagination.Prev>
                { items }
                <Pagination.Next
                    key={ "nextpage" }
                    disabled={ currentPage === totalPages }
                    onClick={
                        currentPage === totalPages ? undefined : () => { onPageClick(currentPage + 1); }
                    }
                >Next<span className="bi bi-arrow-right ms-2"></span></Pagination.Next>
            </Pagination>
        </div>
    );
};

export default Paginator;
