import React, { useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { UnpackNestedValue } from "react-hook-form/dist/types/form";
import { FormProvider, useForm } from "react-hook-form";
import { ResolverResult } from "react-hook-form/dist/types/resolvers";
import { useNavigate } from "react-router-dom";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";
import { ToastDescription } from "store/ToastStore";
import CrudProductService from "services/wholesaler/priceposting/CrudProductService";
import {
    DISCOUNT_SOURCES,
    ITEMS_FIELD,
    ManualProduct,
    ProductModelForForm,
    TabName,
    getItemIndexFromField,
    isStep1Field
} from "pages/wholesaler/priceposting/models/ManualProduct";
import { VINTAGE_NA } from "pages/wholesaler/priceposting/pages/brands/components/ExtendedAgeControl";
import { ComboStep1 } from "pages/wholesaler/priceposting/pages/brands/components/ComboStep1";
import { RegularVapStep1 } from "pages/wholesaler/priceposting/pages/brands/components/RegularVapStep1";
import RegularVapStep2 from "pages/wholesaler/priceposting/pages/brands/components/RegularVapStep2";
import ComboStep2 from "pages/wholesaler/priceposting/pages/brands/components/ComboStep2";
import { useToastStore } from "store/ToastStoreProvider";
import { Combo as ComboModel } from "models/Combo";
import { Appendable } from "pages/wholesaler/priceposting/components/EditDiscountValues";
import { DISCOUNT_VALUES_FIELD } from "models/ProductModel";
import StringUtils from "utils/StringUtils";
import PricePostingTitle from "components/PricePostingTitle";
import { CollapseModel } from "pages/wholesaler/priceposting/models/CollapseModel";
import { useKeyDownEnterEvent } from "components/KeyDownHook";

const defaultManualProduct = (isCombo: boolean, postType: string)=>{
    return {
        reg_combo: isCombo ? "C" : "R",
        ...(isCombo ? { combo_disa: "RD", combo_asse: "" } : { bev_type: "O" }),
        vintage: VINTAGE_NA,
        post_type: postType,
        alcohol: isCombo ? "0.01" : "A",
        label_type: "A",
        items: [
            defaultProductItem(isCombo ? [defaultComboItem()] : undefined )
        ]
    };
};
const defaultProductItem = (combos?: ComboModel[]) => ({
    um: "ML", fullcase: "N", nys_prod: "N", lim_avail: "", discount_id: null, disc_code: "",
    item_size: null, botpercase: null, subpack: null, bot_price: null, case_price: null, bot_nyc: null, case_nyc: null,
    split_charge: null, nys_whole: null, alloc_met: "", combos: combos,
    discount_source: DISCOUNT_SOURCES.SELECT, active_tab: TabName.ITEM_INFO
} as unknown as ProductModelForForm);

const defaultComboItem = () => ({ prod_item: "", quantity: null } as unknown as ComboModel);

const errorMsg: ToastDescription = {
    message:"Something went wrong. Please try again",
    header: "Save",
    variant: "danger",
    timeoutMs: DEFAULT_TOASTER_TIMEOUT };

export enum RegComboType {
    REGULAR_VAP = "regular-vap",
    COMBO = "combo"
}

export type EditProps = {
    rcType: RegComboType,
    postType: string
    editedProduct?: ManualProduct
}

export const Page = ({ rcType, postType, editedProduct }: EditProps)=>{

    const isCombo = rcType === RegComboType.COMBO;
    const { addToast } = useToastStore();
    const navigate = useNavigate();
    const [collapseModel, setCollapseModel] = useState<CollapseModel>({ all: false });
    const [ buttonsBlocked, setButtonsBlocked ] = useState(false);
    const [ focusState, setFocusState ] = useState<{ field?: string; tabReady: boolean }>(
        { field: undefined, tabReady: false });
    const formReturn = useForm<ManualProduct>({
        mode: "onSubmit",
        resolver: customResolver,
        reValidateMode: "onBlur",
        shouldFocusError: false,
        defaultValues:  editedProduct ? editedProduct : defaultManualProduct(isCombo, postType)
    });
    const { setFocus, handleSubmit, setValue, trigger, formState:{ errors } } = formReturn;
    useKeyDownEnterEvent(() => !buttonsBlocked && handleSubmit(onSubmit)());

    const onSubmit = (entity: ManualProduct) => {
        setButtonsBlocked(true);
        CrudProductService.createOrUpdate(entity)
            .then((r) => {
                if (r.error > 0 && r.successful == 0){
                    addToast({ ...errorMsg, message:`All ${r.error} items were not saved due to some errors`, variant: "danger", timeoutMs:0 });
                    setButtonsBlocked(false);
                }
                else if (r.error > 0 && r.successful > 0) {
                    addToast({ ...errorMsg, message:`${r.error} items were not saved due to some errors`, variant: "warning", timeoutMs:0 });
                    setButtonsBlocked(false);
                }
                else {
                    editedProduct ? checkOnDelete(entity, editedProduct) : successfullySaved();
                }
            })
            .catch(()=> {
                addToast(errorMsg);
                setButtonsBlocked(false);
            });
    };

    function checkOnDelete(entity: ManualProduct, editedProduct: ManualProduct) {
        const id = editedProduct.items[0].id;
        entity.items.find(form => form.id === id)
            ? successfullySaved()
            : CrudProductService.delete(id)
                .then(() => successfullySaved())
                .catch(() => { addToast(errorMsg); });
    }

    function successfullySaved() {
        addToast({ ...errorMsg, message:"All items were successfully saved", variant: "success" });
        returnToManageBrandsAndItems();
    }

    function customResolver(values:UnpackNestedValue<ManualProduct>): Promise<ResolverResult<ManualProduct>>{
        return CrudProductService.validate(values)
            .then(r=> (Object.keys(r).length === 0)
                ? { values:values, errors: {} }
                : { values:{},     errors: r }
            );
    }

    useEffect(()=>{
        if (editedProduct){ // display all errors on editing existing product
            trigger();
        }
    },[]);

    useEffect(()=>{
        const keys = Object.keys(StringUtils.flatten(errors));
        if (keys.length === 0 ){
            return;
        }
        const f = keys[0].replace(/\.(type|message)$/, "");
        // step 1 field components has no tabs to pre-activate
        // step 2 field components have tabs, need to switch them before focusing
        isStep1Field(f) ? setFocusSilently(f) : setFocusState ({ field: f, tabReady: false });
    },[errors]);

    useEffect(()=>{
        if (focusState.field === undefined){
            return;
        }
        if (focusState.tabReady){// pass 2 - setting focus
            setFocusSilently(focusState.field);
        }
        else{ //pass 1 - activating tab and switching to pass 2
            activateTabForField(focusState.field);
            setFocusState({ ...focusState, tabReady: true });
        }
    },[focusState]);

    const activateTabForField = (field: string)=>{
        const idx = getItemIndexFromField(field);
        if (idx !== null){
            setValue(`${ITEMS_FIELD}[${idx}].active_tab` as keyof ManualProduct,
                field.includes(DISCOUNT_VALUES_FIELD) ? TabName.DISCOUNTS : TabName.ITEM_INFO);
        }
    };

    const piControl = useRef<Appendable>();
    const addComboOrProductItem = ()=> piControl.current?.append(isCombo ? defaultComboItem() : defaultProductItem()) ;

    const setFocusSilently = (field: string)=>{
        try {
            setFocus(field as keyof ManualProduct);
        }
        catch (e){}
    };
    const returnToManageBrandsAndItems = ()=> navigate(-1);

    function collapse(e: any, value: boolean) {
        e.preventDefault();
        setCollapseModel(prevState => ({ ...prevState, all: value }));
    }

    return <div className="d-flex flex-column">
        <PricePostingTitle type={ postType } />
        <div>
            <div className="row mb-3 mb-md-2">
                <div className="col-12 col-lg-6">
                    { editedProduct == undefined
                        ? <h4 className="fw-bold mb-0">Add Brand or Item</h4>
                        : <h4 className="fw-bold mb-0">Edit Brand</h4>
                    }
                </div>
            </div>

            <FormProvider  { ...formReturn }>
                <form onSubmit={ handleSubmit(onSubmit) }>
                    { /* Step 1 */ }
                    <Card className="mt-3">
                        <Card.Body className="pb-">
                            <div className="mb-4">
                                { editedProduct == undefined
                                    ? <h5><strong>Step 1:</strong> Enter brand information</h5>
                                    : <h5><strong>Step 1:</strong> Edit brand information</h5>
                                }
                                { editedProduct == undefined
                                    ? <p className="mb-0">In order to create a new brand, you must complete the following form. Fields marked with a * are required.</p>
                                    : <p className="mb-0">To modify an existing brand, you need to fill out the provided form. Required fields are marked with a *.</p>
                                }
                            </div>
                            { isCombo ? <ComboStep1 type={ postType }/> : <RegularVapStep1 type={ postType }/> }
                        </Card.Body>
                    </Card>

                    { /* Step 2 */ }
                    <Card className="mt-4">
                        <Card.Body className="pb-3">
                            <div>
                                <div className="d-flex flex-column flex-sm-row">
                                    <div>
                                        <h5><strong>Step 2:</strong> Add item(s)</h5>
                                        <p className="mb-0">
                                            { editedProduct == undefined
                                                ? "Add one or multiple items"
                                                : "Add or edit items" }
                                        </p>
                                    </div>
                                    { !isCombo &&
                                        <div className="ms-sm-auto mt-3 mt-sm-0">
                                            <button
                                                className="btn btn-link p-0 me-3"
                                                onClick={ (e) => collapse(e, false) }
                                            >Expand All</button>
                                            <button
                                                className="btn btn-link p-0"
                                                onClick={ e => collapse(e, true) }
                                            >Collapse All</button>
                                        </div>
                                    }
                                </div>
                            </div>
                        </Card.Body>
                    </Card>

                    <div className="">
                        { isCombo
                            ? <ComboStep2 type={ postType } ref={ piControl }/>
                            : <RegularVapStep2 collapseModel={ collapseModel } ref={ piControl }/>
                        }
                    </div>

                    <div className="d-flex justify-content-end mt-4">
                        <button type="button" onClick={ addComboOrProductItem } className="btn btn-outline-primary btn-add fs-4 p-2" disabled={ buttonsBlocked }>
                            <i className="bi-plus-circle-fill"></i>
                        </button>
                    </div>

                    <div className="row justify-content-center mt-2">
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-4">
                            <button type="button" className="btn btn-outline-secondary w-100" disabled={ buttonsBlocked }
                                onClick={ returnToManageBrandsAndItems }>Cancel</button>
                        </div>
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-4">
                            <button type="submit" className="btn btn-primary w-100" disabled={ buttonsBlocked }>Save</button>
                        </div>
                    </div>
                </form>
            </FormProvider>
        </div>
    </div>;
};
