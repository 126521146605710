export function classByJobStatus(status: string): string {
    switch (status) {
    case "SUCCESS": return "jobs-success";
    case "FAIL": return "jobs-danger";
    case "FAIL_NOT_RUN": return "jobs-danger";
    case "NOT_RUN": return "jobs-grey";
    case "STARTED": return "jobs-grey";
    default: return "jobs-grey";
    }
}

export function statusName(status: string): string {
    switch (status) {
    case "SUCCESS": return "Success";
    case "FAIL": return "Failed: error";
    case "FAIL_NOT_RUN": return "Failed: not run";
    case "NOT_RUN": return "Not Run";
    case "STARTED": return "In progress";
    default: return "Not Run";
    }
}
