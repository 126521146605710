import React from "react";
import { Control } from "react-hook-form/dist/types/form";
import { Controller } from "react-hook-form";
import Select, { Props, StylesConfig } from "react-select";
import { OptionModel } from "models/OptionModel";
import { extractPathFromObject } from "components/form/BaseInput";
import { FieldErrors } from "react-hook-form/dist/types/errors";

const roleColor = (role: string) => {
    if (role === "WHOLESALER" || role === "RETAILER") {
        return "green";
    }
    if (role.startsWith("COD")) {
        return "blue";
    }
    if (role.startsWith("PRICE")) {
        return "purple";
    }
    return "red";
};
const colourStyles: StylesConfig<OptionModel, true> = {
    multiValue: (baseStyles, { data } ) => ({
        ...baseStyles,
        background: "transparent",
        border: `1px solid ${roleColor(data.value)}`,
        borderRadius: "5px"
    }),
    multiValueLabel: (baseStyles, { data }) => ({
        ...baseStyles,
        color: roleColor(data.value)
    }),
    multiValueRemove: (baseStyles, { data, isDisabled }) => ({
        ...baseStyles,
        ...( isDisabled ? { "display": "none" } : {} ),
        color: roleColor(data.value),
        ":hover": {
            color: "white",
            background: roleColor(data.value)
        }
    }),
    indicatorsContainer: (baseStyles, { isDisabled }) => ({
        ...baseStyles,
        ...( isDisabled ? { "display": "none" } : {} )
    })
};
const stringToOption = (r:string) => ({ label: r, value: r } as OptionModel);
const optionToString = (r:OptionModel) => r.value;

type UncontrolledMultiSelectProps = {
    name: string;
    label?: string;
    roles: string[];
    startsWith?: string;
    errorMessage?: string;
} & Props<OptionModel, true>;

type ControlledMultiSelectProps = Omit<UncontrolledMultiSelectProps, "value" | "onChange" > & {
    control: Control<any>;
    errors: FieldErrors<any>;
};

//controlled version, works with string[] values
const MultiSelectRoles = ({ name, control, errors, ...rest }: ControlledMultiSelectProps) => {
    const error = extractPathFromObject(errors, name);
    const errorMessage = error?.message;
    return <Controller name={ name } control={ control } defaultValue=""
        render={ ({ field }) => {
            return <UncontrolledMultiSelectRoles name={ field.name }
                value={ field.value.map(stringToOption) }
                onChange={ newValues => field.onChange(newValues.map(optionToString)) }
                errorMessage={ errorMessage }
                { ...rest }
            />;
        } }
    />;
};

export default MultiSelectRoles;

export const UncontrolledMultiSelectRoles = ({
    name, label, roles, startsWith="", menuPlacement="auto", placeholder="Add role", errorMessage, ...rest }: UncontrolledMultiSelectProps) => {

    const containerStyle =()=>(errorMessage ? "form-select-role is-invalid" : "form-select-role");

    return <div>
        { label && <label className="form-label" htmlFor={ name }>{ label }</label> }
        <Select<OptionModel,true> id={ name } isClearable isMulti classNames={ { container: containerStyle } }
            placeholder={ placeholder }
            options={ roles.map(stringToOption) }
            styles={ colourStyles }
            filterOption={ option => option.value.startsWith(startsWith) }
            menuPlacement={ menuPlacement }
            { ...rest }
        />
        { errorMessage &&
            <div className="invalid-feedback"> { errorMessage } </div>
        }
    </div>;
};
