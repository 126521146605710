import { PostTypeModel } from "models/PostTypeModel";
import { ProductSizeModel } from "models/ProductSizeModel";
import { ProductModel } from "models/ProductModel";
import { SelectOption } from "components/form/Select";

export type PostTypeCode = "WR" | "WW" | "LR" | "LW";

export default class ProductConstants {

    static POST_TYPES : Record<PostTypeCode,PostTypeModel> = {
        WR: new PostTypeModel("WR","Wine to Retailer"),
        WW: new PostTypeModel("WW","Wine to Wholesaler"),
        LR: new PostTypeModel("LR","Liquor to Retailer"),
        LW: new PostTypeModel("LW","Liquor to Wholesaler")
    };

    static postTypeByString(v: PostTypeCode): PostTypeModel {
        return ProductConstants.POST_TYPES[v];
    }

    static postTypeDescription(postType: string): string {
        return ProductConstants.postTypeByString(postType as PostTypeCode)?.description;
    }

    static isLiquor(product: ProductModel): boolean {
        return product.post_type === "LR" || product.post_type === "LW";
    }

    static isWine(product: ProductModel): boolean {
        return product.post_type === "WR" || product.post_type === "WW";
    }

    static WHOLESALER_POST_TYPES: PostTypeModel[] = [
        ProductConstants.POST_TYPES.WW,
        ProductConstants.POST_TYPES.LW
    ];

    static POPULAR_PRODUCT_SIZES: ProductSizeModel[] = [
        { volume: "50", unit: "ML" },
        { volume: "100", unit: "ML" },
        { volume: "180", unit: "ML" },
        { volume: "187", unit: "ML" },
        { volume: "200", unit: "ML" },
        { volume: "237", unit: "ML" },
        { volume: "375", unit: "ML" },
        { volume: "500", unit: "ML" },
        { volume: "720", unit: "ML" },
        { volume: "750", unit: "ML" },
        { volume: "1", unit: "L" },
        { volume: "1.5", unit: "L" },
        { volume: "1.75", unit: "L" },
        { volume: "1.8", unit: "L" },
        { volume: "3", unit: "L" },
        { volume: "4", unit: "L" },
        { volume: "5", unit: "L" },
        { volume: "6", unit: "L" },
        { volume: "9", unit: "L" },
        { volume: "12", unit: "L" },
        { volume: "15", unit: "L" },
        { volume: "18", unit: "L" },
        { volume: "30", unit: "L" }
    ];


    static ALCOHOL_CATEGORIES = [
        { "code":"A", "description":"A (Less than 7%)" },
        { "code":"B", "description":"B (7% to 14%)" },
        { "code":"C", "description":"C (over 14% to 21%)" },
        { "code":"D", "description":"D (over 21% to 24%)" },
        { "code":"E", "description":"E (over 24%)" }
    ];

    static alcoholContentString(v: string): string{
        const f = parseFloat(v);
        const cat = ProductConstants.ALCOHOL_CATEGORIES.find(s=>s.code==v);
        return isNaN(f)
            ? (cat===undefined ? v : cat.description)
            : f.toString() ;
    }

    static alcoholOptions(exclude?: string[]){
        return this.ALCOHOL_CATEGORIES
            .filter(a=> exclude === undefined || exclude.indexOf(a.code) == -1)
            .map(a=>({ value:a.code, title:a.description } as SelectOption));
    }

    static is7to24PercentAlcohol(v: any){
        if (v && v.match("^[BCD]$")!==null){
            return true;
        }
        const ac = parseFloat(v);
        return !isNaN(ac) && ac >= 7 && ac <= 24;
    }

    static units: SelectOption[] = [
        { value: "ML", title: "ML" },
        { value: "L", title: "L" },
        { value: "EA", title: "EA" },
        { value: "KEG", title: "KEG" },
        { value: "OZ", title: "OZ" },
        { value: "G", title: "G" }
    ];

    static ALLOCATION_METHODS: SelectOption[] = [
        { value: 1, title: "Past sales history" },
        { value: 2, title: "Published 3rd party sources" },
        { value: 3, title: "Unsold accounts" },
        { value: 4, title: "First come First served" },
        { value: 5, title: "Advance interest" },
        { value: 6, title: "Closeout sales" },
        { value: 7, title: "Distributor assembled combo pack" },
        { value: 8, title: "Other with prior SLA approval" },
        { value: 9, title: "Smallest Qty Discount" },
        { value: 10, title: "Maximum Per Account" }
    ];

    static allocationMethodsDescription(value: string | undefined): string {
        return value ? this.ALLOCATION_METHODS
            .find(a=> a.value == parseInt(value))?.title || value : "";
    }

    static enumLimAvail = { LIMITED_AVAILABILITY: "L", BLANK: "" } as const;

    static STATUS = {
        WORKING: "WORKING",
        CERTIFIED: "CERTIFIED",
        PUBLISHED: "PUBLISHED"
    } as const;
}
