import React, { useEffect, useState } from "react";
import Spinner from "components/Spinner";
import HistoricReportsService from "services/HistoricReportsService";
import { HistoricData } from "models/HistoricData";
import { Link } from "react-router-dom";

export const HistoricReports = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [historicData, setHistoricData] = useState<Array<HistoricData>>([]);

    useEffect(loadData, []);

    function loadData() {
        setIsLoading(true);
        HistoricReportsService.list()
            .then(data => setHistoricData(data))
            .finally(() => setIsLoading(false));
    }

    return <div className="d-flex flex-column">
        <div className="mb-4 pb-md-3">
            <h1 className="text-primary mb-4">Historic Data Reports </h1>
            <p className="mb-0">Access and download monthly data files.</p>
        </div>
        <div className="table-responsive mt-1">
            <Spinner hidden={ !isLoading } className="mt-4 pt-2 text-center"/>
        </div>
        <table className="table table-bordered table-hover">
            <thead>
                <tr>
                    <th>Month</th>
                    <th>File Name</th>
                    <th>Download Link</th>
                    <th>File Size</th>
                    <th>Upload Date</th>
                </tr>
            </thead>
            <tbody>
                { !isLoading && historicData.map(data =>
                    <tr key={ data.month+data.fileName }>
                        <td>{ data.month }</td>
                        <td>{ data.fileName }</td>
                        <td><Link to={ data.downloadLink } target="_blank" download>Download</Link></td>
                        <td>{ data.fileSize }Kb</td>
                        <td>{ data.uploadDate }</td>
                    </tr>
                ) }
            </tbody>
        </table>

    </div>
    ;
};
