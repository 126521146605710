import React from "react";
import Input from "components/form/Input";

const LicenseSearchView = () => {

    return <div className="d-flex flex-column min-vh-100">
        <div className="mb-4 mb-md-5">
            <h1 className="text-primary mb-4">Licensees Search</h1>
            <p className="mb-0">Complete form below and click &quot;Search&ldquo; button.</p>
        </div>

        <div className="card">
            <div className="card-body">
                <form className="">
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <div className="pe-xl-4">
                                <Input name="license_serial_number" placeholder="Enter license serial number" label="License Serial Number" type="text"/>
                                <Input name="premises_trade_name" placeholder="Enter premises or trade name" label="Premises / Trade Name" type="text"/>
                                <Input name="premises_trade_name" placeholder="Enter principal name" label="Principal Name" type="text"/>
                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="ps-xl-4">
                                <Input name="Address" placeholder="Enter address" label="Address" type="text"/>
                                <Input name="City" placeholder="Enter city" label="City" type="text"/>
                                <div className="row">
                                    <div className="col-12 col-lg-7">
                                        <div className="form-group">
                                            <label className="form-label">State</label>
                                            <select id="Select" className="form-select" aria-label="Select">
                                                <option selected>Select</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5">
                                        <Input name="Zip Code" placeholder="Enter zip Code" label="Zip Code" type="number"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center mt-2">
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                            <div className="pe-md-1">
                                <button className="btn btn-outline-secondary w-100">Clear Form</button>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                            <div className="ps-md-1">
                                <input className="btn btn-primary w-100" type="submit" value="Search" />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div className="mt-2">
            <div className="table-responsive mt-4">
                <table className="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Premises Name</th>
                            <th>Principal Name</th>
                            <th>License Number</th>
                            <th>Address</th>
                            <th>County</th>
                            <th>License Class</th>
                            <th>License Type</th>
                            <th>Expiration Date</th>
                            <th>License Status</th>
                            <th>Delinquent Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <a href="/">Some long link with Premises Name</a>
                            </td>
                            <td>Joffre Winery</td>
                            <td>800000001</td>
                            <td>Some long address somewhere</td>
                            <td>Albany</td>
                            <td>303</td>
                            <td>WW</td>
                            <td>09/30/2029</td>
                            <td>
                                <span className="badge bg-success">
                                    <i className="bi-check-circle"></i>Active
                                </span>
                            </td>
                            <td>
                                <span className="badge bg-grey">
                                    <i className="bi-question-circle"></i>N/A
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a href="/">Some Premises Name</a>
                            </td>
                            <td>Not Joffre Winery</td>
                            <td>800000002</td>
                            <td>Some long address somewhere</td>
                            <td>Albany</td>
                            <td>303</td>
                            <td>WW</td>
                            <td>09/30/2040</td>
                            <td>
                                <span className="badge bg-secondary">
                                    <i className="bi-pause-circle"></i>Inactive
                                </span>
                            </td>
                            <td>
                                <span className="badge bg-danger">
                                    <i className="bi-x-circle"></i>Delinquent
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a href="/">Some Premises Name</a>
                            </td>
                            <td>Not Joffre Winery</td>
                            <td>800000002</td>
                            <td>Some long address somewhere</td>
                            <td>Albany</td>
                            <td>303</td>
                            <td>WW</td>
                            <td>09/30/2040</td>
                            <td>
                                <span className="badge bg-info">
                                    <i className="bi-clock-history"></i>Pending
                                </span>
                            </td>
                            <td>
                                <span className="badge bg-grey">
                                    <i className="bi-question-circle"></i>N/A
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a href="/">Some Premises Name</a>
                            </td>
                            <td>Not Joffre Winery</td>
                            <td>800000002</td>
                            <td>Some long address somewhere</td>
                            <td>Albany</td>
                            <td>303</td>
                            <td>WW</td>
                            <td>09/30/2040</td>
                            <td>
                                <span className="badge bg-success">
                                    <i className="bi-hourglass-top"></i>Temp permit
                                </span>
                            </td>
                            <td>
                                <span className="badge bg-danger">
                                    <i className="bi-x-circle"></i>Delinquent
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <a href="/">Some Premises Name</a>
                            </td>
                            <td>Not Joffre Winery</td>
                            <td>800000002</td>
                            <td>Some long address somewhere</td>
                            <td>Albany</td>
                            <td>303</td>
                            <td>WW</td>
                            <td>09/30/2040</td>
                            <td>
                                <span className="badge bg-orange">
                                    <i className="bi-exclamation-circle"></i>Sapa
                                </span>
                            </td>
                            <td>
                                <span className="badge bg-danger">
                                    <i className="bi-x-circle"></i>Delinquent
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>;
};

export default LicenseSearchView;
