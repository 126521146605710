import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import React from "react";
import classNames from "classnames";

interface Props {
    name: string,
    control: any,
    label?: string,
    rules?: any
    errors?: any
    maxDate?: any
}

const DatePickerInput = ({ name, label, errors, maxDate, ...props }: Props) => {

    const feedbackId = name + "Feedback";
    let errorMessage;
    if (errors && errors.hasOwnProperty(name)) {
        errorMessage = errors[name].message;
    }
    const isInvalid = errorMessage || false;
    return <div className="form-group">
        { label && <label htmlFor="Select3" className="form-label d-block">{ label }</label> }
        <Controller
            name={ name }
            render={ ({ field }) => (
                <DatePicker
                    className={  classNames( { "is-invalid": isInvalid }) }
                    onChange={ date => field.onChange(date) }
                    selected={ field.value }
                    dateFormat="MM/dd/yyyy"
                    maxDate={ maxDate }
                />
            ) }
            { ...props }
        />
        <div id={ feedbackId } className="invalid-feedback d-block">
            { errorMessage }
        </div>
    </div>;
};

export default DatePickerInput;
