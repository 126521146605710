import React, { Fragment, KeyboardEvent, useEffect, useState } from "react";
import Input from "components/form/Input";
import SelectCounty from "components/form/select/SelectCounty";
import { SubmitHandler, useForm } from "react-hook-form";
import { SearchLicenseModel } from "models/requests/SearchLicenseModel";
import WholesalerLicenseSearchService, { SearchLicenseRequest } from "services/wholesaler/WholesalerLicenseSearchService";
import { LicenseSearchResponse } from "models/response/LicenseSearchResponse";
import { useNavigate } from "react-router-dom";
import URLUtil from "utils/URLUtil";
import Spinner from "components/Spinner";
import { PageInfo } from "models/PageInfo";
import { WHOLESALER_PATHS } from "pages/wholesaler/WholesalerRoutes";
import Paginator from "components/Paginator";
import { showLicenseDelinquentInfo } from "components/dialogs/LicenseDelinquentInfo";
import LicenseStatusCell from "components/LicenseStatusCell";

type SearchParams = SearchLicenseModel & PageInfo;

const urlParams = URLUtil.readSearchParams() as SearchParams;

const LicenseSearch = () => {
    const navigate = useNavigate();
    const [licenses, setLicenses] = useState<LicenseSearchResponse[]>([]);
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [pageNumber, setPageNumber] = useState<number>(urlParams?.page || 1);
    const [pageCount, setPageCount] = useState<number>(0);
    const [ totalCount, setTotalCount ] = useState<number>(0);

    const {
        handleSubmit,
        setValue,
        getValues,
        control,
        reset
    } = useForm<SearchLicenseModel>({
        mode: "onSubmit"
    });

    const onSubmit: SubmitHandler<SearchLicenseModel> = async data => {
        const searchParams = data as SearchParams;
        setPageNumber(1);
        searchParams.page = 1;
        URLUtil.updateUrlParams(WHOLESALER_PATHS.LICENSE.SEARCH, searchParams, navigate);
        performQuery(searchParams);
    };

    function performQuery(data: SearchParams) {
        setIsDataLoading(true);

        const request = {
            pageInfo: { page: data.page },
            filters: URLUtil.pureUrlParameters(data)
        } as SearchLicenseRequest;

        WholesalerLicenseSearchService.searchLicense(request).then(response => {
            setIsDataLoading(false);
            setLicenses(response.data);
            setPageCount(response.pageInfo.pagesCount || 1);
            setTotalCount(response.pageInfo.totalItemsCount || 0);
        });
    }

    useEffect(() => {
        URLUtil.setValuesFromUrl(urlParams, setValue);
        performQuery(urlParams || {} as SearchLicenseModel);
    }, []);

    function handlePageClick(pageNumber: number) {
        setPageNumber(pageNumber);
        const searchParams = getValues() as SearchParams;
        searchParams.page = pageNumber;

        URLUtil.updateUrlParams(WHOLESALER_PATHS.LICENSE.SEARCH, searchParams, navigate);
        performQuery(searchParams);
    }

    function handleSearch(event: KeyboardEvent) {
        if (event.key === "Enter") {
            event.preventDefault();
            document.getElementById("searchLicensesForm")?.dispatchEvent(
                new Event("submit", { bubbles: true, cancelable: true })
            );
        }
    }

    return <div className="d-flex flex-column min-vh-100">
        <div className="mb-4 mb-md-5">
            <h1 className="text-primary mb-4">Licensees Search</h1>
            <p className="mb-0">Complete form below and click &quot;Search&ldquo; button.</p>
        </div>

        <div className="card">
            <div className="card-body">
                <form id="searchLicensesForm" className="" onSubmit={ handleSubmit(onSubmit) }>
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <div className="pe-xl-4">
                                <Input
                                    control={ control }
                                    name="license"
                                    placeholder="Enter license id or serial number"
                                    label="License ID"
                                    type="text"
                                    onKeyDown={ handleSearch }
                                />
                                <Input
                                    control={ control }
                                    name="premise_name"
                                    placeholder="Enter premise or trade name"
                                    label="Premise or Trade Name"
                                    type="text"
                                    onKeyDown={ handleSearch }
                                />
                                <Input
                                    control={ control }
                                    name="principal_name"
                                    placeholder="Enter principal name"
                                    label="Principal Name"
                                    type="text"
                                    onKeyDown={ handleSearch }
                                />
                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="ps-xl-4">
                                <Input
                                    control={ control }
                                    name="premise_address"
                                    placeholder="Enter address"
                                    label="Address"
                                    type="text"
                                    onKeyDown={ handleSearch }
                                />
                                <Input
                                    control={ control }
                                    name="premise_city"
                                    placeholder="Enter city"
                                    label="City"
                                    type="text"
                                    onKeyDown={ handleSearch }
                                />
                                <div className="row">
                                    <div className="col-12 col-lg-7">
                                        <div className="form-group">
                                            <SelectCounty
                                                control={ control }
                                                name="county"
                                                label="County"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-5">
                                        <Input
                                            control={ control }
                                            name="zip_code"
                                            placeholder="Enter zip code"
                                            label="Zip Code"
                                            type="number"
                                            onKeyDown={ handleSearch }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center mt-2">
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                            <div className="pe-md-1">
                                <button className="btn btn-outline-secondary w-100" onClick={ () => reset() }>
                                    Clear Form
                                </button>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                            <div className="ps-md-1">
                                <input className="btn btn-primary w-100" type="submit" value="Search"/>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <Spinner className="mt-4 pt-2 text-center" hidden={ !isDataLoading } />

        <div className="mt-3 mb-2 d-flex flex-column flex-lg-row align-items-center">
            <div className="me-lg-auto pt-3">
                <h5 className="mb-0">Found <strong>{ totalCount } records</strong> matching search criteria</h5>
            </div>
        </div>

        <div className="mt-2" hidden={ licenses.length === 0 }>
            <div className="table-responsive">
                <table className="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>License ID</th>
                            <th>Serial Number</th>
                            <th>Premises Name</th>
                            <th>Principal Name</th>
                            <th>Addresses</th>
                            <th>County</th>
                            <th>City</th>
                            <th>Zip code</th>
                            <th>Expiration Date</th>
                            <th>License Status</th>
                            <th>COD Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        { licenses.map((lic, idx) => {
                            return <tr key={ idx }>
                                <td>
                                    <button className="btn btn-link"
                                        onClick={ () => showLicenseDelinquentInfo(lic.id, true) }>
                                        { lic.permit_id }
                                    </button>
                                </td>
                                <td>
                                    <button className="btn btn-link"
                                        onClick={ () => showLicenseDelinquentInfo(lic.id, true) }>
                                        { lic.serial_number }
                                    </button>
                                </td>
                                <td>{ lic.premise_name }</td>
                                <td>{ lic.principal_name &&
                                    lic.principal_name.map(n => (<Fragment key={ n }>{ n }<br/></Fragment>)) }
                                </td>
                                <td>{ lic.premise_address } { lic.premise_address2 && <p>{ lic.premise_address2 }</p> }</td>
                                <td>{ lic.county }</td>
                                <td>{ lic.premise_city }</td>
                                <td>{ lic.zip_code }</td>
                                <td>{ lic.expiration_date }</td>
                                <td>
                                    <LicenseStatusCell statusCode={ lic.license_status }/>
                                </td>
                                <td>
                                    <span className={ lic.is_delinquent ? "badge bg-danger" : "badge bg-grey" }>
                                        <i className={ lic.is_delinquent ? "bi-exclamation-circle" : "bi-check-circle" }></i>
                                        { lic.is_delinquent ? "Delinquent" : "Cleared" }
                                    </span>
                                </td>
                            </tr>;
                        }) }
                    </tbody>
                </table>
            </div>
            <div>
                <Paginator totalPages={ pageCount } currentPage={ pageNumber } pageClicked={ handlePageClick }/>
            </div>
        </div>
    </div>;

};

export default LicenseSearch;

