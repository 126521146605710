import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { useToastStore } from "store/ToastStoreProvider";
import userPublicService from "services/user/UserPublicService";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";

const MESSAGE_HEADER = "Resend confirmation email";

export const ResendConfirmation = ({ email, onComplete }:{ email: string, onComplete:() => void }) => {
    const { addToast } = useToastStore();

    const resend = ()=>{
        email && userPublicService.resendConfirmationEmail(email)
            .then(()=>{
                addToast({
                    header: MESSAGE_HEADER,
                    message:"A confirmation email has been sent to your inbox",
                    variant: "success",
                    timeoutMs: DEFAULT_TOASTER_TIMEOUT
                });
                onComplete();
            })
            .catch(()=>{
                addToast({
                    header: MESSAGE_HEADER,
                    message:"Something went wrong. Please try again",
                    variant: "danger",
                    timeoutMs: DEFAULT_TOASTER_TIMEOUT
                });
            });
    };

    return (
        <Card className={ "mx-auto" } style={ { width: "50%", textAlign: "center" } } >
            <Card.Body>
                <Card.Title>Registration incomplete.</Card.Title>
                <Card.Text>
                    Please note that you did not complete the registration.<br/>
                    Please, click this button to resend a confirmation email.
                </Card.Text>
                <Button variant="primary" onClick={ resend }>Resend Confirmation Email</Button>
            </Card.Body>
        </Card>
    );
};
