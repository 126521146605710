import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AlertService from "services/AlertService";
import { AlertModel } from "models/AlertModel";
import { useToastStore } from "store/ToastStoreProvider";
import { useForm } from "react-hook-form";
import { SelectControlled, SelectOption } from "components/form/Select";
import TextArea from "components/form/TextArea";
import { ADMIN_PATHS } from "pages/admin/AdminRoutes";
import { Alert } from "components/Alert";
import Spinner from "components/Spinner";
import { useKeyDownEnterEvent } from "components/KeyDownHook";
import CheckBox from "components/form/CheckBox";

export const visibilityOptions: SelectOption[] = [
    { value: "AUTH", title: "Public Pages" },
    { value: "RETAILER", title: "Retailer Pages" },
    { value: "WHOLESALER", title: "Wholesaler Pages" }
];

export const messageTypeOptions: SelectOption[] = [
    { value: "NOTIFICATION", title: "Notification" },
    { value: "WARNING", title: "Warning" },
    { value: "ALERT", title: "Alert" }
];


export const alignOptions: SelectOption[] = [
    { value: "LEFT", title: "Left" },
    { value: "CENTER", title: "Center" }
];

export const EditAlerts = () => {

    const { id } = useParams();
    const { addDefaultDangerToast } = useToastStore();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const {
        handleSubmit,
        reset,
        control,
        watch,
        formState: { isDirty }
    } = useForm<AlertModel>({
        mode: "onSubmit",
        defaultValues: {
            id: undefined,
            visibility: "AUTH",
            message_type: "NOTIFICATION",
            align_type: "LEFT",
            message: ""
        }
    });
    useKeyDownEnterEvent(() => !isLoading && handleSubmit(onSubmit)());

    useEffect(() => {
        if (id) {
            setIsLoading(true);
            AlertService.get(id)
                .then(reset)
                .catch(() => addDefaultDangerToast("Edit alert."))
                .finally(() => setIsLoading(false));
        }
    }, [id]);

    const onSubmit = async (data: AlertModel) => {
        setIsLoading(true);
        AlertService.update(data)
            .then(() => { navigate(ADMIN_PATHS.ALERTS); })
            .catch(() => addDefaultDangerToast("Update alert."))
            .finally(() => setIsLoading(false));
    };

    const visibility = watch("visibility");
    const messageType = watch("message_type");
    const alignType = watch("align_type");
    const message = watch("message");

    return <div className="d-flex flex-column">
        <div className="mb-4 pb-md-3">
            <h1 className="text-primary mb-0">Create Alert</h1>
        </div>
        <div>
            <Alert alert={ { visibility: visibility, message_type: messageType, align_type: alignType, message: message } as AlertModel } hideAlert={ () => {} }/>
            <form onSubmit={ handleSubmit(onSubmit) }>
                <div className="card">
                    <div className="card-body pb-3">
                        <div className="row">
                            <div className="col-12 col-md-6 col-xl-4">
                                <SelectControlled
                                    control={ control } name="message_type"
                                    options={ messageTypeOptions }
                                    label="Message Type" required
                                    disabled={ isLoading }/>
                            </div>
                            <div className="col-12 col-md-6 col-xl-4">
                                <SelectControlled
                                    control={ control } name="visibility"
                                    options={ visibilityOptions }
                                    label="Display Module" required
                                    disabled={ isLoading }/>
                            </div>
                            <div className="col-12 col-md-6 col-xl-4">
                                <SelectControlled
                                    control={ control } name="align_type"
                                    options={ alignOptions }
                                    label="Alignment Type" required
                                    disabled={ isLoading }/>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6">
                                <CheckBox control={ control } name="active" label="Active" type="checkbox"/>
                            </div>
                            <div className="col-12">
                                <TextArea
                                    control={ control } name="message" rows={ 4 } placeholder="Message Text"
                                    label="Message Text" disabled={ isLoading }/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center mt-4 pt-3">
                    <Spinner className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1" hidden={ !isLoading } />
                    <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                        <input type="button" className="btn btn-outline-secondary w-100" onClick={ () => navigate(ADMIN_PATHS.ALERTS) } value="Cancel"/>
                    </div>
                    <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                        <input type="submit" className="btn btn-primary w-100" value="Save" disabled={ isLoading || !isDirty }/>
                    </div>
                </div>
            </form>
        </div>
    </div>;
};

