import { DiscountValues } from "models/DiscountValues";
import { LicenseModel } from "models/license.model";
import { BeverageType } from "models/BeverageType";
import { Combo } from "models/Combo";
import { HasErrorsModel } from "models/HasErrorsModel";

export interface ProductModel extends HasErrorsModel {
    id: number;
    alcohol: string;
    alloc_desc: string;
    alloc_met: string;
    bev_type: string;
    bot_nyc: number;
    bot_price: number;
    botpercase: number;
    brand_name: string;
    brand_reg: string;
    case_nyc: number;
    case_price: number;
    combo_asse: string;
    combo_disa: string;
    disc_code?: string;
    discount_id?: number;
    distrib_id?: string[];
    fob: string;
    fullcase: string;
    item_size: number;
    label_type: string;
    license_id?: number;
    license_serial_number?: number; // delete this, there is a license_id here?
    lim_avail: string;
    nys_item: string;
    nys_prod: string;
    nys_whole: string;
    post_month: number;
    post_type: string;
    post_year: number;
    prim_info: string;
    prod_item: string;
    prod_name: string;
    proof: string;
    reg_combo: string;
    split_charge: number;
    subpack: number;
    ttb_id: string;
    um: string;
    vintage: string;
    discount_values?: DiscountValues[];
    discounts_json?: any;
    combos?: Combo[];
    license: LicenseModel;
    beverage_type: BeverageType;
    product_errors?: Record<string, string>;
    replaced_values?: Record<string, string>;
    discount_values_errors?: Record<string, Record<string, string>>;
    combo_errors?: Record<string, Record<string, string>>;
    availability: string;
}

export const DISCOUNT_VALUES_FIELD = "discount_values";
