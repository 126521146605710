import React from "react";
import WholesalerDelinquentService from "services/wholesaler/WholesalerDelinquentService";
import { modalStore } from "store/ModalStoreProvider";

interface Props {
    id: number,
    onRemove: any,
    onError: (data: any)=>void
}

export const showRemoveDelinquencyDialog = (id: number, onRemove: any, onError: any) => {
    modalStore.setBody(<RemoveDelinquencyDialog id={ id } onRemove={ onRemove } onError={ onError } />);
    modalStore.setHeader("Remove Delinquent Records");
    modalStore.showModal();
};

const RemoveDelinquencyDialog = ({ id, onRemove, onError }: Props) => {

    function deleteRecord() {
        modalStore.hideModal();
        WholesalerDelinquentService.deleteDelinquent(id)
            .then(() => onRemove())
            .catch((error => onError(error.data)));
    }

    modalStore.setAdditionalButtons([
        <button key={ 0 } type="button" className="btn btn-primary" onClick={ () => deleteRecord() }>Yes</button>
    ]);

    return <p className="mb-0">You are going to delete 1 delinquent records. Are you sure?</p>;
};
