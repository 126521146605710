import React from "react";

interface Props {
    hidden?: boolean;
    className?: string;
}

const Spinner = ({ hidden, className } : Props) => {
    if (hidden) {
        return null;
    }
    return <div className={ className } >
        <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    </div>;
};

export default Spinner;
