import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ErrorOption } from "react-hook-form/dist/types/errors";
import { ForgotPasswordModel } from "models/ForgotPasswordModel";
import { MessageModel } from "models/message.model";
import Input from "components/form/Input";
import { useToastStore } from "store/ToastStoreProvider";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";
import { AUTH_PATHS } from "pages/auth/AuthRoutes";
import { useNavigate } from "react-router-dom";
import UserPublicService from "services/user/UserPublicService";
import { useKeyDownEnterEvent } from "components/KeyDownHook";

const ResetPassword = () => {
    const navigate = useNavigate();
    const {
        handleSubmit,
        control,
        setError,
        formState: { errors, isSubmitting }
    } = useForm<ForgotPasswordModel>({
        mode: "onSubmit"
    });

    useKeyDownEnterEvent(() => !isLoading && handleSubmit(onSubmit)());

    const { addToast } = useToastStore();
    const [formError, setFormError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onSubmit: SubmitHandler<ForgotPasswordModel> = data => {
        setIsLoading(true);
        UserPublicService.resetPassword(data)
            .then(() => {
                addToast({
                    message:"A password reset email has been sent to your inbox",
                    header: "Reset password",
                    variant: "success",
                    timeoutMs: DEFAULT_TOASTER_TIMEOUT
                });
                navigate(AUTH_PATHS.LOGIN);
            })
            .catch(response =>{
                const msg = response.data as MessageModel;
                setFormError(msg.message);
                if (msg.errors){
                    msg.errors.forEach(er=> setError(er.field as keyof ForgotPasswordModel, er as ErrorOption) );
                }
            }).finally(() => setIsLoading(false));
    };

    return (
        <form className="registration-block" onSubmit={ handleSubmit(onSubmit) }>
            <div className="mb-4 mb-md-5 text-center">
                <h1 className="text-primary mb-4">Forgot my password</h1>
                <p>In order to get your password, you must complete the following form</p>
            </div>
            <div className="card login-block">
                <div className="card-body">
                    <Input control={ control } errors={ errors } name="email"
                        label="Email *"
                        placeholder="Enter email"
                        type="text"
                    />

                    { formError &&
                        <div className="invalid-message alert alert-sm alert-danger">
                            <ul>
                                <li>{ formError }</li>
                            </ul>
                        </div>
                    }

                    <div className="row justify-content-center mt-3">
                        <input className="col-12 col-md-6 col-xl-5 btn btn-primary mt-3"
                            type="submit" value="Get password" disabled={ isSubmitting }/>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default ResetPassword;
