import React  from "react";
import { Control } from "react-hook-form/dist/types/form";
import classNames from "classnames";
import { Controller } from "react-hook-form";
import { PatternFormat } from "react-number-format";
import { InputAttributes } from "react-number-format/types/types";

type Props = {
    name: string,
    label?: string,
    control: Control<any>,
    errors?: any
} & InputAttributes;

const PhoneNumberInput = ({ name, label, control, errors, required }: Props) => {

    let errorMessage;
    if (errors && errors.hasOwnProperty(name)) {
        errorMessage = errors[name].message;
    }

    return <div className="form-group">
        <label className="form-label" htmlFor={ name }> { label } </label>
        <Controller
            control={ control }
            name={ name }
            rules={ {
                required:{ value: required || false, message: "Phone is required" }
            } }
            render={ ({ field: { value, onChange }, fieldState:{ invalid } }) => (
                <PatternFormat
                    format="(###) ###-####" mask="_"
                    placeholder="(___) ___-____"
                    className={ classNames("form-control", { "is-invalid": invalid }) }
                    defaultValue={ value }
                    onValueChange={ ({ value })=> onChange(value) }
                    value={ value }
                />
            ) }
        />
        <div id={ name+"Feedback" }  className="invalid-feedback">
            { errorMessage }
        </div>
    </div>;
};

export default PhoneNumberInput;
