import Input from "components/form/Input";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { BrandsFormModel } from "pages/wholesaler/priceposting/models/BrandsFormModel";
import { SelectBeverageType } from "components/form/select/SelectBeverageType";
import { SelectPackageType } from "components/form/select/SelectPackageType";
import { SelectValid } from "components/form/select/SelectValid";
import URLUtil from "utils/URLUtil";
import { useKeyDownEnterEvent } from "components/KeyDownHook";

interface Props {
    postType: string;
    filters: BrandsFormModel;
    onSubmit: any;
}

export const BrandsSearchForm = ({ postType, filters, onSubmit }: Props) => {

    const {
        handleSubmit,
        reset,
        control,
        setValue
    } = useForm<BrandsFormModel>({
        mode: "onSubmit",
        defaultValues: {}
    });
    useKeyDownEnterEvent(() => { handleSubmit(onSubmit)(); });

    useEffect(() => { URLUtil.setValuesFromUrl(filters, setValue); }, [filters]);

    return <form onSubmit={ handleSubmit(onSubmit) }>
        <div className="row">
            <div className="col-12 col-xl-6">
                <div className="pe-xl-4">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <Input name="brand_name" control={ control } placeholder="Enter brand name" label="Brand name" type="text" />
                        </div>
                        <div className="col-12 col-lg-6">
                            <Input name="prod_name" control={ control } placeholder="Enter item description" label="Item description" type="text" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-xl-6">
                <div className="ps-xl-4">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <Input name="prod_item" control={ control } placeholder="Enter item number" label= { (postType === "LR" || postType === "WR") ? "Item number" : "NYS supplier item number" } type="text" />
                        </div>
                        <div className="col-12 col-lg-6">
                            <Input name="brand_reg" control={ control } placeholder="Enter approval or BLR number" label="Approval or BLR number" type="text" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-xl-6">
                <div className="pe-xl-4">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <Input name="ttb_id" control={ control } placeholder="Enter TTB / CoLA ID number" label="TTB / CoLA ID number" type="text"/>
                        </div>
                        <div className="col-12 col-lg-6">
                            <SelectBeverageType name="beverage_type" control={ control } type={ postType }/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-xl-6">
                <div className="ps-xl-4">
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <SelectPackageType name="reg_combo" control={ control } label="Package Type"/>
                        </div>
                        <div className="col-12 col-lg-6">
                            <SelectValid name="show" control={ control } label="Validity Status"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row justify-content-center mt-2">
            <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                <div className="pe-md-1">
                    <input type="button" className="btn btn-outline-secondary w-100" onClick={ () => reset() } value="Clear Form"/>
                </div>
            </div>
            <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                <div className="ps-md-1">
                    <input type="submit" className="btn btn-primary w-100" value="Filter"/>
                </div>
            </div>
        </div>
    </form>;
};
