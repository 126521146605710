import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import nyGovLogo from "assets/img/nygov-logo.png";
import { observer } from "mobx-react";
import { useAuthStore } from "store/AuthStoreProvider";
import { USER_PATHS } from "pages/user/UserRoutes";
import { useShowHideStore } from "store/ShowHideStoreProvider";
import { LoggedInUserInfo, licenseClasses, licenseText } from "models/LoggedInUserInfo";
import authService from "services/AuthService";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";
import { useModalStore } from "store/ModalStoreProvider";
import { useToastStore } from "store/ToastStoreProvider";
import { redirectToHomePage } from "services/RedirectService";
import { useAlertStore } from "store/AlertStoreProvider";
import classNames from "classnames";

const Header = () => {
    const { isAuthenticated, logout } = useAuthStore();
    const { isAllHidden } = useShowHideStore();

    return (
        <header className="border-bottom" hidden={ isAllHidden }>
            <div className="container-fluid">
                <div className="navbar navbar-expand-lg d-flex flex-wrap align-items-center justify-content-start navbar-expand-long">
                    <Link to="/" className="navbar-brand pe-lg-5 me-lg-4">
                        <img src={ nyGovLogo } alt="New York State Home"/>
                    </Link>
                    <button
                        className="navbar-toggler collapsed ms-auto me-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <nav className="collapse navbar-collapse justify-content-between" id="navbarSupportedContent">
                        <ul className="nav col-12 col-lg-auto justify-content-center">
                            <li><a className="nav-link" href="https://www.ny.gov/services" title="Services">Services</a></li>
                            <li><a className="nav-link" href="https://www.governor.ny.gov/news" title="">News</a></li>
                            <li><a className="nav-link" href="https://www.ny.gov/agencies">Government</a></li>
                            { isAuthenticated &&
                                <li>
                                    <Link className="nav-link d-lg-none" to={ USER_PATHS.PROFILE }>
                                        Edit Profile
                                    </Link>
                                </li>
                            }
                            { isAuthenticated &&
                                <li>
                                    <Link to="/" onClick={ logout } className="nav-link d-lg-none text-orange">Logout</Link>
                                </li>
                            }
                        </ul>

                        <div className="ms-lg-auto me-lg-3 mt-3 mt-lg-0">
                            { isAuthenticated && <LicenseDropdown/> }
                        </div>
                        { isAuthenticated &&
                            <div className="d-flex flex-row align-items-center w-lg-100 px-3 px-lg-0">
                                <div className="navbar-account dropdown d-none d-lg-block">
                                    <a href="/" className="dropdown-toggle" id="nav-account" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static">
                                        <span className="bi bi-person"></span>
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="nav-account">
                                        <li>
                                            <Link className="dropdown-item" to={ USER_PATHS.PROFILE }>
                                                Edit Profile
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="dropdown-item" to={ USER_PATHS.MY_LICENSES }>
                                                My Licenses
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/" onClick={ logout } className="dropdown-item text-orange">Logout</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        }
                    </nav>
                </div>
            </div>
        </header>
    );
};

export default observer(Header);

const LicenseDropdown = () => {
    const { licenses, currentLicense } = useAuthStore();
    const location = useLocation();
    const navigate = useNavigate();
    const modalStore = useModalStore();
    const authStore = useAuthStore();
    const { addToast, addDefaultDangerToast } = useToastStore();
    const { updateAlerts } = useAlertStore();

    const reauthorize = (new_license_id: number) => ()=>{
        if (authService.isDataModifyingPage(location)){
            switchConfirmation(new_license_id);
        }
        else {
            doReauthorize(new_license_id);
        }
    };

    const switchConfirmation = (new_license_id: number) => {
        modalStore.setHeader("Warning");
        modalStore.setBody(<p className="mb-0">Changes you&apos;ve made may not be saved. Are you sure you want to leave this page?</p>);
        modalStore.setAdditionalButtons([
            <button key={ 0 } type="button" className="btn btn-primary" onClick={ ()=> doReauthorize(new_license_id) }>Yes, I&apos;m sure</button>
        ]);
        modalStore.showModal();
    };

    const doReauthorize = (new_license_id: number) => {
        authService.reauthorize(new_license_id)
            .then((resp: LoggedInUserInfo) => {
                authStore.setAuthorizedSilently(resp);
                updateAlerts();
                redirectToHomePage(authStore.permissionStore, navigate);
                navigate(0);
            })
            .catch((error) => {
                switch (error.status){
                case 409://LICENSE_NOT_SELECTED
                    addToast({
                        message: "Unable to authorize into selected license",
                        header: "Reauthorize",
                        variant: "danger",
                        timeoutMs: DEFAULT_TOASTER_TIMEOUT
                    });
                    break;
                case 406://NO_ACTIVE_LICENSES
                default:
                    addDefaultDangerToast("Reauthorize");
                }
            })
            .finally(() => modalStore.hideModal());
    };

    if (!licenses){
        return <></>;
    }
    else if (licenses.length === 1){
        return <div className="btn-group">
            <span className="btn btn-outline-secondary cursor-default">Current License:&nbsp;
                <b className={ classNames(licenseClasses(currentLicense), { "text-primary": currentLicense?.active }) }>
                    { licenseText(currentLicense) }
                </b>
            </span>
        </div>;
    }
    else {
        return <div className="btn-group">
            { currentLicense &&
                <button type="button" className="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown"
                    aria-expanded="false">Current License:&nbsp;
                    <b className={ classNames(licenseClasses(currentLicense), { "text-primary": currentLicense?.active }) }>
                        { licenseText(currentLicense) }
                    </b>
                </button>
            }

            <ul className="dropdown-menu">
                { licenses.filter(lic=>lic.licenseId!=currentLicense?.licenseId).map(lic=>(
                    <li key={ lic.licenseId }>
                        <button className={ classNames("dropdown-item", licenseClasses(lic)) } onClick={ reauthorize(lic.licenseId) }>
                            { licenseText(lic) }
                        </button>
                    </li>
                )) }
            </ul>
        </div>;
    }
};
