import httpClient from "api/HttpClient";
import BACKEND from "services/Constants";
import { PageableResponse } from "models/response/PageableResponse";
import { PageableRequest } from "models/requests/PageableRequest";
import { PrincipalModel } from "models/PrincipalModel";

export default {
    lookup(request: PageableRequest): Promise<PageableResponse<PrincipalModel>> {
        return httpClient.search(BACKEND + "/principal/lookup", request);
    },

    create(principal: PrincipalModel): Promise<void> {
        return httpClient.post(BACKEND + "/principal/create", principal);
    },

    delete(id: number): Promise<void> {
        return httpClient.delete(BACKEND + "/principal/delete/" + id);
    },
    update(data: PrincipalModel): Promise<void> {
        return httpClient.post(BACKEND + "/principal/update", data);
    }
};
