import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { BatchOperationResult } from "models/BatchProductsOperationResult";
import { PageableRequest } from "models/requests/PageableRequest";
import { useToastStore } from "store/ToastStoreProvider";
import BatchProductImportService from "services/BatchProductImportService";
import Paginator from "components/Paginator";
import ErroneousValue from "components/ErroneousValue";
import { unitString } from "services/DiscountConstants";
import { WHOLESALER_PATHS } from "pages/wholesaler/WholesalerRoutes";
import { DiscountValues } from "models/DiscountValues";
import { Discount } from "models/Discount";
import {
    Correctness,
    EntityWithPaging,
    emptyEntityWithPaging
} from "pages/wholesaler/priceposting/pages/batch/components/ProductList";
import { SampleFormatsButton } from "components/SampleFormatsButton";
import { SampleXlsButton } from "components/SampleXlsButton";
import { SampleTxtButton } from "components/SampleTxtButton";
import classNames from "classnames";
import { ImportSubject } from "models/ImportDiscriminator";
import WizardStepsBar from "components/WizardStepsBar";

const BatchDiscountResults = () => {

    const location = useLocation();
    const { addToast } = useToastStore();

    const [operationResult] = useState<BatchOperationResult>(location.state as BatchOperationResult);
    const [ correctDiscounts, setCorrectDiscounts] = useState<EntityWithPaging<Discount>>(emptyEntityWithPaging());
    const [ invalidDiscounts, setInvalidDiscounts] = useState<EntityWithPaging<Discount>>(emptyEntityWithPaging());

    useEffect(()=>{
        if (!operationResult.success){
            addToast({
                header: "Price Posting",
                message: operationResult.message || "Something went wrong. Please try again",
                variant: "danger"
            });
        }
    },[]);

    const tabBadge = (entity: EntityWithPaging<any>, zeroSuccess: boolean)=>{
        const isZero = entity.totalCount == 0;
        const classes= classNames("badge","rounded-pill","ms-1",
            {
                "bg-info" : entity.loading,
                "bg-danger":  !entity.loading && ( zeroSuccess != isZero ),
                "bg-success": !entity.loading && ( zeroSuccess == isZero )
            }
        );

        return <span className={ classes }>
            { entity.loading ? "loading" : entity.totalCount }
        </span>;
    };

    return <div className="d-flex flex-column">
        <div className="mb-3 mb-md-3">
            <h1 className="text-primary mb-2">
                Discount Codes Batch Import
            </h1>
        </div>
        <div className="mb-3">
            <p className="mb-0">Review results of the submission process. You can edit records later in an interactive mode.</p>
        </div>
        <div className="mb-4">
            <SampleFormatsButton subject={ ImportSubject.DISCOUNTS }/>
            <SampleXlsButton subject={ ImportSubject.DISCOUNTS }/>
            <SampleTxtButton subject={ ImportSubject.DISCOUNTS }/>
        </div>
        <WizardStepsBar steps={ ["Upload Discount Codes file","Preview results"] } activity={ [false,true] }/>
        <div className="card mt-4">
            <div className="card-body">
                <div className="mb-3">
                    <h4><strong>Step 2:</strong> Preview batch submission</h4>
                </div>
                <div className="">
                    <div className="nav nav-pills btn-group flex-column flex-lg-row d-lg-inline-flex mb-0" id="nav-tab" role="tablist" aria-orientation="vertical">
                        <button className="btn btn-outline-secondary min-wp-230 active" id="nav-1-tab" data-bs-toggle="tab"
                            data-bs-target="#nav-1" type="button" role="tab" aria-controls="nav-1" aria-selected="true">
                            Correct Discounts
                            { tabBadge(correctDiscounts, false) }
                        </button>
                        <button className="btn btn-outline-secondary min-wp-230" id="nav-2-tab" data-bs-toggle="tab"
                            data-bs-target="#nav-2" type="button" role="tab" aria-controls="nav-2" aria-selected="false">
                            Incorrect Discounts
                            { tabBadge(invalidDiscounts, true) }
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div className="tab-content mt-4" id="nav-tabContent">
            <div className="tab-pane fade show active" id="nav-1" role="tabpanel" aria-labelledby="nav-1-tab">
                <DiscountsList discounts={ correctDiscounts } setDiscounts={ setCorrectDiscounts } correct={ Correctness.ONLY_CORRECT }
                    createdAtStart={ operationResult?.startMillis } createdAtStop={ operationResult?.stopMillis }/>
            </div>
            <div className="tab-pane fade" id="nav-2" role="tabpanel" aria-labelledby="nav-2-tab">
                <DiscountsList discounts={ invalidDiscounts } setDiscounts={ setInvalidDiscounts } correct={ Correctness.ONLY_INVALID }
                    createdAtStart={ operationResult?.startMillis } createdAtStop={ operationResult?.stopMillis }/>
            </div>
        </div>

        <div className="row justify-content-center">
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                <a href={ WHOLESALER_PATHS.DISCOUNTS.BATCH_IMPORT }
                    className="btn btn-outline-secondary w-100">
                    Back to File selection
                </a>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mt-3 mt-sm-1">
                <a href={ WHOLESALER_PATHS.DISCOUNTS.MAIN } className="btn btn-primary w-100">
                    Complete Submission
                </a>
            </div>
        </div>
    </div>;
};

export default BatchDiscountResults;


type DiscountsListParams = {
    discounts: EntityWithPaging<Discount>,
    setDiscounts: React.Dispatch<React.SetStateAction<EntityWithPaging<Discount>>>,
    correct: Correctness,
    createdAtStart: number,
    createdAtStop: number
};

const DiscountsList = ({ discounts, setDiscounts, correct, createdAtStart, createdAtStop }:DiscountsListParams) => {

    useEffect(()=>discountResultsQuery(1),[]);

    function discountResultsQuery(newPageNumber: number) {
        setDiscounts(old =>({ ...old,loading:true }));

        const request = {
            pageInfo: { page: newPageNumber || 1, limit: 10 },
            filters:{
                correct: correct,
                createdAtStart: createdAtStart,
                createdAtStop: createdAtStop
            }
        } as PageableRequest;

        BatchProductImportService.discountResults(request)
            .then(response => setDiscounts(old=>({
                ...old,
                loading: false,
                data: response.data,
                pageNumber: response.pageInfo.page,
                totalCount: response.pageInfo.totalItemsCount || 0,
                pagesCount: response.pageInfo.pagesCount || 1
            })));
    }

    return <div>
        <div className="pt-3 d-flex flex-column flex-lg-row align-items-center justify-content-between">
            <h5 className="mb-3"><strong>Total records:</strong> { discounts.totalCount }</h5>
            <Link to={ BatchProductImportService.downloadDiscountsResultsFileLink(createdAtStart, createdAtStop, correct) } className="mb-3" target="_blank" download>Download</Link>
        </div>
        <div className="mb-4">
            <div className="table-responsive">
                <table className="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Discount code</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        { discounts.data && discounts.data.map((d, idx) => {
                            const d1: Discount = { ...d, errors: d.discount_errors };
                            return <tr key={ idx }>
                                <td><ErroneousValue item={ d1 } name={ "discount_code" }/></td>
                                <td>
                                    { (d.values || []).map((dv, idx) => {
                                        const dvItem: DiscountValues = {
                                            ...dv,
                                            errors: d.discount_values_errors && d.discount_values_errors[idx.toString()]
                                        };
                                        return <p key={ idx } className={ "mb-0" }>
                                            { idx + 1 }.&nbsp;
                                            <ErroneousValue item={ dvItem } name={ "type" }/>&nbsp;
                                            <ErroneousValue item={ dvItem } name={ "amount" }/>&nbsp;on&nbsp;
                                            <ErroneousValue item={ dvItem } name={ "quantity" }/>&nbsp;
                                            <ErroneousValue item={ dvItem } formattedValue={ unitString(dv.unit) }
                                                name={ "unit" }/>&nbsp;
                                        </p>;
                                    }
                                    ) }
                                </td>
                            </tr>;
                        }) }
                    </tbody>
                </table>
            </div>
        </div>
        <Paginator totalPages={ discounts.pagesCount } currentPage={ discounts.pageNumber } pageClicked={ (n:number)=>discountResultsQuery(n) }/>
    </div>;
};


