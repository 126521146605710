import React from "react";

const DelinquentBatchView = () => {

    return <div className="d-flex flex-column min-vh-100">
        <div className="mb-3">
            <h1 className="text-primary mb-4">Delinquent Records - Batch Submission</h1>
            <p className="mb-0">Select CSV file with delinquency record and click &ldquo;Upload&rdquo; button.</p>
        </div>
        <div className="mb-4">
            <a className="btn btn-outline-primary me-3 mb-3" href="/">Read more about Batch Submission</a>
            <a className="btn btn-outline-primary mb-3" href="/">CSV Template</a>
        </div>
        <div className="steps-wr mb-4">
            <div className="steps-l row g-0">
                <div className="steps-li col-12 col-xl-3 active">
                    <div className="steps-li-in">
                        <strong>1</strong> Select CSV file to upload
                    </div>
                </div>
                <div className="steps-li col-12 col-xl-3">
                    <div className="steps-li-in">
                        <strong>2</strong> Preview batch submission
                    </div>
                </div>
            </div>
        </div>
        <div className="content-styles">
            <p><strong>Use the example below as a guide while following a few simple rules:</strong></p>
            <ul>
                <li>Only the fields in the example are permitted</li>
                <li>All fields are required</li>
                <li><strong>&ldquo;Intent&rdquo;</strong> can be either &ldquo;A&rdquo; for Add or &ldquo;R&rdquo; for Remove</li>
                <li><strong>&ldquo;Wholesaler_Serial_Number&rdquo;</strong> and <strong>&ldquo;Retailer_Serial_Number&rdquo;</strong> must be 7 digits long</li>
                <li><strong>&ldquo;Delivery_Amount&rdquo;</strong> must be valid number with maximum possible value 9999999.99</li>
                <li><strong>&ldquo;Delivery_Date&rdquo;</strong> must be in the &apos;mm/dd/yyyy&apos; format</li>
                <li><strong>&ldquo;Comment&rdquo;</strong> can not be longer 200 characters</li>
                <li><strong>&ldquo;Retailer_Name&rdquo;</strong> can not be longer 40 characters</li>
            </ul>
            <br/>
            <h4 className="mb-3">Example</h4>
            <div className="table-responsive mb-4">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Column name</th>
                            <th>Example value</th>
                            <th>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Intent</td>
                            <td>A</td>
                            <td>&ldquo;Intent&rdquo; with either be &ldquo;A&rdquo; for Add or &ldquo;R&rdquo; for Remove</td>
                        </tr>
                        <tr>
                            <td>Wholesaler_Serial_Number</td>
                            <td>9999999</td>
                            <td>Wholesaler serial number</td>
                        </tr>
                        <tr>
                            <td>Retailer_Serial_Number</td>
                            <td>8000001</td>
                            <td>Retailer serial number</td>
                        </tr>
                        <tr>
                            <td>Delivery_Amount</td>
                            <td>11.11</td>
                            <td>Delinquency record amount value</td>
                        </tr>
                        <tr>
                            <td>Delivery_Date</td>
                            <td>02/11/2016</td>
                            <td>Date of delivery</td>
                        </tr>
                        <tr>
                            <td>Comment</td>
                            <td>TEST 1</td>
                            <td>Plain text for comment</td>
                        </tr>
                        <tr>
                            <td>Retailer_Name</td>
                            <td>My Store</td>
                            <td>Name of the retailer</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <h4 className="mb-3">Which would look like this</h4>
            <div className="rounded-3 border px-3 py-2 bg-light opacity-75 mb-4 text-break">
                <p className="mb-0">Intent,Wholesaler_Serial_Number,Retailer_Serial_Number,Delivery_Amount,Delivery_Date,Comment,Retailer_Name<br/>
                    A,9999999,8000001,11.11,02/11/2016,TEST 1,My Store</p>
            </div>
        </div>
        <div className="card mt-3">
            <div className="card-body">
                <div className="mb-3 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                    <h4><strong>Step 1:</strong> Select CSV file</h4>
                    <a href="/">Check the status & history of previous imports</a>
                </div>
                <div className="form-group">
                    <label htmlFor="formFile" className="form-label">Choose file</label>
                    <input className="form-control" type="file" id="formFile" />
                </div>
                <div className="row justify-content-center mt-4">
                    <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                        <a href="/delinquent/batch2" className="btn btn-primary w-100">Continue</a>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default DelinquentBatchView;
