import { Control } from "react-hook-form/dist/types/form";
import { FormCheckType } from "react-bootstrap/FormCheck";
import React from "react";
import { Controller } from "react-hook-form";
import Form from "react-bootstrap/Form";
import { extractPathFromObject } from "components/form/BaseInput";

interface YNFormSwitchParams {
    control:  Control<any>,
    errors?: any,
    name: string,
    label?: string,
    options: string[],//[trueOption: any, falseOption: any]
    type?: FormCheckType
}

export const ControlledFormSwitch = ({ control, errors, name, label, options, type,  ...rest }:YNFormSwitchParams & React.InputHTMLAttributes<HTMLInputElement>) =>{
    const error = extractPathFromObject(errors, name);
    const errorMessage = error?.message;

    return <Controller control={ control } name={ name }
        render={
            ({ field:{ value, onChange, ref } })=> (
                <Form.Check type={ type || "switch" } label={ label } id={ `cfs-${name}` } { ...rest }
                    checked={ value === options[0] } ref={ ref }
                    onChange={ e=>{ onChange(e.target.checked ? options[0] : options[1]); } }
                    isInvalid={ errorMessage!== undefined }
                    feedback={ errorMessage } feedbackType={ errorMessage ? "invalid" : undefined }
                /> )
        }
    />;
};
