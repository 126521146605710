import React from "react";
import { useMenuStore } from "store/MenuStoreProvider";
import { observer } from "mobx-react";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";
import { useShowHideStore } from "store/ShowHideStoreProvider";
import Collapse from "react-bootstrap/Collapse";
import { MenuItem } from "components/menu/Menu";


const LeftMenu = () => {

    const { leftMenuSettings, isLeftMenuHidden } = useMenuStore();
    const { isAllHidden } = useShowHideStore();
    const location = useLocation();

    if (isLeftMenuHidden || !leftMenuSettings || isAllHidden) {
        return null;
    }

    const { items, caption } = leftMenuSettings;

    function collapse(item:MenuItem) {
        item.open = !item?.open;
    }

    return <aside>
        <header>
            { caption }
            <button className="navbar-toggler position-absolute d-lg-none collapsed icon icon-see-all"
                type="button" data-bs-toggle="collapse" data-bs-target="#sidebar"
                aria-controls="sidebar" aria-expanded="false" aria-label="Toggle navigation">
                Show all
            </button>
        </header>
        <ul className="main-sidebar d-lg-block collapse" id="sidebar">
            { items
                .filter(item => (item.predicate === undefined || item.predicate()) )
                .map((item, index) => (
                    <li key={ index }>
                        <div className={ classNames("main-sidebar-h", { "active" : location.pathname.includes(item.link) }) }>
                            { item.submenu && item.submenu.length > 0
                                ? <button className={ classNames("btn d-flex align-items-center w-100 main-sidebar-a", { "active" : location.pathname.includes(item.link) }) } onClick={ () => collapse(item) }>
                                    { item.title }
                                    <i className={ classNames("bi bi-chevron-down ms-auto", { "bi-chevron-up" : item.open }) }></i>
                                </button>
                                : <Link to={ item.link } className={ classNames("main-sidebar-a", { "active" : location.pathname.includes(item.link) }) }>
                                    { item.title }
                                </Link>
                            }
                        </div>
                        { (item.submenu && item.submenu.length > 0) &&
                            <Collapse in={ item.open }>
                                <div className="main-sidebar-toggle-nav">
                                    <ul>
                                        { item.submenu
                                            .filter(child => (child.predicate === undefined || child.predicate()) )
                                            .map((child, childIndex)=>(
                                                <li key={ childIndex }>
                                                    <Link to={ child.link } className={ classNames("main-sidebar-a", { "active" : location.pathname.includes(child.link) }) }>
                                                        { child.title }
                                                    </Link>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </Collapse>
                        }
                    </li>
                ))
            }
        </ul>
    </aside>;
};

export default observer(LeftMenu);
