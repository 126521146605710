import httpClient from "api/HttpClient";
import { PageableResponse } from "models/response/PageableResponse";
import BACKEND from "services/Constants";
import { Discount } from "models/Discount";
import { PageableRequest } from "models/requests/PageableRequest";

const ROOT_PATH = `${BACKEND}/wholesaler/discounts`;

export default {

    list(request: PageableRequest): Promise<PageableResponse<Discount>> {
        return httpClient.search(`${ROOT_PATH}/list`, request);
    },

    create(request: Discount): Promise<void> {
        return httpClient.post(`${ROOT_PATH}/create`, request);
    },

    update(request: Discount): Promise<void> {
        return httpClient.put(`${ROOT_PATH}/update`, request);
    },

    delete(id: number): Promise<void> {
        return httpClient.delete(`${ROOT_PATH}/delete?id=${id}`);
    },

    validate(discount: Discount): Promise<Record<string, string>> {
        return httpClient.post(`${ROOT_PATH}/validate`, discount);
    },

    hasBoundProducts(id: number): Promise<boolean> {
        return httpClient.get(`${ROOT_PATH}/hasBoundProducts?id=${id}`);
    }
};
