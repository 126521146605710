import React, { Fragment, useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm, useWatch } from "react-hook-form";
import { ProductSearchParamsModel } from "models/ProductSearchParamsModel";
import ProductService, { ProductSearchRequest } from "services/ProductService";
import Input from "components/form/Input";
import { SelectControlled } from "components/form/Select";
import { PageInfo } from "models/PageInfo";
import SelectPostType from "components/form/select/SelectPostType";
import SelectProductContainer from "components/form/select/SelectProductContainer";
import Paginator from "components/Paginator";
import DateUtil from "utils/DateUtil";
import { useLocation, useNavigate } from "react-router-dom";
import Spinner from "components/Spinner";
import URLUtil from "utils/URLUtil";
import { DiscountValues } from "models/DiscountValues";
import CurrencyText from "components/CurrencyText";
import { Dayjs } from "dayjs";
import { useTimeStore } from "store/TimeStoreProvider";
import { Accordion, Col, Row } from "react-bootstrap";
import { useAuthStore } from "store/AuthStoreProvider";
import { showDistributorInfo } from "components/dialogs/DistributorInfoDialog";
import { useToastStore } from "store/ToastStoreProvider";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";
import CheckBox from "components/form/CheckBox";
import ProductConstants from "services/ProductConstants";
import { PriceMonthConstants } from "services/PriceMonthConstants";
import { ProductTabContainer, TabModel } from "components/product-tab-widget/ProductTabContainer";
import { NYSSupplierTab } from "components/product-tab-widget/components/NYSSupplierTab";
import { LimitedAvailabilityTab } from "components/product-tab-widget/components/LimitedAvailabilityTab";
import { ComboTab } from "components/product-tab-widget/components/ComboTab";
import { ProductModel } from "models/ProductModel";
import { CustomToggle } from "components/product-tab-widget/CustomToggle";
import { ItemTab } from "components/product-tab-widget/components/ItemTab";
import { SelectDistributors } from "components/form/select/SelectDistributors";
import ReCAPTCHA from "react-google-recaptcha-enterprise";
import { LoadingState, PromiseExecutorArgsType } from "services/RecaptchaService";
import { GRECAPTCHA_SITEKEY } from "config";
import { useKeyDownEnterEvent } from "components/KeyDownHook";

type Params = { month: PriceMonthConstants };

type SearchParams = ProductSearchParamsModel & PageInfo & Params;

const urlData = URLUtil.readSearchParams() as SearchParams;

const units = ProductConstants.units.slice();
units.unshift({ title: "Select...", value: "" });

export const SearchForm = ({ month }: Params) => {
    const { newYorkTime } = useTimeStore();
    const { permissionStore } = useAuthStore();
    const location = useLocation();
    const navigate = useNavigate();
    const { addToast } = useToastStore();

    const [products, setProducts] = useState<ProductModel[]>([]);
    const [pageNumber, setPageNumber] = useState<number>(urlData?.page || 1);
    const [pageCount, setPageCount] = useState<number>(0);
    const [postingDate, setPostingDate] = useState<Dayjs>();

    const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.DATA_DISPLAYED);
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const [captchaPromise, setCaptchaPromise] = useState<PromiseExecutorArgsType>();

    const {
        handleSubmit,
        reset,
        getValues,
        setValue,
        watch,
        control,
        setError,
        clearErrors,
        formState: { errors }
    } = useForm<ProductSearchParamsModel>({
        mode: "onSubmit"
    });
    useKeyDownEnterEvent(() => loadingState != LoadingState.LOADING && handleSubmit(onSubmit)());

    const postTypeWatch = useWatch({ control, name: "post_type" });

    useEffect(() => {
        URLUtil.setValuesFromUrl(urlData, setValue);
        const isNotEmpty = Object.keys(urlData).length > 0;
        if (isNotEmpty) {
            if (urlData.post_type) {
                performQuery(urlData);
            } else {
                setError("post_type", { type: "manual", message: "The field is required" });
            }
        }
    }, []);

    useEffect(() => {
        setPostingDate(month === "current_month" ? newYorkTime.startOf("month") : DateUtil.getPricingDate(newYorkTime));
    }, [month]);

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === "post_type") {
                clearErrors("post_type");
                setValue("license_id", undefined);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const onSubmit: SubmitHandler<ProductSearchParamsModel> = data => {
        const searchParams = data as SearchParams;
        searchParams.page = 1;
        setPageNumber(1);
        updateUrlParams(searchParams);
        performQuery(searchParams);
    };

    function updateUrlParams(params: SearchParams) {
        URLUtil.updateUrlParams(location.pathname, params, navigate, ["productSize", "serial_number"]);
    }

    function performQuery(data: SearchParams) {
        data.month = month;

        const request = {
            pageInfo: {
                page: data.page
            },
            filters: URLUtil.pureUrlParameters(data, ["productSize"])
        } as ProductSearchRequest;

        setLoadingState(LoadingState.LOADING);

        ProductService.searchByRetailer(request, showCaptcha).then(response => {
            setProducts(response.data || []);
            setPageCount(response.pageInfo.pagesCount || 1);
            setLoadingState(LoadingState.DATA_DISPLAYED);
        }).catch(() => {
            addToast({ message:"Something went wrong. Please try again", header: "Search for Price", variant: "danger", timeoutMs: DEFAULT_TOASTER_TIMEOUT });
            setLoadingState(LoadingState.DATA_LOADING_ERROR);
        });
    }

    function handlePageClick(pageNumber: number) {
        setPageNumber(pageNumber);
        const searchParams = getValues() as SearchParams;
        searchParams.page = pageNumber;

        updateUrlParams(searchParams);
        performQuery(searchParams);
    }

    function getDiscountString(discount: DiscountValues): string {
        return getValueString(discount.amount) + getValueString(discount.type) + " on " + getValueString(discount.quantity) + getValueString(discount.unit);
    }

    function getValueString(value: number | string): string {
        return value ? value + "" : "";
    }

    const tabs: TabModel[] = [
        ItemTab, NYSSupplierTab, LimitedAvailabilityTab, ComboTab
    ];

    function showCaptcha(): Promise<string>{
        if (recaptchaRef.current){
            recaptchaRef.current.reset();
            setLoadingState(LoadingState.CAPTCHA_DISPLAYED);

            return new Promise((resolve,reject)=>
                setCaptchaPromise({ resolve: resolve, reject: reject })
            );
        }
        return Promise.reject();
    }

    return <div className="d-flex flex-column min-vh-100">
        <div className="mb-4 mb-md-5">
            <h1 className="text-primary mb-4">Search for Price, { postingDate?.format("MMMM YYYY") }</h1>
            <p>Use the form below to search for a product(s).</p>
            <p className="mb-0">Once you have filled in the information you want to search for, please click the &quot;search&quot; button to submit the form.</p>
        </div>
        <div className="card">
            <div className="card-body">
                <form onSubmit={ handleSubmit(onSubmit) }>
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <div className="pe-xl-4 d-flex flex-row">
                                <div className="flex-fill">
                                    <SelectPostType
                                        control={ control } errors={ errors } name="post_type"
                                        isAll={ permissionStore.isSlaAdminOrAdmin() }
                                        required/>
                                </div>
                                <div className="mt-4 pt-2">
                                    <div className="tooltip-wr tooltip-wr-small ms-2 mt-1">
                                        <span className="tooltip-icon bi-question-circle-fill"></span>
                                        <div className="tooltip-msg text-center">
                                            <p>Choose 1 option in select to make active all available filter options</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={ "row " + (postTypeWatch ? "show" : "disabled-form") }>
                        <div className="col-12 col-xl-6">
                            <div className="pe-xl-4">
                                <Input control={ control } name="query"
                                    placeholder="Name of Brand or Product" label="Name of Brand or Product"
                                    type="text" />
                                <Row>
                                    <Col>
                                        <Input name="item_size" label="Product size" control={ control } type="number"/>
                                    </Col>
                                    <Col>
                                        <SelectControlled name="um" options={ units } control={ control } label={ "\u00A0" } />
                                    </Col>
                                </Row>
                                <SelectDistributors
                                    name="license_id"
                                    control={ control }
                                    label="Distributor"
                                    postType={ postTypeWatch }
                                    month={ month }
                                    empty
                                />
                                <Input control={ control } name="brand_reg"
                                    placeholder="Approval or BLR. #" label="Approval or BLR. #" type="text"/>
                            </div>
                        </div>
                        <div className="col-12 col-xl-6">
                            <div className="ps-xl-4">
                                <Input control={ control } name="ttb_id" placeholder="TTB ID #"
                                    label="TTB ID #" type="text"/>
                                <div className="row">
                                    <div className="col-12">
                                        <p className="mb-1"><strong>Front Price Range</strong></p>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 col-xl-4 pe-2">
                                        <div className="form-group-line form-group-without-label">
                                            <Input control={ control } name="priceMin" placeholder="Min" type="number"/>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 col-xl-4 ps-2 pe-md-2">
                                        <div className="form-group-line form-group-without-label">
                                            <Input control={ control } name="priceMax" placeholder="Max" type="number"/>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4 col-xl-4 ps-md-2">
                                        <div className="form-group-line">
                                            <SelectProductContainer control={ control } name="pricePack" label="per"/>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <p className="mb-1">
                                            <strong>Show Items having Discount dollar or volume at least</strong>
                                        </p>
                                    </div>
                                    <div className="col-5 col-md-4 col-lg-4 col-xl-4 pe-2">
                                        <div className="form-group-line form-group-without-label">
                                            <Input control={ control } name="discPrice" placeholder="$ amount" type="number"/>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 col-xl-4 ps-2 pe-md-2">
                                        <div className="form-group-line form-group-without-label">
                                            <Input control={ control } name="discVol" placeholder="Volume" type="number"/>
                                        </div>
                                    </div>
                                    <div className="col-7 col-md-4 col-lg-4 col-xl-4 ps-2 pe-md-2">
                                        <div className="form-group-line">
                                            <SelectProductContainer control={ control } name="discVolPack" label="per"/>
                                        </div>
                                    </div>
                                </div>

                                <CheckBox name="comboLimited" control={ control } type="checkbox" label="Show Only Combo / Limited" />
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center mt-4">
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                            <div className="pe-md-1">
                                <button className="btn btn-outline-secondary w-100" onClick={ () => reset() }>
                                    Clear Form
                                </button>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                            <div className="ps-md-1">
                                <input
                                    className="btn btn-primary w-100"
                                    type="submit"
                                    value="Search"
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <Spinner className="mt-4 pt-2 text-center" hidden={ loadingState != LoadingState.LOADING } />

        { loadingState === LoadingState.DATA_DISPLAYED &&
            <div hidden={ products.length === 0 } className="mt-2">
                <div className="table-responsive mt-4">
                    <Accordion>
                        <table className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th>&nbsp;</th>
                                    <th>Brand name</th>
                                    <th>Product name</th>
                                    <th>Distributor</th>
                                    <th>TTB ID#</th>
                                    <th>Approval / BLR#</th>
                                    <th>Size</th>
                                    <th>Per bottle</th>
                                    <th>Per case</th>
                                    <th>Bottles per Case</th>
                                    <th>Discount</th>
                                </tr>
                            </thead>
                            <tbody>
                                { products.map((item, index) => {
                                    return (
                                        <Fragment key={ index }>
                                            <tr>
                                                <td>
                                                    <CustomToggle eventKey={ index.toString() } />
                                                </td>
                                                <td>{ item.brand_name }</td>
                                                <td>{ item.prod_name }</td>
                                                <td>
                                                    { (item.license.id && item.license.premise_name) &&
                                                        <button
                                                            className="btn btn-link"
                                                            onClick={ () => showDistributorInfo(item.license.id || 0, DateUtil.getMonth(postingDate), postingDate?.year() || 0, postTypeWatch) }>
                                                            <div>
                                                                { item.license.premise_name }
                                                            </div>
                                                        </button>
                                                    }
                                                </td>
                                                <td>{ item.ttb_id }</td>
                                                <td>{ item.brand_reg }</td>
                                                <td>{ item.item_size && item.item_size + item.um }</td>
                                                <td><CurrencyText value={ item.bot_price } /></td>
                                                <td><CurrencyText value={ item.case_price } /></td>
                                                <td>{ item.botpercase }</td>
                                                <td className="text-nowrap pb-0">{ item.discount_values?.map((discount_value, index) => {
                                                    return (<p className="mb-2" key={ index }>{ getDiscountString(discount_value) }</p>);
                                                }) }</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={ 13 } className="p-0">
                                                    <Accordion.Collapse eventKey={ index.toString() }>
                                                        <div className="p-4">
                                                            <ProductTabContainer product={ item } tabs={ tabs }/>
                                                        </div>
                                                    </Accordion.Collapse>
                                                </td>
                                            </tr>
                                        </Fragment>
                                    );
                                }) }
                            </tbody>
                        </table>
                    </Accordion>
                </div>
                <div>
                    <Paginator totalPages={ pageCount } currentPage={ pageNumber } pageClicked={ handlePageClick }/>
                </div>
            </div>
        }

        { loadingState === LoadingState.DATA_LOADING_ERROR &&
            <h5 className="mt-4 pt-2 text-center">Data loading error</h5>
        }

        <div className={ "mt-4 " + (loadingState === LoadingState.CAPTCHA_DISPLAYED ? "d-inline-block" : "d-none") }>
            <ReCAPTCHA ref={ recaptchaRef } sitekey={ GRECAPTCHA_SITEKEY }
                onChange={ (token: string | null) => token && captchaPromise?.resolve(token) }
                onErrored={ ()=>captchaPromise?.reject() }/>
        </div>
    </div>;
};
