import React from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import Spinner from "components/Spinner";

const StyleGuideMainPage = () => {
    return (
        <div className="d-flex flex-column min-vh-100">
            <Header/>
            <main>
                <aside>
                    <header>
                        Sections
                        <button className="navbar-toggler position-absolute d-md-none collapsed icon icon-see-all" type="button" data-bs-toggle="collapse" data-bs-target="#sidebar" aria-controls="sidebar" aria-expanded="false" aria-label="Toggle navigation">Show all</button>
                    </header>
                    <ul className="main-sidebar d-md-block collapse" id="sidebar">
                        <li>
                            <a className="main-sidebar-a active" href="#sidebar-list-0">Typography</a>
                        </li>
                        <li>
                            <a className="main-sidebar-a" href="#sidebar-list-7">Lists</a>
                        </li>
                        <li>
                            <a className="main-sidebar-a" href="#sidebar-list-1">Input</a>
                        </li>
                        <li>
                            <a className="main-sidebar-a" href="#sidebar-list-2">Textarea</a>
                        </li>
                        <li>
                            <a className="main-sidebar-a" href="#sidebar-list-3">Checks</a>
                        </li>
                        <li>
                            <a className="main-sidebar-a" href="#sidebar-list-4">Radios</a>
                        </li>
                        <li>
                            <a className="main-sidebar-a" href="#sidebar-list-5">Select</a>
                        </li>
                        <li>
                            <a className="main-sidebar-a" href="#sidebar-list-6">Buttons</a>
                        </li>
                        <li>
                            <a className="main-sidebar-a" href="#sidebar-list-8">Alerts</a>
                        </li>
                        <li>
                            <a className="main-sidebar-a" href="#sidebar-list-9">Form</a>
                        </li>
                        <li>
                            <a className="main-sidebar-a" href="#sidebar-list-10">Pagination</a>
                        </li>
                        <li>
                            <a className="main-sidebar-a" href="#sidebar-list-11">Modal window</a>
                        </li>
                    </ul>
                </aside>
                <div className="content-wrapper">
                    <div className="content">
                        <div className="container py-5">
                            <h1 className="mb-5">Styleguide for NYSLA</h1>
                            <div className="styleguide">
                                <section id="sidebar-list-0">
                                    <div className="styleguide-head">
                                        <h5 className="mb-3">Typography</h5>
                                        <hr/>
                                    </div>

                                    <h1>This is an H1 heading</h1>
                                    <h2>This is an H2 heading</h2>
                                    <h3>This is an H3 heading</h3>
                                    <h4>This is an H4 heading</h4>
                                    <h5>This is an H5 heading</h5>
                                    <h6>This is an H6 heading</h6><br/>
                                    <p>This is some text</p>
                                    <p><s>This line of text is meant to be treated as no longer accurate.</s></p>
                                    <p><u>This line of text will render as underlined.</u></p>
                                    <p><small>This line rendered as small text.</small></p>
                                    <p><strong>This line rendered as bold text.</strong></p>
                                    <p><em>This line rendered as italicized text.</em></p>

                                    <div className="styleguide-code">
                                        <pre>
                                            <code className="language-html">
                                                <span className="tag">&lt;h1&gt;</span>This is an H1 heading<span className="tag">&lt;/h1&gt;</span><br/>
                                                <span className="tag">&lt;h2&gt;</span>This is an H2 heading<span className="tag">&lt;/h2&gt;</span><br/>
                                                <span className="tag">&lt;h3&gt;</span>This is an H3 heading<span className="tag">&lt;/h3&gt;</span><br/>
                                                <span className="tag">&lt;h4&gt;</span>This is an H4 heading<span className="tag">&lt;/h4&gt;</span><br/>
                                                <span className="tag">&lt;h5&gt;</span>This is an H5 heading<span className="tag">&lt;/h5&gt;</span><br/>
                                                <span className="tag">&lt;h6&gt;</span>This is an H6 heading<span className="tag">&lt;/h6&gt;</span><br/><br/>
                                                <span className="tag">&lt;p&gt;</span>This is some text<span className="tag">&lt;/p&gt;</span><br/>
                                                <span className="tag">&lt;p&gt;&lt;s&gt;</span>This line of text will render as underlined.<span className="tag">&lt;/s&gt;&lt;/p&gt;</span><br/>
                                                <span className="tag">&lt;p&gt;&lt;u&gt;</span>This line of text will render as underlined.<span className="tag">&lt;/u&gt;&lt;/p&gt;</span><br/>
                                                <span className="tag">&lt;p&gt;&lt;small&gt;</span>This line rendered as small text.<span className="tag">&lt;/small&gt;&lt;/p&gt;</span><br/>
                                                <span className="tag">&lt;p&gt;&lt;strong&gt;</span>This line rendered as bold text.<span className="tag">&lt;/strong&gt;&lt;/p&gt;</span><br/>
                                                <span className="tag">&lt;p&gt;&lt;em&gt;</span>This line rendered as italicized text.<span className="tag">&lt;/em&gt;&lt;/p&gt;</span>
                                            </code>
                                        </pre>
                                    </div>
                                </section>
                                <section id="sidebar-list-7">
                                    <div className="styleguide-head">
                                        <h5 className="mb-3">Lists</h5>
                                        <hr/>
                                    </div>
                                    <div className="content-styles">
                                        <ul>
                                            <li>List item 1</li>
                                            <li>List item 2</li>
                                            <li>List item 3</li>
                                        </ul>
                                        <ol>
                                            <li>List item 1</li>
                                            <li>List item 2</li>
                                            <li>List item 3</li>
                                        </ol>
                                    </div>
                                    <div className="styleguide-code">
                                        <pre>
                                            <code className="language-html">
                                                <span className="tag">&lt;div <span className="class">className</span>="<span className="name">content-styles</span>"</span><span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;ul</span><span className="tag">&gt;</span><br/>
                                                            <div className="ms-3">
                                                                <span className="tag">&lt;li</span><span className="tag">&gt;</span>List item 1<span className="tag">&lt;/li&gt;</span><br/>
                                                                <span className="tag">&lt;li</span><span className="tag">&gt;</span>List item 2<span className="tag">&lt;/li&gt;</span><br/>
                                                                <span className="tag">&lt;li</span><span className="tag">&gt;</span>List item 3<span className="tag">&lt;/li&gt;</span>
                                                            </div>
                                                        <span className="tag">&lt;/ul&gt;</span><br/><br/>
                                                        <span className="tag">&lt;ol</span><span className="tag">&gt;</span><br/>
                                                            <div className="ms-3">
                                                                <span className="tag">&lt;li</span><span className="tag">&gt;</span>List item 1<span className="tag">&lt;/li&gt;</span><br/>
                                                                <span className="tag">&lt;li</span><span className="tag">&gt;</span>List item 2<span className="tag">&lt;/li&gt;</span><br/>
                                                                <span className="tag">&lt;li</span><span className="tag">&gt;</span>List item 3<span className="tag">&lt;/li&gt;</span>
                                                            </div>
                                                        <span className="tag">&lt;/ol&gt;</span>
                                                    </div>
                                                <span className="tag">&lt;/div&gt;</span>
                                            </code>
                                        </pre>
                                    </div>
                                </section>
                                <section id="sidebar-list-1">
                                    <div className="styleguide-head">
                                        <h5 className="mb-3">Input</h5>
                                        <hr/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="Input" className="form-label">Label for Input</label>
                                        <input id="Input" type="text" className="form-control" placeholder="Input" value=""/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="Disabled-input" className="form-label">Label for Disabled input</label>
                                        <input id="Disabled-input" type="text" className="form-control" placeholder="Disabled input" disabled value=""/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="Disabled-readonly-input" className="form-label">Label for Disabled readonly input</label>
                                        <input id="Disabled-readonly-input" type="text" className="form-control" value="Disabled readonly input" disabled readOnly />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="validationServer" className="form-label">Label for Input</label>
                                        <input id="validationServer" type="text" className="form-control is-invalid" placeholder="Input with error" aria-describedby="validationServerFeedback" required/>
                                        <div id="validationServerFeedback" className="invalid-feedback">Please provide a valid input.</div>
                                    </div>
                                    <div className="styleguide-code">
                                        <pre>
                                            <code className="language-html">
                                                <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">form-group</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;label <span className="class">htmlFor</span>="<span className="name">Input</span>" <span className="class">className</span>="<span className="name">form-label</span>"&gt;</span>Label for Input<span className="tag">&lt;/label&gt;</span><br/>
                                                        <span className="tag">&lt;input</span> <span className="class">id</span>="<span className="name">Input</span>" <span className="class">type</span>="<span className="name">text</span>" <span className="class">className</span>="<span className="name">form-control</span>" <span className="class">placeholder</span>="<span className="name">Input</span>" <span className="class">value</span>="<span className="name"></span>" <span className="tag">/&gt;</span><br/>
                                                     </div>
                                                <span className="tag">&lt;/div&gt;</span><br/><br/>
                                                <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">form-group</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;label <span className="class">htmlFor</span>="<span className="name">Disabled-input</span>" <span className="class">className</span>="<span className="name">form-label</span>"&gt;</span>Label for Disabled input<span className="tag">&lt;/label&gt;</span><br/>
                                                        <span className="tag">&lt;input</span> <span className="class">id</span>="<span className="name">Disabled-input</span>" <span className="class">type</span>="<span className="name">text</span>" <span className="class">className</span>="<span className="name">form-control</span>" <span className="class">placeholder</span>="<span className="name">Disabled input</span>" <span className="class">value</span>="<span className="name"></span>" <span className="class">disabled</span> <span className="tag">/&gt;</span><br/>
                                                     </div>
                                                <span className="tag">&lt;/div&gt;</span><br/><br/>
                                                <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">form-group</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;label <span className="class">htmlFor</span>="<span className="name">Disabled-readonly-input</span>" <span className="class">className</span>="<span className="name">form-label</span>"&gt;</span>Label for Disabled readonly input<span className="tag">&lt;/label&gt;</span><br/>
                                                        <span className="tag">&lt;input</span> <span className="class">id</span>="<span className="name">Disabled-readonly-input</span>" <span className="class">type</span>="<span className="name">text</span>" <span className="class">className</span>="<span className="name">form-control</span>" <span className="class">value</span>="<span className="name">Disabled readonly input</span>" <span className="class">disabled</span> <span className="class">readOnly</span> <span className="tag">/&gt;</span><br/>
                                                     </div>
                                                <span className="tag">&lt;/div&gt;</span><br/><br/>
                                                <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">form-group</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;label <span className="class">htmlFor</span>="<span className="name">validationServer</span>" <span className="class">className</span>="<span className="name">form-label</span>"&gt;</span>Label for Input<span className="tag">&lt;/label&gt;</span><br/>
                                                        <span className="tag">&lt;input</span> <span className="class">id</span>="<span className="name">validationServer</span>" <span className="class">type</span>="<span className="name">text</span>" <span className="class">className</span>="<span className="name">form-control is-invalid</span>" <span className="class">placeholder</span>="<span className="name">Input with error</span>" <span className="class">aria-describedby</span>="<span className="name">validationServerFeedback</span>" <span className="class">required</span> <span className="tag">/&gt;</span><br/>
                                                        <span className="tag">&lt;div</span> <span className="class">id</span>="<span className="name">validationServerFeedback</span>" <span className="class">className</span>="<span className="name">invalid-feedback</span>"<span className="tag">&gt;</span>Please provide a valid input.<span className="tag">&lt;/div&gt;</span><br/>
                                                    </div>
                                                <span className="tag">&lt;/div&gt;</span><br/><br/>
                                            </code>
                                        </pre>
                                    </div>
                                </section>
                                <section id="sidebar-list-2">
                                    <div className="styleguide-head">
                                        <h5 className="mb-3">Textarea</h5>
                                        <hr/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="Textarea" className="form-label">Label</label>
                                        <textarea id="Textarea" className="form-control" placeholder="Textarea"></textarea>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="validationTextarea" className="form-label">Label</label>
                                        <textarea className="form-control is-invalid" id="validationTextarea"
                                                  placeholder="Textarea with error" aria-describedby="validationServerFeedback" required></textarea>
                                        <div id="validationServerFeedback" className="invalid-feedback">Please enter a message in the textarea.</div>
                                    </div>
                                    <div className="styleguide-code">
                                        <pre>
                                            <code className="language-html">
                                                <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">form-group</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;label <span className="class">htmlFor</span>="<span className="name">Textarea</span>" <span className="class">className</span>="<span className="name">form-label</span>"&gt;</span>Label<span className="tag">&lt;/label&gt;</span><br/>
                                                        <span className="tag">&lt;textarea</span> <span className="class">id</span>="<span className="name">Textarea</span>" <span className="class">className</span>="<span className="name">form-control</span>" <span className="class">placeholder</span>="<span className="name">Textarea</span>"<span className="tag">&gt;</span><span className="tag">&lt;/textarea&gt;</span><br/>
                                                     </div>
                                                <span className="tag">&lt;/div&gt;</span><br/><br/>
                                                <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">form-group</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;label <span className="class">htmlFor</span>="<span className="name">validationTextarea</span>" <span className="class">className</span>="<span className="name">form-label</span>"&gt;</span>Label<span className="tag">&lt;/label&gt;</span><br/>
                                                        <span className="tag">&lt;textarea</span> <span className="class">id</span>="<span className="name">validationTextarea</span>" <span className="class">className</span>="<span className="name">form-control is-invalid</span>" <span className="class">placeholder</span>="<span className="name">Textarea with error</span>" <span className="class">aria-describedby</span>="<span className="name">validationServerFeedback</span>" <span className="class">required</span><span className="tag">&gt;</span><span className="tag">&lt;/textarea&gt;</span><br/>
                                                        <span className="tag">&lt;div</span> <span className="class">id</span>="<span className="name">validationServerFeedback</span>" <span className="class">className</span>="<span className="name">invalid-feedback</span>"<span className="tag">&gt;</span>Please provide a valid input.<span className="tag">&lt;/div&gt;</span><br/>
                                                    </div>
                                                <span className="tag">&lt;/div&gt;</span><br/><br/>
                                            </code>
                                        </pre>
                                    </div>
                                </section>
                                <section id="sidebar-list-3">
                                    <div className="styleguide-head">
                                        <h5 className="mb-3">Checks</h5>
                                        <hr/>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="CheckDefault" />
                                        <label className="form-check-label" htmlFor="CheckDefault">
                                            Default checkbox
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="CheckChecked" checked />
                                        <label className="form-check-label" htmlFor="CheckChecked">
                                            Checked checkbox
                                        </label>
                                    </div>
                                    <div className="form-check mb-3">
                                        <input className="form-check-input" type="checkbox" value="" id="CheckDisabled" disabled />
                                        <label className="form-check-label" htmlFor="CheckDisabled">
                                            Disabled checkbox
                                        </label>
                                    </div>
                                    <div className="form-check mb-3">
                                        <input className="form-check-input is-invalid" type="checkbox" value=""
                                               id="invalidCheck3" aria-describedby="invalidCheck3Feedback" required />
                                        <label className="form-check-label" htmlFor="invalidCheck3">Checkbox with error</label>
                                        <div id="invalidCheck3Feedback" className="invalid-feedback">You must agree before submitting.</div>
                                    </div>
                                    <form className="was-validated">
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" id="validationFormCheck1" required />
                                            <label className="form-check-label" htmlFor="validationFormCheck1">Checkbox with validation</label>
                                            <div className="invalid-feedback">Example invalid feedback text</div>
                                        </div>
                                    </form>

                                    <div className="styleguide-code">
                                        <pre>
                                            <code className="language-html">
                                                <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">form-check</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;input</span> <span className="class">id</span>="<span className="name">CheckDefault</span>" <span className="class">type</span>="<span className="name">checkbox</span>" <span className="class">className</span>="<span className="name">form-check-input</span>" <span className="class">value</span>="<span className="name"></span>" <span className="tag">/&gt;</span><br/>
                                                        <span className="tag">&lt;label <span className="class">htmlFor</span>="<span className="name">CheckDefault</span>" <span className="class">className</span>="<span className="name">form-check-label</span>"&gt;</span>Default checkbox<span className="tag">&lt;/label&gt;</span><br/>
                                                    </div>
                                                <span className="tag">&lt;/div&gt;</span><br/><br/>
                                                <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">form-check</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;input</span> <span className="class">id</span>="<span className="name">CheckChecked</span>" <span className="class">type</span>="<span className="name">checkbox</span>" <span className="class">className</span>="<span className="name">form-check-input</span>" <span className="class">value</span>="<span className="name"></span>" <span className="class">checked</span> <span className="tag">/&gt;</span><br/>
                                                        <span className="tag">&lt;label <span className="class">htmlFor</span>="<span className="name">CheckChecked</span>" <span className="class">className</span>="<span className="name">form-check-label</span>"&gt;</span>Checked checkbox<span className="tag">&lt;/label&gt;</span><br/>
                                                    </div>
                                                <span className="tag">&lt;/div&gt;</span><br/><br/>
                                                <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">form-check</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;input</span> <span className="class">id</span>="<span className="name">CheckDisabled</span>" <span className="class">type</span>="<span className="name">checkbox</span>" <span className="class">className</span>="<span className="name">form-check-input</span>" <span className="class">value</span>="<span className="name"></span>" <span className="class">disabled</span> <span className="tag">/&gt;</span><br/>
                                                        <span className="tag">&lt;label <span className="class">htmlFor</span>="<span className="name">CheckDisabled</span>" <span className="class">className</span>="<span className="name">form-check-label</span>"&gt;</span>Disabled checkbox<span className="tag">&lt;/label&gt;</span><br/>
                                                    </div>
                                                <span className="tag">&lt;/div&gt;</span><br/><br/>
                                                <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">form-check</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;input</span> <span className="class">id</span>="<span className="name">invalidCheck3</span>" <span className="class">type</span>="<span className="name">checkbox</span>" <span className="class">className</span>="<span className="name">form-check-input is-invalid</span>" <span className="class">value</span>="<span className="name"></span>" <span className="class">aria-describedby</span>="<span className="name">invalidCheck3Feedback</span>" <span className="class">required</span> <span className="tag">/&gt;</span><br/>
                                                        <span className="tag">&lt;label <span className="class">htmlFor</span>="<span className="name">invalidCheck3</span>" <span className="class">className</span>="<span className="name">form-check-label</span>"&gt;</span>Checkbox with error<span className="tag">&lt;/label&gt;</span><br/>
                                                        <span className="tag">&lt;div</span> <span className="class">id</span>="<span className="name">invalidCheck3Feedback</span>" <span className="class">className</span>="<span className="name">invalid-feedback</span>"<span className="tag">&gt;</span>You must agree before submitting.<span className="tag">&lt;/div&gt;</span><br/>
                                                    </div>
                                                <span className="tag">&lt;/div&gt;</span><br/><br/>

                                                <span className="tag">&lt;form</span> <span className="class">className</span>="<span className="name">was-validated</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">form-check</span>"<span className="tag">&gt;</span><br/>
                                                            <div className="ms-3">
                                                                <span className="tag">&lt;input</span> <span className="class">id</span>="<span className="name">validationFormCheck1</span>" <span className="class">type</span>="<span className="name">checkbox</span>" <span className="class">className</span>="<span className="name">form-check-input</span>" <span className="class">required</span> <span className="tag">/&gt;</span><br/>
                                                                <span className="tag">&lt;label <span className="class">htmlFor</span>="<span className="name">validationFormCheck1</span>" <span className="class">className</span>="<span className="name">form-check-label</span>"&gt;</span>Checkbox with validation<span className="tag">&lt;/label&gt;</span><br/>
                                                                <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">invalid-feedback</span>"<span className="tag">&gt;</span>Example invalid feedback text<span className="tag">&lt;/div&gt;</span><br/>
                                                            </div>
                                                        <span className="tag">&lt;/div&gt;</span>
                                                    </div>
                                                <span className="tag">&lt;/form&gt;</span>
                                            </code>
                                        </pre>
                                    </div>
                                </section>
                                <section id="sidebar-list-4">
                                    <div className="styleguide-head">
                                        <h5 className="mb-3">Radios</h5>
                                        <hr/>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" value="" id="RadioDefault" />
                                        <label className="form-check-label" htmlFor="RadioDefault">
                                            Default radio
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" value="" id="RadioChecked" checked />
                                        <label className="form-check-label" htmlFor="RadioChecked">
                                            Checked radio
                                        </label>
                                    </div>
                                    <div className="form-check mb-3">
                                        <input className="form-check-input" type="radio" value="" id="RadioDisabled" disabled />
                                        <label className="form-check-label" htmlFor="RadioDisabled">
                                            Disabled radio
                                        </label>
                                    </div>
                                    <form className="was-validated">
                                        <div className="form-check">
                                            <input type="radio" className="form-check-input" id="validationFormCheck2" name="radio-stacked" required />
                                            <label className="form-check-label" htmlFor="validationFormCheck2">Toggle this radio</label>
                                        </div>
                                        <div className="form-check mb-3">
                                            <input type="radio" className="form-check-input" id="validationFormCheck3" name="radio-stacked" required />
                                            <label className="form-check-label" htmlFor="validationFormCheck3">Or toggle this other radio</label>
                                            <div className="invalid-feedback">More example invalid feedback text</div>
                                        </div>
                                    </form>

                                    <div className="styleguide-code">
                                        <pre>
                                            <code className="language-html">
                                                <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">form-check</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;input</span> <span className="class">id</span>="<span className="name">RadioDefault</span>" <span className="class">type</span>="<span className="name">radio</span>" <span className="class">className</span>="<span className="name">form-check-input</span>" <span className="class">value</span>="<span className="name"></span>" <span className="tag">/&gt;</span><br/>
                                                        <span className="tag">&lt;label <span className="class">htmlFor</span>="<span className="name">RadioDefault</span>" <span className="class">className</span>="<span className="name">form-check-label</span>"&gt;</span>Default radio<span className="tag">&lt;/label&gt;</span><br/>
                                                    </div>
                                                <span className="tag">&lt;/div&gt;</span><br/><br/>
                                                <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">form-check</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;input</span> <span className="class">id</span>="<span className="name">RadioChecked</span>" <span className="class">type</span>="<span className="name">radio</span>" <span className="class">className</span>="<span className="name">form-check-input</span>" <span className="class">value</span>="<span className="name"></span>" <span className="class">checked</span> <span className="tag">/&gt;</span><br/>
                                                        <span className="tag">&lt;label <span className="class">htmlFor</span>="<span className="name">RadioChecked</span>" <span className="class">className</span>="<span className="name">form-check-label</span>"&gt;</span>Checked radio<span className="tag">&lt;/label&gt;</span><br/>
                                                    </div>
                                                <span className="tag">&lt;/div&gt;</span><br/><br/>
                                                <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">form-check</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;input</span> <span className="class">id</span>="<span className="name">RadioDisabled</span>" <span className="class">type</span>="<span className="name">radio</span>" <span className="class">className</span>="<span className="name">form-check-input</span>" <span className="class">value</span>="<span className="name"></span>" <span className="class">disabled</span> <span className="tag">/&gt;</span><br/>
                                                        <span className="tag">&lt;label <span className="class">htmlFor</span>="<span className="name">RadioDisabled</span>" <span className="class">className</span>="<span className="name">form-check-label</span>"&gt;</span>Disabled radio<span className="tag">&lt;/label&gt;</span><br/>
                                                    </div>
                                                <span className="tag">&lt;/div&gt;</span><br/><br/>

                                                <span className="tag">&lt;form</span> <span className="class">className</span>="<span className="name">was-validated</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">form-check</span>"<span className="tag">&gt;</span><br/>
                                                            <div className="ms-3">
                                                                <span className="tag">&lt;input</span> <span className="class">id</span>="<span className="name">validationFormCheck1</span>" <span className="class">type</span>="<span className="name">radio</span>" <span className="class">className</span>="<span className="name">form-check-input</span>" <span className="class">name</span>="<span className="name">radio-stacked</span>" <span className="class">required</span> <span className="tag">/&gt;</span><br/>
                                                                <span className="tag">&lt;label <span className="class">htmlFor</span>="<span className="name">validationFormCheck1</span>" <span className="class">className</span>="<span className="name">form-check-label</span>"&gt;</span>Toggle this radio<span className="tag">&lt;/label&gt;</span><br/>
                                                            </div>
                                                        <span className="tag">&lt;/div&gt;</span><br/>
                                                        <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">form-check</span>"<span className="tag">&gt;</span><br/>
                                                            <div className="ms-3">
                                                                <span className="tag">&lt;input</span> <span className="class">id</span>="<span className="name">validationFormCheck2</span>" <span className="class">type</span>="<span className="name">radio</span>" <span className="class">className</span>="<span className="name">form-check-input</span>" <span className="class">name</span>="<span className="name">radio-stacked</span>" <span className="class">required</span> <span className="tag">/&gt;</span><br/>
                                                                <span className="tag">&lt;label <span className="class">htmlFor</span>="<span className="name">validationFormCheck2</span>" <span className="class">className</span>="<span className="name">form-check-label</span>"&gt;</span>Or toggle this other radio<span className="tag">&lt;/label&gt;</span><br/>
                                                                <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">invalid-feedback</span>"<span className="tag">&gt;</span>More example invalid feedback text<span className="tag">&lt;/div&gt;</span><br/>
                                                            </div>
                                                        <span className="tag">&lt;/div&gt;</span>
                                                    </div>
                                                <span className="tag">&lt;/form&gt;</span>
                                            </code>
                                        </pre>
                                    </div>
                                </section>
                                <section id="sidebar-list-5">
                                    <div className="styleguide-head">
                                        <h5 className="mb-3">Select</h5>
                                        <hr/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="Select" className="form-label">Label</label>
                                        <select id="Select" className="form-select" aria-label="Select">
                                            <option selected>Select</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                    <form className="was-validated">
                                        <div className="form-group">
                                            <select className="form-select" required aria-label="Select">
                                                <option value="">Select with validation</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                            <div className="invalid-feedback">Invalid select feedback</div>
                                        </div>
                                    </form>
                                    <div className="styleguide-code">
                                        <pre>
                                            <code className="language-html">
                                                <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">form-group</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;label <span className="class">htmlFor</span>="<span className="name">Select</span>" <span className="class">className</span>="<span className="name">form-label</span>"&gt;</span>Label<span className="tag">&lt;/label&gt;</span><br/>
                                                        <span className="tag">&lt;select</span> <span className="class">id</span>="<span className="name">Select</span>" <span className="class">className</span>="<span className="name">form-select</span>" <span className="class">aria-label</span>="<span className="name">Select</span>"<span className="tag">&gt;</span><br/>
                                                        <div className="ms-3">
                                                            <span className="tag">&lt;option</span> <span className="class">selected</span><span className="tag">&gt;</span>Select<span className="tag">&lt;/option&gt;</span><br/>
                                                            <span className="tag">&lt;option</span> <span className="class">value</span>="<span className="name">1</span>"<span className="tag">&gt;</span>One<span className="tag">&lt;/option&gt;</span><br/>
                                                            <span className="tag">&lt;option</span> <span className="class">value</span>="<span className="name">2</span>"<span className="tag">&gt;</span>Two<span className="tag">&lt;/option&gt;</span><br/>
                                                            <span className="tag">&lt;option</span> <span className="class">value</span>="<span className="name">3</span>"<span className="tag">&gt;</span>Three<span className="tag">&lt;/option&gt;</span>
                                                        </div>
                                                        <span className="tag">&lt;/select&gt;</span>
                                                     </div>
                                                <span className="tag">&lt;/div&gt;</span><br/><br/>

                                                <span className="tag">&lt;form</span> <span className="class">className</span>="<span className="name">was-validated</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">form-group</span>"<span className="tag">&gt;</span><br/>
                                                            <div className="ms-3">
                                                                <span className="tag">&lt;select</span> <span className="class">className</span>="<span className="name">form-select</span>" <span className="class">aria-label</span>="<span className="name">Select</span>" <span className="class">required</span><span className="tag">&gt;</span><br/>
                                                                <div className="ms-3">
                                                                    <span className="tag">&lt;option</span> <span className="class">value</span>="<span className="name"></span>"<span className="tag">&gt;</span>Select with validation<span className="tag">&lt;/option&gt;</span><br/>
                                                                    <span className="tag">&lt;option</span> <span className="class">value</span>="<span className="name">1</span>"<span className="tag">&gt;</span>One<span className="tag">&lt;/option&gt;</span><br/>
                                                                    <span className="tag">&lt;option</span> <span className="class">value</span>="<span className="name">2</span>"<span className="tag">&gt;</span>Two<span className="tag">&lt;/option&gt;</span><br/>
                                                                    <span className="tag">&lt;option</span> <span className="class">value</span>="<span className="name">3</span>"<span className="tag">&gt;</span>Three<span className="tag">&lt;/option&gt;</span>
                                                                </div>
                                                                <span className="tag">&lt;/select&gt;</span><br/>
                                                                <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">invalid-feedback</span>"<span className="tag">&gt;</span>Invalid select feedback<span className="tag">&lt;/div&gt;</span><br/>
                                                            </div>
                                                        <span className="tag">&lt;/div&gt;</span>
                                                     </div>
                                                <span className="tag">&lt;/form&gt;</span>
                                            </code>
                                        </pre>
                                    </div>
                                </section>
                                <section id="sidebar-list-6">
                                    <div className="styleguide-head">
                                        <h5 className="mb-3">Buttons</h5>
                                        <hr/>
                                    </div>
                                    <button type="submit" className="btn btn-primary">Primary button</button><br/><br/>
                                    <button type="submit" className="btn btn-secondary">Secondary button</button><br/><br/>
                                    <button type="submit" className="btn btn-secondary" disabled>Disabled primary button</button>
                                    <div className="styleguide-code">
                                        <pre>
                                            <code className="language-html">
                                                <span className="tag">&lt;button</span> <span className="class">type</span>="<span className="name">submit</span>" <span className="class">className</span>="<span className="name">btn btn-primary</span>"<span className="tag">&gt;</span>Primary button<span className="tag">&lt;/button&gt;</span><br/><br/>
                                                <span className="tag">&lt;button</span> <span className="class">type</span>="<span className="name">submit</span>" <span className="class">className</span>="<span className="name">btn btn-secondary</span>"<span className="tag">&gt;</span>Secondary button<span className="tag">&lt;/button&gt;</span><br/><br/>
                                                <span className="tag">&lt;button</span> <span className="class">type</span>="<span className="name">submit</span>" <span className="class">className</span>="<span className="name">btn btn-primary</span>" <span className="class">disabled</span><span className="tag">&gt;</span>Disabled primary button<span className="tag">&lt;/button&gt;</span>
                                            </code>
                                        </pre>
                                    </div>
                                </section>
                                <section id="sidebar-list-8">
                                    <div className="styleguide-head">
                                        <h5 className="mb-3">Alerts</h5>
                                        <hr/>
                                    </div>

                                    <div className="alert alert-info alert-dismissible fade show" role="alert">
                                        <i className="bi bi-info-circle-fill me-2"></i>
                                        <strong>Holy guacamole!</strong> Aww yeah, you successfully read this important alert message.
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Cancel"></button>
                                    </div>
                                    <div className="alert alert-success alert-dismissible fade show" role="alert">
                                        <i className="bi bi-check-circle-fill me-2"></i>
                                        <strong>Holy guacamole!</strong> Aww yeah, you successfully read this important alert message.
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Cancel"></button>
                                    </div>
                                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                        <i className="bi bi-exclamation-circle-fill me-2"></i>
                                        <strong>Holy guacamole!</strong> You should check in on some of those fields below.
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Cancel"></button>
                                    </div>
                                    <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                        <i className="bi bi-exclamation-circle-fill me-2"></i>
                                        <strong>Holy guacamole!</strong> You should check in on some of those fields below.
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Cancel"></button>
                                    </div>

                                    <div className="styleguide-code">
                                        <pre>
                                            <code className="language-html">
                                                <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">alert alert-info alert-dismissible fade show</span>" <span className="class">role</span>="<span className="name">alert</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;i <span className="class">className</span>="<span className="name">bi bi-info-circle-fill me-2</span>"&gt;</span><span className="tag">&lt;/i&gt;</span><br/>
                                                        <span className="tag">&lt;strong&gt;</span>Holy guacamole!<span className="tag">&lt;/strong&gt;</span> Aww yeah, you successfully read this important alert message.<br/>
                                                        <span className="tag">&lt;button</span> <span className="class">type</span>="<span className="name">button</span>" <span className="class">className</span>="<span className="name">btn-close</span>" <span className="class">data-bs-dismiss</span>="<span className="name">alert</span>" <span className="class">area-label</span>="<span className="name">Cancel</span>"<span className="tag">&gt;</span><span className="tag">&lt;/button&gt;</span><br/>
                                                    </div>
                                                <span className="tag">&lt;/div&gt;</span>
                                                <br/><br/>
                                                <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">alert alert-success alert-dismissible fade show</span>" <span className="class">role</span>="<span className="name">alert</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;i <span className="class">className</span>="<span className="name">bi bi-check-circle-fill me-2</span>"&gt;</span><span className="tag">&lt;/i&gt;</span><br/>
                                                        <span className="tag">&lt;strong&gt;</span>Holy guacamole!<span className="tag">&lt;/strong&gt;</span> Aww yeah, you successfully read this important alert message.<br/>
                                                        <span className="tag">&lt;button</span> <span className="class">type</span>="<span className="name">button</span>" <span className="class">className</span>="<span className="name">btn-close</span>" <span className="class">data-bs-dismiss</span>="<span className="name">alert</span>" <span className="class">area-label</span>="<span className="name">Cancel</span>"<span className="tag">&gt;</span><span className="tag">&lt;/button&gt;</span><br/>
                                                    </div>
                                                <span className="tag">&lt;/div&gt;</span>
                                                <br/><br/>
                                                <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">alert alert-danger alert-dismissible fade show</span>" <span className="class">role</span>="<span className="name">alert</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;i <span className="class">className</span>="<span className="name">bi bi-exclamation-circle-fill me-2</span>"&gt;</span><span className="tag">&lt;/i&gt;</span><br/>
                                                        <span className="tag">&lt;strong&gt;</span>Holy guacamole!<span className="tag">&lt;/strong&gt;</span> You should check in on some of those fields below.<br/>
                                                        <span className="tag">&lt;button</span> <span className="class">type</span>="<span className="name">button</span>" <span className="class">className</span>="<span className="name">btn-close</span>" <span className="class">data-bs-dismiss</span>="<span className="name">alert</span>" <span className="class">area-label</span>="<span className="name">Cancel</span>"<span className="tag">&gt;</span><span className="tag">&lt;/button&gt;</span><br/>
                                                    </div>
                                                <span className="tag">&lt;/div&gt;</span>
                                                <br/><br/>
                                                <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">alert alert-warning alert-dismissible fade show</span>" <span className="class">role</span>="<span className="name">alert</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;i <span className="class">className</span>="<span className="name">bi bi-exclamation-circle-fill me-2</span>"&gt;</span><span className="tag">&lt;/i&gt;</span><br/>
                                                        <span className="tag">&lt;strong&gt;</span>Holy guacamole!<span className="tag">&lt;/strong&gt;</span> You should check in on some of those fields below.<br/>
                                                        <span className="tag">&lt;button</span> <span className="class">type</span>="<span className="name">button</span>" <span className="class">className</span>="<span className="name">btn-close</span>" <span className="class">data-bs-dismiss</span>="<span className="name">alert</span>" <span className="class">area-label</span>="<span className="name">Cancel</span>"<span className="tag">&gt;</span><span className="tag">&lt;/button&gt;</span><br/>
                                                    </div>
                                                <span className="tag">&lt;/div&gt;</span>
                                            </code>
                                        </pre>
                                    </div>
                                </section>
                                <section id="sidebar-list-9">
                                    <div className="styleguide-head">
                                        <h5 className="mb-3">Form</h5>
                                        <hr/>
                                    </div>

                                    <form className="was-validated">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="exampleInputEmail1">Email address</label>
                                            <input type="email" className="form-control" id="exampleInputEmail1" placeholder="Enter email" />
                                            <div className="invalid-feedback">Please enter email</div>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="exampleInputPassword1">Password</label>
                                            <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" />
                                        </div>
                                        <div className="form-check mb-3">
                                            <input type="checkbox" className="form-check-input" id="validationFormCheck1" required />
                                            <label className="form-check-label" htmlFor="validationFormCheck1">Check me out</label>
                                            <div className="invalid-feedback">Example invalid feedback text</div>
                                        </div>
                                        <button type="submit" className="btn btn-primary" disabled>Submit this form</button>

                                        <Spinner className="mt-3 text-center" />

                                        <div className="invalid-message alert alert-sm alert-danger mt-3" role="alert">
                                            <ul>
                                                <li>You enter not valid email</li>
                                                <li>You enter not current password</li>
                                                <li>You tried to log in too many times</li>
                                            </ul>
                                        </div>
                                    </form>

                                    <div className="styleguide-code">
                                        <pre>
                                            <code className="language-html">
                                                <span className="tag">&lt;form <span className="class">className</span>="<span className="name">was-validated</span>"&gt;</span><br/>
                                                <div className="ms-3">
                                                    <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">form-group</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;label <span className="class">htmlFor</span>="<span className="name">exampleInputEmail1</span>" <span className="class">className</span>="<span className="name">form-label</span>"&gt;</span>Email address<span className="tag">&lt;/label&gt;</span><br/>
                                                        <span className="tag">&lt;input</span> <span className="class">id</span>="<span className="name">exampleInputEmail1</span>" <span className="class">type</span>="<span className="name">email</span>" <span className="class">className</span>="<span className="name">form-control</span>" <span className="class">placeholder</span>="<span className="name">Enter email</span>" <span className="tag">/&gt;</span><br/>
                                                        <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">invalid-feedback</span>"<span className="tag">&gt;</span>Please enter email<span className="tag">&lt;/div&gt;</span><br/>
                                                    </div>
                                                    <span className="tag">&lt;/div&gt;</span><br/>
                                                </div><br/>
                                                <div className="ms-3">
                                                    <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">form-group</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;label <span className="class">htmlFor</span>="<span className="name">exampleInputPassword1</span>" <span className="class">className</span>="<span className="name">form-label</span>"&gt;</span>Password<span className="tag">&lt;/label&gt;</span><br/>
                                                        <span className="tag">&lt;input</span> <span className="class">id</span>="<span className="name">exampleInputPassword1</span>" <span className="class">type</span>="<span className="name">email</span>" <span className="class">className</span>="<span className="name">form-control</span>" <span className="class">placeholder</span>="<span className="name">Password</span>" <span className="tag">/&gt;</span><br/>
                                                    </div>
                                                    <span className="tag">&lt;/div&gt;</span><br/>
                                                </div><br/>
                                                <div className="ms-3">
                                                    <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">form-check mb-3</span>"<span className="tag">&gt;</span><br/>
                                                        <div className="ms-3">
                                                            <span className="tag">&lt;input</span> <span className="class">id</span>="<span className="name">validationFormCheck1</span>" <span className="class">type</span>="<span className="name">checkbox</span>" <span className="class">className</span>="<span className="name">form-check-input</span>" <span className="class">required</span> <span className="tag">/&gt;</span><br/>
                                                            <span className="tag">&lt;label <span className="class">htmlFor</span>="<span className="name">validationFormCheck1</span>" <span className="class">className</span>="<span className="name">form-check-label</span>"&gt;</span>Check me out<span className="tag">&lt;/label&gt;</span><br/>
                                                            <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">invalid-feedback</span>"<span className="tag">&gt;</span>Example invalid feedback text<span className="tag">&lt;/div&gt;</span><br/>
                                                        </div>
                                                    <span className="tag">&lt;/div&gt;</span><br/>
                                                </div><br/>

                                                <div className="ms-3">
                                                    <span className="tag">&lt;button</span> <span className="class">type</span>="<span className="name">submit</span>" <span className="class">className</span>="<span className="name">btn btn-primary</span>"<span className="tag"> <span className="class">disabled</span>&gt;</span>Submit this form<span className="tag">&lt;/button&gt;</span>
                                                </div><br/>

                                                <div className="ms-3">
                                                    <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">mt-3 text-center</span>"<span className="tag">&gt;</span><br/>
                                                        <div className="ms-3">
                                                            <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">spinner-border text-primary</span> <span className="class">role</span>="<span className="name">status</span>"<span className="tag">&gt;</span><br/>
                                                                <div className="ms-3">
                                                                    <span className="tag">&lt;span</span> <span className="class">className</span>="<span className="name">visually-hidden</span>"<span className="tag">&gt;</span>Loading...<span className="tag">&lt;/span&gt;</span><br/>
                                                                </div>
                                                            <span className="tag">&lt;/div&gt;</span><br/>
                                                        </div>
                                                    <span className="tag">&lt;/div&gt;</span><br/>
                                                </div><br/>

                                                <div className="ms-3">
                                                    <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">invalid-message alert alert-sm alert-danger mt-3</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;ul</span><span className="tag">&gt;</span><br/>
                                                        <div className="ms-3">
                                                            <span className="tag">&lt;li</span><span className="tag">&gt;</span>You enter not valid email<span className="tag">&lt;/li&gt;</span><br/>
                                                            <span className="tag">&lt;li</span><span className="tag">&gt;</span>You enter not current password<span className="tag">&lt;/li&gt;</span><br/>
                                                            <span className="tag">&lt;li</span><span className="tag">&gt;</span>You tried to log in too many times<span className="tag">&lt;/li&gt;</span><br/>
                                                        </div>
                                                        <span className="tag">&lt;/ul&gt;</span><br/>
                                                    </div>
                                                    <span className="tag">&lt;/div&gt;</span><br/>
                                                </div>
                                                <span className="tag">&lt;/form&gt;</span><br/>
                                            </code>
                                        </pre>
                                    </div>
                                </section>
                                <section id="sidebar-list-10">
                                    <div className="styleguide-head">
                                        <h5 className="mb-3">Pagination</h5>
                                        <hr/>
                                    </div>

                                    <ul className="justify-content-center pagination">
                                        <li className="page-item disabled">
                                            <span className="page-link">
                                                <span className="bi bi-arrow-left me-2"></span>Prev
                                            </span>
                                        </li>
                                        <li className="page-item active">
                                            <span className="page-link">1</span>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" role="button" href="#">2</a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" role="button" href="#">3</a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" role="button" href="#">…</a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" role="button" href="#">50</a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" role="button" href="#">
                                                Next<span className="bi bi-arrow-right ms-2"></span>
                                            </a>
                                        </li>
                                    </ul>

                                    <br/>

                                    <ul className="justify-content-center pagination">
                                        <li className="page-item">
                                            <a className="page-link" role="button" href="#">
                                                <span className="bi bi-arrow-left me-2"></span>Prev
                                            </a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" role="button" href="#">1</a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" role="button" href="#">…</a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" role="button" href="#">24</a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" role="button" href="#">25</a>
                                        </li>
                                        <li className="page-item active">
                                            <span className="page-link">26</span>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" role="button" href="#">27</a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" role="button" href="#">28</a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" role="button" href="#">…</a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" role="button" href="#">50</a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" role="button" href="#">
                                                Next<span className="bi bi-arrow-right ms-2"></span>
                                            </a>
                                        </li>
                                    </ul>

                                    <div className="styleguide-code">
                                        <pre>
                                            <code className="language-html">
                                                <span className="tag">&lt;ul <span className="class">className</span>="<span className="name">justify-content-center pagination</span>"&gt;</span><br/>
                                                <div className="ms-3">
                                                    <span className="tag">&lt;li</span> <span className="class">className</span>="<span className="name">page-item disabled</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;span <span className="class">className</span>="<span className="name">page-link</span>"&gt;</span>
                                                        <div className="ms-3">
                                                           <span className="tag">&lt;span <span className="class">className</span>="<span className="name">bi bi-arrow-left me-2</span>"&gt;</span><span className="tag">&lt;/span&gt;</span>Prev
                                                        </div>
                                                        <span className="tag">&lt;/span&gt;</span><br/>
                                                    </div>
                                                    <span className="tag">&lt;/li&gt;</span><br/>

                                                    <span className="tag">&lt;li</span> <span className="class">className</span>="<span className="name">page-item active</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;span <span className="class">className</span>="<span className="name">page-link</span>"&gt;</span>1<span className="tag">&lt;/span&gt;</span><br/>
                                                    </div>
                                                    <span className="tag">&lt;/li&gt;</span><br/>

                                                    <span className="tag">&lt;li</span> <span className="class">className</span>="<span className="name">page-item</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;a <span className="class">className</span>="<span className="name">page-link</span>" <span className="class">role</span>="<span className="name">button</span>" <span className="class">href</span>="<span className="name">#</span>"&gt;</span>2<span className="tag">&lt;/a&gt;</span><br/>
                                                    </div>
                                                    <span className="tag">&lt;/li&gt;</span><br/>

                                                    <span className="tag">&lt;li</span> <span className="class">className</span>="<span className="name">page-item</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;a <span className="class">className</span>="<span className="name">page-link</span>" <span className="class">role</span>="<span className="name">button</span>" <span className="class">href</span>="<span className="name">#</span>"&gt;</span>3<span className="tag">&lt;/a&gt;</span><br/>
                                                    </div>
                                                    <span className="tag">&lt;/li&gt;</span><br/>

                                                    <span className="tag">&lt;li</span> <span className="class">className</span>="<span className="name">page-item</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;a <span className="class">className</span>="<span className="name">page-link</span>" <span className="class">role</span>="<span className="name">button</span>" <span className="class">href</span>="<span className="name">#</span>"&gt;</span>...<span className="tag">&lt;/a&gt;</span><br/>
                                                    </div>
                                                    <span className="tag">&lt;/li&gt;</span><br/>

                                                    <span className="tag">&lt;li</span> <span className="class">className</span>="<span className="name">page-item</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;a <span className="class">className</span>="<span className="name">page-link</span>" <span className="class">role</span>="<span className="name">button</span>" <span className="class">href</span>="<span className="name">#</span>"&gt;</span>50<span className="tag">&lt;/a&gt;</span><br/>
                                                    </div>
                                                    <span className="tag">&lt;/li&gt;</span><br/>

                                                    <span className="tag">&lt;li</span> <span className="class">className</span>="<span className="name">page-item</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;a <span className="class">className</span>="<span className="name">page-link</span>" <span className="class">role</span>="<span className="name">button</span>" <span className="class">href</span>="<span className="name">#</span>"&gt;</span>
                                                        <div className="ms-3">
                                                           Next<span className="tag">&lt;span <span className="class">className</span>="<span className="name">bi bi-arrow-right ms-2</span>"&gt;</span><span className="tag">&lt;/span&gt;</span>
                                                        </div>
                                                        <span className="tag">&lt;/a&gt;</span><br/>
                                                    </div>
                                                    <span className="tag">&lt;/li&gt;</span><br/>
                                                </div>
                                                <span className="tag">&lt;/ul&gt;</span><br/>

                                                <br/>

                                                <span className="tag">&lt;ul <span className="class">className</span>="<span className="name">justify-content-center pagination</span>"&gt;</span><br/>
                                                <div className="ms-3">
                                                    <span className="tag">&lt;li</span> <span className="class">className</span>="<span className="name">page-item</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;a <span className="class">className</span>="<span className="name">page-link</span>" <span className="class">role</span>="<span className="name">button</span>" <span className="class">href</span>="<span className="name">#</span>"&gt;</span>
                                                        <div className="ms-3">
                                                           <span className="tag">&lt;span <span className="class">className</span>="<span className="name">bi bi-arrow-left me-2</span>"&gt;</span><span className="tag">&lt;/span&gt;</span>Prev
                                                        </div>
                                                        <span className="tag">&lt;/a&gt;</span><br/>
                                                    </div>
                                                    <span className="tag">&lt;/li&gt;</span><br/>

                                                    <span className="tag">&lt;li</span> <span className="class">className</span>="<span className="name">page-item</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;a <span className="class">className</span>="<span className="name">page-link</span>" <span className="class">role</span>="<span className="name">button</span>" <span className="class">href</span>="<span className="name">#</span>"&gt;</span>1<span className="tag">&lt;/a&gt;</span><br/>
                                                    </div>
                                                    <span className="tag">&lt;/li&gt;</span><br/>

                                                    <span className="tag">&lt;li</span> <span className="class">className</span>="<span className="name">page-item</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;a <span className="class">className</span>="<span className="name">page-link</span>" <span className="class">role</span>="<span className="name">button</span>" <span className="class">href</span>="<span className="name">#</span>"&gt;</span>...<span className="tag">&lt;/a&gt;</span><br/>
                                                    </div>
                                                    <span className="tag">&lt;/li&gt;</span><br/>

                                                    <span className="tag">&lt;li</span> <span className="class">className</span>="<span className="name">page-item</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;a <span className="class">className</span>="<span className="name">page-link</span>" <span className="class">role</span>="<span className="name">button</span>" <span className="class">href</span>="<span className="name">#</span>"&gt;</span>24<span className="tag">&lt;/a&gt;</span><br/>
                                                    </div>
                                                    <span className="tag">&lt;/li&gt;</span><br/>

                                                    <span className="tag">&lt;li</span> <span className="class">className</span>="<span className="name">page-item</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;a <span className="class">className</span>="<span className="name">page-link</span>" <span className="class">role</span>="<span className="name">button</span>" <span className="class">href</span>="<span className="name">#</span>"&gt;</span>25<span className="tag">&lt;/a&gt;</span><br/>
                                                    </div>
                                                    <span className="tag">&lt;/li&gt;</span><br/>

                                                    <span className="tag">&lt;li</span> <span className="class">className</span>="<span className="name">page-item active</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;span <span className="class">className</span>="<span className="name">page-link</span>"&gt;</span>26<span className="tag">&lt;/span&gt;</span><br/>
                                                    </div>
                                                    <span className="tag">&lt;/li&gt;</span><br/>

                                                    <span className="tag">&lt;li</span> <span className="class">className</span>="<span className="name">page-item</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;a <span className="class">className</span>="<span className="name">page-link</span>" <span className="class">role</span>="<span className="name">button</span>" <span className="class">href</span>="<span className="name">#</span>"&gt;</span>27<span className="tag">&lt;/a&gt;</span><br/>
                                                    </div>
                                                    <span className="tag">&lt;/li&gt;</span><br/>

                                                    <span className="tag">&lt;li</span> <span className="class">className</span>="<span className="name">page-item</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;a <span className="class">className</span>="<span className="name">page-link</span>" <span className="class">role</span>="<span className="name">button</span>" <span className="class">href</span>="<span className="name">#</span>"&gt;</span>28<span className="tag">&lt;/a&gt;</span><br/>
                                                    </div>
                                                    <span className="tag">&lt;/li&gt;</span><br/>

                                                    <span className="tag">&lt;li</span> <span className="class">className</span>="<span className="name">page-item</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;a <span className="class">className</span>="<span className="name">page-link</span>" <span className="class">role</span>="<span className="name">button</span>" <span className="class">href</span>="<span className="name">#</span>"&gt;</span>...<span className="tag">&lt;/a&gt;</span><br/>
                                                    </div>
                                                    <span className="tag">&lt;/li&gt;</span><br/>

                                                    <span className="tag">&lt;li</span> <span className="class">className</span>="<span className="name">page-item</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;a <span className="class">className</span>="<span className="name">page-link</span>" <span className="class">role</span>="<span className="name">button</span>" <span className="class">href</span>="<span className="name">#</span>"&gt;</span>50<span className="tag">&lt;/a&gt;</span><br/>
                                                    </div>
                                                    <span className="tag">&lt;/li&gt;</span><br/>

                                                    <span className="tag">&lt;li</span> <span className="class">className</span>="<span className="name">page-item</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;a <span className="class">className</span>="<span className="name">page-link</span>" <span className="class">role</span>="<span className="name">button</span>" <span className="class">href</span>="<span className="name">#</span>"&gt;</span>
                                                        <div className="ms-3">
                                                           Next<span className="tag">&lt;span <span className="class">className</span>="<span className="name">bi bi-arrow-right ms-2</span>"&gt;</span><span className="tag">&lt;/span&gt;</span>
                                                        </div>
                                                        <span className="tag">&lt;/a&gt;</span><br/>
                                                    </div>
                                                    <span className="tag">&lt;/li&gt;</span><br/>
                                                </div>
                                                <span className="tag">&lt;/ul&gt;</span>
                                            </code>
                                        </pre>
                                    </div>
                                </section>
                                <section id="sidebar-list-11">
                                    <div className="styleguide-head">
                                        <h5 className="mb-3">Modal window</h5>
                                        <hr/>
                                    </div>

                                    <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">Launch demo modal</button>

                                    <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Cancel"></button>
                                                </div>
                                                <div className="modal-body">
                                                    <p>Some text or modal window</p>
                                                    <div className="form-group">
                                                        <label htmlFor="Input" className="form-label">Label for Input</label>
                                                        <input id="Input" type="text" className="form-control" placeholder="Input" value="" />
                                                    </div>
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                                    <button type="button" className="btn btn-primary">Save changes</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="styleguide-code">
                                    <pre>
                                        <code className="language-html">
                                            <span className="tag">&lt;button</span> <span className="class">type</span>="<span className="name">submit</span>" <span className="class">className</span>="<span className="name">btn btn-primary</span>" <span className="class">data-bs-toggle</span>="<span className="name">modal</span>" <span className="class">data-bs-target</span>="<span className="name">#exampleModal</span>"<span className="tag">&gt;</span>Launch demo modal<span className="tag">&lt;/button&gt;</span><br/><br/>

                                            <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">modal fade</span>" <span className="class">id</span>="<span className="name">exampleModal</span>" <span className="class">aria-labelledby</span>="<span className="name">exampleModalLabel</span>" <span className="class">aria-hidden</span>="<span className="name">true</span>"<span className="tag">&gt;</span><br/>
                                                <div className="ms-3">
                                                    <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">modal-dialog modal-dialog-centered</span>"<span className="tag">&gt;</span><br/>
                                                    <div className="ms-3">
                                                        <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">modal-content</span>"<span className="tag">&gt;</span><br/>
                                                        <div className="ms-3">
                                                            <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">modal-header</span>"<span className="tag">&gt;</span><br/>
                                                            <div className="ms-3">
                                                                <span className="tag">&lt;h5 <span className="class">className</span>="<span className="name">modal-title</span>" <span className="class">id</span>="<span className="name">exampleModalLabel</span>"&gt;</span>Modal title<span className="tag">&lt;/h5&gt;</span><br/>
                                                                <span className="tag">&lt;button</span> <span className="class">type</span>="<span className="name">button</span>" <span className="class">className</span>="<span className="name">btn-close</span>" <span className="class">data-bs-dismiss</span>="<span className="name">modal</span>" <span className="class">aria-label</span>="<span className="name">Close</span>"<span className="tag">&gt;</span><span className="tag">&lt;/button&gt;</span><br/>
                                                            </div>
                                                            <span className="tag">&lt;/div&gt;</span><br/>

                                                            <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">modal-body</span>"<span className="tag">&gt;</span><br/>
                                                            <div className="ms-3">
                                                                <span className="tag">&lt;p&gt;</span>Some text or modal window<span className="tag">&lt;/p&gt;</span><br/>
                                                                <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">form-group</span>"<span className="tag">&gt;</span><br/>
                                                                    <div className="ms-3">
                                                                        <span className="tag">&lt;label <span className="class">htmlFor</span>="<span className="name">Input</span>" <span className="class">className</span>="<span className="name">form-label</span>"&gt;</span>Label for Input<span className="tag">&lt;/label&gt;</span><br/>
                                                                        <span className="tag">&lt;input</span> <span className="class">id</span>="<span className="name">Input</span>" <span className="class">type</span>="<span className="name">text</span>" <span className="class">className</span>="<span className="name">form-control</span>" <span className="class">placeholder</span>="<span className="name">Input</span>" <span className="class">value</span>="<span className="name"></span>" <span className="tag">/&gt;</span><br/>
                                                                     </div>
                                                                <span className="tag">&lt;/div&gt;</span><br/>
                                                            </div>
                                                            <span className="tag">&lt;/div&gt;</span><br/>

                                                            <span className="tag">&lt;div</span> <span className="class">className</span>="<span className="name">modal-footer</span>"<span className="tag">&gt;</span><br/>
                                                            <div className="ms-3">
                                                                <span className="tag">&lt;button</span> <span className="class">type</span>="<span className="name">button</span>" <span className="class">className</span>="<span className="name">btn btn-secondary</span>" <span className="class">data-bs-dismiss</span>="<span className="name">modal</span>"<span className="tag">&gt;</span>Close<span className="tag">&lt;/button&gt;</span><br/>
                                                                <span className="tag">&lt;button</span> <span className="class">type</span>="<span className="name">button</span>" <span className="class">className</span>="<span className="name">btn btn-primary</span>"<span className="tag">&gt;</span>Save changes<span className="tag">&lt;/button&gt;</span><br/>
                                                            </div>
                                                            <span className="tag">&lt;/div&gt;</span><br/>
                                                        </div>
                                                        <span className="tag">&lt;/div&gt;</span><br/>
                                                    </div>
                                                    <span className="tag">&lt;/div&gt;</span><br/>
                                                </div>
                                            <span className="tag">&lt;/div&gt;</span>
                                        </code>
                                    </pre>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer/>
        </div>
    )
};

export default StyleGuideMainPage;
