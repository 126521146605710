import httpClient from "api/HttpClient";
import { DistributorModel } from "models/DistributorModel";
import BACKEND from "services/Constants";
import { LicenseModel } from "models/license.model";

export default {

    findByPermitIdOrSerialNumber(exactValue: string ): Promise<DistributorModel> {
        return httpClient.get(`${BACKEND}/organization/findByPermitIdOrSerialNumber/${exactValue}`);
    },

    getDistributor(licenseId: number): Promise<DistributorModel> {
        return httpClient.get(BACKEND + "/organization/get-by-id/" + licenseId);
    },

    getFullDistributorInfo(license_id: number, month: number, year: number, postType: string): Promise<LicenseModel> {
        return httpClient.get(BACKEND + "/organization/full-distributor-info/" + license_id
            + "?month=" + month + "&year=" + year + "&post_type=" + postType);
    },

    getPublishedDistributors(postType: string, month?: string): Promise<DistributorModel[]> {
        const additionalParams = month ? "?month=" + month : "";
        return httpClient.get(BACKEND + "/organization/get-published-distributors/" + postType + additionalParams);
    },

    getDistributorsByLicenseAndPostType(licenseId: number, postType: string, month?: string): Promise<DistributorModel[]> {
        const additionalParams = "?post_type=" + postType + (month ? "&month=" + month : "");
        return httpClient.get(BACKEND + "/organization/get-distributors-by-license-and-post-type/" + licenseId + additionalParams);
    }
};
