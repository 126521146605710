import { ProductModel } from "models/ProductModel";
import React from "react";
import { TabModel } from "components/product-tab-widget/ProductTabContainer";
import CurrencyText from "components/CurrencyText";
import { Col, Row } from "react-bootstrap";
import ProductConstants from "services/ProductConstants";

const ItemTabHeader = () => {
    return <span>Item Details</span>;
};

const ItemTabBody = (product: ProductModel) => {
    const getAgentOwnerString = (label_type:string) => label_type === "A" ? "Agent" : (label_type === "O" ? "Owner": "");
    return <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
        <h5 className="mb-3 fw-bold">Item details</h5>
        <Row>
            <Col>
                { ProductConstants.isLiquor(product) &&
                    <p className="mb-2"><strong>Proof:&nbsp;</strong>{ product.proof }</p>
                }
                { ProductConstants.isWine(product) &&
                    <p className="mb-2"><strong>Alcohol Content:&nbsp;</strong>{ ProductConstants.alcoholContentString(product.alcohol) }</p>
                }
                <p className="mb-2"><strong>Age of Year:&nbsp;</strong>{ product.vintage }</p>
                <p className="mb-2"><strong>Owner/Agent:&nbsp;</strong> { getAgentOwnerString(product.label_type) }</p>
            </Col>
            <Col>
                <p className="mb-2"><strong># of bottles per case/sleeve:&nbsp;</strong>{ product.subpack }</p>
                <p className="mb-2"><strong>Case Only:&nbsp;</strong>{ product.fullcase }</p>
                <p className="mb-2"><strong>BT/SLV Split case charge:&nbsp;</strong><CurrencyText value={ product.split_charge || 0 }/></p>
            </Col>
        </Row>
    </div>;
};

export const ItemTab: TabModel = { header: ItemTabHeader, body: ItemTabBody };
