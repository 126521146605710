import httpClient from "api/HttpClient";
import { PageableRequest, pageableRequestToSearchParams } from "models/requests/PageableRequest";
import { PageableResponse } from "models/response/PageableResponse";
import { DelinquentModel } from "models/DelinquentModel";
import BACKEND from "services/Constants";

export type RetailerDelinquencyStatus = {
    byItself: boolean,
    byCBGroup: boolean
};

const root = `${BACKEND}/retailer/delinquent`;
export default {
    getDelinquentDataById(request: PageableRequest): Promise<PageableResponse<DelinquentModel>> {
        return httpClient.get(`${root}/get-delinquent-data-by-id`,{ params: pageableRequestToSearchParams(request) });
    },

    getLicenseDelinquentStatusById(licenseId?: number): Promise<RetailerDelinquencyStatus> {
        return httpClient.get(`${root}/get-license-delinquent-status-by-id`,{ params:{ licenseId: licenseId } });
    }
};
