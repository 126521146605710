import React from "react";
import { observer } from "mobx-react";
import { useAuthStore } from "store/AuthStoreProvider";
import { useShowHideStore } from "store/ShowHideStoreProvider";
import AuthService from "services/AuthService";
import { LoggedInUserInfo } from "models/LoggedInUserInfo";
import { ADMIN_PATHS } from "pages/admin/AdminRoutes";
import { useToastStore } from "store/ToastStoreProvider";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import { useAlertStore } from "store/AlertStoreProvider";
import AlertService from "services/AlertService";
import { Alert } from "components/Alert";

const NotificationBar = () => {
    const authStore = useAuthStore();
    const navigate = useNavigate();
    const location = useLocation();
    const { alerts, updateAlerts } = useAlertStore();
    const { addDefaultDangerToast } = useToastStore();
    const { isAllHidden } = useShowHideStore();

    if (isAllHidden) {
        return <></>;
    }

    function exitImpersonate() {
        const prevLicense = authStore.currentLicense?.permitId || authStore.currentLicense?.serialNumber;
        AuthService.exitImpersonate().then((resp: LoggedInUserInfo) => {
            authStore.setAuthorizedSilently(resp);
            updateAlerts();
            //we navigate to last license page after exiting impersonate
            navigate(generatePath(`${ADMIN_PATHS.LICENSES.SEARCH}?license=${prevLicense}`));
        }).catch(() => addDefaultDangerToast("Auth User"));
    }

    function hideAlert(id: number) {
        AlertService.hideAlert(id)
            .then(() => {
                const hiddenAlerts = authStore.hiddenAlerts;
                hiddenAlerts.push(id);
                authStore.setHiddenAlerts(hiddenAlerts);
            }).catch(() => addDefaultDangerToast("Hide Alert"));
    }

    return (
        <div>
            { authStore.isAdminUnderUser &&
                <div className="alert alert-danger py-2 rounded-0 fade show" role="alert">
                    <i className="bi bi-exclamation-circle-fill me-2"></i>
                    <strong>IMPORTANT!</strong> You are logged in as { authStore.email }. <button className="btn btn-link" onClick={ exitImpersonate }>Click here</button> to return to the admin site
                </div>
            }

            { alerts &&
                alerts
                    .filter(alert => alert.visibility === "AUTH" || !authStore.hiddenAlerts?.includes(alert.id))
                    .filter(alert => location.pathname.includes(alert.visibility.toLowerCase()))
                    .map((alert) => <Alert alert={ alert } hideAlert={ hideAlert } key={ alert.id }/>)
            }
        </div>
    );
};

export default observer(NotificationBar);
