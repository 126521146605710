import React, { ChangeEvent, useEffect, useState } from "react";
import LetterService from "services/wholesaler/LetterService";
import { LetterModel } from "models/LetterModel";
import { Link, useNavigate, useParams } from "react-router-dom";
import { LetterItemModel } from "models/LetterItemModel";
import { WHOLESALER_PATHS } from "pages/wholesaler/WholesalerRoutes";
import { SaveLetterRequest } from "pages/wholesaler/appointment/models/SaveLetterRequest";
import { showChooseFileLetterDialog } from "pages/wholesaler/appointment/dialogs/ChooseFileLetterDialog";
import Input from "components/form/Input";
import ProductService from "services/ProductService";
import { showAddProductLetterDialog } from "pages/wholesaler/appointment/dialogs/AddProductLetterDialog";
import { ProductModel } from "models/ProductModel";
import Spinner from "components/Spinner";
import { SavedFile } from "pages/wholesaler/appointment/components/Step3";
import { useToastStore } from "store/ToastStoreProvider";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";

const UpdateLetter = () => {

    const { id = "-1" } = useParams<{ id: string }>();
    const { addToast } = useToastStore();
    const navigate = useNavigate();
    const [letter, setLetter] = useState<LetterModel>({
        post_type: "",
        description: "",
        file_name: "",
        storage_path: "",
        letter_items: []
    });
    const [selectedProducts, setSelectedProducts] = useState<ProductModel[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [isDescriptionError, setIsDescriptionError] = useState<boolean>(false);
    const errorMsg = {
        message:"Something went wrong. Please try again",
        header: "Update Letter",
        variant: "danger",
        timeoutMs: DEFAULT_TOASTER_TIMEOUT };

    useEffect(() => {
        setIsLoading(true);
        LetterService.getLetter(id).then(response => {
            setLetter(response);
        }).finally(() => setIsLoading(false));
    }, [id]);


    function unlinkItem(id: number) {
        const items = letter.letter_items;
        const size = items.length + selectedProducts.length;
        if (size > 1) {
            items.splice(items.map(product => product.id).indexOf(id), 1);
            setLetter(prevState => ({ ...prevState, letter_items: items }));
        } else {
            setIsError(true);
        }
    }

    function unlinkProduct(id: number) {
        const size = letter.letter_items.length + selectedProducts.length;
        if (size > 1) {
            selectedProducts.splice(selectedProducts.map(product => product.id).indexOf(id), 1);
            setSelectedProducts([...selectedProducts]);
        } else {
            setIsError(true);
        }
    }

    function onSave() {
        if (!letter.description || letter.description === "") {
            setIsDescriptionError(true);
            return;
        }
        const request =  {
            letter: letter,
            product_ids: selectedProducts.map(product => product.id)
        } as SaveLetterRequest;

        LetterService.update(request).then(response => {
            if (response) {
                navigate(WHOLESALER_PATHS.APPOINTMENT.LETTERS);
            }
        }).catch(() => addToast(errorMsg));
    }

    function onFileChange(file: SavedFile) {
        setLetter(prevState => ({
            ...prevState,
            storage_path: file.storagePath,
            file_name:  file.file.name
        }));
    }

    const onDescriptionChange = (value: string) => {
        setIsDescriptionError(value ? value === "" : true);
        setLetter(prevState => ({ ...prevState, description: value }));
    };

    function onSelectNewProduct(products: ProductModel[]) {
        products.length > 0 && setIsError(false);
        setSelectedProducts([...products]);
    }

    return <div className="d-flex flex-column">
        <div className="mb-4 mb-md-5">
            <h1 className="text-primary mb-0">Edit Appointment Letter</h1>
        </div>
        <div className="card">
            <div className="card-body">
                <div className="d-flex flex-column flex-sm-row align-items-center mb-3">
                    <div className="mb-3 me-sm-auto">
                        <h4><strong></strong> Update your letter details</h4>
                        <p className="mb-0"><strong>NOTE:</strong> you can not change schedule type without creating new letter.</p>
                    </div>
                    <input
                        type="button"
                        className="btn btn-primary"
                        value="Link more items"
                        onClick={ () => showAddProductLetterDialog(letter, selectedProducts, onSelectNewProduct) }
                    />
                </div>
                <Spinner hidden={ !isLoading } className="text-center mb-4"/>
                <div className="row mb-2 mb-md-3 align-items-md-center">
                    <div className="col-12 col-md-4 col-lg-2">
                        <p className="mb-0"><strong>File:</strong></p>
                    </div>
                    <div className="col-12 col-md-8 col-lg-10 d-flex align-items-center">
                        <Link to={ LetterService.downloadFile(letter.storage_path, letter.file_name) } className="me-2" target="_blank" download>
                            { letter.file_name }
                        </Link>
                        <div className="choose-dialog-with-link-edit">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={ () => showChooseFileLetterDialog(onFileChange) }
                            ><i className="bi-pencil d-none" title="Update File"></i>Update File</button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-4 col-lg-2">
                        <p className="mb-0 mt-2"><strong>Description:</strong></p>
                    </div>
                    <div className="col-12 col-md-8 col-lg-10 form-group-without-label">
                        <Input
                            name="description"
                            type="text"
                            value={ letter.description }
                            onChange={ (e: ChangeEvent<HTMLInputElement>) => onDescriptionChange(e.target.value) }
                        />
                        <span hidden={ !isDescriptionError }>Description field must be specified.</span>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-12 col-md-4 col-lg-2">
                        <p className="mb-0"><strong>Schedule:</strong></p>
                    </div>
                    <div className="col-12 col-md-8 col-lg-10">
                        <p className="mb-0">
                            { letter?.post_type && ProductService.getScheduleTypeDescription(letter.post_type) }
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-4 col-lg-2">
                        <p className="mb-0"><strong>Linked items:</strong></p>
                    </div>
                    <div className="col-12 col-md-8 col-lg-10">
                        <p className="mb-0">See in the table</p>
                    </div>
                </div>
            </div>
        </div>

        <div className="mt-2">
            <div className="table-responsive mt-4">
                <table className="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Link</th>
                            <th>Wholesaler item ID</th>
                            <th>TTB / CoLA ID #</th>
                            <th>Approval / BLR #</th>
                            <th>Brand name</th>
                            <th>Description</th>
                            <th>Size/ Unit</th>
                            <th>Wholesaler / NYS Supplier ID</th>
                        </tr>
                    </thead>
                    <tbody>
                        { letter.letter_items.map((item: LetterItemModel, index: number) => {
                            return (
                                <tr key={ index }>
                                    <td>
                                        { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions*/ }
                                        <div className="btn btn-sm btn-outline-primary d-flex" onClick={ () => unlinkItem(item.id) } >
                                            <i className="bi-link-45deg me-1"></i>Unlink
                                        </div>
                                    </td>
                                    <td>{ item.prod_item }</td>
                                    <td>{ item.ttb_id }</td>
                                    <td>{ item.brand_reg }</td>
                                    <td>{ item.brand_name }</td>
                                    <td>{ item.prod_name }</td>
                                    <td>{ item.item_size } { item.um }</td>
                                    <td>{ item.distrib_id }</td>
                                </tr>
                            );
                        }) }
                        { selectedProducts.map((item: ProductModel, index: number) => {
                            return (
                                <tr key={ index }>
                                    <td>
                                        { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions*/ }
                                        <div className="btn btn-sm btn-outline-primary d-flex" onClick={ () => unlinkProduct(item.id) } >
                                            <i className="bi-link-45deg me-1"></i>Unlink
                                        </div>
                                    </td>
                                    <td>{ item.prod_item }</td>
                                    <td>{ item.ttb_id }</td>
                                    <td>{ item.brand_reg }</td>
                                    <td>{ item.brand_name }</td>
                                    <td>{ item.prod_name }</td>
                                    <td>{ item.item_size } { item.um }</td>
                                    <td>{ item.distrib_id }</td>
                                </tr>
                            );
                        }) }
                    </tbody>
                </table>
            </div>
        </div>

        <div className="mt-3">
            { isError &&
                <div className="invalid-message alert alert-sm alert-danger mb-4">
                    <p className="mb-0">Should be specified at least one product</p>
                </div>
            }
            <div className="row justify-content-center">
                <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                    <Link
                        to={ WHOLESALER_PATHS.APPOINTMENT.LETTERS }
                        className="btn btn-outline-secondary w-100"
                    >Cancel</Link>
                </div>
                <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                    <input
                        className="btn btn-primary w-100"
                        type="button"
                        onClick={ onSave }
                        value="Save"
                        disabled={ isLoading || isDescriptionError }
                    />
                </div>
            </div>
        </div>
    </div>;
};

export default UpdateLetter;

