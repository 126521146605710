import React from "react";
import { UserLicenseDTO, UserModel } from "models/UserModel";
import MultiSelectRoles from "components/form/select/MultiSelectRoles";
import LicenseBar from "pages/admin/components/LicenseBar";
import { Control } from "react-hook-form/dist/types/form";
import { FieldErrors } from "react-hook-form/dist/types/errors";

/*
    Can operate in 2 modes:
    - single license mode - (when "licenseIdForSingleMode" is set)
        -- BA can use only this mode
        -- "actions" column is hidden
        -- roles in licenses (that do not correspond to licenseIdForSingleMode) are not editable
    - unrestricted mode
        -- intended for SLA_ADMIN / ADMIN
*/

type Props = { fields: UserLicenseDTO[], control: Control<UserModel>, errors: FieldErrors<UserModel> } &
    ({ deleteLicense: (ul:UserLicenseDTO)=> void } | { licenseIdForSingleMode: number });

const EditLicensesTable = ({ fields, control, errors, ...rest }: Props) => {

    return <div className="mb-4 table-responsive-lg">
        <table className="table table-bordered table-hover">
            <thead>
                <tr>
                    <th>License(s)</th>
                    <th>Roles</th>
                    { ("deleteLicense" in rest) && <th>Actions</th> }
                </tr>
            </thead>
            <tbody>
                { fields.map( (ul, idx) => (
                    <tr key={ idx }>
                        <td className="no-icons">
                            <LicenseBar license={ ul } isMulti={ fields.length != 1 }/>
                        </td>
                        <td>
                            <MultiSelectRoles
                                name={ `licenses[${idx}].roles` } control={ control } errors={ errors }
                                isDisabled={ "licenseIdForSingleMode" in rest && rest.licenseIdForSingleMode !== ul.id }
                                roles={ ul.available_roles || [] }
                                menuPlacement="bottom"/>
                        </td>
                        { ("deleteLicense" in rest) &&
                            <td className="text-nowrap">
                                <button className="btn btn-link py-0 px-1" title="Users" onClick={ ()=> rest.deleteLicense(ul) }>
                                    <i className="bi-trash me-1"></i> Delete license
                                </button>
                            </td>
                        }
                    </tr>
                )) }
            </tbody>
        </table>
    </div>;
};

export default EditLicensesTable;
