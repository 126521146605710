import React, { useEffect, useState } from "react";
import { ProductModel } from "models/ProductModel";
import ProductSearchForm from "pages/wholesaler/appointment/components/ProductSearchForm";
import { LetterModel } from "models/LetterModel";
import { modalStore } from "store/ModalStoreProvider";

export interface Props {
    letter: LetterModel,
    initialProducts: ProductModel[],
    onSave: any
}

export const showAddProductLetterDialog = ( letter: LetterModel, initialProducts: ProductModel[], onSave: any) => {
    modalStore.setSize("xl");
    modalStore.setBody(<AddProductLetterDialog letter={ letter } initialProducts={ initialProducts } onSave={ onSave }/>);
    modalStore.setHeader("Link more items");
    modalStore.showModal();
};

const AddProductLetterDialog = ({ letter, initialProducts, onSave }: Props) => {

    const [selectedProducts, setSelectedProducts] = useState<ProductModel[]>([...initialProducts]);

    useEffect(() => {
        setSelectedProducts(initialProducts);
    }, [initialProducts]);

    function linkProduct(product: ProductModel) {
        selectedProducts.push(product);
        setSelectedProducts([...selectedProducts]);
    }

    function unLinkProduct(product: ProductModel) {
        selectedProducts.splice(selectedProducts.indexOf(product), 1);
        setSelectedProducts([...selectedProducts]);
    }

    function onSaveDialog() {
        onSave(selectedProducts);
        modalStore.hideModal();
    }
    modalStore.setAdditionalButtons([
        <button key={ 0 } type="button" className="btn btn-primary" onClick={ onSaveDialog }>Save</button>
    ]);

    return  <ProductSearchForm
        initProducts={ selectedProducts }
        letter={ letter }
        onSelect={ linkProduct }
        onUnselect={ unLinkProduct }
        isDialog={ true }
    />;
};
