import { ProductModel } from "models/ProductModel";
import React from "react";
import ErroneousValue from "components/ErroneousValue";
import { TabModel } from "components/product-tab-widget/ProductTabContainer";
import classNames from "classnames";
import StringUtils from "utils/StringUtils";

const DISPLAYED_PROPERTIES = ["nys_whole","nys_item","nys_prod"];
const NYSSupplierTabHeader = (product: ProductModel) => {
    const hasErrors = (p: ProductModel) =>
        StringUtils.hasAnyProperty(p.product_errors, DISPLAYED_PROPERTIES);

    return <span className = { classNames(hasErrors(product) ? "text-danger" : "" ) }>
        { hasErrors(product) && <i className="bi bi-exclamation-circle-fill me-2"></i> }
        NYS Supplier Details
    </span>;
};

const NYSSupplierTabBody = (product: ProductModel) => {
    return  <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
        <h5 className="mb-3 fw-bold">NYS supplier details</h5>
        <p className="mb-2"><strong>NYS label:&nbsp;</strong><ErroneousValue item={ product } name={ "nys_prod" } /></p>
        { (product.post_type === "LR" || product.post_type === "WR") &&
            <>
                <p className="mb-2"><strong>NYS supplier:&nbsp;</strong><ErroneousValue item={ product } name={ "nys_whole" }/></p>
                <p className="mb-2"><strong>NYS supplier item #:&nbsp;</strong><ErroneousValue item={ product } name={ "nys_item" }/></p>
            </>
        }
        <p className="mb-2"><strong>{ (product.post_type === "LR" || product.post_type === "WR") ? "Your Item #" : "NYS supplier item #" }:&nbsp;</strong><ErroneousValue item={ product } name={ "prod_item" } /></p>
    </div>;
};

export const NYSSupplierTab: TabModel = { header: NYSSupplierTabHeader, body: NYSSupplierTabBody };
