import React from "react";
import { AlertModel } from "models/AlertModel";

interface Props {
    alert: AlertModel,
    hideAlert: (id: number) => void
}

export const Alert = ({ alert, hideAlert } : Props) => {

    function getMessageClassName(messageType: string): string {
        switch (messageType) {
        case "NOTIFICATION":
            return "alert-success";
        case "WARNING":
            return "alert-warning";
        default:
            return "alert-danger";
        }
    }

    function getAlignClassName(alignType: string): string {
        switch (alignType) {
        case "CENTER":
            return "center";
        default:
            return "left";
        }
    }

    return <div
        className={ "alert-cmp alert mb-4 rounded-0 d-flex align-items-center " + getMessageClassName(alert.message_type) + " " + getAlignClassName(alert.align_type) }
        key={ alert.id } role="alert">
        <i className="bi bi-exclamation-circle-fill d-flex align-items-center"></i>
        <div dangerouslySetInnerHTML={ { __html: alert.message } }/>
        { alert.visibility != "AUTH" &&
            <div className="alert-cmp-btn"><button className="btn btn-link text-nowrap" onClick={ () => hideAlert(alert.id) }>Don&apos;t show again</button></div>
        }
    </div>;
};
