import React from "react";
import nyGovLogoFooter from "assets/img/nygov-logo-white.svg";
import { useShowHideStore } from "store/ShowHideStoreProvider";
import { observer } from "mobx-react";

const Footer = () => {
    const { isAllHidden } = useShowHideStore();

    return (
        <div className="mt-auto">
            <footer className="" hidden={ isAllHidden }>
                <div className="container-fluid">
                    <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center">
                        <div className="col-12 col-lg-2 mb-4 mb-lg-0">
                            <img src={ nyGovLogoFooter } alt="New York State Home"/>
                        </div>
                        <div className="col-12 col-lg-10">
                            <ul className="list-unstyled d-flex flex-wrap align-items-center justify-content-start">
                                <li>
                                    <a href="/accessibility">Accessibility</a>
                                </li>
                                <li>
                                    <a href="/agencies">Agencies</a>
                                </li>
                                <li>
                                    <a href="/mobileapps">App Directory</a>
                                </li>
                                <li>
                                    <a href="/counties">Counties</a>
                                </li>
                                <li>
                                    <a href="/website-disclaimer">Disclaimer</a>
                                </li>
                                <li>
                                    <a href="/events">Events</a>
                                </li>
                                <li>
                                    <a href="/language-access-policy">Language Access</a>
                                </li>
                                <li>
                                    <a href="/privacy-policy">Privacy Policy</a>
                                </li>
                                <li>
                                    <a href="/programs">Programs</a>
                                </li>
                                <li>
                                    <a href="/services">Services</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="container-fluid mt-4">
                    <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center pt-2">
                        <div className="col-12 col-lg-2 mb-4 mb-lg-0 pe-lg-3">
                            <i className="bi bi-globe me-2"></i><a className="fw-bold" href="https://www.ny.gov/web-translation-services">Translation Services</a>
                        </div>
                        <div className="col-12 col-lg-10">
                            <p className="mb-0">This page is available in other languages</p>
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-lg-row align-items-center justify-content-end mt-3">
                        <div className="col-12 col-lg-10">
                            <ul className="list-unstyled d-flex flex-wrap align-items-center justify-content-start">
                                <li>
                                    <a translate="no" lang="en" href="https://ny.gov/homepage">English</a>
                                </li>
                                <li>
                                    <a translate="no" lang="es" href="https://es.ny.gov/homepage">Español</a>
                                </li>
                                <li>
                                    <a translate="no" lang="zh" href="https://zh.ny.gov/homepage">中文</a>
                                </li>
                                <li>
                                    <a translate="no" lang="ru" href="https://ru.ny.gov/homepage">Русский</a>
                                </li>
                                <li>
                                    <a translate="no" lang="yi" href="https://yi.ny.gov/homepage">יידיש</a>
                                </li>
                                <li>
                                    <a translate="no" lang="bn" href="https://bn.ny.gov/homepage">বাঙালি</a>
                                </li>
                                <li>
                                    <a translate="no" lang="ko" href="https://ko.ny.gov/homepage">한국어</a>
                                </li>
                                <li>
                                    <a translate="no" lang="ht" href="https://ht.ny.gov/homepage">Kreyòl Ayisyen</a>
                                </li>
                                <li>
                                    <a translate="no" lang="it" href="https://it.ny.gov/homepage">Italiano</a>
                                </li>
                                <li>
                                    <a translate="no" lang="ar" href="https://ar.ny.gov/homepage">العربية</a>
                                </li>
                                <li>
                                    <a translate="no" lang="pl" href="https://pl.ny.gov/homepage">Polski</a>
                                </li>
                                <li>
                                    <a translate="no" lang="fr" href="https://fr.ny.gov/homepage">Français</a>
                                </li>
                                <li>
                                    <a translate="no" lang="ur" href="https://ur.ny.gov/homepage">اردو</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default observer(Footer);
