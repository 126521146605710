import React from "react";
import Input from "components/form/Input";
import Form from "react-bootstrap/Form";

const WholesalerCertify2View = () => {

    return <div className="d-flex flex-column">
        <div className="mb-4 mb-md-5">
            <h1 className="text-primary mb-0">Certify - Liquor to Retailer Schedule for November 2022</h1>
        </div>
        <div>
            <div className="steps-wr mb-4">
                <div className="steps-l row g-0">
                    <div className="steps-li col-12 col-xl-3">
                        <div className="steps-li-in">
                            <strong>1</strong> Edit Posting Profile
                        </div>
                    </div>
                    <div className="steps-li col-12 col-xl-3 active">
                        <div className="steps-li-in">
                            <strong>2</strong> Certify Schedule
                        </div>
                    </div>
                    <div className="steps-li col-12 col-xl-3">
                        <div className="steps-li-in">
                            <strong>2</strong> Confirmation
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-2 mb-3 mb-md-4">
                <h4><strong>Step 2:</strong> Certify Schedule</h4>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="mb-4">
                        <h4 className="mb-3">Certification</h4>
                    </div>
                    <div className="content-styles">
                        <p>
                            By pressing the &quot;Certify Price Posting&quot; button below, you agree to the following:
                        </p>
                        <ul>
                            <li>
                                I state, under penalty of perjury, that all statements herein are true to the best of my knowledge and belief,
                                and all prices posted herein reflect actual prices to be invoiced to licensees.
                                I understand that any price posting or statement made herein which is false may constitute perjury and may
                                subject the licensee to revocation, cancellation, or other penalty.
                            </li>
                            <li>
                                Prices may include a decimal point.
                                For numeric fields, use of dollar signs, commas or other non-numeric entry will cause batch price errors.
                                It is your responsibility to review for such errors before certifying.
                            </li>
                            <li>
                                By certifying on behalf of license number 9999999 as a &quot;Master Filer&quot; on behalf of the following licenses 1300234, 1300235,
                                I attest under penalty of perjury that all said licenses share common ownership and that I have the permission of the license principal(s) to file on behalf of all said licenses as &quot;Master Filer&quot;.
                            </li>
                        </ul>
                    </div>
                    <form action="" className="mt-4 pt-2">
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <p className="mb-1"><strong>Your name</strong></p>
                                <p>Aurel Surname</p>
                            </div>
                            <div className="col-12 col-lg-6">
                                <p className="mb-1"><strong>Your title</strong></p>
                                <p>title (admin user)</p>
                            </div>
                        </div>
                        <div className="row mt-1">
                            <div className="col-12 col-lg-6 col-xl-4">
                                <Input name="name" placeholder="Email address" label="Email address" type="email" />
                            </div>
                        </div>
                    </form>
                    <div className="row justify-content-center mt-3">
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                            <a href="/wholesaler-static/certify" className="btn btn-outline-secondary w-100">Back</a>
                        </div>
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                            <a href="/wholesaler-static/certify3" className="btn btn-primary w-100">Certify Schedule</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default WholesalerCertify2View;

