import httpClient from "api/HttpClient";
import { ForgotPasswordModel } from "models/ForgotPasswordModel";
import { SetNewPasswordRequest } from "models/requests/SetNewPasswordRequest";
import BACKEND from "services/Constants";
import { ConfirmWholesalerInvitationRequest } from "models/requests/ConfirmWholesalerInvitationRequest";

export default {

    resetPassword(request: ForgotPasswordModel): Promise<void> {
        return httpClient.post(BACKEND + "/user/public/reset-password", request);
    },
    setNewPassword(request: SetNewPasswordRequest): Promise<void> {
        return httpClient.post(BACKEND + "/user/public/set-new-password", request);
    },

    resendConfirmationEmail(email: string): Promise<void> {
        return httpClient.post(BACKEND + "/user/public/resend-confirmation-email", undefined, { params: { email: email } });
    },

    confirmInvitation(request: ConfirmWholesalerInvitationRequest): Promise<void> {
        return httpClient.post(BACKEND + "/user/public/confirmInvitation", request);
    }

};
