import { showFilingDialog } from "components/dialogs/FilingDialog";
import { showViewingDialog } from "components/dialogs/ViewingDialog";
import React from "react";

export const FillingPeriodInformation= () => {
    return <p className="mb-0">
        For more information:&nbsp;
        <button className="btn btn-link" onClick={ showFilingDialog }>Filing Period Schedules and Deadlines</button>
        &nbsp;and&nbsp;
        <button className="btn btn-link" onClick={ showViewingDialog }>Viewing Period Schedules and Deadlines</button>
    </p>;
};
