import React, { Fragment, useEffect, useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { SubmitHandler } from "react-hook-form";
import URLUtil from "utils/URLUtil";
import { PageInfo } from "models/PageInfo";
import { BrandsSearchForm } from "components/BrandsSearchForm";
import { BrandsProductList } from "components/BrandsProductList";
import { BrandsFormModel } from "pages/wholesaler/priceposting/models/BrandsFormModel";
import Paginator from "components/Paginator";
import { PageableRequest } from "models/requests/PageableRequest";
import { ProductModel } from "models/ProductModel";
import Spinner from "components/Spinner";
import { useToastStore } from "store/ToastStoreProvider";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";
import SchedulesService from "services/admin/SchedulesService";
import { ADMIN_PATHS } from "pages/admin/AdminRoutes";
import ProductConstants from "services/ProductConstants";
import { Sorting } from "models/Sorting";
import { PageableResponse } from "models/response/PageableResponse";
import { ToastDescription } from "store/ToastStore";
import DateUtil from "utils/DateUtil";

type SearchParams = BrandsFormModel & PageInfo & { sortings: Sorting[]};

const BrandsItems = () => {
    const navigate = useNavigate();
    const { addToast } = useToastStore();
    const { type = "", year = "", month = "", licenseId = "" } = useParams();
    const [ response, setResponse ] = useState<PageableResponse<ProductModel>>({} as PageableResponse<ProductModel>);
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ isRecordsPresent, setIsRecordsPresent ] = useState<boolean>(false);
    const [ urlData, setUrlData ] = useState<SearchParams>(URLUtil.readSearchParams() as SearchParams);

    useEffect(() => {
        SchedulesService.isRecordsPresent(licenseId, type, year, month).then(response => {
            setIsRecordsPresent(response);
        }).catch(() => addToast(errorMsg));
    },[]);

    useEffect(() => {
        URLUtil.updateUrlParams(generatePath(ADMIN_PATHS.LICENSES.UPCOMING_SCHEDULES.BRANDS_ITEMS,
            { "licenseId": licenseId, "type":type, "year":year, "month": month }), urlData, navigate);
        performQuery(urlData);
    },[urlData]);

    const onSubmit: SubmitHandler<BrandsFormModel> = async data => {
        const searchParams = data as SearchParams;
        searchParams.page = 1;
        searchParams.sortings = urlData.sortings;
        setUrlData(searchParams);
    };

    function handlePageClick(pageNumber: number) {
        setUrlData({ ...urlData, page: pageNumber });
    }

    function performQuery(params: SearchParams) {
        const request = {
            pageInfo: { page: params.page || 1 },
            sortings: params.sortings,
            filters:{
                post_type: type,
                year: year,
                month: month,
                brand_name: params.brand_name,
                prod_name: params.prod_name,
                prod_item: params.prod_item,
                brand_reg: params.brand_reg,
                ttb_id: params.ttb_id,
                beverage_type: params.beverage_type,
                reg_combo: params.reg_combo,
                show: params.show
            }
        } as PageableRequest;

        setIsLoading(true);
        SchedulesService.search(request, licenseId)
            .then(response => {
                setResponse(response);
            })
            .catch(() => addToast(errorMsg))
            .finally(() =>  setIsLoading(false));
    }

    const errorMsg: ToastDescription = {
        message:"Something went wrong. Please try again",
        header: "Get data",
        variant: "danger",
        timeoutMs: DEFAULT_TOASTER_TIMEOUT };

    return <div className="d-flex flex-column">
        <div className="mb-4 mb-md-5">
            <h1 className="text-primary mb-0">{ ProductConstants.postTypeDescription(type) } - Schedule for { DateUtil.monthNumberToName(parseInt(month)-1) } { year }</h1>
        </div>
        <div>
            <div className="row mb-3 mb-md-4">
                <div className="col-12 col-lg-6">
                    <h5 className="mb-2 fw-bold">Manage Brands & Items</h5>
                    <p>On this page you can search and manage all your brands and items.</p>
                </div>
            </div>
            <div className="card mb-3">
                <div className="card-body">
                    <BrandsSearchForm postType={ type } filters={ urlData } onSubmit={ onSubmit } />
                </div>
            </div>
            { isRecordsPresent && !isLoading &&
                <Fragment>
                    <BrandsProductList 
                        products={ response?.data }
                        writePermitted={ false } 
                        reloadPage={ () => handlePageClick(response?.pageInfo?.page || 1) }
                        onSorting={ (sortings: Sorting[]) => setUrlData({ ...urlData, sortings: sortings }) }
                        sortings={ urlData?.sortings }
                        postType={ type }
                        month={ month }
                        year={ year }
                        actions={ [] }/>
                    <Paginator
                        totalPages={ response?.pageInfo?.pagesCount || 1 }
                        currentPage={ response?.pageInfo?.page || 1 }
                        pageClicked={ handlePageClick }/>
                </Fragment>
            }

            <Spinner hidden={ !isLoading } className="mt-4 text-center" />

            { !isRecordsPresent && !isLoading &&
                <div className="text-center">
                    <p>There are no records.</p>
                </div>
            }
        </div>
    </div>;
};

export default BrandsItems;

