import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { SelectControlled, SelectOption } from "components/form/Select";
import { ControlledFormSwitch } from "components/form/ControlledFormSwitch";
import { DISCOUNT_SOURCES, TabName } from "pages/wholesaler/priceposting/models/ManualProduct";
import EditDiscountValues, { Appendable } from "pages/wholesaler/priceposting/components/EditDiscountValues";
import { useFormContext } from "react-hook-form";
import { Discount } from "models/Discount";
import DiscountService from "services/wholesaler/DiscountService";
import * as _ from "lodash";
import { PageableRequest } from "models/requests/PageableRequest";

export type DiscountTabHandler = {
    removeDiscounts: () => void;
}

const DiscountTab = forwardRef(( { rootName }: { rootName :string }, forwardedRef)=>{
    const { control, watch, setValue, clearErrors, formState:{ errors } } = useFormContext();
    const setDValues = (v: any) => setValue(`${rootName}.discount_values`, v);
    const setDId     = (v: any) => setValue(`${rootName}.discount_id`, v);
    const setDCode   = (v: any) => setValue(`${rootName}.disc_code`, v);

    const [discounts, setDiscounts] = useState<Discount[] | undefined>(undefined);
    const [discountCodes, setDiscountCodes] = useState<SelectOption[]>([]);
    const dvControl = useRef<Appendable>();
    useImperativeHandle(forwardedRef, () => ({
        removeDiscounts() {
            setDValues(undefined);
            setDId(null);
            setDCode(undefined);
        }
    }));

    const discount_source = watch(`${rootName}.discount_source`);
    const discount_id = watch(`${rootName}.discount_id`);
    const active_tab = watch(`${rootName}.active_tab`);

    const discountIdChanged = ()=>{
        if (discount_id === undefined){
            setDId (null);//to reset select tag
        }
        else if (discount_source === DISCOUNT_SOURCES.SELECT && discounts !== undefined) {
            const d = discounts.find(v => v.id === discount_id);
            clearErrors(`${rootName}.discount_values`);
            setDValues(d?.values);
            setDCode(d?.discount_code);
        }
    };

    const discountSourceChanged = ()=>{
        if (discount_source === DISCOUNT_SOURCES.CREATE) {
            if (discount_id !== undefined && discount_id !== null){
                setDValues(undefined);
            }
            setDId(null);
            setDCode(undefined);
        }
        else if (discount_id === undefined || discount_id === null){
            setDValues(undefined);
        }
    };

    useEffect(()=>{
        DiscountService.list({ pageInfo: { page:1, limit: 100 } } as PageableRequest)
            .then(r=>setDiscounts(r.data));
    },[]);
    useEffect(()=>{
        discountSourceChanged();
        discountIdChanged();
        setDiscountCodes(_.concat(
            { value: undefined, title:"Select..." },
            discounts ? discounts.map(v=>({ value: v.id, title: v.discount_code })) : []
        ));
    },[discounts]);
    useEffect(discountSourceChanged,[discount_source]);
    useEffect(discountIdChanged,[discount_id]);

    return <div>
        <div className="switch-header mb-4 fw-bold">
            <label className="form-check-label h5" htmlFor={ `dsc_src${rootName}` }>Select discount</label>
            <ControlledFormSwitch id={ `dsc_src${rootName}` } control={ control } name={ `${rootName}.discount_source` }
                label={ "Create new discount" } options={ [DISCOUNT_SOURCES.CREATE, DISCOUNT_SOURCES.SELECT] }/>
        </div>
        <div className="mb-4">
            {
                discount_source === DISCOUNT_SOURCES.SELECT
                    ? (<>
                        <div className="row">
                            <div className="col-12 col-xl-6">
                                <div className="pe-xl-4">
                                    <SelectControlled name={ `${rootName}.discount_id` } label={ "Discount code" }
                                        options={ discountCodes } control={ control } valueAsInteger={ true } />
                                </div>
                            </div>
                        </div>
                        <EditDiscountValues ref={ dvControl } valuesName={ `${rootName}.discount_values` } control={ control } errors={ errors } readonly={ true }/>
                    </>)
                    : (<div>
                        <button className="btn btn-primary mb-3" onClick={ () => dvControl.current?.addDefault() }>Add new discount</button>
                        <EditDiscountValues
                            ref={ dvControl } valuesName={ `${rootName}.discount_values` } control={ control }
                            errors={ errors } isTabDiscounts={ active_tab === TabName.DISCOUNTS }/>
                    </div>)
            }
        </div>
    </div>;
});

DiscountTab.displayName = "DiscountTab";

export default DiscountTab;
