import React, { useEffect, useState } from "react";
import OrganizationService from "services/OrganizationService";
import { modalStore } from "store/ModalStoreProvider";
import { LicenseModel } from "models/license.model";
import Spinner from "components/Spinner";

interface Props {
    licenseId: number;
    month: number,
    year: number,
    postType: string;
}

export const showDistributorInfo = (licenseId: number, month: number, year: number, postType: string) => {
    modalStore.setSize("xl");
    modalStore.setHeader("Distributor's information");
    modalStore.setBody(<DistributorInfoDialog licenseId={ licenseId } month={ month } year={ year } postType={ postType }/>);
    modalStore.showModal();
};

const DistributorInfoDialog = (props: Props) => {

    const { licenseId, month, year, postType } = props;
    const [license, setLicense] = useState<LicenseModel>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        OrganizationService.getFullDistributorInfo(licenseId, month, year, postType).then(distributor => {
            setLicense(distributor);
        }).finally(() => setIsLoading(false));
    }, []);

    return <div>
        { !isLoading &&
            <div>
                <div className="">
                    <div className="mb-4">
                        <h5 className="fw-bold mb-3">License information</h5>
                        <hr/>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-5 col-xl-3">
                            <p>
                                <strong>License ID:</strong>
                            </p>
                        </div>
                        <div className="col-12 col-md-7 col-xl-9">
                            <p>{ license?.permit_id }</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-5 col-xl-3">
                            <p>
                                <strong>Serial Number:</strong>
                            </p>
                        </div>
                        <div className="col-12 col-md-7 col-xl-9">
                            <p>{ license?.serial_number }</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-5 col-xl-3">
                            <p>
                                <strong>Premises name:</strong>
                            </p>
                        </div>
                        <div className="col-12 col-md-7 col-xl-9">
                            <p>{ license?.premise_name }</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-5 col-xl-3">
                            <p>
                                <strong>Premises address:</strong>
                            </p>
                        </div>
                        <div className="col-12 col-md-7 col-xl-9">
                            <p>{ license?.premise_address }</p>
                        </div>
                    </div>
                </div>
                <div className="mt-4">
                    <div className="mb-4">
                        <h5 className="fw-bold mb-3">Profile information</h5>
                        <hr/>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-5 col-xl-3">
                            <p>
                                <strong>Discount for time payment:</strong>
                            </p>
                        </div>
                        <div className="col-12 col-md-7 col-xl-9">
                            <p>{ license?.profile_term?.pay_disc == 1 ? "1% for payment within 10 days of delivery" : "None" }</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-5 col-xl-3">
                            <p>
                                <strong>Terms of Sale:</strong>
                            </p>
                        </div>
                        <div className="col-12 col-md-7 col-xl-9">
                            <p>{ license?.profile_term?.terms_sale }</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-5 col-xl-3">
                            <p>
                                <strong>Other Information:</strong>
                            </p>
                        </div>
                        <div className="col-12 col-md-7 col-xl-9">
                            <p>{ license?.profile_term?.other_info }</p>
                        </div>
                    </div>
                </div>
            </div>
        }
        { isLoading && <Spinner className="mt-4 pt-2 text-center" /> }
    </div>;
};

