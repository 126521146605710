import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import LicenseInfo from "components/LicenseInfo";
import RetailerDelinquentRecords from "components/RetailerDelinquentRecords";
import ReturnToSearchLink from "pages/admin/components/ReturnToSearchLink";
import { LicenseModel, isActiveLicense } from "models/license.model";
import { showDeleteCODConfirmation } from "components/dialogs/DeleteCodDataDialog";
import { useAuthStore } from "store/AuthStoreProvider";
import { Reloadable } from "components/WholesalerDelinquentRecords";

export const CodDataRetailer = () => {

    const { permissionStore } = useAuthStore();
    const { licenseId = "" } = useParams();
    const licenseIdNum = parseInt(licenseId);
    const [license, setLicense] = useState<LicenseModel>();
    const recordsRef = useRef<Reloadable>(null);

    const reloadRecords = ()=>{
        recordsRef.current?.reload();
    };

    return <div className="d-flex flex-column">
        <div className="mb-4 pb-md-3 d-flex flex-column flex-md-row align-items-center">
            <div className="order-0 order-md-1">
                <ReturnToSearchLink/>
            </div>
            <h1 className="text-primary mb-3 mb-md-0 mt-3 mt-md-0 me-md-auto order-1 order-md-0">Delinquent Data</h1>
        </div>
        <LicenseInfo licenseId={ licenseIdNum } showDelinquentStatus={ true } onLoadLicense={ setLicense }/>

        <RetailerDelinquentRecords licenseId={ licenseIdNum } ref={ recordsRef }>
            { (permissionStore.isSlaAdminOrAdmin()) &&
                <div className="ms-3 mb-2">
                    <button type="button" className="btn btn-danger"
                        disabled={ license ? isActiveLicense(license) : true }
                        onClick={ () => showDeleteCODConfirmation(license ?? { } as LicenseModel, reloadRecords ) }>
                        Delete COD Data
                    </button>
                </div>
            }
        </RetailerDelinquentRecords>
    </div>;

};
