import React, { useState } from "react";
import StepsWidget from "pages/wholesaler/appointment/components/StepsWidget";
import Step3, { SavedFile } from "pages/wholesaler/appointment/components/Step3";
import Step1 from "pages/wholesaler/appointment/components/Step1";
import Step2 from "pages/wholesaler/appointment/components/Step2";
import Step4 from "pages/wholesaler/appointment/components/Step4";
import { WHOLESALER_PATHS } from "pages/wholesaler/WholesalerRoutes";
import LetterService from "services/wholesaler/LetterService";
import { SaveLetterRequest } from "pages/wholesaler/appointment/models/SaveLetterRequest";
import { LetterModel } from "models/LetterModel";
import { ProductModel } from "models/ProductModel";
import { useToastStore } from "store/ToastStoreProvider";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";
import { useNavigate } from "react-router-dom";

const CreateLetter = () => {

    const { addToast } = useToastStore();
    const navigate = useNavigate();
    const [step, setStep] = useState<number>(1);
    const [letter, setLetter] = useState<LetterModel>({
        post_type: "",
        description: "",
        file_name: "",
        storage_path: "",
        letter_items: []
    });
    const [products, setProducts] = useState<ProductModel[]>([]);
    const [savedFile, setSavedFile] = useState<SavedFile>();
    const errorMsg = {
        message:"Something went wrong. Please try again",
        header: "Create Letter",
        variant: "danger",
        timeoutMs: DEFAULT_TOASTER_TIMEOUT };

    function onSuccessStep1(changedLetter: LetterModel) {
        setLetter(changedLetter);
        setStep(2);
    }

    function onSuccessStep2(selectedProducts: ProductModel[]) {
        setProducts(selectedProducts);
        setStep(3);
    }

    function onSuccessStep3(file: SavedFile) {
        setSavedFile(file);
        setLetter(prevState => ({
            ...prevState,
            file_name: file.file.name,
            storage_path: file.storagePath
        }));
        setStep(4);
    }

    function onSuccessStep4(selectedProducts: ProductModel[]) {

        setProducts(selectedProducts);
        const request =  {
            letter: letter,
            letter_item_ids: [],
            product_ids: products.map(product => product.id)
        } as SaveLetterRequest;

        LetterService.save(request).then(response => {
            if (response) {
                navigate(WHOLESALER_PATHS.APPOINTMENT.LETTERS);
            }
        }).catch(() => addToast(errorMsg));
    }

    return <div className="d-flex flex-column">
        <div className="mb-4 mb-md-5">
            <h1 className="text-primary mb-4">Create Appointment Letter</h1>
            <p className="mb-0">Adding a new appointment letter is 4-steps process:</p>
        </div>
        <StepsWidget step={ step }/>
        { step === 1 &&
            <Step1
                letter={ letter }
                onSuccess={ onSuccessStep1 }
            />
        }
        { step === 2 &&
            <Step2
                initialProducts={ products }
                letter={ letter }
                onSuccess={ onSuccessStep2 }
                onBack={ () =>  setStep(1) }
            />
        }
        { step === 3 &&
            <Step3
                file={ savedFile }
                letter={ letter }
                onSuccess={ onSuccessStep3 }
                onBack={ () =>  setStep(2) }
            />
        }
        { step === 4 &&
            <Step4
                initProducts={ products }
                letter={ letter }
                onSuccess={ onSuccessStep4 }
                onBack={ () =>  setStep(3) }
            />
        }
    </div>;
};

export default CreateLetter;

