import React from "react";

const WholesalerCertify3View = () => {

    return <div className="d-flex flex-column">
        <div className="mb-4 mb-md-5">
            <h1 className="text-primary mb-0">Certify - Liquor to Retailer Schedule for November 2022</h1>
        </div>
        <div>
            <div className="steps-wr mb-4">
                <div className="steps-l row g-0">
                    <div className="steps-li col-12 col-xl-3">
                        <div className="steps-li-in">
                            <strong>1</strong> Edit Posting Profile
                        </div>
                    </div>
                    <div className="steps-li col-12 col-xl-3">
                        <div className="steps-li-in">
                            <strong>2</strong> Certify Schedule
                        </div>
                    </div>
                    <div className="steps-li col-12 col-xl-3 active">
                        <div className="steps-li-in">
                            <strong>2</strong> Confirmation
                        </div>
                    </div>
                </div>
            </div>
            <div className="pt-2 mb-3 mb-md-4">
                <h4><strong>Step 3:</strong> Confirmation</h4>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="mb-4">
                        <h4 className="mb-3 d-flex flex-column flex-sm-row align-items-center">
                            <span className="mb-2 mb-sm-0"><strong className="me-2">Date:</strong> 11/22/2022</span> <span className="bi-line mx-3 d-none d-sm-inline-block">&nbsp;</span> 03:06:50 AM
                        </h4>
                    </div>
                    <div className="content-styles">
                        <p>
                            This is the confirmation of the new posting system.
                        </p>
                        <p>
                            The following price posting schedule has been submitted to the State Liquor Authority.
                        </p>
                        <div className="mb-4 mt-4 pt-2">
                            <div className="row mb-2 mb-lg-0">
                                <div className="col-12 col-lg-4 col-xl-3">
                                    <p className="mb-2"><strong>Confirmation Number:</strong></p>
                                </div>
                                <div className="col-12 col-lg-8 col-xl-9">
                                    <p className="mb-2">SLA20221122030650851390329</p>
                                </div>
                            </div>
                            <div className="row mb-2 mb-lg-0">
                                <div className="col-12 col-lg-4 col-xl-3">
                                    <p className="mb-2"><strong>License Serial Number:</strong></p>
                                </div>
                                <div className="col-12 col-lg-8 col-xl-9">
                                    <p className="mb-2">9999999</p>
                                </div>
                            </div>
                            <div className="row mb-2 mb-lg-0">
                                <div className="col-12 col-lg-4 col-xl-3">
                                    <p className="mb-2"><strong>Posting Type:</strong></p>
                                </div>
                                <div className="col-12 col-lg-8 col-xl-9">
                                    <p className="mb-2">Liquor to Retailer</p>
                                </div>
                            </div>
                            <div className="row mb-2 mb-lg-0">
                                <div className="col-12 col-lg-4 col-xl-3">
                                    <p className="mb-2"><strong>Total Items:</strong></p>
                                </div>
                                <div className="col-12 col-lg-8 col-xl-9">
                                    <p className="mb-2">1</p>
                                </div>
                            </div>
                            <div className="row mb-2 mb-lg-0">
                                <div className="col-12 col-lg-4 col-xl-3">
                                    <p className="mb-2"><strong>Effective Date:</strong></p>
                                </div>
                                <div className="col-12 col-lg-8 col-xl-9">
                                    <p className="mb-2">January 2023</p>
                                </div>
                            </div>
                            <div className="row mb-2 mb-lg-0">
                                <div className="col-12 col-lg-4 col-xl-3">
                                    <p className="mb-2"><strong>Submitted By:</strong></p>
                                </div>
                                <div className="col-12 col-lg-8 col-xl-9">
                                    <p className="mb-2">Last Name, Aurel title (Admin User)</p>
                                </div>
                            </div>
                            <div className="row mb-2 mb-lg-0">
                                <div className="col-12 col-lg-4 col-xl-3">
                                    <p className="mb-2"><strong>This certification applies to the following licenses:</strong></p>
                                </div>
                                <div className="col-12 col-lg-8 col-xl-9 d-flex align-items-center">
                                    <p className="mb-2">1300234, 1300235</p>
                                </div>
                            </div>
                            <div className="row mb-2 mb-lg-0">
                                <div className="col-12 col-lg-4 col-xl-3">
                                    <p className="mb-2"><strong>A confirmation email will be sent to:</strong></p>
                                </div>
                                <div className="col-12 col-lg-8 col-xl-9 d-flex align-items-center">
                                    <p className="mb-2">retailer_test@provi.com</p>
                                </div>
                            </div>
                        </div>
                        <p>
                            You can use the online applications to edit the information contained in this posting schedule. Please review the Filing Period Schedules and Deadlines for more details.
                        </p>
                    </div>
                    <div className="row justify-content-center mt-3">
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                            <a href="/wholesaler-static/certify3" className="btn btn-primary w-100">Return to Posting Summary</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default WholesalerCertify3View;

