import { Sorting } from "models/Sorting";
import { NavigateFunction } from "react-router/dist/lib/hooks";

export default class URLUtil {

    static readSearchParams(dateKeys?: string[]) {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const values = {};
        urlSearchParams.forEach(function(value, key) {
            if (dateKeys && dateKeys.includes(key)) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore`;
                values[key] = new Date(parseInt(value));
            } else if (key === "sortings") {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                values[key] = value.split(";")
                    .map(sorting => sorting.split(","))
                    .map(value1 => ({ field: value1[0], order: value1[1] } as Sorting));
            } else {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                values[key] = value;
            }
        });
        return values;
    }

    static updateUrlParams(path: string, params: any, navigate: NavigateFunction, exclude?: string[]) {
        const urlParams = new URLSearchParams();
        for (const key in params) {
            if (exclude && exclude.includes(key)) continue;
            if (params.hasOwnProperty(key)) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const val = params[key];
                if (val) {
                    if (val instanceof Date) {
                        urlParams.append(key, val.getTime().toString());
                    } else if (key === "sortings" && val instanceof Array && val.length > 0) {
                        urlParams.append(key, val
                            .map(value => value.field + (value.order ? "," + value.order : ""))
                            .join(";"));
                    } else {
                        urlParams.append(key, val);
                    }
                }
            }
        }

        navigate(path + "?" + urlParams.toString());
    }

    static pureUrlParameters(data: any, exclude?: string[]) {
        const requestData = { ...data };
        for (const key in requestData) {
            if (exclude && exclude.includes(key)) continue;
            if (requestData.hasOwnProperty(key)) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const val = requestData[key];
                if (val instanceof Date && val) {
                    requestData[key] = val.getTime();
                }
                if (!val) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    requestData[key] = undefined;
                }
            }
        }
        return JSON.parse(JSON.stringify(requestData));
    }

    static setValuesFromUrl(data: any, setValue: any, dateKeys?: string[]) {
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const val = data[key];
                if (val) {
                    if (dateKeys && dateKeys.includes(key)) {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        setValue(key, new Date(parseInt(val)));
                    } else {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        setValue(key, val);
                    }
                }
            }
        }
    }

    static removeEmptyValues(filters: any): any {
        return filters ? Object.entries(filters).reduce((result: any, [key, value]) => {
            if (value !== undefined && value !== null && value !== "") {
                result[key] = value;
            }
            return result;
        }, {}) : {};
    }
}
