import React, { RefAttributes, forwardRef, useState } from "react";
import { Controller  } from "react-hook-form";
import AsyncSelect, { AsyncProps } from "react-select/async";
import { GroupBase } from "react-select";
import Spinner from "components/Spinner";
import { ShortLicenseInfo } from "models/license.model";
import ManageLicensesService from "services/admin/ManageLicensesService";
import { OptionModel } from "models/OptionModel";
import Select from "react-select/dist/declarations/src/Select";
import { Control } from "react-hook-form/dist/types/form";

export type LicenseOptionModel = OptionModel & { info: ShortLicenseInfo };
type AsyncSelectProps = AsyncProps<LicenseOptionModel, false, GroupBase<LicenseOptionModel>> & RefAttributes<Select<LicenseOptionModel>>;

type UncontrolledLicenseSelectInputProps = Omit<AsyncSelectProps,"isMulti"> & {
    label?: string,
};

type LicenseSelectInputProps = Omit<UncontrolledLicenseSelectInputProps, "value" | "onChange" > & {
    name:string,
    control: Control<any>
};

const cache = new Map<number, LicenseOptionModel|Promise<any>>();
const optionText = (m: ShortLicenseInfo) =>
    m.permit_id
        ? `${m.premise_name??""} / ${m.permit_id}`
        : `${m.premise_name??""} / ${m.serial_number}`;

const modelToOption = (m: ShortLicenseInfo) => ({ value: m.id.toString(), label: optionText(m), info: m } as LicenseOptionModel);
const loadLicensesByPermitIdOrSerial = (p: string, optsConsumer: (oo: LicenseOptionModel[])=>void): Promise<LicenseOptionModel[]> =>
    (!p || p.length < 7)
        ? Promise.resolve([])
        : ManageLicensesService.shortLicenseInfoBySerialOrPermit(p)
            .then(la=> la.map(modelToOption))
            .then(opts=>{
                if (optsConsumer){
                    optsConsumer(opts);
                }
                return opts;
            });

const LicenseSelectInput = ( props:LicenseSelectInputProps ) => {
    type InnerState = { defaultProcessed: boolean, lastOptions: LicenseOptionModel[] };
    const [data, setData] = useState<InnerState>({ defaultProcessed: false, lastOptions:[] });

    const loadLicensesByPermitIdOrSerialAndStoreOpts = (p: string) =>
        loadLicensesByPermitIdOrSerial(p,
            opts=> setData(od=>({ ...od, lastOptions: opts }))
        );

    const loadPresetValue = (licenseId: string) => {
        const key = parseInt(licenseId);
        if (!cache.has(key)){
            cache.set(key,
                ManageLicensesService.shortLicenseInfoById(key)
                    .then(lm=> lm === null ? Promise.reject(1) : modelToOption(lm))
                    .then(opt => {
                        cache.set(key, opt);
                        return setData({ defaultProcessed: true, lastOptions: [opt] });
                    })
                    .catch(() => cache.delete(key))
            );
        }
        else {
            const v = cache.get(key);
            if ((v as LicenseOptionModel).value){
                return setData({ defaultProcessed: true, lastOptions: [v as LicenseOptionModel] });
            }
        }
    };

    return <Controller
        name={ props.name }
        control={ props.control }
        defaultValue=""
        render={ ({ field: { onChange, value, ...rest }, formState: { defaultValues } }) => {

            if (defaultValues?.license_id && !data.defaultProcessed){
                loadPresetValue(defaultValues?.license_id);
                return <Spinner className="mt-4 pt-2 text-center" />;
            }
            else {
                return <UncontrolledLicenseSelectInput
                    label={ props.label }
                    loadOptions={ loadLicensesByPermitIdOrSerialAndStoreOpts }
                    value={ data.lastOptions.find(opt => opt.value === value?.toString()) }
                    onChange={ (newOption: unknown) => onChange(newOption ? (newOption as LicenseOptionModel).value : null) }
                    isDisabled={ props.isDisabled }
                    { ...rest }
                />;
            }
        } }
    />;
};

export default LicenseSelectInput;

export const UncontrolledLicenseSelectInput = forwardRef(
    function UncontrolledLicenseSelectInput(props: UncontrolledLicenseSelectInputProps, ref: any) {
        const { label, name, loadOptions=loadLicensesByPermitIdOrSerial, ...rest } = props;
        return <>
            <div className="mb-3">
                { label && <label htmlFor={ name } className="form-label"> { label } </label> }
                <AsyncSelect<LicenseOptionModel> cacheOptions defaultOptions isClearable placeholder="Enter license id or serial number"
                    components={ { DropdownIndicator: null } }
                    loadOptions={ loadOptions }
                    { ...rest }
                    ref={ ref }
                    id="addLicenseInput"
                />
            </div>
        </>;
    });
