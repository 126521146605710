import React, { Fragment, useState } from "react";
import { modalStore } from "store/ModalStoreProvider";
import { TransferDataParamModel } from "pages/admin/models/TransferDataParamModel";
import ProductConstants from "services/ProductConstants";
import { useTimeStore } from "store/TimeStoreProvider";
import StringUtils from "utils/StringUtils";
import DateUtil from "utils/DateUtil";
import TransferProductsService from "services/admin/TransferProductsService";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";
import { useToastStore } from "store/ToastStoreProvider";
import Spinner from "components/Spinner";
import { TransferScheduleResult } from "pages/admin/TransferScheduleData";

interface Props {
    transferData: TransferDataParamModel;
    count: number;
    onSubmit: (transferResult: TransferScheduleResult) => void;
}

export const showTransferScheduleDataDialog = (transferData: TransferDataParamModel, count: number, onSubmit: (tr: TransferScheduleResult) => void) => {
    modalStore.setBody(<TransferScheduleDataDialog transferData={ transferData } count={ count } onSubmit={ onSubmit }/>);
    modalStore.setHeader("Transfer Schedule Data");
    modalStore.setSize("xl");
    modalStore.showModal();
};

const TransferScheduleDataDialog = ({ transferData, count, onSubmit }: Props) => {
    const { addToast, addDefaultDangerToast } = useToastStore();
    const { newYorkTime } = useTimeStore();
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);

    const dateFrom = newYorkTime.add(transferData?.fromDate, "M");
    const dateTo = newYorkTime.add(transferData?.toDate, "M");

    function doTransfer() {
        setIsDataLoading(true);
        TransferProductsService.transferProducts(transferData).then((resp) => {
            onSubmit({
                ...resp,
                ...transferData,
                toDate: DateUtil.monthNumberToName(dateTo.month()) + " " + dateTo.year(),
                fromDate: DateUtil.monthNumberToName(dateFrom.month()) + " " + dateFrom.year()
            });
            addToast({
                message:"Data has been transferred successfully.",
                header: "Transfer Schedule Data", variant: "success",
                timeoutMs: DEFAULT_TOASTER_TIMEOUT
            });
        }).catch(() => addDefaultDangerToast("Transfer Schedule Data")
        ).finally(() => {
            setIsDataLoading(false);
            modalStore.hideModal();
        });
    }
    modalStore.setAdditionalButtons([
        <button key={ 0 } type="button" className="btn btn-primary" onClick={ () => doTransfer() }>Transfer</button>
    ]);

    return <Fragment>
        <div className="row pt-2">
            <div className="mb-4 col-12 col-lg-6">
                <div className="card bg-white">
                    <div className="card-body pt-3 px-3 pb-0">
                        <h5 className="fw-bold">From:</h5>
                        <div className="d-flex flex-row justify-content-between border-bottom flex-wrap lh-sm py-3">
                            <p className="mb-0 me-2"><strong>License, Nº</strong></p>
                            <p className="mb-0">{ transferData.fromLicense }</p>
                        </div>
                        <div className="d-flex flex-row justify-content-between border-bottom flex-wrap lh-sm py-3">
                            <p className="mb-0 me-2"><strong>Company</strong></p>
                            <p className="mb-0">{ transferData.fromPremiseName }</p>
                        </div>
                        <div className="d-flex flex-row justify-content-between border-bottom flex-wrap lh-sm py-3">
                            <p className="mb-0 me-2"><strong>Address</strong></p>
                            <p className="mb-0">{ transferData.fromPremiseAddress }</p>
                        </div>
                        <div className="d-flex flex-row justify-content-between border-bottom flex-wrap lh-sm py-3">
                            <p className="mb-0 me-2"><strong>Status</strong></p>
                            <span className="transfer-status">{ StringUtils.capitalize(transferData.fromStatus) }</span>
                        </div>
                        <div className="d-flex flex-row justify-content-between border-bottom flex-wrap lh-sm py-3">
                            <p className="mb-0 me-2"><strong>Type</strong></p>
                            <p className="mb-0">{ ProductConstants.postTypeDescription(transferData.postType) }</p>
                        </div>
                        { transferData.fromStatus !== ProductConstants.STATUS.WORKING &&
                            <div className="d-flex flex-row justify-content-between flex-wrap lh-sm py-3">
                                <p className="mb-0 me-2"><strong>Period</strong></p>
                                <p className="mb-0">{ DateUtil.monthNumberToName(dateFrom.month()) } { dateFrom.year() }</p>
                            </div>
                        }
                    </div>
                </div>
                <div className="mt-3">
                    <b>Product count:</b> { count }
                </div>
            </div>
            <div className="mb-4 col-12 col-lg-6">
                <div className="card bg-white">
                    <div className="card-body pt-3 px-3 pb-0">
                        <h5 className="fw-bold">To:</h5>
                        <div className="d-flex flex-row justify-content-between border-bottom flex-wrap lh-sm py-3">
                            <p className="mb-0 me-2"><strong>License, Nº</strong></p>
                            <p className="mb-0">{ transferData.toLicense }</p>
                        </div>
                        <div className="d-flex flex-row justify-content-between border-bottom flex-wrap lh-sm py-3">
                            <p className="mb-0 me-2"><strong>Company</strong></p>
                            <p className="mb-0">{ transferData.toPremiseName }</p>
                        </div>
                        <div className="d-flex flex-row justify-content-between border-bottom flex-wrap lh-sm py-3">
                            <p className="mb-0 me-2"><strong>Address</strong></p>
                            <p className="mb-0">{ transferData.toPremiseAddress }</p>
                        </div>
                        <div className="d-flex flex-row justify-content-between border-bottom flex-wrap lh-sm py-3">
                            <p className="mb-0 me-2"><strong>Status</strong></p>
                            <span className="transfer-status">{ StringUtils.capitalize(transferData.toStatus) }</span>
                        </div>
                        <div className="d-flex flex-row justify-content-between border-bottom flex-wrap lh-sm py-3">
                            <p className="mb-0 me-2"><strong>Type</strong></p>
                            <p className="mb-0">{ ProductConstants.postTypeDescription(transferData?.postType) }</p>
                        </div>
                        { transferData.toStatus !== ProductConstants.STATUS.WORKING &&
                            <div className="d-flex flex-row justify-content-between flex-wrap lh-sm py-3">
                                <p className="mb-0 me-2"><strong>Period</strong></p>
                                <p className="mb-0">{ DateUtil.monthNumberToName(dateTo.month()) } { dateTo.year() }</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
        <div className="text-center pt-2">
            <p className="mb-0">After performing the transfer, your previous data will be completely lost. Are you sure you want to transfer your Schedule Data?</p>
        </div>

        <Spinner className="mt-4 pt-2 text-center" hidden={ !isDataLoading }/>
    </Fragment>;
};
