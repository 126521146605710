import React from "react";
import { ControllerSelectProps, SelectControlled, SelectOption } from "components/form/Select";


type Props = Omit<ControllerSelectProps, "options">

const SelectProductContainer = (props : Props) => {

    const options: SelectOption[] = [
        { value: "C", title: "Case" },
        { value: "B", title: "Bottle" }
    ];

    return <SelectControlled options={ options } { ...props } />;
};

export default SelectProductContainer;
