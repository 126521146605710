import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import {  useAuthStore } from "store/AuthStoreProvider";
import { modalStore } from "store/ModalStoreProvider";
import UserProfileService from "services/user/UserProfileService";
import { AddLicenseRequest } from "models/requests/AddLicenseRequest";
import FormUtil from "utils/FormUtil";
import { LicenseAndPrincipalInput } from "components/LicenseAndPrincipalInput";
import LicenseStatusCell from "components/LicenseStatusCell";
import { showLicenseDelinquentInfo } from "components/dialogs/LicenseDelinquentInfo";
import { WHOLESALER_PATHS } from "pages/wholesaler/WholesalerRoutes";
import { showDeleteCODConfirmation } from "components/dialogs/DeleteCodDataDialog";
import { ShortLicenseInfo } from "models/license.model";
import authService from "services/AuthService";
import { LoggedInUserInfo } from "models/LoggedInUserInfo";
import { redirectToHomePage } from "services/RedirectService";
import { useAlertStore } from "store/AlertStoreProvider";
import { useToastStore } from "store/ToastStoreProvider";

export const MyLicenses = ()=> {

    const auth = useAuthStore();
    const navigate = useNavigate();
    const { updateAlerts } = useAlertStore();
    const { addDefaultDangerToast } = useToastStore();

    const doReauthorize = (newLicenseId: number) => {
        authService.reauthorize(newLicenseId)
            .then((resp: LoggedInUserInfo) => {
                auth.setAuthorizedSilently(resp);
                updateAlerts();
                redirectToHomePage(auth.permissionStore, navigate);
                navigate(0);
            })
            .catch(() => addDefaultDangerToast("Reauthorize"));
    };
    const showAddLicenseDialog = ()=>{
        modalStore.setBody(<AddLicenseDialog onSuccess={ (newLicenseId: number)=>
            auth.currentLicense ? navigate(0) : doReauthorize(newLicenseId)
        }/>);
        modalStore.setHeader("Add License");
        modalStore.showModal();
    };

    return  (
        <div className="d-flex flex-column min-vh-100">
            <div className="mb-3 mb-md-4">
                <h1 className="text-primary mb-4">My Licenses</h1>
                <div className="d-flex flex-column">
                    <div className="mb-3 me-lg-3">
                        On this page, you can view all licenses associated with your account. If required, you also have the ability to add a new license.
                    </div>
                    <div className="d-flex flex-row ms-lg-auto">
                        <button className="btn btn-outline-secondary mt-3 me-3" onClick={ () => showAddLicenseDialog() }>
                            Add license
                        </button>
                        { auth.permissionStore.isWholesalerAndBA_CW() &&
                            <Link className="btn btn-outline-secondary mt-3" to={ WHOLESALER_PATHS.DELINQUENT.TRANSFER }>
                                Delinquency Data Migrator
                            </Link>
                        }
                    </div>
                </div>
            </div>

            <div className="table-fix-height">
                <div className="table-responsive">
                    <table className="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th>License ID</th>
                                <th>Serial Number</th>
                                <th>Premises Name</th>
                                <th>Trade Name</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                auth.licenses?.map(r =>
                                    <tr key={ r.licenseId }>
                                        <td>
                                            <button className="btn btn-link" onClick={ () => showLicenseDelinquentInfo(r.licenseId) }>
                                                { r.permitId }
                                            </button>
                                        </td>
                                        <td>
                                            <button className="btn btn-link" onClick={ () => showLicenseDelinquentInfo(r.licenseId) }>
                                                { r.serialNumber }
                                            </button>
                                        </td>
                                        <td>{ r.premiseName }</td>
                                        <td>{ r.tradeName }</td>
                                        <td><LicenseStatusCell statusCode={ r.active ? "A" : "I" }/></td>
                                        <td>
                                            { (auth.permissionStore.isWholesalerAndBA_CW() && !r.active) &&
                                                <a className="link-danger link-opacity-75 link-opacity-100-hover" href="/#"
                                                    onClick={ (e) => {
                                                        showDeleteCODConfirmation({
                                                            id: r.licenseId,
                                                            serial_number: r.serialNumber,
                                                            permit_id: r.permitId,
                                                            premise_name: r.premiseName,
                                                            dba: r.tradeName,
                                                            status: r.active ? "A" : "I"
                                                        } as ShortLicenseInfo);
                                                        e.preventDefault();
                                                        return false;
                                                    } }>
                                                    Delete COD Data
                                                </a>
                                            }
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                    { auth.licenses?.length === 0 &&
                        <div className="mb-0-5">
                            You currently don&apos;t hold a license. If you personally own a license and are aware of the license details and principals, you can add it yourself by clicking the &apos;Add license&apos; button.
                        </div>
                    }
                </div>
            </div>
        </div>
    );

};


const AddLicenseDialog = ({ onSuccess }: { onSuccess: (newLicenseId: number)=>void }) => {

    const formReturn = useForm<AddLicenseRequest>({ mode: "onChange" });
    const [formError, setFormError] = useState<string | undefined>("");

    const onSubmit: SubmitHandler<AddLicenseRequest> = data => {
        UserProfileService.addToLicense(data)
            .then(()=>{
                onSuccess(data.licenseId);
                modalStore.hideModal();
            })
            .catch(response => {
                formReturn.clearErrors();
                setFormError("");

                if (response.status == 422){
                    FormUtil.handleValidationError(response.data, formReturn.setError, setFormError);
                }
                else {
                    setFormError("Something went wrong. Please try again");
                }
            });
    };

    modalStore.setAdditionalButtons([
        <button key={ 0 } className="btn btn-primary" type="button"
            onClick={ formReturn.handleSubmit(onSubmit) }
            disabled={ !formReturn.formState.isDirty }
        >Add</button>
    ]);

    return <FormProvider { ...formReturn }>
        <LicenseAndPrincipalInput/>
        { formError &&
            <div className="invalid-message alert alert-sm alert-danger">
                <ul>
                    <li>{ formError }</li>
                </ul>
            </div>
        }
    </FormProvider>;
};
