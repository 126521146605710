import { action, computed, makeObservable, observable } from "mobx";
import dayjs, { Dayjs } from "dayjs";
import AppPropertiesService from "services/AppPropertiesService";
import CacheStore from "store/CacheStore";

export class TimeStore {

    @observable
    private _serverTime: Dayjs | undefined;

    constructor() {
        makeObservable(this);
    }

    @computed
    get serverTime(): Dayjs {
        const cache = CacheStore.serverTimeCache.get(CacheStore.SERVER_TIME_KEY);
        if (cache == undefined) {
            this.updateServerTime();
        }
        if (this._serverTime == undefined) {
            return dayjs();
        }
        return this._serverTime;
    }

    @computed
    get isReady(): boolean {
        return this._serverTime !== undefined;
    }

    @computed
    get newYorkTime(): Dayjs {
        return this.serverTime.tz("America/New_York");
    }

    private updateServerTime() {
        AppPropertiesService.getServerTime()
            .then(date => this.setServerTime(date))
            .catch(() => this.setServerTime(dayjs()));
    }

    @action
    private setServerTime(date: Dayjs) {
        this._serverTime = date;
    }
}
