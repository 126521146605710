import httpClient from "api/HttpClient";
import BACKEND from "services/Constants";
import { SaveMasterFilerModel } from "pages/admin/models/SaveMasterFilerModel";
import { PageableResponse } from "models/response/PageableResponse";
import { LicenseModel } from "models/license.model";
import { PageableRequest, pageableRequestToSearchParams } from "models/requests/PageableRequest";
import { LicenseGroupModel } from "pages/admin/models/LicenseGroupModel";
import { LicenseGroupService } from "services/admin/LicenseGroupService";

const root = `${BACKEND}/admin/central-bookkeeping`;
class CentralBookkeepingService implements LicenseGroupService {

    checkLicense = (license: string): Promise<LicenseModel> =>
        httpClient.get( `${root}/check-license/${license}`);

    isPresent = (): Promise<boolean> =>
        httpClient.get( `${root}/check-is-present`);

    save = (request: SaveMasterFilerModel): Promise<boolean> =>
        httpClient.post(`${root}/save`, request);

    delete = (licenseId: number): Promise<boolean> =>
        httpClient.delete(`${root}/delete/${licenseId}`);

    lookup = (request: PageableRequest): Promise<PageableResponse<LicenseGroupModel>> =>
        httpClient.get(`${root}/lookup`,{ params: pageableRequestToSearchParams(request) });
}

const instance = new CentralBookkeepingService();
export default instance;
