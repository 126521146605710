import AuthStaticRoutes, { AUTH_STATIC_PATHS } from "pages/statics/auth/AuthStaticRoutes";
import AdminStaticRoutes, { ADMIN_STATIC_PATHS } from "pages/statics/admin/AdminStaticRoutes";
import { Route, Routes } from "react-router-dom";
import WholesalerStaticRoutes, { WHOLESALER_STATIC_PATHS } from "pages/statics/wholesaler/WholesalerStaticRoutes";
import React from "react";
import DelinquentRetailerStaticRoutes, {
    DELINQUENT_RETAILER_PATHS
} from "pages/statics/retailer-delinquent/DelinquentRetailerStaticRoutes";
import LicenseStaticRoutes, { LICENSE_STATIC_PATHS } from "pages/statics/license/LicenseStaticRoutes";
import DelinquentStaticRoutes, { DELINQUENT_PATHS } from "pages/statics/delinquent/DelinquentStaticRoutes";

export const STATIC_PATHS = {
    MAIN: "/static/*"
};

const StaticRoutes = () => {
    return <Routes>
        <Route path={ AUTH_STATIC_PATHS.MAIN } element={ <AuthStaticRoutes/> }/>
        <Route path={ ADMIN_STATIC_PATHS.MAIN } element={ <AdminStaticRoutes/> }/>
        <Route path={ WHOLESALER_STATIC_PATHS.MAIN } element={ <WholesalerStaticRoutes/> }/>
        <Route path={ DELINQUENT_RETAILER_PATHS.MAIN } element={ <DelinquentRetailerStaticRoutes/> }/>
        <Route path={ DELINQUENT_PATHS.MAIN } element={ <DelinquentStaticRoutes/> }/>
        <Route path={ LICENSE_STATIC_PATHS.MAIN } element={ <LicenseStaticRoutes/> }/>
    </Routes>;
};

export default StaticRoutes;
