import React from "react";
import JobService from "services/admin/JobService";
import { JobInfo } from "models/JobInfo";
import { useToastStore } from "store/ToastStoreProvider";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";
import { useTimeStore } from "store/TimeStoreProvider";
import { classByJobStatus, statusName } from "utils/JobsDashboard";

export interface JobComponentProps {
    job: JobInfo;
    loadData: () => void;
    setIsLoading: (value:boolean) => void;
}

export const JobComponent = ({ job, loadData, setIsLoading }:JobComponentProps) => {
    const { serverTime } = useTimeStore();
    const { addToast } = useToastStore();

    function jobStartedMessage(jobName : string) {
        addToast({ header: "Job status", timeoutMs: DEFAULT_TOASTER_TIMEOUT, message:"Job " + jobNameToDescription(jobName) + " started", variant: "success" });
    }

    function jobNameToDescription(jobName : string) {
        switch (jobName) {
        case "auto_certify_retailer": return "Auto Certify Retailer";
        case "auto_certify_wholesaler": return "Auto Certify Wholesaler";
        case "publication": return "Publication";
        }
    }

    function runJob(jobName : string) {
        setIsLoading(true);
        JobService.runJob(jobName).then((_) => {
            jobStartedMessage(jobName);
            loadData();

        }).catch(() => {
            addToast({ header: "Job status", timeoutMs: DEFAULT_TOASTER_TIMEOUT, message:"Job " + jobNameToDescription(jobName) + " failed", variant: "danger" });
        }).finally(() =>  setIsLoading(false));
    }

    return <div className="col-12 col-xl-4 mb-4">
        <div className={ classByJobStatus(job.status) }>
            <div className="card">
                <div className="card-body">
                    <h5 className="fw-bold mb-4">{ jobNameToDescription(job.jobName) }</h5>
                    <div className="">
                        <span className="badge">
                            <i className="bi-check-circle me-1"></i> { statusName(job.status) }
                        </span>
                        <p className="mt-3 mb-0"><strong>Scheduled
                            on:</strong> { serverTime?.month() ? serverTime?.month() + 1 : 1 }/{ job.scheduledDay }/{ serverTime?.year() }
                        </p>
                        { job.dateCompleted && <p className="mt-2 mb-0"><strong>Date
                            Completed:</strong> { new Date(job.dateCompleted).toLocaleDateString("en-US") }</p> }
                    </div>
                    <button className="btn btn-primary w-100 mt-4" onClick={ () => runJob(job.jobName) }>Run job</button>
                </div>
            </div>
        </div>
    </div>;
};
