import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { Card, Row } from "react-bootstrap";
import { useFieldArray, useFormContext } from "react-hook-form";
import Input from "components/form/Input";
import TextArea from "components/form/TextArea";
import { ControlledFormSwitch } from "components/form/ControlledFormSwitch";
import { InputGroup } from "components/form/InputGroup";
import DiscountTab, { DiscountTabHandler } from "pages/wholesaler/priceposting/pages/brands/components/DiscountTab";
import { LimitedAvailabilityInput } from "pages/wholesaler/priceposting/pages/brands/components//LimitedAvailabilityInput";
import { NumberOfBottlesControl } from "pages/wholesaler/priceposting/pages/brands/components/NumberOfBottlesControl";
import { ComboItem } from "pages/wholesaler/priceposting/pages/brands/components/ComboItem";
import { TabName } from "pages/wholesaler/priceposting/models/ManualProduct";
import ProductItemTabs, {
    ProductItemTabsHandler
} from "pages/wholesaler/priceposting/pages/brands/components/ProductItemTabs";

const ComboStep2 = ({ type }:{ type: string }, ref: any) => {
    const rootName = "items[0]";
    const formReturn = useFormContext();
    const { control, watch, setValue, formState:{ errors } } = formReturn;
    const { fields:combos, append:appendCombo, remove:removeCombo } = useFieldArray({ control, name: `${rootName}.combos` });

    const productItemTabsRef = useRef<ProductItemTabsHandler>(null);
    const discountTabRef = useRef<DiscountTabHandler>(null);

    useImperativeHandle(ref, () => ({
        append: appendCombo
    }));

    function disableDiscounts() {
        productItemTabsRef.current?.disableDiscountTab();
        discountTabRef.current?.removeDiscounts();
    }

    function releaseDiscounts() {
        productItemTabsRef.current?.enableDiscountTab();
    }

    return <Card className="mt-4">
        <Card.Body className="pb-3">
            <ProductItemTabs ref={ productItemTabsRef } activeTab={ watch(`${rootName}.active_tab`) }
                onSelectTab={ k => setValue(`${rootName}.active_tab`, k as TabName || TabName.ITEM_INFO) }
                itemTabContent={
                    <>
                        <div className="pb-3 mx--row">
                            { (type === "LR" || type === "WR") &&
                                <>
                                    <Row>
                                        <div className="col-12 col-xl-6">
                                            <div className="pe-xl-4">
                                                <Input name={ `${rootName}.prod_name` } placeholder="Enter item description"
                                                    maxLength={ 100 } label="Description *" type="text" control={ control } errors={ errors }/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-6">
                                            <div className="ps-xl-4">
                                                <Row>
                                                    <div className="col-12 col-md-6">
                                                        <Input name={ `${rootName}.prod_item` }
                                                            placeholder="Enter your item number"
                                                            label="Your item number *"
                                                            maxLength={ 15 } type="text" control={ control } errors={ errors }/>
                                                    </div>
                                                </Row>
                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="col-12 col-md-6">
                                            <div className="pe-xl-4">
                                                <Row>
                                                    <NumberOfBottlesControl rootName={ rootName } />
                                                </Row>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="ps-xl-4">
                                                <div className="mb-3">
                                                    <p className="mb-2">
                                                        <strong>Case only</strong>
                                                    </p>
                                                    <div className="switch-year pt-1">
                                                        <label className="form-check-label me-2" htmlFor="fullcase0">No</label>
                                                        <ControlledFormSwitch control={ control } errors={ errors } id="fullcase0" label={ "Yes" } name={ `${rootName}.fullcase` } options={ ["Y","N"] }/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="col-12 col-xl-6">
                                            <div className="pe-xl-4">
                                                <Row>
                                                    <div className="col-12 col-md-6">
                                                        <InputGroup name={ `${rootName}.case_price` } control={ control }
                                                            errors={ errors }
                                                            label="Price per case *" prefix="$"
                                                            placeholder="Enter price per case"
                                                            step=".01" type="number" valueAsFloat={ true }/>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <InputGroup name={ `${rootName}.case_nyc` } control={ control }
                                                            errors={ errors }
                                                            label="NYC Price per case" prefix="$"
                                                            placeholder="Enter NYC Price per case"
                                                            min={ 0 } step=".01" type="number" valueAsFloat={ true }/>
                                                    </div>
                                                </Row>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-6">
                                            <div className="ps-xl-4">
                                                <Row>
                                                    <div className="col-12 col-md-6">
                                                        <Input name={ `${rootName}.nys_whole` } placeholder="Enter NYS supplier license id or serial number" control={ control }
                                                            label="NYS supplier *" type="text" errors={ errors } maxLength={ 16 } />
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <Input name={ `${rootName}.nys_item` }
                                                            placeholder="Enter NYS supplier item number"
                                                            label="NYS supplier item number "
                                                            maxLength={ 15 } type="text" control={ control } errors={ errors }/>
                                                    </div>
                                                </Row>
                                            </div>
                                        </div>
                                    </Row>
                                </>
                            }
                            { (type === "LW" || type === "WW") &&
                                <>
                                    <Row>
                                        <div className="col-12 col-xl-6">
                                            <div className="pe-xl-4">
                                                <Input name={ `${rootName}.prod_name` } placeholder="Enter item description"
                                                    maxLength={ 100 } label="Description *" type="text" control={ control } errors={ errors }/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-6">
                                            <div className="ps-xl-4">
                                                <Row>
                                                    <div className="col-6 col-md-6">
                                                        <Input name={ `${rootName}.prod_item` } placeholder="Enter NYS supplier item number" control={ control }
                                                            label="NYS supplier item number *" type="text" errors={ errors }/>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="ps-xl-4">
                                                            <div className="mb-3">
                                                                <p className="mb-2">
                                                                    <strong>Case only</strong>
                                                                </p>
                                                                <div className="switch-year pt-1">
                                                                    <label className="form-check-label me-2" htmlFor="fullcase0">No</label>
                                                                    <ControlledFormSwitch control={ control } errors={ errors } id="fullcase0" label={ "Yes" } name={ `${rootName}.fullcase` } options={ ["Y","N"] }/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Row>
                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="col-12 col-md-6">
                                            <div className="pe-xl-4">
                                                <div className="row">
                                                    <NumberOfBottlesControl rootName={ rootName } />
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-xl-6">
                                                        <InputGroup name={ `${rootName}.case_price` } control={ control } errors={ errors } label="Price (FOB) per case *"
                                                            prefix="$" type="number" required placeholder="Price (FOB) per case"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="ps-xl-4">
                                                <TextArea name={ `${rootName}.fob` } control={ control } errors={ errors }
                                                    label="FOB Designation" placeholder="Enter FOB designation"
                                                    rows={ 4 } maxLength={ 256 } />
                                            </div>
                                        </div>
                                    </Row>
                                </>
                            }
                            <Row>
                                <LimitedAvailabilityInput rootName={ rootName } onCheck={ disableDiscounts } onUncheck={ releaseDiscounts }/>
                            </Row>
                            { (type === "LW" || type === "WW") &&
                                <Row>
                                    <div className="col-12">
                                        <TextArea name={ `${rootName}.prim_info` } control={ control } errors={ errors }
                                            label="Primary source (Brand owner) info" placeholder="Enter primary source (Brand owner) info"
                                            rows={ 2 } maxLength={ 256 } />
                                    </div>
                                </Row>
                            }
                            <hr/>
                        </div>
                        { combos.map((c, idx)=>
                            <ComboItem key={ c.id } rootName={ rootName } index = { idx }
                                onRemove = { (combos.length > 1) ? (() => removeCombo(idx)) : undefined }/>
                        ) }
                    </>
                }
                discountsTabContent={
                    <DiscountTab ref={ discountTabRef } rootName={ rootName }/>
                } />
        </Card.Body>
    </Card>;
};

export default forwardRef(ComboStep2);
