import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { SetPasswordFormModel } from "models/SetPasswordFormModel";
import { useNavigate, useParams } from "react-router-dom";
import { TokenInfo } from "services/AuthService";
import userPublicService from "services/user/UserPublicService";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";
import { useToastStore } from "store/ToastStoreProvider";
import { AUTH_PATHS } from "pages/auth/AuthRoutes";
import CheckTokenForm from "pages/auth/components/CheckTokenForm";
import PasswordFormInput from "components/form/input/PasswordFormInput";
import { useKeyDownEnterEvent } from "components/KeyDownHook";

const SetNewPassword = () => {
    const { token = "" } = useParams<{ token: string }>();
    const [ tokenInfo, setTokenInfo] = useState<Required<TokenInfo>|undefined>();

    return <CheckTokenForm tokenInfo={ tokenInfo } setTokenInfo={ setTokenInfo }
        token={ token } innerForm={ <InputNewPassword token={ token } /> }
        messageHeader={ "Password reset form" }/>;
};

export default SetNewPassword;

const InputNewPassword = ({ token }: { token: string }) => {
    const navigate = useNavigate();
    const { addToast } = useToastStore();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [ formError, setFormError] = useState<string | undefined>();
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm<SetPasswordFormModel>({
        mode: "onSubmit"
    });

    useKeyDownEnterEvent(() => !isLoading && handleSubmit(onSubmit)());

    const onSubmit = (data: SetPasswordFormModel) => {
        if (data.password !== data.confirmPassword) {
            setFormError("Password and confirm password do not match");
            return;
        }
        setIsLoading(true);
        setFormError(undefined);
        userPublicService.setNewPassword({ token, password: data.password }).then(() => {
            addToast({
                header: "Set new password",
                message:"You have successfully reset your password",
                variant: "success",
                timeoutMs: DEFAULT_TOASTER_TIMEOUT
            });
            navigate(AUTH_PATHS.LOGIN);
        }).catch((error) => {
            let errorMessage: string | undefined;
            if (error.status === 422) {
                errorMessage  = error.data?.errors ? error.data.errors[0]?.message : undefined;
            }
            if (error.status === 400) {
                errorMessage  = error.data?.message;
            }

            setFormError(errorMessage);
            addToast({
                header: "Set new password",
                message: errorMessage || "Something went wrong. Please try again",
                variant: "danger",
                timeoutMs: DEFAULT_TOASTER_TIMEOUT
            });
        }).finally(() => setIsLoading(false));
    };

    return (
        <div className="registration-block">
            <div className="mb-4 mb-md-5 text-center">
                <h1 className="text-primary mb-4">Enter your password</h1>
                <div className="d-flex justify-content-center">
                    <div className="d-flex flex-column justify-content-center text-start">
                        <p>In order to update your password, you must complete the following form.</p>
                        <p>Password needs:</p>
                        <div className="content-styles">
                            <ul className="mb-0">
                                <li>to be between 8 and 20 characters</li>
                                <li>have at least one digit</li>
                                <li>have at least one lower case character</li>
                                <li>have at least one upper case character</li>
                                <li>have at least one special character: <b className="text-primary text-nowrap">! @ # &amp; ( ) – [ { } ] : ; &#39; , ? / * ~ $ ^ + = &lt; &gt;</b></li>
                                <li>Kindly note that your password is case-sensitive, so it&apos;s essential to remember the exact capitalization you used during setup.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card login-block">
                <form onSubmit={ handleSubmit(onSubmit) } className="card-body">
                    <PasswordFormInput control={ control } errors={ errors } name="password"
                        label="Password *" placeholder="Enter password" autoComplete="new-password"/>
                    <PasswordFormInput control={ control } errors={ errors } name="confirmPassword"
                        label="Confirm Password *" placeholder="Confirm password" autoComplete="new-password"/>
                    <div className="row justify-content-center mt-4">
                        <div className="col-6 col-md-5 col-lg-5 mt-1">
                            <div className="pe-md-1">
                                <button className="btn-outline-secondary btn w-100" onClick={ () => reset() }>
                                    Clear Form
                                </button>
                            </div>
                        </div>
                        <div className="col-6 col-md-5 col-lg-5 mt-1">
                            <div className="ps-md-1">
                                <input className="btn btn-primary w-100" type="submit" value="Set Password"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column flex-sm-row mt-3">
                        { formError && <p className="invalid-message"> { formError } </p> }
                    </div>
                </form>
            </div>
        </div>
    );
};

