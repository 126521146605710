import { useEffect, useState } from "react";
import { ControllerSelectProps, SelectControlled, SelectOption } from "components/form/Select";
import BeverageTypeService from "services/BeverageTypeService";

type SelectBeverageTypeProps = Omit<ControllerSelectProps, "options"> & {
    type: string,
    addAllOption?: boolean //true if unspecified
};

export const SelectBeverageType = ({ type, addAllOption, ...rest } : SelectBeverageTypeProps) => {

    const [options, setOptions] = useState<SelectOption[]>([]);

    useEffect(() => {
        if(type) {
            const beverageTypes = BeverageTypeService.getTypesByPostType(type);
            const types = beverageTypes.map(type => ({ value: type.type, title: type.description } as SelectOption));
            
            if (addAllOption === undefined || addAllOption) {
                types.unshift({ value: "", title: "All" });
            }
            setOptions(types);

        }
    }, [type]);

    return <SelectControlled label="Beverage Type" options={ options } { ...rest } />;
};
