import { ProductModel } from "models/ProductModel";
import React from "react";
import ErroneousValue from "components/ErroneousValue";
import classNames from "classnames";
import { TabModel } from "components/product-tab-widget/ProductTabContainer";
import { DiscountValuesList } from "components/DiscountValuesList";
import * as _ from "lodash";
import StringUtils from "utils/StringUtils";
import { DiscountValues } from "models/DiscountValues";

const DISPLAYED_PROPERTIES = ["disc_code"];
const DiscountTabHeader = (product: ProductModel) => {
    const hasErrors = (p: ProductModel) =>
        StringUtils.hasAnyProperty(p.product_errors, DISPLAYED_PROPERTIES) ||
        !_.isEmpty(p.discount_values_errors);

    return <span className = { classNames(hasErrors(product) ? "text-danger" : "" ) }>
        { hasErrors(product) && <i className="bi bi-exclamation-circle-fill me-2"></i> }
        Discount Details
    </span>;
};

const DiscountTabBody = (product: ProductModel) => {
    const discountValues = product.discount_values?.reduce((acc: DiscountValues[], dv: DiscountValues) => {
        acc.push( dv.id === undefined
            ? dv
            : { ...dv, errors: product.discount_values_errors && product.discount_values_errors[dv.id.toString()] }
        );
        return acc;
    }, []);
    return  <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
        <h5 className="mb-3 fw-bold">Discount details</h5>
        { product.disc_code &&
            <p className="mb-3">
                <strong>Discount code:&nbsp;</strong>
                <ErroneousValue item={ product } name={ "disc_code" } />
            </p>
        }
        { discountValues
            ? <DiscountValuesList values={ discountValues } />
            : "N/A"
        }

    </div>;
};

export const DiscountTab: TabModel = { header: DiscountTabHeader, body: DiscountTabBody };
