import React, { useEffect, useState } from "react";
import { ControllerSelectProps, SelectControlled, SelectOption } from "components/form/Select";
import WholesalerLicenseSearchService from "services/wholesaler/WholesalerLicenseSearchService";

type SelectCountyProps = Omit<ControllerSelectProps, "options">

const SelectCounty = (props: SelectCountyProps) => {

    const [options, setOptions] = useState<SelectOption[]>([]);

    useEffect(() => {
        WholesalerLicenseSearchService.getCounties().then(counties => {
            const ops = counties.map(county => {
                return { value: county, title: county };
            });
            ops.unshift({ value: "", title: "Select County" });
            setOptions(ops as SelectOption[]);
        });
    }, []);

    return <SelectControlled options={ options } { ...props } />;
};

export default SelectCounty;
