

export default class StringUtils {

    // eslint-disable-next-line max-len
    public static EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    public static validateEmail(email: string) {
        return String(email)
            .toLowerCase()
            .match(
                StringUtils.EMAIL_REGEXP
            );
    }

    static capitalize(word: string) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }

    // flatten object
    // adopted from https://stackoverflow.com/a/49042916/2056178
    static flatten(obj: any, path = ""):any {
        if (!(obj instanceof Object)) {
            return { [path.replace(/^\./g, "")]:obj };
        }

        return Object.entries(obj).reduce((output, [key,value])=>{
            return obj instanceof Array ?
                { ...output, ...StringUtils.flatten(value, `${path}[${key}]`) }:
                { ...output, ...StringUtils.flatten(value, `${path}.${key}`) };
        }, {});
    }

    static hasAnyProperty( obj: any, properties: string[] ): boolean {
        return obj && properties.some(prop=> obj.hasOwnProperty(prop));
    }

}
