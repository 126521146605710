import React from "react";
import Input from "components/form/Input";

const WholesalerManageDiscountsView = () => {

    return <div className="d-flex flex-column">
        <div className="mb-4 pb-md-3">
            <h1 className="text-primary mb-0">Discount Codes - Schedule for November 2022</h1>
        </div>
        <div className="d-flex flex-column flex-sm-row align-items-center justify-content-between">
            <h5 className="mb-sm-0">Found <strong>4 discount codes</strong></h5>
            <a href="/" className="btn btn-primary" title="Create discount code" data-bs-toggle="modal" data-bs-target="#exampleModalAdd">Create discount code</a>
        </div>
        <div className="table-responsive mt-4">
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>Discount Code</th>
                        <th>Description</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>5Btest</td>
                        <td className="pb-1">
                            <p className="mb-1"><strong className="text-primary">$0.56</strong> on 2 bottles</p>
                        </td>
                        <td className="text-nowrap">
                            <button className="btn btn-link py-0 px-1 me-2" title="Edit" data-bs-toggle="modal" data-bs-target="#exampleModalEdit"><i className="bi-pencil"></i></button>
                            <button className="btn btn-link py-0 px-1 me-2" title="Delete" data-bs-toggle="modal" data-bs-target="#exampleModalRemove"><i className="bi-trash"></i></button>
                        </td>
                    </tr>
                    <tr>
                        <td>SALECASE</td>
                        <td className="pb-1">
                            <div className="content-styles">
                                <ol className="ps-4 mb-0">
                                    <li className="mb-1 ps-1"><strong className="text-primary">$1.67</strong> on 3 cases</li>
                                    <li className="mb-1 ps-1"><strong className="text-primary">$0.50</strong> on 1 case</li>
                                    <li className="mb-1 ps-1"><strong className="text-primary">$3.30</strong> on 5 cases</li>
                                </ol>
                            </div>
                        </td>
                        <td className="text-nowrap">
                            <button className="btn btn-link py-0 px-1 me-2" title="Edit" data-bs-toggle="modal" data-bs-target="#exampleModalEdit"><i className="bi-pencil"></i></button>
                            <button className="btn btn-link py-0 px-1 me-2" title="Delete" data-bs-toggle="modal" data-bs-target="#exampleModalRemove"><i className="bi-trash"></i></button>
                        </td>
                    </tr>
                    <tr>
                        <td>HELLO</td>
                        <td className="pb-1">
                            <p className="mb-1"><strong className="text-primary">$2.40</strong> on 3 bottles</p>
                        </td>
                        <td className="text-nowrap">
                            <button className="btn btn-link py-0 px-1 me-2" title="Edit" data-bs-toggle="modal" data-bs-target="#exampleModalEdit"><i className="bi-pencil"></i></button>
                            <button className="btn btn-link py-0 px-1 me-2" title="Delete" data-bs-toggle="modal" data-bs-target="#exampleModalRemove"><i className="bi-trash"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ul className="justify-content-center pagination mt-3">
            <li className="page-item disabled">
                <span className="page-link">
                    <span className="bi bi-arrow-left me-2"></span>Prev
                </span>
            </li>
            <li className="page-item active">
                <span className="page-link">1</span>
            </li>
            <li className="page-item">
                <a className="page-link" role="button" href="/">2</a>
            </li>
            <li className="page-item">
                <a className="page-link" role="button" href="/">3</a>
            </li>
            <li className="page-item">
                <a className="page-link" role="button" href="/">...</a>
            </li>
            <li className="page-item">
                <a className="page-link" role="button" href="/">50</a>
            </li>
            <li className="page-item">
                <a className="page-link" role="button" href="/">
                    Next<span className="bi bi-arrow-right ms-2"></span>
                </a>
            </li>
        </ul>
        <div className="modal fade" id="exampleModalRemove" aria-labelledby="exampleModalRemoveLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalRemoveLabel">Delete discount code</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p className="mb-0">You are going to delete discount code <strong>&quot;Hello&quot;</strong>. Are you sure?</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" className="btn btn-primary">Yes</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal fade" id="exampleModalAdd" aria-labelledby="exampleModalAddLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable modal-xl modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalAddLabel">Create new discount code</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body pb-2">
                        <div className="mb-4 form-group-without-label form-group-without-m w-100">
                            <div className="form-group">
                                <label htmlFor="validationServer" className="form-label">Discount code</label>
                                <input id="validationServer" type="text" className="form-control is-invalid" placeholder="Enter discount code name" aria-describedby="validationServerFeedback" required/>
                                <div id="validationServerFeedback" className="invalid-feedback">Please provide a valid discount code</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-4 mb-4">
                                <div className="card">
                                    <div className="card-body pb-2 pt-3">
                                        <div className="styleguide-head mb-4">
                                            <div className="d-flex flex-row align-items-center mb-2">
                                                <h5 className="me-auto mb-0">Discount 1</h5>
                                                <a className="fs-4" href="/">
                                                    <i className="bi-x-circle-fill"></i>
                                                </a>
                                            </div>
                                            <hr className="mt-1" />
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <div className="form-group">
                                                    <label htmlFor="Type" className="form-label">Type</label>
                                                    <select id="Type" className="form-select" aria-label="Select">
                                                        <option selected>$</option>
                                                        <option value="1">$</option>
                                                        <option value="2">Euro</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <Input name="amount" placeholder="00.00" label="Amount" type="number"/>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <Input name="on" placeholder="0" label="On"  type="number"/>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <div className="form-group">
                                                    <label htmlFor="Units" className="form-label">Units</label>
                                                    <select id="Units" className="form-select" aria-label="Select">
                                                        <option selected>Case</option>
                                                        <option value="1">Case 1</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 mb-4">
                                <div className="card">
                                    <div className="card-body pb-2 pt-3">
                                        <div className="styleguide-head mb-4">
                                            <div className="d-flex flex-row align-items-center mb-2">
                                                <h5 className="me-auto mb-0">Discount 2</h5>
                                                <a className="fs-4" href="/">
                                                    <i className="bi-x-circle-fill"></i>
                                                </a>
                                            </div>
                                            <hr className="mt-1" />
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <div className="form-group">
                                                    <label htmlFor="Type" className="form-label">Type</label>
                                                    <select id="Type" className="form-select" aria-label="Select">
                                                        <option selected>$</option>
                                                        <option value="1">$</option>
                                                        <option value="2">Euro</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <Input name="amount" placeholder="00.00" label="Amount" type="number"/>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <Input name="on" placeholder="0" label="On"  type="number"/>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <div className="form-group">
                                                    <label htmlFor="Units" className="form-label">Units</label>
                                                    <select id="Units" className="form-select" aria-label="Select">
                                                        <option selected>Bottle</option>
                                                        <option value="1">Case</option>
                                                        <option value="1">Bottle</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 mb-4">
                                <div className="card">
                                    <div className="card-body pb-2 pt-3">
                                        <div className="styleguide-head mb-4">
                                            <div className="d-flex flex-row align-items-center mb-2">
                                                <h5 className="me-auto mb-0">Discount 3</h5>
                                                <a className="fs-4" href="/">
                                                    <i className="bi-x-circle-fill"></i>
                                                </a>
                                            </div>
                                            <hr className="mt-1" />
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <div className="form-group">
                                                    <label htmlFor="Type" className="form-label">Type</label>
                                                    <select id="Type" className="form-select" aria-label="Select">
                                                        <option selected>$</option>
                                                        <option value="1">$</option>
                                                        <option value="2">Euro</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <Input name="amount" placeholder="00.00" label="Amount" type="number"/>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <Input name="on" placeholder="0" label="On"  type="number"/>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <div className="form-group">
                                                    <label htmlFor="Units" className="form-label">Units</label>
                                                    <select id="Units" className="form-select" aria-label="Select">
                                                        <option selected>Case</option>
                                                        <option value="1">Case 1</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 mb-4">
                                <div className="card">
                                    <div className="card-body pb-2 pt-3">
                                        <div className="styleguide-head mb-4">
                                            <div className="d-flex flex-row align-items-center mb-2">
                                                <h5 className="me-auto mb-0">Discount 4</h5>
                                                <a className="fs-4" href="/">
                                                    <i className="bi-x-circle-fill"></i>
                                                </a>
                                            </div>
                                            <hr className="mt-1" />
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <div className="form-group">
                                                    <label htmlFor="Type" className="form-label">Type</label>
                                                    <select id="Type" className="form-select is-invalid" aria-label="Select">
                                                        <option selected>$</option>
                                                        <option value="1">$</option>
                                                        <option value="2">Euro</option>
                                                    </select>
                                                    <div id="validationServerFeedback" className="invalid-feedback">Please choose type</div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <div className="form-group">
                                                    <label htmlFor="validationServer" className="form-label">Amount</label>
                                                    <input id="validationServer" type="number" className="form-control is-invalid" placeholder="00.00" aria-describedby="validationServerFeedback" required />
                                                    <div id="validationServerFeedback" className="invalid-feedback">Please add amount</div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <Input name="on" placeholder="0" label="On"  type="number"/>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <div className="form-group">
                                                    <label htmlFor="Units" className="form-label">Units</label>
                                                    <select id="Units" className="form-select" aria-label="Select">
                                                        <option selected>Case</option>
                                                        <option value="1">Case 1</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
                        <a href="/" className="btn btn-secondary">Add new discount</a>
                        <button type="button" className="btn btn-primary">Save</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal fade" id="exampleModalEdit" aria-labelledby="exampleModalEditLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable modal-xl modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalAddLabel">Configure discount code</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body pb-2">
                        <div className="mb-4 form-group-without-label form-group-without-m w-100">
                            <div className="form-group">
                                <label className="form-label">Discount code</label>
                                <input type="text" className="form-control" placeholder="Enter discount code name" required/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-4 mb-4">
                                <div className="card">
                                    <div className="card-body pb-2 pt-3">
                                        <div className="styleguide-head mb-4">
                                            <div className="d-flex flex-row align-items-center mb-2">
                                                <h5 className="me-auto mb-0">Discount 1</h5>
                                                <a className="fs-4" href="/">
                                                    <i className="bi-x-circle-fill"></i>
                                                </a>
                                            </div>
                                            <hr className="mt-1" />
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <div className="form-group">
                                                    <label htmlFor="Type" className="form-label">Type</label>
                                                    <select id="Type" className="form-select" aria-label="Select">
                                                        <option selected>$</option>
                                                        <option value="1">$</option>
                                                        <option value="2">Euro</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <Input name="amount" placeholder="00.00" label="Amount" type="number"/>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <Input name="on" placeholder="0" label="On"  type="number"/>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <div className="form-group">
                                                    <label htmlFor="Units" className="form-label">Units</label>
                                                    <select id="Units" className="form-select" aria-label="Select">
                                                        <option selected>Case</option>
                                                        <option value="1">Case 1</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 mb-4">
                                <div className="card">
                                    <div className="card-body pb-2 pt-3">
                                        <div className="styleguide-head mb-4">
                                            <div className="d-flex flex-row align-items-center mb-2">
                                                <h5 className="me-auto mb-0">Discount 2</h5>
                                                <a className="fs-4" href="/">
                                                    <i className="bi-x-circle-fill"></i>
                                                </a>
                                            </div>
                                            <hr className="mt-1" />
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <div className="form-group">
                                                    <label htmlFor="Type" className="form-label">Type</label>
                                                    <select id="Type" className="form-select" aria-label="Select">
                                                        <option selected>$</option>
                                                        <option value="1">$</option>
                                                        <option value="2">Euro</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <Input name="amount" placeholder="00.00" label="Amount" type="number"/>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <Input name="on" placeholder="0" label="On"  type="number"/>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <div className="form-group">
                                                    <label htmlFor="Units" className="form-label">Units</label>
                                                    <select id="Units" className="form-select" aria-label="Select">
                                                        <option selected>Bottle</option>
                                                        <option value="1">Case</option>
                                                        <option value="1">Bottle</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 mb-4">
                                <div className="card">
                                    <div className="card-body pb-2 pt-3">
                                        <div className="styleguide-head mb-4">
                                            <div className="d-flex flex-row align-items-center mb-2">
                                                <h5 className="me-auto mb-0">Discount 3</h5>
                                                <a className="fs-4" href="/">
                                                    <i className="bi-x-circle-fill"></i>
                                                </a>
                                            </div>
                                            <hr className="mt-1" />
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <div className="form-group">
                                                    <label htmlFor="Type" className="form-label">Type</label>
                                                    <select id="Type" className="form-select" aria-label="Select">
                                                        <option selected>$</option>
                                                        <option value="1">$</option>
                                                        <option value="2">Euro</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <Input name="amount" placeholder="00.00" label="Amount" type="number"/>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <Input name="on" placeholder="0" label="On"  type="number"/>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <div className="form-group">
                                                    <label htmlFor="Units" className="form-label">Units</label>
                                                    <select id="Units" className="form-select" aria-label="Select">
                                                        <option selected>Case</option>
                                                        <option value="1">Case 1</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 mb-4">
                                <div className="card">
                                    <div className="card-body pb-2 pt-3">
                                        <div className="styleguide-head mb-4">
                                            <div className="d-flex flex-row align-items-center mb-2">
                                                <h5 className="me-auto mb-0">Discount 4</h5>
                                                <a className="fs-4" href="/">
                                                    <i className="bi-x-circle-fill"></i>
                                                </a>
                                            </div>
                                            <hr className="mt-1" />
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <div className="form-group">
                                                    <label htmlFor="Type" className="form-label">Type</label>
                                                    <select id="Type" className="form-select is-invalid" aria-label="Select">
                                                        <option selected>$</option>
                                                        <option value="1">$</option>
                                                        <option value="2">Euro</option>
                                                    </select>
                                                    <div id="validationServerFeedback" className="invalid-feedback">Please choose type</div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <div className="form-group">
                                                    <label htmlFor="validationServer" className="form-label">Amount</label>
                                                    <input id="validationServer" type="number" className="form-control is-invalid" placeholder="00.00" aria-describedby="validationServerFeedback" required />
                                                    <div id="validationServerFeedback" className="invalid-feedback">Please add amount</div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <Input name="on" placeholder="0" label="On"  type="number"/>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <div className="form-group">
                                                    <label htmlFor="Units" className="form-label">Units</label>
                                                    <select id="Units" className="form-select" aria-label="Select">
                                                        <option selected>Case</option>
                                                        <option value="1">Case 1</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
                        <a href="/" className="btn btn-secondary">Add new discount</a>
                        <button type="button" className="btn btn-primary">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default WholesalerManageDiscountsView;

