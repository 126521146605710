import httpClient from "api/HttpClient";
import BACKEND from "services/Constants";
import DeadlinesModel from "pages/admin/models/DeadlinesModel";

export default {
    load(): Promise<Array<DeadlinesModel>> {
        return httpClient.get(BACKEND + "/admin/deadlines/schedules-dates");
    },

    save(data: Array<DeadlinesModel>): Promise<void> {
        return httpClient.post(BACKEND + "/admin/deadlines/save-schedules-dates", { deadlines: data });
    }
};
