import React, { useEffect, useState } from "react";
import { modalStore } from "store/ModalStoreProvider";
import { useToastStore } from "store/ToastStoreProvider";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";
import WholesalerDelinquentService from "services/wholesaler/WholesalerDelinquentService";

export const showBatchDialogDialog = () => {
    modalStore.setBody(<BatchDialog />);
    modalStore.setHeader("Batch Submissions");
    modalStore.showModal();
};

export const BatchDialog = () => {

    const [ email, setEmail ] = useState<string>("");
    const { addToast } = useToastStore();

    useEffect(()=>{
        WholesalerDelinquentService.getAdminEmail().then(setEmail).catch(() => {
            addToast({ message:"Something went wrong. Please try again", header:"Batch Dialog",variant: "danger", timeoutMs: DEFAULT_TOASTER_TIMEOUT });
        });
    },[]);

    return <div>
        <p>The Batch Submission of Delinquency Records is available to users with the appropriate role configuration within their organization.</p>
        <p className="mb-0">If you wish to access this feature, please ask your business admin user at <a
            href={ "mailto:" + email }  rel="noreferrer" target="_blank">{ email }</a> to grant you the COD_BATCH_UPLOAD role.
        </p>
    </div>;
};
