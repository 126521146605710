import { SelectOption } from "components/form/Select";

export default class PackageTypeConstants {
    static PACKAGE_TYPES: SelectOption[] = [
        { value: "R", title: "Regular" },
        { value: "C", title: "Combo" },
        { value: "V", title: "VAP" }
    ];

    static filterByValue(filter?: string[]) {
        return (filter
            ? this.PACKAGE_TYPES.filter(el=> el.value ?  filter.indexOf(el.value.toString()) != -1 : false)
            : this.PACKAGE_TYPES
        ).slice();
    }

    static getTitleByCode(code: string): string | undefined{
        return PackageTypeConstants.PACKAGE_TYPES.find(c => c.value === code)?.title;
    }
}
