import { useAuthStore } from "store/AuthStoreProvider";
import { MenuSettings, useLeftMenu } from "components/menu/Menu";
import { MasterFilersLookup } from "pages/admin/master-filers/MasterFilersLookup";
import { CentralBookkeeping } from "pages/admin/CentralBookkeeping";
import { Deadlines } from "pages/admin/Deadlines";
import { Licenses } from "pages/admin/Licenses";
import { Principals } from "pages/admin/Principals";
import { UpcomingSchedules } from "pages/admin/UpcomingSchedules";
import { Settings } from "pages/admin/Settings";
import { CodDataRetailer } from "pages/admin/CodDataRetailer";
import { CodDataWholesaler } from "pages/admin/CodDataWholesaler";
import { Alerts } from "pages/admin/Alerts";
import { EditAlerts } from "pages/admin/EditAlerts";
import BrandsItems from "pages/admin/BrandsItems";
import MonthSchedule from "pages/admin/MonthSchedule";
import { TransferDelinquentData } from "pages/admin/TransferDelinquentData";
import TransferScheduleData from "pages/admin/TransferScheduleData";
import ManageUsers from "pages/admin/ManageUsers";
import LicenseUsers from "components/LicenseUsers";
import { JobsDashboard } from "pages/admin/JobsDashboard";
import { Route, Routes } from "react-router-dom";
import AuthenticatedRoute from "components/AuthenticatedRoute";
import ProtectedRoute from "components/ProtectedRoute";
import React from "react";
import NotFound from "pages/NotFound";
import { Console } from "pages/admin/Console";
import { HistoricReports } from "pages/admin/HistoricReports";
import Reports from "pages/admin/Reports";
import { useTimeStore } from "store/TimeStoreProvider";
import { PriceLookupNext } from "components/PriceLookupNext";
import { PriceLookup } from "components/PriceLookup";
import { PermissionStore } from "store/PermissionStore";
import { Dayjs } from "dayjs";

const RELATIVE_PATHS = {
    MAIN: "/admin",
    MASTER_FILERS: "/master-filers",
    ALERTS: "/alerts",
    CREATE_ALERTS: "/alerts/create",
    EDIT_ALERTS: "/alerts/edit/:id",
    CENTRAL_BOOKKEEPING: "/central-bookkeeping",
    SCHEDULE_DEADLINES: "/deadlines",
    TRANSFER_DELINQUENT_DATA: "/transfer-delinquent-data",
    TRANSFER_SCHEDULE_DATA: "/transfer-schedule-data",
    MANAGE_USERS: "/manage-users",
    LICENSES: {
        SEARCH: "/licenses",
        PRINCIPALS: "/licenses/principals/:licenseId",
        SETTINGS:  "/licenses/settings/:licenseId",
        USERS:  "/licenses/users/:licenseId",
        UPCOMING_SCHEDULES: {
            VIEW: "/licenses/upcoming-schedules/:licenseId",
            MONTH_SCHEDULE: "/licenses/upcoming-schedules/month-schedule/:licenseId/:type/:year/:month",
            BRANDS_ITEMS: "/licenses/upcoming-schedules/brands-items/:type/:licenseId/:year/:month"
        },
        COD_DATA_RETAILER: "/licenses/cod-data-retailer/:licenseId",
        COD_DATA_WHOLESALER: "/licenses/cod-data-wholesaler/:licenseId"
    },
    JOBS: "/jobs",
    CONSOLE: "/console",
    HISTORIC_REPORTS: "/historic-reports",
    REPORTS: "/reports",
    PRICE_LOOKUP: {
        CURRENT: "/price-lookup/current",
        NEXT: "/price-lookup/next"
    }
};

export const ADMIN_PATHS = {
    MAIN: RELATIVE_PATHS.MAIN + "/*",
    MASTER_FILERS: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.MASTER_FILERS,
    ALERTS: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.ALERTS,
    CREATE_ALERTS: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.CREATE_ALERTS,
    EDIT_ALERTS: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.EDIT_ALERTS,
    CENTRAL_BOOKKEEPING: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.CENTRAL_BOOKKEEPING,
    SCHEDULE_DEADLINES: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.SCHEDULE_DEADLINES,
    TRANSFER_DELINQUENT_DATA: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.TRANSFER_DELINQUENT_DATA,
    TRANSFER_SCHEDULE_DATA: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.TRANSFER_SCHEDULE_DATA,
    MANAGE_USERS: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.MANAGE_USERS,
    LICENSES: {
        SEARCH: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.LICENSES.SEARCH,
        PRINCIPALS: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.LICENSES.PRINCIPALS,
        SETTINGS: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.LICENSES.SETTINGS,
        USERS: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.LICENSES.USERS,
        UPCOMING_SCHEDULES: {
            VIEW: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.LICENSES.UPCOMING_SCHEDULES.VIEW,
            MONTH_SCHEDULE: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.LICENSES.UPCOMING_SCHEDULES.MONTH_SCHEDULE,
            BRANDS_ITEMS: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.LICENSES.UPCOMING_SCHEDULES.BRANDS_ITEMS
        },
        COD_DATA_RETAILER: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.LICENSES.COD_DATA_RETAILER,
        COD_DATA_WHOLESALER: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.LICENSES.COD_DATA_WHOLESALER
    },
    JOBS: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.JOBS,
    CONSOLE: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.CONSOLE,
    HISTORIC_REPORTS: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.HISTORIC_REPORTS,
    REPORTS: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.REPORTS,
    PRICE_LOOKUP: {
        CURRENT: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.PRICE_LOOKUP.CURRENT,
        NEXT: RELATIVE_PATHS.MAIN + RELATIVE_PATHS.PRICE_LOOKUP.NEXT
    }
};
export const LEFT_MENU = (permissionStore: PermissionStore, newYorkTime: Dayjs ): MenuSettings => ({
    caption: "Admin Menu",
    items: [
        {
            title: "Manage Licenses",
            link: ADMIN_PATHS.LICENSES.SEARCH,
            predicate: () => permissionStore.isSlaAdminOrAdminOrSlaCod()
        },
        {
            title: "Manage Users",
            link: ADMIN_PATHS.MANAGE_USERS,
            predicate: () => permissionStore.isSlaAdminOrAdmin()
        },
        {
            title: "Pricing Lookup",
            link: ADMIN_PATHS.PRICE_LOOKUP.CURRENT,
            predicate: () => permissionStore.isSlaAdminOrAdmin(),
            submenu:[
                {
                    title: newYorkTime.format("MMMM YYYY"),
                    link: ADMIN_PATHS.PRICE_LOOKUP.CURRENT
                },
                {
                    title: newYorkTime.add(1,"month").format("MMMM YYYY"),
                    link: ADMIN_PATHS.PRICE_LOOKUP.NEXT,
                    predicate: () => newYorkTime.date() >= 15
                }
            ]
        },
        {
            title: "Central Bookkeeping",
            link: ADMIN_PATHS.CENTRAL_BOOKKEEPING,
            predicate: () => permissionStore.isSlaAdminOrAdmin()
        },
        {
            title: "Historic Data Reports",
            link: ADMIN_PATHS.HISTORIC_REPORTS,
            predicate: () => permissionStore.isSlaAdminOrAdmin()
        },
        {
            title: "Admin Reports",
            link: ADMIN_PATHS.REPORTS,
            predicate: () => permissionStore.isSlaAdminOrAdminOrSlaCod()
        },
        {
            title: "Move Delinquent Data",
            link: ADMIN_PATHS.TRANSFER_DELINQUENT_DATA,
            predicate: () => permissionStore.isSlaAdminOrAdmin()
        },
        {
            title: "Transfer Schedule Data",
            link: ADMIN_PATHS.TRANSFER_SCHEDULE_DATA,
            predicate: () => permissionStore.isSlaAdminOrAdmin()
        },
        {
            title: "Manage Schedule Deadlines",
            link: ADMIN_PATHS.SCHEDULE_DEADLINES,
            predicate: () => permissionStore.isSlaAdminOrAdmin()
        },
        {
            title: "Master Filers",
            link: ADMIN_PATHS.MASTER_FILERS,
            predicate: () => permissionStore.isSlaAdminOrAdmin()
        },
        {
            title: "Alerts",
            link: ADMIN_PATHS.ALERTS,
            predicate: () => permissionStore.isSlaAdminOrAdmin()
        },
        {
            title: "Jobs Dashboard",
            link: ADMIN_PATHS.JOBS,
            predicate: () => permissionStore.isSystemAdmin()
        }/*, hidden from menu (NS-1837), but still available by exact URL
        {
            title: "Console",
            link: ADMIN_PATHS.CONSOLE,
            predicate: () => permissionStore.isSystemAdmin()
        }*/
    ]
});

const AdminRoutes = () => {

    const { permissionStore } = useAuthStore();
    const { newYorkTime } = useTimeStore();

    useLeftMenu(LEFT_MENU(permissionStore, newYorkTime));
    return <Routes>
        <Route element={ <AuthenticatedRoute/> }>
            <Route element={ <ProtectedRoute isAllowed={ permissionStore.isSlaAdminOrAdmin() }/> }>
                <Route path={ RELATIVE_PATHS.MASTER_FILERS } element={ <MasterFilersLookup/> }/>
                <Route path={ RELATIVE_PATHS.CENTRAL_BOOKKEEPING } element={ <CentralBookkeeping/> }/>
                <Route path={ RELATIVE_PATHS.SCHEDULE_DEADLINES } element={ <Deadlines/> }/>
                <Route path={ RELATIVE_PATHS.TRANSFER_DELINQUENT_DATA } element={ <TransferDelinquentData/> }/>
                <Route path={ RELATIVE_PATHS.TRANSFER_SCHEDULE_DATA } element={ <TransferScheduleData/> }/>
                <Route path={ RELATIVE_PATHS.MANAGE_USERS } element={ <ManageUsers/> }/>
                <Route path={ RELATIVE_PATHS.LICENSES.PRINCIPALS } element={ <Principals/> }/>
                <Route path={ RELATIVE_PATHS.LICENSES.SETTINGS } element={ <Settings/> }/>
                <Route path={ RELATIVE_PATHS.LICENSES.USERS } element={ <LicenseUsers/> }/>
                <Route path={ RELATIVE_PATHS.LICENSES.UPCOMING_SCHEDULES.BRANDS_ITEMS } element={ <BrandsItems/> }/>
                <Route path={ RELATIVE_PATHS.LICENSES.UPCOMING_SCHEDULES.MONTH_SCHEDULE } element={ <MonthSchedule/> }/>
                <Route path={ RELATIVE_PATHS.LICENSES.UPCOMING_SCHEDULES.VIEW } element={ <UpcomingSchedules/> }/>
                <Route path={ RELATIVE_PATHS.JOBS } element={ <JobsDashboard/> }/>
                <Route path={ RELATIVE_PATHS.CONSOLE } element={ <Console/> }/>
                <Route path={ RELATIVE_PATHS.PRICE_LOOKUP.CURRENT } element={ <PriceLookup/> }/>
            </Route>
            <Route element={ <ProtectedRoute isAllowed={ permissionStore.isSlaAdminOrAdmin() }/> }>
                <Route path={ RELATIVE_PATHS.ALERTS } element={ <Alerts/> }/>
                <Route path={ RELATIVE_PATHS.CREATE_ALERTS } element={ <EditAlerts/> }/>
                <Route path={ RELATIVE_PATHS.EDIT_ALERTS } element={ <EditAlerts/> }/>
            </Route>
            <Route element={ <ProtectedRoute isAllowed={ permissionStore.isSlaAdminOrAdminOrSlaCod() }/> }>
                <Route path={ RELATIVE_PATHS.LICENSES.SEARCH } element={ <Licenses/> }/>
                <Route path={ RELATIVE_PATHS.LICENSES.COD_DATA_RETAILER } element={ <CodDataRetailer/> }/>
                <Route path={ RELATIVE_PATHS.LICENSES.COD_DATA_WHOLESALER } element={ <CodDataWholesaler/> }/>
                <Route path={ RELATIVE_PATHS.HISTORIC_REPORTS } element={ <HistoricReports/> }/>
                <Route path={ RELATIVE_PATHS.REPORTS } element={ <Reports/> }/>
            </Route>
        </Route>
        <Route element={ <ProtectedRoute isAllowed={ newYorkTime.date() >= 15 && permissionStore.isSlaAdminOrAdmin() }/> }>
            <Route path={ RELATIVE_PATHS.PRICE_LOOKUP.NEXT } element={ <PriceLookupNext/> }/>
        </Route>
        <Route path={ "*" } element={ <NotFound/> }/>
    </Routes>;
};

export default AdminRoutes;
