import React from "react";

const DelinquentBatchView = () => {

    return <div className="d-flex flex-column min-vh-100">
        <div className="mb-3">
            <h1 className="text-primary mb-4">Delinquent Records - Batch Submission</h1>
            <p className="mb-0">Select CSV file with delinquency record and click &ldquo;Upload&rdquo; button.</p>
        </div>
        <div className="mb-4">
            <a className="btn btn-outline-primary me-3 mb-3" href="/">Read more about Batch Submission</a>
            <a className="btn btn-outline-primary mb-3" href="/">CSV Template</a>
        </div>
        <div className="steps-wr mb-4">
            <div className="steps-l row g-0">
                <div className="steps-li col-12 col-xl-3">
                    <div className="steps-li-in">
                        <strong>1</strong> Select CSV file to upload
                    </div>
                </div>
                <div className="steps-li col-12 col-xl-3 active">
                    <div className="steps-li-in">
                        <strong>2</strong> Preview batch submission
                    </div>
                </div>
            </div>
        </div>
        <div className="card mt-3">
            <div className="card-body">
                <div className="mb-3">
                    <h4><strong>Step 2:</strong> Preview batch submission</h4>
                </div>
                <div className="">
                    <div className="nav nav-pills btn-group flex-column flex-lg-row d-lg-inline-flex mb-0" id="nav-tab" role="tablist" aria-orientation="vertical">
                        <button className="btn btn-outline-secondary min-wp-230 active" id="nav-1-tab" data-bs-toggle="tab" data-bs-target="#nav-1" type="button" role="tab" aria-controls="nav-1" aria-selected="true">Correct Records <span className="badge rounded-pill bg-success ms-1">6</span></button>
                        <button className="btn btn-outline-secondary min-wp-230" id="nav-2-tab" data-bs-toggle="tab" data-bs-target="#nav-2" type="button" role="tab" aria-controls="nav-2" aria-selected="false">Incorrect Records <span className="badge rounded-pill bg-danger ms-1">3</span></button>
                    </div>
                </div>
            </div>
        </div>
        <div className="tab-content mt-4" id="nav-tabContent">
            <div className="tab-pane fade show active" id="nav-1" role="tabpanel" aria-labelledby="nav-1-tab">
                <div className="pt-3">
                    <h5 className="mb-3"><strong>Total records:</strong> 5</h5>
                </div>
                <div className="table-responsive mb-4">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Intent</th>
                                <th>Wholesaler_Serial_Number</th>
                                <th>Retailer_Serial_Number</th>
                                <th>Delivery_Amount</th>
                                <th>Delivery_Date</th>
                                <th>Comment</th>
                                <th>Retailer_Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>gsome-email@gmail.com</td>
                                <td>74775555</td>
                                <td>22/12/2022</td>
                                <td>Friday</td>
                                <td></td>
                                <td></td>
                                <td>America, Los Angeles</td>
                            </tr>
                            <tr>
                                <td>some-email@gmail.com</td>
                                <td>67775555</td>
                                <td>22/12/2022</td>
                                <td>Friday</td>
                                <td></td>
                                <td></td>
                                <td>America, Los Angeles</td>
                            </tr>
                            <tr>
                                <td>gsome-email@gmail.com</td>
                                <td>74775555</td>
                                <td>22/12/2022</td>
                                <td>Friday</td>
                                <td></td>
                                <td></td>
                                <td>America, Los Angeles</td>
                            </tr>
                            <tr>
                                <td>some-email@gmail.com</td>
                                <td>67775555</td>
                                <td>22/12/2022</td>
                                <td>Friday</td>
                                <td></td>
                                <td></td>
                                <td>America, Los Angeles</td>
                            </tr>
                            <tr>
                                <td>gsome-email@gmail.com</td>
                                <td>74775555</td>
                                <td>22/12/2022</td>
                                <td>Friday</td>
                                <td></td>
                                <td></td>
                                <td>America, Los Angeles</td>
                            </tr>
                            <tr>
                                <td>some-email@gmail.com</td>
                                <td>67775555</td>
                                <td>22/12/2022</td>
                                <td>Friday</td>
                                <td></td>
                                <td></td>
                                <td>America, Los Angeles</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <ul className="justify-content-center pagination mb-5">
                    <li className="page-item disabled">
                        <span className="page-link">
                            <span className="bi bi-arrow-left me-2"></span>Prev
                        </span>
                    </li>
                    <li className="page-item active">
                        <span className="page-link">1</span>
                    </li>
                    <li className="page-item">
                        <a className="page-link" role="button" href="/">2</a>
                    </li>
                    <li className="page-item">
                        <a className="page-link" role="button" href="/">3</a>
                    </li>
                    <li className="page-item">
                        <a className="page-link" role="button" href="/">...</a>
                    </li>
                    <li className="page-item">
                        <a className="page-link" role="button" href="/">50</a>
                    </li>
                    <li className="page-item">
                        <a className="page-link" role="button" href="/">
                            Next<span className="bi bi-arrow-right ms-2"></span>
                        </a>
                    </li>
                </ul>
                <div className="row justify-content-center">
                    <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                        <a href="/delinquent/batch2" className="btn btn-outline-secondary w-100">Start over</a>
                    </div>
                    <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                        <a href="/delinquent/batch4" className="btn btn-primary w-100">Finish import</a>
                    </div>
                </div>
            </div>
            <div className="tab-pane fade" id="nav-2" role="tabpanel" aria-labelledby="nav-2-tab">
                <div className="pt-3 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                    <h5 className="mb-3"><strong>Total records:</strong> 5</h5>
                    <a className="mb-3" href="/">Download file with invalid records</a>
                </div>
                <div className="alert alert-danger mb-4" role="alert">
                    <i className="bi bi-exclamation-circle-fill me-2"></i>
                    <strong>Import error:</strong> Oops,your file was not successfully uploaded. Please make the corrections and try again.
                    <div className="invalid-message alert alert-sm mt-3 mb-0 pb-0 ps-4">
                        <ul>
                            <li className="mb-2">
                                <a className="text-danger" href="#error1">Wholesaler ID is invalid on line 1</a>
                            </li>
                            <li className="mb-2">
                                <a className="text-danger" href="#error1">Retailer_Serial_Number ID is invalid on line 3</a>
                            </li>
                            <li className="mb-2">
                                <a className="text-danger" href="#error2">Delivery date is invalid or in a wrong format on line 15</a>
                            </li>
                        </ul>
                        <a className="text-danger ms-3" href="/" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            <i className="bi-three-dots fs-4"></i>
                        </a>
                    </div>
                </div>
                <div className="table-responsive mb-2">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Intent</th>
                                <th>Wholesaler_Serial_Number</th>
                                <th>Retailer_Serial_Number</th>
                                <th>Delivery_Amount</th>
                                <th>Delivery_Date</th>
                                <th>Comment</th>
                                <th>Retailer_Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>bladldd.fffhsgf.jjj</td>
                                <td>674775555</td>
                                <td>22/12/2022</td>
                                <td>Friday</td>
                                <td></td>
                                <td></td>
                                <td>America, Los Angeles</td>
                            </tr>
                            <tr id="error1">
                                <td colSpan={ 7 } className="p-0">
                                    <div className="alert-danger p-2">
                                        <i className="bi-arrow-up me-2 fw-bold"></i>
                                        Email address is not valid: &apos;bladldd.fffhsgf.jjj&apos;
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>some-email@gmail.com</td>
                                <td>67775555</td>
                                <td>hhhhg</td>
                                <td>Friday</td>
                                <td></td>
                                <td></td>
                                <td>America, Los Angeles</td>
                            </tr>
                            <tr id="error2">
                                <td colSpan={ 7 } className="p-0">
                                    <div className="alert-danger p-2">
                                        <i className="bi-arrow-up me-2 fw-bold"></i>
                                        Unrecognizable due day: &apos;hhhhg&apos;
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>gsome-email@gmail.com</td>
                                <td>74775555</td>
                                <td>22/12/2022</td>
                                <td>Friday</td>
                                <td></td>
                                <td></td>
                                <td>America, Los Angeles</td>
                            </tr>
                            <tr>
                                <td>some-email@gmail.com</td>
                                <td>67775555</td>
                                <td>22/12/2022</td>
                                <td>Friday</td>
                                <td></td>
                                <td></td>
                                <td>America, Los Angeles</td>
                            </tr>
                            <tr>
                                <td>gsome-email@gmail.com</td>
                                <td>74775555</td>
                                <td>22/12/2022</td>
                                <td>Friday</td>
                                <td></td>
                                <td></td>
                                <td>America, Los Angeles</td>
                            </tr>
                            <tr>
                                <td>some-email@gmail.com</td>
                                <td>67775555</td>
                                <td>22/12/2022</td>
                                <td>Friday</td>
                                <td></td>
                                <td></td>
                                <td>America, Los Angeles</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p className="text-danger mb-4">Please correct the errors in CSV file and try uploading again</p>
                <ul className="justify-content-center pagination mb-5">
                    <li className="page-item disabled">
                        <span className="page-link">
                            <span className="bi bi-arrow-left me-2"></span>Prev
                        </span>
                    </li>
                    <li className="page-item active">
                        <span className="page-link">1</span>
                    </li>
                    <li className="page-item">
                        <a className="page-link" role="button" href="/">2</a>
                    </li>
                    <li className="page-item">
                        <a className="page-link" role="button" href="/">3</a>
                    </li>
                    <li className="page-item">
                        <a className="page-link" role="button" href="/">...</a>
                    </li>
                    <li className="page-item">
                        <a className="page-link" role="button" href="/">50</a>
                    </li>
                    <li className="page-item">
                        <a className="page-link" role="button" href="/">
                            Next<span className="bi bi-arrow-right ms-2"></span>
                        </a>
                    </li>
                </ul>
                <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content text-danger">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Import error</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Cancel"></button>
                            </div>
                            <div className="modal-body">
                                <p className="mb-0">You have more errors. Try to correct them before proceeding</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default DelinquentBatchView;
