
export const isDevelopment: boolean = process.env.NODE_ENV === "development";

export const [APP_URL, SUPPORT_EMAIL] =
    isDevelopment ? [
        "http://localhost:8080/",
        "support@nyslapricepostings.com"
    ] : [
        `${window.location.origin}/`,
        "support@nyslapricepostings.com"
    ];

export const GRECAPTCHA_SITEKEY = "6LftNv4kAAAAAB7mEgzTCNTD7S39Hy6be5CAh4rz";
