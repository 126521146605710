import { Role, RolesEnum } from "models/Role";
import { action, computed, makeObservable, observable, toJS } from "mobx";
export class PermissionStore {

    @observable
    public isAdmin = false;
    @observable
    public isBusinessAdmin = false;
    @observable
    public isWholesaler = false;
    @observable
    public isRetailer = false;
    @observable
    public isCodBatchUpload = false;
    @observable
    private roles: Role[] = [];
    @observable
    public initialized = false;

    constructor() {
        makeObservable(this);
    }

    @action
    public setRoles(roles: string[]) {
        this.roles = roles.map((s: string) => ({ name: s } as Role));
    }

    @action
    initialize() {
        this.isAdmin = this.hasRole(RolesEnum.ADMIN);
        this.isBusinessAdmin = this.hasRole(RolesEnum.BUSINESS_ADMIN);
        this.isWholesaler = this.hasRole(RolesEnum.WHOLESALER);
        this.isRetailer = this.hasRole(RolesEnum.RETAILER);
        this.isCodBatchUpload = this.hasRole(RolesEnum.COD_BATCH_UPLOAD);
        this.initialized = true;
    }

    isSlaAdminOrAdmin = () => this.isAdmin || this.hasRole(RolesEnum.SLA_ADMIN);
    isSlaAdminOrAdminOrSlaCod = () => this.isAdmin || this.hasRole(RolesEnum.SLA_ADMIN) || this.hasRole(RolesEnum.SLA_COD);

    isSystemAdmin = () => this.isAdmin || this.hasRole(RolesEnum.ADMIN);

    isSlaCod(): boolean {
        return this.hasRole(RolesEnum.SLA_COD);
    }

    isWholesalerAdmin(): boolean {
        if (this.isAdmin) {
            return true;
        }
        return this.isBusinessAdmin && this.isWholesaler;
    }

    isRetailerAdmin(): boolean {
        if (this.isAdmin) {
            return true;
        }
        return this.isBusinessAdmin && this.isRetailer;
    }

    isRetailerAndCodRead(): boolean {
        if (this.isAdmin) {
            return true;
        }
        return this.isRetailer && (this.isBusinessAdmin || this.hasRole(RolesEnum.COD_READ));
    }

    isRetailerAndPriceRead(): boolean {
        if (this.isAdmin) {
            return true;
        }
        return this.isRetailer && (this.isBusinessAdmin || this.hasRole(RolesEnum.PRICES_READ));
    }

    isWholesalerAndBatchUpload(): boolean {
        if (this.isAdmin) {
            return true;
        }
        return this.isWholesaler && (this.isCodBatchUpload || this.hasRole(RolesEnum.BUSINESS_ADMIN));
    }

    isWholesalerAndPricePosting(): boolean {
        return this.isWholesaler &&
            (  this.hasRole(RolesEnum.BUSINESS_ADMIN)
            || this.hasRole(RolesEnum.PRICES_READ)
            || this.hasRole(RolesEnum.PRICES_WRITE)
            || this.hasRole(RolesEnum.PRICES_CERTIFY)
            );
    }

    isWholesalerAndPricesCertifyOrWrite(): boolean {
        return this.isWholesaler &&
            (  this.hasRole(RolesEnum.BUSINESS_ADMIN)
            || this.hasRole(RolesEnum.PRICES_WRITE)
            || this.hasRole(RolesEnum.PRICES_CERTIFY));
    }

    canCertify(): boolean {
        return this.isAdmin || this.hasRole(RolesEnum.SLA_ADMIN)
            || (this.isWholesaler && (this.isBusinessAdmin || this.hasRole(RolesEnum.PRICES_CERTIFY)));
    }

    isWholesalerAndBA_CR_CW_CA_CB():boolean {
        return this.isWholesaler &&
            (  this.hasRole(RolesEnum.BUSINESS_ADMIN)
                || this.hasRole(RolesEnum.COD_READ)
                || this.hasRole(RolesEnum.COD_WRITE)
                || this.hasRole(RolesEnum.COD_API)
                || this.hasRole(RolesEnum.COD_BATCH_UPLOAD)
            );
    }

    isWholesalerAndBA_CR_CW_CA():boolean {
        return this.isWholesaler &&
            (  this.hasRole(RolesEnum.BUSINESS_ADMIN)
                || this.hasRole(RolesEnum.COD_READ)
                || this.hasRole(RolesEnum.COD_WRITE)
                || this.hasRole(RolesEnum.COD_API)
            );
    }

    isWholesalerAndBA_CW_CA_CB():boolean {
        return this.isWholesaler &&
            (  this.hasRole(RolesEnum.BUSINESS_ADMIN)
                || this.hasRole(RolesEnum.COD_WRITE)
                || this.hasRole(RolesEnum.COD_API)
                || this.hasRole(RolesEnum.COD_BATCH_UPLOAD)
            );
    }

    isWholesalerAndBA_CW():boolean {
        return this.isWholesaler &&
            (  this.hasRole(RolesEnum.BUSINESS_ADMIN)
                || this.hasRole(RolesEnum.COD_WRITE)
            );
    }

    hasRole(role: RolesEnum): boolean {
        const roles = toJS(this.roles) as Role[];
        return roles.some( r => r.name == role );
    }

    hasAnyRole(roles: RolesEnum[]): boolean {
        const userRoles = toJS(this.roles) as Role[];
        let notFound = true;
        userRoles.forEach(role => {
            const found = roles.find(roleEnum => roleEnum == role.name);
            if (!found) {
                notFound = false;
                return;
            }
        });
        return notFound;
    }

    hasAllRoles(roles: RolesEnum[]): boolean {
        const userRoles = this.roles.map( r => r.name);
        return roles.every( r => userRoles.includes(r.toString()) );
    }

    @computed
    get isReady() { return this.initialized; }

    @action
    setInitialized(initialized: boolean) {
        this.initialized = initialized;
    }

    @action
    reset() {
        this.setRoles([]);
    }

    isWholesalerAdminOrCodApi(): boolean {
        return this.hasRole(RolesEnum.BUSINESS_ADMIN) || this.hasRole(RolesEnum.COD_API);
    }
}
