import React, { useEffect, useState } from "react";
import { Link, generatePath, useNavigate } from "react-router-dom";
import { ADMIN_PATHS } from "pages/admin/AdminRoutes";
import AlertService from "services/AlertService";
import { AlertModel } from "models/AlertModel";
import { useToastStore } from "store/ToastStoreProvider";
import Spinner from "components/Spinner";
import { alignOptions, messageTypeOptions, visibilityOptions } from "pages/admin/EditAlerts";
import { showDeleteAlertDialog } from "pages/admin/dialogs/DeleteAlertDialog";
import { useAuthStore } from "store/AuthStoreProvider";
import classNames from "classnames";

export const Alerts = () => {

    const { addDefaultDangerToast } = useToastStore();
    const navigate = useNavigate();
    const auth = useAuthStore();
    const currentLicenseIsActive = auth.currentLicense?.active;
    const [alerts, setAlerts] = useState<AlertModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(loadData, []);

    function loadData() {
        setIsLoading(true);
        AlertService.getAllForAdmin()
            .then(setAlerts)
            .catch(() => addDefaultDangerToast("Alerts Page"))
            .finally(() => setIsLoading(false));
    }

    function onDeleteClick(id: number) {
        AlertService.delete(id)
            .then(() => navigate(0))
            .catch(() => addDefaultDangerToast("Delete alert."));
    }

    return <div className="d-flex flex-column">
        <div className="mb-4 pb-md-3">
            <h1 className="text-primary mb-4">Alerts</h1>
            <p className="mb-0">This page provides the view and management for custom alerts / messages for the SLA platform.</p>
        </div>
        <div className="d-flex flex-column flex-lg-row align-items-end">
            <div className="me-lg-auto pb-3">
                <h5 className="mb-0">Found <strong> { alerts.length } Alerts / messages</strong></h5>
            </div>
            <div className="pb-3 ms-lg-3">
                <Link className={ classNames("btn","btn-primary",{ "disabled": !currentLicenseIsActive }) }
                    to={ ADMIN_PATHS.CREATE_ALERTS }>Create New Message</Link>
            </div>
        </div>
        <div className="table-responsive mt-1">
            <Spinner hidden={ !isLoading } className="mt-4 pt-2 text-center"/>
            <table className="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th>Message Type</th>
                        <th>Visibility</th>
                        <th>Align Type</th>
                        <th>Message</th>
                        <th>Active</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    { !isLoading && alerts.map(alert =>
                        <tr key={ alert.id }>
                            <td>{ messageTypeOptions.find(value => value.value ==alert.message_type)?.title }</td>
                            <td>{ visibilityOptions.find(value => value.value ==alert.visibility)?.title }</td>
                            <td>{ alignOptions.find(value => value.value ==alert.align_type)?.title }</td>
                            <td>{ alert.message }</td>
                            <td>{ alert.active? "Yes" : "No" }</td>
                            <td className="text-nowrap">
                                <button
                                    type="button" className="btn btn-link py-0 px-1 me-2" title="Edit"
                                    disabled={ !currentLicenseIsActive }
                                    onClick={ () => navigate(generatePath(ADMIN_PATHS.EDIT_ALERTS, { id: alert.id })) }>
                                    <i className="bi-pencil"></i>
                                </button>
                                <button
                                    type="button" className="btn btn-link py-0 px-1" title="Delete"
                                    disabled={ !currentLicenseIsActive }
                                    onClick={ () => showDeleteAlertDialog( () => onDeleteClick(alert.id)) }>
                                    <i className="bi-trash"></i>
                                </button>
                            </td>
                        </tr>
                    ) }
                </tbody>
            </table>
        </div>
    </div>;
};

