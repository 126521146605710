import React, { useEffect, useState } from "react";
import { Link, generatePath, useLocation, useParams } from "react-router-dom";
import { BatchOperationResult } from "models/BatchProductsOperationResult";
import { useToastStore } from "store/ToastStoreProvider";
import ProductConstants, { PostTypeCode } from "services/ProductConstants";
import { WHOLESALER_PATHS } from "pages/wholesaler/WholesalerRoutes";
import ProductList, {
    Correctness,
    EntityWithPaging,
    emptyEntityWithPaging
} from "pages/wholesaler/priceposting/pages/batch/components/ProductList";
import { ProductToggledModel } from "models/ProductToggledModel";
import PricePostingTitle from "components/PricePostingTitle";
import { SampleFormatsButton } from "components/SampleFormatsButton";
import { SampleXlsButton } from "components/SampleXlsButton";
import { SampleTxtButton } from "components/SampleTxtButton";
import classNames from "classnames";
import { ImportSubject } from "models/ImportDiscriminator";
import WizardStepsBar from "components/WizardStepsBar";

const BatchProductsResults = () => {

    const location = useLocation();
    const { addToast } = useToastStore();

    const [operationResult] = useState<BatchOperationResult>(location.state as BatchOperationResult);
    const { type = "", year= "", month= "" } = useParams();
    const parsedType = ProductConstants.postTypeByString(type as PostTypeCode);

    const [ correctProducts, setCorrectProducts] = useState<EntityWithPaging<ProductToggledModel>>(emptyEntityWithPaging());
    const [ invalidProducts, setInvalidProducts] = useState<EntityWithPaging<ProductToggledModel>>(emptyEntityWithPaging());

    useEffect(()=>{
        if (!operationResult.success){
            addToast({
                header: "Price Posting",
                message: operationResult.message || "Something went wrong. Please try again",
                variant: "danger"
            });
        }
    },[]);

    const tabBadge = (entity: EntityWithPaging<any>, zeroSuccess: boolean)=>{
        const isZero = entity.totalCount == 0;
        const classes= classNames("badge","rounded-pill","ms-1",
            {
                "bg-info" : entity.loading,
                "bg-danger":  !entity.loading && ( zeroSuccess != isZero ),
                "bg-success": !entity.loading && ( zeroSuccess == isZero )
            }
        );

        return <span className={ classes }>
            { entity.loading ? "loading" : entity.totalCount }
        </span>;
    };

    return <div className="d-flex flex-column">
        <PricePostingTitle type={ type } year={ year } month={ month } />
        <div className="mb-3">
            <p className="mb-0">Review results of the submission process. You can edit records later in an interactive mode.</p>
        </div>
        <div className="mb-4">
            <SampleFormatsButton subject={ ImportSubject.PRODUCTS }/>
            <SampleXlsButton subject={ ImportSubject.PRODUCTS }/>
            <SampleTxtButton subject={ ImportSubject.PRODUCTS }/>
        </div>
        <WizardStepsBar steps={ ["Upload Price Posting file","Preview Batch Submission"] } activity={ [false,true] }/>
        <div className="card mt-4">
            <div className="card-body">
                <div className="mb-3">
                    <h4><strong>Step 2:</strong> Preview batch submission</h4>
                </div>
                <div className="">
                    <div className="nav nav-pills btn-group flex-column flex-lg-row d-lg-inline-flex mb-0" id="nav-tab" role="tablist" aria-orientation="vertical">
                        <button className="btn btn-outline-secondary min-wp-230 active" id="nav-1-tab" data-bs-toggle="tab"
                            data-bs-target="#nav-1" type="button" role="tab" aria-controls="nav-1" aria-selected="true">
                            Correct Records
                            { tabBadge(correctProducts, false) }
                        </button>
                        <button className="btn btn-outline-secondary min-wp-230" id="nav-2-tab" data-bs-toggle="tab"
                            data-bs-target="#nav-2" type="button" role="tab" aria-controls="nav-2" aria-selected="false">
                            Incorrect Records
                            { tabBadge(invalidProducts, true) }
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div className="tab-content mt-4" id="nav-tabContent">
            <div className="tab-pane fade show active" id="nav-1" role="tabpanel" aria-labelledby="nav-1-tab">
                <div className="pt-3">
                    <h5 className="mb-3"><strong>Total records:</strong> { correctProducts.totalCount }</h5>
                </div>
                <ProductList products={ correctProducts } setProducts={ setCorrectProducts } correct={ Correctness.ONLY_CORRECT }
                    type={ parsedType } createdAtStart={ operationResult?.startMillis } createdAtStop={ operationResult?.stopMillis }/>
            </div>
            <div className="tab-pane fade" id="nav-2" role="tabpanel" aria-labelledby="nav-2-tab">
                <div className="pt-3 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                    <h5 className="mb-3"><strong>Total records:</strong> { invalidProducts.totalCount }</h5>
                </div>
                <ProductList products={ invalidProducts } setProducts={ setInvalidProducts } correct={ Correctness.ONLY_INVALID }
                    type={ parsedType } createdAtStart={ operationResult?.startMillis } createdAtStop={ operationResult?.stopMillis }/>
            </div>
        </div>

        <div className="row justify-content-center">
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                <Link
                    to={ generatePath(WHOLESALER_PATHS.PRICE_POSTING.BATCH_IMPORT, { "type":type, "year": year, "month": month }) }
                    className="btn btn-outline-secondary w-100">
                    Back to File selection
                </Link>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mt-3 mt-sm-1">
                <a href={ WHOLESALER_PATHS.PRICE_POSTING.MAIN } className="btn btn-primary w-100">
                    Complete Submission
                </a>
            </div>
        </div>
    </div>;
};

export default BatchProductsResults;

