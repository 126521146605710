import React from "react";

type LicenseStatusProps = {
    statusCode: string;
}

interface LicenseStatus {
    class1: string;
    class2: string;
    title: string;
}

export const LICENSE_STATUSES = new Map<string, LicenseStatus>();
LICENSE_STATUSES.set("A", { class1: "badge bg-success", class2: "bi-check-circle",  title: "Active" });
LICENSE_STATUSES.set("I", { class1: "badge bg-secondary", class2: "bi-pause-circle", title: "Inactive" });
LICENSE_STATUSES.set("P", { class1: "badge bg-info", class2: "bi-clock-history", title: "Pending" });
LICENSE_STATUSES.set("T", { class1: "badge bg-success", class2: "bi-hourglass-top", title: "Temp permit" });
LICENSE_STATUSES.set("S", { class1: "badge bg-orange", class2: "bi-exclamation-circle", title: "Sapa" });


const LicenseStatusCell = ({ statusCode } : LicenseStatusProps) => {
    const cell = LICENSE_STATUSES.get(statusCode);
    if (!cell) {
        return null;
    }
    return <span className={ cell.class1 }>
        <i className={ cell.class2 }></i> { cell.title }
    </span>;
};

export default LicenseStatusCell;
