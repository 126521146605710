import httpClient from "api/HttpClient";
import BACKEND from "services/Constants";
import { CountItem } from "models/response/CountItem";
import { PriceScheduleByDate } from "models/response/PriceScheduleByDate";
import { PricesStatusAndDate } from "models/response/PricesStatusAndDate";

const ROOT_PATH = `${BACKEND}/wholesaler/price-posting`;

export default {

    fetchPriceSchedules(): Promise<PriceScheduleByDate[]> {
        return httpClient.get(`${ROOT_PATH}/fetchPriceSchedules`);
    },

    fetchAmendmentSchedules(): Promise<PriceScheduleByDate[]> {
        return httpClient.get(`${ROOT_PATH}/fetchAmendmentSchedules`);
    },

    countProductsByBeverageTypes(postType: string, year: string, month: string, certifiedNeeded = false): Promise<Array<CountItem>> {
        return httpClient.get(`${ROOT_PATH}/countProductsByBeverageTypes`,
            { params: { type: postType, year: year, month: month, certifiedNeeded: certifiedNeeded } });
    },

    countProductsByPackageTypes(postType: string, year: string, month: string, certifiedNeeded = false): Promise<Array<CountItem>> {
        return httpClient.get(`${ROOT_PATH}/countProductsByPackageTypes`,
            { params: { type: postType, year: year, month: month, certifiedNeeded: certifiedNeeded } });
    },

    isProductsValid(type: string, year:string, month: string, certifiedNeeded = false): Promise<boolean> {
        return httpClient.get(  `${ROOT_PATH}/checkProductsValid`,
            { params: { type: type, year: year, month: month, certifiedNeeded: certifiedNeeded } });
    },

    fetchActions(type: string, year:string, month: string, certifiedNeeded = false): Promise<Array<string>> {
        return httpClient.get(  `${ROOT_PATH}/availableActions?`,
            { params: { type: type, year: year, month: month, certifiedNeeded: certifiedNeeded } });
    },

    getStatus(type: string, year:string, month: string, certifiedNeeded = false): Promise<PricesStatusAndDate> {
        return httpClient.get(  `${ROOT_PATH}/getStatus`,
            { params: { type: type, year: year, month: month, certifiedNeeded: certifiedNeeded } });
    },

    canDoAction(actionsList: Array<string>, action: string): boolean {
        if (actionsList == undefined || action == undefined) return false;

        return actionsList.indexOf(action) != -1;
    },

    revertPricesFromCertified(postType: string, year: string, month: string) {
        return httpClient.post(  `${ROOT_PATH}/revertFromCertified?type=`+postType+"&year="+year+"&month="+month);
    }

};
