import React, { useState } from "react";
import { UserModel } from "models/UserModel";
import UserService from "services/UserService";
import Spinner from "components/Spinner";
import { modalStore } from "store/ModalStoreProvider";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";
import { useToastStore } from "store/ToastStoreProvider";

export const showDeleteUserDialog = (user: UserModel, onSuccessDelete: any, licenseIdToRemoveFrom?: number) => {
    modalStore.setBody(<DeleteUserDialog user={ user } licenseIdToRemoveFrom={ licenseIdToRemoveFrom } onSuccessDelete={ onSuccessDelete } />);
    modalStore.setHeader("Delete User");
    modalStore.showModal();
};

/*
    This dialog can be used for:
    - removing user from specific license ("licenseIdToRemoveFrom" parameter is specified) - should be available to SLA_ADMIN as well as to BA
    - deleting user from DB - should be available to SLA_ADMIN/ADMIN only
*/
const DeleteUserDialog = ({ user, licenseIdToRemoveFrom, onSuccessDelete }: { user: UserModel, licenseIdToRemoveFrom?: number, onSuccessDelete: any }) => {
    const { addToast, addDefaultDangerToast } = useToastStore();
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);

    function deleteUser() {
        setIsDataLoading(true);
        (licenseIdToRemoveFrom ? UserService.removeFromLicense(user.id, licenseIdToRemoveFrom) : UserService.deleteUser(user.id))
            .then(() => {
                setIsDataLoading(false);
                addToast({
                    message: licenseIdToRemoveFrom ? "Selected user has been removed from the license." : "Selected user has been removed from system",
                    header: "Manage Users",
                    variant: "success",
                    timeoutMs: DEFAULT_TOASTER_TIMEOUT
                });
                onSuccessDelete();
            })
            .catch(() => addDefaultDangerToast("Manage Users"))
            .finally(() => modalStore.hideModal());
    }
    modalStore.setAdditionalButtons([
        <button key={ 0 } type="button" className="btn btn-primary" onClick={ () => deleteUser() }>Yes</button>
    ]);

    return <div>
        <p className="mb-0">
            You are going to delete user <b>{ user.first_name } { user.last_name }</b> from the&nbsp;
            { licenseIdToRemoveFrom ? "license": "system" }. Are you sure?</p>
        <Spinner className="mt-4 pt-2 text-center" hidden={ !isDataLoading }/>
    </div>;
};
