import React from "react";

const WholesalerPricePostingView = () => {

    return <div className="d-flex flex-column">
        <div className="mb-4 mb-md-5">
            <h1 className="text-primary mb-4">Price Posting</h1>
            <p>No brand of liquor or wine shall be sold to or purchased by any licensee unless a price schedule is filed with the Liquor Authority and is then in effect.</p>
            <p>The table below indicates the completion status for the price schedules associated with this license. Please select the action that you want to perform.</p>
            <p className="mb-0 text-danger">The NYS Gaming Commission, not the NYSLA, is mandated to issue liquor licenses for certain casinos. Montreign Operating Company LLC d/b/a Resorts World Catskills has been issued a temporary license by the Commission authorizing NYS licensed wholesalers to sell alcoholic beverages to them, pursuant to the terms of that license.</p>

            <div className="mt-4">
                <div className="btn-group me-3 mb-3">
                    <button type="button" className="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        Formats
                    </button>
                    <ul className="dropdown-menu">
                        <li>
                            <a className="dropdown-item" href="https://www.nyslapricepostings.com/docs/BatchFileFormat.pdf">Price Posting format</a>
                        </li>
                        <li>
                            <a className="dropdown-item" href="https://www.nyslapricepostings.com/docs/DiscountFileFormat.pdf">Discount Table format</a>
                        </li>
                    </ul>
                </div>
                <div className="btn-group me-3 mb-3">
                    <button type="button" className="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        Sample <b>CSV</b> file
                    </button>
                    <ul className="dropdown-menu">
                        <li>
                            <a className="dropdown-item" href="https://www.nyslapricepostings.com/docs/LiquorToRetail.xls">Liquor to Retailer</a>
                        </li>
                        <li>
                            <a className="dropdown-item" href="https://www.nyslapricepostings.com/docs/WineToRetail.xls">Wine to Retailer</a>
                        </li>
                        <li>
                            <a className="dropdown-item" href="https://www.nyslapricepostings.com/docs/LiquorToWholesale.xls">Liquor to Wholesaler</a>
                        </li>
                        <li>
                            <a className="dropdown-item" href="https://www.nyslapricepostings.com/docs/WineToWholesale.xls">Wine to Wholesaler</a>
                        </li>
                        <li>
                            <a className="dropdown-item" href="https://www.nyslapricepostings.com/docs/DiscountCodes.xls">Discount Table</a>
                        </li>
                    </ul>
                </div>
                <div className="btn-group me-3 mb-3">
                    <button type="button" className="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        Sample <b>TXT</b> batch files
                    </button>
                    <ul className="dropdown-menu">
                        <li>
                            <a className="dropdown-item" href="https://www.nyslapricepostings.com/docs/LiquorToRetail.txt">Liquor to Retailer</a>
                        </li>
                        <li>
                            <a className="dropdown-item" href="https://www.nyslapricepostings.com/docs/WineToRetail.txt">Wine to Retailer</a>
                        </li>
                        <li>
                            <a className="dropdown-item" href="https://www.nyslapricepostings.com/docs/LiquorToWholesale.txt">Liquor to Wholesaler</a>
                        </li>
                        <li>
                            <a className="dropdown-item" href="https://www.nyslapricepostings.com/docs/WineToWholesale.txt">Wine to Wholesaler</a>
                        </li>
                        <li>
                            <a className="dropdown-item" href="https://www.nyslapricepostings.com/docs/DiscountCodes.txt">Discount Table</a>
                        </li>
                    </ul>
                </div>
                <a className="btn btn-outline-primary me-3 mb-3" href="https://support.nyslapricepostings.com/s/article/Mid-month-Limited-Availability">Mid-Month Limited Availability Tutorial</a>
                <div className="btn-group mb-3">
                    <button type="button" className="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        Basic Info On Licensee Responsibilities
                    </button>
                    <ul className="dropdown-menu">
                        <li>
                            <a className="dropdown-item" href="https://www.nyslapricepostings.com/docs/Basic Info On Licensee Responsibilities - Liquor Wine Wholesaler Manufacturers.pdf">Liquor Wine Wholesaler Manufacturers</a>
                        </li>
                        <li>
                            <a className="dropdown-item" href="https://www.nyslapricepostings.com/docs/Basic Info On Licensee Responsibilities - Beer Cider Wholesale Manufacturing.pdf">Beer Cider Wholesale Manufacturing</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="">
            <h5>Schedules Effective October 2022</h5>
            <div className="table-responsive mt-4">
                <table className="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th className="w-30">Price Schedule</th>
                            <th className="w-13 min-wp-120">Current Status</th>
                            <th>Total</th>
                            <th>Valid</th>
                            <th>Errors</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Liquor to Retailer</td>
                            <td>
                                <span className="badge bg-orange"><i className="bi-exclamation-circle"></i>Not Certified</span>
                            </td>
                            <td>1,679</td>
                            <td>4</td>
                            <td className="text-danger">1,675</td>
                            <td>
                                <a className="btn btn-link py-0 px-1 me-2" href="/wholesaler-static/manage-schedule" title="Manage Schedule">
                                    <i className="bi-gear"></i>
                                </a>
                                <a className="btn btn-link py-0 px-1" href="/" title="Revert to Most Recent Certified Schedule *">
                                    <i className="bi-bootstrap-reboot"></i>
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>Wine to Retailer</td>
                            <td>
                                <span className="badge bg-orange"><i className="bi-exclamation-circle"></i>Not Certified</span>
                            </td>
                            <td>807</td>
                            <td>807</td>
                            <td>0</td>
                            <td>
                                <a className="btn btn-link py-0 px-1 me-2" href="/wholesaler-static/manage-schedule" title="Manage Schedule">
                                    <i className="bi-gear"></i>
                                </a>
                                <a className="btn btn-link py-0 px-1" href="/" title="Revert to Most Recent Certified Schedule *">
                                    <i className="bi-bootstrap-reboot"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br/>
            <h5>Schedules Effective November 2022</h5>
            <div className="table-responsive mt-4">
                <table className="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th className="w-30">Price Schedule</th>
                            <th className="w-13 min-wp-120">Current Status</th>
                            <th>Total</th>
                            <th>Valid</th>
                            <th>Errors</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Liquor to Wholesaler</td>
                            <td>
                                <span className="badge bg-orange"><i className="bi-exclamation-circle"></i>Not Certified</span>
                            </td>
                            <td>35</td>
                            <td>35</td>
                            <td>0</td>
                            <td>
                                <a className="btn btn-link py-0 px-1 me-2" href="/wholesaler-static/manage-schedule" title="Manage Schedule">
                                    <i className="bi-gear"></i>
                                </a>
                                <a className="btn btn-link py-0 px-1" href="/" title="Revert to Most Recent Certified Schedule *">
                                    <i className="bi-bootstrap-reboot"></i>
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <td>Wine to Wholesaler</td>
                            <td>
                                <span className="badge bg-orange"><i className="bi-exclamation-circle"></i>Not Certified</span>
                            </td>
                            <td>35</td>
                            <td>35</td>
                            <td>0</td>
                            <td>
                                <a className="btn btn-link py-0 px-1 me-2" href="/wholesaler-static/manage-schedule" title="Manage Schedule">
                                    <i className="bi-gear"></i>
                                </a>
                                <a className="btn btn-link py-0 px-1" href="/" title="Revert to Most Recent Certified Schedule *">
                                    <i className="bi-bootstrap-reboot"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div className="modal fade" id="exampleModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Deadlines for Filing Price Postings</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Cancel"></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-4">
                            <a className="btn btn-outline-primary" href="/">Download file with deadlines</a>
                        </div>
                        <p>The online applications allow wholesalers to file price schedule information with the Liquor Authority according to the following dates:</p>
                        <div className="table-responsive mt-4">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th colSpan={ 2 }>Wholesaler Schedules</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="w-40">1st thru 25th</td>
                                        <td>Create and amend schedules that shall become effective on the first day of the second succeeding calendar month.</td>
                                    </tr>
                                    <tr>
                                        <td className="w-40">26th thru End of Month</td>
                                        <td>Create and amend schedules that shall become effective on the first day of the third succeeding calendar month.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="table-responsive mt-4">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th colSpan={ 2 }>Retail Schedules</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="w-40">1st thru 5th</td>
                                        <td>Create and amend schedules that shall become effective on the first day of the following calendar month.</td>
                                    </tr>
                                    <tr>
                                        <td className="w-40">6th thru End of Month</td>
                                        <td>Create and amend schedules that shall become effective on the first day of the second succeeding calendar month.</td>
                                    </tr>
                                    <tr>
                                        <td className="w-40">3 Business Days after the 15th</td>
                                        <td>Ammend previously filed schedules that shall become effective on the first day of the following calendar month.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p>If due dates fall on holidays or weekends, due dates move to the next business day.</p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal fade" id="exampleModal2" aria-labelledby="exampleModal2Label" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModal2Label">Deadlines for Viewing Previously Filed Price Postings</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Cancel"></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-4">
                            <a className="btn btn-outline-primary" href="/">Download file with deadlines</a>
                        </div>
                        <p>The online applications allow users to view the price schedule information previously filed with the Liquor Authority according to the following dates:</p>
                        <div className="table-responsive mt-4">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th colSpan={ 2 }>Wholesaler Schedules</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="w-40">1st thru 14th</td>
                                        <td>View the schedules filed for the current month.</td>
                                    </tr>
                                    <tr>
                                        <td className="w-40">15th thru End of Month</td>
                                        <td>View the schedules filed for the next month.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="table-responsive mt-4">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th colSpan={ 2 }>Retail Schedules</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="w-40">1st thru 14th</td>
                                        <td>View the schedules filed for the current month.</td>
                                    </tr>
                                    <tr>
                                        <td className="w-40">15th thru 3 Business Days after the 15th</td>
                                        <td>View the schedules filed for the next month.</td>
                                    </tr>
                                    <tr>
                                        <td className="w-40">3 Business Days after the 15th thru End of Month</td>
                                        <td>View the schedules filed for the next month, including any amendments made by the wholesaler.</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default WholesalerPricePostingView;

