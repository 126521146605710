import React, { useEffect, useState } from "react";
import Input from "components/form/Input";
import { showTransferScheduleDataDialog } from "pages/admin/dialogs/TransferScheduleDataDialog";
import { TransferDataParamModel } from "pages/admin/models/TransferDataParamModel";
import { SubmitHandler, useForm } from "react-hook-form";
import { DistributorModel } from "models/DistributorModel";
import SelectTransferMonth from "pages/admin/components/SelectTransferMonth";
import FormUtil from "utils/FormUtil";
import Spinner from "components/Spinner";
import { RadioProductStateButtons } from "pages/admin/components/RadioProductStateButtons";
import TransferCODService from "services/admin/TransferCODService";
import TransferProductsService from "services/admin/TransferProductsService";
import SelectTransferPostType from "pages/admin/components/SelectTransferPostType";
import ProductConstants, { PostTypeCode } from "services/ProductConstants";
import { useToastStore } from "store/ToastStoreProvider";
import ProductService from "services/ProductService";
import { TransferResult } from "models/response/TransferResult";
import StringUtils from "utils/StringUtils";
import { useAuthStore } from "store/AuthStoreProvider";

export interface PostCountModel {
    code: PostTypeCode,
    count: number
}

export interface DatePostCountModel {
    month: number,
    data: PostCountModel[]
}

export type TransferScheduleResult = TransferResult & Omit<TransferDataParamModel, "fromDate"|"toDate"> & {
    fromDate: string,
    toDate: string
};

const TransferScheduleData = () => {
    const { addDefaultDangerToast } = useToastStore();
    const auth = useAuthStore();
    const currentLicenseIsActive = auth.currentLicense?.active;
    const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
    const [fromLicenseInfo, setFromLicenseInfo] = useState<boolean>(false);
    const [toLicenseInfo, setToLicenseInfo] = useState<boolean>(false);
    const [counts, setCounts] = useState<DatePostCountModel[]>();
    const [transferResult, setTransferResult] = useState<TransferScheduleResult>();

    const {
        handleSubmit,
        setValue,
        control,
        watch,
        getValues,
        clearErrors,
        setError,
        reset,
        formState: { errors, isDirty }
    } = useForm<TransferDataParamModel>({
        mode: "onSubmit",
        defaultValues: {
            fromLicense: undefined,
            fromPremiseName: "",
            fromPremiseAddress: "",
            fromDate: undefined,
            fromStatus: "",
            toLicense: undefined,
            toPremiseName: "",
            toPremiseAddress: "",
            toDate: undefined,
            toStatus: "",
            postType: ""
        }
    });

    const fromStatus = watch("fromStatus");
    const fromDate = watch("fromDate");
    const postType = watch("postType");
    const toStatus = watch("toStatus");
    useEffect(() => {
        fromStatus && loadCounts();
    }, [fromStatus]);

    function checkLicense(type: "from" | "to") {
        const license: string = getValues(`${type}License`);
        if (license) {
            clearErrors(`${type}License`);
            setIsDataLoading(true);
            TransferCODService.getLicenseInfo(license).then( (distributor: DistributorModel) => {
                type === "from" ? setFromLicenseInfo(true) : setToLicenseInfo(true);
                setValue(`${type}PremiseName`, distributor.premise_name?.trim() || "");
                setValue(`${type}PremiseAddress`, distributor.premise_address?.trim() || "");
            }).catch(() => {
                type === "from" ? setFromLicenseInfo(false) : setToLicenseInfo(false);
                setError(`${type}License`, { type: "manual", message: "License is not found" });
                setValue(`${type}PremiseName`, "");
                setValue(`${type}PremiseAddress`, "");
            }).finally(() => {
                setIsDataLoading(false);
                setValue(`${type}Status`, "");
                setValue(`${type}Date`, 0);
                type === "from" && setValue("postType", "");
            });
        }
    }

    function loadCounts() {
        const fromLicense: string = getValues("fromLicense");
        const fromStatus: string = getValues("fromStatus");
        setValue("toStatus", "");
        setValue("postType", "");
        setValue("fromDate", 0);
        if (fromLicense) {
            setIsDataLoading(true);
            TransferProductsService.getCounts(fromLicense, fromStatus).then( (response) => {
                setCounts(response);
            }).catch(() => addDefaultDangerToast("Transfer Schedule Data")
            ).finally(() => setIsDataLoading(false));
        }
    }

    const toPostType = postType
        ? ProductService.getPostTypesSync()
            .filter(({ code }) => postType === code)
            .map(m=> m.toSelectOption())[0]?.title + " Price"
        : "";

    const productCount = counts && postType
        ? counts.find(value => (fromDate && value.month == fromDate) || (fromStatus === ProductConstants.STATUS.WORKING))
            ?.data.find(value => value.code == postType)?.count || 0
        : 0;

    const onSuccessTransfer = (transferResult: TransferScheduleResult) => {
        setTransferResult(transferResult);
        reset();
    };

    const onSubmit: SubmitHandler<TransferDataParamModel> = data => showTransferScheduleDataDialog(data, productCount, onSuccessTransfer);

    const sourceMonthDisabled = () =>
        isDataLoading || !fromLicenseInfo || !fromStatus || !counts || fromStatus === ProductConstants.STATUS.WORKING;

    const destMonthDisabled = () =>
        isDataLoading || !toLicenseInfo || !toStatus || toStatus === ProductConstants.STATUS.WORKING;

    const sourceTypePostCounts = () => {
        return fromStatus === ProductConstants.STATUS.WORKING && counts
            ? counts[0]?.data // for working area, backend returns the same data for all months
            : counts?.find(value => value.month == fromDate)?.data;
    };

    const sourceTypeDisabled = () =>
        isDataLoading || !fromLicenseInfo || !fromStatus || !counts || !(fromDate || fromStatus === ProductConstants.STATUS.WORKING);

    return <div className="d-flex flex-column">
        <div className="mb-4 pb-md-3">
            <h1 className="text-primary mb-4">Transfer Schedule Data</h1>
            <p>You can transfer Schedule data from one license to another by entering the source license number and the target license number, and then clicking the &apos;Transfer&apos; button.</p>
        </div>
        <form onSubmit={ handleSubmit(onSubmit) } className="row justify-content-center">
            <div className="col-12 col-lg-6 col-xl-6">
                <div className="pe-lg-4">
                    <div className="card">
                        <div className="card-body pb-3">
                            <h4 className="mb-3">Source License</h4>
                            <div className="row pt-1">
                                <div className="col-12 col-md-5 col-lg-12">
                                    <Input
                                        name="fromLicense" control={ control } errors={ errors }
                                        rules={ FormUtil.PERMIT_ID_OR_SERIAL_NUMBER }
                                        onBlur={ () => checkLicense("from") }
                                        placeholder="Enter license id or serial number"
                                        label="Source License" type="text"
                                    />
                                </div>
                                <div className="col-12 col-md-7 col-lg-12 col-xxl-7">
                                    <Input
                                        name="fromPremiseName" control={ control } disabled
                                        label="Company Name" type="text" />
                                </div>
                                <div className="col-12 col-xxxl-5">
                                    <Input
                                        name="fromPremiseAddress" control={ control } disabled
                                        label="Company Address" type="text" />
                                </div>
                                <div className="col-12">
                                    <hr/>
                                    <RadioProductStateButtons
                                        name="fromStatus" control={ control } errors={ errors } required
                                        disabled={ isDataLoading || !fromLicenseInfo }/>
                                </div>
                                <div className="col-12 col-md-7 col-lg-12 col-xxl-7">
                                    <div className="mb-3">
                                        <SelectTransferMonth
                                            name="fromDate" control={ control } errors={ errors } counts={ counts }
                                            disabled={ sourceMonthDisabled() }
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-5 col-lg-12 col-xxl-5">
                                    <div className="mb-3">
                                        <SelectTransferPostType
                                            name="postType" control={ control } errors={ errors }
                                            post_counts={ sourceTypePostCounts() }
                                            disabled={ sourceTypeDisabled() }
                                            required/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    { toStatus === ProductConstants.STATUS.PUBLISHED && <div><b>Note:</b> The scheduled data will be transferred to certified area too.</div> }
                    <div className="mt-3"><b>Products count:</b> { productCount }</div>
                </div>
            </div>
            <div className="col-12 col-lg-6 col-xl-6 mt-5 pt-3 pt-lg-0 mt-lg-0">
                <div className="ps-lg-4 position-relative">
                    <div className="arrow-right-big bi-arrow-right-circle d-block">&nbsp;</div>
                    <div className="card">
                        <div className="card-body pb-3">
                            <h4 className="mb-3">Target License</h4>
                            <div className="row pt-1">
                                <div className="col-12 col-md-5 col-lg-12">
                                    <Input
                                        name="toLicense" control={ control } errors={ errors }
                                        rules={ FormUtil.PERMIT_ID_OR_SERIAL_NUMBER }
                                        onBlur={ () => checkLicense("to") }
                                        placeholder="Enter license id or serial number" label="Target License" type="text" />
                                </div>
                                <div className="col-12 col-md-7 col-lg-12 col-xxl-7">
                                    <Input
                                        name="toPremiseName" control={ control } disabled
                                        label="Company Name" type="text" />
                                </div>
                                <div className="col-12 col-xxxl-5">
                                    <Input
                                        name="toPremiseAddress" control={ control } disabled
                                        label="Company Address" type="text" />
                                </div>
                                <div className="col-12">
                                    <hr/>
                                    <RadioProductStateButtons
                                        name="toStatus" control={ control } errors={ errors }
                                        disabled={ isDataLoading || !toLicenseInfo || !fromStatus }
                                    />
                                </div>
                                <div className="col-12 col-md-7 col-lg-12 col-xxl-7">
                                    <div className="mb-3">
                                        <SelectTransferMonth
                                            name="toDate" control={ control } errors={ errors }
                                            disabled={ destMonthDisabled() }
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-5 col-lg-12 col-xxl-5">
                                    <div className="mb-3">
                                        <Input
                                            name="postType" disabled value={ toPostType }
                                            label="Select type" type="text" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-4">
                <div className="row justify-content-center">
                    <div className="col-6 col-md-4 col-lg-4 col-xl-3">
                        <input
                            type="submit" className="btn btn-primary w-100" value="Transfer"
                            disabled={ !isDirty || isDataLoading || !(Object.keys(errors).length === 0) || !currentLicenseIsActive }/>
                        { isDataLoading && <Spinner className="mt-4 pt-2 text-center"/> }
                    </div>
                </div>
            </div>
        </form>
        { transferResult &&
            <div>
                <h4 className="mb-3">Transfer Detail:</h4>
                <div>
                    Transfer from [
                    { transferResult.fromLicense }/{ StringUtils.capitalize(transferResult.fromStatus) }/{ transferResult.postType }
                    { transferResult.fromStatus === ProductConstants.STATUS.WORKING ? "" :  ("/" + transferResult.fromDate) }
                    ]
                    to [
                    { transferResult.toLicense }/{ StringUtils.capitalize(transferResult.toStatus) }/{ transferResult.postType }
                    { transferResult.toStatus === ProductConstants.STATUS.WORKING ? "" : ("/" + transferResult.toDate) }
                    ]
                    complete at: { transferResult.time }
                </div>
                <div>Records transferred: { transferResult.count }</div>
            </div>
        }
    </div>;
};

export default TransferScheduleData;
