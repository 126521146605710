import PriceSchedules from "pages/wholesaler/priceposting/components/PricesSchedules";
import { SampleXlsButton } from "components/SampleXlsButton";
import { SampleTxtButton } from "components/SampleTxtButton";
import React, { useEffect, useState } from "react";
import { SampleFormatsButton } from "components/SampleFormatsButton";
import { PriceScheduleByDate } from "models/response/PriceScheduleByDate";
import WholesalerPricePostingService from "services/wholesaler/WholesalerPricePostingService";

const PricePosting = () => {
    const [ priceSchedule1, setPriceSchedules1] = useState<PriceScheduleByDate>();
    const [ priceSchedule2, setPriceSchedules2] = useState<PriceScheduleByDate>();
    const [ priceSchedule3, setPriceSchedules3] = useState<PriceScheduleByDate>();
    const [ isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(loadData,[]);

    function loadData() {
        setIsLoading(true);
        WholesalerPricePostingService.fetchPriceSchedules()
            .then(response => {
                response.sort((a,b)=>compareNumbers(a.year+a.month, b.year+b.month)) ;
                setPriceSchedules1(response[0] || []);
                setPriceSchedules2(response[1] || []);
                setPriceSchedules3(response[2] || []);
            })
            .finally(() =>  setIsLoading(false));
    }

    const compareNumbers = (a:number, b:number) => a < b ? -1 : ( a > b ? 1 : 0 );

    return <div className="d-flex flex-column">
        <div className="mb-4 mb-md-5">
            <h1 className="text-primary mb-4">Price Posting</h1>
            <p>No brand of liquor or wine shall be sold to or purchased by any licensee unless a price schedule is filed with the Liquor Authority and is then in effect.</p>
            <p>The table below indicates the completion status for the price schedules associated with this license. Please select the action that you want to perform.</p>
            <p className="mb-0 text-danger">
                The NYS Gaming Commission, not the NYSLA, is mandated to issue liquor licenses for certain casinos. Montreign Operating Company LLC d/b/a Resorts World Catskills&nbsp;
                has been issued a temporary license by the Commission authorizing NYS licensed wholesalers to sell alcoholic beverages to them, pursuant to the terms of that license.
            </p>

            <div className="mt-4">
                <SampleFormatsButton/>
                <SampleXlsButton/>
                <SampleTxtButton/>
                <a className="btn btn-outline-primary me-3 mb-3" rel="noreferrer" target="_blank" href="https://support.nyslapricepostings.com/s/article/Mid-month-Limited-Availability">Mid-Month Limited Availability Tutorial</a>
                <div className="btn-group mb-3">
                    <button type="button" className="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        Basic Info On Licensee Responsibilities
                    </button>
                    <ul className="dropdown-menu">
                        <li>
                            <a className="dropdown-item" href="/files/Basic Info On Licensee Responsibilities - Liquor Wine Wholesaler Manufacturers.pdf">Liquor Wine Wholesaler Manufacturers</a>
                        </li>
                        <li>
                            <a className="dropdown-item" href="/files/Basic Info On Licensee Responsibilities - Beer Cider Wholesale Manufacturing.pdf">Beer Cider Wholesale Manufacturing</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="">
            <div className="mb-3 price-posting-cnt">
                <PriceSchedules loadData={ loadData } isLoading={ isLoading } priceSchedule={ priceSchedule1 } />
                <PriceSchedules loadData={ loadData } isLoading={ isLoading } priceSchedule={ priceSchedule2 } />
                <PriceSchedules loadData={ loadData } isLoading={ isLoading } priceSchedule={ priceSchedule3 } />
            </div>
        </div>
    </div>;
};

export default PricePosting;
