import React from "react";

const DelinquentBatch4View = () => {

    return <div className="d-flex flex-column min-vh-100">
        <div className="mb-5">
            <h1 className="text-primary mb-0">Delinquent Records - Batch Submission</h1>
        </div>

        <h4 className="mb-3">Import History</h4>

        <div className="table-responsive mb-4">
            <table className="table">
                <thead>
                    <tr>
                        <th>Importer</th>
                        <th>Uploaded</th>
                        <th>Filename</th>
                        <th>Rows</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>First name very very very long Last name</td>
                        <td>Tuesday, September 6-7:15 a.m.</td>
                        <td>export1.csv</td>
                        <td>N/A</td>
                        <td>
                            <span className="badge bg-info">
                                <i className="bi-clock-history"></i>In progress
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>First name Last name</td>
                        <td>Tuesday, September 6-7:15 a.m.</td>
                        <td>export_data6554442.csv</td>
                        <td>1</td>
                        <td>
                            <span className="badge bg-success">
                                <i className="bi-check-circle"></i>Completed
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>;
};

export default DelinquentBatch4View;
