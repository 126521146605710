import { modalStore } from "store/ModalStoreProvider";
import React from "react";

export const showDeleteAlertDialog = (onDelete: () => void) => {
    modalStore.setBody(<DeleteAlertDialog onDelete={ onDelete } />);
    modalStore.setHeader("Remove Alert");
    modalStore.showModal();
};

const DeleteAlertDialog = ({ onDelete }: { onDelete: () => void }) => {

    modalStore.setAdditionalButtons([
        <button key={ 0 } type="button" className="btn btn-primary" onClick={ () => onDelete() }>Yes</button>
    ]);

    return <div>You are going to delete an alert. Are you sure?</div>;
};
