import React from "react";
import Input from "components/form/Input";
import Form from "react-bootstrap/Form";

const WholesalerWRAddBrandOrItem21View = () => {

    return <div className="d-flex flex-column">
        <div className="mb-4 mb-md-5">
            <h1 className="text-primary mb-0">Wine To Retailer - Schedule for November 2022</h1>
        </div>
        <div>
            <div className="row mb-3 mb-md-4">
                <div className="col-12 col-lg-6">
                    <h4 className="mb-2">Add Brand or Item</h4>
                </div>
            </div>
            <div className="card mt-3">
                <div className="card-body pb-2">
                    <div className="mb-4">
                        <h4><strong>Step 1:</strong> Enter brand information</h4>
                        <p className="mb-0">In order to create a new brand, you must complete the following form. Fields marked with a * are required.</p>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="pe-xl-4">
                                <Input name="name" placeholder="Enter brand name" label="Brand name *" required type="text"/>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="ps-xl-4">
                                <div className="row">
                                    <div className="col-12 col-xl-6">
                                        <div className="form-label-with-tooltip">
                                            <label className="form-label" htmlFor="ttb_id">Package type</label>
                                            <div className="tooltip-wr tooltip-wr-small ms-2">
                                                <span className="tooltip-icon bi-question-circle-fill"></span>
                                                <div className="tooltip-msg text-center">
                                                    <p>Some text here</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="package-type" className="form-label">Package type</label>
                                            <select id="package-type" className="form-select" aria-label="Select" disabled>
                                                <option selected value="2">Combo</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-xl-6">
                            <div className="pe-xl-4">
                                <div className="row">
                                    <div className="col-12 col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="Combo-type-1" className="form-label">Combo type 1</label>
                                            <select id="Combo-type-1" className="form-select" aria-label="Select">
                                                <option selected>Retail Disassembled (RD)</option>
                                                <option value="1">Retail Disassembled (RD)</option>
                                                <option value="2">Consumer Packaging (CP)</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <div className="form-group">
                                            <label htmlFor="Combo-type-2" className="form-label">Combo type 2</label>
                                            <select id="Combo-type-1" className="form-select" aria-label="Select">
                                                <option selected>Supplier Assembled</option>
                                                <option value="1">Supplier Assembled</option>
                                                <option value="2">Distributor Assembled</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="pe-xl-4">
                                <div className="col-12">
                                    <div className="form-label-with-tooltip">
                                        <label className="form-label" htmlFor="ttb_id">Age *</label>
                                        <div className="tooltip-wr tooltip-wr-small ms-2">
                                            <span className="tooltip-icon bi-question-circle-fill"></span>
                                            <div className="tooltip-msg text-center">
                                                <p>Some text</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="form-check form-check-inline mb-2">
                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1"/>
                                            <label className="form-check-label" htmlFor="inlineRadio1">Not applicable (N/A)</label>
                                        </div>
                                        <div className="form-check form-check-inline mb-2">
                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
                                            <label className="form-check-label" htmlFor="inlineRadio2">Non vintage (NV)</label>
                                        </div>
                                        <div className="form-check form-check-inline mb-2">
                                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option3"/>
                                            <label className="form-check-label" htmlFor="inlineRadio3">Various vintage (VV)</label>
                                        </div>
                                        <div className="form-check form-check-inline mb-2">
                                            <input className="form-check-input form-check-input-with-year" type="radio" name="inlineRadioOptions" id="inlineRadio4" value="option4" checked />
                                            <label className="form-check-label wp-80 input-year flex-lg-fill" htmlFor="inlineRadio4">
                                                <Input name="year" placeholder="Year" label="" type="number"/>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mt-4">
                <div className="card-body pb-0">
                    <div className="mb-4">
                        <h4><strong>Step 2:</strong> Add item(s)</h4>
                        <p className="mb-0">Add one or multiple items</p>
                    </div>
                    <div className="">
                        <div className="nav nav-pills btn-group flex-column flex-lg-row d-lg-inline-flex mb-4" id="nav-tab" role="tablist" aria-orientation="vertical">
                            <button className="btn btn-outline-secondary min-wp-230" id="nav-1-tab" data-bs-toggle="tab" data-bs-target="#nav-1" type="button" role="tab" aria-controls="nav-1" aria-selected="false">Item info</button>
                            <button className="btn btn-outline-secondary min-wp-230 active" id="nav-2-tab" data-bs-toggle="tab" data-bs-target="#nav-2" type="button" role="tab" aria-controls="nav-2" aria-selected="false">Discounts</button>
                        </div>

                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade" id="nav-1" role="tabpanel" aria-labelledby="nav-1-tab">
                                <div className="pb-3 mx--row">
                                    <div className="row">
                                        <div className="col-12 col-xl-6">
                                            <div className="pe-xl-4">
                                                <Input name="description" placeholder="Description" label="Description *" type="text" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-6">
                                            <div className="ps-xl-4">
                                                <div className="row">
                                                    <div className="col-12 col-md-5">
                                                        <Input name="number" placeholder="Your item #" label="Your item # *" required type="text"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <div className="pe-xl-4">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <label htmlFor="number" className="form-label"># of bottles per *</label>
                                                    </div>
                                                    <div className="col-12 col-lg-6 form-group-without-label">
                                                        <Input name="number" placeholder="Case *" label="" required type="text"/>
                                                    </div>
                                                    <div className="col-12 col-lg-6 form-group-without-label">
                                                        <Input name="number" placeholder="SubPack / Sleeve *" label="" required type="text"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <div className="ps-xl-4">
                                                <div className="mb-3">
                                                    <p className="mb-2">
                                                        <strong>Case only</strong>
                                                    </p>
                                                    <div className="switch-year pt-1">
                                                        <label className="form-check-label me-2" htmlFor="custom-switch">No</label>
                                                        <Form.Switch
                                                            type="switch"
                                                            id="custom-switch"
                                                            label="Yes"
                                                            checked
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-xl-6">
                                            <div className="pe-xl-4">
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <label className="form-label" htmlFor="Price3">Price per case *</label>
                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text" id="Price3">$</span>
                                                            <input type="number" className="form-control" placeholder="Price per case" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <label className="form-label" htmlFor="Price4">NYC Price per case</label>
                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text" id="Price4">$</span>
                                                            <input type="number" className="form-control" placeholder="NYC Price per case" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-6">
                                            <div className="ps-xl-4">
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <Input name="serial-number" placeholder="NYS supplier serial #" label="NYS supplier serial # *" required type="number"/>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <Input name="item-number" placeholder="NYS supplier item #" label="NYS supplier item # *" required type="number"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-xl-6">
                                            <div className="pe-xl-4">
                                                <div className="form-check mb-3 mt-3">
                                                    <input id="CheckDefault" type="checkbox" className="form-check-input" value="" checked />
                                                    <label htmlFor="CheckDefault" className="form-check-label">Limited Availability</label>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="Method" className="form-label">Allocation Method</label>
                                                    <select id="Method" className="form-select" aria-label="Select">
                                                        <option selected>N/A</option>
                                                        <option value="1">Method 1</option>
                                                        <option value="2">Method 2</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-6">
                                            <div className="ps-xl-4">
                                                <div className="form-group">
                                                    <label htmlFor="Textarea" className="form-label">Allocation Description</label>
                                                    <textarea rows={ 4 } id="Textarea" className="form-control" placeholder="Allocation Description"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                </div>
                                <div className="mb-3">
                                    <div className="styleguide-head mb-4">
                                        <div className="d-flex flex-row align-items-end">
                                            <h5 className="me-auto mb-0">Combo item 1</h5>
                                            <a className="btn btn-outline-secondary" href="/">Remove item</a>
                                        </div>
                                        <hr/>
                                    </div>
                                    <form action="">
                                        <div className="row">
                                            <div className="col-12 col-xl-6">
                                                <div className="pe-xl-4">
                                                    <Input name="NYS-supplier-item" placeholder="NYS supplier item" label="NYS supplier item" type="text" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-xl-3">
                                                <div className="ps-xl-4">
                                                    <Input name="quantity" placeholder="Quantity" label="Quantity" type="number" />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="mb-3">
                                    <div className="styleguide-head mb-4">
                                        <div className="d-flex flex-row align-items-end">
                                            <h5 className="me-auto mb-0">Combo item 2</h5>
                                            <a className="btn btn-outline-secondary" href="/">Remove item</a>
                                        </div>
                                        <hr/>
                                    </div>
                                    <form action="">
                                        <div className="row">
                                            <div className="col-12 col-xl-6">
                                                <div className="pe-xl-4">
                                                    <Input name="NYS-supplier-item1" placeholder="NYS supplier item" label="NYS supplier item" type="text" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 col-xl-3">
                                                <div className="ps-xl-4">
                                                    <Input name="quantity1" placeholder="Quantity" label="Quantity" type="number" />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="tab-pane fade show active" id="nav-2" role="tabpanel" aria-labelledby="nav-2-tab">
                                <div className="switch-header mb-4 fw-bold">
                                    <label className="form-check-label h5" htmlFor="custom-switch">Select discount</label>
                                    <Form.Switch type="switch" id="custom-switch1" label="Create new discount" checked />
                                </div>
                                <div className="mb-4 pb-2">
                                    <div className="d-none">
                                        <form action="">
                                            <div className="row">
                                                <div className="col-12 col-xl-6">
                                                    <div className="pe-xl-4">
                                                        <div className="form-group mb-3 mb-xl-0">
                                                            <label htmlFor="code" className="form-label">Discount code</label>
                                                            <select id="code" className="form-select" aria-label="Select" disabled>
                                                                <option selected>Discount code</option>
                                                                <option value="1">Discount code 1</option>
                                                                <option value="2">Discount code 2</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-xl-6">
                                                    <div className="ps-xl-4">
                                                        <div className="mb-3 mb-xl-0">
                                                            <p className="mb-2">
                                                                <strong>Discounts by</strong>
                                                            </p>
                                                            <div className="switch-year pt-1">
                                                                <label className="form-check-label disabled me-2" htmlFor="custom-switch">Case</label>
                                                                <Form.Switch
                                                                    type="switch"
                                                                    id="custom-switch"
                                                                    label="Bottle"
                                                                    checked
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <div className="row">
                                            <div className="col-12 col-lg-4 mt-4">
                                                <div className="card bg-white">
                                                    <div className="card-body pb-2 pt-3">
                                                        <div className="styleguide-head mb-4">
                                                            <div className="d-flex flex-row align-items-center mb-2">
                                                                <h5 className="me-auto mb-0">Discount 1</h5>
                                                                <a className="fs-4" href="/">
                                                                    <i className="bi-x-circle-fill"></i>
                                                                </a>
                                                            </div>
                                                            <hr className="mt-1" />
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="Type" className="form-label">Type</label>
                                                                    <select id="Type" className="form-select" aria-label="Select">
                                                                        <option selected>$</option>
                                                                        <option value="1">$</option>
                                                                        <option value="2">Euro</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <Input name="amount" placeholder="00.00" label="Amount" type="number"/>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <Input name="on" placeholder="0" label="On"  type="number"/>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="Units" className="form-label">Units</label>
                                                                    <select id="Units" className="form-select" aria-label="Select">
                                                                        <option selected>Case</option>
                                                                        <option value="1">Case 1</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-4 mt-4">
                                                <div className="card bg-white">
                                                    <div className="card-body pb-2 pt-3">
                                                        <div className="styleguide-head mb-4">
                                                            <div className="d-flex flex-row align-items-center mb-2">
                                                                <h5 className="me-auto mb-0">Discount 2</h5>
                                                                <a className="fs-4" href="/">
                                                                    <i className="bi-x-circle-fill"></i>
                                                                </a>
                                                            </div>
                                                            <hr className="mt-1" />
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="Type" className="form-label">Type</label>
                                                                    <select id="Type" className="form-select" aria-label="Select">
                                                                        <option selected>$</option>
                                                                        <option value="1">$</option>
                                                                        <option value="2">Euro</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <Input name="amount" placeholder="00.00" label="Amount" type="number"/>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <Input name="on" placeholder="0" label="On"  type="number"/>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="Units" className="form-label">Units</label>
                                                                    <select id="Units" className="form-select" aria-label="Select">
                                                                        <option selected>Bottle</option>
                                                                        <option value="1">Case</option>
                                                                        <option value="1">Bottle</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-4 mt-4">
                                                <div className="card bg-white">
                                                    <div className="card-body pb-2 pt-3">
                                                        <div className="styleguide-head mb-4">
                                                            <div className="d-flex flex-row align-items-center mb-2">
                                                                <h5 className="me-auto mb-0">Discount 3</h5>
                                                                <a className="fs-4" href="/">
                                                                    <i className="bi-x-circle-fill"></i>
                                                                </a>
                                                            </div>
                                                            <hr className="mt-1" />
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="Type" className="form-label">Type</label>
                                                                    <select id="Type" className="form-select" aria-label="Select">
                                                                        <option selected>$</option>
                                                                        <option value="1">$</option>
                                                                        <option value="2">Euro</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <Input name="amount" placeholder="00.00" label="Amount" type="number"/>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <Input name="on" placeholder="0" label="On"  type="number"/>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="Units" className="form-label">Units</label>
                                                                    <select id="Units" className="form-select" aria-label="Select">
                                                                        <option selected>Case</option>
                                                                        <option value="1">Case 1</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-4 mt-4">
                                                <div className="card bg-white">
                                                    <div className="card-body pb-2 pt-3">
                                                        <div className="styleguide-head mb-4">
                                                            <div className="d-flex flex-row align-items-center mb-2">
                                                                <h5 className="me-auto mb-0">Discount 4</h5>
                                                                <a className="fs-4" href="/">
                                                                    <i className="bi-x-circle-fill"></i>
                                                                </a>
                                                            </div>
                                                            <hr className="mt-1" />
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="Type" className="form-label">Type</label>
                                                                    <select id="Type" className="form-select" aria-label="Select">
                                                                        <option selected>$</option>
                                                                        <option value="1">$</option>
                                                                        <option value="2">Euro</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <Input name="amount" placeholder="00.00" label="Amount" type="number"/>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <Input name="on" placeholder="0" label="On"  type="number"/>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="Units" className="form-label">Units</label>
                                                                    <select id="Units" className="form-select" aria-label="Select">
                                                                        <option selected>Case</option>
                                                                        <option value="1">Case 1</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-block">
                                        <button className="btn btn-primary">Add new discount</button>
                                        <div className="row">
                                            <div className="col-12 col-lg-4 mt-4">
                                                <div className="card bg-white">
                                                    <div className="card-body pb-2 pt-3">
                                                        <div className="styleguide-head mb-4">
                                                            <div className="d-flex flex-row align-items-center mb-2">
                                                                <h5 className="me-auto mb-0">Discount 1</h5>
                                                                <a className="fs-4" href="/">
                                                                    <i className="bi-x-circle-fill"></i>
                                                                </a>
                                                            </div>
                                                            <hr className="mt-1" />
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="Type" className="form-label">Type</label>
                                                                    <select id="Type" className="form-select" aria-label="Select">
                                                                        <option selected>$</option>
                                                                        <option value="1">$</option>
                                                                        <option value="2">Euro</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <Input name="amount" placeholder="00.00" label="Amount" type="number"/>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <Input name="on" placeholder="0" label="On"  type="number"/>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="Units" className="form-label">Units</label>
                                                                    <select id="Units" className="form-select" aria-label="Select">
                                                                        <option selected>Case</option>
                                                                        <option value="1">Case 1</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-4 mt-4">
                                                <div className="card bg-white">
                                                    <div className="card-body pb-2 pt-3">
                                                        <div className="styleguide-head mb-4">
                                                            <div className="d-flex flex-row align-items-center mb-2">
                                                                <h5 className="me-auto mb-0">Discount 2</h5>
                                                                <a className="fs-4" href="/">
                                                                    <i className="bi-x-circle-fill"></i>
                                                                </a>
                                                            </div>
                                                            <hr className="mt-1" />
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="Type" className="form-label">Type</label>
                                                                    <select id="Type" className="form-select" aria-label="Select">
                                                                        <option selected>$</option>
                                                                        <option value="1">$</option>
                                                                        <option value="2">Euro</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <Input name="amount" placeholder="00.00" label="Amount" type="number"/>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <Input name="on" placeholder="0" label="On"  type="number"/>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="Units" className="form-label">Units</label>
                                                                    <select id="Units" className="form-select" aria-label="Select">
                                                                        <option selected>Bottle</option>
                                                                        <option value="1">Case</option>
                                                                        <option value="1">Bottle</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-4 mt-4">
                                                <div className="card bg-white">
                                                    <div className="card-body pb-2 pt-3">
                                                        <div className="styleguide-head mb-4">
                                                            <div className="d-flex flex-row align-items-center mb-2">
                                                                <h5 className="me-auto mb-0">Discount 3</h5>
                                                                <a className="fs-4" href="/">
                                                                    <i className="bi-x-circle-fill"></i>
                                                                </a>
                                                            </div>
                                                            <hr className="mt-1" />
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="Type" className="form-label">Type</label>
                                                                    <select id="Type" className="form-select" aria-label="Select">
                                                                        <option selected>$</option>
                                                                        <option value="1">$</option>
                                                                        <option value="2">Euro</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <Input name="amount" placeholder="00.00" label="Amount" type="number"/>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <Input name="on" placeholder="0" label="On"  type="number"/>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="Units" className="form-label">Units</label>
                                                                    <select id="Units" className="form-select" aria-label="Select">
                                                                        <option selected>Case</option>
                                                                        <option value="1">Case 1</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-4 mt-4">
                                                <div className="card bg-white">
                                                    <div className="card-body pb-2 pt-3">
                                                        <div className="styleguide-head mb-4">
                                                            <div className="d-flex flex-row align-items-center mb-2">
                                                                <h5 className="me-auto mb-0">Discount 4</h5>
                                                                <a className="fs-4" href="/">
                                                                    <i className="bi-x-circle-fill"></i>
                                                                </a>
                                                            </div>
                                                            <hr className="mt-1" />
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="Type" className="form-label">Type</label>
                                                                    <select id="Type" className="form-select" aria-label="Select">
                                                                        <option selected>$</option>
                                                                        <option value="1">$</option>
                                                                        <option value="2">Euro</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <Input name="amount" placeholder="00.00" label="Amount" type="number"/>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <Input name="on" placeholder="0" label="On"  type="number"/>
                                                            </div>
                                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                                <div className="form-group">
                                                                    <label htmlFor="Units" className="form-label">Units</label>
                                                                    <select id="Units" className="form-select" aria-label="Select">
                                                                        <option selected>Case</option>
                                                                        <option value="1">Case 1</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="row justify-content-center mt-2">
            <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-4">
                <a href="/" className="btn btn-outline-secondary w-100">Cancel</a>
            </div>
            <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-4">
                <a href="/wholesaler-static/wine-to-retailer/add-brand-or-item1" className="btn btn-secondary w-100">Add another item</a>
            </div>
            <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-4">
                <a href="/wholesaler-static/wine-to-retailer/add-brand-or-item21" className="btn btn-primary w-100">Save</a>
            </div>
        </div>
    </div>;
};

export default WholesalerWRAddBrandOrItem21View;

