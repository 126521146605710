import React from "react";
import { ImportSubject } from "models/ImportDiscriminator";

export const SampleFormatsButton = ({ subject }:{ subject?: ImportSubject }) => {
    return <div className="btn-group me-3 mb-3">
        <button type="button" className="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            Formats
        </button>
        <ul className="dropdown-menu">
            { (typeof subject==="undefined" || subject == ImportSubject.PRODUCTS) &&
                <li>
                    <a
                        className="dropdown-item" rel="noreferrer" target="_blank"
                        href="https://docs.google.com/spreadsheets/d/e/2PACX-1vSiGDGbZGYx_xSvfc4ve_d-ee-Ja8AoXk2_BvzjYVeSe5P6Tmvsjc2v_QQ6Ft82Cfk_k19Fm9xmC3pL/pub?output=pdf" >
                        Price Posting format
                    </a>
                </li>
            }
            { (typeof subject==="undefined" || subject == ImportSubject.DISCOUNTS) &&
                <li>
                    <a className="dropdown-item" rel="noreferrer" target="_blank" href="/files/DiscountFileFormat.pdf">Discount Table format</a>
                </li>
            }
        </ul>
    </div>;
};
