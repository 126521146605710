import React from "react";
import { NumericFormat } from "react-number-format";
import { Controller } from "react-hook-form";
import classNames from "classnames";

interface Props {
    name: string,
    control: any,
    label?: string,
    rules?: any
    errors?: any
}

const CurrencyInput = ({ name, label, errors, ...props }: Props) => {

    const feedbackId = name + "Feedback";
    let errorMessage;
    if (errors && errors.hasOwnProperty(name)) {
        errorMessage = errors[name].message;
    }
    const isInvalid = errorMessage || false;
    return <div className="form-group form-group-currency">
        { label && <label className="form-label" htmlFor={ name }> { label } </label> }
        <Controller
            name={ name }
            render={ ({ field }) => (
                <NumericFormat
                    className={  classNames( { "is-invalid": isInvalid }) }
                    displayType={ "input" }
                    thousandSeparator={ true }
                    prefix={ "$" }
                    decimalScale={ 2 }
                    fixedDecimalScale={ true }
                    aria-describedby={ feedbackId }
                    placeholder="$00.00"
                    value={ field.value }
                    allowNegative={ false }
                    onValueChange={ values => { field.onChange(values.floatValue); } }
                />
            ) }
            { ...props }
        />
        <div id={ feedbackId } className="invalid-feedback">
            { errorMessage }
        </div>
    </div>;
};

export default CurrencyInput;
