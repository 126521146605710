import BatchProductImportService, { StoredFileType } from "services/BatchProductImportService";
import React, { useEffect, useState } from "react";
import StoredFile from "pages/wholesaler/priceposting/models/StoredFile";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import ProductConstants from "services/ProductConstants";
import { Nav } from "react-bootstrap";
import TabContent from "react-bootstrap/TabContent";
import TabPane from "react-bootstrap/TabPane";
import TabContainer from "react-bootstrap/TabContainer";
import { PageableRequest } from "models/requests/PageableRequest";
import Paginator from "components/Paginator";

dayjs.extend(utc);
dayjs.extend(timezone);

const FORMAT = "dddd MMMM D YYYY h:mm A [ET]";
const TIMEZONE = "America/New_York";

const UploadedFiles = ()=>{
    const [activeTab, setActiveTab] = useState<StoredFileType>("BATCH_PRICEPOSTING");

    return <div>
        <div className="mb-4 mb-md-5">
            <h1 className="text-primary mb-0">Uploaded Files</h1>
        </div>
        <div className="mb-3">
            <p className="mb-0">Note: displaying files for the last 13 months.</p>
        </div>
        <TabContainer id="_ignored" activeKey={ activeTab } onSelect={ k=> setActiveTab(k as StoredFileType) }>
            <Nav variant="pills" className="btn-group flex-column flex-lg-row d-lg-inline-flex mb-4" id="nav-tab"  role="tablist" >
                <Nav.Link eventKey={ "BATCH_PRICEPOSTING" } as="button" type="button" className="btn btn-outline-secondary min-wp-230" bsPrefix="_U">Price Postings</Nav.Link>
                <Nav.Link eventKey={ "BATCH_DISCOUNT" } as="button" type="button" className="btn btn-outline-secondary min-wp-230" bsPrefix="_U">Discount Codes</Nav.Link>
            </Nav>
            <TabContent id="nav-tabContent">
                <TabPane eventKey={ "BATCH_PRICEPOSTING" }>
                    <FilesTab type={ "BATCH_PRICEPOSTING" } hidePostType={ false } />
                </TabPane>
                <TabPane eventKey={ "BATCH_DISCOUNT" }>
                    <FilesTab type={ "BATCH_DISCOUNT" } hidePostType={ true }/>
                </TabPane>
            </TabContent>
        </TabContainer>
    </div>;
};

export default UploadedFiles;

const FilesTab = ({ type, hidePostType }:{ type: StoredFileType, hidePostType: boolean })=>{
    const [files, setFiles] = useState<StoredFile[]>();
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pagesCount, setPagesCount] = useState<number>(0);
    useEffect(()=>{
        performSearch(1);
    }, []);

    function performSearch(pageNumber: number) {
        const request = {
            filters: { type: type },
            pageInfo: { page: pageNumber }
        } as PageableRequest;
        BatchProductImportService.listFiles(request).then(r => {
            setFiles(r.data);
            r.pageInfo.pagesCount && setPagesCount(r.pageInfo.pagesCount);
        });
    }

    function handlePageClick(pageNumber: number) {
        setPageNumber(pageNumber);
        performSearch(pageNumber);
    }

    return <table className="table table-bordered table-hover">
        <thead>
            <tr>
                <th>File name</th>
                { !hidePostType && <th>Schedule Type</th> }
                <th>Date</th>
            </tr>
        </thead>
        <tbody>
            {
                files && files.map((file, idx)=>(
                    <tr key={ idx }>
                        <td>
                            <a href={ BatchProductImportService.downloadFileLink(file.id) }>
                                { file.title }
                            </a>
                        </td>
                        { !hidePostType && <td>{ ProductConstants.postTypeDescription(file.post_type) }</td> }
                        <td>{ dayjs.utc(file.client_date).tz(TIMEZONE).format(FORMAT) }</td>
                    </tr>
                ))
            }
        </tbody>
        <Paginator totalPages={ pagesCount } currentPage={ pageNumber } pageClicked={ handlePageClick }/>
    </table>;
};

