import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useNavigate } from "react-router-dom";
import { DEFAULT_PAGE_LIMIT } from "api/HttpClient";
import LicenseStatusCell from "components/LicenseStatusCell";
import TextArea from "components/form/TextArea";
import Paginator from "components/Paginator";
import WholesalerDelinquentService from "services/wholesaler/WholesalerDelinquentService";
import { LicenseModel } from "models/license.model";
import { PageableRequest } from "models/requests/PageableRequest";
import { WHOLESALER_PATHS } from "pages/wholesaler/WholesalerRoutes";
import classNames from "classnames";
import { LicenseTypeModel } from "models/LicenseTypeModel";

const DELINQUENT_STATUSES: { index: string; description: string; colorClass: string; iconClass: string }[] = [
    {
        index: "0",
        description: "cleared",
        colorClass: "bg-darkgreen",
        iconClass: "bi-check-circle"
    },
    {
        index: "1",
        description: "a delinquency posted by another wholesaler",
        colorClass: "bg-orange",
        iconClass: "bi-exclamation-diamond"
    },
    {
        index: "2",
        description: "a delinquency posted by this wholesaler",
        colorClass: "bg-grape",
        iconClass: "bi-exclamation-triangle"
    },
    {
        index: "3",
        description: "a delinquency posted by another wholesaler as well as this one",
        colorClass: "bg-danger",
        iconClass: "bi-exclamation-square"
    }
];

interface LocalPageableRequest extends PageableRequest {
    filters: {
        licenses?: string
    }
}

export type SearchDownloadDataModel = LicenseModel & LicenseTypeModel;
const SearchDownload = () => {

    const navigate = useNavigate();
    const [ inputMode, setInputMode ] = useState(true);
    const [ data, setData ] = useState<SearchDownloadDataModel[]>();
    const { control, handleSubmit, setValue, watch, getValues } = useForm<LocalPageableRequest>({
        defaultValues: {
            pageInfo: { page: 1, limit: DEFAULT_PAGE_LIMIT, pagesCount: 0 }
        }
    });

    useEffect(()=> setValue("pageInfo.page",1),[watch("filters.licenses")]);

    function onSubmit(request: LocalPageableRequest) {
        request.pageInfo.page = request.pageInfo.page || 1;
        request.pageInfo.limit = DEFAULT_PAGE_LIMIT;
        WholesalerDelinquentService.searchDownload(request).then(response => {
            setValue("pageInfo.page", response.pageInfo.page || 1);
            setValue("pageInfo.pagesCount", response.pageInfo.pagesCount || 0);
            setData(response.data);
            setInputMode(false);
        });
    }

    const handlePageClick = (pageNumber: number) => {
        setValue("pageInfo.page", pageNumber);
        onSubmit(getValues());
    };

    const backButtonClick = ()=>{
        if (inputMode){
            navigate(WHOLESALER_PATHS.DELINQUENT.SEARCH);
        }
        else {
            setInputMode(true);
        }
    };

    const downloadCSV = ()=>{
        WholesalerDelinquentService.searchDownloadCSV(getValues())
            .then((response) => {
                const link = document.createElement<"a">("a");
                link.href = window.URL.createObjectURL(
                    new Blob([response])
                );
                link.setAttribute("download", "download.csv");
                link.click();
            });
    };

    return <div className="d-flex flex-column">
        <form onSubmit={ handleSubmit(onSubmit) }>
            <div className="mb-2">
                <h1 className="text-primary mb-4">Delinquency Status Search</h1>
                <p className="mb-0">
                    Enter one license ID per line to perform a batch search. Each new line represents a separate search entry.
                    Then, click the &apos;Submit&apos; button to check for any delinquency records.
                    The results will show you any existing records for the licenses you entered.
                </p>
            </div>
            <div>
                <div className={ "form-group " + (!inputMode && "d-none") }>
                    <div className="form-label-with-tooltip">
                        <label className="form-label" htmlFor="numbers">License numbers</label>
                        <div className="tooltip-wr tooltip-wr-small ms-2">
                            <span className="tooltip-icon bi-question-circle-fill"/>
                            <div className="tooltip-msg text-center">
                                <p>
                                    0340-23-12345<br/>
                                    0340-23-12346<br/>
                                    0340-23-12347<br/>
                                    ….
                                </p>
                            </div>
                        </div>
                    </div>
                    <TextArea rows={ 20 } control={ control } name="filters.licenses" placeholder="0340-23-12345
0340-23-12346
0340-23-12347"></TextArea>
                </div>

                { (!inputMode && data && data.length>0) &&
                    <>
                        <div className="mb-2 d-flex">
                            <div className="ms-auto">
                                <Button className="btn btn-secondary" type="button" onClick={ downloadCSV }>
                                    <i className="bi-download me-2"/>Download CSV
                                </Button>
                            </div>
                        </div>
                        <div className="legend d-flex flex-column flex-xl-row flex-wrap mt-2">
                            { DELINQUENT_STATUSES.map((ds,i)=>
                                <div key={ i } className="d-flex align-items-center me-3 mt-2">
                                    <span className={ classNames("badge",ds.colorClass, "me-2") }>
                                        <i className={ classNames("bi",ds.iconClass, "me-1") } /> &nbsp;
                                        { ds.index }
                                    </span>
                                    <div className="text-lg-nowrap">{ ds.description }</div>
                                </div>
                            ) }
                        </div>
                        <div className="table-responsive mt-4">
                            <table className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>License ID</th>
                                        <th>Serial number</th>
                                        <th>Premise name</th>
                                        <th>Trade name</th>
                                        <th>Address</th>
                                        <th>County</th>
                                        <th>Type</th>
                                        <th>Class</th>
                                        <th>Expire date</th>
                                        <th className="w-13">License status</th>
                                        <th className="w-13">Delinquent status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { data && data.map((item, idx) => {
                                        const dstatus = DELINQUENT_STATUSES[parseInt(item.delinquent_status)];
                                        return <tr key={ idx }>
                                            <td>{ item.permit_id }</td>
                                            <td>{ item.serial_number }</td>
                                            <td>{ item.premise_name }</td>
                                            <td>{ item.dba }</td>
                                            <td>{ item.premise_address }</td>
                                            <td>{ item.county }</td>
                                            <td>{ item.type }</td>
                                            <td>{ item.class }</td>
                                            <td>{ item?.expiration_date }</td>
                                            <td><LicenseStatusCell statusCode={ item.status }/></td>
                                            <td>
                                                <OverlayTrigger placement={ "left" } overlay={
                                                    <Tooltip id={ `tooltip-${idx}` }>{ dstatus.description }</Tooltip>
                                                }>
                                                    <div className="d-flex justify-content-center w-100">
                                                        <span className={ classNames("badge",dstatus.colorClass, "w-auto") } >
                                                            <i className={ classNames("bi",dstatus.iconClass) } />
                                                            { dstatus.index }
                                                        </span>
                                                    </div>
                                                </OverlayTrigger>
                                            </td>
                                        </tr>;
                                    }) }
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <Paginator totalPages={ watch("pageInfo.pagesCount") || 0 } currentPage={ watch("pageInfo.page") }
                                pageClicked={ handlePageClick }/>
                        </div>
                    </>
                }
                { (!inputMode && (!data || data.length === 0)) &&
                    <div className="my-5 alert alert-danger" role="alert">
                        No delinquent records were found in your search. Please check your query and try again.
                        If you believe this is an error, please contact support for further assistance.
                    </div>
                }
            </div>
            <div className="row justify-content-center mt-3">
                <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                    <div className="pe-md-1">
                        <Button variant="outline-secondary" className="w-100" type="button" onClick={ backButtonClick }>Back</Button>
                    </div>
                </div>
                { inputMode &&
                    <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                        <div className="ps-md-1">
                            <Button variant="primary" className="w-100" type="submit">
                                Submit
                            </Button>
                        </div>
                    </div>
                }
            </div>
        </form>
    </div>;
};

export default SearchDownload;
