import React from "react";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { useToastStore } from "store/ToastStoreProvider";
import { observer } from "mobx-react";

export const DEFAULT_TOASTER_TIMEOUT = 5000;

const Toaster = () => {
    const { toasts, deleteToast } = useToastStore();
    return (
        <ToastContainer className="position-fixed top-0 end-0 p-3">
            {
                Array.from(toasts, ([key,item])=>
                    <Toast key={ key }
                        animation={ false }
                        bg={ item.variant }
                        onClose={ ()=>deleteToast(key) }>
                        <Toast.Header>
                            <strong className="me-auto">{ item.header }</strong>
                        </Toast.Header>
                        <Toast.Body>{ item.message }</Toast.Body>
                    </Toast>
                )
            }
        </ToastContainer>
    );
};

export default observer(Toaster);
