import React, { Fragment, useContext, useState } from "react";
import { ProductModel } from "models/ProductModel";
import classNames from "classnames";
import { Accordion, AccordionContext, useAccordionButton } from "react-bootstrap";
import PackageTypeConstants from "services/PackageTypeConstants";
import { ProductTabContainer, TabModel } from "components/product-tab-widget/ProductTabContainer";
import { BrandTab } from "components/product-tab-widget/components/BrandsTab";
import { ProductTab } from "components/product-tab-widget/components/ProductTab";
import { NYSSupplierTab } from "components/product-tab-widget/components/NYSSupplierTab";
import { LimitedAvailabilityTab } from "components/product-tab-widget/components/LimitedAvailabilityTab";
import { ComboTab } from "components/product-tab-widget/components/ComboTab";
import { DiscountTab } from "components/product-tab-widget/components/DiscountTab";
import TextArea from "components/form/TextArea";
import { SelectControlled } from "components/form/Select";
import ProductConstants from "services/ProductConstants";
import { UpdateAvailabilityRequest } from "pages/wholesaler/availability/models/UpdateAvailabilityRequest";
import { useForm } from "react-hook-form";
import { useAuthStore } from "store/AuthStoreProvider";
import { BrandOwnerTab } from "components/product-tab-widget/components/BrandOwnerTab";
import { showClearLimitAvailDialog } from "pages/wholesaler/priceposting/dialogs/ClearLimitedAvailability";

interface Props {
    products: ProductModel[];
    onUpdateProduct: (r: UpdateAvailabilityRequest, postType: string, year: number, month: number) => void;
    onClearLimAvail: (id: number, postType: string, year: number, month: number) => void;
}

interface PropTypes {
    eventKey: string,
    callback?: (ev:string) => void,
}

export const AvailabilityProductList = ({ products, onUpdateProduct, onClearLimAvail }: Props) => {
    const auth = useAuthStore();
    const allowedWholesaler = auth.permissionStore.isWholesalerAndPricesCertifyOrWrite();
    const currentLicenseIsActive = auth.currentLicense?.active;
    const { getValues,
        control,
        watch,
        setValue,
        reset } = useForm<UpdateAvailabilityRequest>({ mode: "onChange", defaultValues: {} });
    const editedId = watch("id");
    const [ editedProduct, setEditedProduct ] = useState<ProductModel>();

    const tabs: TabModel[] = [
        BrandTab, ProductTab, NYSSupplierTab, LimitedAvailabilityTab, ComboTab, DiscountTab, BrandOwnerTab
    ];

    function updateProduct() {
        if (!editedProduct){
            return;
        }
        onUpdateProduct(getValues(), editedProduct.post_type, editedProduct.post_year, editedProduct.post_month);
        reset();
    }

    function editProduct(product: ProductModel) {
        setEditedProduct(product);
        setValue("id", product.id);
        setValue("alloc_met", product.alloc_met || "01");
        setValue("alloc_desc", product.alloc_desc);
    }

    return <div className="mb-4">
        <Accordion className="table-responsive" alwaysOpen>
            <table className="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th>Brand name</th>
                        <th>Item description</th>
                        <th>Item Number</th>
                        <th>Beverage Type</th>
                        <th>Approval or BLR #</th>
                        <th>TTB / CoLA #</th>
                        <th>Package Type</th>
                        <th>Size/Unit</th>
                        <th>Allocation Method</th>
                        <th>Allocation Description</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    { products && products.map((product: ProductModel, index) => {
                        return (
                            <Fragment key={ index }>
                                <tr>
                                    <td>
                                        <CustomToggle eventKey={ index.toString() } />
                                    </td>
                                    <td>{ product.brand_name }</td>
                                    <td>{ product.prod_name }</td>
                                    <td>{ product.prod_item }</td>
                                    <td>{ product.beverage_type?.description }</td>
                                    <td>{ product.brand_reg }</td>
                                    <td>{ product.ttb_id }</td>
                                    <td>{ PackageTypeConstants.getTitleByCode(product.reg_combo) }</td>
                                    <td>{ product.item_size }&nbsp;{ product.um }</td>
                                    <td>
                                        <div className="form-group-without-m">
                                            { editedId != product.id && ProductConstants.allocationMethodsDescription(product.alloc_met)  }
                                            { editedId === product.id &&
                                                <SelectControlled name="alloc_met" options={ ProductConstants.ALLOCATION_METHODS } control={ control } valueAsInteger={ true }/>
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <div className="form-group-without-m">
                                            { editedId != product.id && product.alloc_desc }
                                            { editedId === product.id &&
                                                <TextArea name="alloc_desc" control={ control } />
                                            }
                                        </div>
                                    </td>
                                    <td className="text-nowrap">

                                        { editedId === product.id ?
                                            <button
                                                type="button" className="btn btn-link py-0 px-1" title="Save"
                                                onClick={ updateProduct }
                                                disabled={ !allowedWholesaler || !currentLicenseIsActive }>
                                                <i className="bi-save me-1"/> Save
                                            </button> :
                                            <button
                                                type="button" className="btn btn-link py-0 px-1" title="Edit"
                                                onClick={ () => editProduct(product) }
                                                disabled={ !allowedWholesaler || !currentLicenseIsActive }>
                                                <i className="bi-pencil me-1"/> Edit
                                            </button>
                                        }
                                        { "L" === product.lim_avail &&
                                            <button type="button" className="btn btn-link py-0 px-1" title="Clear"
                                                onClick={ () => showClearLimitAvailDialog(() => {
                                                    onClearLimAvail(product.id, product.post_type, product.post_year, product.post_month);
                                                }) }
                                                disabled={ !allowedWholesaler || !currentLicenseIsActive }>
                                                <i className="icon icon-cleaning"/> Clear
                                            </button>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={ 13 } className="p-0">
                                        <Accordion.Collapse eventKey={ index.toString() }>
                                            <div className="p-4">
                                                <ProductTabContainer product={ product } tabs={ tabs }/>
                                            </div>
                                        </Accordion.Collapse>
                                    </td>
                                </tr>
                            </Fragment>
                        );
                    }) }
                </tbody>
            </table>
        </Accordion>
        { products.length === 0 &&
            <div className="text-center">
                <p>Records not found.</p>
            </div>
        }
    </div>;
};

const CustomToggle = ({ eventKey, callback }: PropTypes) => {
    const { activeEventKey } = useContext(AccordionContext);
    const onClickToggle = useAccordionButton(eventKey, (event) => {
        event.preventDefault();
        callback && callback(eventKey);
    });

    const isOpen = activeEventKey && activeEventKey.includes(eventKey);

    return (
        <a className={ classNames("table-collapse-a", isOpen ? "" : "collapsed") } onClick={ onClickToggle } href="wholesaler#/" role="button">
            <i className="bi-caret-right-fill"/>
        </a>
    );
};

