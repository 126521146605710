import { Link, generatePath, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import WholesalerPricePostingService from "services/wholesaler/WholesalerPricePostingService";
import { WHOLESALER_PATHS } from "pages/wholesaler/WholesalerRoutes";
import Spinner from "components/Spinner";
import { CountItem } from "models/response/CountItem";
import { showRevertScheduleDialog } from "components/dialogs/RevertScheduleDialog";
import { useAuthStore } from "store/AuthStoreProvider";
import PricePostingTitle from "components/PricePostingTitle";

const PricePostingView = () => {
    const { permissionStore } = useAuthStore();
    const navigate = useNavigate();
    const { type = "", year = "", month = "", certifiedNeeded = "" } = useParams<{
        type: string;
        year: string;
        month: string;
        certifiedNeeded?: string;
        confirmation_number?: string;
    }>();
    const [beverageTypes, setBeverageTypes] = useState<Array<CountItem>>();
    const [packageTypes, setPackageTypes] = useState<Array<CountItem>>();
    const [productsValid, setProductsValid] = useState<boolean>();
    const [isLoading, setIsLoading] = useState<boolean>();
    const [actions, setActions] = useState<Array<string>>();

    useEffect( loadData,[]);

    function loadData() {
        setIsLoading(true);

        WholesalerPricePostingService
            .countProductsByBeverageTypes(type, year, month, certifiedNeeded==="true")
            .then(setBeverageTypes)
            .finally(()=>setIsLoading(false));

        WholesalerPricePostingService
            .countProductsByPackageTypes(type, year, month, certifiedNeeded==="true")
            .then(setPackageTypes);

        WholesalerPricePostingService
            .isProductsValid(type, year, month, certifiedNeeded==="true")
            .then(setProductsValid);

        WholesalerPricePostingService
            .fetchActions(type, year, month, certifiedNeeded==="true")
            .then(setActions);
    }

    function canPostPrices(): boolean {
        return WholesalerPricePostingService.canDoAction(actions || [], "POST")
            && permissionStore.isWholesalerAndPricesCertifyOrWrite();
    }

    function canCertify(): boolean {
        return WholesalerPricePostingService.canDoAction(actions || [], "CERTIFY")
            && permissionStore.canCertify();
    }

    function canRevertRecent(): boolean {
        return WholesalerPricePostingService.canDoAction(actions || [], "REVERT_RECENT")
            && permissionStore.isWholesalerAndPricesCertifyOrWrite();
    }

    function canAmend(): boolean {
        return WholesalerPricePostingService.canDoAction(actions || [], "EQUALIZE")
            && permissionStore.isWholesalerAndPricesCertifyOrWrite();
    }

    return <div className="d-flex flex-column">
        <PricePostingTitle type={ type } year={ year } month={ month } certifiedNeeded={ certifiedNeeded==="true" } />
        <Spinner hidden={ !isLoading } className="text-center mt-2" />
        <div>
            <h5 className="mb-3 fw-bold">Price Posting Summary</h5>
            <p>
                The existing price posting schedule has been auto-populated using brands details from your most recent online submission.&nbsp;
                If you notice a value of 0 for any products, it indicates that no products were created in the previous months.
            </p>
            <p>The table below contains a summary of the current price schedule. As you make changes, this summary information is updated.</p>
            <div className="row">
                <div className="col-12 col-lg-6">
                    <div className="pe-xl-4">
                        <div className="table-responsive mt-3">
                            <table className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>Beverage type</th>
                                        <th>Number of items</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { beverageTypes?.map( (item: CountItem) => {
                                        /* eslint-disable indent */
                                        return <tr key={ item.title }>
                                            <td>
                                                <Link to={ generatePath(WHOLESALER_PATHS.PRICE_POSTING.MANAGE_BRANDS_ITEMS, { "type":type, "year":year, "month": month, "certifiedNeeded": certifiedNeeded }) + "?beverage_type=" + item.code }>
                                                    { item.title }
                                                </Link>
                                            </td>
                                            <td>
                                                { item.count }
                                            </td>
                                        </tr>;
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="ps-xl-4">
                        <div className="table-responsive mt-3">
                            <table className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th>Package type</th>
                                        <th>Number of items</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    { packageTypes?.map( (item: CountItem) => {
                                        /* eslint-disable indent */
                                        return <tr key={ item.title }>
                                            <td>
                                                <Link to={ generatePath(WHOLESALER_PATHS.PRICE_POSTING.MANAGE_BRANDS_ITEMS, { "type":type, "year":year, "month": month, "certifiedNeeded": certifiedNeeded }) + "?reg_combo=" + item.code }>
                                                    { item.title }
                                                </Link>
                                            </td>
                                            <td>
                                                { item.count }
                                            </td>
                                        </tr>;
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="mt-4">
            <h5 className="mb-3 fw-bold">Price Posting Actions</h5>
            <div className="content-styles">
                <ul>
                    <li>
                        <Link
                            to={ generatePath(WHOLESALER_PATHS.PRICE_POSTING.MANAGE_BRANDS_ITEMS, { "type":type, "year":year, "month": month, "certifiedNeeded": certifiedNeeded }) }
                            className="mb-0">
                            { canPostPrices()
                                ? "Manage Brands & Items"
                                : (canAmend() ? "View Brands & Items or Make Amendments" : "View Brands & Items") }
                        </Link>
                    </li>
                    { productsValid && canCertify() && <li><Link
                        to={ generatePath(WHOLESALER_PATHS.CERTIFY.STEP1, { "type":type, "year":year, "month": month }) }
                        className="mb-0">
                        Certify Schedule
                    </Link></li>
                    }
                    { !productsValid && canCertify() && <li className="form-label-with-tooltip">
                        <span className="mb-0">Certify Schedule</span>
                        <div className="tooltip-wr tooltip-wr-small ms-2">
                            <span className="tooltip-icon bi-info-circle-fill text-danger"></span>
                            <div className="tooltip-msg text-center">
                                <p>The schedule can not be certified due to errors. You can correct them in interactive mode or re-upload the corrected information.</p>
                            </div>
                        </div>
                    </li>
                    }
                    { canPostPrices() && <li>
                        <Link
                            to={ generatePath(WHOLESALER_PATHS.PRICE_POSTING.EDIT_PROFILE, { "type":type, "year":year, "month": month }) }
                            className="mb-0">
                            Edit posting profile
                        </Link>
                    </li>
                    }
                    { !canPostPrices() && <li>
                        <Link
                            to={ generatePath(WHOLESALER_PATHS.PRICE_POSTING.VIEW_PROFILE, { "type":type, "year":year, "month": month }) }
                            className="mb-0">
                            View posting profile
                        </Link>
                    </li>
                    }

                    { WholesalerPricePostingService.canDoAction(actions || [], "POST")
                        && permissionStore.isWholesalerAndPricesCertifyOrWrite() && <li>
                            <Link
                                to={ generatePath(WHOLESALER_PATHS.PRICE_POSTING.BATCH_IMPORT, { "type":type, "year":year, "month": month }) }
                                className={ "mb-0" } title="Add in batch">
                                Batch Submission
                            </Link>
                        </li>
                    }
                    { canRevertRecent() && <li><a href="/" onClick={ e => { e.preventDefault(); showRevertScheduleDialog(type, year, month,loadData);  } } className="mb-0">Revert Schedule to Most Recent Certified Schedule</a></li> }
                </ul>
            </div>
        </div>
    </div>;
};

export default PricePostingView;
