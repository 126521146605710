import { ProductModel } from "models/ProductModel";
import React from "react";
import ErroneousValue from "components/ErroneousValue";
import { TabModel } from "components/product-tab-widget/ProductTabContainer";
import classNames from "classnames";
import StringUtils from "utils/StringUtils";
import CurrencyText from "components/CurrencyText";
import { arrayToString } from "pages/wholesaler/priceposting/pages/brands/components/DistribIdInput";

const DISPLAYED_PROPERTIES = [ "subpack","fullcase","split_charge","botpercase","bot_price","bot_nyc","case_price","case_nyc","distrib_id"];
const ProductTabHeader = (product: ProductModel) => {

    const hasErrors = (p: ProductModel) =>
        StringUtils.hasAnyProperty(p.product_errors, DISPLAYED_PROPERTIES);

    return <span className = { classNames(hasErrors(product) ? "text-danger" : "" ) }>
        { hasErrors(product) && <i className="bi bi-exclamation-circle-fill me-2"></i> }
        Product Details
    </span>;
};

const ProductTabBody = (product: ProductModel) => {
    const currencyFormat = (value: number | undefined) => value === undefined  ? "N/A" : <CurrencyText value={ value } />;
    return  <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
        <h5 className="mb-3 fw-bold">Product details</h5>
        <div className="row">
            <div className="col-6 mb-3">
                <p className="mb-2"><strong># of bottles per case:&nbsp;</strong><ErroneousValue item={ product } name={ "botpercase" } /></p>
                <p className="mb-2"><strong># of bottles per subpack/sleeve:&nbsp;</strong><ErroneousValue item={ product } name={ "subpack" } /></p>
                <p className="mb-2"><strong>Case only:&nbsp;</strong><ErroneousValue item={ product } name={ "fullcase" } /></p>
                <p className="mb-2"><strong>BT / SLV Split case charge:&nbsp;</strong>
                    <ErroneousValue item={ product } name={ "split_charge" } formattedValue={ currencyFormat(product.split_charge) } />
                </p>
                <p className="mb-2"><strong>FOM Designation:&nbsp;</strong><ErroneousValue item={ product } name={ "fob" } /></p>
            </div>
            <div className="col-6 mb-lg-3">
                <p className="mb-2"><strong>Price per bottle:&nbsp;</strong><ErroneousValue item={ product } formattedValue={ currencyFormat(product.bot_price) } name={ "bot_price" } /></p>
                <p className="mb-2"><strong>NYC Price per bottle:&nbsp;</strong><ErroneousValue item={ product } formattedValue={ currencyFormat(product.bot_nyc) } name={ "bot_nyc" } /></p>
                <p className="mb-2"><strong>Price per case:&nbsp;</strong><ErroneousValue item={ product } formattedValue={ currencyFormat(product.case_price) } name={ "case_price" } /></p>
                <p className="mb-2"><strong>NYC Price per case:&nbsp;</strong><ErroneousValue item={ product } formattedValue={ currencyFormat(product.case_nyc) } name={ "case_nyc" } /></p>
                <p className="mb-2"><strong>Authorized NYS distributor license #&apos;s: </strong><ErroneousValue item={ product } name={ "distrib_id" } formattedValue={ arrayToString(product.distrib_id) }/></p>
            </div>
        </div>
    </div>;
};

export const ProductTab: TabModel = { header: ProductTabHeader, body: ProductTabBody };
