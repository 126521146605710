import httpClient from "api/HttpClient";
import { CurrentUserProfile } from "models/UserModel";
import BACKEND from "services/Constants";
import { AddLicenseRequest } from "models/requests/AddLicenseRequest";

export default {

    getProfile(): Promise<CurrentUserProfile> {
        return httpClient.get(BACKEND + "/user/profile");
    },

    updateProfile(data: CurrentUserProfile): Promise<void> {
        return httpClient.post(BACKEND + "/user/profile/update", data);
    },

    generateUUID(): Promise<string> {
        return httpClient.get(BACKEND + "/user/profile/generate-new-uuid");
    },

    addToLicense(data: AddLicenseRequest): Promise<void> {
        return httpClient.post(BACKEND + "/user/profile/addToLicense", data);
    }
};
