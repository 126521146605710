import { useEffect } from "react";
import { useShowHideStore } from "store/ShowHideStoreProvider";

export function useHideAll() {
    const showHideStore = useShowHideStore();
    useEffect(() => {
        showHideStore.hideAll();
        return () => {
            showHideStore.showAll();
        };
    }, []);
}
