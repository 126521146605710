import React from "react";
import { modalStore } from "store/ModalStoreProvider";
import LicenseInfo from "components/LicenseInfo";
import { LicenseModel } from "models/license.model";

interface Props {
    licenseId: number;
    showDelinquentStatus: boolean
}

export const showLicenseDelinquentInfo = (licenseId: number, showDelinquentStatus = false) => {
    modalStore.setSize("xl");
    modalStore.setBody(<LicenseDelinquentInfo licenseId={ licenseId } showDelinquentStatus = { showDelinquentStatus } />);
    modalStore.showModal();
};

const LicenseDelinquentInfo = (props: Props) => {
    function onLoadLicense(license: LicenseModel) {
        modalStore.setHeader(`Premise Name: ${getPremiseName(license)}`);
    }

    function getPremiseName(license: LicenseModel) {
        if (license?.premise_name) {
            if (license?.premise_name.trim()) {
                return license?.premise_name.trim();
            }
        }
        return "N/A";
    }

    return <LicenseInfo
        licenseId={ props.licenseId } onLoadLicense={ onLoadLicense }
        showDelinquentStatus={ props.showDelinquentStatus }/>;
};
