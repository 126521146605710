import React, { InputHTMLAttributes, forwardRef } from "react";
import classNames from "classnames";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label?: string;
    errors?: any; //TODO this is bad idea, it should be string error message here instead. Move to ControllerInputProps
    type: "text" | "number" | "password" | "date" | "email" | "checkbox";
    value?: any;
    valueAsInteger?: boolean;
    valueAsFloat?: boolean;
    onChange?: (...event: any[]) => void;
    onBeforeBlur?: (e:any)=>void;
    onBlur?: (e:any)=>void;
    divClassName?: string;
    helpText?: string;
}

export interface ControllerInputProps extends InputProps {
    control?: any;
    rules?: any;
}

// eslint-disable-next-line react/display-name
const BaseInput = forwardRef((props: InputProps, ref: any) => {
    const { name, label, errors, className, value, valueAsInteger, valueAsFloat, onChange, onBeforeBlur, onBlur, divClassName, helpText, ...input } = props;
    const feedbackId = name + "Feedback";

    const error = extractPathFromObject(errors,name);
    const isInvalid = error !== undefined;
    const errorMessage = error?.message;

    return <div className={ divClassName ? divClassName : "mb-3" }>
        { label &&
            <label className="form-label" htmlFor={ name }> { label } </label>
        }
        <input
            value={ nonDisplayable(value) ? defaultIfFalsy(props) : value }
            { ...input }
            onChange={ valueAsHandler(valueAsInteger, valueAsFloat, onChange) }
            onBlur={ handleBlur(onBeforeBlur, onBlur) }
            className={ classNames({ "form-control": !className } , { "is-invalid": isInvalid }, className) }
            aria-describedby={ feedbackId }
            id={ name }
            ref={ ref }
        />
        <div id={ feedbackId } className="invalid-feedback">
            { errorMessage }
        </div>
        {
            helpText && <small id={ name+"Help" } className="form-text text-muted">{ helpText }</small>
        }
    </div>;
});

export default BaseInput;

export const extractPathFromObject = (obj: any, path: string)=>{
    try {
        return Function("e",`return e.${path};`)(obj);
    }
    catch (e){}
};

export const valueAsHandler = (valueAsInteger?: boolean, valueAsFloat?: boolean, onChange?: (...event: any[]) => void)=>{
    {/* valueAsNumber ignored when used in Controller.rules, so implemented other way: https://github.com/react-hook-form/react-hook-form/discussions/8068 */}
    if (valueAsInteger && onChange) {
        return (e: any) => {
            const v = parseInt(e.target.value);
            onChange(isNaN(v) ? null : v);
        };
    }
    else if (valueAsFloat && onChange) {
        return (e: any) => {
            const v = parseFloat(e.target.value);
            onChange(isNaN(v) ? null : v);
        };
    }
    return onChange;
};

const handleBlur = (onBeforeBlur?: (e:any)=>void, onBlur?: (e:any)=>void) =>{
    return (e:any)=>{
        if (onBeforeBlur){
            onBeforeBlur(e);
        }
        if (onBlur){
            onBlur(e);
        }
    };
};

export const nonDisplayable = (v: any) => v === undefined || v === null || (typeof (v) === "number" && isNaN(v));
export const defaultIfFalsy = (props: InputProps) => (props.valueAsInteger || props.valueAsFloat ) ? null : "";
