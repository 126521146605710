import { action, makeObservable, observable } from "mobx";

export class ModalStore {

    @observable
    public show = false;

    @observable
    public header = "";

    @observable
    public size?: "sm" | "lg" | "xl" | undefined = undefined;

    @observable
    public fullscreen?: string | true | undefined = undefined;

    @observable
    public body?: JSX.Element;

    @observable
    public additionalButtons?: JSX.Element[];

    @observable
    public isLoading: boolean = false;

    constructor() {
        makeObservable(this);
    }

    @action
    public showModal() {
        this.show = true;
    }

    @action
    public hideModal() {
        this.show = false;
        setTimeout(() => {
            this.reset();
        }, 500);
    }

    @action
    public reset() {
        this.body = undefined;
        this.header = "";
        this.additionalButtons = undefined;
        this.size = undefined;
        this.fullscreen = undefined;
        this.isLoading = false;
    }

    @action
    public setBody(children: JSX.Element) {
        this.body = children;
    }

    @action
    public setAdditionalButtons(x: JSX.Element[]) {
        this.additionalButtons = x;
    }

    @action
    public setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    }

    @action
    public setSize(size: "sm" | "lg" | "xl" | undefined) {
        this.size = size;
    }

    @action
    public setFullscreen(fullscreen: string | true | undefined) {
        this.fullscreen = fullscreen;
    }

    @action
    public setHeader(header: string) {
        this.header = header;
    }

    @action
    public openModal(header: string, body: JSX.Element, additionalButtons?: JSX.Element[], size?: "sm" | "lg" | "xl") {
        this.setHeader(header);
        this.setBody(body);
        if(additionalButtons) {
            this.setAdditionalButtons(additionalButtons);
        }
        if(size) {
            this.setSize(size);
        }
        this.showModal();
    }

    @action
    public setHeaderAndButtons(header: string, additionalButtons: JSX.Element[]) {
        this.setHeader(header);
        this.setAdditionalButtons(additionalButtons);
    }
}
