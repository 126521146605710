import { useFieldArray, useForm } from "react-hook-form";
import React, { useEffect } from "react";
import DeadlinesModel from "pages/admin/models/DeadlinesModel";
import DeadlinesService from "services/admin/DeadlinesService";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";
import { useToastStore } from "store/ToastStoreProvider";
import { useTimeStore } from "store/TimeStoreProvider";
import { FillingPeriodInformation } from "components/FillingPeriodInformation";
import { DeadlineInput } from "pages/admin/components/DeadlineInput";
import { DeadlineAmendmentInputs } from "pages/admin/components/DeadlineAmendmentInputs";
import DateUtil from "utils/DateUtil";
import { useAuthStore } from "store/AuthStoreProvider";

interface ManageDeadlinesForm {
    schedules: Array<DeadlinesModel>;
}

export const Deadlines = () => {
    const {
        control,
        reset,
        handleSubmit,
        formState: { errors }
    } = useForm<ManageDeadlinesForm>(
        {
            defaultValues: { schedules: [] },
            mode: "onChange"
        });

    const { addToast } = useToastStore();
    const auth = useAuthStore();
    const currentLicenseIsActive = auth.currentLicense?.active;

    const { newYorkTime } = useTimeStore();

    const currentMonth = newYorkTime.month()+1;
    const ordinaryMonthStyle = "admin-calendar-li d-flex flex-column flex-xxl-row align-items-stretch";
    const currentMonthStyle = "admin-calendar-li admin-calendar-li-active d-flex flex-column flex-xxl-row align-items-stretch";


    const { fields } = useFieldArray({
        control,
        name: "schedules"
    });

    function onSubmit(data: ManageDeadlinesForm) {
        data.schedules.sort((a: DeadlinesModel, b: DeadlinesModel) => a.month - b.month);
        DeadlinesService.save(data.schedules).then( () => {
            addToast({ header: "Manage schedules dates", timeoutMs: DEFAULT_TOASTER_TIMEOUT, message:"Schedules dates were saved successfully", variant: "success" });
        }).catch(() => onError());
    }

    function onError() {
        addToast({ header: "Manage schedules dates", timeoutMs: DEFAULT_TOASTER_TIMEOUT, message:"Can't save schedule dates", variant: "error" });
    }

    useEffect(() => {
        DeadlinesService.load().then((response: Array<DeadlinesModel>) => {
            response.sort((a: DeadlinesModel, b: DeadlinesModel) => a.month - b.month);

            const part1: Array<DeadlinesModel> = response.slice(0, currentMonth-1);
            const part2: Array<DeadlinesModel> = response.slice(currentMonth-1, response.length);

            part1.forEach((item: DeadlinesModel) => {
                item.year = newYorkTime.year()+1;
            });

            part2.forEach((item: DeadlinesModel) => {
                item.year = newYorkTime.year();
            });

            reset({ schedules: part2.concat(part1) });
        });
    }, []);

    return <div className="d-flex flex-column">
        <div className="mb-4 pb-md-3">
            <h1 className="text-primary mb-4">Manage Schedule Deadlines</h1>
            <p>
                You can configure the deadlines for users to submit their price postings. This allows you to specify a time frame in which you can receive all submissions,&nbsp;
                making it easier to manage and plan your tasks accordingly. You can adjust the deadlines as needed to suit your preferences.
            </p>
            <FillingPeriodInformation/>
        </div>

        <div>
            <div className="">
                <h4 className="mb-3">Schedule Deadline Information</h4>
            </div>
            <div className="admin-calendar">
                <form id="schedulesForm" onSubmit={ handleSubmit(onSubmit) } className="admin-calendar-l">
                    { fields.map((field: DeadlinesModel, index: number) => <div key={ "tr" + field.month }>
                        <div className={ field.month === currentMonth ? currentMonthStyle : ordinaryMonthStyle }>
                            <div className="header d-flex align-items-center">
                                <h5>{ DateUtil.monthNumberToName(field.month-1) }, { field.year }</h5>
                            </div>
                            <div className="body d-flex flex-column flex-lg-row align-items-lg-center flex-wrap">
                                <DeadlineInput
                                    deadline={ field } control={ control } errors={ errors }
                                    name={ `schedules[${index}].retailer_certify_day` } isWholesaler={ false }/>
                                <DeadlineInput
                                    deadline={ field } control={ control } errors={ errors }
                                    name={ `schedules[${index}].wholesaler_certify_day` } isWholesaler={ true }/>
                                <DeadlineAmendmentInputs deadline={ field } control={ control } errors={ errors } index={ index }/>
                            </div>
                        </div>
                    </div>) }
                    <div className="row justify-content-center mt-3">
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                            <input type="submit" className="btn btn-primary w-100" disabled={ !currentLicenseIsActive } value="Save"/>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>;
};

