import React, { useEffect, useState } from "react";
import Input from "components/form/Input";
import { SubmitHandler, useForm } from "react-hook-form";
import ProductService, { ProductSearchRequest } from "services/ProductService";
import { ProductModel } from "models/ProductModel";
import URLUtil from "utils/URLUtil";
import { ProductSearchParamsModel } from "models/ProductSearchParamsModel";
import Spinner from "components/Spinner";
import Paginator from "components/Paginator";
import { LetterModel } from "models/LetterModel";
import { useKeyDownEnterEvent } from "components/KeyDownHook";

export interface ProductSearchProps {
    initProducts: ProductModel[],
    letter: LetterModel,
    isDialog: boolean,
    onSelect: (product: ProductModel) => void,
    onUnselect: (product: ProductModel) => void
}

const ProductSearchForm = (props: ProductSearchProps) => {

    const { initProducts, letter, onUnselect, onSelect, isDialog } = props;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [products, setProducts] = useState<ProductModel[]>([]);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageCount, setPageCount] = useState<number>(0);

    const {
        control,
        handleSubmit,
        getValues,
        reset
    } = useForm<ProductSearchParamsModel>({
        mode: "onSubmit"
    });
    useKeyDownEnterEvent(() => !isLoading && handleSubmit(onSuccessForm)());

    useEffect(() => {
        performQuery(getValues(), 1);
    }, []);

    const onSuccessForm: SubmitHandler<ProductSearchParamsModel> = async data => {
        performQuery(data, 1);
    };

    function handlePageClick(pageNumber: number) {
        performQuery( getValues(), pageNumber);
    }

    function performQuery(data: ProductSearchParamsModel, pageNumber: number) {
        setPageNumber(pageNumber);

        const request = {
            pageInfo: {
                page: pageNumber
            },
            filters:  URLUtil.pureUrlParameters(data)
        } as ProductSearchRequest;

        request.filters.post_type = letter?.post_type ? letter.post_type : "";
        setIsLoading(true);
        ProductService.searchByLetters(request).then(response => {
            setProducts(response.data || []);
            setPageCount(response.pageInfo.pagesCount || 1);
            setPageNumber(response.pageInfo.page || 1);
        }).finally(() => {
            setIsLoading(false);
        });
    }

    function linkProduct(event: any) {
        const product = products.find(p => p.id == event.target.value);
        if (!product) return;
        if (event.target.checked == true) {
            onSelect(product);
        } else {
            onUnselect(product);
        }
    }

    return <div className="d-flex flex-column">
        <div className="card">
            <div className="card-body">
                { !isDialog &&
                    <div className="mb-4">
                        <h5><strong>Step 2:</strong> Link item(s)</h5>
                        <p className="mb-0">Link at least one item to the letter. It is mandatory to select at least one Brand
                            Name from your Wholesale schedule.</p>
                    </div>
                }
                <form onSubmit={ handleSubmit(onSuccessForm) }>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="pe-xl-4">
                                <Input
                                    name="brand_name"
                                    control={ control }
                                    placeholder="Enter brand name"
                                    label="Brand name"
                                    type="text"
                                />
                                <div className="row">
                                    <div className="col-12 col-lg-5">
                                        <Input
                                            name="prod_item"
                                            control={ control }
                                            placeholder="Enter wholesaler item ID"
                                            label="Wholesaler item ID"
                                            type="text"
                                        />
                                    </div>
                                    <div className="col-12 col-lg-7">
                                        <Input
                                            name="ttb_id"
                                            control={ control }
                                            placeholder="Enter TTB / CoLA ID number"
                                            label="TTB / CoLA ID number"
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="ps-xl-4">
                                <Input
                                    name="prod_name"
                                    control={ control }
                                    placeholder="Enter description"
                                    label="Description"
                                    type="text"
                                />
                                <div className="row">
                                    <div className="col-12 col-lg-5">
                                        <Input
                                            name="brand_reg"
                                            control={ control }
                                            placeholder="Enter approval/BLR number"
                                            label="Approval / BLR number"
                                            type="text"
                                        />
                                    </div>
                                    <div className="col-12 col-lg-7">
                                        <Input
                                            name="distrib_id"
                                            control={ control }
                                            placeholder="Enter wholesaler / NYS Supplier ID"
                                            label="Wholesaler / NYS Supplier ID"
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-2">
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                            <input
                                className="btn btn-outline-secondary w-100"
                                type="button"
                                onClick={ () => reset() }
                                value="Clear form"
                            />
                        </div>
                        <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                            <input className="btn btn-primary w-100" type="submit" value="Filter"/>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <Spinner hidden={ !isLoading } className="mt-4 text-center" />

        { products.length !== 0 && !isLoading &&
            <div className="mt-2">
                <div className="table-responsive mt-4">
                    <table className="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th>Link</th>
                                <th>Wholesaler item ID</th>
                                <th>TTB / CoLA ID #</th>
                                <th>Approval / BLR #</th>
                                <th>Brand name</th>
                                <th>Description</th>
                                <th>Size/ Unit</th>
                                <th>Wholesaler / NYS Supplier ID</th>
                            </tr>
                        </thead>
                        <tbody>
                            { products.map((product: ProductModel, index: number) => {
                                return (
                                    <tr key={ index }>
                                        <td>
                                            <div className="form-check ms-2">
                                                <Input
                                                    name="id"
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    onChange={ linkProduct }
                                                    value={ product.id }
                                                    checked={ !!initProducts.find(p => p.id === product.id) }
                                                />
                                            </div>
                                        </td>
                                        <td>{ product.prod_item }</td>
                                        <td>{ product.ttb_id }</td>
                                        <td>{ product.brand_reg }</td>
                                        <td>{ product.brand_name }</td>
                                        <td>{ product.prod_name }</td>
                                        <td>{ product.item_size }{ product.um }</td>
                                        <td>{ product.distrib_id }</td>
                                    </tr>
                                );
                            }) }
                        </tbody>
                    </table>
                </div>
                <Paginator totalPages={ pageCount } currentPage={ pageNumber } pageClicked={ handlePageClick }/>
            </div>
        }

        { products.length == 0 && !isLoading &&
            <div className="text-center">
                <p>Items not found.</p>
            </div>
        }

    </div>;
};

export default ProductSearchForm;

