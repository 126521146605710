import React from "react";
import ChooseLicense from "pages/statics/auth/ChooseLicense";
import { Route, Routes } from "react-router-dom";

export const AUTH_STATIC_PATHS = {
    MAIN: "/auth/*",
    CHOOSE_LICENSE: "/choose-license"
};

const AuthStaticRoutes = () => {
    return <Routes>
        <Route path={ AUTH_STATIC_PATHS.CHOOSE_LICENSE } element={ <ChooseLicense/> }/>
    </Routes>;
};

export default AuthStaticRoutes;
