import { Controller } from "react-hook-form";
import React, { TextareaHTMLAttributes } from "react";
import classNames from "classnames";

type Props = TextareaHTMLAttributes<any> & {
    name: string,
    control: any,
    label?: string,
    rules?: any,
    errors?: any,
}

const TextArea = ({ name, label, errors, control, rules, ...rest }: Props) => {

    const feedbackId = name + "Feedback";
    let errorMessage;
    if (errors && errors.hasOwnProperty(name)) {
        errorMessage = errors[name].message;
    }
    const isInvalid = errorMessage || false;
    return <div className="mb-3">
        { label && <label htmlFor={ `Textarea-${name}` } className="form-label">{ label }</label> }
        <Controller
            name={ name }
            control={ control }
            defaultValue=""
            rules={ rules }
            render={ ({ field: { value, onChange, ref } }) => (
                <textarea id={ `Textarea-${name}` }
                    className={  classNames( "form-control", { "is-invalid": isInvalid }) }
                    { ...rest }
                    ref={ ref } value={ value } onChange={ onChange }
                />
            ) }

        />
        <div id={ feedbackId } className="invalid-feedback">
            { errorMessage }
        </div>
    </div>;
};

export default TextArea;
