import React from "react";
import { ImportSubject } from "models/ImportDiscriminator";

export const SampleTxtButton = ({ subject }:{ subject?: ImportSubject }) => {
    return <div className="btn-group me-3 mb-3">
        <button type="button" className="btn btn-outline-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            Sample <b>TXT</b> file
        </button>
        <ul className="dropdown-menu">
            { (typeof subject==="undefined" || subject == ImportSubject.PRODUCTS) &&
                <>
                    <li>
                        <a className="dropdown-item" rel="noreferrer" target="_blank" href="/files/LiquorToRetail.txt">Liquor to Retailer</a>
                    </li>
                    <li>
                        <a className="dropdown-item" rel="noreferrer" target="_blank" href="/files/WineToRetail.txt">Wine to Retailer</a>
                    </li>
                    <li>
                        <a className="dropdown-item" rel="noreferrer" target="_blank" href="/files/LiquorToWholesale.txt">Liquor to Wholesaler</a>
                    </li>
                    <li>
                        <a className="dropdown-item" rel="noreferrer" target="_blank" href="/files/WineToWholesale.txt">Wine to Wholesaler</a>
                    </li>
                </>
            }
            { (typeof subject==="undefined" || subject == ImportSubject.DISCOUNTS) &&
                <li>
                    <a className="dropdown-item" rel="noreferrer" target="_blank" href="/files/DiscountCodes.txt">Discount Table</a>
                </li>
            }
        </ul>
    </div>;
};
