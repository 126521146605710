import { ProductModel } from "models/ProductModel";
import React from "react";
import ErroneousValue from "components/ErroneousValue";
import { TabModel } from "components/product-tab-widget/ProductTabContainer";
import classNames from "classnames";
import StringUtils from "utils/StringUtils";
import ProductConstants from "services/ProductConstants";

const DISPLAYED_PROPERTIES = ["alloc_met","alloc_desc"];
const LimitedAvailabilityTabHeader = (product: ProductModel) => {
    const hasErrors = (p: ProductModel) =>
        StringUtils.hasAnyProperty(p.product_errors, DISPLAYED_PROPERTIES);

    return <span className = { classNames(hasErrors(product) ? "text-danger" : "" ) }>
        { hasErrors(product) && <i className="bi bi-exclamation-circle-fill me-2"></i> }
        Limited availability
    </span>;
};

const LimitedAvailabilityTabBody = (product: ProductModel) => {
    return <div className="ms-sm-4 ps-sm-2 mt-4 mt-sm-2">
        <h5 className="mb-3 fw-bold">Limited availability</h5>
        <p className="mb-2"><strong>Allocation method:&nbsp;</strong>
            <ErroneousValue
                item={ product } name={ "alloc_met" }
                formattedValue={ ProductConstants.allocationMethodsDescription(product.alloc_met) || "N/A" }/>
        </p>
        <p className="mb-2"><strong>Allocation description:&nbsp;</strong>
            <ErroneousValue item={ product } name={ "alloc_desc" } formattedValue={ product.alloc_desc || "N/A" }/>
        </p>
    </div>;
};

export const LimitedAvailabilityTab: TabModel = { header: LimitedAvailabilityTabHeader, body: LimitedAvailabilityTabBody };
