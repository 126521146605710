import React from "react";
import Input from "components/form/Input";

const WholesalerManageDiscountsView = () => {

    return <div className="d-flex flex-column">
        <div className="mb-4 mb-md-5">
            <h1 className="text-primary mb-0">Discount Codes - Schedule for November 2022</h1>
        </div>
        <div className="card">
            <div className="card-body pb-1">
                <div className="text-center">
                    <p>Looks like you don&apos;t have any discount codes. You can create your first one by clicking button below:</p>
                    <div className="form-group mt-4">
                        <a href="/" className="btn btn-primary" title="Create discount code" data-bs-toggle="modal" data-bs-target="#exampleModalAdd">Create discount code</a>
                    </div>
                </div>
            </div>
        </div>
        <div className="modal fade" id="exampleModalAdd" aria-labelledby="exampleModalAddLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable modal-xl modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalAddLabel">Create new discount code</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Cancel"></button>
                    </div>
                    <div className="modal-body pb-2">
                        <div className="mb-4 form-group-without-label form-group-without-m w-100">
                            <div className="form-group">
                                <label className="form-label">Discount code</label>
                                <input type="text" className="form-control" placeholder="Enter discount code name" required/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-4 mb-4">
                                <div className="card">
                                    <div className="card-body pb-2 pt-3">
                                        <div className="styleguide-head mb-4">
                                            <div className="d-flex flex-row align-items-center mb-2">
                                                <h5 className="me-auto mb-0">Discount 1</h5>
                                                <a className="fs-4" href="/">
                                                    <i className="bi-x-circle-fill"></i>
                                                </a>
                                            </div>
                                            <hr className="mt-1" />
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <div className="form-group">
                                                    <label htmlFor="Type" className="form-label">Type</label>
                                                    <select id="Type" className="form-select" aria-label="Select">
                                                        <option selected>$</option>
                                                        <option value="1">$</option>
                                                        <option value="2">Euro</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <Input name="amount" placeholder="00.00" label="Amount" type="number"/>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <Input name="on" placeholder="0" label="On"  type="number"/>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <div className="form-group">
                                                    <label htmlFor="Units" className="form-label">Units</label>
                                                    <select id="Units" className="form-select" aria-label="Select">
                                                        <option selected>Case</option>
                                                        <option value="1">Case 1</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 mb-4">
                                <div className="card">
                                    <div className="card-body pb-2 pt-3">
                                        <div className="styleguide-head mb-4">
                                            <div className="d-flex flex-row align-items-center mb-2">
                                                <h5 className="me-auto mb-0">Discount 2</h5>
                                                <a className="fs-4" href="/">
                                                    <i className="bi-x-circle-fill"></i>
                                                </a>
                                            </div>
                                            <hr className="mt-1" />
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <div className="form-group">
                                                    <label htmlFor="Type" className="form-label">Type</label>
                                                    <select id="Type" className="form-select" aria-label="Select">
                                                        <option selected>$</option>
                                                        <option value="1">$</option>
                                                        <option value="2">Euro</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <Input name="amount" placeholder="00.00" label="Amount" type="number"/>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <Input name="on" placeholder="0" label="On"  type="number"/>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <div className="form-group">
                                                    <label htmlFor="Units" className="form-label">Units</label>
                                                    <select id="Units" className="form-select" aria-label="Select">
                                                        <option selected>Bottle</option>
                                                        <option value="1">Case</option>
                                                        <option value="1">Bottle</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 mb-4">
                                <div className="card">
                                    <div className="card-body pb-2 pt-3">
                                        <div className="styleguide-head mb-4">
                                            <div className="d-flex flex-row align-items-center mb-2">
                                                <h5 className="me-auto mb-0">Discount 3</h5>
                                                <a className="fs-4" href="/">
                                                    <i className="bi-x-circle-fill"></i>
                                                </a>
                                            </div>
                                            <hr className="mt-1" />
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <div className="form-group">
                                                    <label htmlFor="Type" className="form-label">Type</label>
                                                    <select id="Type" className="form-select" aria-label="Select">
                                                        <option selected>$</option>
                                                        <option value="1">$</option>
                                                        <option value="2">Euro</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <Input name="amount" placeholder="00.00" label="Amount" type="number"/>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <Input name="on" placeholder="0" label="On"  type="number"/>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <div className="form-group">
                                                    <label htmlFor="Units" className="form-label">Units</label>
                                                    <select id="Units" className="form-select" aria-label="Select">
                                                        <option selected>Case</option>
                                                        <option value="1">Case 1</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 mb-4">
                                <div className="card">
                                    <div className="card-body pb-2 pt-3">
                                        <div className="styleguide-head mb-4">
                                            <div className="d-flex flex-row align-items-center mb-2">
                                                <h5 className="me-auto mb-0">Discount 4</h5>
                                                <a className="fs-4" href="/">
                                                    <i className="bi-x-circle-fill"></i>
                                                </a>
                                            </div>
                                            <hr className="mt-1" />
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <div className="form-group">
                                                    <label htmlFor="Type" className="form-label">Type</label>
                                                    <select id="Type" className="form-select is-invalid" aria-label="Select">
                                                        <option selected>$</option>
                                                        <option value="1">$</option>
                                                        <option value="2">Euro</option>
                                                    </select>
                                                    <div id="validationServerFeedback" className="invalid-feedback">Please choose type</div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <div className="form-group">
                                                    <label htmlFor="validationServer" className="form-label">Amount</label>
                                                    <input id="validationServer" type="number" className="form-control is-invalid" placeholder="00.00" aria-describedby="validationServerFeedback" required />
                                                    <div id="validationServerFeedback" className="invalid-feedback">Please add amount</div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <Input name="on" placeholder="0" label="On"  type="number"/>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-12 col-xl-6">
                                                <div className="form-group">
                                                    <label htmlFor="Units" className="form-label">Units</label>
                                                    <select id="Units" className="form-select" aria-label="Select">
                                                        <option selected>Case</option>
                                                        <option value="1">Case 1</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Cancel</button>
                        <a href="/" className="btn btn-secondary">Add new discount</a>
                        <button type="button" className="btn btn-primary">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default WholesalerManageDiscountsView;

