import { ValidationRule } from "react-hook-form/dist/types/validator";
import { MessageModel } from "models/message.model";
import { UseFormSetError } from "react-hook-form/dist/types/form";

export default class FormUtil {

    static PASSWORD_NOT_MATCH_MESSAGE = "Your passwords do not match";
    static EMAIL_NOT_MATCH_MESSAGE = "Your emails do not match";

    static REQUIRED_RULE: ValidationRule<boolean> = {
        value: true,
        message: "The field is required"
    };

    static REQUIRED_FIELD = {
        required: FormUtil.REQUIRED_RULE
    };

    static PASSWORD_PATTERN: ValidationRule<RegExp> = {
        value: /[A-Z]/,
        message: "Password should contains at least ..."
    };

    static EMAIL_VALIDATIONS = {
        required: FormUtil.required(),
        maxLength: FormUtil.maxLength(50),
        pattern: FormUtil.emailPattern()
    };

    static PHONE_VALIDATIONS = {
        required: FormUtil.required(),
        maxLength: FormUtil.maxLength(20)
    };

    static ZIP_COD_VALIDATIONS = {
        required: FormUtil.required(),
        maxLength: FormUtil.maxLength(10)
    };

    static YEAR_VALIDATIONS = {
        required: FormUtil.required(),
        maxLength: FormUtil.maxLength(4),
        minLength: FormUtil.minLength(4),
        min: FormUtil.minValue(1800),
        max: FormUtil.maxValue(3000),
        pattern: FormUtil.positiveNumberPattern()
    };

    static NAME_VALIDATIONS = {
        required: FormUtil.required(),
        maxLength: FormUtil.maxLength(56)
    };

    static SERIAL_NUMBER_VALIDATIONS = {
        required: FormUtil.requiredSerialNumber(),
        maxLength: FormUtil.maxLength(20),
        pattern: FormUtil.numberPattern()
    };

    static SERIAL_NUMBER_OR_PERMITID_VALIDATIONS = {
        required: FormUtil.required(),
        maxLength: FormUtil.maxLength(20)
    };

    static PERMIT_ID_OR_SERIAL_NUMBER = {
        required: FormUtil.requiredPermitIdOrSerialNumber(),
        maxLength: FormUtil.maxLength(20)
    };

    static PASSWORD_VALIDATION_MESSAGE = "Your password does not meet the required criteria. " +
        "The requirements are: " +
        " Password needs to be between 8 and 20 characters " +
        " and have at least one digit, " +
        " and at least one lower case character, " +
        " and at least one upper case character, " +
        " and at least one special character: !@#&()–[{}]:;',?/*~$^+=<>";

    static PASSWORD_VALIDATIONS = {
        required: { value: true, message: FormUtil.PASSWORD_VALIDATION_MESSAGE },
        minLength: { value: 8, message: FormUtil.PASSWORD_VALIDATION_MESSAGE },
        maxLength: { value: 20, message: FormUtil.PASSWORD_VALIDATION_MESSAGE },
        pattern: FormUtil.passwordPattern()
    };

    static emailConfirmValidations(func: any) {
        return {
            required: FormUtil.required(),
            maxLength: FormUtil.maxLength(50),
            pattern: FormUtil.emailPattern(),
            validate: func
        };
    }

    static passwordConfirmValidations(func: any) {
        return {
            required: FormUtil.required(),
            minLength: FormUtil.minLength(8),
            maxLength: FormUtil.maxLength(20),
            pattern: FormUtil.passwordPattern(),
            validate: func
        };
    }

    static required(): ValidationRule<boolean> {
        return {
            value: true,
            message: "The field is required"
        };
    }

    static requiredSerialNumber(): ValidationRule<boolean> {
        return {
            value: true,
            message: "Enter a valid serial number."
        };
    }

    static requiredPermitIdOrSerialNumber(): ValidationRule<boolean> {
        return {
            value: true,
            message: "Enter a valid license id or serial number."
        };
    }

    static emailPattern(): ValidationRule<RegExp> {
        return {
            value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: "Email is not correct"
        };
    }

    static positiveNumberPattern(): ValidationRule<RegExp> {
        return {
            value: /^(0|[1-9]\d*)(\.\d+)?$/,
            message: "Incorrect number"
        };
    }

    static numberPattern(): ValidationRule<RegExp> {
        return {
            value: /^\d+$/,
            message: "Incorrect number"
        };
    }

    static passwordPattern(): ValidationRule<RegExp> {
        return {
            value: new RegExp(
                "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,20})",
                "gm"
            ),
            message: FormUtil.PASSWORD_VALIDATION_MESSAGE
        };
    }

    static maxLengthRule(length: number) {
        return {
            maxLength: FormUtil.maxLength(length)
        };
    }

    static maxLength(length: number): ValidationRule<number> {
        return {
            value: length,
            message: "Max length is " + length + " symbols"
        };
    }

    static minLength(length: number): ValidationRule<number> {
        return {
            value: length,
            message: "Min length is " + length + " symbols"
        };
    }

    static minValue(value: number): ValidationRule<number> {
        return {
            value: value,
            message: "Min value is " + value
        };
    }

    static maxValue(value: number): ValidationRule<number> {
        return {
            value: value,
            message: "Max value is " + value
        };
    }

    static statusToName(status: string): string {
        switch(status) {
        case "NOT_CERTIFIED": return "Draft";
        case "CERTIFIED": return "Certified";
        case "PUBLISHED": return "Published";
        case "EQUALIZABLE": return "Published"; // This is the same as 'published' but supports price amendment
        case "EFFECTIVE": return "Effective";
        default: return "Unknown";
        }
    }

    static bevTypeToName(bevType: string): string {
        switch(bevType) {
        case "B": return "Brandy";
        case "CS": return "Cocktails & Specialties";
        case "CL": return "Cordials & Liqueurs";
        case "G": return "Gin";
        case "I": return "Imitations";
        case "NS": return "Neutral Spirits";
        case "R": return "Rum";
        case "Q": return "Tequila";
        case "WH": return "Whiskey";
        case "O": return "Other";
        default: return bevType;
        }
    }

    static handleValidationError = (message: MessageModel, setError: UseFormSetError<any>, setIsUnknownError: (msg?:string)=>void) =>{
        if (message.errors){
            message.errors.forEach(error=>{
                if (error.field && error.field.length > 0){
                    setError(error.field, { message: error.message }, { shouldFocus: true });
                }
                else {
                    setIsUnknownError(error.message);
                }
            });
        }
    };
}
