import React from "react";
import Input from "components/form/Input";

const AdminView = () => {

    return <div className="d-flex flex-column">
        <div className="mb-4 pb-md-3">
            <h1 className="text-primary mb-0">Manage Schedule Deadlines</h1>
        </div>

        <div>
            <div className="">
                <h4 className="mb-3">Schedule Deadline Information - <strong>2022</strong></h4>
            </div>

            <div className="admin-calendar">
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Month</th>
                                <th>Retail</th>
                                <th>Wholesale</th>
                                <th>Amendments</th>
                                <th>Exceptions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="fw-bold bg-light">January</td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Retail" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Wholesale" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex">
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments1" placeholder="" label="" type="number" />
                                        </div>
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments2" placeholder="" label="" type="number" />
                                        </div>
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments3" placeholder="" label="" type="number" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Exceptions" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="fw-bold bg-light">February</td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Retail" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Wholesale" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex">
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments1" placeholder="" label="" type="number" />
                                        </div>
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments2" placeholder="" label="" type="number" />
                                        </div>
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments3" placeholder="" label="" type="number" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Exceptions" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="fw-bold bg-light">March</td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Retail" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Wholesale" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex">
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments1" placeholder="" label="" type="number" />
                                        </div>
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments2" placeholder="" label="" type="number" />
                                        </div>
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments3" placeholder="" label="" type="number" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Exceptions" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="fw-bold bg-light">April</td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Retail" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Wholesale" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex">
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments1" placeholder="" label="" type="number" />
                                        </div>
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments2" placeholder="" label="" type="number" />
                                        </div>
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments3" placeholder="" label="" type="number" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Exceptions" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="fw-bold bg-light">May</td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Retail" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Wholesale" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex">
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments1" placeholder="" label="" type="number" />
                                        </div>
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments2" placeholder="" label="" type="number" />
                                        </div>
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments3" placeholder="" label="" type="number" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Exceptions" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="fw-bold bg-light">June</td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Retail" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Wholesale" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex">
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments1" placeholder="" label="" type="number" />
                                        </div>
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments2" placeholder="" label="" type="number" />
                                        </div>
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments3" placeholder="" label="" type="number" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Exceptions" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="fw-bold bg-light">July</td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Retail" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Wholesale" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex">
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments1" placeholder="" label="" type="number" />
                                        </div>
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments2" placeholder="" label="" type="number" />
                                        </div>
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments3" placeholder="" label="" type="number" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Exceptions" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="fw-bold bg-light">August</td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Retail" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Wholesale" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex">
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments1" placeholder="" label="" type="number" />
                                        </div>
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments2" placeholder="" label="" type="number" />
                                        </div>
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments3" placeholder="" label="" type="number" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Exceptions" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="fw-bold bg-light">September</td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Retail" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Wholesale" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex">
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments1" placeholder="" label="" type="number" />
                                        </div>
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments2" placeholder="" label="" type="number" />
                                        </div>
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments3" placeholder="" label="" type="number" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Exceptions" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="fw-bold bg-light">October</td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Retail" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Wholesale" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex">
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments1" placeholder="" label="" type="number" />
                                        </div>
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments2" placeholder="" label="" type="number" />
                                        </div>
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments3" placeholder="" label="" type="number" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Exceptions" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="fw-bold bg-light">November</td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Retail" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Wholesale" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex">
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments1" placeholder="" label="" type="number" />
                                        </div>
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments2" placeholder="" label="" type="number" />
                                        </div>
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments3" placeholder="" label="" type="number" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Exceptions" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className="fw-bold bg-light">December</td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Retail" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Wholesale" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex">
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments1" placeholder="" label="" type="number" />
                                        </div>
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments2" placeholder="" label="" type="number" />
                                        </div>
                                        <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                            <Input name="Amendments3" placeholder="" label="" type="number" />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className="form-group-without-label form-group-without-m form-group-sm wp-50">
                                        <Input name="Exceptions" placeholder="" label="" type="number" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row justify-content-center mt-3">
                <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                    <a className="btn btn-outline-secondary w-100" href="/">Cancel</a>
                </div>
                <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                    <a className="btn btn-primary w-100" href="/">Save</a>
                </div>
            </div>
            <div className="mt-5">
                <h4 className="mb-4">Generic/Default Schedule Example</h4>
                <img className="img-fluid" src="https://www.nyslapricepostings.com/images/deadlines.jpg" alt="" />
            </div>
        </div>
    </div>;
};

export default AdminView;
