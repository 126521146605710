import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { UnpackNestedValue } from "react-hook-form/dist/types/form";
import { ResolverResult } from "react-hook-form/dist/types/resolvers";
import { Discount } from "models/Discount";
import { DiscountValues } from "models/DiscountValues";
import Input from "components/form/Input";
import DiscountService from "services/wholesaler/DiscountService";
import { modalStore } from "store/ModalStoreProvider";
import EditDiscountValues, { Appendable } from "pages/wholesaler/priceposting/components/EditDiscountValues";

type EditDiscountDialogProps = {
    discount?: Discount,
    onFinally: (success: boolean)=>void
}

export const createDefaultDiscount = () => ({ discount_code :"", values: [createDefaultDiscountValue()] } as Discount);
export const createDefaultDiscountValue = ()=> ({ amount: 0, type: "$", quantity: 0, unit: "C" } as DiscountValues);

export const showEditDiscountDialog = (onFinally: (success: boolean)=>void, discount?: Discount) => {
    modalStore.setSize("xl");
    modalStore.setBody(<EditDiscountDialog discount={ discount } onFinally={ onFinally }/>);
    modalStore.setHeader(discount ? "Configure discount code" : "Create new discount code" );
    modalStore.showModal();
};

const EditDiscountDialog = ({ discount, onFinally }:EditDiscountDialogProps) => {

    const [saveBlocked, setSaveBlocked] = useState<boolean>(false);

    const useFormParams = useForm<Discount>({
        mode: "onSubmit",
        resolver: customResolver,
        reValidateMode: "onBlur",
        defaultValues: discount || createDefaultDiscount()
    });
    const {
        handleSubmit,
        formState: { errors, isSubmitting },
        control,
        trigger
    } = useFormParams;

    const dvControl = useRef<Appendable>(null);
    const addValue = () => dvControl.current?.addDefault();

    useEffect(()=>{
        if (discount){ // display all errors on editing existing discount
            trigger();
        }
    },[]);

    const createOrUpdate = (entity: Discount) => {
        setSaveBlocked(true);
        (entity.id ? DiscountService.update : DiscountService.create)(entity)
            .then(() => {
                onFinally(true);
            })
            .catch(() => onFinally(false))
            .finally(() => {
                modalStore.hideModal();
                setSaveBlocked(false);
            })
        ;
    };

    modalStore.setAdditionalButtons([
        <button key={ 1 } type="button" className="btn btn-primary" onClick={ handleSubmit(createOrUpdate) } disabled={ isSubmitting || saveBlocked }>
            Save
        </button>
    ]
    );

    function customResolver(values:UnpackNestedValue<Discount>): Promise<ResolverResult<Discount>>{
        return DiscountService.validate(values)
            .then(r=> (Object.keys(r).length === 0) ?
                { values:values, errors: {} } :
                { values:{}, errors: r }
            );
    }

    return <div>
        <FormProvider { ...useFormParams }>
            <div className="mb-4 form-group-without-label form-group-without-m w-100">
                <Input
                    name="discount_code" type="text" placeholder="Enter discount code name"
                    control={ control } errors={ errors } maxLength={ 24 } required
                />
            </div>
            <EditDiscountValues control={ control }  errors={ errors } valuesName={ "values" } ref={ dvControl } />
            <div className="d-flex justify-content-end">
                <button key={ 0 } type="button" className="btn btn-outline-primary btn-add fs-4 p-2" onClick={ addValue } >
                    <i className="bi-plus-circle-fill"></i>
                </button>
            </div>
        </FormProvider>
    </div>;
};
