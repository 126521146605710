import React, { useState } from "react";
import LetterService from "services/wholesaler/LetterService";
import Spinner from "components/Spinner";
import { modalStore } from "store/ModalStoreProvider";
import { SavedFile } from "pages/wholesaler/appointment/components/Step3";

export interface Props {
    onChange: any
}

export const showChooseFileLetterDialog = (onChange: any) => {
    modalStore.setSize("lg");
    modalStore.setBody(<ChooseFileLetterDialog onChange={ onChange }/>);
    modalStore.setHeader("Update File");
    modalStore.showModal();
};

const ChooseFileLetterDialog = (props: Props) => {

    const { onChange } = props;
    const [savedFile, setSavedFile] = useState<SavedFile>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    function onFileUpdate(event: any) {
        setIsLoading(true);
        const file: File = event.target.files[0];
        LetterService.uploadFile(file).then(response => {
            if (response) {
                setIsLoading(false);
                setSavedFile({ storagePath: response, file: file });
            }
        }).catch(() => setIsError(true));
    }

    function onSave() {
        if (savedFile) {
            modalStore.hideModal();
            onChange(savedFile);
        } else {
            setIsError(true);
        }
    }
    modalStore.setAdditionalButtons([
        <button key={ 0 } type="button" className="btn btn-primary" onClick={ onSave } disabled={ isLoading }>Save</button>
    ]);

    return <div>
        <div className="form-group">
            <label htmlFor="formFile" className="form-label">Choose file</label>
            <input
                className="form-control"
                type="file"
                id="formFile"
                onChange={ onFileUpdate }
                accept="application/pdf"
            />
            <div hidden={ !isError } className="text-danger small mt-1">
                <p className="b-0">Something went wrong. Please choose a file.</p>
            </div>
        </div>
        <Spinner hidden={ !isLoading } className="text-center mt-2" />
    </div>;
};
