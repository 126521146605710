import TextArea from "components/form/TextArea";
import { useForm } from "react-hook-form";
import ConsoleModel from "pages/admin/models/ConsoleModel";
import { useState } from "react";
import ConsoleService from "services/admin/ConsoleService";

export const Console = () => {
    const [result, setResult] = useState<string>();

    const {
        control,
        handleSubmit
    } = useForm<ConsoleModel>({ mode: "onChange" });

    const onSubmit = async (data: ConsoleModel) => {
        ConsoleService.eval(data.code).then(response => {
            setResult(response);
        });
    };

    return <div className="d-flex flex-column">
        <div className="mb-4 mb-md-5">
            <h1 className="text-primary mb-0">Console</h1>
        </div>

        <form className="card-body" onSubmit={ handleSubmit(onSubmit) }>
            <div className="steps-wr mb-4">
                <TextArea control={ control } name="code" rows={ 5 } placeholder="Groovy code" label="Code"/>
            </div>

            <div className="pt-2 mb-3 mb-md-4">
                <div className="col-6 col-md-4 col-lg-4 col-xl-3 mt-1">
                    <input type="submit" className="btn btn-primary w-100" value="Evaluate"/>
                </div>
            </div>
        </form>

        <div className="pt-2 mb-3 mb-md-4">
            { result }
        </div>
    </div>;

};
