import React, { useEffect, useState } from "react";
import { Link, generatePath, useNavigate } from "react-router-dom";
import { modalStore, useModalStore } from "store/ModalStoreProvider";
import { useAuthStore } from "store/AuthStoreProvider";
import { useToastStore } from "store/ToastStoreProvider";
import UserService from "services/UserService";
import URLUtil from "utils/URLUtil";
import { PageableRequest } from "models/requests/PageableRequest";
import { PageableResponse } from "models/response/PageableResponse";
import { UserModel } from "models/UserModel";
import { LoggedInUserInfo, UserLicense } from "models/LoggedInUserInfo";
import Paginator from "components/Paginator";
import Spinner from "components/Spinner";
import { showDeleteUserDialog } from "components/dialogs/DeleteUserDIalog";
import DateUtil from "utils/DateUtil";
import CreateEditUser from "pages/admin/dialogs/CreateEditUser";
import AuthService from "services/AuthService";
import { redirectToHomePage } from "services/RedirectService";
import UserPublicService from "services/user/UserPublicService";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";
import { UncontrolledMultiSelectRoles } from "components/form/select/MultiSelectRoles";
import { ManageUsersRequest } from "pages/admin/ManageUsers";
import { LicenseSelectControl } from "pages/auth/components/LicenseSelectForm";
import classNames from "classnames";
import { ADMIN_PATHS } from "pages/admin/AdminRoutes";
import { isActiveLicense } from "models/license.model";
/*
    Component can operate in 2 modes -
        Licenses - displaying Licenses column
            - only available for SLA_ADMIN/ADMIN
        Roles - displaying Roles column with non-editable roles for given one license
            - available for both SLA_ADMIN/ADMIN and BA
            - activated when "licenseIdForRolesMode" property is set
*/
type Props = {
    urlData: ManageUsersRequest,
    setUrlData: (a:ManageUsersRequest)=>void,
    licenseIdForRolesMode?: number
};
const UserList = ({ urlData, setUrlData, licenseIdForRolesMode }: Props) => {
    const { addToast, addDefaultDangerToast } = useToastStore();
    const modal = useModalStore();
    const auth = useAuthStore();
    const navigate = useNavigate();
    const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
    const [data, setData] = useState<PageableResponse<UserModel>>({ pageInfo: { page: 1, pagesCount: 1, totalItemsCount: 0 }, data: [] });

    const isBA = !auth.permissionStore.isSlaAdminOrAdmin();
    const currentLicenseIsActive = auth.currentLicense?.active;

    useEffect(()=>reloadTableData(), [urlData]);

    const handlePageClick = (pageNumber: number) => setUrlData({ ...urlData, page: pageNumber });
    const reloadTableData = () => {
        const request = {
            pageInfo: { page: urlData.page || 1 },
            filters:  URLUtil.pureUrlParameters(urlData)
        } as PageableRequest;

        setIsDataLoading(true);

        UserService.lookup(request).then(setData)
            .finally(() => setIsDataLoading(false));
    };

    const displayCreateEditUserDialog = (user?: UserModel) => {
        modalStore.openModal(user ? "Edit User" : "Create User",
            <CreateEditUser
                licenseIdForSingleMode={ licenseIdForRolesMode }
                userUpdated={ reloadTableData }
                user={ user }/>,[],"xl");
    };

    const displaySelectLicenseForImpersonate = (user: UserModel) => {
        if (licenseIdForRolesMode){ // in roles mode, use selected license for impersonate
            impersonate(user.id, licenseIdForRolesMode);
        }
        else if (!user.licenses || user.licenses.length === 0) { // if there is no licenses, do nothing
            return;
        }
        else if (user.licenses.length === 1){ // if there is only one license, do not show dialog, select it
            impersonate(user.id, user.licenses[0].id);
        }
        else { // otherwise show dialog to select license
            const ll = user.licenses.map(l=> ({
                licenseId: l.id,
                premiseName: l.premise_name,
                serialNumber: l.serial_number,
                permitId: l.permit_id,
                active: isActiveLicense(l)
            } as UserLicense));

            modal.openModal("Select license for impersonate",
                <SelectLicenseForImpersonate list={ ll } callback={ licId => impersonate(user.id, licId) }/>,[], "lg");
        }
    };

    const impersonate = (userId: number, licenseId: number) => {
        AuthService.impersonate({ userId: userId, licenseId: licenseId })
            .then((resp:LoggedInUserInfo) => {
                auth.setAuthorizedSilently(resp);
                redirectToHomePage(auth.permissionStore, navigate);
            }).catch(() => addDefaultDangerToast("Auth User"));
    };

    const resendInvitation = (userId: number) => {
        UserService.resendInvitation(userId)
            .then(() =>
                addToast({ message:"An invitation email has been resent", header:"Manage Users", variant: "success", timeoutMs: DEFAULT_TOASTER_TIMEOUT })
            )
            .catch( () =>
                addToast({ message:"Something went wrong. Please try again", header:"Manage Users", variant: "danger", timeoutMs: DEFAULT_TOASTER_TIMEOUT }));
    };

    const resetPassword = (email: string) =>
        UserPublicService.resetPassword({ email: email })
            .then(() => addToast({ message:"A password reset email has been sent to your inbox", header: "Reset password", variant: "success", timeoutMs: DEFAULT_TOASTER_TIMEOUT }))
            .catch(() => addDefaultDangerToast("Manage users"));

    if (isDataLoading){
        return <Spinner className="mt-4 text-center"/>;
    }
    else return <>
        <div className="my-2 d-flex flex-column flex-xl-row align-items-center">
            <div className="me-lg-auto pt-3 mb-3 mb-lg-0">
                <h5 className="mb-0">Found <strong>{ data.pageInfo.totalItemsCount } users</strong></h5>
            </div>
            <div className="pt-3 d-flex flex-row">
                <div className="ms-3 mb-2">
                    <button type="button" className="btn btn-primary" onClick={ () => displayCreateEditUserDialog() } disabled={ !currentLicenseIsActive }>
                        Add new user
                    </button>
                </div>
            </div>
        </div>
        <div className="table-responsive min-hp-180">
            <table className="table table-bordered table-hover mt-2">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Status</th>
                        <th>Last log in</th>
                        { licenseIdForRolesMode ? <th>Roles</th> : <th>License(s)</th> }
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    { data.data.map(user=>{
                        return <tr key={ user.id }>
                            <td>{ user.first_name }&nbsp;{ user.last_name }</td>
                            <td>{ user.email }</td>
                            <td>
                                <ConfirmedStatus is_confirmed={ user.is_confirmed } />
                            </td>
                            <td>{ user.last_login ? DateUtil.formatDateTimeToLocalTimezone(user.last_login) : "" }</td>
                            { licenseIdForRolesMode ?
                                <td>
                                    <UncontrolledMultiSelectRoles name="rc3" placeholder="" roles={ [] } isDisabled
                                        value={
                                            user.licenses?.find(l=>l.id==licenseIdForRolesMode)?.roles.map(r => ({ label: r, value: r }))
                                        }
                                    />
                                </td>
                                : <td className="no-icons">
                                    <div className="d-flex flex-row flex-wrap gap-2">
                                        <ul className="table-list">
                                            { user.licenses?.map((license, index) => (
                                                <li key={ index }>
                                                    <Link to={ generatePath(ADMIN_PATHS.LICENSES.USERS, { "licenseId": license.id }) }
                                                        className = { classNames({ "text-danger": !isActiveLicense(license) }) }
                                                        style={ { textDecoration: "none" } }>
                                                        <strong>{ license.permit_id || license.serial_number }:</strong> { license.premise_name || license.dba }
                                                    </Link>
                                                </li>)
                                            ) }
                                        </ul>
                                    </div>
                                </td>
                            }
                            <td className="dropstart">
                                <button type="button" className="btn dropdown-toggle dropdown-dots rounded-circle mx-auto d-block" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="bi-three-dots-vertical"/>
                                </button>
                                <ul className="dropdown-menu">
                                    <button className="dropdown-item" disabled={ user.is_confirmed } onClick={ () => resendInvitation(user.id) }>
                                        <i className="bi-envelope text-primary me-1"></i>Resend invitation mail
                                    </button>
                                    { (!isBA && currentLicenseIsActive) &&
                                        <button className="dropdown-item" disabled={ !user.is_confirmed || user.licenses?.length == 0 }
                                            onClick={ () => displaySelectLicenseForImpersonate(user) } >
                                            <i className="bi-box-arrow-right text-primary me-1"></i> Impersonate user
                                        </button>
                                    }
                                    <button className="dropdown-item" disabled={ !user.is_confirmed || !currentLicenseIsActive } onClick={ () => resetPassword(user.email) }>
                                        <i className="bi-key text-primary me-1"></i> Reset password
                                    </button>
                                    <button className="dropdown-item" onClick={ () => displayCreateEditUserDialog(user) } disabled={ !currentLicenseIsActive }>
                                        <i className="bi-pencil text-primary me-1"></i> Edit
                                    </button>
                                    <button className="dropdown-item"
                                        disabled={ auth.email === user.email || !currentLicenseIsActive }
                                        onClick={ () => showDeleteUserDialog(user, reloadTableData, licenseIdForRolesMode) }>
                                        <i className="bi-trash text-primary me-1"></i>
                                        { licenseIdForRolesMode ? "Remove from license" : "Delete" }
                                    </button>
                                </ul>
                            </td>
                        </tr>;
                    }) }
                </tbody>
            </table>
        </div>
        <Paginator totalPages={ data.pageInfo.pagesCount || 1 } currentPage={ data.pageInfo.page } pageClicked={ handlePageClick }/>
    </>;
};

export default UserList;

const ConfirmedStatus = ({ is_confirmed }: { is_confirmed : boolean})=>{
    return is_confirmed
        ? <span className="badge bg-success max-w-120">
            <i className="bi-check-circle"></i> Confirmed
        </span>
        : <span className="badge bg-warning max-w-120">
            <i className="bi-clock-history"></i> Not Confirmed
        </span>;
};

const SelectLicenseForImpersonate = ({ list, callback }:{ list : UserLicense[], callback: (licenseId:number)=>void }) =>{
    const [ impersonateLicenseId, setImpersonateLicenseId] = useState<number>();

    const modal = useModalStore();
    modal.setAdditionalButtons([
        <button key={ 1 } type="button" className="btn btn-primary" onClick={ ()=> {
            if (impersonateLicenseId) {
                callback(impersonateLicenseId);
                modal.hideModal();
            }
        } }>Impersonate</button>
    ]);

    return <LicenseSelectControl setter={ setImpersonateLicenseId } list={ list }/>;
};
