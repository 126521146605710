import React from "react";
import { MenuSettings, useLeftMenu } from "components/menu/Menu";
import LicenseSearchView from "pages/statics/license/LicenseSearchView";
import { Route, Routes } from "react-router-dom";

export const LICENSE_STATIC_PATHS = {
    MAIN: "/license/*",
    SEARCH: "/search"
};

const LEFT_MENU: MenuSettings = {
    caption: "Search Licenses",
    items: [
        { title: "Price Posting", link: "/link" },
        { title: "Users", link: "/link2" },
        { title: "My Delinquent Records", link: "/link2" },
        { title: "Licensees Search", link: "/link2" },
        { title: "Delinquent Licensees Search", link: "/link2" }
    ]
};

const LicenseStaticRoutes = () => {
    
    useLeftMenu(LEFT_MENU);

    return <Routes>
        <Route path={ LICENSE_STATIC_PATHS.SEARCH } element={ <LicenseSearchView/> }/>
    </Routes>;
};

export default LicenseStaticRoutes;
