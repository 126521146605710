import React, { Fragment, useEffect, useState } from "react";
import { DEFAULT_TOASTER_TIMEOUT } from "components/Toaster";
import { useToastStore } from "store/ToastStoreProvider";
import Spinner from "components/Spinner";
import { Link, generatePath } from "react-router-dom";
import { WHOLESALER_PATHS } from "pages/wholesaler/WholesalerRoutes";
import ProductConstants from "services/ProductConstants";
import { PriceScheduleByDate } from "models/response/PriceScheduleByDate";
import WholesalerPricePostingService from "services/wholesaler/WholesalerPricePostingService";
import { PriceScheduleData } from "models/PriceScheduleData";
import DateUtil from "utils/DateUtil";

const Amendments = () => {

    const { addToast } = useToastStore();
    const [model, setModel] = useState<PriceScheduleByDate>();
    const [isLoaded, setIsLoaded] = useState<boolean>(true);

    const errorMsg = {
        message:"Something went wrong. Please try again",
        header: "Amendments",
        variant: "danger",
        timeoutMs: DEFAULT_TOASTER_TIMEOUT };

    useEffect(() => {
        setIsLoaded(false);
        WholesalerPricePostingService.fetchAmendmentSchedules()
            .then(response => {
                if (response && response.length > 0) {
                    setModel(response[0]);
                }
            }).catch(() => addToast(errorMsg)
            ).finally(() => setIsLoaded(true));
    }, []);

    return <>
        <div className="mb-4 mb-md-5">
            { model && model.schedules?.length > 0
                ? <h1 className="text-primary mb-0">Amendments</h1>
                : <h1 className="text-primary mb-0">Amendments Period Closed</h1>
            }
        </div>
        <div>
            { model && model.schedules?.length > 0
                ? <p>
                    Welcome to the Price Posting Amendments page. The amendment period is currently open and will remain active for the next three business days. You may now submit any necessary amendments.&nbsp;
                    Please make sure to finalize your changes before the closing date. If you require any assistance, our support team is here to help. Thank you for your prompt attention to this process.
                </p>
                : <Fragment>
                    <p>Thank you for visiting the Price Posting Amendments page. However, please note that the amendment period is currently closed.</p>
                    <p>Price posting amendments can only be submitted during a specific period each month, which is the three business days following the 15th. This policy ensures orderly and efficient processing of amendments.</p>
                    <p>Please mark your calendar and return to this page during the next amendment period. If the 15th falls on a weekend or holiday, the amendment period will start on the next business day.</p>
                    <p>If you have any questions or need further assistance, please don&apos;t hesitate to contact our support team. We appreciate your understanding and cooperation.</p>
                </Fragment>
            }
            <Spinner className="mt-4 pt-2 text-center" hidden={ isLoaded } />
            { model && model.schedules?.length > 0 &&
                <div className="mb-3 price-posting-cnt mt-4 mt-md-5">
                    <h5>Schedules Effective { DateUtil.monthNumberToName(model.month-1) } { model.year }</h5>
                    <div className="table-responsive mt-3 mb-3">
                        <table className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th className="w-30">Price Schedule</th>
                                    <th>Total</th>
                                    <th className="w-19">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                { model.schedules.map((schedule: PriceScheduleData) => {
                                    function generateEditUrl() {
                                        return generatePath(WHOLESALER_PATHS.PRICE_POSTING.VIEW, {
                                            "type": schedule.postType,
                                            "year": model?.year,
                                            "month": model?.month
                                        });
                                    }

                                    return <tr key={ schedule.postType }>
                                        <td>
                                            <Link
                                                className="" title="Manage Schedule"
                                                to={ generateEditUrl() }>
                                                { ProductConstants.postTypeDescription(schedule.postType) }
                                            </Link>
                                        </td>
                                        <td>{ schedule.total }</td>
                                        <td>
                                            <Link
                                                className="btn btn-link py-0 px-1 me-2" title="Manage Schedule"
                                                to={ generateEditUrl() }>
                                                <i className="bi-gear"></i>
                                            </Link>
                                        </td>
                                    </tr>;
                                }) }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </div>
    </>;
};

export default Amendments;
