import React, { PropsWithChildren, Ref, forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SelectControlled, SelectOption } from "components/form/Select";
import RetailerDelinquentService, { RetailerDelinquencyStatus } from "services/retailer/RetailerDelinquentService";
import { DelinquentModel } from "models/DelinquentModel";
import { PageInfo } from "models/PageInfo";
import URLUtil from "utils/URLUtil";
import { useForm } from "react-hook-form";
import { PageableRequest } from "models/requests/PageableRequest";
import Paginator from "components/Paginator";
import Spinner from "components/Spinner";
import { showLicenseDelinquentInfo } from "components/dialogs/LicenseDelinquentInfo";
import CurrencyText from "components/CurrencyText";
import * as _ from "lodash";
import { SortingButton } from "components/SortingButton";
import { Sorting, SortingOrder } from "models/Sorting";
import { Reloadable } from "components/WholesalerDelinquentRecords";

const DELINQUENT_TYPE_OPTIONS: SelectOption[] = [
    { value: "active", title: "Delinquent" },
    { value: "inactive", title: "Cleared" }
];

type SearchDelinquentRetailerRequest = {
    type: string;
    licenseId?: number
}
type SearchParams = SearchDelinquentRetailerRequest & PageInfo & { sortings: Sorting[] };

type Props = { licenseId?: number /* empty if retailer is current logged-in user */ }

const RetailerDelinquentRecords = ({ licenseId, children }: PropsWithChildren<Props>, ref: Ref<Reloadable> ) => {

    const navigate = useNavigate();
    const [ delinquentRecords, setDelinquentRecords ] = useState<DelinquentModel[]>([]);
    const [ status, setStatus ] = useState<RetailerDelinquencyStatus>({ byItself: false, byCBGroup: false });
    const [ isDataLoading, setIsDataLoading ] = useState<boolean>(false);
    const [ pagesCount, setPagesCount ] = useState<number>(0);
    const [ urlParams, setUrlParams ] = useState<SearchParams>(getReadSearchParams());
    const location = useLocation();

    const {
        getValues,
        control,
        watch
    } = useForm<SearchDelinquentRetailerRequest>({
        defaultValues:{
            type: urlParams.type || "active",
            licenseId: licenseId
        }
    });

    useImperativeHandle(ref, () => ({
        reload: () => reload()
    }));

    useEffect(() => {
        RetailerDelinquentService.getLicenseDelinquentStatusById(licenseId).then(setStatus);
    }, []);

    useEffect(() => {
        updateUrlParams(urlParams);
        performQuery(urlParams);
    }, [urlParams]);

    const typeWatch = watch("type");
    useEffect(() => {
        if (typeWatch === urlParams.type) return;
        if (typeWatch === "active") {
            const foundOffDate = urlParams.sortings?.find((element) => element.field === "off_date");
            foundOffDate && urlParams.sortings.splice(urlParams.sortings.indexOf(foundOffDate), 1);
        }
        setUrlParams(prevState =>  ({
            ...prevState,
            type: typeWatch,
            page: 1,
            sortings: urlParams.sortings }));
    }, [typeWatch]);

    function getReadSearchParams() {
        const searchParams = URLUtil.readSearchParams() as SearchParams;
        if (searchParams.sortings === undefined) {
            searchParams.sortings = [{ field: "on_date", order: "desc" }];
        }
        if (searchParams.type === undefined) {
            searchParams.type = "active";
        }
        return searchParams;
    }

    const updateUrlParams = (data: SearchParams) =>
        URLUtil.updateUrlParams(location.pathname, _.omit(data,"licenseId"), navigate);

    function performQuery(data: SearchParams) {
        const request = {
            pageInfo: { page: data.page || 1 },
            filters: {
                type: data.type || "active",
                licenseId: licenseId || ""
            },
            sortings: data.sortings
        } as PageableRequest;

        setIsDataLoading(true);
        RetailerDelinquentService.getDelinquentDataById(request).then(response => {
            setPagesCount(response.pageInfo.pagesCount || 1);
            setDelinquentRecords(response.data || []);
        }).finally(() => setIsDataLoading(false));
    }

    const reload = () => setUrlParams({ ...urlParams });

    function handlePageClick(pageNumber: number) {
        setUrlParams(prevState =>  ({ ...prevState, page: pageNumber }));
    }

    function handleSorting(field: string, order?: SortingOrder) {
        const newSorting = { field: field, order: order };
        const updatedSortings = urlParams.sortings || [];
        const found = urlParams.sortings?.find((element) => element.field === newSorting.field);

        if (found) {
            const index = updatedSortings.indexOf(found);
            if (!newSorting.order) {
                updatedSortings.splice(index, 1);
            } else {
                updatedSortings[index] = newSorting;
            }
        } else {
            updatedSortings.push(newSorting);
        }

        setUrlParams(prevState =>  ({ ...prevState, sortings: updatedSortings }));
    }

    return <div className="mt-5">
        <div className="mb-0 d-flex flex-column flex-xl-row align-items-center">
            <div className="me-lg-auto pt-3 mb-3 mb-lg-0">
                <p className="mb-0">Please select the delinquent list you would like to view.</p>
            </div>
            <div className="pt-3 d-flex flex-row">
                { (delinquentRecords.length != 0 && !isDataLoading) &&
                    <>
                        { children }
                    </>
                }
            </div>
        </div>
        <div className="card">
            <div className="card-body pb-2">
                <form>
                    <div className="row">
                        <div className="col-12 col-md-6 col-xl-4">
                            <SelectControlled name="type" control={ control }
                                options={ DELINQUENT_TYPE_OPTIONS }
                                label="Delinquent list"
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <Spinner className="mt-4 pt-2 text-center" hidden={ !isDataLoading } />

        <div className="mt-2" hidden={ delinquentRecords && delinquentRecords.length === 0 }>
            <div className="table-responsive mt-4">
                <table className="table table-bordered table-hover">
                    <thead>
                        <tr>
                            <th>Wholesale License ID (Serial) #
                                <SortingButton
                                    value={ urlParams.sortings?.find((s) => s.field === "license_id")?.order }
                                    onSorting={ (order) => handleSorting("license_id", order) }/>
                            </th>
                            <th>Premises Name
                                <SortingButton
                                    value={ urlParams.sortings?.find((s) => s.field === "premise_name")?.order }
                                    onSorting={ (order) => handleSorting("premise_name", order) }/>
                            </th>
                            <th>Amount
                                <SortingButton
                                    value={ urlParams.sortings?.find((s) => s.field === "amount")?.order }
                                    onSorting={ (order) => handleSorting("amount", order) }/>
                            </th>
                            <th>Delivery Date
                                <SortingButton
                                    value={ urlParams.sortings?.find((s) => s.field === "delivery_date")?.order }
                                    onSorting={ (order) => handleSorting("delivery_date", order) }/>
                            </th>
                            <th>Date Added
                                <SortingButton
                                    value={ urlParams.sortings?.find((s) => s.field === "on_date")?.order }
                                    onSorting={ (order) => handleSorting("on_date", order) }/>
                            </th>
                            { getValues("type") != "active" &&
                                <th>Date Removed
                                    <SortingButton
                                        value={ urlParams.sortings?.find((s) => s.field === "off_date")?.order }
                                        onSorting={ (order) => handleSorting("off_date", order) }/>
                                </th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        { delinquentRecords.map(record => {
                            return <tr key={ record.id }>
                                <td>
                                    <button className="btn btn-link" onClick={ () => showLicenseDelinquentInfo(record.license.id) }>
                                        { record.license.permit_id } ({ record.license.serial_number })
                                    </button>
                                </td>
                                <td>
                                    <button className="btn btn-link" onClick={ () => showLicenseDelinquentInfo(record.license.id) }>
                                        { record.license.premise_name }
                                    </button>
                                </td>
                                <td><CurrencyText value={ record.amount } /></td>
                                <td> { record.delivery_date } </td>
                                <td> { record.on_date } </td>
                                { getValues("type") != "active" && <td> { record.off_date } </td> }
                            </tr>;
                        }) }
                    </tbody>
                </table>
            </div>
            <div>
                <Paginator totalPages={ pagesCount } currentPage={ urlParams.page || 1 } pageClicked={ handlePageClick }/>
            </div>
        </div>
        { !status.byItself &&
            <div className="mt-4 pt-2" hidden={ getValues("type") === "active" && delinquentRecords && delinquentRecords.length != 0 }>
                <p>
                    <strong>Retail Delinquent Query Results:&nbsp;</strong>
                    { status.byCBGroup
                        ? <>The selected license shows no delinquency, but group-related delinquencies may apply. Please check the status of all associated licenses.</>
                        : <>SLA files indicate that there is no current delinquency reported for the current license number.</>
                    }
                </p>
                <p>
                    <strong>Disclaimer:</strong> The retail query status check provides information taken from the State Liquor Authority Information Systems, before relying on this information,&nbsp;
                    you should be aware that due to workload and administrative issues there may be relevant information that has not yet been entered or updated.
                </p>
            </div>
        }
    </div>;
};

export default forwardRef(RetailerDelinquentRecords);

